import React, { useState, useContext, useEffect } from 'react'
import StudyEventUtils from '../../../../utils/StudyEventUtils'
import {
    NOTE,
    MEASUREMENT,
    DELETABLE_EVENTS,
    IMPEDANCE_MEASUREMENT,
    LONG_DURATION_EVENTS,
    DURATION_CHANGEABLE_EVENTS,
    CAN_MARK_IMPORTANT_EVENTS,
} from '../../../../constants/studyevents'
import StudyEvent from '../../../../types/StudyEvent'
import AppContext from '../../../../components/AppContext'
import { Action } from '../../../../components/AppState'
import StudyEventsApi from '../../../../services/StudyEventsApi'
import ToastUtils from '../../../../utils/ToastUtils'
import ImpedanceModal from '../../Impedance/ImpedanceModal'
import EventMarkerDurationEditing from './EventMarkerDurationEditing'
import ClockSetting from '../../../../types/ClockSetting'

export type EventMoveLocation = 'start' | 'center' | 'end'

interface Props {
    infoBoxPosition: string
    isSaving: boolean
    event: StudyEvent
    montage: any
    infoBoxPositionKey: string
    titleText: string
    border: string
    infoBoxPositionValue: number
    isSelected: boolean
    values: number[]
    clockSetting: ClockSetting
    timebase: number
    handleEditStudyEvent: (event: StudyEvent) => void
    onUpdateEvent: (event: StudyEvent) => Promise<void>
    handleDeleteStudyEvent: (event: StudyEvent) => void
    handleChangeChannel: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onClick: () => void
    onMoveGraphToStudyEvent: (event: StudyEvent, endOfEvent: EventMoveLocation) => Promise<void>
    calculateSampleIndex: (recordingIndex: number, packetIndex: number) => number
}

const EventMarkerInfoBox: React.FC<Props> = props => {
    const ctx = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const [isImpedanceModalOpen, toggleImpedanceModal] = useState(false)
    const [isChangingDuration, setIsChangingDuration] = useState(false)

    const { isSelected } = props
    useEffect(() => {
        if (!isSelected) {
            setIsChangingDuration(false)
        }
    }, [isSelected])

    const initiateEditEvent = () => {
        if (props.isSaving) return
        props.handleEditStudyEvent(props.event)
    }

    const initiateDeleteEvent = () => {
        if (props.isSaving) return
        props.handleDeleteStudyEvent(props.event)
    }

    const initiateMoveToStartOfEvent = (e: any) => {
        if (props.isSaving) return
        props.onMoveGraphToStudyEvent(props.event, 'start')
        e.stopPropagation()
    }

    const initiateMoveToEndOfEvent = (e: any) => {
        if (props.isSaving) return
        props.onMoveGraphToStudyEvent(props.event, 'end')
        e.stopPropagation()
    }

    const isChannelNearTop = () => {
        for (let i = 0; i < props.montage.Channels.length; i += 1) {
            if (props.montage.Channels[i].Name === props.event.ChannelName) {
                return (i + 1) / props.montage.Channels.length < 0.5
            }
        }
        return false
    }

    const toggleImportant = () => {
        if (isSaving) return
        setIsSaving(true)
        const important = !props.event.Important
        StudyEventsApi.setImportant(props.event.ID, important)
            .then(() => {
                setIsSaving(false)
                ctx.commit(Action.UpdateStudyEvent, {
                    ...props.event,
                    Important: !props.event.Important,
                })
            })
            .catch(err => {
                ToastUtils.error({
                    message: `Unable to mark event as ${important ? '' : 'un'}important`,
                })
                console.log('Error updating important:', err)
            })
    }

    const isImpedanceEvent = props.event.EventType.ID === IMPEDANCE_MEASUREMENT
    const isMeasurement = props.event.EventType.ID === MEASUREMENT
    const isNote = props.event.EventType.ID === NOTE
    const canChangeDuration = DURATION_CHANGEABLE_EVENTS.includes(props.event.EventTypeID) && props.event.EndPacketIndex
    const canMarkImportant = CAN_MARK_IMPORTANT_EVENTS.includes(props.event.EventTypeID)
    const isLongDurationEvent = LONG_DURATION_EVENTS.includes(props.event.EventTypeID) && props.event.EndPacketIndex
    const isValidImpedance = (value: string) => value.split('|').length === 2

    return (
        <React.Fragment>
            <div
                className={`info-box ${props.infoBoxPosition}`}
                style={{
                    [props.infoBoxPositionKey]: `${props.infoBoxPositionValue}%`,
                    [isMeasurement && isChannelNearTop()
                        ? 'bottom'
                        : 'top']: '10px',
                    border: props.border,
                }}
                onClick={props.onClick}
            >
                <div
                    className="nav-title-bar"
                    style={{ border: props.border }}
                    onClick={props.onClick}
                >
                    {isLongDurationEvent ? (
                        <React.Fragment>
                            <div
                                className="nav nav-left"
                                style={{ border: props.border, borderBottom: 'none' }}
                                onClick={(e) => isLongDurationEvent && initiateMoveToStartOfEvent(e)}
                                title="Move to event start"
                            >
                                <div className="no-selection-toggle fas fa-fw fa-arrow-left" />
                            </div>
                            {StudyEventUtils.getFriendlyTitle(props.event)}
                            <div
                                className="nav nav-right"
                                style={{ border: props.border, borderBottom: 'none' }}
                                onClick={(e) => isLongDurationEvent && initiateMoveToEndOfEvent(e)}
                                title="Move to event end"
                            >
                                <div className="no-selection-toggle fas fa-fw fa-arrow-right" />
                            </div>
                        </React.Fragment>
                    ) : StudyEventUtils.getFriendlyTitle(props.event)}
                </div>
                <br />
                {props.event.EventType.ID !== MEASUREMENT ? (
                    <React.Fragment>
                        {isChangingDuration ? (
                            <EventMarkerDurationEditing
                                event={props.event}
                                onExit={() => setIsChangingDuration(false)}
                                onUpdateEvent={props.onUpdateEvent}
                                onMoveGraphToStudyEvent={props.onMoveGraphToStudyEvent}
                                calculateSampleIndex={props.calculateSampleIndex}
                                clockSetting={props.clockSetting}
                                timebase={props.timebase}
                            />
                        ) : (
                            <React.Fragment>
                                {!!props.event.Comment && !isNote &&
                                    <div className="event-value">
                                        {props.event.Comment}
                                        <br />
                                    </div>
                                }
                                <div className="event-value">
                                    {StudyEventUtils.getFriendlyValue(props.event)}
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                ) : (
                    <div className="measurement">
                        <p className="text-center">
                            <span className="select-wrapper">
                                <select
                                    className="no-selection-toggle select"
                                    value={
                                        props.values.length === 5
                                            ? props.event.ChannelName
                                            : ''
                                    }
                                    onChange={props.handleChangeChannel}
                                >
                                    <option disabled value="">
                                        Select a channel
                                    </option>
                                    {props.montage.Channels.map((channel: any) => (
                                        <option
                                            value={channel.Name}
                                            key={
                                                channel.Name +
                                                channel.Group +
                                                channel.Sort
                                            }
                                        >
                                            {channel.Name}
                                        </option>
                                    ))}
                                </select>
                            </span>
                        </p>
                        {props.values.length === 5 && (
                            <React.Fragment>
                                <div>
                                    <dt>Duration</dt>
                                    <dd>
                                        {StudyEventUtils.getFormattedMeasurementTime(
                                            props.values[0]
                                        )}
                                    </dd>
                                </div>
                                <div>
                                    <dt>Frequency</dt>
                                    <dd>
                                        {StudyEventUtils.getFormattedMeasurementFreq(
                                            props.values[1]
                                        )}
                                    </dd>
                                </div>
                                <div>
                                    <dt>Amplitude</dt>
                                    <dd>
                                        {StudyEventUtils.getFormattedMeasurementVolt(
                                            props.values[2],
                                            true
                                        )}
                                    </dd>
                                </div>
                                <div>
                                    <dt>Min V</dt>
                                    <dd>
                                        {StudyEventUtils.getFormattedMeasurementVolt(
                                            props.values[3]
                                        )}
                                    </dd>
                                </div>
                                <div>
                                    <dt>Max V</dt>
                                    <dd>
                                        {StudyEventUtils.getFormattedMeasurementVolt(
                                            props.values[4]
                                        )}
                                    </dd>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                )}
                {(props.isSelected && !isChangingDuration) && (
                    <>
                        <div className="actions">
                            <i
                                title="Edit"
                                className="no-selection-toggle icon-electrotek-pencil"
                                onClick={() => initiateEditEvent()}
                            />
                            {DELETABLE_EVENTS.includes(props.event.EventTypeID) && (
                                <i
                                    title="Delete"
                                    className="no-selection-toggle icon-electrotek-delete"
                                    onClick={() => initiateDeleteEvent()}
                                />
                            )}
                            {isImpedanceEvent && isValidImpedance(props.event.Value) && (
                                <i
                                    title="Impedance Measurement"
                                    className="no-selection-toggle icon-electrotek-omega"
                                    onClick={() => toggleImpedanceModal(!isImpedanceModalOpen)}
                                />
                            )}
                            {isImpedanceEvent && isImpedanceModalOpen && (
                                <ImpedanceModal
                                    event={props.event}
                                    toggle={() => toggleImpedanceModal(false)}
                                />
                            )}
                            {canMarkImportant && <i
                                title="Important"
                                className={`
                                no-selection-toggle
                                icon-electrotek-star${props.event.Important ? '' : '-o'}
                                ${isSaving ? 'text-secondary' : props.event.Important ? 'text-warning' : ''}`}
                                onClick={toggleImportant}
                            />}
                            {canChangeDuration && (
                                <i
                                    title="Duration"
                                    className="no-selection-toggle far fa-clock"
                                    onClick={() => setIsChangingDuration(true)}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        </React.Fragment >
    )
}

export default EventMarkerInfoBox
