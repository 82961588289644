import axios from 'axios'
import MMTApi from './MMTApi'
import moment, { Moment } from 'moment'
import AuditLogEntry, { AuditLogEntryFilter } from '../types/AuditLogEntry'

class AuditLogsApi {

    static getAuditLogsForUser(userId: string, startTime?: Moment, endTime?: Moment): Promise<{ data: AuditLogEntry[] }> {
        let timeSpanQueryString = ''
        if (startTime && endTime) {
            const offset = new Date().getTimezoneOffset()
            const start = startTime.clone().add(offset, 'minutes')
            const end = endTime.clone().add(offset, 'minutes')
            timeSpanQueryString = `&startDateTime=${start.format()}&endDateTime=${end.format()}`
            timeSpanQueryString = timeSpanQueryString.replace(/\+/g, '%2B')
        }

        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/AuditLogs/GetAuditLogsForUser/?userId=${userId}${timeSpanQueryString}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getAuditLogsForPatient(patientId: string, filterValues: AuditLogEntryFilter, startTime?: Moment, endTime?: Moment): Promise<{ data: AuditLogEntry[] }> {
        let timeSpanQueryString = ''
        if (startTime && endTime) {
            const offset = new Date().getTimezoneOffset()
            const start = startTime.clone().add(offset, 'minutes')
            const end = endTime.clone().add(offset, 'minutes')
            timeSpanQueryString = `&startDateTime=${start.format()}&endDateTime=${end.format()}`
            timeSpanQueryString = timeSpanQueryString.replace(/\+/g, '%2B')
        }

        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/AuditLogs/GetAuditLogsForPatient/?patientId=${patientId}${timeSpanQueryString}`,
            filterValues,
            MMTApi.getRequestHeaders(),
        )
    }

    static getCptMonitoringLogsForStudy(studyId: string, startTime?: Moment, endTime?: Moment): Promise<{ data: AuditLogEntry[] }> {
        let timeSpanQueryString = ''
        if (startTime) {
            const offset = new Date().getTimezoneOffset()
            const start = startTime.clone().add(offset, 'minutes')
            timeSpanQueryString = `&startDateTime=${start.format()}`
            let end = moment()
            if (endTime) {
                end = endTime.clone().add(offset, 'minutes')
            }
            timeSpanQueryString += `&endDateTime=${end.format()}`
            timeSpanQueryString = timeSpanQueryString.replace(/\+/g, '%2B')
        }

        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/AuditLogs/GetCptMonitoringLogsForStudy/?studyId=${studyId}${timeSpanQueryString}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getAuditLogsForStudy(studyId: string, startTime?: Moment, endTime?: Moment): Promise<{ data: AuditLogEntry[] }> {
        let timeSpanQueryString = ''
        if (startTime) {
            const offset = new Date().getTimezoneOffset()
            const start = startTime.clone().add(offset, 'minutes')
            let end = moment()
            if (endTime) {
                end = endTime.clone().add(offset, 'minutes')
            }
            timeSpanQueryString = `&startDateTime=${start.format()}&endDateTime=${end.format()}`
            timeSpanQueryString = timeSpanQueryString.replace(/\+/g, '%2B')
        }

        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/AuditLogs/GetAuditLogsForStudy/?studyId=${studyId}${timeSpanQueryString}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getFilteredAuditLogs(streamName: string | undefined, filterValues: AuditLogEntryFilter, startTime?: Moment, endTime?: Moment): Promise<{ data: AuditLogEntry[] }> {
        let timeSpanQueryString = ''
        if (startTime && endTime) {
            const offset = new Date().getTimezoneOffset()
            const start = startTime.clone().add(offset, 'minutes')
            const end = endTime.clone().add(offset, 'minutes')
            timeSpanQueryString = `&startDateTime=${start.format()}&endDateTime=${end.format()}`
            timeSpanQueryString = timeSpanQueryString.replace(/\+/g, '%2B')
        }

        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/AuditLogs/FilteredAuditLogs/?id=${streamName}${timeSpanQueryString}`,
            filterValues,
            MMTApi.getRequestHeaders(),
        )
    }
}

export default AuditLogsApi
