import React, { useState, useMemo, Ref } from 'react'
import './CPTMonitoringBlockView.scss'
import moment from 'moment'
import CPTMonitoringTimestamps from './CPTMonitoringTimestamps'
import CPTMonitoringSession from './CPTMonitoringSession'
import User from '../../types/User'
import CPTMonitoringService from '../../services/CPTMonitoringService'
import { Session } from '../../types/cptMonitoringTypes'
import { LABEL_SPACING_PX } from './constants'
import { truncate } from '../../utils/StringUtils'
import { Scope } from './types'

interface Props<Info> {
    wrapper: Ref<HTMLDivElement>
    msPerPx: number
    user?: User
    scope: Scope
    sessions: Session<Info>[]
    renderSessionInfo: (session: Session<Info>) => JSX.Element
}

const CPTMonitoringBlockView = <Info,>(props: Props<Info>) => {
    const mountedAt = useMemo(moment, [])
    const [mouseY, setMouseY] = useState(-1e5)
    const [hoveredSessionIds, setHoveredSessionIds] = useState<string[]>([])
    const [selectedSessionID, setSelectedSessionID] = useState<string | undefined>()

    const mouseAt = props.scope.start.clone().add(mouseY * props.msPerPx, 'milliseconds')

    const { sessions } = props
    const lanes = useMemo(() => {
        const lanes = CPTMonitoringService.getLanes(sessions)
        if (lanes.length === 1) {
            lanes.unshift({ sessions: [] })
            lanes.push({ sessions: [] })
        }
        return lanes
    }, [sessions])

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.currentTarget.getBoundingClientRect()
        const pxFromTop = e.clientY - target.top
        const msFromTop = pxFromTop * props.msPerPx
        const mouseAt = props.scope.start.clone().add(msFromTop, 'milliseconds')
        if (mouseAt.clone().isBefore(props.scope.start) || pxFromTop < 0) {
            setHoveredSessionIds([])
            setMouseY(-1e5)
            return
        }

        const hoveredSessIds = []
        for (const session of sessions) {
            const isMouseAfterStart = (!session.Started || session.Started.isBefore(mouseAt))
            const isMouseBeforeEnd = ((session.Stopped ?? mountedAt).isAfter(mouseAt))
            if (isMouseAfterStart && isMouseBeforeEnd) {
                hoveredSessIds.push(session.ID)
            }
        }
        setHoveredSessionIds(hoveredSessIds)
        setMouseY(pxFromTop)
    }

    const laneHeight = props.scope.end.diff(props.scope.start) / props.msPerPx

    const currentSessions: Session<Info>[] = []
    for (const session of sessions) {
        const startedBeforeNow = session.Started.isBefore(mountedAt)
        const endedAfterNow = (session.Stopped ?? props.scope.end).isAfter(mountedAt)
        if (startedBeforeNow && endedAfterNow) {
            currentSessions.push(session)
        }
    }

    return (
        <div className="cpt-monitoring-block-view" ref={props.wrapper}>
            <div className="timestamps" style={{ height: laneHeight }}>
                <CPTMonitoringTimestamps
                    msPerPx={props.msPerPx}
                    scopeStart={props.scope.start}
                    scopeEnd={props.scope.end}
                    mouseAt={mouseAt}
                />
            </div>
            <div
                className="lanes"
                onMouseMove={handleMouseMove}
                onMouseLeave={() => {
                    setHoveredSessionIds([])
                    setMouseY(-1e5)
                }}
                style={{ height: laneHeight }}
            >
                <div className="mouse-ruler" style={{ top: mouseY }}>
                    <span className="session-count">
                        {hoveredSessionIds.length}{' '}
                        {props.user ? `stud${hoveredSessionIds.length === 1 ? 'y' : 'ies'}` : `user${hoveredSessionIds.length === 1 ? '' : 's'}`}
                    </span>
                    <span className="stamp">
                        {mouseAt.format('h:mm a')}
                    </span>
                </div>
                {mountedAt.isSameOrBefore(props.scope.end) && (
                    <div className="current-time-ruler" style={{ top: mountedAt.clone().diff(props.scope.start) / props.msPerPx }}>
                        {Math.abs(mountedAt.unix() - mouseAt.unix()) * 1000 / props.msPerPx > LABEL_SPACING_PX && (
                            <>
                                <span className="stamp">
                                    {mountedAt.format('h:mm a')}
                                </span>
                                <span className="session-count">
                                    {currentSessions.length}{' '}
                                    {props.user ? `stud${currentSessions.length === 1 ? 'y' : 'ies'}` : `user${currentSessions.length === 1 ? '' : 's'}`}
                                </span>
                            </>
                        )}
                    </div>
                )}
                {lanes.length === 0 && (props.user ? (
                    <h2 className="no-studies-msg">
                        {truncate(props.user.FirstName, 16)} {truncate(props.user.LastName, 16)} didn't monitor any studies on {props.scope.start.format('MMMM D, YYYY')}
                    </h2>
                ) : (
                    <h2 className="no-studies-msg">
                        No one monitored this study
                    </h2>
                ))}
                {lanes.map((lane, laneIndex) => {
                    if (lane.sessions.length === 0) return <div key={laneIndex}></div>
                    return (
                        <div
                            className="lane"
                            key={`lane-${lane.sessions[0].ID}`}
                            style={{
                                left: `calc(${100 * (laneIndex / lanes.length)}% + 10px)`,
                                width: `calc(${100 / lanes.length}% - 20px)`,
                                height: laneHeight,
                            }}
                        >
                            {lane.sessions.map(session => (
                                <CPTMonitoringSession
                                    key={session.ID}
                                    msPerPx={props.msPerPx}
                                    session={session}
                                    laneIndex={laneIndex}
                                    laneCount={lanes.length}
                                    scopeEnd={props.scope.end}
                                    scopeStart={props.scope.start}
                                    hoveredSessionIds={hoveredSessionIds}
                                    selectedSessionID={selectedSessionID}
                                    onSelectSession={selectedId => {
                                        setSelectedSessionID(id => {
                                            return selectedId === id ? undefined : selectedId
                                        })
                                    }}
                                    renderSessionInfo={props.renderSessionInfo}
                                />
                            ))}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CPTMonitoringBlockView
