import React, { useEffect, useState } from 'react'
import Patient from '../../types/Patient'
import { Tabs, Tab } from '@mui/material'
import PatientsApi from '../../services/PatientsApi'
import ToastUtils from '../../utils/ToastUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import DateTimeUtils from '../../utils/DateTimeUtils'
import PatientUtils from '../../utils/PatientUtils'
import PatientDocuments from './PatientDocuments'
import RoleBasedContent from '../../components/RoleBasedContent'
import PatientAuditLogs from './PatientAuditLogs'
import PatientAuth from '../../auth/PatientAuth'
import PatientStudies from './PatientStudies'
import AppContext from '../../components/AppContext'
import { hasRequiredRole } from '../../services/Auth'

interface Props {
    patient: Patient
    onSetIsDocumentUploading: React.Dispatch<React.SetStateAction<boolean>>
}

const PatientView: React.FC<Props> = props => {
    const TABNAME_STUDIES = 'Studies'
    const TABNAME_DOCS = 'Documents'
    const TABNAME_AUDIT = 'Audit Trail'
    
    const [isLoading, setIsLoading] = useState(true)
    const [patient, setPatient] = useState<Patient | undefined>()
    const [activeTabName, setActiveTabName] = useState(TABNAME_STUDIES)

    const context = React.useContext(AppContext)
    const bCanShowStudies = hasRequiredRole(PatientAuth.AccessStudies, context.Roles)
    const bCanShoDocuments = hasRequiredRole(PatientAuth.AccessDocuments, context.Roles)
    const bCanShowAuditLogs = hasRequiredRole(PatientAuth.AccessAuditLogs, context.Roles)
    const patientId = props.patient.ID

    useEffect(() => {
        setIsLoading(true)
        setPatient(undefined)
        // this is a bug - can't set state after useEffect leaves and component unmounts
        // somehow it seems to work, but generates console warning
        PatientsApi.getPatient(patientId).then(res => {
            setPatient(res.data)
        }).catch(err => {
            ToastUtils.error({ message: 'Unable to get patient' })
            console.log(err)
        }).finally(() => { setIsLoading(false) })
    }, [patientId])

    const handleEditIconClick = () => {
        window.location.assign(`/Patient/${patientId}`)
    }

    function a11yProps(labelString: string) {
        return {
            label: labelString,
            value: labelString,
            'aria-controls': `tab ${labelString}`,
        }
    }
    
    const handleTabChange = (event: React.SyntheticEvent<Element, Event>, newValue: string ) => {
        setActiveTabName(newValue)
    }

    return (
        <div className="view">
            { !isLoading && patient ? (
                <>
                    <div className="patient-picture">
                        <img src="/assets/img/profile-alpha.png" alt="profile" />
                    </div>
                    <div className="info">
                        <h4 className="patient-name">{patient.FullName}</h4>
                        <p>Born: {DateTimeUtils.getFriendlyDateOfBirth(patient.DateOfBirth)}</p>
                        <p>SSN: {patient.SSN}</p>
                        <p>ID: {patient.PatientID}</p>
                        <p>Sex: {PatientUtils.formatSex(patient.Sex)}</p>
                        <div className="actions">
                            <RoleBasedContent required_roles={PatientAuth.EditRole}>
                                <i
                                    className="icon-electrotek-pencil"
                                    onClick={handleEditIconClick}
                                    title={`Edit ${patient.FullName}`}
                                />
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={PatientAuth.Import}>
                                <i
                                    onClick={() => window.open(`/Patient/${patientId}/ImportStudy`, '_blank')}
                                    className="icon-electrotek-import"
                                    title={`Import study for ${patient.FullName}`}
                                />
                            </RoleBasedContent>
                        </div>
                    </div>
                    <div className="tabs">
                        <Tabs value={activeTabName} onChange={handleTabChange} aria-label="Patient detail tabs">
                            {bCanShowStudies && <Tab {...a11yProps(TABNAME_STUDIES)} />}
                            {bCanShoDocuments && <Tab {...a11yProps(TABNAME_DOCS)} />}
                            {bCanShowAuditLogs && <Tab {...a11yProps(TABNAME_AUDIT)} />}
                        </Tabs>
                        
                        {activeTabName === TABNAME_STUDIES &&
                            <PatientStudies patient={patient} />
                        }

                        {activeTabName === TABNAME_DOCS &&
                            <PatientDocuments
                                patient={patient}
                                onSetIsDocumentUploading={props.onSetIsDocumentUploading}
                            />
                        }

                        {activeTabName === TABNAME_AUDIT &&
                            <PatientAuditLogs patient={patient} />
                        }
                    </div>
                </>
            ) : isLoading && <LoadingSpinner />}
        </div>
    )
}

export default PatientView
