const containsNonDigitsRegExp = new RegExp(/[^0-9.]/)

export const ALPHA_ASC = field => (a, b) => {
    if (!a[field]) return 1
    if (!b[field]) return -1
    if (a[field].toLowerCase() < b[field].toLowerCase()) return -1
    if (a[field].toLowerCase() > b[field].toLowerCase()) return 1
    return 0
}

export const ALPHA_DESC = field => (a, b) => {
    if (!a[field] || a === '' || a === null) return 1   // ensures blanks are sorted to bottom
    if (!b[field] || b === '' || b === null) return -1  //
    if (a[field].toLowerCase() > b[field].toLowerCase()) return -1
    if (a[field].toLowerCase() < b[field].toLowerCase()) return 1
    return 0
}

export const DIGIT_ASC = field => (a, b) => {
    const aIsNotFloat = containsNonDigitsRegExp.test(a[field])
    const bIsNotFloat = containsNonDigitsRegExp.test(b[field])
    if (aIsNotFloat && bIsNotFloat) return ALPHA_ASC(field)(a, b)
    if (parseFloat(a[field]) < parseFloat(b[field]) || bIsNotFloat) return -1
    if (parseFloat(a[field]) > parseFloat(b[field]) || aIsNotFloat) return 1
    return 0
}

export const DIGIT_DESC = field => (a, b) => {
    const aIsNotFloat = containsNonDigitsRegExp.test(a[field])
    const bIsNotFloat = containsNonDigitsRegExp.test(b[field])
    if (aIsNotFloat && bIsNotFloat) return ALPHA_DESC(field)(a, b)
    if (parseFloat(a[field]) > parseFloat(b[field]) || aIsNotFloat) return -1
    if (parseFloat(a[field]) < parseFloat(b[field]) || bIsNotFloat) return 1
    return 0
}

export const NUM_ASC = field => (a, b) => {
    if (a[field] < b[field]) return -1
    if (a[field] > b[field]) return 1
    return 0
}

export const NUM_DESC = field => (a, b) => {
    if (a[field] > b[field]) return -1
    if (a[field] < b[field]) return 1
    return 0
}

export const DATE_ASC = field => (a, b) =>
    new Date(a[field]) - new Date(b[field])

export const DATE_DESC = field => (a, b) =>
    new Date(b[field]) - new Date(a[field])
