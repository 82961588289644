const Validation = {
    isValidDomain(Domain) {
        const pattern = /\b^((?=[a-z0-9-]{1,63}\.)(xn--)?[a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,63}$\b/
        return pattern.test(Domain)
    },

    isValidEmail(email) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(email)
    },

    /**
     * This method tests to see if all characters in the input are digits
     */
    isDigits(input) {
        return /^\d+$/.test(input)
    },

    isNullOrEmpty(input) {
        return input == null || input === ''
    },
}

export default Validation
