export const TEN_TWENTY_ELECTRODES = [
    'P4',
    'F7',
    'Pz',
    'C4',
    'O2',
    'A2',
    'F4',
    'Fp2',
    'T3',
    'T5',
    'T4',
    'F8',
    'O1',
    'A1',
    'C3',
    'P3',
    'Fp1',
    'F3',
    'T6',
    'Cz',
    'Fz',
]