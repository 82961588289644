import './Facilities.scss'
import { Component } from 'react'
import FacilitiesApi from '../../services/FacilitiesApi'
import FacilityForm from './FacilityForm'
import Validation from '../../services/Validation'
import NavUtils from '../../utils/NavUtils'
import { hasRequiredRole } from '../../services/Auth'
import {
    FACILITY_ADMIN_ROLE,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
} from '../../constants/roles'
import AppContext from '../../components/AppContext'
import { Action } from '../../components/AppState'
import LoadingSpinner from '../../components/LoadingSpinner'
import ToastUtils from '../../utils/ToastUtils'
import { Box, Button } from '@mui/material'
import { withRouter } from 'react-router-dom'

const length_validation = {
    Name: {
        min: 1,
        max: 200,
    },
    Domain: {
        min: 1,
        max: 200,
    },
}

class Facility extends Component {
    static contextType = AppContext

    state = {
        facility: {
            Domain: '',
            Name: '',
        },
        initial_facility: {
            ArchiveLocation: '',
            DateCreated: '',
            DisableAutoArchive: '',
            Domain: '',
            ID: '',
            LocalStorageDays: '',
            Name: '',
            StudyNotesTemplate: '',
            UsesCloudStorage: '',
        },
        value_changed: {
            ArchiveLocation: false,
            DateCreated: false,
            DisableAutoArchive: false,
            Domain: false,
            ID: false,
            LocalStorageDays: false,
            Name: false,
            StudyNotesTemplate: false,
            UsesCloudStorage: false,
        },
        validation: {
            Domain: true,
            Name: true,
            LocalStorageDays: true,
        },
        is_saving: false,
        is_loading: true,
    }

    componentDidMount() {
        NavUtils.setPageTitle(this.getTitle())

        if (!this.props.creating) {
            FacilitiesApi.getFacility(this.props.match.params.id)
                .then(response => {
                    this.setFacility(response.data)
                })
                .catch(error => {
                    const isNotFound = error.status === 404
                    const isBadRequest = error.status === 400
                    if (isNotFound || isBadRequest) {
                        window.location.href = '/404'
                    }
                    ToastUtils.error({ message: error.statusText })
                })
        } else {
            this.newFacility()
        }
    }

    setFacility(facility) {
        this.setState({
            facility,
            initial_facility: facility,
            is_loading: false,
        })
    }

    getFacilityWithDisableAutoArchiveSet = () => {
        const facility = { ...this.state.facility }
        if (facility.LocalStorageDays === '0') {
            facility.DisableAutoArchive = true
        } else {
            facility.DisableAutoArchive = false
        }
        return facility
    }

    getTitle = () => {
        if (this.props.creating) {
            return 'Create Facility'
        }
        return 'Edit Facility'
    }

    isFormValid = () => {
        let valid = true
        let Domain = true
        let Name = true
        let LocalStorageDays = true

        const domainRegExp = new RegExp(/^([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]{0,1}\.){1,2}[a-zA-Z]{2,6}$/)

        // trim and set the fields so we validate on the trimmed values
        this.trimAndSetFields()

        if (
            !this.state.facility.Name ||
            (this.state.facility.Name.length < length_validation.Name.min ||
                this.state.facility.Name.length > length_validation.Name.max)
        ) {
            Name = false
            valid = false
        }

        if (
            !this.state.facility.Domain ||
            (this.state.facility.Domain.length < length_validation.Domain.min ||
                this.state.facility.Domain.length > length_validation.Domain.max)  ||
                !domainRegExp.test(this.state.facility.Domain)
        ) {
            Domain = false
            valid = false
        }

        if (
            !this.props.creating &&
            (typeof this.state.facility.LocalStorageDays === 'undefined' ||
                (!Validation.isDigits(this.state.facility.LocalStorageDays) ||
                    parseInt(this.state.facility.LocalStorageDays, 10) > 999))
        ) {
            LocalStorageDays = false
            valid = false
        }

        this.setState({
            validation: {
                ...this.state.validation,
                Name,
                Domain,
                LocalStorageDays,
            },
        })
        return valid
    }

    newFacility = () => {
        this.setState({ is_loading: false })
    }

    handleChange = (event) => {
        const facility = {
            ...this.state.facility,
            [event.target.name]: event.target.value.length === 1 ? event.target.value.trim() : event.target.value,
        }

        this.setState(
            {
                facility,
            },
            () => {
                this.hasFacilityChanged(event.target.name)
            },
        )
    }

    hasFacilityChanged = field => {
        if (this.hasFacilityValueChanged(field)) {
            this.setState({
                value_changed: {
                    ...this.state.value_changed,
                    [field]: true,
                },
            })
        } else {
            this.setState({
                value_changed: {
                    ...this.state.value_changed,
                    [field]: false,
                },
            })
        }
    }

    hasFacilityValueChanged(field) {
        // if one field is null, then we can't call toString()
        if (
            Validation.isNullOrEmpty(this.state.initial_facility[field]) ||
            Validation.isNullOrEmpty(this.state.facility[field])
        ) {
            // if both are empty or null, then we didn't change
            if (
                Validation.isNullOrEmpty(this.state.initial_facility[field]) &&
                Validation.isNullOrEmpty(this.state.facility[field])
            )
                return false

            // otherwise, something changed
            return true
        }

        return (
            this.state.initial_facility[field].toString().trim() !==
            this.state.facility[field].toString().trim()
        )
    }

    trimAndSetFields() {
        const facility = { ...this.state.facility }

        if (facility.Name) facility.Name = facility.Name.trim().replace(/\s+/g, ' ')
        if (facility.Domain) facility.Domain = facility.Domain.trim()

        if (facility.ArchiveLocation)
            facility.ArchiveLocation = facility.ArchiveLocation.trim()

        this.setState({
            facility,
        })
    }

    handleCancel = () => {
        let path = '/Facilities'
        this.props.history.push(path)
    }

    handleSubmit = e => {
        e.preventDefault()
        if (!this.props.creating) {
            this.updateFacility()
        } else {
            this.createFacility()
        }
    }

    canEdit = () =>
        hasRequiredRole(
            [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE],
            this.context.Roles,
        )

    updateFacilities = () => this.context.commit(Action.ResetFacilities)

    createFacility() {
        const facility = this.getFacilityWithDisableAutoArchiveSet()
        facility.Name = facility.Name.trim().replace(/\s+/g, ' ')
        facility.Domain = facility.Domain.trim().replace(/\s+/g, ' ')
        if (this.isFormValid()) {
            this.setState({
                is_saving: true,
            })

            FacilitiesApi.createFacility(facility)
                .then(() => {
                    this.setState({ is_saving: false })
                    ToastUtils.success({ message: 'Facility created!' })
                    this.updateFacilities()
                })
                .then(() => {
                    this.resetState()
                })
                .catch(error => {
                    let errMsg = error.data.Message || error.statusText
                    if (error.status === 409) {
                        errMsg = 'A facility with this name already exists'
                    }
                    ToastUtils.error({ message: errMsg })
                    this.setState({ is_saving: false })
                })
        }
    }

    updateFacility() {
        const facility = this.getFacilityWithDisableAutoArchiveSet()
        if (this.isFormValid()) {
            this.setState({
                is_saving: true,
            })
            facility.Name = facility.Name.trim().replace(/\s+/g, ' ')
            facility.Domain = facility.Domain.trim().replace(/\s+/g, ' ')
            FacilitiesApi.updateFacility(
                this.props.match.params.id,
                facility,
            )
                .then(() => {
                    this.setState({
                        is_saving: false,
                        initial_facility: facility,
                        value_changed: {
                            ArchiveLocation: false,
                            DateCreated: false,
                            DisableAutoArchive: false,
                            Domain: false,
                            ID: false,
                            LocalStorageDays: false,
                            Name: false,
                            StudyNotesTemplate: false,
                            UsesCloudStorage: false,
                        },
                    })
                    ToastUtils.success({ message: 'Facility saved!' })
                    this.setState({ facility })
                    this.updateFacilities()
                })
                .catch(error => {
                    let errMsg =
                        error.data.Message || error.data || error.statusText
                    if (error.status === 409) {
                        errMsg = 'A facility with this name already exists'
                    }
                    ToastUtils.error({ message: errMsg })
                    this.setState({ is_saving: false })
                })
        }
    }

    resetState = () => {
        const facility = {}

        const validation = {
            Domain: true,
            Name: true,
            LocalStorageDays: true,
        }
        this.setState({
            ...this.state,
            facility,
            validation,
            is_saving: false,
            is_loading: false,
            value_changed: {
                ArchiveLocation: false,
                DateCreated: false,
                DisableAutoArchive: false,
                Domain: false,
                ID: false,
                LocalStorageDays: false,
                Name: false,
                StudyNotesTemplate: false,
                UsesCloudStorage: false,
            },
        })
    }

    render() {
        if (this.state.is_loading) return <LoadingSpinner />
        const has_facility_changed = Object.values(
            this.state.value_changed,
        ).some(element => element === true)
        const can_edit = this.canEdit()

        return (
            <>
                <h3>
                    <i className="icon-grid" /> {this.getTitle()}
                </h3>
                <Box component="form" className="facility-form">
                    <FacilityForm
                        {...this.state.facility}
                        validationRules={length_validation}
                        validation={this.state.validation}
                        creating={this.props.creating}
                        readOnly={!can_edit}
                        handleChange={this.handleChange}
                    />
                    <div style={{ float: 'right' }}>
                        <Button onClick={this.handleCancel} className="cancel-btn">
                            Cancel
                        </Button>
                        <Button
                            disabled={this.state.is_saving || !has_facility_changed}
                            onClick={this.handleSubmit}
                        >
                            {this.state.is_saving ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                </Box>
            </>
        )
    }
}

export default withRouter(Facility)
