import React, { useState } from 'react'
import './PasswordInput.scss'

interface Props {
    fieldRef: React.LegacyRef<HTMLInputElement>
    value: string
    onEnter: () => void
    onChange: React.ChangeEventHandler<HTMLInputElement>
}

function PasswordInput(props: Props) {
    const [isShowing, setIsShowing] = useState(false)

    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            props.onEnter()
        }
    }

    return (
        <div className="password-input">
            <input
                type={isShowing ? 'text' : 'password'}
                name="password"
                id="password"
                className="form-control"
                placeholder="Password"
                onChange={props.onChange}
                value={props.value}
                ref={props.fieldRef}
                onKeyDown={handleEnter}
            />
            <span
                className={`icon-electrotek-eye-${isShowing ? 'closed' : 'open'} show-password-btn`}
                title={`${isShowing ? 'Hide password' : 'Show password'}`}
                onClick={() => setIsShowing(i => !i)}
            />
        </div>
    )
}

export default PasswordInput
