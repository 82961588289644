import React from 'react'
import { Grid } from '@mui/material'

class InputGroup extends React.Component {
    state = { is_showing_password: false }

    toggleIsShowingPassword = () =>
        this.setState({
            is_showing_password: !this.state.is_showing_password,
        })

    render() {
        return (
            <Grid container style={{ margin: '0 0 16px 0' }}>
                <Grid item xs={12} sm={2} md={3} className="form-group" style={{ position: 'relative' }}>
                    <label
                        style={{ margin: 0, paddingRight: 12, top: '100%' }}
                        className={this.props.labelClass || ''}
                        htmlFor={this.props.field || ''}
                    >
                        {this.props.label || ''}
                    </label>
                </Grid>
                <Grid item xs={12} sm={10} md={9}
                    className={
                        `${this.props.type === 'password' ? 'password-input' : ''}`
                    }
                >
                    {this.props.type !== 'textarea' && (
                        <input
                            style={{ width: '100%',  ...{ border: this.props.readOnly && 0 }, ...(this.props.style || null) }}
                            className={ `form-control ${this.props.class ? this.props.class : ''} ` }
                            type={
                                this.props.type === 'password' &&
                                this.state.is_showing_password
                                    ? 'text'
                                    : this.props.type || ''
                            }
                            min={this.props.min || ''}
                            maxLength={this.props.maxLength || ''}
                            id={this.props.field || ''}
                            name={this.props.field || ''}
                            value={this.props.value && this.props.value.toString()}
                            checked={this.props.checked || false}
                            onChange={event =>
                                !!this.props.handleChange(
                                    this.props.field || '',
                                    this.props.type === 'checkbox'
                                        ? event.target.checked
                                        : event.target.value,
                                )
                            }
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    e.preventDefault()
                                    this.props.onEnter?.()
                                }
                            }}
                            disabled={this.props.disabled || false}
                            readOnly={this.props.readOnly || ''}
                            placeholder={this.props.placeholder || ''}
                            pattern={this.props.pattern || null}
                            onBlur={event =>
                                this.props.handleBlur
                                    ? this.props.handleBlur(event.target)
                                    : null
                            }
                            onClick={this.props.onClick ? this.props.onClick : null}
                            tabIndex={this.props.tabIndex || null}
                            title={this.props.title}
                            autoComplete={this.props.autoComplete || null}
                            width={'100%'}
                        />
                    )}
                    {this.props.type === 'textarea' && (
                        <textarea
                            id={this.props.field || ''}
                            title={this.props.title}
                            className={ `form-control ${this.props.class ? this.props.class : ''} ` }
                            style={{ width: '100%',  ...{ border: this.props.readOnly && 0 }, ...(this.props.style || null) }}
                            name={this.props.field || ''}
                            min={this.props.min || ''}
                            maxLength={this.props.maxLength || ''}
                            value={this.props.value && this.props.value.toString()}
                            disabled={this.props.disabled || false}
                            readOnly={this.props.readOnly || ''}
                            placeholder={this.props.placeholder || ''}
                            pattern={this.props.pattern || null}
                            onChange={event => !!this.props.handleChange(this.props.field || '', event.target.value)}
                            onBlur={event => this.props.handleBlur ? this.props.handleBlur(event.target) : null}
                            onClick={this.props.onClick ? this.props.onClick : null}
                            tabIndex={this.props.tabIndex || null}
                            autoComplete={this.props.autoComplete || null}
                        />
                    )}
                    {this.props.type === 'password' && (
                        <span
                            className={`icon-electrotek-eye-${
                                this.state.is_showing_password
                                    ? 'closed'
                                    : 'open'
                            } show-password-btn`}
                            title={`${
                                this.state.is_showing_password
                                    ? 'Hide password'
                                    : 'Show password'
                            }`}
                            onClick={this.toggleIsShowingPassword}
                        />
                    )}
                    {this.props.labelSubText && (
                        <div className="label-subtext">
                            <small>{this.props.labelSubText || ''}</small>
                        </div>
                    )}
                </Grid>
                {!this.props.valid && this.props.error && !this.props.hideError && (
                    <>
                        <Grid item xs={12} sm={2} md={3} className="form-group empty-column-offset" style={{ position: 'relative', paddingRight: 12 }} />
                        <Grid item xs={12} sm={10} md={9}>                        
                            <div className="input-error-msg">
                                {this.props.error || ''}
                            </div>
                        </Grid>
                    </>                 
                )}                
            </Grid>
        )
    }
}

export default InputGroup
