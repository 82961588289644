import React from 'react'
import './LogEntriesTable.scss'

import LogEntryRow from './LogEntryRow'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

const LogEntriesTable = props => (
    <TableContainer className="log-entries-table">       
        <Table id="logentriestable" className="table-hover" stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Software Version</TableCell>
                    <TableCell>User Name</TableCell>
                    <TableCell>Error Code</TableCell>
                </TableRow>
            </TableHead>            
            <TableBody>            
                {props.log_entries.map(entry => (
                    <LogEntryRow
                        key={entry.ID}
                        selected={
                            props.selected && props.selected.ID === entry.ID
                        }
                        logEntry={entry}
                        click_handler={props.click_handler}
                    />
                ))}
            </TableBody>
        </Table>
    </TableContainer>         
)

export default LogEntriesTable
