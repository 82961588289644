export const UNKNOWN = 1
export const START = 2
export const STOP = 3
export const MONTAGE_CHANGE = 4
export const HIGH_FREQUENCY_FILTER_CHANGE = 5
export const LOW_FREQUENCY_FILTER_CHANGE = 6
export const NOTCH_FILTER_CHANGE = 7
export const NOTCH_TOGGLE = 8
export const MANUAL_PULSE = 9 // EVENT MARKER
export const PHOTIC_STIM = 10
export const CHECKERED_BOARD = 11
export const THEME_CHANGE = 12
export const HARNESS_CHANGE = 13
export const NOTE = 14
export const MEASUREMENT = 15
export const HYPERVENT_START = 16
export const HYPERVENT_STOP = 17
export const HYPERVENT_REST_STOP = 18
export const INITIAL_FILTER_VALUES = 19
export const INITIAL_NOTCH = 20
export const IMPEDANCE_MEASUREMENT = 21
export const SENSITIVITY_CHANGE = 22
export const TIMEBASE_CHANGE = 23
export const VIDEO_START = 24
export const VIDEO_STOP = 25
export const DOOR_OPEN = 26
export const DOOR_CLOSED = 27
export const HOST_ON = 28
export const HOST_OFF = 29
export const LOW_BATTERY = 30
export const OK_BATTERY = 31
export const CALIBRATE_MODE = 32
export const NORMAL_MODE = 33
export const ELECTRODES_ON = 34
export const ELECTRODES_OFF = 35
export const PATIENT_EVENT = 36
export const EXTERNAL_EVENT = 37
export const PHOTIC_START = 38
export const PHOTIC_STOP = 39
export const HYPERVENT_CONT = 40
export const POST_HYPERVENT_START = 41
export const POST_HYPERVENT_CONT = 42
export const POST_HYPERVENT_STOP = 43
export const VIDEO_MOVEMENT = 44
export const TRACKIT_CONNECT = 45
export const TRACKIT_DISCONNECT = 46
export const VIDEO_SYNC = 47
export const IMPEDANCE_MODE = 48
export const HIGH_CPU = 49
export const WEAK_BLUETOOTH = 50
export const SOFTWARE_RECOVERY = 51

// STUDY EVENT GROUPINGS
export const HIDDEN_EVENTS = [VIDEO_SYNC, VIDEO_STOP]
export const NOTE_EVENTS = [
    MANUAL_PULSE,
    NOTE,
    MEASUREMENT,
    PATIENT_EVENT,
    EXTERNAL_EVENT,
]
export const SETTING_EVENTS = [
    MONTAGE_CHANGE,
    HIGH_FREQUENCY_FILTER_CHANGE,
    LOW_FREQUENCY_FILTER_CHANGE,
    NOTCH_FILTER_CHANGE,
    NOTCH_TOGGLE,
    THEME_CHANGE,
    INITIAL_FILTER_VALUES,
    INITIAL_NOTCH,
    IMPEDANCE_MEASUREMENT,
    HARNESS_CHANGE,
    SENSITIVITY_CHANGE,
    TIMEBASE_CHANGE,
]
export const STIMULATION_EVENTS = [
    PHOTIC_STIM,
    HYPERVENT_START,
    HYPERVENT_STOP,
    HYPERVENT_REST_STOP,
    CHECKERED_BOARD,
    PHOTIC_START,
    PHOTIC_STOP,
    HYPERVENT_CONT,
    POST_HYPERVENT_START,
    POST_HYPERVENT_CONT,
    POST_HYPERVENT_STOP,
]
export const OTHER_EVENTS = [
    START,
    UNKNOWN,
    STOP,
    VIDEO_START,
    VIDEO_STOP,
    DOOR_OPEN,
    DOOR_CLOSED,
    HOST_ON,
    HOST_OFF,
    LOW_BATTERY,
    OK_BATTERY,
    CALIBRATE_MODE,
    NORMAL_MODE,
    ELECTRODES_ON,
    ELECTRODES_OFF,
    VIDEO_MOVEMENT,
    TRACKIT_CONNECT,
    TRACKIT_DISCONNECT,
    VIDEO_SYNC,
    IMPEDANCE_MODE,
    HIGH_CPU,
    WEAK_BLUETOOTH,
    SOFTWARE_RECOVERY,
]
export const ALL_EVENTS = [
    ...NOTE_EVENTS,
    ...SETTING_EVENTS,
    ...STIMULATION_EVENTS,
    ...OTHER_EVENTS,
]
export const MOVABLE_EVENTS = [NOTE]
export const DELETABLE_EVENTS = [NOTE]
export const DURATION_CHANGEABLE_EVENTS = [NOTE]
export const CAN_MARK_IMPORTANT_EVENTS = [NOTE, MEASUREMENT, PATIENT_EVENT]
export const LONG_DURATION_EVENTS = MOVABLE_EVENTS.filter(e => e !== MEASUREMENT).concat([SOFTWARE_RECOVERY])

// STUDY EVENT FRIENDLY NAMES
export const EFN: { [event: number]: string } = {}
EFN[CALIBRATE_MODE] = 'Calibration Mode'
EFN[CHECKERED_BOARD] = 'Checkered Board'
EFN[DOOR_CLOSED] = 'Door Closed'
EFN[DOOR_OPEN] = 'Door Open'
EFN[ELECTRODES_ON] = 'Electrodes On'
EFN[ELECTRODES_OFF] = 'Electrodes Off'
EFN[EXTERNAL_EVENT] = 'External Event'
EFN[HARNESS_CHANGE] = 'Harness Change'
EFN[HIGH_FREQUENCY_FILTER_CHANGE] = 'High Frequency Filter Change'
EFN[HOST_OFF] = 'Amp detected disconnection from PC'
EFN[HOST_ON] = 'Amp detected connection to PC'
EFN[HYPERVENT_CONT] = 'Hypervent Continue'
EFN[HYPERVENT_REST_STOP] = 'End Resting'
EFN[HYPERVENT_START] = 'Hyperventilating'
EFN[HYPERVENT_STOP] = 'Resting'
EFN[IMPEDANCE_MEASUREMENT] = 'Impedance Measurement'
EFN[IMPEDANCE_MODE] = 'Impedance Mode'
EFN[INITIAL_FILTER_VALUES] = 'Initial Filter Settings'
EFN[INITIAL_NOTCH] = 'Initial Notch Settings'
EFN[LOW_BATTERY] = 'Low Battery'
EFN[LOW_FREQUENCY_FILTER_CHANGE] = 'Low Frequency Filter Change'
EFN[MANUAL_PULSE] = 'Event Marker'
EFN[MEASUREMENT] = 'Measurement'
EFN[MONTAGE_CHANGE] = 'Montage Change'
EFN[NOTCH_FILTER_CHANGE] = 'Notch Frequency Filter Change'
EFN[NOTCH_TOGGLE] = 'Notch Toggled'
EFN[NOTE] = 'Note'
EFN[NORMAL_MODE] = 'Normal Mode'
EFN[OK_BATTERY] = 'Power Source Changed'
EFN[PATIENT_EVENT] = 'Patient Event'
EFN[PHOTIC_START] = 'Photic Start'
EFN[PHOTIC_STIM] = 'Photic Stimulation'
EFN[PHOTIC_STOP] = 'Photic Stop'
EFN[POST_HYPERVENT_START] = 'Post Hypervent Start'
EFN[POST_HYPERVENT_CONT] = 'Post Hypervent Cont'
EFN[POST_HYPERVENT_STOP] = 'Post Hypervent Stop'
EFN[SENSITIVITY_CHANGE] = 'Sensitivity Change'
EFN[START] = 'Start Recording'
EFN[STOP] = 'Stop recording'
EFN[THEME_CHANGE] = 'Theme Change'
EFN[TIMEBASE_CHANGE] = 'Time Base Change'
EFN[TRACKIT_CONNECT] = 'PC detected connection to amp'
EFN[TRACKIT_DISCONNECT] = 'PC detected disconnection from amp'
EFN[UNKNOWN] = 'Unknown'
EFN[VIDEO_MOVEMENT] = 'Video Movement'
EFN[VIDEO_START] = 'Video Start'
EFN[VIDEO_STOP] = 'Video Stop'
EFN[VIDEO_SYNC] = 'Video Sync'
EFN[HIGH_CPU] = 'High CPU'
EFN[WEAK_BLUETOOTH] = 'Weak Bluetooth'
EFN[SOFTWARE_RECOVERY] = 'Software Recovery'

// STUDY EVENT ICONS
export const EVENT_ICON: { [event: number]: string } = {}
EVENT_ICON[UNKNOWN] = ''
EVENT_ICON[START] = 'icon-electrotek-control-record'
EVENT_ICON[STOP] = 'icon-electrotek-control-stop'
EVENT_ICON[MONTAGE_CHANGE] = 'icon-electrotek-eeg'
EVENT_ICON[HIGH_FREQUENCY_FILTER_CHANGE] = 'icon-electrotek-opt'
EVENT_ICON[LOW_FREQUENCY_FILTER_CHANGE] = 'icon-electrotek-opt2'
EVENT_ICON[NOTCH_FILTER_CHANGE] = 'icon-electrotek-notch'
EVENT_ICON[NOTCH_TOGGLE] = 'icon-electrotek-notch'
EVENT_ICON[MANUAL_PULSE] = 'icon-electrotek-thumb-press'
EVENT_ICON[PHOTIC_STIM] = 'icon-electrotek-light'
EVENT_ICON[CHECKERED_BOARD] = ''
EVENT_ICON[THEME_CHANGE] = ''
EVENT_ICON[HARNESS_CHANGE] = ''
EVENT_ICON[NOTE] = 'icon-electrotek-note_add'
EVENT_ICON[MEASUREMENT] = 'icon-electrotek-ruler'
EVENT_ICON[HYPERVENT_START] = 'icon-electrotek-lungs-fill'
EVENT_ICON[HYPERVENT_STOP] = 'icon-electrotek-wind2'
EVENT_ICON[HYPERVENT_REST_STOP] = ''
EVENT_ICON[INITIAL_FILTER_VALUES] = 'icon-electrotek-controls'
EVENT_ICON[INITIAL_NOTCH] = 'icon-electrotek-controls'
EVENT_ICON[IMPEDANCE_MEASUREMENT] = 'icon-electrotek-omega'
EVENT_ICON[SENSITIVITY_CHANGE] = 'sensitivity-icon-letter'
EVENT_ICON[TIMEBASE_CHANGE] = 'timebase-icon-letter'
EVENT_ICON[VIDEO_START] = 'icon-electrotek-videocam'
EVENT_ICON[VIDEO_STOP] = 'icon-electrotek-videocam'
EVENT_ICON[DOOR_OPEN] = ''
EVENT_ICON[DOOR_CLOSED] = ''
EVENT_ICON[HOST_ON] = 'icon-electrotek-amplifier-connected'
EVENT_ICON[HOST_OFF] = 'icon-electrotek-amplifier-disconnected'
EVENT_ICON[LOW_BATTERY] = 'icon-electrotek-low-battery'
EVENT_ICON[OK_BATTERY] = 'icon-electrotek-ok-battery'
EVENT_ICON[CALIBRATE_MODE] = 'icon-electrotek-calibrate-mode'
EVENT_ICON[NORMAL_MODE] = 'icon-electrotek-normal-mode'
EVENT_ICON[ELECTRODES_ON] = ''
EVENT_ICON[ELECTRODES_OFF] = ''
EVENT_ICON[PATIENT_EVENT] = 'icon-electrotek-person'
EVENT_ICON[POST_HYPERVENT_START] = 'icon-electrotek-lungs-fill'
EVENT_ICON[POST_HYPERVENT_STOP] = 'icon-electrotek-wind2'
EVENT_ICON[IMPEDANCE_MODE] = 'icon-electrotek-impedance-mode'
EVENT_ICON[TRACKIT_CONNECT] = 'icon-electrotek-amplifier-connected'
EVENT_ICON[TRACKIT_DISCONNECT] = 'icon-electrotek-amplifier-disconnected'
EVENT_ICON[HIGH_CPU] = 'icon-electrotek-high-cpu'
EVENT_ICON[WEAK_BLUETOOTH] = 'icon-electrotek-weak-bluetooth'
EVENT_ICON[EXTERNAL_EVENT] = 'icon-electrotek-external-event'
EVENT_ICON[SOFTWARE_RECOVERY] = 'icon-electrotek-software-recovery'

// QUICK NOTE DEFINITIONS
export const EYES_OPEN = 'Eyes Open'
export const EYES_CLOSED = 'Eyes Closed'
export const MOVEMENT = 'Movement'
export const BLINKS = 'Blinks'
export const LAUGHING = 'Laughing'
export const TALKING = 'Talking'
export const CRYING = 'Crying'
export const CHEWING = 'Chewing'
export const SWALLOW = 'Swallow'
export const YAWN = 'Yawn'

// QUICK NOTE GROUPING
export const QUICK_NOTES = [
    EYES_OPEN,
    EYES_CLOSED,
    MOVEMENT,
    BLINKS,
    LAUGHING,
    TALKING,
    CRYING,
    CHEWING,
    SWALLOW,
    YAWN,
]

// QUICK NOTE ICONS
export const QUICK_NOTE_ICON: { [event: string]: string } = {}
QUICK_NOTE_ICON[EYES_OPEN] = 'icon-electrotek-eye-open'
QUICK_NOTE_ICON[EYES_CLOSED] = 'icon-electrotek-eye-closed'
QUICK_NOTE_ICON[MOVEMENT] = 'icon-electrotek-move'
QUICK_NOTE_ICON[BLINKS] = 'icon-electrotek-blink'
QUICK_NOTE_ICON[LAUGHING] = 'icon-electrotek-laugh'
QUICK_NOTE_ICON[TALKING] = 'icon-electrotek-talk'
QUICK_NOTE_ICON[CRYING] = 'icon-electrotek-cry'
QUICK_NOTE_ICON[CHEWING] = 'icon-electrotek-chew'
QUICK_NOTE_ICON[SWALLOW] = 'icon-electrotek-swallow'
QUICK_NOTE_ICON[YAWN] = 'icon-electrotek-yawn'
