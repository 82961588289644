import { useEffect, useState } from 'react'
import './MontageEditor.scss'
import Montage from '../../types/Montage'
import MontagesApi from '../../services/MontagesApi'
import ToastUtils from '../../utils/ToastUtils'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import MontageHeadView from './MontageHeadView'
import MontageChannelListView from './MontageChannelListView'
import MontageChannel from '../../types/MontageChannel'
import { HEAD_HEIGHT_RATIO } from '../../components/head/constants'
import useDeviceHeight from '../../hooks/useDeviceHeight'
import { getChannelFormulaViolation } from '../../utils/StringUtils'
import NeutralButton from '../../components/NeutralButton'
import useDocumentTitle from '../../hooks/useDocumentTitle'

export const CHANNEL_SORTER = (a: MontageChannel, b: MontageChannel) => {
    if (a.Group === b.Group) {
        return a.Sort - b.Sort
    }
    return a.Group - b.Group
}

function areMontagesEqual(a: Montage, b: Montage): boolean {
    if (a.Name !== b.Name) return false
    if (a.Channels.length !== b.Channels.length) return false
    a.Channels.sort(CHANNEL_SORTER)
    b.Channels.sort(CHANNEL_SORTER)
    for (let i = 0; i < a.Channels.length; i++) {
        const aCh = a.Channels[i]
        const bCh = b.Channels[i]
        if (aCh.Sort !== bCh.Sort) return false
        if (aCh.ID !== bCh.ID) return false
        if (aCh.Name !== bCh.Name) return false
        if (aCh.Formula !== bCh.Formula) return false
        if (aCh.Group !== bCh.Group) return false
        if (aCh.ChannelTypeID !== bCh.ChannelTypeID) return false
    }
    return true
}

function cloneAndNormalizeChannels(m: Montage): Montage {
    const newMontage: Montage = JSON.parse(JSON.stringify(m))
    newMontage.Channels.sort(CHANNEL_SORTER)
    for (let i = 0; i < newMontage.Channels.length; i++) {
        newMontage.Channels[i].Sort = i
    }
    return newMontage
}

function MontageEditor() {
    const [originalMontage, setOriginalMontage] = useState<Montage | null>(null)
    const [workingMontage, setWorkingMontage] = useState<Montage | null>(null)
    const [isSaving, setIsSaving] = useState(false)
    const [groupCnt, setGroupCnt] = useState(0)
    const [selectedChannel, setSelectedChannel] = useState<MontageChannel | null>(null)
    const { montageId } = useParams<{ montageId: string }>()
    useDocumentTitle('Edit Montage')
    const deviceHeight = useDeviceHeight()
    const headWidth = (deviceHeight - 200) / HEAD_HEIGHT_RATIO

    useEffect(() => {
        setOriginalMontage(null)
        setWorkingMontage(null)
        setGroupCnt(0)
        const source = axios.CancelToken.source()
        MontagesApi.getMontage(montageId, source).then(res => {
            setOriginalMontage(cloneAndNormalizeChannels(res.data))
            setWorkingMontage(cloneAndNormalizeChannels(res.data))
            setGroupCnt([...cloneAndNormalizeChannels(res.data).Channels].pop()?.Group ?? 1)
        }).catch(err => {
            if (axios.isCancel(err)) return
            ToastUtils.error({ message: 'Unable to get montage' })
            console.log(err)
        })
        return () => source.cancel('Montage no longer needed')
    }, [montageId])

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            switch (e.key) {
                case 'Backspace':
                    const target = e.target as HTMLDivElement
                    if (target.classList?.contains('channel-formula')) {
                        return
                    }
                    if (selectedChannel) {
                        setWorkingMontage(m => m ? ({
                            ...m,
                            Channels: m.Channels.filter(ch => ch.ID !== selectedChannel.ID),
                        }) : null)
                        setSelectedChannel(null)
                    }
                    break
                case 'ArrowDown':
                    if (!workingMontage) return
                    if (!selectedChannel) return
                    e.preventDefault()
                    for (let i = 0; i < workingMontage.Channels.length; i++) {
                        if (workingMontage.Channels[i].ID === selectedChannel.ID) {
                            let selectedIndex = i
                            if (selectedIndex === workingMontage.Channels.length) {
                                selectedIndex = -1
                            }
                            if (e.shiftKey) {
                                if (selectedIndex === workingMontage.Channels.length - 1) {
                                    return
                                }
                                setWorkingMontage(m => {
                                    if (!m) return m
                                    const aCh = m.Channels[selectedIndex]
                                    const bCh = m.Channels[selectedIndex + 1]
                                    if (aCh.Group !== bCh.Group) {
                                        aCh.Group = bCh.Group
                                    } else {
                                        aCh.Sort = aCh.Sort + 1
                                        bCh.Sort = bCh.Sort - 1
                                    }
                                    return { ...m }
                                })
                                break
                            } else if (selectedIndex < workingMontage.Channels.length - 1) {
                                setSelectedChannel({ ...workingMontage.Channels[selectedIndex + 1] })
                            }
                            break
                        }
                    }
                    break
                case 'ArrowUp':
                    if (!workingMontage) return
                    if (!selectedChannel) return
                    e.preventDefault()
                    for (let i = 0; i < workingMontage.Channels.length; i++) {
                        if (workingMontage.Channels[i].ID === selectedChannel.ID) {
                            let selectedIndex = i
                            if (selectedIndex < 0) {
                                selectedIndex = workingMontage.Channels.length
                            }
                            if (e.shiftKey) {
                                if (selectedIndex === 0) {
                                    return
                                }
                                setWorkingMontage(m => {
                                    if (!m) return m
                                    const aCh = m.Channels[selectedIndex]
                                    const bCh = m.Channels[selectedIndex - 1]
                                    if (aCh.Group !== bCh.Group) {
                                        aCh.Group = bCh.Group
                                    } else {
                                        aCh.Sort = aCh.Sort - 1
                                        bCh.Sort = bCh.Sort + 1
                                    }
                                    return { ...m }
                                })
                                break
                            } else if (selectedIndex > 0) {
                                setSelectedChannel({ ...workingMontage.Channels[selectedIndex - 1] })
                            }
                            break
                        }
                    }
                    break
            }
        }
        window.addEventListener('keydown', listener)
        return () => window.removeEventListener('keydown', listener)
    }, [selectedChannel, workingMontage])

    const handleSave = () => {
        if (!workingMontage) return
        setIsSaving(true)
        const montage = { ...workingMontage, Name: workingMontage.Name.trim() }
        MontagesApi.updateMontage(montage).then(res => {
            setOriginalMontage(cloneAndNormalizeChannels(res.data))
            setWorkingMontage(cloneAndNormalizeChannels(res.data))
            setGroupCnt([...cloneAndNormalizeChannels(res.data).Channels].pop()?.Group ?? 1)
            setIsSaving(false)
            ToastUtils.success({ message: `${montage.Name} saved!` })
        }).catch(err => {
            ToastUtils.error({ message: `Unable to update montage: ${err.data?.Message ?? 'Unknown reason'}` })
            console.log(err)
            setIsSaving(false)
        })
    }

    const hasChanges = workingMontage !== null
        && originalMontage !== null
        && !areMontagesEqual(workingMontage, originalMontage)

    const isNameValid = workingMontage && workingMontage.Name.trim().length > 0 && workingMontage.Name.trim().length <= 50

    useEffect(() => {
        if (hasChanges) {
            const listener = (e: BeforeUnloadEvent) => {
                if (ToastUtils.isOpen(/Inactivity warning/)) {
                    return
                }
                e.preventDefault()
                const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave this page?'
                const event = e || window.event
                event.returnValue = confirmationMessage
                return confirmationMessage
            }
            window.addEventListener('beforeunload', listener)
            return () => {
                window.removeEventListener('beforeunload', listener)
            }
        }
    }, [hasChanges])

    return (
        <div className="montage-editor">
            <div>
                {hasChanges && workingMontage && !workingMontage.Channels.some(ch => getChannelFormulaViolation(ch.Formula)) && isNameValid && (
                    <NeutralButton
                        sx={{ float: 'right', position: 'relative', top: '4px' }}
                        disabled={isSaving}
                        onClick={handleSave}
                    >
                        Sav{isSaving ? 'ing...' : 'e'}
                    </NeutralButton>
                )}
                <input
                    style={{ width: 'calc(100% - 150px)' }}
                    contentEditable
                    className="name"
                    maxLength={50}
                    onChange={e => setWorkingMontage(m => m ? ({ ...m, Name: e.target.value }) : m)}
                    value={workingMontage?.Name ?? 'Loading...'}
                    disabled={!workingMontage}
                    onKeyDown={e => e.key === 'Enter' ? (e.target as HTMLInputElement).blur() : null}
                />
            </div>
            <div className="view">
                {workingMontage && (
                    <MontageHeadView
                        montage={workingMontage}
                        width={headWidth}
                        onChange={setWorkingMontage}
                        groupCnt={groupCnt}
                        selectedChannel={selectedChannel}
                        onSelectChannel={ch => setSelectedChannel(ch)}
                    />
                )}
                {workingMontage && (
                    <MontageChannelListView
                        height={headWidth * HEAD_HEIGHT_RATIO}
                        montage={workingMontage}
                        onChange={setWorkingMontage}
                        groupCnt={groupCnt}
                        onSetGroupCnt={setGroupCnt}
                        selectedChannel={selectedChannel}
                        onSelectChannel={ch => setSelectedChannel(ch)}
                    />
                )}
            </div>
        </div>
    )
}

export default MontageEditor
