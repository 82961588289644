// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

import OriginalDataCache from './OriginalDataCache'
import DataResponse from './DataResponse'
import DataRequest from './DataRequest'
import CustomMontageStorage from './CustomMontageStorage'
import CustomSettingsChange from './CustomSettingsChange'
import RequestedMinute from './RequestedMinute'
import * as WS from '../../../../constants/websocket'
import Peripherals from './Peripherals'
import SessionManager from '../../../../services/SessionManager'

/**
 * This class is responsible for interacting directly with the API over the web socket.  It
 * translates requests for data into messages that are sent to the API.  It manages the
 * responses from the API to requests and bundles the responses up for others to further process.
 * It also caches filtered and unfiltered data in order to return responses more quickly if the
 * data is in the cache.
 * @class DataSource
 */
class DataSource {
    constructor(webSocketPath, graphDataManager, montageTemplate) {
        if (!window.WebSocket) {
            console.warn('[GDM] websockets are not supported')
            return
        }
        this.attemptingWebsocketReconnect = false
        this.customSettingsChangeId = 0
        this.customSettingsChanges = {}
        this.dataRequests = []
        this.dataResponses = []
        this.graphDataManager = graphDataManager
        this.isAvailable = false
        this.lastOriginalLivePacketIndex = null
        this.minutesInRequestQueue = []
        this.montage = montageTemplate
        this.originalDataMontages = {}
        this.originalDataCache = new OriginalDataCache()
        this.redundantRequests = 0
        this.requestIdCounter = -1
        this.webwebSocketPath = ''
        this.websocketReconnectDelay = 1
        this.websocketReconnectionAttempts = 0
        this.processEventRecordingData = null

        // for now I'm saving all custom montages sent since it's a pain to determine when to delete them.
        this.customMontageStorage = new CustomMontageStorage()

        // Subscribe to the live data stream at the current point in the study
        this.openWebSocket(webSocketPath)
    }

    // #region Web Socket

    /**
     * Opens a web socket connection to the API
     *
     * @param {any} webSocketPath
     * @memberof DataSource
     */
    openWebSocket(webSocketPath) {
        this.webSocketPath = webSocketPath
        const authToken = SessionManager.get('mmt_auth_token')
        this.dataWebsocket = new WebSocket(webSocketPath, authToken)
        this.dataWebsocket.binaryType = 'arraybuffer'

        this.dataWebsocket.onopen = () => {
            this.graphDataManager.setIsLive(true)
            this.setIsAvailable(true)
            console.log('[GDM]WS] datasource websocket connected')
        }

        this.dataWebsocket.onclose = event => {
            this.graphDataManager.setIsLive(false)
            this.setIsAvailable(false)
            const closeReason = WS.OnCloseEventCodesFN[event.code] || 'unknown'
            console.log(
                `[GDM]WS] datasource websocket closed with code: ${event.code
                } - ${event.reason || closeReason}`,
            )
            if (event.code !== WS.CLOSE_NORMAL) {
                setTimeout(
                    () => this.reconnectWebSocket(),
                    this.websocketReconnectDelay * 1000,
                )
            }
        }

        this.dataWebsocket.onerror = () => {
            if (this.dataWebsocket.readyState !== WS.OPEN_STATE) {
                this.graphDataManager.setIsLive(false)
                this.setIsAvailable(false)
            }
        }

        this.dataWebsocket.onmessage = e => {
            switch (e.data.constructor) {
                case ArrayBuffer:
                    return this.routeBinaryResponse(e.data)
                case String:
                    return this.routeTextResponse(JSON.parse(e.data))
                default:
                    return console.error('[GDM] unhandled data type received')
            }
        }
    }

    closeWebSocket() {
        this.dataWebsocket.close()
    }

    reconnectWebSocket() {
        this.websocketReconnectDelay += 1
        this.websocketReconnectionAttempts += 1
        console.log(
            `[GDM]WS] websocket reconnection attempt ${this.websocketReconnectionAttempts
            }`,
        )
        this.openWebSocket(this.webSocketPath)
    }

    /**
     * This method sets the available property and alerts listeners that the data source is now
     * available for requests.
     *
     * @param {any} value
     * @memberof DataSource
     */
    setIsAvailable(value) {
        // set the property
        this.isAvailable = value

        // notify listeners
        this.graphDataManager.dataSourceAvailableChanged(value)
    }

    /**
     * returns a properly formatted websocket request for a data request object
     * @param {object} dr | DataRequest
     */
    getWebSocketRequestMsg(dr) {
        const msg = JSON.stringify({
            Message: WS.TextMessageType.CustomDataRequest.name,
            Body: {
                RequestId: this.requestIdCounter,
                StartMinute: dr.startMinute,
                EndMinute: dr.endMinute,
                PriorityMinute: dr.priorityMinute,
                FilterSettings: dr.customFilterSettings,
                MontageTemplateId: dr.montageTemplateId,
                SettingsChangeId: this.customSettingsChangeId,
            },
        })
        return msg
    }

    /**
     * 
     * @returns a properly formatted websocket request to clear custom data requests
     */
    getClearCustomDataRequestsMsg() {
        const msg = JSON.stringify({
            Message: WS.TextMessageType.ClearCustomDataRequests.name,
            Body: {
            },
        })
        return msg
    }

    // #endregionF

    // #region Requests

    // TODO: Create method for original data requests.  A separate method is necessary since the
    // custom requests use a settings change ID and the requests are optimized using a comparison
    // based on that value.

    /**
     * All requests for custom data should funnel through this method
     *
     * @param {DataRequest} dr
     */
    requestCustomData(dr) {
        let wasRequestSent = false

        // assign the settings change Id for comparison sake
        dr.settingsChangeId = this.customSettingsChangeId

        if (!this.isAvailable) {
            console.log('[GDM]DS] data request failed - datasource unavailable.')
            return wasRequestSent
        }

        if (this.areAllMinutesAlreadyRequested(dr)) {
            this.redundantRequests += 1
            console.log(
                `[GDM]DS] redundant request eliminated [total:${this.redundantRequests
                }]`,
            )
            return wasRequestSent
        }

        this.optimizeRequest(dr)
        wasRequestSent = this._requestCustomData(dr)
        wasRequestSent &&
            this.graphDataManager.electroGraph.addToMinutesRequestedIndices(dr)
        wasRequestSent &&
            this.addToMinutesInRequestQueue(
                dr.startMinute,
                dr.endMinute,
                dr.settingsChangeId,
            )
        return wasRequestSent
    }

    /**
     * Requests custom data with the given parameters.  The request will only be sent to the API if
     * the last request was different that this one.  Or if it was the same and the last one was
     * completed.
     * @param {DataRequest} DataRequest
     * @returns True if the request was made, False if the request was thrown out.
     * @memberof DataSource
     */
    _requestCustomData(dr) {
        this.createRequestResponseQueueEntries(dr)
        const customDataRequestMsg = this.getWebSocketRequestMsg(dr)
        this.dataRequests[dr.requestId].requestSentTimeStamp = Date.now()
        this.sendWebsocketMessage(customDataRequestMsg)

        console.log(
            `[GDM]DS] request #${this.requestIdCounter} custom data minutes: ${dr.startMinute
            } - ${dr.endMinute} [p:${dr.priorityMinute}]`,
        )
        return true
    }

    clearCustomDataRequests() {
        this._clearCustomDataRequests()
    }

    _clearCustomDataRequests() {
        this.dataRequests = []
        this.minutesInRequestQueue = []
        this.dataResponses = []
        const clearCustomDataRequestsMsg = this.getClearCustomDataRequestsMsg()
        this.sendWebsocketMessage(clearCustomDataRequestsMsg)
    }

    /**
     * This method creates a data response with the data that's requested.  If the cache has the
     * data, it will be returned in a response.  If only part of the data is in the cache, then the
     * part that isn't in the cache will be requested from the API and when it's returned, the full
     * data response will be sent back to the graph.
     *
     * The request will only be sent to the API if the last request was different that this one.
     * Or if it was the same and the last one was completed.
     * @param {any} startMinute
     * @param {any} endMinute
     * @param {any} priorityMinute
     * @returns
     * @memberof DataSource
     */
    _requestOriginalData(dr) {
        // TODO: change the code so the priority minute isn't requested if it's in the cache

        // only request minutes that you need from the given range...
        dr.startMinute = dr.startMinute === undefined ? 0 : dr.startMinute
        dr.endMinute = dr.endMinute === undefined ? 0 : dr.endMinute

        // flip the request if needed.
        if (dr.startMinute > dr.endMinute) {
            const temp = dr.startMinute
            dr.startMinute = dr.endMinute
            dr.endMinute = temp
        }

        const requestId = this.incrementRequestIdCounter()
        let requestData = false
        const dataResponse = new DataResponse(
            requestId,
            dr.startMinute,
            dr.endMinute,
            null,
            null,
        )

        // TODO: Revisit the logic that pulls data from the cache. -MRB

        // trim the minutes that we have on either side and just request the ones in the middle.
        // adjust the start minute
        for (let index = dr.startMinute; index < dr.endMinute; index += 1) {
            const filteredDataMinute = this.originalDataCache.getMinute(index)

            if (filteredDataMinute != null) {
                // add the data to the response
                dataResponse.addData(filteredDataMinute)
                dr.startMinute += 1
            } else {
                requestData = true
                break
            }
        }

        // let's find the last minute that needs to be requested.
        if (requestData) {
            for (
                let index = dr.endMinute - 1;
                index > dr.startMinute;
                index -= 1
            ) {
                const filteredDataMinute = this.originalDataCache.getMinute(
                    index,
                )
                if (filteredDataMinute != null) {
                    // add the data to the response
                    dataResponse.addData(filteredDataMinute)
                    dr.endMinute -= 1
                } else {
                    break
                }
            }
        }

        if (requestData) {
            const lastRequestTheSame = this.lastRequestTheSame(
                dr.startMinute,
                dr.endMinute,
                true,
                undefined,
                undefined,
            )

            // if the last request isn't a duplicate (if the last request was completed, then the request was removed from the list of requests.)
            requestData = !lastRequestTheSame
        } else {
            console.log('[GDM] blocked duplicate data request')
        }

        if (requestData) {
            // update the counter since we're actually making a request for data.
            this.requestIdCounter = requestId
            dataResponse.requestId = requestId

            this.dataRequests[requestId] = new DataRequest(
                true,
                dr.startMinute,
                dr.endMinute,
                null,
                null,
            )

            this.dataResponses[requestId] = dataResponse

            console.log(
                `[GDM]DS] request original data minutes: ${dr.startMinute} - ${dr.endMinute
                } [p:${dr.priorityMinute} id:${requestId}]`,
            )

            // make the request
            const jsonString = JSON.stringify({
                Message: WS.TextMessageType.OriginalDataRequest.name,
                Body: {
                    RequestId: requestId,
                    StartMinute: dr.startMinute,
                    EndMinute: dr.endMinute,
                    PriorityMinute: dr.priorityMinute,
                },
            })
            this.sendWebsocketMessage(jsonString)
        }

        return requestData
    }

    // ET-6370: InvalidStateError: Failed to execute 'send' on 'WebSocket': Still in CONNECTING stat
    // This method is an attempt to reduce occurrences of this error
    sendWebsocketMessage(msg, retryCnt = 0, wait = 500) {
        if (this.dataWebsocket.readyState !== WS.OPEN_STATE) {
            if (retryCnt > 5) {
                console.log('[GDM]DS] websocket not ready; max retry count exceeded')
                return
            }
            console.log(`[GDM]DS] websocket not ready; retry in ${wait}ms...`)
            setTimeout(() => this.sendWebsocketMessage(msg, retryCnt + 1, wait * 2), wait)
            return
        }
        this.dataWebsocket.send(msg)
    }

    /**
     * increases a request's start minute to the first minute not found in request queue
     * @param {object} timeRange
     */
    optimizeRequest(request) {
        const minutesRequested = this.getArrayOfRequestedMinutesForRange(
            request.startMinute,
            request.endMinute - 1,
            request.settingsChangeId,
        )
        const minutesNotInQueue = this.getMinutesNotInQueue(
            minutesRequested,
            this.minutesInRequestQueue,
        )

        if (minutesNotInQueue.length === 0) {
            return
        }

        request.startMinute = minutesNotInQueue[0].minute
        if (request.priorityMinute < request.startMinute) {
            request.priorityMinute = request.startMinute
        }
    }

    areAllMinutesAlreadyRequested(request) {
        if (Object.keys(this.dataRequests).length === 0) {
            return false
        }
        const minutesRequested = this.getArrayOfRequestedMinutesForRange(
            request.startMinute,
            request.endMinute - 1,
            request.settingsChangeId,
        )
        const minutesNotInQueue = this.getMinutesNotInQueue(
            minutesRequested,
            this.minutesInRequestQueue,
        )

        if (minutesNotInQueue.length === 0) {
            return true
        }
        return false
    }

    getArrayOfRequestedMinutesForRange(start, end, settingsChangeId) {
        const arrayOfMinutes = []

        for (let cnt = start; cnt <= end; cnt += 1) {
            arrayOfMinutes.push(new RequestedMinute(cnt, settingsChangeId))
        }

        return arrayOfMinutes
    }

    /**
     * creates an entry for the request and response queue arrays for the passed request object
     * @param {object} DataRequest
     */
    createRequestResponseQueueEntries(dr) {
        const requestId = this.incrementRequestIdCounter()
        const dataResponse = new DataResponse(
            this.requestIdCounter,
            dr.startMinute,
            dr.endMinute,
            dr.montageTemplateId,
            dr.customFilterSettings,
        )
        this.dataResponses[requestId] = dataResponse

        this.dataRequests[requestId] = dr
        this.dataRequests[requestId].requestId = requestId
        this.dataRequests[
            requestId
        ].settingsChangeId = this.customSettingsChangeId
        this.dataRequests[requestId].montageTemplateId = dr.montageTemplateId
    }

    // #endregion

    // #region Response Routing

    /**
     * This is called when binary data is received over the web socket.  This examines the data
     * header and sends it on to the proper data processor.  If the header contains invalid data,
     * the incoming data is ignored.
     *
     * @param {any} data
     * @memberof DataSource
     */
    routeBinaryResponse(data) {
        const firstInt = new Int32Array(data, 0, 1)
        const responseType = firstInt[0]

        // if this is original requested data...
        switch (responseType) {
            case 0x80:
                this.processOriginalRequestedData(data)
                break
            case 0x81:
                this.processOriginalLiveData(data)
                break
            case 0x82:
                this.processCustomRequestedData(data)
                break
            case 0x83:
                this.processCustomLiveData(data)
                break
            default:
                break
        }
    }

    /**
     * This is called when text data is received over the web socket.  This examines the data's
     * message type and processes it accordingly.  If the header contains invalid data, the
     * incoming text data is ignoted.
     *
     * @param {any} jsonMessage
     * @memberof DataSource
     */
    routeTextResponse(jsonMessage) {
        const messageType = jsonMessage.Message
        const body = jsonMessage.Body
        let montageType = null

        switch (messageType) {
            case WS.TextMessageType.OriginalRequestedMontage.name:
                this.originalDataMontages[body.Index] = body
                montageType = 'original'
                break

            case WS.TextMessageType.CustomRequestedMontage.name:
                this.customMontageStorage.addMontage(body)
                montageType = 'custom'
                break

            case WS.TextMessageType.Error.name:
                console.log(`[GDM] websocket error : ${jsonMessage.Message}`)
                break

            case WS.TextMessageType.StopRecordingEvents.name:
                if (this.processEventRecordingData) {
                    this.processEventRecordingData(body)
                }
                break

            default:
                console.log(
                    `[GDM] unhandled websocket message received: ${messageType}`,
                )
        }

        montageType &&
            console.log(
                `[GDM] received ${montageType} montage: ${body.Name} [${body.Index
                }]`,
            )
    }

    // #endregion

    // #region Response Processing

    setEventRecordingProcessor(processor) {
        this.processEventRecordingData = processor
    }

    /**
     * This method processes data that was requested by this remote viewer.  Original data is data as
     * it was originally recorded.
     *
     * @param {any} data
     * @returns
     * @memberof DataSource
     */
    processOriginalRequestedData(data) {
        const headerLength = 32 // 4 byte int + 4 * 7 ints
        const byteLength = data.byteLength

        const sevenDwarfs = new Int32Array(data, 4, 7)

        const requestId = sevenDwarfs[0]
        const montageIndex = sevenDwarfs[1]
        const minute = sevenDwarfs[2]
        const minutePart = sevenDwarfs[3]
        const totalMinuteParts = sevenDwarfs[4]
        const finalRecordingIndexOfMinute = sevenDwarfs[5]
        const finalRecordingPacketIndexOfMinute = sevenDwarfs[6]

        // if this is an empty response, then there isn't any data in it.  It just means that we
        // requested a range of data and the API didn't have the data.  Remove the request and
        // response.
        if (byteLength <= headerLength) {
            this.removeRequestResponse(requestId)
            return
        }

        // process the samples with the montage
        // I did this because the offset needed to be a multiple of 4 according to VS Code.
        // const packetBuffer = data.slice(30) // 7 * 4 byte integers + 2 byte short = 30
        const studySamples = this.getSamplesFromResponse(
            data,
            headerLength,
            this.originalDataMontages[montageIndex],
        )

        // add the data to the response
        this.dataResponses[requestId].addData(
            minute,
            minutePart,
            totalMinuteParts,
            finalRecordingIndexOfMinute,
            finalRecordingPacketIndexOfMinute,
            studySamples,
        )

        // if this is the last part of a minute of data...
        if (minutePart === totalMinuteParts) {
            if (minute in this.dataResponses[requestId].filteredDataMinutes) {
                // cache the minute
                const minuteToCache = this.dataResponses[requestId]
                    .filteredDataMinutes[minute]
                this.originalDataCache.addMinute(minuteToCache)

                // send the minute to the graph
                this.graphDataManager.processOriginalDataResponseMinute(
                    minuteToCache,
                )
            }
        }

        if (this.dataResponses[requestId].responseComplete) {
            console.log(`[GDM]DB] response for request #${requestId} completed`)
            this.removeRequestResponse(requestId)
        }
    }

    /**
     * This method processes live original data being sent to this remote viewer.
     *
     * @param {any} data
     * @memberof DataSource
     */
    processOriginalLiveData(data) {
        const header = new Int32Array(data, 4, 1)
        const montageIndex = header[0]

        // process the samples with the montage
        // I did this because the offset needed to be a multiple of 4 according to VS Code.
        // const packetBuffer = data.slice(6) // 1 * 4 byte integer + 2 byte short = 6
        const studySamples = this.getSamplesFromResponse(
            data,
            8,
            this.originalDataMontages[montageIndex],
        )

        // add all packets at once.
        if (studySamples.length > 0) {
            this.graphDataManager.addPackets(studySamples)
        }
    }

    /**
     * This method processes data that was requested by this remote viewer.  Custom data is data
     * that was filtered and montaged according to the values in the custom data request.
     *
     * @param {any} data
     * @returns
     * @memberof DataSource
     */
    processCustomRequestedData(data) {
        const headerLength = 36 // 4 byte int + 8 * 4 byte integers
        const byteLength = data.byteLength

        const header = new Int32Array(data, 4, 8)
        const requestId = header[0]
        const settingsChangeId = header[1]
        const montageIndex = header[2]

        const minute = header[3]
        const minutePart = header[4]
        const totalMinuteParts = header[5]
        const finalRecordingIndexOfMinute = header[6]
        const finalRecordingPacketIndexOfMinute = header[7]

        if (!this.dataResponses[requestId]) {
            return
        }
        // console.log(`[GDM] RequestId:${requestId} settingsChangeID:${settingsChangeId} MontageIndex:${montageIndex} Minute:${minute} MinPrt:${minutePart} TotMinPrts:${totalMinuteParts} FinRecIndexOfMin:${finalRecordingIndexOfMinute} FinRecPktIndexOfMin:${finalRecordingPacketIndexOfMinute}`)

        // if this is an empty response, then there isn't any data in it.  It just means that we
        // requested a range of data and the API didn't have the data.  Remove the request and
        // response.
        if (byteLength <= headerLength) {
            if (minute === -1) {
                this.removeRequestResponse(requestId)
                return
            }

            this.dataResponses[requestId].addData(
                minute,
                minutePart,
                totalMinuteParts,
                finalRecordingIndexOfMinute,
                finalRecordingPacketIndexOfMinute,
                null,
            )

            if (this.dataResponses[requestId].responseComplete) {
                this.removeRequestResponse(requestId)
            }

            return
        }

        const bufferLength = byteLength - headerLength

        // check settings and discard data if not found
        const settingsChange = this.customSettingsChanges[settingsChangeId]
        if (settingsChange === undefined) {
            console.log(
                '[GDM] received custom data not processed since settings have changed',
            )
            this.removeRequestResponse(requestId)
            return
        }

        // process the samples with the montage
        const samplesMontage = this.customMontageStorage.getMontage(
            settingsChange.montageTemplateId,
            montageIndex,
        )

        const sampleLength = samplesMontage.Channels.length * 4 + 12
        const sampleCount = bufferLength / sampleLength
        const studySamples = new Array(sampleCount)

        for (let i = 0; i < sampleCount; i += 1) {
            const studySample = this.getPacketFromBuffer(
                data,
                headerLength + i * sampleLength,
                samplesMontage.Channels.length,
                sampleLength,
            )

            // saving all for now, we may need to rethink this if an out of order packet is encountered -MRB
            studySamples[i] = studySample
        }

        this.graphDataManager.processCustomDataResponseMinute(
            studySamples,
            samplesMontage,
        )

        // add data to the response
        this.dataResponses[requestId].addData(
            minute,
            minutePart,
            totalMinuteParts,
            finalRecordingIndexOfMinute,
            finalRecordingPacketIndexOfMinute,
            studySamples,
        )

        if (this.dataResponses[requestId].responseComplete) {
            this.removeRequestResponse(requestId)
        }
    }

    processCustomLiveData(data) {
        const headerLength = 12 // 4 byte int + 2 * 4 byte integer
        const byteLength = data.byteLength

        const header = new Int32Array(data, 4, 2)
        const montageIndex = header[0]
        const settingsChangeId = header[1]

        const settingsChange = this.customSettingsChanges[settingsChangeId]

        // if the settings are still being used, process the data
        if (settingsChange !== undefined) {
            // process the samples with the montage
            const bufferLength = byteLength - headerLength
            const samplesMontage = this.customMontageStorage.getMontage(
                settingsChange.montageTemplateId,
                montageIndex,
            )
            const sampleLength = samplesMontage.Channels.length * 4 + 12
            const sampleCount = bufferLength / sampleLength
            const studySamples = new Array(sampleCount)

            // TODO: I'll have to change the last packet index since it is being used in this and in the requested original data -MRB
            for (let i = 0; i < sampleCount; i += 1) {
                const studySample = this.getPacketFromBuffer(
                    data,
                    headerLength + i * sampleLength,
                    samplesMontage.Channels.length,
                    sampleLength,
                )

                if (
                    this.lastOriginalLivePacketIndex &&
                    studySample.Index > 1 &&
                    studySample.Index !== this.lastOriginalLivePacketIndex + 1
                ) {
                    // console.log(`[GDM] Out of order sample. Last index is ${this.lastOriginalLivePacketIndex} and current study sample is ${studySample.Index}`)
                }

                studySamples[i] = studySample
                this.lastOriginalLivePacketIndex = studySample.Index
            }

            // TODO: I'll have to let the graph know that these packets are custom packets with a given montage template ID so it knows whether to draw them or not.
            // add all packets at once.
            if (sampleCount > 0) {
                this.graphDataManager.addPackets(studySamples)
            }
        } else {
            console.log(
                '[GDM] live custom data not processed since settings have changed',
            )
        }
    }

    // #endregion

    // #region Event Handlers

    handleSettingsChange(filterSettings, montageTemplateId) {
        let consoleMsg = '[GDM] graph settings have changed ('

        // remove the old settings change
        if (this.customSettingsChangeId !== 0) {
            delete this.customSettingsChanges[this.customSettingsChangeId]
            consoleMsg += `removed #${this.customSettingsChangeId}, `
        }

        // add the new settings change
        this.customSettingsChangeId += 1
        const customSettingsChange = new CustomSettingsChange(
            this.customSettingsChangeId,
            filterSettings,
            montageTemplateId,
        )
        this.customSettingsChanges[
            customSettingsChange.changeId
        ] = customSettingsChange
        consoleMsg += `added #${this.customSettingsChangeId})`

        const isInitial = this.customSettingsChangeId === 1
        !isInitial && console.log(consoleMsg)
    }

    // #endregion

    // #region Utility

    addToMinutesInRequestQueue(start, end, settingsChangeId) {
        const msg = this.getArrayOfRequestedMinutesForRange(
            start,
            end - 1,
            settingsChangeId,
        ).toString()
        for (let cnt = start; cnt < end; cnt += 1) {
            this.minutesInRequestQueue.push(
                new RequestedMinute(cnt, settingsChangeId),
            )
        }
        console.log(
            `[GDM]DS] added minutes:${msg} to queue:`,
            this.minutesInRequestQueue.toString(),
        )
    }

    removeFromMinutesInRequestQueue(start, end, settingsChangeId) {
        const minutesToRemove = this.getArrayOfRequestedMinutesForRange(
            start,
            end,
            settingsChangeId,
        )
        this.minutesInRequestQueue = this.filterMinutesFromArray(
            minutesToRemove,
            this.minutesInRequestQueue,
        )
        console.log(
            `[GDM]DS] removed minutes:${minutesToRemove.toString()} from queue:`,
            this.minutesInRequestQueue.toString(),
        )
    }

    filterMinutesFromArray(minutes, array) {
        array = array.filter(
            a =>
                minutes.findIndex(
                    m =>
                        m.minute === a.minute &&
                        m.settingsChangeId === a.settingsChangeId,
                ) < 0,
        )
        return array
    }

    getMinutesNotInQueue(minutesRequested, minutesInRequestQueue) {
        const minutesNotInQueue = minutesRequested.filter(
            a =>
                minutesInRequestQueue.findIndex(
                    m =>
                        m.minute === a.minute &&
                        m.settingsChangeId === a.settingsChangeId,
                ) < 0,
        )
        return minutesNotInQueue
    }

    incrementRequestIdCounter() {
        this.requestIdCounter += 1
        return this.requestIdCounter
    }

    /**
     * Removes the data requests and responses given the requestId.
     *
     * @param {any} requestId
     * @memberof DataSource
     */
    removeRequestResponse(requestId) {
        const dr = this.dataRequests[requestId]

        if (dr) {
            const requestResponseTime =
                (Date.now() - dr.requestSentTimeStamp) / 1000
            delete this.dataRequests[requestId]
            delete this.dataResponses[requestId]
            this.removeFromMinutesInRequestQueue(
                dr.startMinute,
                dr.endMinute - 1,
                dr.settingsChangeId,
            ) // endminute is not inclusive

            console.log(`[GDM]DS] removed request-response #${requestId}`)
            console.log(
                `[GDM]WS] request #${requestId} took ${requestResponseTime}s`,
            )
            this.isDataRequestQueueEmpty() &&
                console.info('[GDM]DS] data request queue is now empty')
        }
    }

    isDataRequestQueueEmpty() {
        return Object.keys(this.dataRequests).length === 0
    }

    getSamplesFromResponse(packetBuffer, offset, samplesMontage) {
        const bufferLength = packetBuffer.byteLength - offset
        const studySamples = []
        const sampleLength = samplesMontage.Channels.length * 4 + 12
        const sampleCount = bufferLength / sampleLength
        let previousPacketIndex = null

        for (let i = 0; i < sampleCount; i += 1) {
            const studySample = this.getPacketFromBuffer(
                packetBuffer,
                offset + i * sampleLength,
                samplesMontage.Channels.length,
                sampleLength,
            )

            if (
                previousPacketIndex &&
                studySample.Index > 1 &&
                studySample.Index !== previousPacketIndex + 1
            ) {
                console.log(`[GDM] warning : out of order sample : previousPacketIndex=${previousPacketIndex}, studySample.Index=${studySample.Index}`)
            }

            // saving all for now, we may need to rethink this if an out of order packet is encountered -MRB
            studySamples.push(studySample)
            previousPacketIndex = studySample.Index
        }
        return studySamples
    }

    /**
     * Compares the last data request in the array of requests to see if its properties are the same as the ones given.
     * @param {any} startMinute
     * @param {any} endMinute
     * @param {any} originalData
     * @param {any} settingsChangeId
     * @param {any} montageTemplateId
     * @returns True if all the properties match, False if any property doesn't match
     * @memberof DataSource
     * */
    lastRequestTheSame(
        startMinute,
        endMinute,
        originalData,
        settingsChangeId,
        montageTemplateId,
    ) {
        const lastRequest = this.dataRequests[this.requestIdCounter]
        let equal = false

        if (lastRequest !== undefined) {
            if (
                lastRequest.startMinute === startMinute &&
                lastRequest.endMinute === endMinute &&
                lastRequest.originalData === originalData &&
                lastRequest.montageTemplateId === montageTemplateId
            ) {
                if (
                    (lastRequest.settingsChangeId === undefined &&
                        settingsChangeId !== undefined) ||
                    (lastRequest.settingsChangeId !== undefined &&
                        settingsChangeId === undefined)
                ) {
                    equal = false
                } else if (
                    lastRequest.settingsChangeId === undefined &&
                    settingsChangeId === undefined
                ) {
                    equal = true
                } else {
                    equal = lastRequest.settingsChangeId === settingsChangeId
                }
            }
        }

        return equal
    }

    getHexStringFromUint8(value) {
        return `0${value.toString(16)}`.slice(-2)
    }

    /**
     * Parses the given data array according to the parameters and returns a packet if the data is
     * present.
     * @param {any} data
     * @param {Number} offset
     * @param {Number} channelCount
     * @param {Number} sampleLength
     * @returns
     * @memberof DataSource
     */
    getPacketFromBuffer(data, offset, channelCount, sampleLength) {
        const [RecordingIndex, Index] = new Int32Array(data, offset, 2)
        const Inputs = new Float32Array(data, offset + 8, channelCount)
        const periphByte = new Uint8Array(data, offset + sampleLength - 1, 1)[0]
        const Periphs = new Peripherals(periphByte)

        return { RecordingIndex, Index, Inputs, Periphs }
    }

    // #endregion
}

export default DataSource
