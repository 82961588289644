import React from 'react'
import AmplifiersApi from '../../services/AmplifiersApi'
import AmplifierForm from './AmplifierForm'
import FacilitiesApi from '../../services/FacilitiesApi'
import Validation from '../../services/Validation'
import SessionManager from '../../services/SessionManager'
import NavUtils from '../../utils/NavUtils'
import AppContext from '../../components/AppContext'
import ToastUtils from '../../utils/ToastUtils'
import AmplifierType from '../../types/AmplifierType'
import Facility from '../../types/Facility'
import Amplifier, { emptyAmplifer, AmplifierField } from '../../types/Amplifier'
import AmplifierAuth from '../../auth/AmplifierAuth'
import { hasRequiredRole } from '../../services/Auth'
import { SUPPORTED_AMP_TYPE_IDS, AMP_FRIENDLY_NAMES } from '../../constants/amplifiertypes'
import { Button } from '@mui/material'

const length_validation = {
    Name: {
        min: 1,
        max: 50,
    },
    SerialNumber: {
        min: 1,
        max: 16,
    },
    BluetoothID: {
        min: 0,
        max: 50,
    },
    UsbID: {
        min: 0,
        max: 50,
    },
}

interface Props {
    creating: boolean
    match: any
}

interface State {
    amplifier: Amplifier,
    initial_amplifier: Amplifier
    value_changed: {
        Name: boolean
        FacilityID: boolean
        SerialNumber: boolean
        AmplifierTypeID: boolean
        BluetoothID: boolean
        Enabled: boolean,
        UsbID: boolean
    }
    validation: {
        Name: boolean
        SerialNumber: boolean
        AmplifierTypeID: boolean
        BluetoothID: boolean
        UsbID: boolean
    }
    amplifier_types: AmplifierType[]
    facilitiesList: Facility[]
    is_saving: boolean
    is_loading: boolean
    has_success: boolean
    has_error: boolean
}

class AmplifierView extends React.Component<Props, State> {
    static contextType = AppContext

    constructor(props: Props) {
        super(props)
        this.state = {
            amplifier: emptyAmplifer(),
            initial_amplifier: emptyAmplifer(),
            value_changed: {
                Name: false,
                FacilityID: false,
                SerialNumber: false,
                AmplifierTypeID: false,
                BluetoothID: false,
                Enabled: false,
                UsbID: false,
            },
            validation: {
                Name: true,
                SerialNumber: true,
                AmplifierTypeID: true,
                BluetoothID: true,
                UsbID: true,
            },
            amplifier_types: [],
            facilitiesList: [],
            is_saving: false,
            is_loading: true,
            has_success: false,
            has_error: false,
        }
    }

    componentDidMount() {
        NavUtils.setPageTitle(this.getTitle())

        AmplifiersApi.getAmplifierTypes().then(response => {
            const allTypes: AmplifierType[] = response.data
            const supportedTypes = allTypes.filter(a => SUPPORTED_AMP_TYPE_IDS.includes(a.ID))
            this.setAmplifierTypes(supportedTypes)
        })

        if (!this.props.creating) {
            AmplifiersApi.getAmplifier(this.props.match.params.id)
                .then(amplifier => {
                    this.setAmplifier(amplifier.data)
                })
                .catch(error => {
                    const isNotFound = error.status === 404
                    const isBadRequest = error.status === 400
                    if (isNotFound || isBadRequest) {
                        window.location.href = '/404'
                    }
                    ToastUtils.error({ message: error.data.Message || error.statusText })
                })
        } else {
            this.newAmplifier()
        }

        this.resetFacilityField()
    }

    getTitle = () => {
        if (this.props.creating) {
            return 'Create Amplifier'
        }
        return 'Edit Amplifier'
    }

    setAmplifier = (amplifier: Amplifier) => {
        this.setState({
            amplifier,
            initial_amplifier: amplifier,
            is_loading: false,
        })
    }

    setAmplifierTypes = (amplifierTypes: AmplifierType[]) => {
        amplifierTypes.forEach(a => a.Name = AMP_FRIENDLY_NAMES[a.ID] || a.Name)
        amplifierTypes.unshift({ ID: 0, Name: 'Please select type', Description: null })
        this.setState({
            amplifier_types: amplifierTypes,
        })
    }

    handleChange = (field: AmplifierField, value: string | boolean | number) => {
        const amplifier = {
            ...this.state.amplifier,
            [field]: value,
        }
        this.setState(
            {
                amplifier,
            },
            () => {
                this.hasAmplifierChanged(field)
            },
        )
    }

    hasAmplifierChanged = (field: AmplifierField) => {
        this.setState({
            value_changed: {
                ...this.state.value_changed,
                [field]: this.hasAmplifierValueChanged(field),
            },
        })
    }

    hasAmplifierValueChanged = (field: AmplifierField) => {
        // if one field is null, then we can't call toString()
        if (
            Validation.isNullOrEmpty(this.state.initial_amplifier[field]) ||
            Validation.isNullOrEmpty(this.state.amplifier[field])
        ) {
            // if both are empty or null, then we didn't change
            if (
                Validation.isNullOrEmpty(this.state.initial_amplifier[field]) &&
                Validation.isNullOrEmpty(this.state.amplifier[field])
            )
                return false

            // otherwise, something changed
            return true
        }

        return (
            this.state.initial_amplifier[field]?.toString().trim() !==
            this.state.amplifier[field]?.toString().trim()
        )
    }

    handleSubmit = () => {
        const amplifier = { ...this.state.amplifier }

        if (amplifier.Name) amplifier.Name = amplifier.Name.trim()
        if (amplifier.SerialNumber) amplifier.SerialNumber = amplifier.SerialNumber.trim()
        if (amplifier.BluetoothID) amplifier.BluetoothID = amplifier.BluetoothID.trim()
        if (amplifier.UsbID) amplifier.UsbID = amplifier.UsbID.trim()

        this.setState({ amplifier, is_saving: true }, () => {
            if (!this.isFormValid()) {
                this.setState({ is_saving: false })
                return
            }

            if (!this.props.creating) {
                this.updateAmplifier()
            } else {
                this.createAmplifier()
            }
        })
    }

    newAmplifier = () => {
        this.setState({
            is_loading: false,
        })
    }

    isFormValid = () => {
        let valid = true
        let Name = true
        let SerialNumber = true
        let BluetoothID = true
        let UsbID = true
        let AmplifierTypeID = true

        if (
            !this.state.amplifier.Name ||
            this.state.amplifier.Name.length < length_validation.Name.min ||
            this.state.amplifier.Name.length > length_validation.Name.max
        ) {
            valid = false
            Name = false
        }

        if (!this.state.amplifier.AmplifierTypeID || this.state.amplifier.AmplifierTypeID === 0) {
            valid = false
            AmplifierTypeID = false
        }

        if (
            !this.state.amplifier.SerialNumber ||
            this.state.amplifier.SerialNumber.length < length_validation.SerialNumber.min ||
            this.state.amplifier.SerialNumber.length > length_validation.SerialNumber.max
        ) {
            valid = false
            SerialNumber = false
        }

        if (
            this.state.amplifier.BluetoothID &&
            (this.state.amplifier.BluetoothID.length < length_validation.BluetoothID.min ||
                this.state.amplifier.BluetoothID.length > length_validation.BluetoothID.max)
        ) {
            valid = false
            BluetoothID = false
        }

        if (
            this.state.amplifier.UsbID &&
            (this.state.amplifier.UsbID.length < length_validation.UsbID.min ||
                this.state.amplifier.UsbID.length > length_validation.UsbID.max)
        ) {
            valid = false
            UsbID = false
        }

        this.setState({
            validation: {
                ...this.state.validation,
                Name,
                SerialNumber,
                AmplifierTypeID,
                BluetoothID,
                UsbID,
            },
            has_success: false,
            has_error: false,
        })

        return valid
    }

    createAmplifier() {
        AmplifiersApi.createAmplifier(
            this.state.amplifier.FacilityID,
            this.state.amplifier,
        )
            .then(() => {
                this.setState({ is_saving: false })
                ToastUtils.success({ message: 'Amplifier created!' })
            })
            .then(() => {
                this.resetState()
                this.resetFacilityField()
            })
            .catch(error => {
                this.setState({ is_saving: false })
                ToastUtils.error({ message: error.data.Message || error.statusText })
            })
    }

    updateAmplifier() {
        let req = AmplifiersApi.updateAmplifierName
        if (hasRequiredRole(this.context.Roles, AmplifierAuth.EditAll)) {
            req = AmplifiersApi.updateAmplifierAllFields
        }
        req(this.props.match.params.id, this.state.amplifier).then(() => {
            this.setState({
                is_saving: false,
                initial_amplifier: this.state.amplifier,
                value_changed: {
                    Name: false,
                    FacilityID: false,
                    SerialNumber: false,
                    AmplifierTypeID: false,
                    BluetoothID: false,
                    Enabled: false,
                    UsbID: false,
                },
            })
            ToastUtils.success({ message: 'Amplifier saved!' })
        }).catch(error => {
            this.setState({ is_saving: false })
            ToastUtils.error({ message: error.data.Message || error.statusText })
        })
    }

    resetFacilityField() {
        const currentFacility = SessionManager.get('mmt_facility_id')

        FacilitiesApi.getFacilities().then(response => {
            if (this.props.creating) {
                this.setState({
                    facilitiesList: response.data,
                    amplifier: {
                        ...this.state.amplifier,
                        FacilityID: currentFacility,
                    },
                })
            } else {
                this.setState({
                    facilitiesList: response.data,
                    amplifier: {
                        ...this.state.amplifier,
                        FacilityID: this.state.amplifier.FacilityID,
                    },
                })
            }
        })
    }

    resetState = () => {
        const validation = {
            Name: true,
            SerialNumber: true,
            AmplifierTypeID: true,
            BluetoothID: true,
            UsbID: true,
        }

        this.setState({
            ...this.state,
            amplifier: emptyAmplifer(),
            validation,
            is_saving: false,
            value_changed: {
                Name: false,
                FacilityID: false,
                SerialNumber: false,
                AmplifierTypeID: false,
                BluetoothID: false,
                Enabled: false,
                UsbID: false,
            },
        })
    }

    render() {
        const has_amplifier_changed = Object.values(
            this.state.value_changed,
        ).some(element => element === true)

        const amplifierTypes = [...this.state.amplifier_types]
        if (!amplifierTypes.some(t => t.ID === this.state.amplifier.AmplifierTypeID)) {
            amplifierTypes.push({
                ID: this.state.amplifier.AmplifierTypeID,
                Name: AMP_FRIENDLY_NAMES[this.state.amplifier.AmplifierTypeID],
                Description: null,
            })
        }

        return (
            <React.Fragment>
                {!this.state.is_loading && (
                    <div className="col-xl-4 col-lg-6 px-0">
                        <h3>
                            <i className="icon-electrotek-amplifier" />{' '}
                            {this.getTitle()}
                        </h3>
                        <React.Fragment>
                            <AmplifierForm
                                amplifier={this.state.amplifier}
                                validationRules={length_validation}
                                validation={this.state.validation}
                                facilitiesList={this.state.facilitiesList}
                                amplifierTypes={amplifierTypes}
                                handleChange={this.handleChange}
                                creating={this.props.creating}
                            />

                            <Button
                                disabled={this.state.is_saving || !has_amplifier_changed}
                                onClick={this.handleSubmit}
                                sx={{ float: 'right' }}
                            >
                                {this.state.is_saving ? 'Saving...' : 'Save'}
                            </Button>
                        </React.Fragment>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default AmplifierView
