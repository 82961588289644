import axios from 'axios'
import SessionManager from './SessionManager'

const MMTApi = {
    getAPIVersion() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Meta/ApiVersion`,
            MMTApi.getRequestHeaders(),
        )
    },

    getHeaders() {
        return {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    },

    getRequestHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${SessionManager.get('mmt_auth_token')}`,
            },
        }
    },
}

export default MMTApi
