import React, { useState } from 'react'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from 'reactstrap'

interface Filter {
    type: string
    value: string | number
    friendlyName?: string
}

interface Props {
    filters: Filter[]
    onClearFilters: (value: string) => void
    onAddFilter: (filter: Filter) => void
}

const FilterDropDowns: React.FC<Props> = props => {
    const [openedDropdown, setOpenedDropdown] = useState<string | null>(null)

    const toggle = (dropdown: string) => {
        if (openedDropdown === dropdown) {
            setOpenedDropdown(null)
        } else {
            setOpenedDropdown(dropdown)
        }
    }

    const itemClass = (filterValue: string) => {
        let className = 'small mb-1'
        if (props.filters.some(f => f.value === filterValue)) {
            className += ' dropdown-item-checked'
        }
        return className
    }

    const renderStatus = () => {
        const isOpen = openedDropdown === 'status'
        const currentFilter = props.filters.filter(
            f => f.type === 'status',
        )
        const isFiltered = currentFilter.length > 0
        const title = isFiltered
            ? `${currentFilter[0].friendlyName ||
                  (currentFilter[0].value === 'InProgress'
                      ? 'In Progress'
                      : 'Pending Review')}`
            : 'Any Status '
        const titleClass = `filter-control ${isFiltered && 'has-filter'}`

        return (
            <Dropdown isOpen={isOpen} toggle={() => toggle('status')}>
                <DropdownToggle nav className="filterdd">
                    <span className={titleClass}>
                        {title}
                        <i className="fa fa-sort-down" />
                    </span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-lg">
                    <DropdownItem
                        onClick={() => props.onClearFilters('status')}
                    >
                        <div
                            className={`small mb-1 ${!isFiltered &&
                                'dropdown-item-checked'}`}
                        >
                            Any Status
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'status',
                                value: 'InProgress',
                                friendlyName: 'In Progress',
                            })
                        }
                    >
                        <div className={itemClass('InProgress')}>
                            In Progress
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'status',
                                value: 'PendingReview',
                                friendlyName: 'Pending Review',
                            })
                        }
                    >
                        <div className={itemClass('PendingReview')}>
                            Pending Review
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'status',
                                value: 'ReportComplete',
                                friendlyName: 'Report Complete',
                            })
                        }
                    >
                        <div className={itemClass('ReportComplete')}>
                            Report Complete
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }

    const renderTime = () => {
        const isOpen = openedDropdown === 'time'
        const currentFilter = props.filters.filter(f => f.type === 'time')
        const isFiltered = currentFilter.length > 0
        const title = isFiltered
            ? `Past ${currentFilter[0].value}`
            : 'Any Time '
        const titleClass = `filter-control ${isFiltered && 'has-filter'}`

        return (
            <Dropdown isOpen={isOpen} toggle={() => toggle('time')}>
                <DropdownToggle nav className="filterdd">
                    <span className={titleClass}>
                        {title}
                        <i className="fa fa-sort-down" />
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg">
                    <DropdownItem
                        onClick={() => props.onClearFilters('time')}
                    >
                        <div
                            className={`small mb-1 ${!isFiltered &&
                                'dropdown-item-checked'}`}
                        >
                            Any time
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'time',
                                value: 'hour',
                            })
                        }
                    >
                        <div className={itemClass('hour')}>Past hour</div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'time',
                                value: 'day',
                            })
                        }
                    >
                        <div className={itemClass('day')}>Past 24 hours </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'time',
                                value: 'week',
                            })
                        }
                    >
                        <div className={itemClass('week')}>Past week</div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'time',
                                value: 'month',
                            })
                        }
                    >
                        <div className={itemClass('month')}>Past month</div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'time',
                                value: 'year',
                            })
                        }
                    >
                        <div className={itemClass('year')}>Past year </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }

    const renderSync = () => {
        const isOpen = openedDropdown === 'sync'
        const currentFilter = props.filters.filter(f => f.type === 'sync')
        const isFiltered = currentFilter.length > 0
        const title = isFiltered
            ? `${currentFilter[0].friendlyName}`
            : 'Any Sync State '
        const titleClass = `filter-control ${isFiltered && 'has-filter'}`

        return (
            <Dropdown isOpen={isOpen} toggle={() => toggle('sync')}>
                <DropdownToggle nav className="filterdd">
                    <span className={titleClass}>
                        {title}
                        <i className="fa fa-sort-down" />
                    </span>
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu-lg">
                    <DropdownItem
                        onClick={() => props.onClearFilters('sync')}
                    >
                        <div
                            className={`small mb-1 ${!isFiltered &&
                                'dropdown-item-checked'}`}
                        >
                            Any Sync State
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'sync',
                                value: 0,
                                friendlyName: 'Sync Incomplete',
                            })
                        }
                    >
                        <div className={itemClass('0')}>
                            Synchronization Incomplete
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'sync',
                                value: 1,
                                friendlyName: 'Sync Complete',
                            })
                        }
                    >
                        <div className={itemClass('1')}>
                            Synchronization Completed
                        </div>
                    </DropdownItem>

                    <DropdownItem
                        onClick={() =>
                            props.onAddFilter({
                                type: 'sync',
                                value: 2,
                                friendlyName: 'Sync Failed',
                            })
                        }
                    >
                        <div className={itemClass('2')}>
                            Synchronization Failed
                        </div>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }

    return (
        <div className="row col-lg-12 studylist-filter-controls">
            {renderStatus()}
            {renderTime()}
            {renderSync()}
            {props.filters.length > 0 ? (
                <i
                    title="Clear filters"
                    className="fas fa-fw fa-times filter-icon"
                    onClick={() => props.onClearFilters('all')}
                />
            ) : (
                <i className="fas fa-fw fa-filter filter-icon" />
            )}
        </div>
    )
}

export default FilterDropDowns
