import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import FacilitiesApi from '../../services/FacilitiesApi'
import DevicesApi from '../../services/DevicesApi'
import ToastUtils from '../../utils/ToastUtils'
import SelectGroup from '../../components/forms/SelectGroup'
import SortUtils from '../../utils/SortUtils'

class AddFacilityCameraModal extends React.Component {

    state = {
        is_saving: false,
        selected_camera: null,
        selected_device: null,
        camera_options: [{ name: 'Wait.. Loading options...' }],
        device_options: [{ name: 'Wait.. Loading options...' }],
        supported_cameras: [],
        devices: [],
        disable_device_selection: false,
        excluded_cameras: this.props.excluded_cameras,
        facility_only_cameras: this.props.facility_only_cameras,
    }


    componentDidMount() {
        FacilitiesApi.getSupportedNetworkCameras()
            .then(response => {
                this.setSupportedCameras(response.data)
            })
            .catch((err) => {
                ToastUtils.error({ message: 'Unable to get supported cameras' })
            })

        DevicesApi.getDevices()
            .then(response => {
                this.setDevices(response.data)
            })
            .catch((err) => {
                ToastUtils.error({ message: 'Unable to get facility devices' })
            })
    }

    setSupportedCameras(supported_cameras) {
        const excluded_cameras = this.state.excluded_cameras
        let camera_options = supported_cameras.map(c => ({ id: c.ID, name: c.Name, value: c.ID }))
        camera_options = camera_options.filter(c => !excluded_cameras.includes(c.name))
        camera_options.unshift({ id: '', name: 'Select camera' })
        this.setState({ supported_cameras, camera_options })
    }

    setDevices(devices) {
        const device_options = devices.map(c => ({ id: c.ID, name: c.Name, value: c.ID }))
        SortUtils.string(device_options, 'name', 'asc')
        device_options.unshift({ id: '', name: 'Select device (optional)' })
        this.setState({ devices, device_options })
    }

    handleChange = (field, value) => {
        this.setState({ [field]: value })
        if (field === 'selected_camera') {
            const isFacilityOnly = this.state.camera_options.filter(c => c.id === value &&
                this.props.facility_only_cameras.includes(c.name)).length > 0
            this.setState({ disable_device_selection: isFacilityOnly })
        }
    }

    handleAddCamera = () => {

        const camera = this.state.selected_camera
        const device = this.state.selected_device

        this.setState({ is_saving: true })

        FacilitiesApi.addFacilityNetworkCamera(camera, device)
            .then(() => this.props.handleSuccess())
            .catch((error) => {
                this.setState({ is_saving: false })
                ToastUtils.error({ message: error.data?.Message || error.message || error.statusText })
            })
    }

    render() {
        const camera = this.state.selected_camera
        const device = this.state.selected_device
        const disableDeviceSelection = this.state.disable_device_selection
        const canSave = !!camera && camera !== 'Select camera'
        const deviceOptions = disableDeviceSelection ? [{ id: 1, name: 'Facility Assignable Only', value: 1 }] : this.state.device_options

        return (
            <Modal isOpen toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    Create New Facility Camera
                </ModalHeader>
                <ModalBody>
                    <div className="container w-100">
                        <div className="row">
                            <div className="col">
                                <SelectGroup
                                    field="selected_camera"
                                    label="Supported Cameras"
                                    options={this.state.camera_options}
                                    value={camera || ''}
                                    handleChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <SelectGroup
                                    field="selected_device"
                                    label="Associated Device"
                                    options={deviceOptions}
                                    value={device || ''}
                                    handleChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outlined" onClick={this.props.toggle}>
                        Cancel
                    </Button>
                    <Button
                        onClick={this.handleAddCamera}
                        disabled={!this.state.is_saving && !canSave}
                        sx={{ ml: 2 }}
                    >
                        {this.state.is_saving ? 'Creating...' : 'Create'}
                    </Button>
                </ModalFooter>
            </Modal >
        )
    }
}

export default AddFacilityCameraModal
