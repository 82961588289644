import { getRGBAFromHexARGB } from './ColorUtilities'
import Theme from '../../../types/Theme'
import Montage from '../../../types/Montage'

export function getChannelColor(channelIndex: number, montage: Montage, theme: Theme): string {
    let color = ''
    const channelTypeId = montage.Channels[channelIndex].ChannelTypeID

    if (montage.Name === 'BioCalibration') {
        const group = montage.Channels[channelIndex].Group
        if (group === 1) {
            color = getRGBAFromHexARGB(theme.BioLeftTemporalColor)
        } else if (group === 2) {
            color = getRGBAFromHexARGB(theme.BioRightParasagittalColor)
        } else if (group === 3) {
            color = getRGBAFromHexARGB(theme.BioMidlineColor)
        } else if (group === 4) {
            color = getRGBAFromHexARGB(theme.BioRightParasagittalColor)
        } else if (group === 5) {
            color = getRGBAFromHexARGB(theme.BioRightTemporalColor)
        } else if (group === 0) {
            color = getColorByChannelType(theme, channelTypeId)
        }
    } else {
        color = getColorByChannelType(theme, channelTypeId)
    }

    if (!color) {
        color = getRGBAFromHexARGB(theme.ManualPulseColor)
    }

    return color
}

function getColorByChannelType(theme: Theme, channelTypeId: number): string {
    const themeChannelType = theme.ThemeChannelTypes.find(t => t.ChannelTypeID === channelTypeId)
    if (themeChannelType) {
        return getRGBAFromHexARGB(themeChannelType.Color)
    }
    return ''
}