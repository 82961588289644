import axios from 'axios'
import MMTApi from './MMTApi'
import Amplifier from '../types/Amplifier'

class AmplifiersApi {
    static getAmplifiers() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Amplifiers/GetAmplifiers`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getAmplifierTypes() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Amplifiers/GetAmplifierTypes`,
            MMTApi.getRequestHeaders(),
        )
    }

    static createAmplifier(guid: string, data: Partial<Amplifier>) {
        if (data.ID === '') delete data['ID']
        return axios.post(
            `${
                process.env.REACT_APP_API_URL
            }/api/Amplifiers/CreateAmplifier/?facilityId=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    }

    static updateAmplifierAllFields(guid: string, data: Amplifier) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/Amplifiers/?id=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    }

    static updateAmplifierName(amplifierId: string, amp: Amplifier) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Amplifiers/UpdateAmplifierName?amplifierId=${amplifierId}&newName=${amp.Name}`,
            {},
            MMTApi.getRequestHeaders(),
        )
    }

    static getAmplifier(guid: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Amplifiers/${guid}`,
            MMTApi.getRequestHeaders(),
        )
    }
}

export default AmplifiersApi
