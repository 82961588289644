import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { hasRequiredRole, Auth } from '../services/Auth'
import AppContext from './AppContext'

interface Props {
    component?: string | React.FunctionComponent<any> | React.ComponentClass<any, any>
    render?: (props: any) => any
    authorizedRoles?: string[]
    [key: string]: any
}

type PrivateRouteFunc = (props: Props) => any

const PrivateRoute: PrivateRouteFunc = ({ component, render, authorizedRoles, ...rest }) => (
    <AppContext.Consumer>
        {context => {
            function customRender(props: any) {
                if (!Auth.loggedIn()) {
                    return (window.location.href = `/Login${
                        window.location.pathname
                            ? `?l=${window.location.pathname +
                                  window.location.search}`
                            : ''
                    }`)
                }

                if (
                    authorizedRoles &&
                    !hasRequiredRole(authorizedRoles, context.Roles)
                ) {
                    return <Redirect to="/" />
                }

                if (typeof component !== 'undefined') {
                    return React.createElement(component, props)
                }
                if (typeof render !== 'undefined') {
                    return render({ ...props })
                }
            }

            return <Route {...rest} render={customRender} />
        }}
    </AppContext.Consumer>
)

export default PrivateRoute
