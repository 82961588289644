import axios from 'axios'
import MMTApi from './MMTApi'

const DevicesApi = {
    getDevices() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Devices/GetDevices`,
            MMTApi.getRequestHeaders(),
        )
    },

    createDevice(guid, data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/api/Devices/CreateDevice/?facilityId=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    updateDevice(guid, data) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/Devices/?id=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    getDevice(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Devices/${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },


    getDeviceForStudy(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Studies/GetStudyDevice/?studyId=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default DevicesApi
