import React from 'react'
import InputGroup from '../../components/forms/InputGroup'
import DateTimePicker from '../../components/forms/DateTimePicker'
import SelectGroup from '../../components/forms/SelectGroup'

// type Rule = {
//     min: number,
//     max: number,
// }

// type ValidationRules = {
//     FirstName: Rule,
//     MiddleName: Rule,
//     LastName: Rule,
//     PatientID: Rule,
//     DateOfBirth: Rule,
//     SSN: Rule,
// }

// type Sex = {
//     Name: string,
//     Value: string,
// }

// type Props = {
//     ID: string,
//     FirstName: ?string,
//     MiddleName: ?string,
//     LastName: ?string,
//     PatientID: ?string,
//     DateOfBirth: ?string,
//     SSN: ?string,
//     Sex: string,
//     sexesList: Array<Sex>,
//     validationRules: ValidationRules,
//     validation: {
//         FirstName: boolean,
//         MiddleName: boolean,
//         LastName: boolean,
//         PatientID: boolean,
//         DateOfBirth: boolean,
//         SSN: boolean,
//     },
//     creating?: boolean,
//     readOnly?: boolean,
//     handleDateOfBirthChange: (value: any) => ?void,
//     handleChange: (field: string, value: string) => ?void,
// }

function PatientForm(props) {
    const sexOptions = () =>
        props.sexesList.map(sex => ({
            value: sex.Value,
            name: sex.Name,
        }))

    return (
        <React.Fragment>
            <InputGroup
                type="text"
                field="FirstName"
                valid={props.validation.FirstName}
                error={`A first name is required that's ${
                    props.validationRules.FirstName.max
                } characters or less`}
                value={props.FirstName || ''}
                label="First*"
                placeholder="First Name"
                disabled={props.readOnly}
                handleChange={props.handleChange}
                maxLength={50}
            />

            <InputGroup
                type="text"
                field="MiddleName"
                valid={props.validation.MiddleName}
                error={`If a middle name is provided, it must be ${
                    props.validationRules.MiddleName.max
                } characters or less`}
                value={props.MiddleName || ''}
                label="Middle"
                placeholder="Middle Name"
                disabled={props.readOnly}
                handleChange={props.handleChange}
                maxLength={50}
            />

            <InputGroup
                type="text"
                field="LastName"
                valid={props.validation.LastName}
                error={`A last name is required that's ${
                    props.validationRules.LastName.max
                } characters or less`}
                value={props.LastName || ''}
                label="Last*"
                placeholder="Last Name"
                disabled={props.readOnly}
                handleChange={props.handleChange}
                maxLength={50}
            />

            <DateTimePicker
                field="DateOfBirth"
                label="DOB*"
                labelSubText="mm/dd/yyyy"
                valid={props.validation.DateOfBirth}
                error="A date of birth that is less than or equal to today's date is required"
                value={props.DateOfBirth}
                disabled={props.readOnly}
                handleChange={props.handleDateOfBirthChange}
                timeFormat={false}
                input
            />

            <SelectGroup
                field="Sex"
                label="Sex"
                options={sexOptions()}
                value={props.Sex || ''}
                disabled={props.readOnly}
                handleChange={props.handleChange}
            />

            <InputGroup
                type="text"
                field="SSN"
                valid={props.validation.SSN}
                error={`If a social security number is provided, it must be exactly ${
                    props.validationRules.SSN.max
                } digits`}
                value={props.SSN || ''}
                label="SSN"
                placeholder="SSN"
                disabled={props.readOnly}
                handleChange={props.handleChange}
                maxLength={11}
            />

            <InputGroup
                type="text"
                field="PatientID"
                valid={props.validation.PatientID}
                error={`If a patient ID is provided, it must be ${
                    props.validationRules.PatientID.max
                } characters or less`}
                value={props.PatientID || ''}
                label="Patient ID"
                placeholder="Patient ID"
                disabled={props.readOnly}
                handleChange={props.handleChange}
                maxLength={30}
            />
        </React.Fragment>
    )
}

export default PatientForm
