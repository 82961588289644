class StorageManager {
    static get(key: string) {
        const value = sessionStorage.getItem(key)
        if (value === null) return null
        try {
            return JSON.parse(value)
        } catch (err) {
            return value
        }
    }

    static set(key: string, value: any) {
        if (typeof value === 'object') {
            value = JSON.stringify(value)
        }
        sessionStorage.setItem(key, value)
    }
}

export default StorageManager
