import React, { useMemo } from 'react'
import moment, { Moment } from 'moment'
import { Session } from '../../types/cptMonitoringTypes'

interface Props<Info> {
    session: Session<Info>
    hoveredSessionIds: string[]
    laneIndex: number
    laneCount: number
    onSelectSession: (id: string) => void
    selectedSessionID?: string
    scopeEnd: Moment
    scopeStart: Moment
    msPerPx: number
    renderSessionInfo: (session: Session<Info>) => JSX.Element
}

const SESSION_MIN_WIDTH = 215
const SESSION_MIN_HEIGHT = 120

const CPTMonitoringSession = <Info,>(props: Props<Info>) => {
    const mountedAt = useMemo(moment, [])
    let endOrNow = props.session.Stopped ?? mountedAt.clone()
    if (endOrNow.isAfter(props.scopeEnd)) {
        endOrNow = props.scopeEnd
    }

    const getContainerClasses = () => {
        let classes = 'session'
        if (props.session.ID === props.selectedSessionID) {
            classes += ' selected'
        }
        if (!props.session.Stopped || props.session.Stopped.isAfter(props.scopeEnd)) {
            classes += ' ended-after-day-or-ongoing'
        }
        if (props.session.Started.isBefore(props.scopeStart)) {
            classes += ' started-before-today'
        }
        if (props.hoveredSessionIds.includes(props.session.ID) ? 'hovered' : '') {
            classes += ' hovered'
        }
        return classes
    }

    let positionKey = ''
    let positionValue = 0
    if (props.session.Stopped) {
        if (props.session.Stopped.isAfter(props.scopeEnd)) {
            positionKey = 'bottom'
        } else {
            positionValue = moment.max(props.session.Started, props.scopeStart).diff(props.scopeStart) / props.msPerPx
            positionKey = 'top'
        }
    } else {
        if (mountedAt.isBefore(props.scopeEnd)) {
            positionValue = props.scopeEnd.diff(mountedAt) / props.msPerPx
        }
        positionKey = 'bottom'
    }

    return (
        <div
            key={props.session.ID}
            className={getContainerClasses()}
            style={{
                [positionKey]: positionValue,
                height: endOrNow.diff(moment.max(props.session.Started, props.scopeStart)) / props.msPerPx,
                minWidth: props.session.ID === props.selectedSessionID ? SESSION_MIN_WIDTH : 0,
                minHeight: props.session.ID === props.selectedSessionID ? SESSION_MIN_HEIGHT : 0,
                right: props.laneIndex / (props.laneCount- 1) > 0.5 ? 0 : undefined,
                zIndex: props.session.ID === props.selectedSessionID ? 1 : undefined,
            }}
            onClick={() => props.onSelectSession(props.session.ID)}
        >
            {props.renderSessionInfo(props.session)}
        </div>
    )
}

export default CPTMonitoringSession
