const FunctionUtils = {
    debounce(func, wait) {
        let timer
        function run(...args) {
            const context = this
            clearTimeout(timer)
            timer = setTimeout(() => {
                func.apply(context, args)
            }, wait)
        }
        return run
    },
}

export default FunctionUtils
