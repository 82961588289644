import {
    ALPHA_ASC,
    ALPHA_DESC,
    DIGIT_ASC,
    DIGIT_DESC,
    NUM_ASC,
    NUM_DESC,
    DATE_ASC,
    DATE_DESC,
} from '../constants/sortalgorithms'

export type SortDir = 'asc' | 'desc'

export type KeysOfTypedValue<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[keyof T]

class SortUtils {
    static string<T, K extends KeysOfTypedValue<T, string>>(array: T[], field: K, dir: SortDir): void {
        switch (dir) {
            case 'asc':
                array.sort(ALPHA_ASC(field))
                break
            case 'desc':
                array.sort(ALPHA_DESC(field))
                break
        }
    }

    static digits<T, K extends KeysOfTypedValue<T, string>>(array: T[], field: K, dir: SortDir): void {
        switch (dir) {
            case 'asc':
                array.sort(DIGIT_ASC(field))
                break
            case 'desc':
                array.sort(DIGIT_DESC(field))
                break
        }
    }

    static number<T, K extends KeysOfTypedValue<T, number>>(array: T[], field: K, dir: SortDir): void {
        switch (dir) {
            case 'asc':
                array.sort(NUM_ASC(field))
                break
            case 'desc':
                array.sort(NUM_DESC(field))
                break
        }
    }

    static date<T, K extends KeysOfTypedValue<T, string>>(array: T[], field: K, dir: SortDir): void {
        switch (dir) {
            case 'asc':
                array.sort(DATE_ASC(field))
                break
            case 'desc':
                array.sort(DATE_DESC(field))
                break
        }
    }
}

export default SortUtils
