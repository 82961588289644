import axios from 'axios'
import MMTApi from './MMTApi'

const AccountApi = {
    resetUserPassword(guid) {
        return axios.post(
            `${
                process.env.REACT_APP_API_URL
            }/api/Account/ResetUserPassword?ID=${guid}`,
            guid,
            MMTApi.getRequestHeaders(),
        )
    },

    ForgotUserPassword(email) {
        return axios.post(
            `${
                process.env.REACT_APP_API_URL
            }/api/Account/ForgotPassword?email=${email}`,
            email,
            MMTApi.getRequestHeaders(),
        )
    },

    AccountRecovery(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Account/AccountRecovery`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    getProfile() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Account/UserInfo`,
            MMTApi.getRequestHeaders(),
        )
    },

    changePassword(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Account/ChangePassword`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default AccountApi
