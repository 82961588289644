import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './scss/style.scss'
import AppState from './components/AppState'
import bugsnag from 'bugsnag-js'
import createPlugin from 'bugsnag-react'
import axios from 'axios'
import SessionManager from './services/SessionManager'
import packageJson from '../package.json'
import { Auth } from './services/Auth'
import { Modal } from 'reactstrap'
import LogRocket from 'logrocket'

const bugsnagClient = bugsnag({
    apiKey: 'd4feaee048cd3b2cae357a2e8c3232ff',
    appVersion: packageJson.version,
    releaseStage: process.env.REACT_APP_NODE_ENV,
})
const ErrorBoundary = bugsnagClient.use(createPlugin(React))

// eslint-disable-next-line
// @ts-expect-error
File.prototype.toJSON = function () {
    return `${this.name}:${this.size}:${this.lastModified}`
}

Modal.defaultProps = { ...Modal.defaultProps, zIndex: 1300 }

bugsnagClient.metaData = {
    user: {
        id: SessionManager.get('mmt_user_id'),
        name: SessionManager.get('mmt_user_name'),
        email: SessionManager.get('mmt_auth_user'),
        facilityId: SessionManager.get('mmt_facility_id'),
        facilityName: SessionManager.get('mmt_facility_name'),
    },
}
if (process.env.REACT_APP_LOGROCKET_ENABLE) {
    bugsnagClient.metaData['beforeSend'] = function (report) {report.updateMetaData('LogRocket', { sessionURL: LogRocket?.sessionURL } )}
}


axios.interceptors.response.use(
    response => response,
    error => {
        const status = error?.response?.status
        if (status === 401) {
            console.log(`[AXIOS] Status 401 auto-redirection to login from ${window.location.pathname} [LoggedIn:${Auth?.loggedIn}]`)
            console.error(JSON.stringify(error))
            bugsnagClient.notify(error)
            SessionManager.clear()
            window.sessionStorage.clear()
            window.location.pathname = '/login'
        }
        if (status === 403) {
            Auth.logout()
        }
        return Promise.reject(error.response ?? error)
    },
)

ReactDOM.render(
    <ErrorBoundary>
        <AppState>
            <App />
        </AppState>
    </ErrorBoundary>,
    document.getElementById('root')
)
