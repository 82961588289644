import {
    SETTING_NOTCH_FILTER,
    SETTING_SENSITIVITY,
    SETTING_TIMEBASE,
    SETTING_MONTAGE,
} from '../../../constants/graphsettings'
import { Ctx } from '../../../components/AppContext'
import DateTimeUtils from '../../../utils/DateTimeUtils'
import Study from '../../../types/Study'

const GraphUtilities = {
    toFixed(value: number, precision: number) {
        const power = 10 ** precision || 0
        return String(Math.round(value * power) / power)
    },

    formatMicrovoltsPerMil(volts: number) {
        return `${Math.floor(volts * 1000000)} µV/mm`
    },

    formatMillivoltsPerMil(volts: number) {
        return `${Math.floor(volts * 1000)} mV/mm`
    },

    formatVoltsPerMil(volts: number) {
        return `${Math.floor(volts)} V/mm`
    },

    formatMicrovolts(volts: number) {
        return `${this.toFixed(volts * 1000000, 1)} µV`
    },

    formatMillivolts(volts: number) {
        return `${this.toFixed(volts * 1000, 1)} mV`
    },

    formatVolts(volts: number) {
        return `${this.toFixed(volts, 1)} V`
    },

    getFrequencyFromTimeConstant(time_constant: number) {
        return 1 / (2 * Math.PI * time_constant)
    },

    getFrequency(time_constant: number) {
        const frequency = this.getFrequencyFromTimeConstant(time_constant)

        let rounded = Math.round(frequency * 100) / 100

        if (rounded > 0.1) {
            rounded = Math.round(rounded * 10) / 10
            const fraction = Math.abs(rounded) - Math.floor(Math.abs(rounded))

            if (fraction > 0.81) {
                return `${this.toFixed(rounded, 0)} Hz`
            }
            return `${this.toFixed(rounded, 1)} Hz`
        }
        return `${this.toFixed(rounded, 2)} Hz`
    },

    formatSensitivity(volts: number) {
        if (volts >= 1) return this.formatVoltsPerMil(volts)
        if (volts >= 0.001) return this.formatMillivoltsPerMil(volts)
        return this.formatMicrovoltsPerMil(volts)
    },

    formatLegendSensitivity(volts: number) {
        volts *= 10
        if (volts >= 1) return this.formatVolts(volts)
        if (volts >= 0.001) return this.formatMillivolts(volts)
        return this.formatMicrovolts(volts)
    },

    formatTimeConstant(time_constant: number) {
        return `${time_constant} sec`
    },

    formatLegendXValue(volts: number) {
        if (volts >= 1) return this.formatVolts(volts)
        if (volts >= 0.001) return this.formatMillivolts(volts)
        return this.formatMicrovolts(volts)
    },

    formatFilter(value: any) {
        return `${value} Hz`
    },

    formatNotchFilter(value: any) {
        if (value === 'Off') {
            return 'Off'
        }

        return `${value} Hz`
    },

    getSettingDisplayString(type: string, setting: any) {
        switch (type) {
            case SETTING_SENSITIVITY:
                return this.formatSensitivity(setting)
            case SETTING_TIMEBASE:
                return this.formatTimeConstant(setting)
            case SETTING_NOTCH_FILTER:
                return this.formatNotchFilter(setting)
            case SETTING_MONTAGE:
                return setting
            default:
                return this.formatFilter(setting)
        }
    },

    getPixelWidthFromSeconds(seconds: number, width: number, timebase: any) {
        return (seconds * width) / timebase
    },

    getMajorTickSecondsInterval(timebase: any) {
        if (timebase === '1') return 0.1
        if (timebase === '30') return 5
        return 1
    },

    getMomentByPacketIndex(ctx: Ctx, packetIndex: number) {
        let studyRecording = ctx.Study.StudyRecordings[0]
        let packetCountThroughCurrentRecording = 0
        for (const rec of ctx.Study.StudyRecordings) {
            if (packetCountThroughCurrentRecording > packetIndex) break
            packetCountThroughCurrentRecording += rec.PacketCount - 12
            studyRecording = rec
        }
        
        const mom = DateTimeUtils.getMomentInTimeZone(studyRecording.DateStarted, studyRecording.TimeZone)
        
        const packetCountTilCurrentRecording = packetCountThroughCurrentRecording - studyRecording.PacketCount
        const packetsSinceRecStart = packetIndex - packetCountTilCurrentRecording

        mom.add(packetsSinceRecStart / ctx.Study.StudyDataRate, 'seconds')

        return mom
    },

    getMomentByTimeRelativeToStudy(study: Study, time: number) {
        const mom = DateTimeUtils.getMomentInTimeZone(study.StudyRecordings[0].DateStarted, study.TimeZone)
        mom.add(time, 'seconds')
        return mom
    },
}

export default GraphUtilities
