import SessionManager from './SessionManager'

const VideoApi = {
    getStudyVideoSrc(study_id, recording_id, camera_id, video_id) {
        return `${
            process.env.REACT_APP_API_URL
        }/api/Studies/${study_id}/${recording_id}/${camera_id}/${video_id}.mp4?access_token=${SessionManager.get(
            'mmt_auth_token',
        )}`
    },
}

export default VideoApi
