import { makeStyles } from '@mui/styles'

function useStyles() {
    return makeStyles({
        root: {
            cursor: 'pointer',
            height: 40,
            position: 'relative',
            top: '-1px',
            marginLeft: '20px',
            marginRight: '56px',
        },
    })()
}

export default useStyles
