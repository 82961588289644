import { Link } from 'react-router-dom'
import './Facilities.scss'
import React from 'react'
import moment from 'moment-timezone'
import FacilitiesApi from '../../services/FacilitiesApi'
import RoleBasedContent from '../../components/RoleBasedContent'
import * as DateTimeFormats from '../../constants/datetimeformats'
import NavUtils from '../../utils/NavUtils'
import { SUPER_ADMIN_ROLE, FACILITY_ADMIN_ROLE } from '../../constants/roles'
import SortUtils from '../../utils/SortUtils'
import SortHeader from '../../components/SortHeader'
import FailedToLoad from '../../components/FailedToLoad'
import NeutralButton from '../../components/NeutralButton'
import LoadingSpinner from '../../components/LoadingSpinner'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { IconButton } from '@mui/material'
import Typography from '@mui/material/Typography'

const NAME_FIELD = 'Name'
const DOMAIN_FIELD = 'Domain'
const DATE_CREATED_FIELD = 'DateCreated'

class Facilities extends React.Component {
    state = {
        is_loading: true,
        get_facilities_did_fail: false,
        facilities: [],
        sorted_by: NAME_FIELD,
        sorted_dir: 'asc',
    }

    componentDidMount() {
        NavUtils.setPageTitle('Facilities')
        this.getFacilities()
    }

    getFacilities = () => {
        this.setState({
            facilities: [],
            is_loading: true,
            get_facilities_did_fail: false,
        })
        FacilitiesApi.getFacilities()
            .then(response => {
                const sorted_by = NAME_FIELD
                const sorted_dir = 'asc'
                SortUtils.string(response.data, sorted_by, sorted_dir)
                this.setFacilities(response.data)
            })
            .catch(() => {
                this.setState({
                    get_facilities_did_fail: true,
                    is_loading: false,
                })
            })
    }

    setFacilities = facilities =>
        this.setState({ facilities, is_loading: false })

    handleSort = sorted_by => {
        const facilities = [...this.state.facilities]
        let sorted_dir = 'desc'
        if (
            this.state.sorted_by !== sorted_by ||
            this.state.sorted_dir === 'desc'
        ) {
            sorted_dir = 'asc'
        }
        switch (sorted_by) {
            case NAME_FIELD:
                SortUtils.string(facilities, sorted_by, sorted_dir)
                break
            case DOMAIN_FIELD:
                SortUtils.string(facilities, sorted_by, sorted_dir)
                break
            case DATE_CREATED_FIELD:
                SortUtils.date(facilities, sorted_by, sorted_dir)
                break
            default:
                throw Error(`you can not sort by ${sorted_by}`)
        }
        this.setState({ sorted_by, sorted_dir, facilities })
    }

    pageHeader = () => {
        const countMessage = this.state.is_loading ? 'Loading...' : `${this.state.facilities?.length} facilities found`

        return (
            <div style={{ marginBottom: 10 }}>
                <h3 style={{ marginBottom: 3 }}>
                    <i className="icon-grid" />{' '}
                    Facilities
                    <RoleBasedContent required_roles={[SUPER_ADMIN_ROLE]}>
                        <NeutralButton
                            sx={{ marginTop: 2 }}
                            component={Link}
                            className="btn btn-secondary pull-right"
                            to="/Facility/Create"
                        >
                            Create
                        </NeutralButton>
                    </RoleBasedContent>
                </h3>
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
                {this.state.is_loading && (<LoadingSpinner />)}
            </div >
        )
    }


    tableHeader = () => (
        <TableHead>
            <TableRow>
                <SortHeader
                    text="Name"
                    field={NAME_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Domain"
                    field={DOMAIN_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Date Created"
                    field={DATE_CREATED_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <TableCell />
            </TableRow>
        </TableHead>
    )

    render() {
        const isLoading = this.state.is_loading
        const noFacilities =
            !isLoading && !this.state.get_facilities_did_fail && this.state.facilities.length === 0
        const pageHeader = this.pageHeader
        const tableHead = this.tableHeader

        if (noFacilities || isLoading)
            return pageHeader()

        return (
            <div className="animated fadeIn facilities">
                {pageHeader()}
                <TableContainer>
                    <Table>
                        {tableHead()}
                        <TableBody>
                            {this.state.facilities.map(facility => (
                                <TableRow key={facility.ID}>
                                    <TableCell className="name">
                                        {facility.Name}
                                    </TableCell>
                                    <TableCell
                                        className="domain text-truncate"
                                        style={{
                                            maxWidth: '200px',
                                        }}
                                    >
                                        {facility.Domain}
                                    </TableCell>
                                    <TableCell className="date-created">
                                        {moment
                                            .utc(facility.DateCreated)
                                            .local()
                                            .format(
                                                DateTimeFormats.FACILITIES_LIST_DATE_CREATED_FORMAT,
                                            )}
                                    </TableCell>

                                    <TableCell className="text-right">
                                        <RoleBasedContent
                                            required_roles={[
                                                SUPER_ADMIN_ROLE,
                                                FACILITY_ADMIN_ROLE,
                                            ]}
                                        >
                                            <div className="row-action-icons">
                                                <IconButton
                                                    title="Edit"
                                                    color="primary"
                                                    component={Link}
                                                    to={`/Facility/${facility.ID}`}>
                                                    <i className="fa fa-pencil-alt" />
                                                </IconButton>
                                            </div>
                                        </RoleBasedContent>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {!this.state.facilities.length && (
                                <TableRow>
                                    <TableCell
                                        colSpan="5"
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        {this.state.get_facilities_did_fail && (
                                            <FailedToLoad
                                                items="facilities"
                                                onRetry={this.getFacilities}
                                            />
                                        )}
                                        {this.state.is_loading &&
                                            'Loading...'}
                                        {noFacilities &&
                                            'There are no facilities'}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
            </div >
        )
    }
}

export default Facilities
