import React from 'react'
import NavUtils from '../utils/NavUtils'

const Help = props => {
    NavUtils.setPageTitleFromPath(props.match.path)

    return (
        <div className="animated fadeIn">
            <div className="row">
                <section className="col-lg-12">
                    <h3>
                        <i className="icon-question" />
                        &nbsp; Help
                        <hr />
                    </h3>
                    <h5>
                        Lifelines Neuro Technical Support
                    </h5>
                    <div className="ml-4 mt-3">
                        <ul>
                            <li>
                                Technical Support Hub:{' '}
                                <a
                                    href="https://www.lifelinesneuro.com/tech-support.html"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Link
                                </a>
                            </li>
                        </ul>
                    </div>
                    <br />
                    <h5>
                        Telephone
                    </h5>
                    <div className="ml-4">
                        <ul>
                            <li>
                                Number:{' '}
                                <a href="tel:+18668896505">866.889.6505</a>{' '}
                                24/7/365
                            </li>
                            <li>Normal business hours 7:30a-5:00p CT</li>
                            <div className="ml-5">
                                <li>All support questions</li>
                            </div>
                            <li>
                                Afterhours 5:00p-8:30a CT, weekends and holidays
                            </li>
                            <div className="ml-5">
                                <li>Recording or reviewing emergencies</li>
                            </div>
                        </ul>
                    </div>
                    <br />
                    <h5>
                        Email
                    </h5>
                    <div className="ml-4">
                        <ul>
                            <li>
                                Email:{' '}
                                <a href="mailto:support@Lifelinesneuro.com">
                                    support@Lifelinesneuro.com
                                </a>
                            </li>
                            <li>
                                During normal business hours you can expect a
                                response within 2 hours.
                            </li>
                            <li>
                                Outside normal business hours or holidays,
                                please be advised that it may take up to 48
                                hours to respond.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Help
