import React from 'react'
import InputGroup from '../../../components/forms/InputGroup'
import StudiesApi from '../../../services/StudiesApi'
import FunctionUtils from '../../../utils/FunctionUtils'
import AppContext from '../../../components/AppContext'
import { Action } from '../../../components/AppState'
import ToastUtils from '../../../utils/ToastUtils'

class StudyNotes extends React.Component {
    static contextType = AppContext

    constructor() {
        super()
        this.state = {
            is_saving: false,
            is_saved_msg_visible: false,
            saved_msg_timeout: null,
            tech_initials: null,
            exam_id: null,
            cpt_code: null,
            notes: null,
        }
        this.updateStudyNotes = FunctionUtils.debounce(
            this.updateStudyNotes,
            500,
        )
    }

    componentWillUnmount() {
        clearTimeout(this.state.saved_msg_timeout)
    }

    updateStudyNotes = () => {
        this.setState({ is_saving: true })
        const studyNotes = {
            ExamId:
                this.state.exam_id === null
                    ? this.context.Study.ExamId
                    : this.state.exam_id,
            CptCode:
                this.state.cpt_code === null
                    ? this.context.Study.CptCode
                    : this.state.cpt_code,
            TechInitials:
                this.state.tech_initials === null
                    ? this.context.Study.TechInitials
                    : this.state.tech_initials,
            Notes:
                this.state.notes === null
                    ? this.context.Study.Notes
                    : this.state.notes,
        }
        StudiesApi.updateStudyNotes({
            ...studyNotes,
            StudyID: this.context.Study.ID,
        })
            .then(() => {
                this.flashSavedMessage()
                this.setState({ is_saving: false })
                this.context.commit(Action.UpdateStudy, studyNotes)
                this.props.setCanCloseReviewOverlay(true)
            })
            .catch(() => {
                ToastUtils.error({ message: 'Unable to save study notes' })
                this.setState({ is_saving: false })
            })
    }

    handleChangeStudyNotes = (field, value) => {
        this.props.setCanCloseReviewOverlay(false)
        this.setState({ [field]: value })
        this.updateStudyNotes()
    }

    flashSavedMessage = () => {
        this.setState({
            is_saved_msg_visible: true,
            saved_msg_timeout: setTimeout(
                () => this.setState({ is_saved_msg_visible: false }),
                1500,
            ),
        })
    }

    getSaveStatus = () => {
        if (this.state.is_saving === true) {
            return 'Saving...'
        }
        if (this.state.is_saved_msg_visible === true) {
            return 'Saved!'
        }
        return ''
    }

    render() {
        return (
            <div
                className={`row study-notes ${this.state.is_saving || this.state.is_saved_msg_visible
                    ? 'save-status'
                    : ''
                }`}
            >
                <div className="col-12">
                    <h4>
                        Study Details
                        <span className="save-status">
                            {this.getSaveStatus()}
                        </span>
                    </h4>
                    <InputGroup
                        type="text"
                        field="exam_id"
                        label="Study ID"
                        maxLength={50}
                        value={
                            this.state.exam_id === null
                                ? this.context.Study.ExamId || ''
                                : this.state.exam_id
                        }
                        handleChange={this.handleChangeStudyNotes}
                    />
                    <InputGroup
                        type="text"
                        field="cpt_code"
                        label="CPT Code"
                        maxLength={50}
                        value={
                            this.state.cpt_code === null
                                ? this.context.Study.CptCode || ''
                                : this.state.cpt_code
                        }
                        handleChange={this.handleChangeStudyNotes}
                    />
                    <InputGroup
                        type="text"
                        field="tech_initials"
                        label="Tech Initials"
                        maxLength={50}
                        value={
                            this.state.tech_initials === null
                                ? this.context.Study.TechInitials || ''
                                : this.state.tech_initials
                        }
                        handleChange={this.handleChangeStudyNotes}
                    />
                    <InputGroup
                        type="textarea"
                        field="notes"
                        label="Notes"
                        maxLength={2000}
                        value={
                            this.state.notes === null
                                ? this.context.Study.Notes || ''
                                : this.state.notes
                        }
                        handleChange={this.handleChangeStudyNotes}
                    />
                </div>
            </div>
        )
    }
}

export default StudyNotes
