import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    FACILITY_ADMIN_ROLE,
    LEAD_TECH_ROLE,
    FIELD_TECH_ROLE,
    OFFICE_PERSONNEL_ROLE,
    REVIEW_DOCTOR_ROLE,
} from '../constants/roles'

const PatientAuth = {
    List: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE, OFFICE_PERSONNEL_ROLE, REVIEW_DOCTOR_ROLE],
    Import: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE],
    AccessDocuments: [LEAD_TECH_ROLE, REVIEW_DOCTOR_ROLE, SUPER_ADMIN_ROLE, OFFICE_PERSONNEL_ROLE, FIELD_TECH_ROLE, FACILITY_ADMIN_ROLE],
    AccessAuditLogs: [SUPER_ADMIN_ROLE, SUPPORT_ROLE],
    AccessStudies: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, REVIEW_DOCTOR_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE, OFFICE_PERSONNEL_ROLE],
    EditRole: [SUPER_ADMIN_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE, SUPPORT_ROLE, FIELD_TECH_ROLE, OFFICE_PERSONNEL_ROLE],
}

export default PatientAuth
