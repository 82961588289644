import { SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE, FACILITY_ADMIN_ROLE } from '../constants/roles'

const AmplifierAuth = {
    List: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE, FACILITY_ADMIN_ROLE],
    View: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE, FACILITY_ADMIN_ROLE],
    EditName: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE, FACILITY_ADMIN_ROLE],
    EditAll: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE],
    Create: [SUPER_ADMIN_ROLE],
}

export default AmplifierAuth
