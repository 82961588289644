import {
    TableCell,
    TableRow,
    IconButton,
    Chip,
} from '@mui/material'
import { Link } from 'react-router-dom'
import StudyAuth from '../../auth/StudyAuth'
import RoleBasedContent from '../../components/RoleBasedContent'
import { SUPER_ADMIN_ROLE, SUPPORT_ROLE } from '../../constants/roles'
import Study from '../../types/Study'
import DateTimeUtils from '../../utils/DateTimeUtils'
import PatientStudiesInfoRow from './PatientStudiesInfoRow'
import { Videocam as VideoIcon } from '@mui/icons-material'

interface Props {
    study: Study
    onDelete: () => void
    onRestore: () => void
    onShare: () => void
}

function PatientStudiesRow(props: Props) {
    const hasShares = props.study.Shares !== null && props.study.Shares.length > 0

    return (
        <TableRow
            sx={{
                '& .MuiTableCell-root': {
                    background: props.study.DateDeleted ? 'rgba(128, 128, 128, 0.5)' : undefined,
                    '& :hover': {
                        background: props.study.DateDeleted ? 'rgba(128, 128, 128, 0.5)' : undefined,
                    },
                    verticalAlign: 'top',
                    whiteSpace: 'nowrap',
                },
            }}
        >
            <TableCell sx={{
                maxWidth: '250px',
                width: '250px',
                '& .info-item': {
                    display: 'inline-block',
                },
            }}>
                {DateTimeUtils.getMinStudyDate(props.study.DateCreated)}
                {' - '}
                {DateTimeUtils.getMinStudyTimestamp(props.study.DateCreated)}
                {props.study.DateDeleted && ' (deleted)'}
                <PatientStudiesInfoRow study={props.study} />
            </TableCell>
            <TableCell>
                {DateTimeUtils.getStudyDuration(props.study.TotalStudyRecordingDuration)}
            </TableCell>
            <TableCell>
                {props.study.UsesVideo && (
                    <>
                        <VideoIcon color="primary"/>
                        <RoleBasedContent required_roles={[SUPER_ADMIN_ROLE, SUPPORT_ROLE]}>
                            <Chip
                                size="small"
                                sx={{ ml: 1 }}
                                color={props.study.SyncState === 1 ? 'primary' : 'secondary'}
                                label={props.study.VideoCount}
                                title={props.study.SyncState === 1 ? `${props.study.VideoCount} videos synced` : `${props.study.VideoCount} videos (sync in progress)`}
                            />
                            {props.study.SyncInfo.TotalCorruptVideoCount > 0 && (
                                <Chip
                                    size="small"
                                    sx={{ ml: 1 }}
                                    color="warning"
                                    label={props.study.SyncInfo.TotalCorruptVideoCount}
                                    title={`${props.study.SyncInfo.TotalCorruptVideoCount} Corrupt videos`}
                                />
                            )}
                        </RoleBasedContent>
                    </>
                )}
            </TableCell>
            <TableCell sx={{ textAlign: 'right' }}>
                <span style={{ position: 'relative', top: '-7px' }}>
                    <RoleBasedContent required_roles={StudyAuth.View}>
                        {!props.study.DateDeleted && (
                            <IconButton
                                color="primary"
                                title="Open"
                                component={Link}
                                to={`/Study/${props.study.ID}`}>
                                <i className="icon fas fa-book-open" />
                            </IconButton>
                        )}
                        {!props.study.DateDeleted && (
                            <IconButton
                                title="Share Study"
                                color="primary"
                                onClick={props.onShare}>
                                <i className="icon icon-electrotek-share-alt-solid" />
                            </IconButton>
                        )}
                        {hasShares && (
                            <IconButton
                                title="Shared Users"
                                color="primary"
                                to={`/SharedUsers/${props.study.ID}`}
                                component={Link}>
                                <i className="icon shares-icon fas fa-handshake" />
                            </IconButton>
                        )}
                    </RoleBasedContent>
                    <RoleBasedContent required_roles={StudyAuth.AuditLogsPage}>
                        <IconButton
                            title="Audit Logs"
                            color="primary"
                            component={Link}
                            to={`/Study/${props.study.ID}/AuditLogs`}>
                            <i className="icon fas fa-list" />
                        </IconButton>
                    </RoleBasedContent>
                    <RoleBasedContent required_roles={StudyAuth.DeleteRestore}>
                        {props.study.IsLive ? null : (
                            props.study.DateDeleted ? (
                                <IconButton
                                    title="Restore study"
                                    color="primary"
                                    onClick={props.onRestore}>
                                    <i className="icon fas fa-fw fa-trash-restore" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    title="Delete study"
                                    color="primary"
                                    onClick={props.onDelete}>
                                    <i className="icon fa fa-trash-alt" />
                                </IconButton>
                            )
                        )}
                    </RoleBasedContent>
                </span>
            </TableCell>
        </TableRow>
    )
}

export default PatientStudiesRow
