import { EEG } from '../eegMonitor/constants'

/**
 * TODO: Currently these utilities only return the custom default values.  In the future, they will
 *  need to know whether the user wants custom or original default values.
 */
const FilterUtilities = {
    getMontageTemplate(props) {
        return (
            props.FacilityDefaultSettings.DefaultMontageTemplate ||
            props.Montages[0]
        )
    },

    getSensitivity(props) {
        let defaultSensitivitySetting

        const defaultSettingValue = props.FacilityDefaultSettings.DefaultSensitivities.Sensitivities.find(
            s => s.ChannelTypeID === EEG,
        )

        if (defaultSettingValue) {
            defaultSensitivitySetting = props.SensitivitySettings.find(
                s =>
                    parseFloat(s.Value) === defaultSettingValue.SenstivityValue,
            )
        }

        return (
            defaultSensitivitySetting ||
            props.SensitivitySettings.find(i => i.IsDefault) ||
            props.SensitivitySettings[0]
        )
    },

    getTimebase(props) {
        return (
            props.FacilityDefaultSettings.DefaultTimebaseSetting ||
            props.TimebaseSettings.find(i => i.IsDefault) ||
            props.TimebaseSettings[0]
        )
    },

    getLowFilter(props) {
        let defaultLowFilterSetting

        const defaultFilterSettings = props.FacilityDefaultSettings.DefaultFilterSettings.HighLowFrequencyFilterValues.find(
            v => v.ChannelTypeId === EEG,
        )

        if (defaultFilterSettings) {
            const valueString = defaultFilterSettings.LowFrequencyFilterTimeConstant.toString()
            defaultLowFilterSetting = props.LowFilterSettings.find(
                s => s.Value === valueString,
            )
        }

        return (
            defaultLowFilterSetting ||
            props.LowFilterSettings.find(i => i.IsDefault) ||
            props.LowFilterSettings[0] ||
            ''
        )
    },

    getHighFilter(props) {
        let defaultHighFilterSetting

        const defaultEegFilterSettings = props.FacilityDefaultSettings.DefaultFilterSettings.HighLowFrequencyFilterValues.find(
            v => v.ChannelTypeId === EEG,
        )

        if (defaultEegFilterSettings) {
            const valueString = defaultEegFilterSettings.HighFrequencyFilterValue.toString()
            defaultHighFilterSetting = props.HighFilterSettings.find(
                s => s.Value === valueString,
            )
        }

        return (
            defaultHighFilterSetting ||
            props.HighFilterSettings.find(i => i.IsDefault) ||
            props.HighFilterSettings[0]
        )
    },

    getNotchFilter(props) {
        const valueString = props.FacilityDefaultSettings.DefaultFilterSettings.NotchFilterFrequency.toString()

        const defaultNotchFilterSetting = props.NotchFilterSettings.find(
            s => s.Value === valueString,
        )

        return (
            defaultNotchFilterSetting ||
            props.NotchFilterSettings.find(i => i.IsDefault) ||
            props.NotchFilterSettings[0]
        )
    },
}

export default FilterUtilities
