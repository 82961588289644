import DateTime from 'react-datetime'
import { Grid } from '@mui/material'

// inteface Props {
//     field: string,
//     value: any,
//     label: string,
//     timeFormat: boolean,
//     handleChange: (moment: any) => ?void,
//     error?: string,
//     disabled?: boolean,
//     labelSubText?: ?string,
//     valid?: boolean,
//     placeholder?: string,
//     class?: string,
//     labelClass?: string,
//     disabled?: boolean,
//     isValid?: (moment: any) => boolean,
// }

function DateTimePicker(props) {
    return (
        <Grid container style={{ margin: '0 0 16px 0' }}>
            <Grid item xs={12} sm={2} md={3} className="form-group" style={{ position: 'relative' }}>
                <label
                    htmlFor={props.field || ''}
                    style={{ margin: 0, paddingRight: 12 }}
                >
                    {props.label || ''}
                </label>
            </Grid>
            <Grid item xs={12} sm={10} md={9}>
                <DateTime
                    value={props.value || ''}
                    timeFormat={props.timeFormat}
                    onChange={props.handleChange}
                    className={props.disabled ? 'disabled' : ''}
                    isValid={props.isValid ? props.isValid : () => true}
                    input
                    utc
                />
                {props.labelSubText && (
                    <div className="label-subtext">
                        <small>{props.labelSubText || ''}</small>
                    </div>
                )}
            </Grid>                
            {!props.valid && props.error && !props.hideError && (
                <>
                    <Grid item xs={12} sm={2} md={3} className="form-group empty-column-offset" style={{ position: 'relative', paddingRight: 12 }} />
                    <Grid item xs={12} sm={10} md={9}>                        
                        <div className="input-error-msg">
                            {props.error || ''}
                        </div>
                    </Grid>
                </>                 
            )}                    
        </Grid>
    )
}

export default DateTimePicker