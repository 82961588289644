import React, { useMemo } from 'react'
import moment, { Moment } from 'moment'
import { LABEL_SPACING_PX } from './constants'

interface Props {
    msPerPx: number
    scopeEnd: Moment
    scopeStart: Moment
    mouseAt: Moment
}

interface Stamp {
    key: number
    text: string
    props: {
        top: number
        color?: string
    }
}

const CPTMonitoringTimestamps: React.FC<Props> = props => {
    const mountedAt = useMemo(moment, [])

    const stamps: Stamp[] = []

    const doesNearVisibleStampExist = (mom: Moment) => {
        const top = 1000 * (mom.unix() - props.scopeStart.unix()) / props.msPerPx
        if (stamps.find(s => s.props.color === '' && Math.abs(s.props.top - top) < LABEL_SPACING_PX)) {
            return true
        }
        return false
    }

    // add day change timestamps
    const dayChangeMomCounter = props.scopeStart.clone().startOf('hour')
    while (dayChangeMomCounter.isSameOrBefore(props.scopeEnd)) {
        if (dayChangeMomCounter.hours() === 0) {
            stamps.push({
                key: dayChangeMomCounter.unix(),
                text: dayChangeMomCounter.format('MMM D'),
                props: {
                    top: 1000 * (dayChangeMomCounter.unix() - props.scopeStart.unix()) / props.msPerPx,
                    color: '',
                },
            })
        }
        dayChangeMomCounter.add(1, 'hour')
    }

    // add study start timestamp
    if (!doesNearVisibleStampExist(props.scopeStart)) {
        stamps.push({
            key: props.scopeStart.unix(),
            text: props.scopeStart.format('h:mm a'),
            props: {
                top: 0,
                color: '',
            },
        })
    }

    // add study end timestamp
    if (!doesNearVisibleStampExist(props.scopeEnd)) {
        stamps.push({
            key: props.scopeEnd.unix(),
            text: props.scopeEnd.format('h:mm a'),
            props: {
                top: 1000 * (props.scopeEnd.unix() - props.scopeStart.unix()) / props.msPerPx,
                color: '',
            },
        })
    }

    const pxPerSec = 1000 / props.msPerPx
    const pxPerHr = pxPerSec * 60 * 60
    const hourStep = Math.ceil(1.5 * LABEL_SPACING_PX / pxPerHr)
    for (let i = 0; true; i += hourStep) {
        const mom = props.scopeStart.clone().startOf('hour').add(i, 'hours')
        if (mom.isAfter(props.scopeEnd)) break
        const top = 1000 * (mom.unix() - props.scopeStart.unix()) / props.msPerPx
        if (doesNearVisibleStampExist(mom)) {
            continue
        }
        const stamp = {
            key: mom.unix(),
            text: mom.format('h a'),
            props: { top, color: '' },
        }
        stamps.push(stamp)
    }

    // hide stamps too close to other stamps
    for (const stamp of stamps) {
        const mountedAtTop = Math.abs(props.scopeStart.unix() - mountedAt.unix()) * pxPerSec
        if (Math.abs(stamp.props.top - mountedAtTop) < LABEL_SPACING_PX) {
            stamp.props.color = 'transparent'
        }

        const mouseAtTop = Math.abs(props.mouseAt.unix() - props.scopeStart.unix()) * pxPerSec
        if (Math.abs(stamp.props.top - mouseAtTop) < LABEL_SPACING_PX) {
            stamp.props.color = 'transparent'
        }
    }

    return (
        <>
            {stamps.map(stamp => (
                <span
                    key={stamp.key}
                    style={{ ...stamp.props }}
                    className="stamp"
                >
                    {stamp.text} -
                </span>
            ))}
        </>
    )
}

export default CPTMonitoringTimestamps
