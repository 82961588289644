import React, { useEffect, useState } from 'react'
import Patient from '../../types/Patient'
import SearchBar from '../../components/forms/SearchBar'
import SortUtils, { SortDir } from '../../utils/SortUtils'
import { patientNameAbr } from '../../utils/StringUtils'
import { NavLink } from 'react-router-dom'
import RoleBasedContent from '../../components/RoleBasedContent'
import { LEAD_TECH_ROLE, OFFICE_PERSONNEL_ROLE, FIELD_TECH_ROLE, SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE } from '../../constants/roles'

const CREATE_PATIENT_ROLES = [SUPER_ADMIN_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE, SUPPORT_ROLE, OFFICE_PERSONNEL_ROLE]

interface Props {
    isLoading: boolean
    onSearch: (text: string) => void
    onSelectPatient: (patient: Patient) => void
    selectedPatient?: Patient
    patients: Patient[]
    isDocumentUploading: boolean
}

const PatientSearch: React.FC<Props> = props => {
    const { selectedPatient } = props
    const [sortOrder, setSortOrder] = useState<SortDir>('asc')
    const patients = [...props.patients]

    useEffect(() => {
        setTimeout(() => {
            const selectedPatientDiv = document.querySelector('.selected')
            if (selectedPatientDiv) {
                selectedPatientDiv.scrollIntoView({ block: 'center', behavior: 'smooth' })
            }
        }, 10)
    }, [selectedPatient])

    SortUtils.string(patients, 'LastName', sortOrder)

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return
            if (e.repeat) return
            if (!selectedPatient) return
            if (props.isDocumentUploading) return
            let step = 0
            if (e.key === 'ArrowUp') step = -1
            if (e.key === 'ArrowDown') step = 1
            for (let i = 0; i < patients.length; i += 1) {
                if (patients[i].ID === selectedPatient.ID) {
                    if (i + step < 0 || i + step >= patients.length) return
                    props.onSelectPatient(patients[i + step])
                }
            }
        }
        window.addEventListener('keydown', listener)
        return () => window.removeEventListener('keydown', listener)
    })

    const handleSortClick = () => {
        if (sortOrder === 'asc') {
            setSortOrder('desc')
        } else {
            setSortOrder('asc')
        }
    }

    return (
        <div className="search">
            <div className="input">
                <RoleBasedContent required_roles={CREATE_PATIENT_ROLES}>
                    <NavLink
                        title="Create patient"
                        className="create-icon fa fa-fw fa-user-plus"
                        to="/Patient/Create"
                    />
                </RoleBasedContent>
                <i
                    className={`sort-icon fa fa-fw fa-sort-amount-${sortOrder === 'asc' ? 'down' : 'up'}`}
                    onClick={handleSortClick}
                    title="Sort patients"
                />
                <SearchBar
                    onSearch={props.onSearch}
                    searching={props.isLoading}
                    maxSearchStringLength={100}
                />
            </div>
            <div className="results">
                {props.isLoading ? 'Loading...' : patients.map(patient => (
                    <div
                        key={patient.ID}
                        className={`
                            patient
                            ${selectedPatient && patient.ID === selectedPatient.ID ? 'selected' : ''}
                            ${props.isDocumentUploading ? 'disabled' : ''}
                        `}
                        onClick={() => props.isDocumentUploading ? null : props.onSelectPatient(patient)}
                    >
                        {patientNameAbr(patient, 'm')}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default PatientSearch
