import React from 'react'
import './ChannelLabels.scss'
import { CHANNEL_TYPE, EEG, EM } from '../constants'
import { getRGBAFromHexARGB } from '../../utils/ColorUtilities'

class ChannelLabels extends React.Component {
    state = {}

    render() {
        return (
            <div
                className="channel-labels"
                style={{
                    backgroundColor: getRGBAFromHexARGB(
                        this.props.theme.BackgroundColor,
                    ),
                    width: `${this.props.width}px`,
                    height: `${this.props.height}px`,
                    color: getRGBAFromHexARGB(this.props.theme.LabelColor),
                }}
            >
                {this.props.channels.length > 0 &&
                    this.props.channels.map((channel, i) => (
                        <div
                            key={`${channel.label}${i.toString()}`}
                            className={`channel-label ${channel.type !== CHANNEL_TYPE[EM] &&
                                channel.type !== CHANNEL_TYPE[EEG]
                                ? 'noneeg'
                                : ''
                            }`}
                            style={{
                                top: `${this.props.midpoints[i].midpoint -
                                    12}px`,
                            }}
                            onClick={() => this.props.handleChannelClick(i)}
                        >
                            {channel.label}
                        </div>
                    ))}
            </div>
        )
    }
}

export default ChannelLabels
