class Peripherals {
    constructor(byte) {
        this.isEventMarker = (byte >>> 6) % 2 === 1
        this.isReRequestFlag = (byte >>> 5) % 2 === 1
        this.isPhoticStimPeriod = (byte >>> 4) % 2 === 1
        this.isLastRecordingPacket = (byte >>> 3) % 2 === 1
        this.isPhoticLightOn = (byte >>> 2) % 2 === 1
    }
}

export default Peripherals
