import ElectroGraph from '../ElectroGraph'

class CacheManager {
    constructor(electroGraph: ElectroGraph) {
        this.electroGraph = electroGraph
        this.packetRetentionWindow = 1e6
        this.importantMinuteRetention = 100
        this.samplesPerMinute = this.electroGraph.props.study.StudyDataRate * 60
    }

    private electroGraph: ElectroGraph
    private importantMinuteRetention: number
    private packetRetentionWindow: number
    private samplesPerMinute: number

    clean(importantMinutes: number[]) {
        let packetsDeleted = 0
        
        if (this.electroGraph.props.isFocusedReview) {
            packetsDeleted = this.cleanUnimportantMinutes(importantMinutes)
        } else {
            packetsDeleted = this.cleanRemoteMinutes()
        }

        if (packetsDeleted > 0) {
            console.log(`[GMM] cleared ${packetsDeleted} packets from memory`)
        }

        return packetsDeleted
    }

    private cleanRemoteMinutes() {
        const upperBoundIndex = this.electroGraph.playCursor.index + this.packetRetentionWindow
        const lowerBoundIndex = this.electroGraph.playCursor.index - this.packetRetentionWindow
        let packetsDeleted = 0
        packetsDeleted += this.cleanRange(0, Math.max(0, lowerBoundIndex - 1))
        packetsDeleted += this.cleanRange(upperBoundIndex + 1, this.electroGraph.getTotalPacketCountForStudy())
        return packetsDeleted
    }

    private cleanUnimportantMinutes(importantMinutes: number[]) {
        let packetsDeleted = 0

        const unimportantMinutes = this.getUnimportantMinutes(importantMinutes)
        for (const minute of unimportantMinutes) {
            const minuteStartIndex = (minute - 1) * this.samplesPerMinute
            const minuteEndIndex = minuteStartIndex + this.samplesPerMinute - 1
            packetsDeleted += this.cleanRange(minuteStartIndex, minuteEndIndex)
        }
       
        return packetsDeleted
    }

    private getUnimportantMinutes(importantMinutes: number[]) {
        const lastMinute = this.electroGraph.getLastMinuteOfData()
        const unimportantMinutes = []
        for (let minute = 1; minute <= lastMinute; minute += 1) {
            if (!importantMinutes.includes(minute)) {
                unimportantMinutes.push(minute)
            }
        }
        return unimportantMinutes
    }

    private cleanRange(rangeStart: number, rangeEnd: number) {
        let packetsDeleted = 0
        for (let i = this.electroGraph.packetsSegmentsLoaded.length - 1; i >= 0; i -= 1) {
            const segment = this.electroGraph.packetsSegmentsLoaded[i]

            const isSegmentUnaffected = segment.end < rangeStart || segment.start > rangeEnd
            if (isSegmentUnaffected) continue

            const isSegmentSplit = segment.start < rangeStart && segment.end > rangeEnd
            if (isSegmentSplit) {
                packetsDeleted += rangeEnd - rangeStart + 1
                this.deleteRangeFromPackets(rangeStart, rangeEnd)
                this.electroGraph.packetsSegmentsLoaded.push({ start: rangeEnd + 1, end: segment.end })
                segment.end = rangeStart - 1
                continue
            }

            const isSegmentRemoved = segment.start >= rangeStart && segment.end <= rangeEnd
            if (isSegmentRemoved) {
                packetsDeleted += segment.end - segment.start + 1
                this.deleteRangeFromPackets(segment.start, segment.end)
                this.electroGraph.packetsSegmentsLoaded.splice(i, 1)
                continue
            }

            const isSegmnetStartIncreased = segment.end > rangeEnd && segment.start >= rangeStart && segment.start <= rangeEnd
            if (isSegmnetStartIncreased) {
                packetsDeleted += rangeEnd - segment.start + 1
                this.deleteRangeFromPackets(segment.start, rangeEnd)
                segment.start = rangeEnd + 1
                continue
            }

            // only remaining option is to decrease segment.end
            packetsDeleted += segment.end - rangeStart + 1
            this.deleteRangeFromPackets(rangeStart, segment.end)
            segment.end = rangeStart - 1
        }

        return packetsDeleted
    }

    private deleteRangeFromPackets(start: number, end: number) {
        for (let i = start; i <= end; i += 1) {
            delete this.electroGraph.packets[i]
        }
    }
}

export default CacheManager
