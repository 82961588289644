// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

class RequestedMinute {
    constructor(minute, settingsChangeId) {
        this.minute = minute
        this.settingsChangeId = settingsChangeId
    }

    toString() {
        return ` ${this.minute}_${this.settingsChangeId}`
    }
}

export default RequestedMinute
