import React from 'react'
import './StudyCard.scss'
import ReactCardFlip from 'react-card-flip'
import DateTimeUtils from '../../utils/DateTimeUtils'
import PatientsApi from '../../services/PatientsApi'
import { truncate, abbreviate } from '../../utils/StringUtils'
import NeutralButton from '../NeutralButton'
import { NavLink } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'
import { Card, CardContent, Typography } from '@mui/material'

class StudyCard extends React.Component {
    state = {
        is_patient_tooltip_open: false,
        patient: {
            FirstName: '',
            MiddleName: '',
            LastName: '',
            ID: '',
        },
        is_flipped: false,
    }

    componentDidMount() {
        PatientsApi.getPatient(this.props.study.PatientSystemId).then(
            response => {
                this.setPatient(response.data)
            },
        )
    }

    setPatient = patient => {
        this.setState({
            patient,
        })
    }

    togglePatientInfo = () => {
        this.setState({
            is_flipped: !this.state.is_flipped,
        })
    }

    togglePatientTooltip = () => {
        this.setState({
            is_patient_tooltip_open: !this.state.is_patient_tooltip_open,
        })
    }

    render() {
        return (
            <div className="study-card">
                <ReactCardFlip isFlipped={this.state.is_flipped}>
                    <Card key="front" raised>
                        <CardContent>
                            <Tooltip
                                title={<Typography variant="body2">Patient Info</Typography>}
                                placement="top"
                                arrow
                            >
                                <i
                                    id={`patient-${this.props.study.ID}`}
                                    className="fa fa-user-circle patient-icon"
                                    onClick={this.togglePatientInfo}
                                />
                            </Tooltip>
                            <h4
                                className="patient-name"
                                title={`${this.props.study.LastName}, ${this.props.study.FirstName} ${this.props.study.MiddleName || ''}`}
                            >
                                {truncate(this.props.study.LastName, 12)},{' '}
                                {abbreviate(this.props.study.FirstName)}{' '}
                                {abbreviate(this.props.study.MiddleName)}
                            </h4>
                            <p>
                                <span className="float-right">
                                    {DateTimeUtils.getFriendlyStudyDate(
                                        this.props.study.DateCreated,
                                    )}
                                </span>
                                Updated
                            </p>
                            <p>
                                <span className="float-right">
                                    {DateTimeUtils.getStudyDuration(
                                        this.props.study
                                            .TotalStudyRecordingDuration,
                                    )}
                                </span>
                                Duration
                            </p>
                            <NeutralButton
                                component={NavLink}
                                to={`/study/${this.props.study.ID}`}
                                className="open-study-button"
                            >
                                Open
                            </NeutralButton>
                        </CardContent>
                    </Card>

                    <Card key="back" raised onClick={this.togglePatientInfo}>
                        <CardContent>
                            <div className="container">
                                {this.state.patient.LastName && (
                                    <>
                                        <dt>Name</dt>
                                        <dd>
                                            {truncate(
                                                this.state.patient
                                                    .LastName,
                                                12,
                                            )}
                                            ,{' '}
                                            {truncate(
                                                this.state.patient
                                                    .FirstName,
                                                8,
                                            )}{' '}
                                            {abbreviate(
                                                this.state.patient
                                                    .MiddleName,
                                            )}
                                        </dd>
                                    </>
                                )}
                                {this.state.patient.DateOfBirth && (
                                    <>
                                        <dt>DOB:</dt>
                                        <dd>
                                            {DateTimeUtils.getFriendlyDateOfBirth(
                                                this.state.patient
                                                    .DateOfBirth,
                                            )}
                                        </dd>
                                    </>
                                )}
                                {this.state.patient.PatientID && (
                                    <>
                                        <dt>Patient ID:</dt>
                                        <dd className="text-truncate">
                                            {
                                                this.state.patient
                                                    .PatientID
                                            }
                                        </dd>
                                    </>
                                )}
                                {this.state.patient.SSN && (
                                    <>
                                        <dt>SSN:</dt>
                                        <dd>
                                            {this.state.patient.SSN}
                                        </dd>
                                    </>
                                )}
                            </div>
                        </CardContent>
                    </Card>
                </ReactCardFlip>
            </div>
        )
    }
}

export default StudyCard
