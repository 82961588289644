// TODO: should we get these from API?

export const TRACKIT_M_AMP_ID = 1
export const MK3_AMP_ID = 2
export const T4_AMP_ID = 3
export const T4A_AMP_ID = 4
export const R40_AMP_ID = 5
export const JNS8_AMP_ID = 6
export const TRACKIT_MK3_ID = 7
export const TRACKIT_T4A_ID = 8
export const GENERIC_32_ID = 9

export const AMP_FRIENDLY_NAMES = []
AMP_FRIENDLY_NAMES[TRACKIT_M_AMP_ID] = 'Trackit M'
AMP_FRIENDLY_NAMES[MK3_AMP_ID] = 'Mk3' // 'Trackit Mk3 Old'
AMP_FRIENDLY_NAMES[T4_AMP_ID] = 'T4'
AMP_FRIENDLY_NAMES[T4A_AMP_ID] = 'T4a' // 'Trackit T4a Old'
AMP_FRIENDLY_NAMES[R40_AMP_ID] = 'R40'
AMP_FRIENDLY_NAMES[JNS8_AMP_ID] = 'JNS 8'
AMP_FRIENDLY_NAMES[TRACKIT_MK3_ID] = 'MK3'
AMP_FRIENDLY_NAMES[TRACKIT_T4A_ID] = 'T4A'
AMP_FRIENDLY_NAMES[GENERIC_32_ID] = '32 Channel'

export const SUPPORTED_AMP_TYPE_IDS = [
    TRACKIT_M_AMP_ID,
    TRACKIT_MK3_ID,
    TRACKIT_T4A_ID,
]
