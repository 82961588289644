import React from 'react'
import AppContext from '../../../../components/AppContext'
import { VIDEO_SYNC, VIDEO_START, VIDEO_STOP } from '../../../../constants/studyevents'
import StudyEvent from '../../../../types/StudyEvent'
import { IMPORTANT_COLOR } from '../constants'

interface Props {
    packetCount: number
    calculateSampleIndex: (recordingIndex: number, startPacketIndex: number) => number
}

const ScrubberEvents: React.FC<Props> = props => {
    const context = React.useContext(AppContext)

    const getWidthOfEvent = (event: StudyEvent) => {
        if (event.EndPacketIndex === null) return '1px'
        const packetsSpanned = event.EndPacketIndex - event.StartPacketIndex
        const percent = (100 * packetsSpanned) / props.packetCount
        return `${percent}%`
    }

    const getLeftOfEvent = (event: StudyEvent) => {
        const startIndex = props.calculateSampleIndex(
            event.RecordingIndex,
            event.StartPacketIndex,
        )
        if (startIndex === 1) return '0'
        const percent = (100 * startIndex) / props.packetCount
        return `${percent}%`
    }

    const getEventColor = (event: StudyEvent) => {
        if (event.Important) {
            return `${IMPORTANT_COLOR}AA`
        } else {
            return `${event.EventType.Color}77`
        }
    }

    return (
        <>
            {context.Study.StudyEvents.filter(
                e => e.EventTypeID !== VIDEO_SYNC &&
                    e.EventTypeID !== VIDEO_STOP &&
                    !(e.EventTypeID === VIDEO_START && (!!e.Value && JSON.parse(e.Value).VideoIndex !== 1)),
            ).map(event => (
                <div
                    className="section"
                    key={event.ID}
                    style={{
                        width: getWidthOfEvent(event),
                        left: getLeftOfEvent(event),
                        background: getEventColor(event),
                    }}
                />
            ))}
        </>
    )
}

export default ScrubberEvents
