import React from 'react'
import './Studies.scss'
import 'moment-duration-format'
import moment from 'moment'
import StudiesApi from '../../services/StudiesApi'
import StudiesList from './StudiesList'
import FilterDropDowns from './Filters/FilterDropDowns'
import SearchBar from '../../components/forms/SearchBar'
import DTU from '../../utils/DateTimeUtils'
import NavUtils from '../../utils/NavUtils'
import ShareStudyModal from '../../components/modals/ShareStudyModal'
import SortUtils from '../../utils/SortUtils'
import SortHeader from '../../components/SortHeader'
import { REVIEW_DOCTOR_ROLE, FIELD_TECH_ROLE } from '../../constants/roles'
import AppContext from '../../components/AppContext'
import FailedToLoad from '../../components/FailedToLoad'
import ToastUtils from '../../utils/ToastUtils'
import { commatizeNumber } from '../../utils/StringUtils'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Paper } from '@mui/material'

const NAME_FIELD = 'name'
const ID_FIELD = 'patientId'
const DATE_FIELD = 'mostRecentStudy'

class Studies extends React.Component {
    static contextType = AppContext

    // temp - until a control to set study day limit or preferemnces
    defaultStudyDays = localStorage.getItem('studylimit') ? localStorage.getItem('studylimit') : 60

    state = {
        last_search: null,
        empty_search: true,
        is_loading: true,
        get_studies_did_fail: false,
        is_share_model_open: false,
        studies: [],
        patient_studies: [],
        sorted_by: null,
        sorted_dir: null,
        applied_filters: [],
        current_selected_study: '',
        recently_shared: [],
        are_advanced_options_visible: false,
        include_deleted_studies: false,
        include_zero_duration_studies: false,
        fromLastNumDays: this.defaultStudyDays,
        filtered_study_count: 0,
    }

    componentDidMount() {
        NavUtils.setPageTitle('Studies')

        const wasFilterHashPassed =
            this.props.location && this.props.location.hash
        const filtersFromUrl = {
            '#NeedsReview': { type: 'status', value: 'PendingReview' },
            '#InProgress': { type: 'status', value: 'InProgress' },
        }
        this.initialFilter = wasFilterHashPassed
            ? [filtersFromUrl[this.props.location.hash]]
            : undefined
        wasFilterHashPassed
            ? this.setInitialFilters(this.initialFilter)
            : this.search('')
    }

    search = searchString => {
        this.clearStudies()
        this.setState({
            is_loading: true,
            last_search: searchString,
            empty_search: !!!searchString,
            get_studies_did_fail: false,
        })

        StudiesApi.getStudiesForList(searchString, this.state.include_zero_duration_studies, this.state.include_deleted_studies, this.state.fromLastNumDays)
            .then(response => {
                if (searchString !== this.state.last_search) return
                SortUtils.date(response.data, 'DateCreated', 'desc')
                this.setStudies(response.data)
                this.setState({ is_loading: false })
            })
            .catch(() => {
                this.setState({ get_studies_did_fail: true, is_loading: false })
            })
    }

    setInitialFilters(newFilters) {
        this.setState({ applied_filters: newFilters }, () => this.search(''))
    }

    setStudies(studies) {
        this.setState({ studies, recently_shared: [] }, () => this.setPatientStudies())
    }

    setPatientStudies() {
        const studies = this.state.studies
        const filtered_studies = this.getFilteredStudies(studies)
        const patient_studies = this.getPatientStudiesList(filtered_studies)
        this.setState({
            patient_studies,
            sorted_by: DATE_FIELD,
            sorted_dir: 'desc',
            filtered_study_count: commatizeNumber(filtered_studies.length),
        })
    }

    clearStudies() {
        this.setStudies([])
    }

    getFilteredStudies() {
        const filters = this.state.applied_filters
        let studies = this.state.studies

        if (studies) {
            filters.forEach(filter => {
                switch (filter.type) {
                    case 'time':
                        studies = studies.filter(s =>
                            DTU.isDateAfter(s.DateCreated, filter.value),
                        )
                        break

                    case 'status':
                        studies = studies.filter(
                            s => s.StudyStateName === filter.value,
                        )
                        break

                    case 'patient':
                        studies = studies.filter(
                            s => s.LastName === filter.value,
                        )
                        break

                    case 'sync':
                        studies = studies.filter(
                            s => s.SyncInfo.SyncState === filter.value,
                        )
                        break

                    default:
                }
            })
        }

        return studies
    }

    getUniquePatientList(studies) {
        const patientIds = []
        for (const study of studies) {
            if (!patientIds.find(p => p.id === study.PatientSystemId)) {
                patientIds.push({
                    name: `${study.LastName}, ${study.FirstName} ${study.MiddleName ? study.MiddleName : ''}`,
                    id: study.PatientSystemId,
                    patientId: study.PatientId || '',
                })
            }
        }
        return patientIds
    }

    showShareModal = current_selected_study => {
        this.setState({
            current_selected_study,
            is_share_model_open: true,
        })
    }

    toggleShareModal = () => {
        this.setState({
            is_share_model_open: !this.state.is_share_model_open,
        })
    }

    getPatientStudiesList(studies) {
        const patientStudies = []
        if (studies && studies.length > 0) {
            const patients = this.getUniquePatientList(studies)
            patients.forEach(patient => {
                const patientSpecificStudies = studies.filter(
                    s => s.PatientSystemId === patient.id,
                )
                patientStudies.push({
                    id: patient.id,
                    name: patient.name,
                    mostRecentStudy: patientSpecificStudies[0].DateCreated,
                    patientId: patient.patientId,
                    studies: patientSpecificStudies,
                })
            })
        }
        return patientStudies
    }

    handleSort = sorted_by => {
        const patient_studies = [...this.state.patient_studies]
        let sorted_dir = 'desc'
        if (
            this.state.sorted_by !== sorted_by ||
            this.state.sorted_dir === 'desc'
        ) {
            sorted_dir = 'asc'
        }
        switch (sorted_by) {
            case ID_FIELD:
                SortUtils.string(patient_studies, sorted_by, sorted_dir)
                break
            case NAME_FIELD:
                SortUtils.string(patient_studies, sorted_by, sorted_dir)
                break
            case DATE_FIELD:
                SortUtils.date(patient_studies, sorted_by, sorted_dir)
                break
            default:
                throw Error(`you can not sort by ${sorted_by}`)
        }
        this.setState({ sorted_by, sorted_dir, patient_studies })
    }

    handleAddFilter = filterSettings => {
        const updatedFilters = this.state.applied_filters
            .filter(f => f.type !== filterSettings.type)
            .concat(filterSettings)
        this.setState(
            { applied_filters: updatedFilters },
            this.setPatientStudies,
        )
    }

    handleClearFilters = filterToClear => {
        const updatedFilters =
            filterToClear === 'all'
                ? []
                : this.state.applied_filters.filter(
                    f => f.type !== filterToClear,
                )
        this.setState(
            { applied_filters: updatedFilters },
            this.setPatientStudies,
        )
    }

    handleNewShare(id) {
        const shares = this.state.recently_shared
        shares.push(id)
        this.setState({ recently_shared: shares })
    }

    handleDelete = id => {
        return new Promise(resolve => {
            StudiesApi.deleteStudy(id).then(() => {
                const deleted = this.state.studies.find(s => s.ID === id)
                deleted.DateDeleted = moment().format()
                this.setState({ studies: this.state.studies }, () => {
                    this.setPatientStudies()
                    resolve()
                })
            }).catch(err => {
                console.log(err)
                ToastUtils.error({ message: 'Unable to mark study for deletion' })
            })
        })
    }

    handleRestore = id => {
        return new Promise(resolve => {
            StudiesApi.restoreStudy(id).then(() => {
                const restored = this.state.studies.find(s => s.ID === id)
                restored.DateDeleted = null
                this.setState({ studies: this.state.studies }, () => {
                    this.setPatientStudies()
                    resolve()
                })
            }).catch(err => {
                console.log(err)
                ToastUtils.error({ message: 'Unable to restore study' })
            })
        })
    }

    pageHeader = (studyCountMessage) => (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} lg={3}>
                    <h3 style={{ marginBottom: 5 }}>
                        <i className="icon-notebook" /> Studies
                        <Typography noWrap variant="subtitle1" component={'div'}>{studyCountMessage}</Typography>
                    </h3>
                </Grid>
                <Grid item xs={12} lg={9} sx={{ marginTop: 2 }}>
                    <div className="studies-search-container">
                        <div id="search-bar-section">
                            <div
                                className="advanced-button"
                                onClick={() => this.setState({
                                    are_advanced_options_visible: !this.state.are_advanced_options_visible,
                                })}
                            >Advanced</div>
                            <div
                                className={`advanced-options ${this.state.are_advanced_options_visible ? '' : 'hidden'}`}
                            >
                                <span className="include-in-search">
                                    Include in search:
                                </span>
                                <span
                                    className="option"
                                    onClick={() => this.setState(
                                        { include_zero_duration_studies: !this.state.include_zero_duration_studies },
                                        () => this.search(this.state.last_search),
                                    )}
                                >
                                    <input type="checkbox" readOnly checked={this.state.include_zero_duration_studies} /> Empty studies
                                </span>
                                <span
                                    className="option"
                                    onClick={() => this.setState(
                                        { include_deleted_studies: !this.state.include_deleted_studies },
                                        () => this.search(this.state.last_search),
                                    )}
                                >
                                    <input type="checkbox" readOnly checked={this.state.include_deleted_studies} /> Deleted studies
                                </span>
                            </div>
                            <SearchBar
                                searching={this.state.is_loading}
                                maxSearchStringLength={100}
                                onSearch={this.search}
                            />
                            <FilterDropDowns
                                filters={this.state.applied_filters}
                                onAddFilter={this.handleAddFilter}
                                onClearFilters={this.handleClearFilters}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    )

    render() {
        const noSearchResults =
            !this.state.is_loading &&
            (this.state.last_search !== '' ||
                this.state.applied_filters.length > 0) &&
            !this.state.get_studies_did_fail

        const noStudies =
            !this.state.last_search &&
            !this.state.is_loading &&
            !this.state.get_studies_did_fail

        const canViewAllStudiesInFacility = !(
            this.context.Roles.includes(REVIEW_DOCTOR_ROLE) ||
            this.context.Roles.includes(FIELD_TECH_ROLE)
        )
        const noStudiesMessage = canViewAllStudiesInFacility
            ? `No viewable studies have been recorded in the last ${this.state.fromLastNumDays} days.`
            : 'There are no studies available for you'

        const shouldAutoExpandList = this.state.patient_studies?.length === 1
        const isLoading = this.state.is_loading
        const areFiltersApplied = this.state.applied_filters.length > 0
        const areStudiesAvailable = this.state.studies.length > 0
        const wasSearchPerformed = !this.state.empty_search
        const countStudies = this.state.studies.length
        const countFilteredStudies = this.state.filtered_study_count

        let studyCountMessage = isLoading ? 'Retrieving studies...' : 'No studies found'

        if (areStudiesAvailable) {
            studyCountMessage = `${countFilteredStudies} studies from the last ${this.state.fromLastNumDays} days`
            studyCountMessage = wasSearchPerformed ? `${countFilteredStudies} studies found` : studyCountMessage
            studyCountMessage = areFiltersApplied ? `${countFilteredStudies} filtered from ${countStudies} studies found` : studyCountMessage
        }

        return (
            <div className="animated fadeIn">
                {// Share Study Modal
                    this.state.is_share_model_open &&
                    (<ShareStudyModal
                        toggle={this.toggleShareModal}
                        study={this.state.current_selected_study}
                        handleShare={(id) => this.handleNewShare(id)}
                    />)
                }

                <>
                    {this.pageHeader(studyCountMessage)}
                    <Paper className="studies-paper-table" elevation={8}>
                        <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                            <Table className="studies-table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <SortHeader
                                            text="Patient Name"
                                            field={NAME_FIELD}
                                            onSort={this.handleSort}
                                            by={this.state.sorted_by}
                                            dir={this.state.sorted_dir}
                                        />
                                        <SortHeader
                                            text="Patient ID"
                                            field={ID_FIELD}
                                            onSort={this.handleSort}
                                            by={this.state.sorted_by}
                                            dir={this.state.sorted_dir}
                                        />
                                        <TableCell>
                                            SSN
                                        </TableCell>
                                        <SortHeader
                                            text="Date"
                                            field={DATE_FIELD}
                                            onSort={this.handleSort}
                                            by={this.state.sorted_by}
                                            dir={this.state.sorted_dir}
                                        />
                                        <TableCell>Duration</TableCell>
                                        <TableCell>Video</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.patient_studies.map(ps => (
                                        <StudiesList
                                            key={ps.id}
                                            onDelete={this.handleDelete}
                                            onRestore={this.handleRestore}
                                            studies={ps.studies}
                                            showShareModal={this.showShareModal}
                                            recentlyShared={this.state.recently_shared}
                                            noExpandingList={shouldAutoExpandList}
                                        />
                                    ))}

                                    {!this.state.patient_studies.length && (
                                        <TableRow>
                                            <TableCell
                                                colSpan="8"
                                                style={{
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {this.state.get_studies_did_fail && (
                                                    <FailedToLoad
                                                        items="studies"
                                                        onRetry={() => {
                                                            this.search(
                                                                this.state
                                                                    .last_search ||
                                                                '',
                                                            )
                                                        }}
                                                    />
                                                )}
                                                {this.state.is_loading &&
                                                    `${this.state
                                                        .last_search === ''
                                                        ? 'Loading...'
                                                        : 'Searching...'
                                                    }`}
                                                {noSearchResults &&
                                                    'Your search resulted in no matches'}
                                                {noStudies &&
                                                    !noSearchResults &&
                                                    noStudiesMessage}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>                        
                    </Paper>
                </>
            </div >
        )
    }
}
export default Studies
