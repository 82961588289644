import React, { useRef, useEffect, useCallback, useMemo } from 'react'
import moment, { Moment } from 'moment'
import DateTime from 'react-datetime'

interface Props {
    inputProps: React.HTMLProps<HTMLInputElement>
    date: Moment
    open: boolean
    onSelect: (mom: Moment) => void
}

const MonthPicker: React.FC<Props> = props => {
    const dateTimePicker = useRef<DateTime | null>(null)
    const { onSelect, open, date } = props
    const selectedDate = useMemo(() => date.clone(), [date])

    const registerYearCellListeners = () => {
        setTimeout(() => {
            const yearCells = Array.from(document.querySelectorAll('.rdtYear'))
            for (let i = 0; i < yearCells.length; i += 1) {
                yearCells[i].addEventListener('click', () => {
                    const selectedYear = parseInt(yearCells[i].getAttribute('data-value') || '')
                    selectedDate.year(selectedYear)
                })
            }
        })
    }

    const registerMonthCellListeners = useCallback(() => {
        setTimeout(() => {
            const monthCells = Array.from(document.querySelectorAll('.rdtMonth'))
            for (let i = 0; i < monthCells.length; i += 1) {
                monthCells[i].addEventListener('click', () => {
                    if (monthCells[i].classList.contains('rdtDisabled')) {
                        return
                    }
                    if (!dateTimePicker.current) {
                        return
                    }
                    dateTimePicker.current.setState({ open: false })
                    selectedDate.month(i).startOf('month')
                    onSelect(selectedDate)
                })
            }
        })
    }, [onSelect, selectedDate])

    useEffect(() => {
        if (open) {
            registerMonthCellListeners()
        }
    }, [open, registerMonthCellListeners])

    const handleNavigate = (mode: string) => {
        if (mode === 'months') {
            registerMonthCellListeners()
        } else if (mode === 'years') {
            registerYearCellListeners()
        }
    }

    const handleNavigateBack = (years: number) => {
        if (years === 1) {
            selectedDate.subtract(1, 'year')
            registerMonthCellListeners()
        } else {
            registerYearCellListeners()
        }
    }

    const handleNavigateForward = (years: number) => {
        if (years === 1) {
            selectedDate.add(1, 'year')
            registerMonthCellListeners()
        } else {
            registerYearCellListeners()
        }
    }

    return (
        <DateTime
            inputProps={props.inputProps}
            value={selectedDate.clone()}
            timeFormat={false}
            dateFormat="MMMM YYYY"
            ref={dateTimePicker}
            onNavigate={handleNavigate}
            onNavigateBack={handleNavigateBack}
            onNavigateForward={handleNavigateForward}
            initialViewMode="months"
            open={open}
            isValidDate={(m: Moment) => m.isBefore(moment().endOf('month'))}
        />
    )
}

export default MonthPicker