import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import RoleBasedContent from '../../components/RoleBasedContent'
import './StudiesListRow.scss'
import StudySyncStatusBar from './StudySyncStatusBar'
import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    OFFICE_PERSONNEL_ROLE,
} from '../../constants/roles'
import { SYNCED } from '../../constants/syncstates'
import DateTimeUtils from '../../utils/DateTimeUtils'
import StudyAuth from '../../auth/StudyAuth'
import ModalBool from '../../components/modals/ModalBool'
import NotSet from '../../components/NotSet'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import {
    FormatListBulleted as ListIcon,
    RestoreFromTrash as RestoreIcon,
    Share as ShareIcon,
    Videocam as VideoIcon,
} from '@mui/icons-material'
import { Chip } from '@mui/material'

const BADGE_ROLES = [SUPER_ADMIN_ROLE, SUPPORT_ROLE]

const StudiesListRow = props => {
    const [showDeleteModal, setIsDeleteModalVisible] = useState(false)
    const [showRestoreModal, setIsRestoreModalVisible] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isRestoring, setIsRestoring] = useState(false)

    const { patientName } = props
    const patientTitle = `${props.study.LastName}, ${props.study.FirstName} ${props.study.MiddleName || ''}`
    const problemVideos = props.study.SyncInfo.TotalCorruptVideoCount

    const hasShares = props.wasRecentlyShared ||
        (!!props.study && !!props.study.Shares && props.study.Shares.length > 0)

    const handleDelete = async () => {
        setIsDeleting(true)
        await props.onDelete()
        setIsDeleteModalVisible(false)
        setIsDeleting(false)
    }

    const handleRestore = async () => {
        setIsRestoring(true)
        await props.onRestore()
        setIsRestoreModalVisible(false)
        setIsRestoring(false)
    }

    const extraRowInfo = () => (
        <div className="extra-info">
            <span className="info-item">
                {props.study.StudyStateName.replace(/([a-z])([A-Z])/g, '$1 $2') || <NotSet />}
            </span>
            <span className="info-item">
                Study ID: {props.study.ExamId || <NotSet />}
            </span>
            <span className="info-item">
                Amplifier Name:{' '}
                {props.study.AmplifierName || <NotSet />}
            </span>
            {props.study.TotalPacketCount !== 0 && (
                <React.Fragment>
                    {props.study.SyncState === SYNCED && (
                        <span className={`info-item ${hasSyncError ? 'sync-error' : ''}`}>
                            {hasSyncError ? 'Sync Error' : 'Sync Complete'}
                        </span>
                    )}
                    {props.study.SyncState !== SYNCED && (
                        <span>
                            <StudySyncStatusBar study={props.study} />
                        </span>
                    )}
                </React.Fragment>
            )}
            {props.study.IsLive && <span className="live-study-indicator">&bull; LIVE</span>}
        </div>
    )

    const deleteModal = (
        <ModalBool
            isOpen
            loading={isDeleting}
            toggle={() => setIsDeleteModalVisible(false)}
            title="Mark Study for Deletion"
            trueButtonText="Delete"
            falseButtonText="Cancel"
            handleTrue={handleDelete}
            handleFalse={() => setIsDeleteModalVisible(false)}
        >
            <p>Are you sure you want to mark this study for deletion?</p>
            <p title={patientTitle}>Patient Name: {patientName}</p>
            <p>Patient ID: {props.study.PatientId || <NotSet />}</p>
            <p style={{ margin: 0 }}>Study ID: {props.study.ExamId || <NotSet />}</p>
        </ModalBool>
    )

    const restoreModal = (
        <ModalBool
            loading={isRestoring}
            toggle={() => setIsRestoreModalVisible(false)}
            title="Restore Study"
            trueButtonText="Restore"
            falseButtonText="Cancel"
            handleTrue={handleRestore}
            handleFalse={() => setIsRestoreModalVisible(false)}
            isOpen={showRestoreModal}
        >
            <p>Are you sure you want to restore this study?</p>
            <p title={patientTitle}>Patient Name: {patientName}</p>
            <p>Patient ID: {props.study.PatientId || <NotSet />}</p>
            <p style={{ margin: 0 }}>Study ID: {props.study.ExamId || <NotSet />}</p>
        </ModalBool>
    )

    const rowClass = `studies-list-row 
        ${props.leftBorder ? 'studylist-border-left' : ''}
        ${props.study.DateDeleted ? 'deleted' : ''}`

    const isSynced = props.study.SyncState === 1

    // A sync error is when a study recording has SyncState of SYNCED
    // but the SyncPacketIndex values are not equal to the PacketCount values.
    const hasSyncError = props.study.SyncInfo.StudyRecordings.find(r => r.SyncState === SYNCED && r.SyncPacketIndex !== r.PacketCount)

    return (
        <React.Fragment>
            {showDeleteModal && deleteModal}
            {showRestoreModal && restoreModal}
            <TableRow className={rowClass}>
                <TableCell>
                    {props.titleRow && props.studyCount > 1 && (
                        <Badge
                            color="primary"
                            badgeContent={props.studyCount}
                            max={1000}
                        >
                            <IconButton
                                title="Expand Toggle"
                                color="primary" 
                                onClick={() => props.toggleExpandedRow(props.study.PatientSystemId)}>
                                <i className="icon icon-electrotek-chevron-right" />
                            </IconButton>
                        </Badge>
                    )}
                </TableCell>
                <TableCell className="name-column">
                    <span title={patientTitle}>
                        <RoleBasedContent required_roles={StudyAuth.View}>
                            {props.study.DateDeleted ? (
                                <>
                                    {patientName} {props.study.DateDeleted ? '(deleted)' : ''}
                                </>
                            ) : (
                                <Link
                                    className="name-link"
                                    to={`/study/${props.study.ID}`}
                                >
                                    {patientName}
                                </Link>
                            )}
                        </RoleBasedContent>
                        <RoleBasedContent required_roles={[OFFICE_PERSONNEL_ROLE]}>
                            {patientName}
                        </RoleBasedContent>
                    </span>
                    <br />
                    <span>
                        {extraRowInfo()}
                    </span>
                </TableCell>
                <TableCell className="patient-id-column">{props.study.PatientId}<br />&nbsp;</TableCell>
                <TableCell>{props.study.SSN}<br />&nbsp;</TableCell>
                <TableCell
                    className="date-column"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={DateTimeUtils.getFullStudyDateTimestamp(
                        props.study.DateCreated,
                    )}
                >
                    {DateTimeUtils.getMinStudyDate(props.study.DateCreated)}
                    &nbsp;-&nbsp;
                    {DateTimeUtils.getMinStudyTimestamp(
                        props.study.DateCreated,
                    )}
                    <br />&nbsp;
                </TableCell>
                <TableCell>
                    {DateTimeUtils.getStudyDuration(
                        props.study.TotalStudyRecordingDuration,
                    )}
                    <br />&nbsp;
                </TableCell>
                <TableCell className="video-column">
                    {props.study.UsesVideo && (
                        <>
                            <VideoIcon color="primary" />
                            <RoleBasedContent required_roles={BADGE_ROLES}>
                                <Chip
                                    color={isSynced ? 'primary' : 'secondary'}
                                    label={props.study.VideoCount}
                                    sx={{ ml: 1 }}
                                    size="small"
                                    title={isSynced ? `${props.study.VideoCount} videos synced` : `${props.study.VideoCount} videos (sync in progress)`}
                                />
                                {problemVideos > 0 && (
                                    <Chip
                                        color="error"
                                        sx={{ ml: 1 }}
                                        size="small"
                                        label={problemVideos}
                                        title={`${problemVideos} corrupt videos`}
                                    />
                                )}
                            </RoleBasedContent>
                        </>
                    )}
                    <br />&nbsp;
                </TableCell>
                <TableCell>
                    <div className="row-action-icons">
                        <RoleBasedContent required_roles={StudyAuth.View}>
                            {!props.study.DateDeleted && (
                                <IconButton
                                    title="Open"
                                    component={Link}
                                    to={`/Study/${props.study.ID}`}
                                    color="primary">
                                    <i className="icon fas fa-book-open" />
                                </IconButton>
                            )}
                            {!props.study.DateDeleted && (
                                <IconButton
                                    title="Share Study"
                                    onClick={props.showShareModal}
                                    color="primary">
                                    <ShareIcon />
                                </IconButton>
                            )}
                            {hasShares &&
                                <IconButton
                                    title="Shared Users"
                                    component={Link}
                                    to={`/SharedUsers/${props.study.ID}`}
                                    color="primary">
                                    <i className="icon shares-icon fas fa-handshake" />
                                </IconButton>
                            }
                        </RoleBasedContent>
                        <RoleBasedContent required_roles={StudyAuth.AuditLogsPage}>
                            <IconButton
                                title="Audit Logs"
                                component={Link}
                                to={`/Study/${props.study.ID}/AuditLogs`}
                                color="primary">
                                <ListIcon />
                            </IconButton>
                        </RoleBasedContent>
                        <RoleBasedContent required_roles={StudyAuth.DeleteRestore}>
                            {props.study.IsLive ? null : (
                                props.study.DateDeleted ? (
                                    <IconButton
                                        title="Restore study"
                                        onClick={() => setIsRestoreModalVisible(true)}
                                        color="primary">
                                        <RestoreIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        title="Delete study"
                                        onClick={() => setIsDeleteModalVisible(true)}
                                        color="primary">
                                        <DeleteIcon />
                                    </IconButton>
                                )
                            )}
                        </RoleBasedContent>
                    </div>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default StudiesListRow
