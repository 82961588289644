import React, { useEffect, useState, useCallback } from 'react'
import './Patients.scss'
import axios from 'axios'
import PatientSearch from './PatientSearch'
import Patient from '../../types/Patient'
import PatientView from './PatientView'
import PatientsApi from '../../services/PatientsApi'
import LoadingSpinner from '../../components/LoadingSpinner'
import FailedToLoad from '../../components/FailedToLoad'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

const Patients: React.FC = () => {
    const [patients, setPatients] = useState<Patient[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isDocumentUploading, setIsDocumentUploading] = useState(false)
    const [didPatientsFailToLoad, setDidPatientsFailToLoad] = useState(false)
    const [selectedPatient, setSelectedPatient] = useState<Patient | undefined>()
    const [searchedText, setSearchedText] = useState('')

    const search = useCallback(() => {
        setIsLoading(true)
        setDidPatientsFailToLoad(false)
        setSelectedPatient(undefined)
        const source = axios.CancelToken.source()
        PatientsApi.search(searchedText, source).then(res => {
            setPatients(res.data)
            setIsLoading(false)
            const selectedPatientId = window.location.hash.replace('#', '')
            for (const patient of res.data) {
                if (patient.ID === selectedPatientId) {
                    setSelectedPatient(patient)
                    window.location.hash = patient.ID
                    return
                }
            }
            setSelectedPatient(res.data[0])
            if (res.data[0]) {
                window.location.hash = res.data[0].ID
            } else {
                window.history.pushState('', document.title, window.location.pathname)
            }
        }).catch(err => {
            if (axios.isCancel(err)) return
            console.log(err)
            setDidPatientsFailToLoad(true)
            setIsLoading(false)
        })
        return () => source.cancel('New search performed')
    }, [searchedText])

    useEffect(search, [search])
    useDocumentTitle('Patients')

    const pageHeader = () => {
        const countMessage = isLoading ? 'Loading...' :
            `${patients.length === 0 ? 'No' : patients.length} patients found`

        return (
            <div className="header-seperator">
                <h3 style={{ marginBottom: 3 }}>
                    <i className="icon-electrotek-person" />
                    <span>Patients</span>
                </h3 >
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
            </div>
        )
    }

    return (
        <div className="animated fadeIn patients">
            {pageHeader()}
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <PatientSearch
                        isLoading={isLoading}
                        onSearch={setSearchedText}
                        onSelectPatient={p => {
                            setSelectedPatient(p)
                            window.location.hash = p.ID
                        }}
                        selectedPatient={selectedPatient}
                        patients={patients}
                        isDocumentUploading={isDocumentUploading}
                    />
                </Grid>
                <Grid item xs={9}>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : selectedPatient ? (
                        <PatientView
                            patient={selectedPatient}
                            onSetIsDocumentUploading={setIsDocumentUploading}
                        />
                    ) : (
                        <h2 style={{ textAlign: 'center' }}>
                            {didPatientsFailToLoad ? (
                                <FailedToLoad items="patients" onRetry={search} />
                            ) : 'No patients found'}
                        </h2>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default Patients
