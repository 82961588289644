import './NonEEGOverlay.scss'
import GraphSettingsDropdown from '../../../OptionsMenu/GraphSettingsDropdown'
import { CHANNEL_LABEL, CHANNEL_TYPE_ICON } from '../../constants'
import {
    SETTING_HIGH_FILTER,
    SETTING_LOW_FILTER,
    SETTING_SENSITIVITY,
} from '../../../../../constants/graphsettings'

const NonEEGOverlay = props => {
    const fixSettingOptions = field =>
        props.options[field].map(setting => ({
            ...setting,
            Value: parseFloat(setting.Value),
        }))

    if (!props.settings || !props.channel) return null

    const handleSettingSelect = (type, value) => {
        props.handleSettingChange(props.channel.type, type, value)
    }

    return (
        <div className={`non-eeg-overlay ${props.isOpen ? 'show' : ''}`}>
            <div
                className="exit-button float-right"
                title="Exit Settings"
                onClick={props.handleToggle}
            >
                <i className="icon icon-electrotek-cross2" />
            </div>
            <div className="sensitivity-dropdown float-right">
                <GraphSettingsDropdown
                    type={SETTING_SENSITIVITY}
                    displayName="Sens."
                    currentSetting={{
                        Value: props.settings[SETTING_SENSITIVITY],
                    }}
                    options={fixSettingOptions('SensitivitySettings')}
                    handleSettingSelect={handleSettingSelect}
                />
            </div>
            <div className="high-filter-dropdown float-right">
                <GraphSettingsDropdown
                    type={SETTING_HIGH_FILTER}
                    displayName="High"
                    currentSetting={{
                        Value: props.settings[SETTING_HIGH_FILTER],
                    }}
                    options={fixSettingOptions('HighFilterSettings')}
                    handleSettingSelect={handleSettingSelect}
                />
            </div>
            <div className="low-filter-dropdown float-right">
                <GraphSettingsDropdown
                    type={SETTING_LOW_FILTER}
                    displayName="Low"
                    currentSetting={{
                        Value: props.settings[SETTING_LOW_FILTER],
                    }}
                    options={fixSettingOptions('LowFilterSettings')}
                    handleSettingSelect={handleSettingSelect}
                />
            </div>
            <div
                className="channel-type-icon float-right"
                title={CHANNEL_LABEL[props.channel.type]}
            >
                <i
                    className={`icon ${CHANNEL_TYPE_ICON[props.channel.type]}`}
                />
            </div>
        </div>
    )
}

export default NonEEGOverlay
