import axios from 'axios'
import MMTApi from './MMTApi'
import SessionManager from './SessionManager'

const StudiesApi = {
    getStudiesForList(searchString, includeZeroDuration = false, includeDeleted = false, fromLastNumDays = 30) {
        if (searchString === undefined) {
            searchString = ''
        }
        if (searchString.trim() === 'show zero') {
            includeZeroDuration = true
            searchString = ''
        }

        let uri = `${process.env.REACT_APP_API_URL}/api/Studies/ListView/?searchString=${searchString}`
        if (includeDeleted) {
            uri += '&includeDeleted=true'
        } else {
            uri += '&includeDeleted=false'
        }
        if (includeZeroDuration) {
            uri += '&includeZeroDuration=true'
        } else {
            uri += '&includeZeroDuration=false'
        }
        uri += `&fromLastNumDays=${fromLastNumDays ? fromLastNumDays : 60}`

        return axios.get(uri, MMTApi.getRequestHeaders())
    },

    getStudy(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Studies/StudyView/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    deleteStudy(guid) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Studies/DeleteStudy?studyId=${guid}&facilityId=${SessionManager.get('mmt_facility_id')}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },

    restoreStudy(guid) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Studies/RestoreStudy?studyId=${guid}&facilityId=${SessionManager.get('mmt_facility_id')}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },

    removeStudyShare(studyShareId) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Studies/RemoveStudyShare?studyShareId=${studyShareId}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },

    getStudySyncInfo(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Studies/SyncInfo/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getLinkedStudies(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Studies/LinkedStudies/?studyId=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getStudyRecordings(guid, index) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Studies/GetStudyRecording/?id=${guid}&index=${index}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getStudyReport(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Studies/GetStudyReport/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    changeStudyState(studyId, studyState) {
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/api/Studies/ChangeStudyState?studyId=${studyId}&studyState=${studyState}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },

    updateStudyNotes(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Studies/UpdateStudyNotes`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    updateStudyRecordingNotes(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/api/StudyRecordings/UpdateStudyRecordingNotes`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    updateStudyReport(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Studies/UpdateStudyReport`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    uploadSetup(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Studies/StudyUploadSetup`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    awsBucketForStudy(studyId) {
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/api/Studies/AwsBucketForStudy?studyId=${studyId}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },

    assignPatient(studyId, patientId) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/Studies/AssignPatient?patientId=${patientId}&studyId=${studyId}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default StudiesApi
