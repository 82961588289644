import React from 'react'
import { Grid, Typography } from '@mui/material'

const Page404 = () => (
    <Grid container justifyContent="center" marginTop="15vh">
        <Grid item xs={7}>
            <Typography variant="h2" marginRight="3%" sx={{ float: 'left' }}>
                404
            </Typography>
            <Typography variant="h5">
                Not Found
            </Typography>
        </Grid>
        <Grid item xs={7}>
            <Typography>
                The resource you are looking for was not found. Please retry or contact customer service for further assistance.
            </Typography>
        </Grid>
    </Grid>
)

export default Page404
