import { PropsWithChildren, useState } from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import { Collapse, useTheme } from '@mui/material'
import MenuLink from './MenuLink'
import {
    ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material'

interface Props {
    label: string
    icon: JSX.Element
    isDrawerOpen: boolean
}

function ExpandableMenu(props: PropsWithChildren<Props>) {
    const [open, setOpen] = useState(false)
    const theme = useTheme()

    return (
        <Box sx={{ background: open ? 'rgba(0, 0, 0, 0.2)' : undefined }}>
            <MenuLink
                isDrawerOpen={props.isDrawerOpen}
                onClick={() => setOpen(i => !i)}
                label={props.label}
                leftIcon={props.icon}
                rightIcon={<ExpandMoreIcon style={{
                    color: 'rgba(255, 255, 255, 0.4)',
                    transform: open ? undefined : 'rotate(90deg)',
                    transition: theme.transitions.create('transform'),
                }} />}
            />
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List disablePadding>
                    {props.children}
                </List>
            </Collapse>
        </Box>
    )
}

export default ExpandableMenu
