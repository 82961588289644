import AsideMenu from './AsideMenu'
import ThemeItem from './ThemeItem'

const ThemeSelectPanel = ({
    facilityThemes,
    handleThemeSelectClick,
    areControlsMinimized,
    selectedTheme,
}) => (
    <AsideMenu areControlsMinimized={areControlsMinimized}>
        <div className="m-0 py-2 text-secondary text-center text-uppercase aside-menu-icons">
            <small>
                <b>Themes</b>
            </small>
        </div>

        <div className="aside-content">
            {facilityThemes &&
                facilityThemes.map(item => (
                    <ThemeItem
                        key={item.ID}
                        id={item.ID}
                        name={item.Name}
                        handleThemeSelectClick={id =>
                            handleThemeSelectClick(id)
                        }
                        isSelected={selectedTheme.ID === item.ID}
                    />
                ))}
        </div>
    </AsideMenu>
)

export default ThemeSelectPanel
