import React from 'react'
import './Legend.scss'
import GraphUtilities from '../../utils/GraphUtilities'

class Legend extends React.Component {
    state = {
        right: 0,
        bottom: 30,
        mouse_was_at: null,
    }

    componentDidUpdate(prevProps) {
        if (prevProps.timebase !== this.props.timebase) {
            this.repositionWithinBounds()
        }
    }

    handleDragStart = e => {
        this.setState({ mouse_was_at: this.getCoordinatesFromEvent(e) })
        window.addEventListener(this.getMoveEventFromEvent(e), this.handelMove)
    }

    handleDragEnd = e => {
        this.repositionWithinBounds()
        window.removeEventListener(
            this.getMoveEventFromEvent(e),
            this.handelMove,
        )
    }

    repositionWithinBounds = () => {
        const legendWidth = this.getWidthFromTimebase()
        const right = Math.min(
            Math.max(0 - legendWidth, this.state.right),
            this.props.graphWidth - legendWidth,
        )
        const bottom = Math.min(
            Math.max(0, this.state.bottom),
            this.props.graphHeight,
        )
        this.setState({ right, bottom, mouse_was_at: null })
    }

    handelMove = e => {
        const { x, y } = this.getCoordinatesFromEvent(e)
        const right = this.state.right + this.state.mouse_was_at.x - x
        const bottom = this.state.bottom + this.state.mouse_was_at.y - y
        this.setState({ right, bottom, mouse_was_at: { x, y } })
    }

    getCoordinatesFromEvent = e => {
        if (e.touches) e = e.touches[0]
        return { x: e.clientX, y: e.clientY }
    }

    getMoveEventFromEvent = e => {
        if (e.touches) return 'touchmove'
        return 'mousemove'
    }

    getWidthFromTimebase = () => {
        switch (this.props.timebase) {
            case 1:
                return this.props.graphWidth / 10
            case 5:
                return this.props.graphWidth / 5
            case 10:
                return this.props.graphWidth / 10
            case 15:
                return this.props.graphWidth / 15
            case 20:
                return this.props.graphWidth / 10
            case 30:
                return this.props.graphWidth / 6
            default:
                throw Error(`invalid timebase ${this.props.timebase}`)
        }
    }

    getSecondsScaleFromTimebase = () => {
        switch (this.props.timebase) {
            case 1:
                return '0.1 sec'
            case 5:
            case 10:
            case 15:
                return '1 sec'
            case 20:
                return '2 sec'
            case 30:
                return '5 sec'
            default:
                throw Error(`invalid timebase ${this.props.timebase}`)
        }
    }

    render() {
        return (
            <div
                className={`legend ${
                    this.state.mouse_was_at === null ? '' : 'grabbing'
                }`}
                style={{
                    left: `${this.props.gutter.left}px`,
                    right: `${this.props.gutter.right}px`,
                    top: `${this.props.gutter.top}px`,
                    bottom: `${this.props.gutter.bottom +
                        this.props.scrubberHeight}px`,
                }}
            >
                <div
                    onMouseDown={this.handleDragStart}
                    onMouseUp={this.handleDragEnd}
                    onTouchStart={this.handleDragStart}
                    onTouchEnd={this.handleDragEnd}
                    className="lines"
                    style={{
                        right: this.state.right,
                        bottom: this.state.bottom,
                        width: this.getWidthFromTimebase(),
                        borderColor: this.props.color,
                        height: this.props.height,
                    }}
                >
                    <div
                        className="sensitivity-tick"
                        style={{ borderColor: this.props.color }}
                    />
                    <div
                        className="scale-tick"
                        style={{ borderColor: this.props.color }}
                    />
                    <span
                        className="sensitivity"
                        style={{ color: this.props.color }}
                        // add key for Safari bug
                        key={GraphUtilities.formatLegendSensitivity(
                            this.props.sensitivity,
                        )}
                    >
                        {GraphUtilities.formatLegendSensitivity(
                            this.props.sensitivity,
                        )}
                    </span>
                    <span className="scale" style={{ color: this.props.color }}>
                        {this.getSecondsScaleFromTimebase()}
                    </span>
                </div>
            </div>
        )
    }
}

export default Legend
