import React from 'react'
import './DeviceLogs.scss'
import ControlSoftwareLogsApi from '../../services/ControlSoftwareLogsApi'
import DevicesApi from '../../services/DevicesApi'
import LogEntriesTable from './LogEntriesTable'
import LogEntryDetail from './LogEntryDetail'
import NavUtils from '../../utils/NavUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import ToastUtils from '../../utils/ToastUtils'

class DeviceLogs extends React.Component {
    state = {
        log_entries: {},
        is_loading: true,
        selected_log_entry: null,
        device: null,
        selected_index: 0,
    }

    async componentDidMount() {
        this.pageTitle = 'Device Logs'
        NavUtils.setPageTitle(this.pageTitle)

        const deviceID = this.props.match.params.id
        try {
            const deviceResponse = await DevicesApi.getDevice(deviceID)
            const logResponse = await ControlSoftwareLogsApi.getDeviceLogs(
                deviceID,
            )
            this.setDeviceData(logResponse.data, deviceResponse.data)
            window.document.onkeydown = this.handleKeyDown
        } catch (error) {
            ToastUtils.error({ message: 'Error attempt to load data' })
            console.log('[LOGS] Error retrieving device data')
            console.log(error)
        }
    }

    moveSelected(value) {
        let selected_index = this.state.selected_index
        selected_index += value
        selected_index = Math.max(0, selected_index)
        selected_index = Math.min(
            selected_index,
            this.state.log_entries.length - 1,
        )
        const selected_log_entry = this.state.log_entries[selected_index]
        this.setState({ selected_log_entry, selected_index })
        const selectedRowDiv = document.querySelector('.selected')
        if (selectedRowDiv) {
            selectedRowDiv.scrollIntoView({ block: 'center', behavior: 'smooth' })
        }
    }

    handleKeyDown = e => {
        switch (e.key) {
            case 'ArrowDown':
                this.moveSelected(+1)
                e.preventDefault()
                break
            case 'ArrowUp':
                this.moveSelected(-1)
                e.preventDefault()
                break
            default:
        }
    }

    setDeviceData(log_entries, device) {
        this.setState({
            log_entries,
            device,
            is_loading: false,
            selected_log_entry: log_entries[0],
        })
    }

    clickHandler = id => {
        const selected_log_entry = this.state.log_entries.find(
            logEntry => logEntry.ID === id,
        )
        const selected_index = this.state.log_entries.findIndex(
            logEntry => logEntry.ID === id,
        )
        this.setState({ selected_log_entry, selected_index })
    }

    render() {
        const log_entries = this.state.log_entries
        const device = this.state.device
        const selected_log_entry = this.state.selected_log_entry

        return (
            <div className="devicelogs">
                {this.state.is_loading ? (
                    <LoadingSpinner />
                ) : (
                    <React.Fragment>
                        <h3>
                            <i className="fas fa-file-contract" />
                            &nbsp;
                            {this.pageTitle}
                            <div className="extra-info">
                                <span className="info-item">
                                    Device: {device.Name || '<unnamed>'}
                                </span>
                                <span className="info-item">
                                    Serial Number:{' '}
                                    {device.SerialNumber || '<empty>'}
                                </span>
                                <span className="info-item">
                                    Software:{' '}
                                    {device.SoftwareVersion || '<empty>'}
                                </span>
                                <span className="info-item">
                                    Log Entries:{' '}
                                    {log_entries.length || '<empty>'}
                                </span>
                            </div>
                        </h3>
                        {log_entries.length > 0 ? (
                            <LogEntriesTable
                                log_entries={log_entries}
                                click_handler={this.clickHandler}
                                selected={selected_log_entry}
                            />
                        ) : (
                            <p>There are no log entries for this device</p>
                        )}
                        {!!this.state.selected_log_entry && (
                            <React.Fragment>
                                <br />
                                <LogEntryDetail logEntry={selected_log_entry} />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default DeviceLogs
