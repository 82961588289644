import React, { useContext } from 'react'
import InputGroup from '../../components/forms/InputGroup'
import SelectGroup from '../../components/forms/SelectGroup'
import AmplifierType from '../../types/AmplifierType'
import Facility from '../../types/Facility'
import Amplifier, { AmplifierField } from '../../types/Amplifier'
import { hasRequiredRole } from '../../services/Auth'
import AmplifierAuth from '../../auth/AmplifierAuth'
import AppContext from '../../components/AppContext'

interface Rule {
    min: number
    max: number
}

interface ValidationRules {
    Name: Rule
    SerialNumber: Rule
    BluetoothID: Rule
    UsbID: Rule
}

interface Props {
    amplifier: Amplifier
    facilitiesList: Facility[]
    amplifierTypes: AmplifierType[]
    validationRules: ValidationRules
    validation: {
        Name: boolean
        SerialNumber: boolean
        AmplifierTypeID: boolean
        BluetoothID: boolean
        UsbID: boolean
    }
    creating?: boolean
    handleChange: (field: AmplifierField, value: string | boolean | number) => void
}

const AmplifierForm: React.FC<Props> = props => {
    const ctx = useContext(AppContext)

    const facilityOptions = () => {
        return props.facilitiesList.map(facility => ({
            value: facility.ID,
            name: facility.Name,
        }))
    }

    const amplifierTypes = () => {
        return props.amplifierTypes.map(type => ({
            value: type.ID,
            name: type.Name,
        }))
    }

    const canEditAllFields = hasRequiredRole(ctx.Roles, AmplifierAuth.EditAll)

    return (
        <form>
            <InputGroup
                type="text"
                field="Name"
                valid={props.validation.Name}
                error={`A name is required that's ${props.validationRules.Name.max} characters or less`}
                value={props.amplifier.Name}
                label="Name*"
                placeholder="Name"
                maxLength={50}
                handleChange={props.handleChange}
            />

            <InputGroup
                type="text"
                field="SerialNumber"
                valid={props.validation.SerialNumber}
                error={`A serial number is required that's ${props.validationRules.SerialNumber.max} characters or less`}
                label="Serial Number*"
                placeholder="Serial Number"
                disabled={!canEditAllFields}
                maxLength={16}
                value={props.amplifier.SerialNumber}
                handleChange={props.handleChange}
            />

            <InputGroup
                tabIndex="-1"
                type="text"
                field="BluetoothID"
                valid={props.validation.BluetoothID}
                error={`A bluetooth id is required that's ${props.validationRules.BluetoothID.max} characters or less`}
                label="Bluetooth ID"
                placeholder="Bluetooth ID"
                disabled
                maxLength={50}
                value={props.amplifier.BluetoothID || '<Not set>'}
            />

            <InputGroup
                tabIndex="-1"
                type="text"
                field="UsbID"
                valid={props.validation.UsbID}
                error={`A usb id is required that's ${props.validationRules.UsbID.max} characters or less`}
                label="USB ID"
                placeholder="USB ID"
                disabled
                maxLength={50}
                value={props.amplifier.UsbID || '<Not set>'}
            />

            <SelectGroup
                field="AmplifierTypeID"
                label="Type*"
                options={amplifierTypes()}
                value={props.amplifier.AmplifierTypeID}
                valid={props.validation.AmplifierTypeID}
                disabled={!canEditAllFields}
                handleChange={(f: AmplifierField, v: string) => props.handleChange(f, parseInt(v))}
                error="Invalid amplifier selected"
            />
            <SelectGroup
                field="Enabled"
                label="Enabled"
                options={[{ name: 'Yes', value: true }, { name: 'No', value: false }]}
                value={props.amplifier.Enabled}
                disabled={!canEditAllFields}
                handleChange={props.handleChange}
            />
            {props.creating && (
                <SelectGroup
                    field="FacilityID"
                    label="Facility*"
                    options={facilityOptions()}
                    value={props.amplifier.FacilityID}
                    disabled={!canEditAllFields}
                    handleChange={props.handleChange}
                />
            )}
        </form>
    )
}

export default AmplifierForm
