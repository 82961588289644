function ScreenCalibrationMeasurementArrow() {
    const color = 'rgba(255, 255, 255, 0.4)'
    const arrowSize = 20

    const arrow = (dir: 'right' | 'left') => {
        const fauxBorder = `${arrowSize / 2}px solid transparent`
        const border = `${arrowSize}px solid ${color}`
        return (
            <div style={{
                position: 'absolute',
                transform: 'translateY(-50%)',
                [dir]: -arrowSize,
                width: 0,
                height: 0,
                borderTop: fauxBorder,
                borderBottom: fauxBorder,
                borderRight: dir === 'left' ? border : undefined,
                borderLeft: dir === 'right' ? border : undefined,
            }} />
        )
    }

    const lineBorder = `1px dashed ${color}`

    return (
        <div style={{
            width: `calc(100% - ${arrowSize * 2}px)`,
            borderBottom: lineBorder,
            borderTop: lineBorder,
            position: 'absolute',
            top: '50%',
            left: arrowSize,
            transform: 'translateY(-50%)',
        }}>
            {arrow('right')}
            {arrow('left')}
        </div>
    )
}

export default ScreenCalibrationMeasurementArrow
