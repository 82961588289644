import React from 'react'
import './PrintModal.scss'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import JsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import DateTimeUtils from '../../utils/DateTimeUtils'
import GraphUtilities from './utils/GraphUtilities'
import {
    SETTING_TIMEBASE,
    SETTING_SENSITIVITY,
} from '../../constants/graphsettings'
import { IN_PROGRESS } from '../../constants/studystates'

const PDF_WIDTH = 842
const PDF_HEIGHT = 595
const PDF_VERT_PADDING = 30
const PDF_HORIZ_PADDING = 15
const PDF_INFO_HEIGHT = 30
const PDF_INFO_MARGIN_BOTTOM = 10
const PDF_MAX_GRAPH_WIDTH = PDF_WIDTH - PDF_HORIZ_PADDING * 2
const PDF_MAX_GRAPH_HEIGHT =
    PDF_HEIGHT - PDF_VERT_PADDING * 2 - PDF_INFO_HEIGHT - PDF_INFO_MARGIN_BOTTOM
const PDF_FONT_SIZE = 8
const PDF_LINE_HEIGHT = 1.5

const CANVAS_IMG_FORMAT = 'image/png'
const PDF_IMG_FORMAT = 'PNG'

class PrintModal extends React.Component {
    state = {
        creating_pdf: false,
        maintain_graph_scale: true,
        graph_width: 0,
        graph_height: 0,
        gutter_top: 0,
        gutter_bottom: 0,
        pdf_cursor_position: { x: PDF_HORIZ_PADDING, y: PDF_VERT_PADDING },
        show_patient_name: false,
        show_patient_dob: false,
        show_study_start_date: false,
        show_study_end_date: false,
        show_study_duration: false,
        show_time_base: false,
        show_sensitivity: false,
        show_montage_name: false,
    }

    pdf = new JsPDF({ unit: 'pt', orientation: 'landscape' })

    componentDidMount() {
        this.setGraphDimensions()
    }

    setGraphDimensions = () => {
        const totalWidthInPixels =
            this.props.electroGraph.graphWidth +
            this.props.electroGraph.gutter.left +
            this.props.electroGraph.gutter.right
        const totalHeightInPixels =
            this.props.electroGraph.graphHeight +
            this.props.electroGraph.gutter.top +
            this.props.electroGraph.gutter.bottom
        let graph_width = PDF_MAX_GRAPH_WIDTH
        let graph_height = PDF_MAX_GRAPH_HEIGHT

        if (this.state.maintain_graph_scale) {
            const window_aspect = PDF_MAX_GRAPH_WIDTH / PDF_MAX_GRAPH_HEIGHT
            const graph_aspect = totalWidthInPixels / totalHeightInPixels
            let scale
            if (window_aspect < graph_aspect) {
                scale = PDF_MAX_GRAPH_WIDTH / totalWidthInPixels
            } else {
                scale = PDF_MAX_GRAPH_HEIGHT / totalHeightInPixels
            }
            graph_height = totalHeightInPixels * scale
            graph_width = totalWidthInPixels * scale
        }

        this.setState({
            graph_width,
            graph_height,
            gutter_top:
                (graph_height * this.props.electroGraph.gutter.top) /
                totalHeightInPixels,
            gutter_bottom:
                (graph_height * this.props.electroGraph.gutter.bottom) /
                totalHeightInPixels,
        })
    }

    handlePrint = () => {
        this.setState({ creating_pdf: true })

        // This freezes the UI ~1 second. Timeout allows rerender with state.creating_pdf: true
        // to show user that it is working.
        setTimeout(async () => {
            this.pdf.setFontSize(PDF_FONT_SIZE)
            this.writePDFInfo()
            await this.addPDFImages()
            window.open(URL.createObjectURL(this.pdf.output('blob')))
            this.setState({ creating_pdf: false })
            this.props.toggle()
        }, 10)
    }

    writePDFInfo = () => {
        if (this.state.show_patient_name === true) {
            this.writePDFLine(
                `Patient Name: ${this.props.patient.LastName}, ${this.props.patient.FirstName
                }`,
            )
        }
        if (this.state.show_patient_dob === true) {
            this.writePDFLine(
                `Date of Birth: ${DateTimeUtils.getFriendlyDateOfBirth(
                    this.props.patient.DateOfBirth,
                )}`,
            )
        }
        this.setState({
            pdf_cursor_position: { x: PDF_WIDTH / 2, y: PDF_VERT_PADDING },
        })
        if (this.state.show_study_start_date === true) {
            const firstRecording = this.props.study.StudyRecordings[0]
            this.writePDFLine(
                `Study Start Date: ${DateTimeUtils.getFullStudyDateTimestampTimeZone(
                    firstRecording.DateStarted,
                    firstRecording.TimeZone,
                )}`,
            )
        }
        if (this.state.show_study_end_date === true) {
            if (this.props.study.StudyState.ID !== IN_PROGRESS) {
                const lastRecording = this.props.study.StudyRecordings[
                    this.props.study.StudyRecordings.length - 1
                ]
                this.writePDFLine(
                    `Study End Date: ${DateTimeUtils.getFullStudyDateTimestampTimeZone(
                        lastRecording.DateStopped,
                        lastRecording.TimeZone,
                    )}`,
                )
            } else {
                this.writePDFLine('Study End Date: In Progress')
            }
        }
        if (this.state.show_study_duration === true) {
            this.writePDFLine(
                `Study Duration: ${DateTimeUtils.getStudyDuration(
                    this.props.study.TotalStudyRecordingDuration,
                )}`,
            )
        }
        if (this.state.show_time_base === true) {
            this.writePDFLine(
                `Time Base: ${GraphUtilities.getSettingDisplayString(
                    SETTING_TIMEBASE,
                    this.props.settings.Timebase.Value,
                )}`,
            )
        }
        if (this.state.show_sensitivity === true) {
            this.writePDFLine(
                `Sensitivity: ${GraphUtilities.getSettingDisplayString(
                    SETTING_SENSITIVITY,
                    this.props.settings.Sensitivity.Value,
                )}`,
            )
        }
        if (this.state.show_montage_name === true) {
            this.writePDFLine(
                `Montage Name: ${this.props.settings.Montage.Name}`,
            )
        }
    }

    addPDFImages = async () => {
        const elementsToHide = [
            document.querySelector('#GraphChannelMenu'),
            document.querySelector('.scrubber-wrapper'),
        ]
        for (const el of elementsToHide) {
            el.style.display = 'none'
        }

        const graphPanel = document.querySelector('#GraphPanel')
        graphPanel.style.height = `${graphPanel.clientHeight}px`
        graphPanel.style.width = `${graphPanel.clientWidth}px`

        const eventsCanvas = await html2canvas(graphPanel, {
            backgroundColor: null,
        })
        this.pdf.addImage(
            eventsCanvas.toDataURL(CANVAS_IMG_FORMAT),
            PDF_IMG_FORMAT,
            PDF_HORIZ_PADDING,
            PDF_INFO_HEIGHT +
            PDF_VERT_PADDING +
            PDF_INFO_MARGIN_BOTTOM +
            this.state.gutter_top,
            this.state.graph_width,
            this.state.graph_height + this.state.gutter_bottom,
        )
        for (const el of elementsToHide) {
            el.style.display = ''
        }
        graphPanel.style.height = null
        graphPanel.style.width = null
    }

    writePDFLine = txt => {
        this.pdf.text(
            txt,
            this.state.pdf_cursor_position.x,
            this.state.pdf_cursor_position.y,
        )
        if (
            this.state.pdf_cursor_position.y >
            PDF_VERT_PADDING + PDF_INFO_HEIGHT - PDF_FONT_SIZE * PDF_LINE_HEIGHT
        ) {
            const x =
                this.state.pdf_cursor_position.x + Math.floor(PDF_WIDTH / 4)
            const y = PDF_VERT_PADDING
            const pdf_cursor_position = { x, y }
            this.setState({ pdf_cursor_position })
        } else {
            const x = this.state.pdf_cursor_position.x
            const y =
                this.state.pdf_cursor_position.y +
                PDF_FONT_SIZE * PDF_LINE_HEIGHT
            const pdf_cursor_position = { x, y }
            this.setState({ pdf_cursor_position })
        }
    }

    handleShowInfoChange = (field, value) => this.setState({ [field]: value })

    handleShowAllInfo = () => {
        const show = this.isAllInfoSelected() !== true
        this.setState({
            show_patient_name: show,
            show_patient_dob: show,
            show_study_start_date: show,
            show_study_end_date: show,
            show_study_duration: show,
            show_time_base: show,
            show_sensitivity: show,
            show_montage_name: show,
        })
    }

    handleMaintainGraphScale = e => {
        this.setState(
            {
                maintain_graph_scale: e.target.checked,
            },
            this.setGraphDimensions,
        )
    }

    isAllInfoSelected = () =>
        this.state.show_patient_name &&
        this.state.show_patient_dob &&
        this.state.show_study_start_date &&
        this.state.show_study_end_date &&
        this.state.show_study_duration &&
        this.state.show_time_base &&
        this.state.show_sensitivity &&
        this.state.show_montage_name

    render() {
        return (
            <Modal isOpen toggle={this.props.toggle} className="print-modal">
                <ModalHeader toggle={this.props.toggle}>
                    Print Epoch
                </ModalHeader>
                <ModalBody>
                    <div className="body">
                        <p>
                            <input
                                type="checkbox"
                                onChange={this.handleMaintainGraphScale}
                                checked={this.state.maintain_graph_scale}
                            />
                            Maintain Graph Scale
                        </p>
                        <hr />
                        <p>What information should be shown?</p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={this.handleShowAllInfo}
                                checked={this.isAllInfoSelected()}
                            />
                            Show Everything
                        </p>
                        <hr />
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_patient_name',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_patient_name}
                            />
                            Patient Name
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_patient_dob',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_patient_dob}
                            />
                            Patient Date of Birth
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_study_start_date',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_study_start_date}
                            />
                            Study Start Date
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_study_end_date',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_study_end_date}
                            />
                            Study End Date
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_study_duration',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_study_duration}
                            />
                            Study Duration
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_time_base',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_time_base}
                            />
                            Time Base
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_sensitivity',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_sensitivity}
                            />
                            Sensitivity
                        </p>
                        <p>
                            <input
                                type="checkbox"
                                onChange={e =>
                                    this.handleShowInfoChange(
                                        'show_montage_name',
                                        e.target.checked,
                                    )
                                }
                                checked={this.state.show_montage_name}
                            />
                            Montage Name
                        </p>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outlined" onClick={this.props.toggle}>
                        Cancel
                    </Button>
                    <Button
                        onClick={this.handlePrint}
                        disabled={this.state.creating_pdf}
                        sx={{ ml: 2 }}
                    >
                        {this.state.creating_pdf ? 'Creating PDF...' : 'Print'}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default PrintModal
