import _zxcvbn from 'zxcvbn'

interface Result {
    feedback: {
        warning: string
    }
}

export function zxcvbn(s: string): Result {
    const result: Result = _zxcvbn(s)

    if (!result.feedback.warning) {
        if (s.startsWith(' ')) {
            result.feedback.warning = 'Can not start with a space'
        } else if (s.endsWith(' ')) {
            result.feedback.warning = 'Can not end with a space'
        }
    }

    return result
}
