import axios from 'axios'
import MMTApi from './MMTApi'

const ChannelTypesApi = {
    getSupportedChannelTypes() {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/ChannelTypes/GetSupportedChannelTypes`,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default ChannelTypesApi
