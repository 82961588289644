import { Link, NavLink } from 'react-router-dom'
import React, { useEffect, useState, useCallback } from 'react'
import AmplifiersApi from '../../services/AmplifiersApi'
import RoleBasedContent from '../../components/RoleBasedContent'
import NavUtils from '../../utils/NavUtils'
import AmplifierInfoRow from './AmplifierInfoRow'
import Amplifier from '../../types/Amplifier'
import { AMP_FRIENDLY_NAMES } from '../../constants/amplifiertypes'
import SortUtils, { KeysOfTypedValue, SortDir } from '../../utils/SortUtils'
import useOpenableTableRow from '../../utils/useOpenableTableRow'
import SortHeader from '../../components/SortHeader'
import LoadingSpinner from '../../components/LoadingSpinner'
import FailedToLoad from '../../components/FailedToLoad'
import AmplifierAuth from '../../auth/AmplifierAuth'
import NeutralButton from '../../components/NeutralButton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const Amplifiers: React.FC = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [didGetAmplifiersFail, setDidGetAmplifiersFail] = useState(false)
    const [amplifiers, setAmplifiers] = useState<Amplifier[]>([])
    const [sortedBy, setSortedBy] = useState<KeysOfTypedValue<Amplifier, string>>('Name')
    const [sortedDir, setSortedDir] = useState<SortDir>('asc')
    const rows = useOpenableTableRow()

    const getAmplifiers = useCallback(() => {
        setAmplifiers([])
        setIsLoading(true)
        setDidGetAmplifiersFail(false)

        AmplifiersApi.getAmplifiers()
            .then(res => {
                const amplifiersPlus = res.data.map((obj: Amplifier) => (
                    { ...obj, FriendlyName: AMP_FRIENDLY_NAMES[obj.AmplifierTypeID],
                        FirmwareVersionString: obj.FirmwareVersion ? obj.FirmwareVersion : 'Unavailable' }
                ))
                setAmplifiers(amplifiersPlus)
            })
            .catch(() => setDidGetAmplifiersFail(true))
            .finally(() => setIsLoading(false))
    }, [])

    useEffect(() => {
        NavUtils.setPageTitle('Amplifiers')
        getAmplifiers()
    }, [getAmplifiers])

    const handleSort = (newSortedBy: KeysOfTypedValue<Amplifier, string>) => {
        let newSortedDir: SortDir = 'desc'
        if (sortedBy !== newSortedBy || sortedDir === 'desc') {
            newSortedDir = 'asc'
        }
        setSortedDir(newSortedDir)
        setSortedBy(newSortedBy)
    }

    SortUtils.string(amplifiers, sortedBy, sortedDir)
    const noAmpsFound = !isLoading && !didGetAmplifiersFail && amplifiers?.length === 0

    const pageHeader = () => {
        const countMessage = isLoading ? 'Loading...' :
            `${noAmpsFound ? 'No' : amplifiers.length} amplifiers found`

        return (
            <div style={{ marginBottom: 10 }}>
                <h3 style={{ marginBottom: 3 }}>
                    <i className="icon-electrotek-amplifier" />{' '}
                    Amplifiers
                    <RoleBasedContent required_roles={AmplifierAuth.Create}>
                        <NeutralButton
                            sx={{ marginTop: 2 }}
                            component={NavLink}
                            className="btn btn-secondary pull-right"
                            to="/Amplifier/Create"
                        >
                            Create
                        </NeutralButton>
                    </RoleBasedContent>
                </h3>
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
                {isLoading && (<LoadingSpinner />)}
            </div>
        )
    }

    const tableHeader = () => (
        <TableHead>
            <TableRow>
                <SortHeader
                    text="Serial"
                    field="SerialNumber"
                    onSort={handleSort}
                    by={sortedBy}
                    dir={sortedDir}
                />
                <SortHeader
                    text="Name"
                    field="Name"
                    onSort={handleSort}
                    by={sortedBy}
                    dir={sortedDir}
                />
                <SortHeader
                    text="Type"
                    field="FriendlyName"
                    onSort={handleSort}
                    by={sortedBy}
                    dir={sortedDir}
                />                
                <SortHeader
                    text="Firmware"
                    field="FirmwareVersionString"
                    onSort={handleSort}
                    by={sortedBy}
                    dir={sortedDir}
                />                               
                <RoleBasedContent required_roles={[...AmplifierAuth.View, ...AmplifierAuth.Create, ...AmplifierAuth.EditName]}>
                    <TableCell />
                </RoleBasedContent>
            </TableRow>
        </TableHead>
    )

    if (isLoading || noAmpsFound)
        return pageHeader()

    return (
        <div className="animated fadeIn">
            {pageHeader()}
            <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                <Table stickyHeader>
                    {tableHeader()}
                    <TableBody>
                        {amplifiers.map(amplifier => {
                            let versionProps = {}
                            const firmwareVersDisplay = amplifier.FirmwareVersionString
                            if (!amplifier.FirmwareVersion) {
                                versionProps = { color: 'rgba(255, 255, 255, 0.50)', fontStyle: 'italic' }
                            }
                            return (
                                <React.Fragment key={amplifier.ID}>
                                    <TableRow>
                                        <TableCell>{amplifier.SerialNumber}</TableCell>
                                        <TableCell>{amplifier.Name}</TableCell>
                                        <TableCell>{amplifier.FriendlyName}</TableCell>
                                        <TableCell sx={{ ...versionProps }} >{firmwareVersDisplay}</TableCell>                                    
                                        <TableCell className="text-right">
                                            <div className="row-action-icons">
                                                <RoleBasedContent required_roles={AmplifierAuth.EditName}>
                                                    <IconButton
                                                        title="Edit"
                                                        component={Link}
                                                        to={`/Amplifier/${amplifier.ID}`}
                                                        color="primary">
                                                        <i className="fa fa-pencil-alt" />
                                                    </IconButton>
                                                </RoleBasedContent>
                                                <RoleBasedContent required_roles={AmplifierAuth.View}>
                                                    <IconButton
                                                        title="Info"
                                                        onClick={() => rows.toggle(amplifier.ID)}
                                                        color="primary">
                                                        <i className="fa fa-info" />
                                                    </IconButton>
                                                </RoleBasedContent>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                    {
                                        rows.isOpen(amplifier.ID) &&
                                        ( <AmplifierInfoRow amplifier={amplifier} /> )
                                    }

                                </React.Fragment>
                            )}
                        )}
                        {!amplifiers.length && (
                            <TableRow>
                                <TableCell
                                    colSpan={5}
                                    style={{ textAlign: 'center' }}
                                >
                                    {didGetAmplifiersFail && (
                                        <FailedToLoad
                                            items="amplifiers"
                                            onRetry={getAmplifiers}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <br />
        </div>
    )
}

export default Amplifiers
