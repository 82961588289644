import React from 'react'
import './EventMarkers.scss'
import EventMarker from './EventMarker'
import AppContext from '../../../../components/AppContext'
import {
    EFN,
    VIDEO_SYNC,
    VIDEO_START,
} from '../../../../constants/studyevents'
import { DEFAULT_EVENT_COLOR } from '../constants'

class EventMarkers extends React.Component {
    static contextType = AppContext

    render() {
        const events = []
        for (const event of this.context.Study.StudyEvents) {
            if (event.EventTypeID === VIDEO_SYNC || (event.EventTypeID === VIDEO_START && (!!event.Value && JSON.parse(event.Value).VideoIndex > 1))) continue
            const startIndex = this.props.calculateSampleIndex(
                event.RecordingIndex,
                event.StartPacketIndex,
            )
            let endIndex = startIndex
            if (event.EndPacketIndex !== null) {
                endIndex += event.EndPacketIndex - event.StartPacketIndex
            }

            const sampleEndIndex =
                this.props.sampleOffset + this.props.samplesPerWindow
            const isStartInEpoch =
                startIndex >= this.props.sampleOffset &&
                startIndex < sampleEndIndex
            const isEndInEpoch =
                endIndex >= this.props.sampleOffset &&
                endIndex < sampleEndIndex
            const doesEventEncompassEpoch =
                startIndex < this.props.sampleOffset &&
                endIndex > sampleEndIndex
            const isInRange =
                isStartInEpoch || isEndInEpoch || doesEventEncompassEpoch

            if (isInRange) {
                const adjustedStartIndex =
                    startIndex - this.props.sampleOffset
                const adjustedEndIndex =
                    endIndex - this.props.sampleOffset
                const recordingMinIndex = this.props.calculateSampleIndex(
                    event.RecordingIndex,
                    1,
                )
                let recordingMaxIndex = 0
                for (let i = 1; i <= event.RecordingIndex; i += 1) {
                    recordingMaxIndex += this.props.recordingPacketCounts[i]
                }
                events.push({
                    ...event,
                    adjustedStartIndex,
                    adjustedEndIndex,
                    recordingMinIndex,
                    recordingMaxIndex,
                })
            }
        }

        events.sort((a, b) => {
            if (a.EndPacketIndex === null) return 1
            if (b.EndPacketIndex === null) return -1
            return (
                b.EndPacketIndex -
                b.StartPacketIndex -
                (a.EndPacketIndex - a.StartPacketIndex)
            )
        })

        return (
            <div
                className="event-markers"
                style={{
                    left: `${this.props.gutter.left}px`,
                    right: `${this.props.gutter.right}px`,
                    top: `${this.props.gutter.top}px`,
                    bottom: `${this.props.gutter.bottom +
                        this.props.scrubberHeight}px`,
                }}
            >
                {this.props.creatingEventOfType !== null ? (
                    <EventMarker
                        samplesPerWindow={this.props.samplesPerWindow}
                        sampleOffset={this.props.sampleOffset}
                        gutter={this.props.gutter}
                        graphWidth={this.props.graphWidth}
                        isSelected
                        creatingEventOfType={this.props.creatingEventOfType}
                        creatingEventOnChannel={
                            this.props.creatingEventOnChannel
                        }
                        onExitCreatingEvent={this.props.onExitCreatingEvent}
                        calculateSampleIndex={this.props.calculateSampleIndex}
                        recordingPacketCounts={this.props.recordingPacketCounts}
                        getPackets={this.props.getPackets}
                        montage={this.props.montage}
                        onUpdateEvent={this.props.onUpdateEvent}
                        onCreateEvent={this.props.onCreateEvent}
                        event={{
                            EventType: {
                                ID: this.props.creatingEventOfType,
                                Color: DEFAULT_EVENT_COLOR,
                                Name: EFN[this.props.creatingEventOfType],
                            },
                            ChannelName: this.props.creatingEventOnChannel
                                ? this.props.creatingEventOnChannel.label
                                : null,
                            ChannelTypeId: this.props.creatingEventOnChannel
                                ? this.props.creatingEventOnChannel.type
                                : 0,
                            Value: '',
                            StartPacketIndex: null,
                            EndPacketIndex: null,
                            RecordingIndex: 0,
                            EventTypeID: this.props.creatingEventOfType,
                        }}
                        getChannelScale={this.props.getChannelScale}
                        channels={this.props.channels}
                    />
                ) : (
                    events.map(event => (
                        <EventMarker
                            key={`${event.ID}${event.Comment}${event.Important}`}
                            event={event}
                            timebase={this.props.timebase}
                            samplesPerWindow={this.props.samplesPerWindow}
                            sampleOffset={this.props.sampleOffset}
                            graphWidth={this.props.graphWidth}
                            isSelected={this.props.selectedStudyEvent === event.ID}
                            creatingEventOfType={null}
                            onSelectEvent={this.props.onSelectEvent}
                            montage={this.props.montage}
                            calculateSampleIndex={this.props.calculateSampleIndex}
                            getPackets={this.props.getPackets}
                            onUpdateEvent={this.props.onUpdateEvent}
                            onMoveGraphToStudyEvent={this.props.onMoveGraphToStudyEvent}
                            onDeleteStudyEvent={this.props.onDeleteStudyEvent}
                            onEditStudyEvent={this.props.onEditStudyEvent}
                            getChannelScale={this.props.getChannelScale}
                            channels={this.props.channels}
                            clockSetting={this.props.clockSetting}
                        />
                    ))
                )}
            </div>
        )
    }
}

export default EventMarkers
