import React from 'react'
import {
    ResponsiveContainer,
    LineChart,
    XAxis,
    YAxis,
    Line,
    Tooltip,
    CartesianGrid,
} from 'recharts'
import moment from 'moment'
interface UploadSpeedDataPoint {
    timestamp: number // Date.now() -> 1625846691089 -> 2021-July-09
    bytesPerMs: number // 1000 -> 1000000 bytes/sec -> 1 megabyte/sec -> 8 megabits/sec
}
interface Props {
    data: UploadSpeedDataPoint[]
}

function bytesPerMsToMbps(bytesPerMs: number): string {
    const val = (bytesPerMs * .008)
    const decPlaces = val.toString().split('.')
    const shouldFix = !!decPlaces[1] && decPlaces[1].length > 2
    return shouldFix ? val.toFixed(2).toString() : val.toString()
}

function ImportStudyUploadGraph(props: Props) {
    const CustomTooltip = (props: { active?: boolean, label?: string, payload?: any }) => {
        if (props.active && !!props.payload && props.payload.length > 0) {
            return (
                <div className="chart-tooltip">
                    <div className="speed">{props.payload[0].value > 0 ? (props.payload[0].value * .008).toFixed(2) : 0} Mbps</div>
                    <div className="date">{moment(props.label).format('h:mm:ss a')}</div>
                </div>
            )
        }
        return null
    }

    return (
        <ResponsiveContainer width="100%" height={200} className="chart-container">
            <LineChart data={props.data} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
                <Line isAnimationActive={false} type="monotone" dataKey="bytesPerMs" stroke="#2451B7" strokeWidth="2" fill="url(#color)" />

                <XAxis
                    dataKey="timestamp"
                    axisLine={false}
                    tickLine={true}
                    tick={false}
                />

                <YAxis
                    dataKey="bytesPerMs"
                    axisLine={false}
                    tickLine={false}
                    tickCount={8}
                    tickFormatter={bytesPerMs => bytesPerMsToMbps(bytesPerMs)}
                    tick={{ fill: 'white' }}
                    label={{ value: 'Mbps', angle: -90, position: 'insideLeft', fill: 'white' }}
                />

                <Tooltip content={CustomTooltip} />

                <CartesianGrid strokeDasharray="3" fill="rgba(255, 255, 255, 0.05)" />
            </LineChart>
        </ResponsiveContainer>
    )
}

export default ImportStudyUploadGraph
