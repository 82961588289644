import React, { useContext, useState } from 'react'
import './ChangePatientModal.scss'
import AppContext from '../../../components/AppContext'
import SearchPatient from '../../../components/forms/SearchPatient'
import Patient from '../../../types/Patient'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Button } from '@mui/material'
import StudiesApi from '../../../services/StudiesApi'
import ToastUtils from '../../../utils/ToastUtils'
import { Action } from '../../../components/AppState'
import PatientCard from '../../../components/cards/PatientCard'
import NavUtils from '../../../utils/NavUtils'

interface Props {
    toggle: () => void
    isOpen: boolean
}

const ChangePatientModal: React.FC<Props> = props => {
    const ctx = useContext(AppContext)

    const [patient, setPatient] = useState<Patient | null>(null)
    const [is_saving, changeIsSaving] = useState<boolean>(false)
    const [is_searching, changeIsSearching] = useState<boolean>(false)

    const handleAssignPatient = () => {
        changeIsSaving(true)
        if (patient) {
            StudiesApi.assignPatient(ctx.Study.ID, patient.ID)
                .then(() => {
                    ToastUtils.success({ message: 'Patient has successfully been assigned to study' })
                    ctx.commit(Action.SetPatient, patient)
                    changeIsSaving(false)
                    setPatient(null)
                    props.toggle()
                    NavUtils.setPageTitle(
                        `${patient.LastName}, ${patient.FirstName}`,
                    )
                })
                .catch(error => {
                    console.log(error)
                    ToastUtils.error({ message: 'There has been an issue assigning patient to study' })
                    changeIsSaving(false)
                    setPatient(null)
                })
        }
    }

    const handleSetPatient = (patient: Patient) => {
        setPatient(patient)
        changeIsSearching(false)
    }

    const handleCancel = () => {
        props.toggle()
        setPatient(null)
        changeIsSearching(false)
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-lg change-patient-modal">
            <ModalHeader toggle={props.toggle}>
                Change Patient
            </ModalHeader>
            <ModalBody>
                <div className="container py-4">
                    <div className="row align-self-center mx-2">
                        {
                            is_searching &&
                            <SearchPatient handlePatient={patient => handleSetPatient(patient)} isPatientSelected={patient !== null} />
                        }
                        {
                            !is_searching &&
                            <>
                                <div className="col-md-5">
                                    <h5 className="text-muted">From this patient:</h5>
                                    <PatientCard
                                        patient={ctx.Study.Patient}
                                        isOld
                                    />
                                </div>

                                <div className="col-md-2 align-self-center text-center transition">
                                    <span>
                                        <i className="icon icon-electrotek-arrow-right" />
                                    </span>
                                </div>

                                <div className="col-md-5">
                                    <h5 className="text-success">To this patient:</h5>
                                    {
                                        patient === null &&
                                        <div className="card shadow-none border-light add-patient-card" onClick={() => changeIsSearching(true)}>
                                            <div className="mt-2 card-body text-center align-middle">
                                                <i className="align-self-center icon icon-electrotek-search add-patient-icon" />
                                                <p><small>Search for a patient</small></p>
                                            </div>
                                        </div>
                                    }
                                    {
                                        patient !== null &&
                                        <PatientCard
                                            patient={patient}
                                            handleRemove={() => setPatient(null)}
                                            canRemove
                                        />
                                    }
                                </div>
                            </>
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button variant="outlined" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button
                    sx={{ ml: 2  }}
                    onClick={handleAssignPatient}
                    disabled={is_saving || !patient}
                >
                    {is_saving ? 'Assigning...' : 'Assign'}
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default ChangePatientModal
