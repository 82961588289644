import { PropsWithChildren, useContext } from 'react'
import { useTheme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import Box from '@mui/material/Box'
import drawerHeaderMixin from './drawerHeaderMixin'
import DrawerHeader from './DrawerHeader'
import AppContext from '../AppContext'

const sx: SxProps = {
    flexGrow: 1,
    p: 3,
    height: '100vh',
    backgroundImage: `radial-gradient(
        farthest-corner at 0 0,
        rgba(22,65,88,.8) 0,
        rgba(43,150,204,.8) 50%,
        rgba(43,150,204,.8) 60%,
        rgba(22,65,88,.8) 100%
    )`,
    overflowY: 'auto',
}

function Content(props: PropsWithChildren<any>) {
    const theme = useTheme()
    const ctx = useContext(AppContext)

    return (
        <Box component="main" sx={sx}>
            {!ctx.AreGraphControlsMinimized && (
                <DrawerHeader sx={{ ...drawerHeaderMixin(theme) }} />
            )}
            {props.children}
        </Box>
    )
}

export default Content
