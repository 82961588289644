class FileUtils {
    static read(file: File, charCount: number, offset = 0): Promise<string> {
        const reader = new FileReader()
        return new Promise((resolve, reject) => {
            reader.onerror = reject
            reader.onload = () => {
                if (reader.result !== null) {
                    resolve(reader.result.toString())
                } else {
                    reject('No target.result on FileReader onload event')
                }
            }
            reader.readAsText(file.slice(offset, offset + charCount))
        })
    }
}

export default FileUtils
