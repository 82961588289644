const FullScreenUtilities = {
    available() {
        const canHaveFullscreenElement =
            document.fullscreenElement !== undefined ||
            document.webkitFullscreenElement !== undefined
        const canExitFullscreen =
            document.exitFullscreen !== undefined ||
            document.webkitExitFullscreen !== undefined
        return canHaveFullscreenElement && canExitFullscreen
    },

    enter() {
        const app = document.querySelector('.app')
        if (app.requestFullscreen) {
            app.requestFullscreen()
        } else if (app.webkitRequestFullScreen) {
            app.webkitRequestFullScreen()
        }
    },

    exit() {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        }
    },

    enabled() {
        return (
            (!document.fullscreenElement &&
                !document.webkitFullscreenElement) === false
        )
    },

    registerChangeListener(listener) {
        document.addEventListener('webkitfullscreenchange', listener)
        document.addEventListener('fullscreenchange', listener)
    },

    unregisterChangeListener(listener) {
        document.removeEventListener('webkitfullscreenchange', listener)
        document.removeEventListener('fullscreenchange', listener)
    },
}

export default FullScreenUtilities
