import axios from 'axios'
import MMTApi from './MMTApi'

class FacilitiesApi {
    static acceptInvitation(guid: string, data: any) {
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/api/Facilities/AcceptInvitation?invitationId=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    }

    static getFacilities() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Facilities/GetFacilities`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getFacility(guid: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Facilities/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getPreferences(id: string, ...names: string[]) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/FacilityPreferences/GetPreferences?id=${id}&typesCsv=${names.join(',')}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static createFacility(data: any) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Facilities/CreateFacility`,
            data,
            MMTApi.getRequestHeaders(),
        )
    }

    static updateFacility(guid: string, data: any) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/Facilities/?id=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    }

    static sendUserInvite(email: string, role: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Facilities/SendInvitation?email=${encodeURIComponent(email)}&roleid=${role}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static doesInvitedUserExist(guid: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Facilities/DoesInvitedUserExist?id=${guid}`,
        )
    }

    static isInviteExpired(guid: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Facilities/IsInvitationExpired?id=${guid}`,
        )
    }

    static getInvitation(guid: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Facilities/GetInvitation?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getSupportedNetworkCameras() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Facilities/GetSupportedNetworkCameras`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getFacilityNetworkCameras() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Facilities/GetFacilityNetworkCameras`,
            MMTApi.getRequestHeaders(),
        )
    }

    static addFacilityNetworkCamera(networkCameraId: string, deviceId: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Facilities/AddFacilityNetworkCamera?networkCameraId=${networkCameraId}&deviceId=${deviceId || ''}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static removeFacilityNetworkCamera(facilityNetworkCameraId: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Facilities/RemoveFacilityNetworkCamera?facilityNetworkCameraId=${facilityNetworkCameraId}`,
            MMTApi.getRequestHeaders(),
        )
    }
}

export default FacilitiesApi
