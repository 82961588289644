import React from 'react'
import { HEAD_HEIGHT_RATIO } from './constants'

interface Props {
    width: number
    children?: any
}

function Head(props: Props) {
    return (
        <div
            style={{
                width: props.width,
                height: props.width * HEAD_HEIGHT_RATIO,
            }}
        >
            <img
                style={{ width: '100%' }}
                src="/assets/img/Male_Top2.png"
                alt="Head"
            />
            {props.children}
        </div>
    )
}

export default Head
