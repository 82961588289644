import React, { useEffect, useState, useCallback, useContext, useRef } from 'react'
import './PatientDocuments.scss'
import PatientsApi from '../../services/PatientsApi'
import Patient from '../../types/Patient'
import Document from '../../types/Document'
import FacilityPreference from '../../types/FacilityPreference'
import FacilityPreferenceType from '../../types/FacilityPreferenceType'
import ToastUtils from '../../utils/ToastUtils'
import SortHeader from '../../components/SortHeader'
import SortUtils, { SortDir } from '../../utils/SortUtils'
import { FACILITY_ADMIN_ROLE, REVIEW_DOCTOR_ROLE, FIELD_TECH_ROLE, LEAD_TECH_ROLE, OFFICE_PERSONNEL_ROLE, SUPER_ADMIN_ROLE } from '../../constants/roles'
import ModalBool from '../../components/modals/ModalBool'
import { formatStorageSize, randString } from '../../utils/StringUtils'
import { Link, Prompt } from 'react-router-dom'
import AWS from 'aws-sdk'
import AppContext from '../../components/AppContext'
import DateTimeUtils from '../../utils/DateTimeUtils'
import FacilitiesApi from '../../services/FacilitiesApi'
import SessionManager from '../../services/SessionManager'
import { downloadRemoteFile } from '../../utils/DownloadUtils'
import { ManagedUpload } from 'aws-sdk/clients/s3'
import axios from 'axios'
import UserAuth from '../../auth/UserAuth'
import useHasRole from '../../hooks/useHasRole'
import LinearProgress from '@mui/material/LinearProgress'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'

interface Props {
    patient: Patient
    onSetIsDocumentUploading: React.Dispatch<React.SetStateAction<boolean>>
}

interface ProgressBar {
    label: string
    value: number
    color: 'success' | 'info'
    transition: boolean
    totalFileCount: number
    currentFile: number
}

const VALID_EXTENSIONS = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'jpeg']

const key2reference = (key: string) => {
    return key.split('/').pop() ?? key
}

const getS3ServiceObjectFromCreds = (awsBucket: any) => {
    const credentials = new AWS.Credentials(
        awsBucket.AccessKeyId,
        awsBucket.SecretAccessKey,
        awsBucket.SessionToken,
    )

    AWS.config.update({
        region: awsBucket.AwsRegion,
        credentials,
        correctClockSkew: true,
    })

    const params = {
        Bucket: awsBucket.BucketName,
        Prefix: awsBucket.Path,
    }

    return new AWS.S3({
        apiVersion: '2006-03-01',
        params,
        s3ForcePathStyle: true,
        useAccelerateEndpoint: true,
    })
}

const PatientDocuments: React.FC<Props> = props => {
    const ctx = useContext(AppContext)
    const hasEditUserRole = useHasRole(UserAuth.Edit)
    const [maxDocumentsPerPatient, setMaxDocumentsPerPatient] = useState(0)
    const [maxPatientDocumentSize, setMaxPatientDocumentSize] = useState(0)
    const [documents, setDocuments] = useState<Document[]>([])
    const [areDocumentsLoading, setAreDocumentsLoading] = useState(true)
    const [sortedBy, setSortedBy] = useState<keyof Document>('Key')
    const [sortedDir, setSortedDir] = useState<SortDir>('asc')
    const [documentBeingDeleted, setDocumentBeingDeleted] = useState<Document | null>(null)
    const [documentBeingDownloaded, setDocumentBeingDownloaded] = useState<Document | null>(null)
    const [isDropping, setIsDropping] = useState(false)
    const [isLockToggling, setIsLockToggling] = useState(false)
    const [progressBar, setProgressBar] = useState<ProgressBar>({
        label: '',
        value: 0,
        color: 'success',
        transition: true,
        totalFileCount: 0,
        currentFile: 0,
    })
    const folderSelectInput = useRef<HTMLInputElement | null>(null)

    const patientId = props.patient.ID
    const getDocuments = useCallback(() => {
        setAreDocumentsLoading(true)
        setDocuments([])
        const source = axios.CancelToken.source()
        PatientsApi.getDocuments(patientId, source).then((res: any) => {
            setDocuments(res.data)
            setAreDocumentsLoading(false)
        }).catch((err: any) => {
            if (axios.isCancel(err)) return
            console.log(err)
            ToastUtils.error({ message: 'Unable to get patient documents' })
            setAreDocumentsLoading(false)
        })
        return () => source.cancel('New patient documents neededs')
    }, [patientId])

    useEffect(getDocuments, [getDocuments])

    const beforeUnloadListener = (e: any) => {
        e.preventDefault()
        const confirmationMessage = 'Leaving or reloading page will cancel file uploading, are you sure?'
        const event = e || window.event
        event.returnValue = confirmationMessage
        return confirmationMessage
    }

    const facilityId = SessionManager.get('mmt_facility_id')
    useEffect(() => {
        FacilitiesApi.getPreferences(facilityId, FacilityPreferenceType.MaxDocumentsPerPatient, FacilityPreferenceType.MaxPatientDocumentSizeBytes).then(res => {
            const maxDocsPref = res.data.find((p: FacilityPreference) => p.Name === FacilityPreferenceType.MaxDocumentsPerPatient)
            const maxDocSizePref = res.data.find((p: FacilityPreference) => p.Name === FacilityPreferenceType.MaxPatientDocumentSizeBytes)
            if (!maxDocsPref || !maxDocSizePref) {
                ToastUtils.error({ message: 'Unable to get facility preferences' })
                console.log('Invalid facility preferences response', res.data)
                return
            }
            setMaxDocumentsPerPatient(parseInt(maxDocsPref.Value))
            setMaxPatientDocumentSize(parseInt(maxDocSizePref.Value))
        }).catch(err => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to get facility preferences' })
        })
    }, [facilityId])

    useEffect(() => {
        return () =>
            window.removeEventListener('beforeunload', beforeUnloadListener)
    }, [])

    const handleSort = (newSortedBy: string) => {
        const docs = [...documents]
        let newSortedDir: SortDir = 'desc'
        if (
            sortedBy !== newSortedBy ||
            sortedDir === 'desc'
        ) {
            newSortedDir = 'asc'
        }
        switch (newSortedBy) {
            case 'Key':
                SortUtils.string(docs, newSortedBy, newSortedDir)
                break
            case 'LastModified':
                SortUtils.date(docs, newSortedBy, newSortedDir)
                break
            default:
                throw Error(`you can not sort by ${newSortedBy}`)
        }
        setSortedBy(newSortedBy)
        setSortedDir(newSortedDir)
        setDocuments(docs)
    }

    const toggleLock = (doc: Document) => {
        setIsLockToggling(true)
        let isLocked = true
        if (doc.LockerName) {
            isLocked = false
        }
        PatientsApi.setDocumentLock(patientId, key2reference(doc.Key), isLocked).then(() => {
            const docs = [...documents]
            for (const d of docs) {
                if (d.Key === doc.Key) {
                    if (doc.LockerName) {
                        doc.LockerName = ''
                    } else {
                        doc.LockerName = `${window.localStorage.getItem('mmt_user_name')}`
                        doc.Lock = window.localStorage.getItem('mmt_user_id') ?? ''
                    }
                    break
                }
            }
            setDocuments(docs)
        }).catch((err: any) => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to lock patient document' })
        }).finally(() => { setIsLockToggling(false)} )
    }

    const handleDelete = (doc: Document) => {
        PatientsApi.deleteDocument(patientId, key2reference(doc.Key)).then(() => {
            const docs = [...documents]
            for (let i = docs.length - 1; i >= 0; i -= 1) {
                if (docs[i].Key === doc.Key) {
                    docs.splice(i, 1)
                    break
                }
            }
            setDocuments(docs)
            ToastUtils.success({ message: 'Document successfully deleted' })
        }).catch((err: any) => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to delete patient document' })
        })
    }

    const handleDownload = (doc: Document) => {
        const docKey = key2reference(doc.Key)
        PatientsApi.getDocumentDownloadUrl(patientId, docKey).then(res => {
            let fileName = doc.FileName
            if (!fileName) {
                fileName = docKey
            }
            downloadRemoteFile(fileName, res.data)
        }).catch((err: any) => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to download patient document' })
        })
    }

    const uploadFiles = async (files: File[]) => {
        if (documents.length + files.length > maxDocumentsPerPatient) {
            ToastUtils.warning({ message: `You can only have ${maxDocumentsPerPatient} documents for this patient.` })
            return
        }
        props.onSetIsDocumentUploading(true)
        let creds
        try {
            creds = await PatientsApi.getDocumentUploadCreds(patientId)
        } catch (err) {
            console.log(err)
            ToastUtils.error({ message: 'Unable to upload files' })
            props.onSetIsDocumentUploading(false)
            return
        }

        const options: ManagedUpload.ManagedUploadOptions = {
            tags: [
                { Key: 'FacilityID', Value: window.localStorage.getItem('mmt_facility_id') ?? '' },
                { Key: 'PatientID', Value: patientId },
                { Key: 'UserID', Value: window.localStorage.getItem('mmt_user_id') ?? '' },
            ],
            queueSize: 1,
            leavePartsOnError: true,
        }

        let uploadedCnt = 0
        let skippedCnt = 0
        window.addEventListener('beforeunload', beforeUnloadListener)
        for (const file of files) {
            let fileAlreadyExists = false
            for (const doc of documents) {
                if (key2reference(doc.FileName) === file.name) {
                    fileAlreadyExists = true
                    break
                }
            }
            if (fileAlreadyExists) {
                ToastUtils.warning({ message: `Skipping ${file.name}. A file with this name has already been uploaded.` })
                skippedCnt += 1
                continue
            }
            if (file.size > maxPatientDocumentSize) {
                ToastUtils.warning({ message: `Skipping ${file.name}. Patient documents can not exceed ${formatStorageSize(maxPatientDocumentSize)}.` })
                skippedCnt += 1
                continue
            }
            if (!VALID_EXTENSIONS.includes(file.name.split('.').pop()?.toLowerCase() || '')) {
                ToastUtils.warning({ message: `Skipping ${file.name}. Patient documents must be one of ${VALID_EXTENSIONS.join(', ')}.` })
                skippedCnt += 1
                continue
            }
            const s3Key = `${creds.data.Path}/${randString(32)}`
            const params = {
                Body: file,
                Key: s3Key,
                Bucket: creds.data.BucketName,
                Metadata: {
                    'filename': file.name,
                },
            }

            const s3 = getS3ServiceObjectFromCreds(creds.data)
            const managedUpload = s3.upload(params, options)
            setProgressBar({
                label: file.name,
                color: 'info',
                value: 0,
                transition: false,
                totalFileCount: files.length - skippedCnt,
                currentFile: uploadedCnt + 1,
            })
            managedUpload.on('httpUploadProgress', (e: any) => {
                setProgressBar(b => ({
                    ...b,
                    value: 100 * e.loaded / e.total,
                }))
            })
            await new Promise<void>((resolve, reject) => {
                const attemptSend = () => {
                    managedUpload.send((err: any) => {
                        if (err) reject(err)
                        resolve()
                    })
                }
                attemptSend()
            }).then(async () => {
                await PatientsApi.logDocumentCreated(patientId, key2reference(s3Key), file.name)
            })
            uploadedCnt += 1
        }
        window.removeEventListener('beforeunload', beforeUnloadListener)
        props.onSetIsDocumentUploading(false)
        setProgressBar(b => ({ ...b, label: '' }))
        if (uploadedCnt > 0) {
            getDocuments()
            ToastUtils.success({ message: `${uploadedCnt} document${uploadedCnt > 1 ? 's' : ''} successfully uploaded` })
        }
    }

    const preventDefault = (e: { preventDefault: () => void }) => {
        e.preventDefault()
    }

    const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
        preventDefault(e)
        setIsDropping(false)
        await uploadFiles(Array.from(e.dataTransfer.files))
    }

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        preventDefault(e)
        setIsDropping(true)
    }

    const handleFolderSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            await uploadFiles(Array.from(files))
            if (folderSelectInput.current) {
                folderSelectInput.current.value = ''
            }
        }
    }

    const isReviewDoctor = ctx.Roles.includes(REVIEW_DOCTOR_ROLE)
    const isFieldTech = ctx.Roles.includes(FIELD_TECH_ROLE)
    const isLeadTech = ctx.Roles.includes(LEAD_TECH_ROLE)
    const isOfficePersonnel = ctx.Roles.includes(OFFICE_PERSONNEL_ROLE)
    const isFacilityAdmin = ctx.Roles.includes(FACILITY_ADMIN_ROLE)
    const isSuperAdmin = ctx.Roles.includes(SUPER_ADMIN_ROLE)
    const doesUserRoleAllowDocumentLocking = isLeadTech || isFieldTech || isOfficePersonnel || isFacilityAdmin || isReviewDoctor

    return (
        <div className="patient-documents" onDragEnter={handleDragEnter}>
            <Prompt
                when={!!progressBar.label}
                message="Leaving this page will cancel any remaining uploads, are you sure?"
            />

            {progressBar.label ? (
                <>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                        <span style={{ float: 'right' }}>Uploading {progressBar.currentFile} of {progressBar.totalFileCount}</span>
                        {progressBar.label}
                    </div>
                    <LinearProgress
                        variant="determinate"
                        color={progressBar.color}
                        value={progressBar.value}
                        sx={{ marginBottom: '16px' }}
                    />
                </>
            ) : documents.length < maxDocumentsPerPatient && (
                <>
                    <label
                        title="Upload document"
                        htmlFor="input"
                        className="upload-icon-label pull-right"
                    >
                        <i className="icon fas fa-fw fa-lg fa-cloud-upload-alt" />
                    </label>
                    <input
                        id="input"
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        onChange={handleFolderSelect}
                        ref={e => e ? folderSelectInput.current = e : null}
                    />
                    <p className="drop-message">Drag and drop to upload</p>
                </>
            )}

            {documentBeingDeleted && (
                <ModalBool
                    isOpen
                    toggle={() => setDocumentBeingDeleted(null)}
                    title={`Delete ${documentBeingDeleted.FileName ?? key2reference(documentBeingDeleted.Key)}`}
                    message={`Are you sure you want to delete ${documentBeingDeleted.FileName ?? key2reference(documentBeingDeleted.Key)}?`}
                    trueButtonText="Delete"
                    falseButtonText="Cancel"
                    handleTrue={() => {
                        handleDelete(documentBeingDeleted)
                        setDocumentBeingDeleted(null)
                    }}
                    handleFalse={() => setDocumentBeingDeleted(null)}
                />
            )}

            {documentBeingDownloaded && (
                <ModalBool
                    isOpen
                    toggle={() => setDocumentBeingDownloaded(null)}
                    title={`Download ${documentBeingDownloaded.FileName ?? key2reference(documentBeingDownloaded.Key)}`}
                    message="You are about to download a document that may contain confidential patient information. Doing so may violate HIPAA rules. Are you sure you want to do this?"
                    trueButtonText="Download"
                    falseButtonText="Cancel"
                    handleTrue={() => {
                        handleDownload(documentBeingDownloaded)
                        setDocumentBeingDownloaded(null)
                    }}
                    handleFalse={() => setDocumentBeingDownloaded(null)}
                />
            )}

            <TableContainer>
                <Table className="document-table">
                    <TableHead>
                        <TableRow>
                            <SortHeader
                                text="Name"
                                field={'Key'}
                                onSort={handleSort}
                                by={sortedBy}
                                dir={sortedDir}
                            />
                            <SortHeader
                                text="Uploaded"
                                field={'LastModified'}
                                onSort={handleSort}
                                by={sortedBy}
                                dir={sortedDir}
                            />
                            <TableCell>Lock</TableCell>
                            <TableCell style={{ width: '120px' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {documents.map(doc => {
                            const canToggleLock = (): boolean => {
                                const isDocumentUnlocked = !!!doc.LockerName
                                const didUserLockDocument = !isDocumentUnlocked && doc.LockerName === window.localStorage.getItem('mmt_user_name')

                                if (isDocumentUnlocked) {
                                    return doesUserRoleAllowDocumentLocking
                                }

                                return isSuperAdmin || didUserLockDocument
                            }

                            return (
                                <TableRow key={doc.Key}>
                                    <TableCell>
                                        {doc.FileName || key2reference(doc.Key)}
                                    </TableCell>
                                    <TableCell>
                                        {DateTimeUtils.getFriendlyDateTime(doc.LastModified)}
                                    </TableCell>
                                    <TableCell>
                                        {hasEditUserRole ? (
                                            <Link to={`/User/${doc.Lock}`}>
                                                {doc.LockerName}
                                            </Link>
                                        ) : doc.LockerName}
                                    </TableCell>
                                    <TableCell>
                                        <div className="row-action-icons">
                                            {!doc.LockerName && (
                                                <IconButton
                                                    title="Delete"
                                                    color="primary"
                                                    disabled={isLockToggling}
                                                    onClick={() => setDocumentBeingDeleted(doc)}>
                                                    <i className="fa fa-trash-alt" />
                                                </IconButton>
                                            )}
                                            {canToggleLock() && (
                                                <IconButton
                                                    title={!doc.LockerName ? 'Lock' : 'Unlock'}
                                                    disabled={isLockToggling}
                                                    color="primary"
                                                    onClick={() => toggleLock(doc)}>
                                                    <i className={`icon fa fa-fw fa-${doc.LockerName ? 'lock' : 'unlock'}`} />
                                                </IconButton>
                                            )}
                                            <IconButton
                                                title="Download"
                                                color="primary"
                                                onClick={() => setDocumentBeingDownloaded(doc)}>
                                                <i className="icon fas fa-fw fa-cloud-download-alt" />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        {areDocumentsLoading ? (
                            <TableRow>
                                <TableCell
                                    colSpan={5}
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    Loading...
                                </TableCell>
                            </TableRow>
                        ) : documents.length === 0 && (
                            <TableRow>
                                <TableCell
                                    colSpan={5}
                                    style={{
                                        textAlign: 'center',
                                    }}
                                >
                                    No documents for this patient
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <div
                className={`dropper ${isDropping ? 'dropping' : ''}`}
                onDrop={handleDrop}
                onDragLeave={() => setIsDropping(false)}
                onDragOver={preventDefault}
            >
                <h3>Drop to upload</h3>
            </div>
        </div>
    )
}

export default PatientDocuments
