import React from 'react'
import Study from '../types/Study'
import { Action } from './AppState'

export interface Ctx {
    Study: Study
    Roles: any[]
    Facilities: any[]
    AreGraphControlsMinimized: boolean
    commit: (action: Action, payload?: any) => void | Promise<void> | null
}

export const defaultCtx = (): Ctx => ({
    AreGraphControlsMinimized: false,
    Study: {
        ID: '',
        StudyEvents: [],
        StudyRecordings: [],
        StudyDataRate: 0,
        TimeZone: '',
        ExamId: '',
        Patient: {
            ID: '',
            FirstName: '',
            MiddleName: null,
            LastName: '',
            FullName: '',
            SSN: null,
            PatientID: null,
            FacilityID: '',
            DateDeleted: null,
            DateOfBirth: '',
        },
        StudyState: {
            Name: '',
            ID: 0,
        },
        StudyType: {
            Continuous: false,
            Name: '',
            ID: 0,
            XAxisWindow: 0,
            XAxisWindowUOM: 0,
            DateCreated: '',
        },
        DateCreated: '',
    },
    Roles: [],
    Facilities: [],
    commit: () => null,
})

const AppContext = React.createContext<Ctx>(defaultCtx())

export default AppContext
