import React, { useEffect, useState, useCallback } from 'react'
import PatientsApi from '../../services/PatientsApi'
import AuditLogItem from '../../types/AuditLogItem'
import AuditLogsApi from '../../services/AuditLogsApi'
import ToastUtils from '../../utils/ToastUtils'
import SortUtils, { SortDir } from '../../utils/SortUtils'
import NavUtils from '../../utils/NavUtils'
import SortHeader from '../../components/SortHeader'
import moment, { Moment } from 'moment'
import LoadingSpinner from '../../components/LoadingSpinner'
import { ChangeActionType, formatActionType } from '../../types/AuditLogEntry'
import Study from '../../types/Study'
import AuditLogUtils from '../../utils/AuditLogUtils'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

interface Props {
    study: Study
}

const StudyAuditLogs: React.FC<Props> = props => {
    const [studyRecordLogs, setStudyRecordLogs] = useState<AuditLogItem[]>([])
    const [isLoadingCPTLogs, setIsLoadingCPTLogs] = useState(false)
    const [isLoadingUserLogs, setIsLoadingUserLogs] = useState(false)
    const [sortedBy, setSortedBy] = useState<keyof AuditLogItem>('LogDate')
    const [sortedDir, setSortedDir] = useState<SortDir>('asc')
    const study = props.study

    const getStudyRecordAction = (logItem: any) => {
        if (logItem.ActionTypeId === ChangeActionType.Update && logItem.ItemBefore.PatientID !== logItem.ItemAfter.PatientID) {
            return 'Patient Changed'
        }
        return formatActionType(logItem.ActionTypeId)
    }

    const mapStudyRecordItems = useCallback(async (logItems: any[]) => {
        const mappedItems: AuditLogItem[] = []
        for (let i = 0; i < logItems.length; i++) {
            const newDocItem: AuditLogItem = {
                ID: logItems[i].ID,
                LogDate: logItems[i].DateCreated,
                User: AuditLogUtils.formatUserName(logItems[i]),
                Action: getStudyRecordAction(logItems[i]),
                Details: '',
            }

            if (newDocItem.Action === 'Patient Changed') {
                const patient = await PatientsApi.getPatient(logItems[i].ItemBefore.PatientID)
                newDocItem.Details = `Previous association: ${patient.data.FullName}`
            }

            mappedItems.push(newDocItem)
        }
        setStudyRecordLogs(logs => {
            if (!logs) return mappedItems
            logs = logs.concat(...mappedItems)
            SortUtils.date(logs, 'LogDate', 'asc')
            return logs
        })
    }, [])

    useEffect(() => {
        setStudyRecordLogs([])
        setIsLoadingCPTLogs(true)
        setIsLoadingUserLogs(true)
        if (study.StudyRecordings.length === 0) {
            return
        }
        const startWithPadding = moment(study.StudyRecordings[0].DateStarted).subtract(1, 'day')
        let endWithPadding: Moment | undefined = undefined
        if (study.StudyRecordings[study.StudyRecordings.length - 1].DateStopped) {
            endWithPadding = moment(study.StudyRecordings[study.StudyRecordings.length - 1].DateStopped).add(1, 'day')
        }
        AuditLogsApi.getAuditLogsForStudy(study.ID, startWithPadding, endWithPadding)
            .then((res: any) => { mapStudyRecordItems(res.data) })
            .catch((err: any) => { ToastUtils.error({ message: 'Unable to load study record audit logs' }) })
            .finally(() => setIsLoadingUserLogs(false))

        AuditLogsApi.getCptMonitoringLogsForStudy(study.ID, startWithPadding, endWithPadding).then((res: any) => {
            mapStudyRecordItems(res.data)
        }).catch((err: any) => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to load study cpt monitoring logs' })
        }).finally(() => setIsLoadingCPTLogs(false))
    }, [study, mapStudyRecordItems])

    useEffect(() => {
        NavUtils.setPageTitle('Study Audit Logs')
    })

    if (isLoadingCPTLogs || isLoadingUserLogs) {
        return (
            <LoadingSpinner />
        )
    }

    const handleSort = (newSortedBy: keyof AuditLogItem) => {
        const studyRecLogs = [...studyRecordLogs]
        let newSortedDir: SortDir = 'asc'

        if (sortedBy === newSortedBy) {
            newSortedDir = sortedDir === 'desc' ? 'asc' : 'desc'
        }

        switch (newSortedBy) {
            case 'LogDate':
                SortUtils.date(studyRecLogs, newSortedBy, newSortedDir)
                break
            case 'User':
            case 'Action':
                SortUtils.string(studyRecLogs, newSortedBy, newSortedDir)
                break
            default:
                throw Error('Unrecognized sort field')
        }

        setSortedBy(newSortedBy)
        setSortedDir(newSortedDir)
        setStudyRecordLogs(studyRecLogs)
    }

    return (
        <TableContainer>       
            <Table>
                <TableHead>
                    <TableRow>
                        <SortHeader
                            text="Date"
                            field="LogDate"
                            onSort={handleSort}
                            by={sortedBy}
                            dir={sortedDir}
                        />
                        <SortHeader
                            style={{ width: '40%' }}
                            text="User"
                            field="User"
                            onSort={handleSort}
                            by={sortedBy}
                            dir={sortedDir}
                        />
                        <SortHeader
                            text="Action"
                            field="Action"
                            onSort={handleSort}
                            by={sortedBy}
                            dir={sortedDir}
                        />
                        <TableCell>Change Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {studyRecordLogs.length === 0 ? (
                        <TableRow>
                            <TableCell
                                colSpan={5}
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                No audit logs are available for this study
                            </TableCell>
                        </TableRow>
                    ) : (studyRecordLogs.map(studyLog => (
                        <TableRow key={studyLog.ID}>
                            <TableCell>
                                {moment(studyLog.LogDate).tz(props.study.TimeZone).format('MMMM D, YYYY h:mm A')}
                            </TableCell>
                            <TableCell style={{ wordBreak: 'break-word' }}>
                                {studyLog.User}
                            </TableCell>
                            <TableCell>
                                {studyLog.Action}
                            </TableCell>
                            <TableCell>
                                {studyLog.Details}
                            </TableCell>
                        </TableRow>
                    )))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default StudyAuditLogs
