import React, { useRef, useEffect, useState } from 'react'
import './GraphSettingsDropdown.scss'
import {
    Dropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
} from 'reactstrap'
import { Grid } from '@mui/material'
import { SETTING_LOW_FILTER } from '../../../constants/graphsettings'
import GraphUtilities from '../utils/GraphUtilities'

const displaySetting = (props, setting) => {
    if (props.type === SETTING_LOW_FILTER) {
        return (
            <React.Fragment>
                {GraphUtilities.getFrequency(setting.Value)}{' '}
                <small style={{ marginLeft: '5px' }}>
                    {GraphUtilities.formatTimeConstant(setting.Value)}
                </small>
            </React.Fragment>
        )
    }

    return GraphUtilities.getSettingDisplayString(props.type, setting.Value)
}

const renderOptions = (props, isOpen, selectedItem) => {
    if (!props.options || !isOpen) {
        return false
    }

    return props.options.map(option => {
        const isSelected = option.Value === props.currentSetting.Value
        const handleClick = () => {
            props.handleSettingSelect(props.type, option.Value)
        }
        let ref = null
        if (option.Value === props.currentSetting.Value) {
            ref = selectedItem
        }

        return (
            <div key={option.Value} ref={ref}>
                <DropdownItem
                    onClick={handleClick}
                    className={`text-truncate ${isSelected ? 'selected' : ''}`}
                    disabled={isSelected}
                >
                    {displaySetting(props, option)}
                </DropdownItem>
            </div>
        )
    })
}

const GraphSettingsDropdown = props => {
    const selectedItem = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    const scrollToSelectedItem = () => {
        if (isOpen && selectedItem.current) {
            selectedItem.current.scrollIntoView({
                block: 'center',
            })
        }
    }

    useEffect(scrollToSelectedItem, [isOpen])

    return (
        <Grid item className="px-0 d-none d-sm-block graph-settings-dropdown" sm={2}>
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <DropdownToggle
                    className="dropdown-toggle"
                    disabled={props.disabled}
                >
                    <div className="tag text-truncate">{props.displayName}</div>
                    <span className="value text-truncate">
                        {displaySetting(props, props.currentSetting)}
                    </span>
                    <i className="icon icon-electrotek-chevron-down pull-right" />
                </DropdownToggle>
                <DropdownMenu>
                    {renderOptions(props, isOpen, selectedItem)}
                </DropdownMenu>
            </Dropdown>
        </Grid>
    )
}

export default GraphSettingsDropdown
