import React, { useState, useEffect, useCallback, useMemo } from 'react'
import moment from 'moment'
import ModalBool from '../../components/modals/ModalBool'
import SessionManager from '../../services/SessionManager'
import useS3Uploader from '../../hooks/useS3Uploader'
import ToastUtils from '../../utils/ToastUtils'
import usePreventAutoLogout from '../../hooks/usePreventAutoLogout'
import ImportStudyUpdloadGraph from './ImportStudyUploadGraph'
import { formatStorageSize } from '../../utils/StringUtils'
import NeutralButton from '../../components/NeutralButton'
import { Button, Grid, LinearProgress } from '@mui/material'
import DateTimeUtils from '../../utils/DateTimeUtils'

const beforeUnloadListener = e => {
    e.preventDefault()
    const confirmationMessage = 'Are you sure you want to cancel import study?'
    const event = e || window.event
    event.returnValue = confirmationMessage
    return confirmationMessage
}

const ImportStudyUpload = ({ files, prefix, patient, s3, onFinish, recordId }) => {
    const uploadStartedAt = useMemo(() => Date.now(), [])
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [hasConnMsgBeenShown, setHasConnMsgBeenShown] = useState(false)
    const [refreshCounter, setRefreshCounter] = useState(0)

    const removeIncompleteImports = useCallback(() => {
        const studyImports = JSON.parse(
            SessionManager.get('incomplete_study_imports'),
        )
        if (patient) {
            delete studyImports[patient.ID]
        } else {
            delete studyImports[recordId]
        }
        SessionManager.set('incomplete_study_imports', JSON.stringify(studyImports))
    }, [patient, recordId])

    const done = useCallback(() => {
        onFinish()
        removeIncompleteImports()
    }, [onFinish, removeIncompleteImports])

    const upload = useS3Uploader({
        files,
        s3,
        done,
        prefix,
    })

    const totalUploadSize = files.reduce((a, b) => (a + b.size), 0)
    const incrementCounter = useCallback(() => setRefreshCounter(refreshCounter+1),[refreshCounter])

    usePreventAutoLogout()

    useEffect( () => {
        const elapsedTime = setInterval(incrementCounter, 1000)
        return () => clearInterval(elapsedTime)
    }, [incrementCounter, refreshCounter] )

    useEffect(() => {
        window.addEventListener('beforeunload', beforeUnloadListener)
        return () =>
            window.removeEventListener('beforeunload', beforeUnloadListener)
    }, [])

    useEffect(() => {
        if (upload.error) {
            setTimeout(upload.retry, 10000)
        }
    }, [upload.error, upload.retry])

    const getColorOfUpload = progress => {
        if (upload.error) return 'error'
        return 'primary'
    }

    const handleCancelImport = () => {
        window.removeEventListener('beforeunload', beforeUnloadListener)
        removeIncompleteImports()
        window.close()
    }

    const timeEstimationMessage = () => {
        return upload.remainingMs
            ? `${moment
                .duration(
                    upload.remainingMs / 1000,
                    'seconds'
                )
                .humanize(false)}`
            : 'Estimating time remaining...'
    }

    const currentUploadSpeed = (() => {
        if (upload.records.length === 0) return null
        const recentDataPointCount = Math.min(10, upload.records.length)
        const recentDataPoints = upload.records.slice(upload.records.length - recentDataPointCount)
        const avgBytesPerMs = recentDataPoints.reduce((a, b) => (a + b.bytesPerMs), 0) / recentDataPointCount
        const avgMegabitsPerSecond = Math.max(0, avgBytesPerMs * .008)
        return avgMegabitsPerSecond
    })()

    const averageUploadSpeed = (() => {
        if (upload.records.length < 5) return null
        const countOfDataPoints = upload.records.length
        const avgBytesPerMs = upload.records.reduce((a, b) => (a + b.bytesPerMs), 0) / countOfDataPoints
        const avgMbpsUploadSpeed = Math.max(0, avgBytesPerMs * .008)
        return avgMbpsUploadSpeed
    })()

    useEffect(() => {
        if (hasConnMsgBeenShown) return
        if (currentUploadSpeed === null) return
        if (currentUploadSpeed >= 5) return
        if (ToastUtils.isOpen(/^upload speed will be affected$/)) return
        ToastUtils.error({ message: 'We\'ve detected a slow connection, upload speed will be affected.' })
        setHasConnMsgBeenShown(true)
    }, [currentUploadSpeed, hasConnMsgBeenShown])

    return (
        <div className="progress-container">
            {showCancelModal && (
                <ModalBool
                    isOpen
                    title="Stop Import"
                    message="Are you sure you want to stop the study import?"
                    trueButtonText="Stop Import"
                    falseButtonText="Continue Import"
                    handleTrue={handleCancelImport}
                    handleFalse={() => setShowCancelModal(false)}
                />
            )}
            <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                    {upload.error ? (
                        upload.error.message
                    ) : (
                        <Grid container justifyContent="space-between">
                            <Grid item xs="auto">
                                <span className="upload-feedback-labels">Estimated Completion:</span>
                                <span className="upload-feedback-values">{timeEstimationMessage()}</span>
                            </Grid>
                            <Grid item xs="auto">
                                <span className="upload-feedback-labels">Avg Speed:</span>
                                <span className="upload-feedback-values">
                                    {averageUploadSpeed === null ? 'Measuring...' : `${averageUploadSpeed.toFixed(2)} Mbps`}
                                </span>
                            </Grid>
                            <Grid item xs="auto">
                                <span className="upload-feedback-labels">Current Speed:</span>
                                <span className="upload-feedback-values">
                                    {currentUploadSpeed === null ? 'Measuring...' : `${currentUploadSpeed.toFixed(2)} Mbps`}
                                </span>
                            </Grid>
                        </Grid>
                    )}
                </div>
                <ImportStudyUpdloadGraph data={upload.records} />
                {!upload.current.label ? (
                    <span>&nbsp;</span>
                ) : (
                    <React.Fragment>
                        <br />
                        <div style={{ paddingTop: '30px' }} />
                        <span style={{ float: 'right' }}>
                            {upload.current.label}&nbsp;&nbsp;({formatStorageSize(upload.current.size)})
                        </span>
                        ({upload.current.index + 1} / {files.length} files){' '}
                        {Math.floor(upload.current.progress)}%
                    </React.Fragment>
                )}
                <LinearProgress
                    variant="determinate"
                    value={upload.current.progress}
                    color={getColorOfUpload(upload.current.progress)}
                />
                <br />
                <span className="import-elapsed">
                    <span className="label">Total progress:</span>
                    <span className="data">{upload.totalProgress}%</span>
                    <span style={{ float: 'right' }}>
                        <span className="label">Total upload:</span>
                        <span className="data">{formatStorageSize(totalUploadSize)}</span>
                    </span>
                </span>
                <LinearProgress
                    variant="determinate"
                    value={upload.totalProgress}
                    color={getColorOfUpload(upload.current.progress)}
                />
                <br />
            </React.Fragment>
            <div style={{ textAlign: 'left' }}>
                {upload.error && (
                    <Button
                        onClick={() => {
                            if (navigator.onLine) {
                                upload.retry()
                            } else {
                                ToastUtils.warning({ message: 'You are not connected to the internet' })
                            }
                        }}
                        sx={{ float: 'right' }}
                    >
                        Retry
                    </Button>
                )}
                <div className="import-elapsed button">
                    <span className="label">Elapsed Time:</span>
                    <span className="data">{DateTimeUtils.getFriendlyDuration((Date.now() - uploadStartedAt)/1000)}</span>
                </div>
                <NeutralButton
                    className={upload.error ? '' : 'center'}
                    onClick={() => setShowCancelModal(true)}
                >
                    Cancel
                </NeutralButton>
            </div>
        </div>
    )
}

export default ImportStudyUpload


