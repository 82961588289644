import MappingItem from '../../../types/MappingItem'

export const STANDARD_MAPPING_NAMES = [
    'Fp1',
    'Fp2',
    'F7',
    'F8',
    'F3',
    'F4',
    'Fz',
    'A1',
    'A2',
    'T3',
    'T4',
    'C3',
    'C4',
    'Cz',
    'T5',
    'T6',
    'P3',
    'P4',
    'Pz',
    'O1',
    'O2',
    'Oz',
    'Ref',
    'Gnd',
]

export const GND_MAPPING_ITEM: MappingItem = {
    ID: 'gnd-id',
    Name: 'Gnd',
    Electrode: {
        Name: 'Gnd',
        ID: -1,
        Input: 0,
        HarnessID: 1,
    },
    ChannelType: { ID: 0, Name: '' },
    InputType: { ID: 1, Name: 'Referential' },
    InputTypeID: 0,
    MappingID: 'gnd-mapping-id',
    Harness: { ID: 1, Name: 'Rendr' },
}

export const REF_MAPPING_ITEM: MappingItem = {
    ID: 'ref-id',
    Name: 'Ref',
    Electrode: {
        Name: 'Ref',
        ID: -2,
        Input: 0,
        HarnessID: 1,
    },
    ChannelType: { ID: 0, Name: '' },
    InputType: { ID: 1, Name: 'Referential' },
    InputTypeID: 0,
    MappingID: 'ref-mapping-id',
    Harness: { ID: 1, Name: 'Rendr' },
}