import { useHistory } from 'react-router-dom'
import useStyles from './Logo.styles'

function Logo() {
    const history = useHistory()
    const classes = useStyles()

    return (
        <img
            alt="logo"
            src="/render-platform.png"
            className={classes.root}
            onClick={() => history.push('/Dashboard')}
        />
    )
}

export default Logo
