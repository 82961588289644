export const EEG = 1
export const EKG = 2
export const TEMPERATURE = 3
export const RESPIRATORY = 4
export const EMG = 5
export const EOG = 10
export const CHIN = 11
export const NASAL = 12
export const SNORE = 13
export const PHOTIC = 14
export const EM = -1
export const NONIN = -3
export const SAMPLECOUNTER = -4
export const EVENT = -5

export const CHANNEL_TYPE = []
CHANNEL_TYPE[EEG] = EEG
CHANNEL_TYPE[EKG] = EKG
CHANNEL_TYPE[TEMPERATURE] = TEMPERATURE
CHANNEL_TYPE[RESPIRATORY] = RESPIRATORY
CHANNEL_TYPE[EMG] = EMG
CHANNEL_TYPE[EM] = EM
CHANNEL_TYPE[EOG] = EOG
CHANNEL_TYPE[CHIN] = CHIN
CHANNEL_TYPE[NASAL] = NASAL
CHANNEL_TYPE[SNORE] = SNORE

export const NON_EEG_CHANNEL_TYPES = [EKG, TEMPERATURE, RESPIRATORY, EMG, EOG, CHIN, NASAL, SNORE]

export const CHANNEL_LABEL = []
CHANNEL_LABEL[EEG] = 'EEG'
CHANNEL_LABEL[EKG] = 'EKG'
CHANNEL_LABEL[TEMPERATURE] = 'Thermister'
CHANNEL_LABEL[RESPIRATORY] = 'Respiratory'
CHANNEL_LABEL[EMG] = 'EMG'
CHANNEL_LABEL[EM] = 'EM'
CHANNEL_LABEL[EOG] = 'EOG'
CHANNEL_LABEL[CHIN] = 'Chin'
CHANNEL_LABEL[NASAL] = 'Nasal Pressure'
CHANNEL_LABEL[SNORE] = 'Snore'

export const CHANNEL_TYPE_ICON = []
CHANNEL_TYPE_ICON[EEG] = 'icon-electrotek-eeg'
CHANNEL_TYPE_ICON[EKG] = 'icon-electrotek-heart'
CHANNEL_TYPE_ICON[TEMPERATURE] = 'icon-electrotek-thermometer-three-quarters'
CHANNEL_TYPE_ICON[RESPIRATORY] = 'icon-electrotek-lungs-fill'
CHANNEL_TYPE_ICON[EMG] = 'icon-electrotek-move'
CHANNEL_TYPE_ICON[EOG] = 'icon-electrotek-eye-open'

//Temporary - replace with actual icons
CHANNEL_TYPE_ICON[SNORE] = 'icon-electrotek-Snore'
CHANNEL_TYPE_ICON[NASAL] = 'icon-electrotek-Nasal-Pressure'
CHANNEL_TYPE_ICON[CHIN] = 'icon-electrotek-Chin'

// colors
export const IMPORTANT_COLOR = '#ffd700'
export const DEFAULT_EVENT_COLOR = '#05adee'

// study types
export const STUDY_TYPE_ROUTINE = 1
export const STUDY_TYPE_AMBULATORY = 2
