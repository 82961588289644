// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

class CustomMontageStorage {
    constructor() {
        // dictionary of dictionaries
        this.montagesReceived = {}
    }

    addMontage(customMontage) {
        if (this.montagesReceived[customMontage.ID] === undefined) {
            this.montagesReceived[customMontage.ID] = {}
        }

        this.montagesReceived[customMontage.ID][
            customMontage.Index
        ] = customMontage
    }

    getMontage(id, index) {
        return this.montagesReceived[id][index]
    }
}

export default CustomMontageStorage
