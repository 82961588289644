import React, { useEffect } from 'react'
import UsersApi from '../../services/UsersApi'
import LoadingSpinner from '../../components/LoadingSpinner'
import ToastUtils from '../../utils/ToastUtils'
import SessionManager from '../../services/SessionManager'

const PATH_REGEXP = new RegExp('/shared-study/([a-z0-9-]+?)/', 'i')

interface Props {
    match: any
}

const SharedStudy: React.FC<Props> = props => {
    const facilityId = props.match.params.facilityId
    const studyId = props.match.params.id

    useEffect(() => {
        UsersApi.setSelectedFacility(facilityId).then(res => {
            SessionManager.set('mmt_facility_id', facilityId)
            SessionManager.set('mmt_facility_name', res.data.SelectedFacilityName)
            const pathAndQuery = window.location.href.replace(window.location.origin, '')
            const newPathAndQuery = pathAndQuery.replace(PATH_REGEXP, '/study/')
            window.location.replace(newPathAndQuery)
        }).catch(err => {
            ToastUtils.error({ message: 'Unable to switch facilities' })
            console.log(err)
        })
    }, [facilityId, studyId])

    return <LoadingSpinner />
}

export default SharedStudy
