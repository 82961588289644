import { makeStyles } from '@mui/styles'

function useStyles() {
    const color = 'rgba(0, 0, 0, 0.25)'
    const darkenedColor = 'rgba(0, 0, 0, 0.5)'

    return makeStyles({
        root: {
            borderColor: color,
            color: '#fff',
            background: color,
            '&:hover': {
                borderColor: darkenedColor,
                color: '#fff',
                background: darkenedColor,
            },
        },
    })()
}

export default useStyles
