import React from 'react'
import './Profile.scss'
import { zxcvbn } from '../../utils/PasswordUtils'
import InputGroup from '../../components/forms/InputGroup'
import AccountApi from '../../services/AccountApi'
import FormFieldManager from '../../utils/FormFieldManager'
import LoadingSpinner from '../../components/LoadingSpinner'
import { ROLE_NAMES } from '../../constants/roles'
import ToastUtils from '../../utils/ToastUtils'
import { NavLink } from 'react-router-dom'
import RoleBasedContent from '../../components/RoleBasedContent'
import UserAuth from '../../auth/UserAuth'
import { Button, Card, CardContent, CardHeader, Grid } from '@mui/material'

class Profile extends React.Component {
    state = {
        profile: null,
        is_updating_password: false,
    }

    async componentDidMount() {
        let response = null

        try {
            response = await AccountApi.getProfile()
        } catch (error) {
            return
        }

        this.setProfile(response.data)
    }

    setProfile(profile) {
        profile.Roles = profile.Roles.filter(r => r !== 'RegisteredUser')
        this.setFieldsConfig()
        this.setState({ profile })
    }

    setFieldsConfig() {
        // base fields w/ initial values
        const fieldsConfig = [
            { name: 'CurrentPassword', value: '' },
            { name: 'NewPassword', value: '' },
            { name: 'ConfirmPassword', value: '' },
        ]

        // set field validations
        fieldsConfig.forEach(field => {
            field.hideValidityError = true
            switch (field.name) {
                case 'CurrentPassword':
                    field.isValid = () =>
                        !!field.value && field.value.length >= 6
                    break
                case 'NewPassword':
                    field.isValid = () =>
                        field.value.length >= 8 &&
                        field.value.length <= 100 &&
                        zxcvbn(field.value).feedback.warning === ''
                    break
                case 'ConfirmPassword':
                    field.isValid = () => this.doPasswordFieldsMatch()
                    break
                default:
                    field.isValid = () => true
            }
        })

        const passwordFormManager = new FormFieldManager(fieldsConfig, () =>
            this.forceUpdate(),
        )
        this.setState({ passwordFormManager })
    }

    doPasswordFieldsMatch() {
        const pfm = this.state.passwordFormManager
        return (
            pfm.getFieldValue('NewPassword') ===
            pfm.getFieldValue('ConfirmPassword')
        )
    }

    handleChangePasswordSubmit = async () => {
        const pfm = this.state.passwordFormManager
        const data = {
            OldPassword: pfm.getFieldValue('CurrentPassword'),
            NewPassword: pfm.getFieldValue('NewPassword'),
            ConfirmPassword: pfm.getFieldValue('ConfirmPassword'),
        }

        try {
            await AccountApi.changePassword(data)
            ToastUtils.success({ message: 'Password has been changed' })
            this.setFieldsConfig()
        } catch (err) {
            ToastUtils.error({ message: 'Unable to change password' })
        }
    }

    render() {
        const profile = this.state.profile
        if (!profile) return <LoadingSpinner />

        const passwordFormManager = this.state.passwordFormManager
        const fullName = `${profile.FirstName} ${profile.LastName}`
        const role = profile.Roles[0] || profile.Roles[1]
        const roleNames = ROLE_NAMES.filter(rn => rn.name === role)
        const roleFriendlyName = roleNames[0]?.friendly_name || ''

        return (
            <React.Fragment>
                <div className="animated fadeIn profile">
                    <div className="profile-block profile-card">
                        <div className="rela-block profile-name-container">
                            <div
                                className="rela-block user-name"
                                id="user_name"
                            >
                                {fullName}
                            </div>
                            <div
                                className="rela-block user-desc"
                                id="user_description"
                            >
                                {profile.FacilityName}
                            </div>
                            <div
                                className="rela-block user-desc"
                                id="user_description"
                            >
                                {roleFriendlyName}
                            </div>
                        </div>
                        <div className="profile-pic" id="profile_pic" />
                    </div>

                    <Card raised>
                        <CardHeader title="General" className="header-top" />
                        <CardContent>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={4}>
                                    <dt>First Name</dt>
                                    <dd>{profile.FirstName}</dd>
                                </Grid>
                                <Grid item xs={4}>
                                    <dt>Last Name</dt>
                                    <dd>{profile.LastName}</dd>
                                </Grid>
                                <Grid item xs={4}>
                                    <dt>Middle Name</dt>
                                    <dd>{profile.MiddleName}</dd>
                                </Grid>

                                <Grid item xs={4}>
                                    <dt>Email</dt>
                                    <dd>{profile.Email}</dd>
                                </Grid>
                                <Grid item xs={4}>
                                    <dt>Title</dt>
                                    <dd>{profile.Title}</dd>
                                </Grid>
                                <Grid item xs={4}>
                                    <dt>Suffix</dt>
                                    <dd>{profile.Suffix}</dd>
                                </Grid>
                            </Grid>
                            <RoleBasedContent required_roles={UserAuth.AuditLogsPage}>
                                <div>
                                    <Button
                                        component={NavLink}
                                        sx={{ mt: 2 }}
                                        to={`/User/${profile.ID}/AuditLogs`}
                                    >
                                        Monitored Studies
                                    </Button>
                                </div>
                            </RoleBasedContent>
                        </CardContent>

                        <CardHeader title="Security" className="header-middle" />
                        <CardContent>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid item xs={10} md={6}>
                                        <InputGroup
                                            type="password"
                                            field="CurrentPassword"
                                            label="Current Password"
                                            placeholder="Current Password"
                                            error={
                                                zxcvbn(
                                                    passwordFormManager.getFieldValue(
                                                        'Password',
                                                    ),
                                                ).feedback.warning ||
                                                'more characters are required'
                                            }
                                            value={passwordFormManager.getFieldValue(
                                                'CurrentPassword',
                                            )}
                                            valid={passwordFormManager.isValid(
                                                'CurrentPassword',
                                            )}
                                            hideError={passwordFormManager.shouldHideError(
                                                'CurrentPassword',
                                            )}
                                            handleChange={
                                                passwordFormManager.handleChange
                                            }
                                            handleBlur={
                                                passwordFormManager.handleBlur
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={10} md={6}>
                                        <InputGroup
                                            type="password"
                                            field="NewPassword"
                                            label="New Password"
                                            placeholder="New Password"
                                            error={
                                                zxcvbn(
                                                    passwordFormManager.getFieldValue(
                                                        'NewPassword',
                                                    ),
                                                ).feedback.warning ||
                                                'Passwords must be 8 to 100 characters long'
                                            }
                                            value={passwordFormManager.getFieldValue(
                                                'NewPassword',
                                            )}
                                            valid={passwordFormManager.isValid(
                                                'NewPassword',
                                            )}
                                            hideError={passwordFormManager.shouldHideError(
                                                'NewPassword',
                                            )}
                                            handleChange={
                                                passwordFormManager.handleChange
                                            }
                                            handleBlur={
                                                passwordFormManager.handleBlur
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid item xs={10} md={6}>
                                        <InputGroup
                                            type="password"
                                            field="ConfirmPassword"
                                            label="Confirm Password"
                                            placeholder="Confirm Password"
                                            error="Does not match Password"
                                            value={passwordFormManager.getFieldValue(
                                                'ConfirmPassword',
                                            )}
                                            valid={passwordFormManager.isValid(
                                                'ConfirmPassword',
                                            )}
                                            hideError={passwordFormManager.shouldHideError(
                                                'ConfirmPassword',
                                            )}
                                            handleChange={
                                                passwordFormManager.handleChange
                                            }
                                            handleBlur={
                                                passwordFormManager.handleBlur
                                            }
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="justify-content-start" sx={{ textAlign: 'center' }}>
                                    <Button
                                        disabled={
                                            this.state.is_updating_password ||
                                            !passwordFormManager.hasChanged() ||
                                            !passwordFormManager.areFieldsValid()
                                        }
                                        onClick={this.handleChangePasswordSubmit}
                                    >
                                        {this.state.is_updating_password ? 'Changing...' : 'Change Password'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                </div>
            </React.Fragment>
        )
    }
}

export default Profile
