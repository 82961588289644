export enum ChangeActionType {
    Create = 1,
    Read,
    Update,
    Delete,
    StartMonitoring,
    StopMonitoring,
}

export const formatActionType = (actionType: ChangeActionType): string => {
    switch (actionType) {
        case ChangeActionType.Create: return 'Create'
        case ChangeActionType.Read: return 'Read'
        case ChangeActionType.Update: return 'Update'
        case ChangeActionType.Delete: return 'Delete'
        case ChangeActionType.StartMonitoring: return 'Start Monitoring'
        case ChangeActionType.StopMonitoring: return 'Stop Monitoring'
    }
}

export enum ItemType {
    Study = 'Study',
    PatientDocument = 'PatientDocument',
}

type AuditLogEntry<T = any> = ConsistentFields<T> & (OldNameFields | NewNameFields)

interface ConsistentFields<T = any> {
    ID: string
    DateCreated: string
    UserName: string
    UserRole: string
    UserId: string
    UserSelectedFacilityId: string
    ItemType: ItemType
    ItemId: string
    ActionTypeId: ChangeActionType
    FieldsChanged: string[] | null
    ItemBefore: T
    ItemAfter: T
}

// 2.3 fields
export interface OldNameFields {
    UserTitle: null
    UserFirstName: null
    UserMiddleName: null
    UserLastName: null
    UserSuffix: null
}

// 2.4 and after
export interface NewNameFields {
    UserTitle: string | null
    UserFirstName: string
    UserMiddleName: string | null
    UserLastName: string
    UserSuffix: string | null
}

export interface AuditLogEntryFilter {
    UserId?: string
    UserSelectedFacilityId?: string
    ItemType?: ItemType
    ItemId?: string
    ActionTypeId?: ChangeActionType
    FieldsChanged?: string[] | null
    ItemBefore?: any
    ItemAfter?: any
}

export default AuditLogEntry
