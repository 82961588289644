import React from 'react'
import './Users.scss'
import { Link } from 'react-router-dom'
import UsersApi from '../../services/UsersApi'
import AccountApi from '../../services/AccountApi'
import FacilitiesApi from '../../services/FacilitiesApi'
import ModalBool from '../../components/modals/ModalBool'
import UserInvite from './UserInvite'
import {
    FACILITY_ADMIN_ROLE,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    PRODUCTION_ROLE,
    REGISTERED_USER_ROLE,
    ROLE_NAMES,
} from '../../constants/roles'
import RoleBasedContent from '../../components/RoleBasedContent'
import LoadingSpinner from '../../components/LoadingSpinner'
import UserInfoRow from './UserInfoRow'
import NavUtils from '../../utils/NavUtils'
import { truncate, abbreviate } from '../../utils/StringUtils'
import SortUtils from '../../utils/SortUtils'
import SortHeader from '../../components/SortHeader'
import FailedToLoad from '../../components/FailedToLoad'
import ToastUtils from '../../utils/ToastUtils'
import AppContext from '../../components/AppContext'
import NeutralButton from '../../components/NeutralButton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const NAME_FIELD = 'LastName'
const EMAIL_FIELD = 'Email'
const ROLENAME_FIELD = 'roleName'

class Users extends React.Component {
    static contextType = AppContext

    state = {
        is_loading: true,
        users: [],
        is_confirm_modal_open: false,
        is_invite_modal_open: false,
        is_password_reset_modal_open: false,
        user_to_delete: {},
        scroll_to_top: false,
        is_row_open: [],
        availableRoles: [],
        invitee_email: '',
        invitee_role: '',
        sorted_by: NAME_FIELD,
        sorted_dir: 'asc',
        is_resetting_password: false,
    }

    componentDidMount() {
        NavUtils.setPageTitle('Users')
        this.getUsers()
    }

    getUsers = () => {
        this.setState({ is_loading: true, get_users_did_fail: false })

        UsersApi.getUsers()
            .then(data => this.setUsers(data.data))
            .catch(err => {
                this.setState({
                    is_loading: false,
                    get_users_did_fail: true,
                })
                console.log(err)
            })
    }

    componentDidUpdate() {
        if (this.state.scroll_to_top) {
            window.scrollTo(0, 0)
            this.setScrollToTop(false)
        }
    }

    getUserName(user) {
        let name = `${truncate(user.LastName, 15)}, ${truncate(
            user.FirstName,
            15,
        )}`
        if (user.MiddleName) name += ` ${abbreviate(user.MiddleName)}`
        return name
    }

    setScrollToTop(setting) {
        this.setState({ scroll_to_top: setting })
    }

    setUsers(users) {
        const usersWithRoles = users.map(u => {
            const roleName = this.getRoleName(u, ROLE_NAMES)
            u.roleName = roleName || ''
            return u
        })
        SortUtils.string(usersWithRoles, this.state.sorted_by, this.state.sorted_dir)
        this.setState({ users: usersWithRoles, is_loading: false })
    }

    getRoleName(user, roleNames) {
        const regUserRoleId = roleNames.filter(r => r.name === 'RegisteredUser')[0]?.id
        const roles = user.Roles.filter(ur => ur.toLowerCase() !== regUserRoleId.toLowerCase())
        const roleName = ROLE_NAMES.find(rn => rn.id.toLowerCase() === roles[0]?.toLowerCase())?.friendly_name
        return roleName
    }

    refreshUsers = () => {
        UsersApi.getUsers().then(data => {
            this.setUsers(data.data)
        })
    }

    toggleConfirmDeleteModal = () => {
        this.setState({
            is_confirm_modal_open: !this.state.is_confirm_modal_open,
        })
    }

    togglePasswordResetModal = () => {
        this.setState({
            is_password_reset_modal_open: !this.state.is_password_reset_modal_open,
            is_resetting_password: false,
        })
    }

    toggleUserInvite = () => {
        this.setState({
            is_invite_modal_open: !this.state.is_invite_modal_open,
            invitee_email: '',
            invitee_role: '',
        })
    }

    toggleInfoRow(id) {
        const rows = this.state.is_row_open
        rows[id] = !rows[id]
        this.setState({ is_row_open: rows })
    }

    initiateDeleteUser = (id, name) => {
        this.setState({ user_to_delete: { id, name } })
        this.toggleConfirmDeleteModal()
    }

    initiatePasswordReset = (id, name) => {
        this.setState({ user_to_reset_password: { id, name } })
        this.togglePasswordResetModal()
    }

    handleConfirmDeleteYes = () => {
        this.toggleConfirmDeleteModal()
        const user_to_delete = this.state.users.find(u => u.ID === this.state.user_to_delete.id)
        const isFacilityUser = this.isFacilityUser(user_to_delete)

        UsersApi.deleteUser(this.state.user_to_delete.id, isFacilityUser)
            .then(result => {
                if (result.status === 200) {
                    ToastUtils.success({ message: 'User has been deleted' })
                    this.refreshUsers()
                } else {
                    ToastUtils.error({ message: `User delete failed: ${result.statusText}` })
                }
            })
            .then(this.setScrollToTop(true))
            .catch(error => {
                ToastUtils.error({ message: error.data.Message || error.statusText })
            })
    }

    handleConfirmDeleteNo = () => {
        this.setState({ is_confirm_modal_open: false })
        this.setState({ user_to_delete: {} })
    }

    handlePasswordResetYes = () => {
        this.setState({ is_resetting_password: true })
        if (this.state.is_resetting_password) return
        AccountApi.resetUserPassword(this.state.user_to_reset_password.id)
            .then(result => {
                if (result.status === 200) {
                    const userName = this.state.user_to_reset_password.name
                    const newPassword = result.data || 'error'
                    ToastUtils.notice({
                        message:
                            `'${userName}' password has been reset to: ${newPassword}`,
                    })
                    this.togglePasswordResetModal()
                } else {
                    ToastUtils.error({
                        message:
                            `User password reset failed: ${result.statusText}`,
                    })
                }
            })
            .then(this.setScrollToTop(true))
            .catch(error => {
                ToastUtils.error({
                    message:
                        error.data.Message || error.data || error.statusText,
                })
                this.togglePasswordResetModal()
            })
    }

    handlePasswordResetNo = () => {
        this.setState({ user_to_reset_password: '' })
        this.togglePasswordResetModal()
    }

    handleChange = (field, value) => {
        switch (field) {
            case 'InviteeEmail':
                this.setState({ invitee_email: value })
                break
            case 'InviteeRole':
                this.setState({ invitee_role: value })
                break
            default:
        }
    }

    sendInvite = () => {
        const email = this.state.invitee_email
        const role = this.state.invitee_role

        FacilitiesApi.sendUserInvite(email, role)
            .then(response => {
                ToastUtils.success({ message: response.data || 'Invitation has been sent!' })
            })
            .catch(error => {
                ToastUtils.error({
                    message:
                        (error.data && error.data.Message) ||
                        'An error occurred sending the invitation!',
                })
            })
        this.toggleUserInvite()
    }

    roleOptions() {
        let availableRoles = ROLE_NAMES.map(r => ({ name: r.friendly_name, value: r.id, systemName: r.name }))
        availableRoles.unshift({ value: '', name: 'Please select a role' })
        const options = availableRoles.filter(
            r =>
                r.systemName !== SUPER_ADMIN_ROLE &&
                r.systemName !== SUPPORT_ROLE &&
                r.systemName !== PRODUCTION_ROLE &&
                r.systemName !== REGISTERED_USER_ROLE,
        )
        const final = options.map(r => ({ name: r.name, value: r.value }))
        return final
    }

    isFormValid = () => {
        const fieldValues = this.state.fieldValues
        let valid = true

        fieldValues.forEach(field => {
            valid = valid && field.isValid(field.value)
        })

        return valid
    }

    handleSort = sorted_by => {
        const users = [...this.state.users]
        let sorted_dir = 'desc'
        if (
            this.state.sorted_by !== sorted_by ||
            this.state.sorted_dir === 'desc'
        ) {
            sorted_dir = 'asc'
        }
        switch (sorted_by) {
            case NAME_FIELD:
            case EMAIL_FIELD:
            case ROLENAME_FIELD:
                SortUtils.string(users, sorted_by, sorted_dir)
                break
            default:
                throw Error(`you can not sort by ${sorted_by}`)
        }
        this.setState({ sorted_by, sorted_dir, users })
    }

    isSuperAdmin = user => {
        const superAdminRoleId = ROLE_NAMES.find(
            r => r.name === SUPER_ADMIN_ROLE,
        ).id
        for (const role of user.Roles) {
            if (role === superAdminRoleId) {
                return true
            }
        }
        return false
    }

    isFacilityUser = user => {
        const nonFacilityRoles = ROLE_NAMES.filter(r =>
            r.name === SUPER_ADMIN_ROLE ||
            r.name === SUPPORT_ROLE ||
            r.name === PRODUCTION_ROLE)

        for (const role of user.Roles) {
            if (nonFacilityRoles.find(r => r.id === role)) {
                return false
            }
        }
        return true
    }

    canEdit = user => {
        const isAppUserSuperAdmin = this.context.Roles.includes(
            SUPER_ADMIN_ROLE,
        )
        return !user.isDeleted && (isAppUserSuperAdmin || !this.isSuperAdmin(user))
    }

    confirmModal = () => (
        (
            <ModalBool
                isOpen={this.state.is_confirm_modal_open}
                title="Confirm User Deletion"
                message={`Are you sure you want to delete the user '${this.state.user_to_delete.name}'?`}
                trueButtonText="Delete"
                falseButtonText="Cancel"
                toggle={this.toggleConfirmDeleteModal}
                handleTrue={this.handleConfirmDeleteYes}
                handleFalse={this.handleConfirmDeleteNo}
            />
        )
    )

    passwordResetModal = () => (
        <ModalBool
            isOpen={
                this.state.is_password_reset_modal_open
            }
            title="Confirm Reset User Password"
            message={`Are you sure you want to reset the password for user '${this.state.user_to_reset_password.name}' ?`}
            trueButtonText="Reset"
            falseButtonText="Cancel"
            toggle={this.togglePasswordResetModal}
            handleTrue={this.handlePasswordResetYes}
            handleFalse={this.handlePasswordResetNo}
            loading={this.state.is_resetting_password}
        />
    )

    userInvite = () => (
        <UserInvite
            is_modal_open={this.state.is_invite_modal_open}
            toggle={this.toggleUserInvite}
            handleChange={this.handleChange}
            handleSendInvite={this.sendInvite}
            roleOptions={this.roleOptions()}
            email={this.state.invitee_email}
            role={this.state.invitee_role}
        />
    )

    pageHeader = () => {
        const countMessage = this.state.is_loading ? 'Loading...' :
            `${this.state.users?.length === 0 ? 'No' : this.state.users?.length} users found`

        return (
            <div style={{ marginBottom: 10 }}>
                <h3 style={{ marginBottom: 3 }}>
                    <i className="icon-user" />{' '}
                    Users
                    <RoleBasedContent
                        required_roles={[
                            FACILITY_ADMIN_ROLE,
                            SUPER_ADMIN_ROLE,
                            SUPPORT_ROLE,
                        ]}
                    >
                        <NeutralButton
                            onClick={this.toggleUserInvite}
                            sx={{ marginTop: 2, float: 'right' }}
                        >
                            Invite
                        </NeutralButton>
                    </RoleBasedContent>
                    <RoleBasedContent
                        required_roles={[SUPER_ADMIN_ROLE, SUPPORT_ROLE]}
                    >
                        <NeutralButton
                            component={Link}
                            to="/User/Create"
                            sx={{ marginTop: 2, float: 'right', mr: 2 }}
                        >
                            Create
                        </NeutralButton>
                    </RoleBasedContent>
                </h3>
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
                {this.state.is_loading && (<LoadingSpinner />)}
                {this.state.is_invite_modal_open && this.userInvite()}
            </div>
        )
    }

    tableHeader = () => (
        <TableHead>
            <TableRow>
                <SortHeader
                    text="Name"
                    field={NAME_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Email"
                    field={EMAIL_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Role"
                    field={ROLENAME_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <TableCell colSpan={3} />
            </TableRow>
        </TableHead>
    )

    render() {
        const isLoading = this.state.is_loading
        const noUsers = !this.state.is_loading && !this.state.get_users_did_fail && this.state.users?.length === 0
        const isConfirmOpen = this.state.is_confirm_modal_open
        const isPwdResetOpen = this.state.is_password_reset_modal_open
        const isInviteOpen = this.state.is_invite_modal_open

        if (isLoading || noUsers)
            return (this.pageHeader())

        return (
            <div className="animated fadeIn Users">
                <div>
                    {isConfirmOpen && this.confirmModal()}
                    {isPwdResetOpen && this.passwordResetModal()}
                    {isInviteOpen && this.userInvite()}
                    {this.pageHeader()}

                    <TableContainer>
                        <Table>
                            {this.tableHeader()}
                            <TableBody>
                                {this.state.users.map(user => (
                                    <React.Fragment key={user.ID}>
                                        <TableRow className={`user_row ${user.isDeleted ? 'deleted' : ''}`}>
                                            <TableCell title={`${user.LastName}, ${user.FirstName} ${user.MiddleName || ''}`}>
                                                {this.getUserName(user)}
                                            </TableCell>
                                            <TableCell style={{ maxWidth: '300px' }}>
                                                <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }} >
                                                    {user.Email}
                                                </div>
                                            </TableCell>
                                            <TableCell style={{ maxWidth: '300px' }}>
                                                <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }} >
                                                    {user.roleName}
                                                </div>
                                            </TableCell>
                                            <TableCell className="text-right">
                                                <div className="row-action-icons">
                                                    <IconButton
                                                        title="Audit Logs"
                                                        color="primary"
                                                        component={Link}
                                                        to={`/User/${user.ID}/AuditLogs`}>
                                                        <i className="icon fas fa-list" />
                                                    </IconButton>
                                                    <RoleBasedContent
                                                        required_roles={[
                                                            SUPER_ADMIN_ROLE,
                                                            SUPPORT_ROLE,
                                                            FACILITY_ADMIN_ROLE,
                                                        ]}
                                                    >
                                                        {this.canEdit(user) && (
                                                            <React.Fragment>
                                                                <IconButton
                                                                    title="Edit"
                                                                    color="primary"
                                                                    component={Link}
                                                                    to={`/User/${user.ID}`}>
                                                                    <i className="fa fa-pencil-alt" />
                                                                </IconButton>
                                                                <RoleBasedContent
                                                                    required_roles={[
                                                                        SUPER_ADMIN_ROLE,
                                                                        SUPPORT_ROLE,
                                                                    ]}
                                                                >
                                                                    <IconButton
                                                                        title="Reset Password"
                                                                        color="primary"
                                                                        onClick={() =>
                                                                            this.initiatePasswordReset(
                                                                                user.ID,
                                                                                this.getUserName(
                                                                                    user,
                                                                                )
                                                                            )
                                                                        }>
                                                                        <i className="fa icon-electrotek-forgot-password" />
                                                                    </IconButton>
                                                                </RoleBasedContent>
                                                            </React.Fragment>
                                                        )}
                                                        {!user.isDeleted && (
                                                            <IconButton
                                                                title="Info"
                                                                color="primary"
                                                                onClick={() =>
                                                                    this.toggleInfoRow(
                                                                        user.ID
                                                                    )
                                                                }>
                                                                <i className="fa fa-info" />
                                                            </IconButton>
                                                        )}
                                                        {!user.isDeleted && this.canEdit(user) && (
                                                            <IconButton
                                                                title="Delete"
                                                                color="primary"
                                                                onClick={() =>
                                                                    this.initiateDeleteUser(
                                                                        user.ID,
                                                                        this.getUserName(
                                                                            user
                                                                        )
                                                                    )
                                                                }>
                                                                <i className="fa fa-trash-alt" />
                                                            </IconButton>
                                                        )}
                                                    </RoleBasedContent>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {
                                            this.state.is_row_open[user.ID] &&
                                            (
                                                <RoleBasedContent required_roles={[SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE]}>
                                                    <UserInfoRow user={user} />
                                                </RoleBasedContent>
                                            )
                                        }
                                    </React.Fragment>
                                ))}
                                {!this.state.users.length && (
                                    <TableRow>
                                        <TableCell colSpan={8}>
                                            {this.state.get_users_did_fail && (
                                                <FailedToLoad
                                                    items="users"
                                                    onRetry={this.getUsers}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                </div>
            </div >
        )
    }
}

export default Users
