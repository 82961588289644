export const EPOCH_DIRECTION_NONE = 0
export const EPOCH_DIRECTION_FORWARD = 1
export const EPOCH_DIRECTION_FORWARD_FAST = 2
export const EPOCH_DIRECTION_BACKWARD = 3
export const edfn = []

edfn[EPOCH_DIRECTION_NONE] = 'no direction'
edfn[EPOCH_DIRECTION_FORWARD] = 'forward'
edfn[EPOCH_DIRECTION_FORWARD_FAST] = 'fast forward'
edfn[EPOCH_DIRECTION_BACKWARD] = 'backward'
