import axios from 'axios'
import MMTApi from './MMTApi'

const UsersApi = {
    getUsers() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Users/GetUsers`,
            MMTApi.getRequestHeaders(),
        )
    },

    createUser(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Users/CreateUser`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    deleteUser(guid, isFacilityUser = true) {
        const url = isFacilityUser ? `${process.env.REACT_APP_API_URL}/api/Users/DeleteFacilityUser/?id=${guid}`
            : `${process.env.REACT_APP_API_URL}/api/Users/${guid}`

        return axios.delete(url, MMTApi.getRequestHeaders())
    },

    updateUser(guid, data) {
        return axios
            .patch(
                `${process.env.REACT_APP_API_URL}/api/Users/?id=${guid}`,
                data,
                MMTApi.getRequestHeaders(),
            )
            .then(() => {
                const newRole = data.Role
                return axios.post(
                    `${process.env.REACT_APP_API_URL
                    }/api/Users/ChangeUserRole?userId=${guid}&newRoleId=${newRole}`,
                    newRole,
                    MMTApi.getRequestHeaders(),
                )
            })
    },

    setSelectedFacility(guid) {
        return axios.post(
            `${process.env.REACT_APP_API_URL
            }/api/Users/SetSelectedFacility?id=${guid}`,
            '',
            MMTApi.getRequestHeaders(),
        )
    },

    getUserInfo(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Users/GetUserInfo/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getUserForCPTMonitoring(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL
            }/api/Users/GetUserForCPTMonitoring/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getAvailableRolesForUser(userId: string) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Roles/GetAvailableRolesForUser?userId=${userId}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getMyRoles() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Users/getMyRoles`,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default UsersApi
