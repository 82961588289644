import React, { useContext } from 'react'
import './GridLines.scss'
import DateTimeUtils from '../../../../utils/DateTimeUtils'
import AppContext from '../../../../components/AppContext'
import { getRGBAFromHexARGB } from '../../utils/ColorUtilities'
import Gutter from '../../../../types/Gutter'
import ClockSetting from '../../../../types/ClockSetting'
import GraphUtilities from '../../utils/GraphUtilities'

const getVerticalLineCount = (timebase: string) => {
    switch (timebase) {
        case '1':
            return 10
        case '30':
            return 6
        default:
            return parseInt(timebase, 10)
    }
}

interface Props {
    sampleOffset: number
    timebase: string
    theme: any
    width: number
    height: number
    midpoints: { channel_index: number, midpoint: number }[]
    gutter: Gutter
    clockSetting: ClockSetting
}

const GridLines: React.FC<Props> = props => {
    const ctx = useContext(AppContext)
    let packetsPerVerticleLine: number
    switch (props.timebase) {
        case '1':
            packetsPerVerticleLine = 0.1 * ctx.Study.StudyDataRate
            break
        case '30':
            packetsPerVerticleLine = 5 * ctx.Study.StudyDataRate
            break
        default:
            packetsPerVerticleLine = 1 * ctx.Study.StudyDataRate
    }

    const renderVerticalLines = () => {
        const lines = []
        const lineCount = getVerticalLineCount(props.timebase)
        for (let i = 1; i <= lineCount; i += 1) {
            const left = `${((props.width - 1) * i) / lineCount - 1}px`
            const style = {
                left,
                width: '0',
                height: `${props.height - 1}px`,
                borderLeft: `1px solid ${getRGBAFromHexARGB(
                    props.theme.VerticalGridColor,
                )}`,
            }

            const packetIndex = packetsPerVerticleLine * i + props.sampleOffset
            const lineMoment = GraphUtilities.getMomentByPacketIndex(ctx, packetIndex)

            let decimalFormat = ''
            if (props.timebase === '1') {
                decimalFormat = '.S'
            }

            let display = ''
            switch (props.clockSetting) {
                case ClockSetting.Duration:
                    display = DateTimeUtils.getGraphTimestamp(
                        i,
                        props.sampleOffset,
                        props.timebase,
                        ctx.Study.StudyDataRate,
                    )
                    break
                case ClockSetting.TwelveHour:
                    display = lineMoment.format(`h:mm:ss${decimalFormat} A`)
                    break
                case ClockSetting.TwentyFourHour:
                    display = lineMoment.format(`HH:mm:ss${decimalFormat}`)
                    break
                default:
                    throw new Error(`unrecognized clock setting ${props.clockSetting}`)
            }
            lines.push(
                <div key={i} style={style}>
                    <span
                        className="timestamp"
                        style={{
                            color: getRGBAFromHexARGB(
                                props.theme.LabelColor,
                            ),
                        }}
                    >
                        {display}
                    </span>
                </div>,
            )
        }

        return lines
    }

    const renderHorizontalLines = () => {
        const width = `${props.width - 1}px`
        const borderTop = `1px solid ${getRGBAFromHexARGB(
            props.theme.HorizontalGridColor,
        )}`

        return props.midpoints.map(midpoint => (
            <div
                key={midpoint.channel_index}
                style={{
                    top: `${midpoint.midpoint}px`,
                    width,
                    borderTop,
                }}
            />
        ))
    }

    return (
        <div
            className="grid-lines"
            style={{
                width: `${props.width}px`,
                left: `${props.gutter.left}px`,
                right: `${props.gutter.right}px`,
                height: `${props.height}px`,
                borderBottom: `1px solid ${getRGBAFromHexARGB(
                    props.theme.AxesColor,
                )}`,
                borderLeft: `1px solid ${getRGBAFromHexARGB(
                    props.theme.AxesColor,
                )}`,
                background: getRGBAFromHexARGB(props.theme.BackgroundColor),
            }}
        >
            {renderHorizontalLines()}
            {renderVerticalLines()}
        </div>
    )
}

export default GridLines
