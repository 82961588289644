import { Box } from '@mui/system'
import { PropsWithChildren } from 'react'
import NotSet from '../../components/NotSet'
import { SYNCED } from '../../constants/syncstates'
import Study from '../../types/Study'

interface Props {
    study: Study
}

function NextRowCell(props: PropsWithChildren<{}>) {
    return (
        <Box
            sx={{
                m: t => t.spacing(0, 1),
            }}
            component="span"
        >
            {props.children}
        </Box>
    )
}

function PatientStudiesInfoRow(props: Props) {
    const hasSyncError = props.study.SyncInfo.StudyRecordings.find((r: any) => r.SyncState === SYNCED && r.SyncPacketIndex !== r.PacketCount)

    return (
        <Box sx={{
            mt: 1,
            whiteSpace: 'nowrap',
            color: 'rgba(255, 255, 255, 0.5)',
            '& > :last-child': {
                mr: 0,
            },
            '& > span:first-of-type': {
                ml: 0,
            },
        }}>
            <NextRowCell>
                {props.study.StudyStateName.replace(/([a-z])([A-Z])/g, '$1 $2')}
            </NextRowCell>
            |
            <NextRowCell>
                Study ID: {props.study.ExamId ?? <NotSet />}
            </NextRowCell>
            |
            <NextRowCell>
                Amplifier Name: {props.study.AmplifierName}
            </NextRowCell>
            |
            <NextRowCell>
                {props.study.SyncState === SYNCED ? (
                    hasSyncError? 'Sync Error' : 'Sync Complete'
                ) : (
                    <>
                        <span>
                            Data: {props.study.SyncInfo.DataChecksumError
                                ? 'Incomplete'
                                : props.study.SyncInfo.StudyDataSyncPercent}%
                        </span>
                        {props.study.UsesVideo && (
                            <span>
                                {' '}
                                Video: {props.study.SyncInfo.VideoChecksumError
                                    ? 'Incomplete'
                                    : props.study.SyncInfo.VideoDataSyncPercent}%
                            </span>
                        )}
                    </>
                )}
            </NextRowCell>
            {props.study.IsLive && (
                <>
                    |
                    <NextRowCell>
                        <span style={{ color: 'red' }}>
                            LIVE
                        </span>
                    </NextRowCell>
                </>
            )}
        </Box>
    )
}

export default PatientStudiesInfoRow
