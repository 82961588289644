import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    FACILITY_ADMIN_ROLE,
    PRODUCTION_ROLE,
} from '../constants/roles'

const FacilityAuth = {
    List: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE, FACILITY_ADMIN_ROLE],
    View: [SUPER_ADMIN_ROLE, FACILITY_ADMIN_ROLE, PRODUCTION_ROLE],
}

export default FacilityAuth
