import { Components } from '@mui/material/styles'

function IconButton(): Components['MuiIconButton'] {
    return {
        styleOverrides: {
            root: {
                '& > .fas, & > .fa, & > .icon': {
                    height: 24,
                    width: 24,
                    '&::before': {
                        position: 'absolute',
                        transform: 'translate(-50%, -50%)',
                        top: '50%',
                        left: '50%',
                    },
                },
            },
        },
        defaultProps: {
            size: 'small',
        },
    }
}

export default IconButton
