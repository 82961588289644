import React from 'react'
import moment from 'moment'
import PatientsApi from '../../services/PatientsApi'
import ModalBool from '../../components/modals/ModalBool'
import PatientForm from './PatientForm'
import Validation from '../../services/Validation'
import SessionManager from '../../services/SessionManager'
import NavUtils from '../../utils/NavUtils'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { hasRequiredRole } from '../../services/Auth'
import {
    FACILITY_ADMIN_ROLE,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    LEAD_TECH_ROLE,
    OFFICE_PERSONNEL_ROLE,
    FIELD_TECH_ROLE,
} from '../../constants/roles'
import LoadingSpinner from '../../components/LoadingSpinner'
import ToastUtils from '../../utils/ToastUtils'
import AppContext from '../../components/AppContext'
import { Button } from '@mui/material'

const length_validation = {
    FirstName: {
        min: 1,
        max: 50,
    },
    MiddleName: {
        min: 1,
        max: 50,
    },
    LastName: {
        min: 1,
        max: 50,
    },
    DateOfBirth: {
        min: 1,
    },
    Sex: {
        min: 0,
        max: 1,
    },
    SSN: {
        min: 9,
        max: 9,
    },
    PatientID: {
        min: 1,
        max: 30,
    },
}

class Patient extends React.Component {
    static contextType = AppContext

    state = {
        patient: {},
        initial_patient: {
            FirstName: '',
            MiddleName: '',
            LastName: '',
            DateOfBirth: '',
            Sex: '',
            SSN: '',
            PatientID: '',
            FacilityID: '',
        },
        value_changed: {
            FirstName: false,
            MiddleName: false,
            LastName: false,
            DateOfBirth: false,
            Sex: false,
            SSN: false,
            PatientID: false,
            FacilityID: false,
        },
        validation: {
            FirstName: true,
            MiddleName: true,
            LastName: true,
            DateOfBirth: true,
            Sex: true,
            SSN: true,
            PatientID: true,
        },
        sexesList: [
            { Name: '', Value: '' },
            { Name: 'Female', Value: 'f' },
            { Name: 'Male', Value: 'm' },
        ],
        is_saving: false,
        is_loading: true,
        is_patient_id_modal_open: false,
    }

    componentDidMount() {
        const isEditText = this.props.match.params.id ? 'Edit ' : ''
        this.pageTitle = this.props.creating
            ? 'Create Patient'
            : `${isEditText}Patient`
        NavUtils.setPageTitle(this.pageTitle)

        if (!this.props.creating) {
            PatientsApi.getPatientForEditing(this.props.match.params.id)
                .then(patient => {
                    this.setPatient(patient.data)
                    this.resetFacilityField()
                })
                .catch(error => {
                    const isNotFound = error.status === 404
                    const isBadRequest = error.status === 400
                    if (isNotFound || isBadRequest) {
                        window.location.href = '/404'
                    }
                    ToastUtils.error({ message: error.data.Message || error.statusText })
                })
        } else {
            this.newPatient()
            this.resetFacilityField()
        }
    }

    setPatient(patient) {
        patient.DateOfBirth = DateTimeUtils.getFriendlyDateOfBirth(
            patient.DateOfBirth,
        )
        this.setState({
            patient,
            initial_patient: patient,
            is_loading: false,
        })
    }

    handleDateOfBirthChange = value => {
        const patient = {
            ...this.state.patient,
            DateOfBirth: value,
        }
        this.setState(
            {
                patient,
            },
            () => {
                this.hasPatientChanged('DateOfBirth')
            },
        )
    }

    handleChange = (field, value) => {
        if (typeof value === 'string') {
            value = value.replace(/^\s+/g, '')
        }
        const patient = { ...this.state.patient, [field]: value }
        this.setState(
            {
                patient,
            },
            () => {
                this.hasPatientChanged(field)
            },
        )
    }

    hasPatientChanged = field => {
        if (this.hasPatientValueChanged(field)) {
            this.setState({
                value_changed: {
                    ...this.state.value_changed,
                    [field]: true,
                },
            })
        } else {
            this.setState({
                value_changed: {
                    ...this.state.value_changed,
                    [field]: false,
                },
            })
        }
    }

    hasPatientValueChanged(field) {
        // if one field is null, then we can't call toString()
        if (
            Validation.isNullOrEmpty(this.state.initial_patient[field]) ||
            Validation.isNullOrEmpty(this.state.patient[field])
        ) {
            // if both are empty or null, then we didn't change
            if (
                Validation.isNullOrEmpty(this.state.initial_patient[field]) &&
                Validation.isNullOrEmpty(this.state.patient[field])
            )
                return false

            // otherwise, something changed
            return true
        }

        if (field === 'DateOfBirth') {
            const initialDate = moment(
                this.state.initial_patient[field],
                'YYYY-MM-DD',
                'en',
            )
            const newDate = moment(
                this.state.patient[field],
                'YYYY-MM-DD',
                'en',
            )

            return initialDate.diff(newDate, 'days') !== 0
        }

        return (
            this.state.initial_patient[field].toString() !==
            this.state.patient[field].toString()
        )
    }

    checkDuplicatePatientId = () => {

        const patientId = this.state.patient.PatientID
        const origPatientId = this.state.initial_patient.PatientID
        const hasPatientIdChanged = origPatientId?.toLocaleUpperCase() !== patientId?.toLocaleUpperCase()
        const isPatientIdValid = patientId?.length >= length_validation.PatientID.min &&
            patientId.length <= length_validation.PatientID.max

        if (!this.isFormValid()) {
            return
        }

        if (isPatientIdValid && hasPatientIdChanged) {
            this.setState({ is_saving: true })
            PatientsApi.doesPatientWithPatientIdExist(patientId)
                .then(patientExists => {
                    if (patientExists.data) {
                        this.setState({ is_saving: false })
                        this.togglePatientIdModal()
                    }
                    else {
                        this.handleSubmit()
                    }
                })
        }
        else {
            this.handleSubmit()
        }
    }

    handleSubmit = () => {
        this.setState({ is_patient_id_modal_open: false })
        if (!this.props.creating) {
            this.updatePatient()
        } else {
            this.createPatient()
        }
    }

    togglePatientIdModal = () => {
        this.setState({
            is_patient_id_modal_open: !this.state.is_patient_id_modal_open,
        })
    }

    newPatient = () => {
        this.setState({
            is_loading: false,
        })
    }

    resetFacilityField() {
        const currentFacility = SessionManager.get('mmt_facility_id')

        if (this.props.creating) {
            this.setState({
                patient: {
                    ...this.state.patient,
                    FacilityID: currentFacility,
                },
            })
        } else {
            this.setState({
                patient: {
                    ...this.state.patient,
                    FacilityID: this.state.patient.FacilityID,
                },
            })
        }
    }

    isFormValid = () => {
        let valid = true
        let FirstName = true
        let MiddleName = true
        let LastName = true
        let DateOfBirth = true
        let Sex = true
        let SSN = true
        let PatientID = true

        if (
            !this.state.patient.DateOfBirth ||
            this.state.patient.DateOfBirth.length <
            length_validation.DateOfBirth.min ||
            !this.isValidDateOfBirth(this.state.patient.DateOfBirth)
        ) {
            valid = false
            DateOfBirth = false
        }

        if (
            !this.state.patient.FirstName ||
            this.state.patient.FirstName.trim().length <
            length_validation.FirstName.min ||
            this.state.patient.FirstName.length >
            length_validation.FirstName.max
        ) {
            valid = false
            FirstName = false
        }

        if (
            this.state.patient.MiddleName &&
            (this.state.patient.MiddleName.length <
                length_validation.MiddleName.min ||
                this.state.patient.MiddleName.length >
                length_validation.MiddleName.max)
        ) {
            valid = false
            MiddleName = false
        }

        if (
            !this.state.patient.LastName ||
            this.state.patient.LastName.trim().length <
            length_validation.LastName.min ||
            this.state.patient.LastName.length > length_validation.LastName.max
        ) {
            valid = false
            LastName = false
        }

        if (
            this.state.patient.Sex &&
            (this.state.patient.Sex.length < length_validation.Sex.min ||
                this.state.patient.Sex.length > length_validation.Sex.max)
        ) {
            valid = false
            Sex = false
        }

        if (
            this.state.patient.PatientID &&
            (this.state.patient.PatientID.length <
                length_validation.PatientID.min ||
                this.state.patient.PatientID.length >
                length_validation.PatientID.max)
        ) {
            valid = false
            PatientID = false
        }

        // TODO: validate sex selection if one is made

        if (
            this.state.patient.SSN &&
            (this.state.patient.SSN.length < length_validation.SSN.min ||
                this.state.patient.SSN.length > length_validation.SSN.max ||
                !Validation.isDigits(this.state.patient.SSN))
        ) {
            valid = false
            SSN = false
        }

        this.setState({
            validation: {
                ...this.state.validation,
                FirstName,
                MiddleName,
                LastName,
                DateOfBirth,
                Sex,
                SSN,
                PatientID,
            },
        })

        return valid
    }

    isValidDateOfBirth(input) {
        if (typeof input === 'string' && input.split('/').length !== 3)
            return false

        const date = moment(input, 'MM/DD/YYYY', 'en', true)
        const today = moment()

        if (date.isValid()) {
            return date.year() > 1752 && date <= today
        }

        return false
    }

    createPatient() {
        if (this.isFormValid()) {
            this.setState({
                is_saving: true,
            })

            PatientsApi.createPatient(this.state.patient)
                .then(() => {
                    this.setState({ is_saving: false })
                    ToastUtils.success({ message: 'Patient created!' })
                })
                .then(() => {
                    this.resetState()
                    this.resetFacilityField()
                })
                .catch(error => {
                    this.setState({ is_saving: false })
                    ToastUtils.error({ message: error.data.Message || error.statusText })
                })
        }
    }

    updatePatient() {
        if (this.isFormValid()) {
            this.setState({
                is_saving: true,
            })

            PatientsApi.updatePatient(
                this.props.match.params.id,
                this.state.patient,
            )
                .then(() => {
                    this.setState({
                        is_saving: false,
                        initial_patient: this.state.patient,
                        value_changed: {
                            FirstName: false,
                            MiddleName: false,
                            LastName: false,
                            DateOfBirth: false,
                            Sex: false,
                            SSN: false,
                            PatientID: false,
                        },
                    })
                    ToastUtils.success({ message: 'Patient saved!' })
                })
                .catch(error => {
                    this.setState({ is_saving: false })
                    ToastUtils.error(
                        this.getErrorMessage(error) ||
                        'Unable to update patient',
                    )
                })
        }
    }

    getErrorMessage(error) {
        if (error.data.ModelState) {
            const data = error.data.ModelState

            // return the first error in the dictionary
            for (const key in data) {
                return data[key]
            }

            return error.data.ModelState[0]
        }

        return error.data.Message || error.statusText
    }

    resetState = () => {
        const patient = {
            FirstName: '',
            MiddleName: '',
            LastName: '',
            DateOfBirth: '',
            Sex: '',
            SSN: '',
            PatientID: '',
        }

        const validation = {
            FirstName: true,
            MiddleName: true,
            LastName: true,
            DateOfBirth: true,
            Sex: true,
            SSN: true,
            PatientID: true,
        }

        this.setState({
            ...this.state,
            patient,
            validation,
            is_saving: false,
            value_changed: {
                FirstName: false,
                MiddleName: false,
                LastName: false,
                DateOfBirth: false,
                Sex: false,
                SSN: false,
                PatientID: false,
            },
        })
    }

    canEdit = () =>
        hasRequiredRole(
            [
                SUPER_ADMIN_ROLE,
                LEAD_TECH_ROLE,
                FIELD_TECH_ROLE,
                SUPPORT_ROLE,
                FACILITY_ADMIN_ROLE,
                OFFICE_PERSONNEL_ROLE,
            ],
            this.context.Roles,
        )

    render() {
        if (this.state.is_loading) return <LoadingSpinner />
        const has_patient_changed = Object.values(
            this.state.value_changed,
        ).some(element => element === true)
        const can_edit = this.canEdit()

        return (
            <div className="col-xl-4 col-lg-6 px-0">
                <div>
                    {this.state.is_patient_id_modal_open && (
                        <ModalBool
                            isOpen={this.state.is_patient_id_modal_open}
                            title="Duplicate Patient ID"
                            message="This patient ID already exists and is assigned to a patient. Do you want to continue? This will result in multiple patients with the same Patient ID."
                            trueButtonText="Allow Duplicate ID"
                            falseButtonText="Cancel"
                            toggle={this.togglePatientIdModal}
                            handleTrue={this.handleSubmit}
                            handleFalse={this.togglePatientIdModal}
                        />
                    )}
                </div>
                <h3>
                    <i className="icon-electrotek-person" /> {this.pageTitle}
                </h3>
                <div>
                    <PatientForm
                        {...this.state.patient}
                        validationRules={length_validation}
                        validation={this.state.validation}
                        sexesList={this.state.sexesList}
                        handleChange={this.handleChange}
                        handleDateOfBirthChange={this.handleDateOfBirthChange}
                        creating={this.props.creating}
                        readOnly={!can_edit}
                    />
                    <Button
                        onClick={this.checkDuplicatePatientId}
                        disabled={this.state.is_saving || !has_patient_changed}
                        sx={{ float: 'right' }}
                    >
                        {this.state.is_saving ? 'Saving...' : 'Save'}
                    </Button>
                </div>
            </div>
        )
    }
}

export default Patient
