import React from 'react'
import { hasRequiredRole } from '../services/Auth'
import AppContext from './AppContext'

interface Props {
    required_roles: string[] | undefined
}

const RoleBasedContent: React.FC<Props> = props => {
    return (
        <AppContext.Consumer>
            {context => {
                const hasRole =
                    props.required_roles &&
                    hasRequiredRole(
                        props.required_roles,
                        context.Roles,
                    )
                return hasRole && props.children
            }}
        </AppContext.Consumer>
    )
}

export default RoleBasedContent
