import React, { useState, useEffect, useRef, useCallback } from 'react'
import './CPTStudyMonitoring.scss'
import moment, { Moment } from 'moment'
import CPTMonitoringBlockView from './CPTMonitoringBlockView'
import AuditLogsApi from '../../services/AuditLogsApi'
import ToastUtils from '../../utils/ToastUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import AuditLogEntry, { ChangeActionType } from '../../types/AuditLogEntry'
import { Session } from '../../types/cptMonitoringTypes'
import NavUtils from '../../utils/NavUtils'
import CPTMonitoringService from '../../services/CPTMonitoringService'
import StudyAuditLogs from '../studies/StudyAuditLogs'
import StudiesApi from '../../services/StudiesApi'
import Study from '../../types/Study'
import { handleZoomIn, handleZoomOut } from './utils'
import Patient from '../../types/Patient'
import RoleBasedContent from '../../components/RoleBasedContent'
import StudyAuth from '../../auth/StudyAuth'
import { downloadFile } from '../../utils/DownloadUtils'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { MIN_ZOOM_SCALE, MAX_ZOOM_SCALE } from './constants'
import { Scope } from './types'
import { Typography } from '@mui/material'

interface Props {
    match: any
}

enum View {
    Block,
    List,
}

const CPTStudyMonitoring: React.FC<Props> = props => {
    const wrapper = useRef<HTMLDivElement | null>(null)
    const [msPerPx, setMsPerPx] = useState(50000)
    const [logEntries, setLogEntries] = useState<AuditLogEntry[] | undefined>()
    const [view, setView] = useState(View.List)
    const [study, setStudy] = useState<Study | undefined>()
    const [patient, setPatient] = useState<Patient | undefined>()

    const studyId = props.match.params.id
    useEffect(() => {
        NavUtils.setPageTitle('Study Monitoring History')
    }, [])

    const getLogs = useCallback((study: Study) => {
        setLogEntries(undefined)
        const startWithPadding = moment(study.StudyRecordings[0].DateStarted).subtract(1, 'day')
        let endWithPadding: Moment | undefined = undefined
        if (study.StudyRecordings[study.StudyRecordings.length - 1].DateStopped) {
            endWithPadding = moment(study.StudyRecordings[study.StudyRecordings.length - 1].DateStopped).add(1, 'day')
        }
        AuditLogsApi.getCptMonitoringLogsForStudy(study.ID, startWithPadding, endWithPadding)
            .then(res => {
                const actionTypes = [ChangeActionType.StartMonitoring, ChangeActionType.StopMonitoring]
                const logEntries = res.data.filter(e => actionTypes.includes(e.ActionTypeId))
                setLogEntries(logEntries)
            })
            .catch(err => {
                console.log(err)
                ToastUtils.error({ message: 'Unable to get monitoring logs' })
            })
    }, [])

    const getStudy = useCallback((id: string) => {
        StudiesApi.getStudy(id)
            .then(res => {
                const study = res.data.Study
                setStudy(study)
                setPatient(res.data.Patient)
                if (study.StudyRecordings.length === 0) {
                    setLogEntries([])
                    return
                }
                const start = moment(study.StudyRecordings[0].DateStarted) // no recs
                const lastRecording = study.StudyRecordings[study.StudyRecordings.length - 1]
                let stop = moment()
                if (lastRecording.DateStopped) {
                    stop = moment(lastRecording.DateStopped)
                }
                const fittedMsPerPx = stop.diff(start) / 500
                if (fittedMsPerPx < MIN_ZOOM_SCALE) {
                    setMsPerPx(MIN_ZOOM_SCALE)
                } else if (fittedMsPerPx > MAX_ZOOM_SCALE) {
                    setMsPerPx(MAX_ZOOM_SCALE)
                } else {
                    setMsPerPx(fittedMsPerPx)
                }
                getLogs(study)
            })
            .catch(err => {
                console.log(err)
                ToastUtils.error({ message: 'Unable to get study' })
            })
    }, [getLogs])

    useEffect(() => {
        StudiesApi.getLinkedStudies(studyId)
            .then(res => {
                getStudy(res.data.PCStudyId)
            })
            .catch(err => {
                console.log(err)
                ToastUtils.error({ message: 'Unable to get linked study' })
            })

    }, [studyId, getStudy])

    const renderSessionInfo = (session: Session) => {
        const getSessionTimeRange = () => {
            const formatString = 'h:mm a MMM D'
            const started = session.Started.format(formatString)

            let stopped = 'now'
            if (session.Stopped) {
                stopped = session.Stopped.format(formatString)
            }

            return `${started} - ${stopped}`
        }

        const timeRange = getSessionTimeRange()

        return (
            <div className="info">
                <p title={session.UserName}>{session.UserName}</p>
                <p title={session.UserRole}>Role: {session.UserRole}</p>
                <p title={timeRange}>{timeRange}</p>
            </div>
        )
    }

    let scope: Scope | null = null
    if (study && study.StudyRecordings.length > 0) {
        const firstRecording = study.StudyRecordings[0]
        const lastRecording = study.StudyRecordings[study.StudyRecordings.length - 1]
        let start = DateTimeUtils.getMomentFromDBString(firstRecording.DateStarted)
        start = start.tz(firstRecording.TimeZone)
        let end = moment()
        if (lastRecording.DateStopped) {
            end = DateTimeUtils.getMomentFromDBString(lastRecording.DateStopped)
            end = end.tz(lastRecording.TimeZone)
        }
        scope = { start, end }
    }

    let sessions: Session[] = []
    if (logEntries && study && scope) {
        sessions = CPTMonitoringService.getUserSessions(logEntries, scope.start, scope.end, study.StudyRecordings[0].TimeZone)
    }

    const handleDownload = () => {
        if (!study || !patient) return
        const f = CPTMonitoringService.getStudyCSVReport(sessions, study, patient)
        downloadFile(f)
    }

    let heading = ''
    if (study) {
        heading += `${study.ExamId || '<No Study ID>'}`
        if (study.StudyRecordings.length > 0) {
            const firstRecording = study.StudyRecordings[0]
            const lastRecording = study.StudyRecordings[study.StudyRecordings.length - 1]
            const dateStarted = moment(firstRecording.DateStarted).tz(firstRecording.TimeZone)
            let dateStopped = moment().tz(lastRecording.TimeZone)
            if (lastRecording.DateStopped) {
                dateStopped = moment(lastRecording.DateStopped).tz(lastRecording.TimeZone)
            }
            let dateCreatedDisplay = ''
            if (dateStarted.date() !== dateStopped.date()) {
                dateCreatedDisplay = `${dateStarted.format('MMM D, YYYY')} - ${dateStopped.format('MMM D, YYYY')}`
            } else {
                dateCreatedDisplay = dateStarted.format('MMM D, YYYY')
            }
            heading += ` (${dateCreatedDisplay})`
        }
    }

    return (
        <div className="animated fadeIn cpt-study-monitoring">
            <div className="row">
                <section className="col-lg-12">
                    <div className="action-icons">
                        <RoleBasedContent required_roles={StudyAuth.AuditLogsList}>
                            <i
                                title="All audit logs"
                                className="icon icon-electrotek-th-list mr-3"
                                onClick={() => setView(View.List)}
                            />
                            <i
                                title="CPT Monitoring logs"
                                className="icon icon-electrotek-tile mr-3"
                                onClick={() => setView(View.Block)}
                            />
                        </RoleBasedContent>
                        {view === View.Block && (
                            <>
                                <i
                                    title="Zoom in"
                                    className={`icon-electrotek-zoom-in ${msPerPx <= MIN_ZOOM_SCALE ? 'disabled' : ''}`}
                                    onClick={() => handleZoomIn(MIN_ZOOM_SCALE, msPerPx, wrapper, setMsPerPx)}
                                />
                                <i
                                    title="Zoom out"
                                    className={`icon-electrotek-zoom-out ${msPerPx >= MAX_ZOOM_SCALE ? 'disabled' : ''}`}
                                    onClick={() => handleZoomOut(MAX_ZOOM_SCALE, msPerPx, wrapper, setMsPerPx)}
                                />
                            </>
                        )}
                        <i
                            title="Download report"
                            className="fas fa-cloud-download-alt"
                            onClick={handleDownload}
                        />
                    </div>
                    <h3>
                        <i
                            className="icon-electrotek-monitoring mr-3"
                            style={{ position: 'relative', top: '3px' }}
                        />
                        {heading}
                        <hr />
                    </h3>
                    <div>
                        {patient && (
                            <>
                                <p>
                                    Patient: {patient.FirstName}
                                    {patient.MiddleName ? ` ${patient.MiddleName[0]}. ` : ' '}
                                    {patient.LastName}
                                </p>
                                <p>
                                    ID: {patient.PatientID}
                                </p>
                                <p>
                                    DOB: {moment(patient.DateOfBirth).format('MMM D, YYYY')}
                                </p>
                                <hr />
                            </>
                        )}
                    </div>
                    {(!logEntries || !study || !scope) ? (
                        <>
                            {logEntries ? (
                                <Typography
                                    variant="h4"
                                    sx={{
                                        textAlign: 'center',
                                        pt: 5,
                                    }}
                                >
                                    No logs for this study yet.
                                </Typography>
                            ) : <LoadingSpinner />}
                        </>
                    ) : (
                        <>
                            {view === View.Block && (
                                <CPTMonitoringBlockView
                                    wrapper={wrapper}
                                    scope={scope}
                                    msPerPx={msPerPx}
                                    sessions={sessions}
                                    renderSessionInfo={renderSessionInfo}
                                />
                            )}
                            {view === View.List && <StudyAuditLogs study={study} />}
                        </>
                    )}
                </section>
            </div>
        </div>
    )
}

export default CPTStudyMonitoring
