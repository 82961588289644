import React, { useRef, useState } from 'react'
import './MontageChannelListView.scss'
import Montage from '../../types/Montage'
import MontageChannel from '../../types/MontageChannel'
import { getChannelFormulaViolation, normalizeChannelFormulaCase } from '../../utils/StringUtils'
import ToastUtils from '../../utils/ToastUtils'
import { EEG } from '../studies/eegMonitor/constants'
import MontageChannelListItem from './MontageChannelListItem'
import { CHANNEL_SORTER } from './MontageEditor'

interface Props {
    montage: Montage
    selectedChannel: MontageChannel | null
    height: number
    onSelectChannel?: (channel: MontageChannel) => void
    onChange?: (montage: Montage) => void
    groupCnt: number
    onSetGroupCnt: React.Dispatch<React.SetStateAction<number>>
}

function MontageChannelListView(props: Props) {
    const [newChannelFormula, setNewChannelFormula] = useState('')
    const [draggingDivider, setDraggingDivider] = useState(0)
    const [draggingChannel, setDraggingChannel] = useState('')
    const listContainer = useRef<HTMLDivElement | null>(null)

    const handleChange = (channel: MontageChannel, formula: string) => {
        props.onChange?.({
            ...props.montage,
            Channels: props.montage.Channels.map(ch => {
                if (ch.ID === channel.ID) {
                    return { ...ch, Formula: formula }
                }
                return ch
            }),
        })
    }

    const handleDelete = (channel: MontageChannel) => {
        props.onChange?.({
            ...props.montage,
            Channels: props.montage.Channels.filter(ch => ch.ID !== channel.ID),
        })
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            if (listContainer.current) {
                listContainer.current.scrollTo({ top: 99999, behavior: 'smooth' })
            }
        }, 100)
    }

    const handleCreateChannel = () => {
        const violation = getChannelFormulaViolation(newChannelFormula)
        if (violation) {
            ToastUtils.error({ message: violation, duration: 2000 })
            return
        }
        setNewChannelFormula('')
        const lastCh = [...props.montage.Channels].pop()
        const channel = {
            ID: Math.floor(Math.random() * 1e9).toString(),
            Formula: normalizeChannelFormulaCase(newChannelFormula),
            Sort: lastCh ? lastCh.Sort + 1 : 0,
            Group: props.groupCnt,
            ChannelTypeID: EEG,
            MontageTemplateID: props.montage.ID,
        } as MontageChannel
        props.onChange?.({
            ...props.montage,
            Channels: [...props.montage.Channels, channel],
        })
        scrollToBottom()
    }

    const addDividerAfter = (chIndex: number) => {
        const chs = props.montage.Channels.map(ch => ({ ...ch }))
        if (chs[chIndex].Group <= draggingDivider) {
            let i = chIndex + 1
            while (chs[i] && chs[i].Group === draggingDivider) {
                chs[i].Group = draggingDivider + 1
                i++
            }
            if (i > chIndex + 1) {
                props.onChange?.({ ...props.montage, Channels: chs })
            }
        } else if (chs[chIndex].Group > draggingDivider) {
            let i = chIndex
            while (chs[i] && chs[i].Group === draggingDivider + 1) {
                chs[i].Group = draggingDivider
                i--
            }
            if (i < chIndex) {
                props.onChange?.({ ...props.montage, Channels: chs })
            }
        }
    }

    const addChannelAfter = (chIndex: number) => {
        const ch = { ...props.montage.Channels.find(ch => ch.ID === draggingChannel) as MontageChannel }
        ch.Sort = props.montage.Channels[chIndex].Sort + 1
        ch.Group = props.montage.Channels[chIndex].Group
        const precedingChs = props.montage.Channels
            .slice(0, chIndex + 1)
            .map(ch => ({ ...ch }))
            .filter(ch => ch.ID !== draggingChannel)
        let followingChs = props.montage.Channels.slice(chIndex + 1)
        followingChs = followingChs
            .map((ch, i) => ({
                ...ch,
                Sort: followingChs[i].Sort + 1,
            }))
            .filter(ch => ch.ID !== draggingChannel)
        const chs = [...precedingChs, ch, ...followingChs]
        props.onChange?.({ ...props.montage, Channels: chs })
    }

    const addChannelBefore = (chIndex: number) => {
        const ch = { ...props.montage.Channels.find(ch => ch.ID === draggingChannel) as MontageChannel }
        ch.Sort = props.montage.Channels[chIndex].Sort - 1
        ch.Group = props.montage.Channels[chIndex].Group
        const precedingChs = props.montage.Channels
            .slice(0, chIndex)
            .map(ch => ({ ...ch }))
            .filter(ch => ch.ID !== draggingChannel)
        let followingChs = props.montage.Channels.slice(chIndex)
        followingChs = followingChs
            .map((ch, i) => ({
                ...ch,
                Sort: followingChs[i].Sort + 1,
            }))
            .filter(ch => ch.ID !== draggingChannel)
        const chs = [...precedingChs, ch, ...followingChs]
        props.onChange?.({ ...props.montage, Channels: chs })
    }

    const handleDragOverChannel = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const listItem = e.currentTarget.closest('.list-item') as HTMLDivElement
        const parent = listItem.parentElement as HTMLDivElement
        let chIndex = props.montage.Channels.findIndex(c => c.ID === listItem.id)
        if (chIndex < 0) return
        let isDraggingBeforeCh = false
        if ((e.clientY + parent.scrollTop) < (listItem.offsetTop) + (listItem.offsetHeight / 2)) {
            chIndex--
            isDraggingBeforeCh = true
        }
        if (draggingDivider > 0) {
            if (chIndex < 0 && props.montage.Channels.length > 0) {
                props.onChange?.({
                    ...props.montage,
                    Channels: props.montage.Channels.map((ch, i) => ({
                        ...ch,
                        Group: i === 0 ? draggingDivider + 1 : ch.Group,
                    })),
                })
            } else {
                addDividerAfter(chIndex)
            }
        } else if (draggingChannel) {
            if (isDraggingBeforeCh) {
                addChannelBefore(chIndex + 1)
            } else {
                addChannelAfter(chIndex)
            }
        }
    }

    const handleDragOverDivider = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const divider = e.currentTarget.closest('.group') as HTMLDivElement
        let group = parseInt(divider.id.split('-')[1])
        const parent = divider.parentElement as HTMLDivElement
        if ((e.clientY + parent.scrollTop) > (divider.offsetTop) + (divider.offsetHeight / 2)) {
            group++
        }
        if (draggingChannel) {
            if (props.montage.Channels.find(ch => ch.ID === draggingChannel)?.Group === group) {
                return
            }
            props.onChange?.({
                ...props.montage,
                Channels: props.montage.Channels.map((ch, i) => ({
                    ...ch,
                    Group: ch.ID === draggingChannel ? group : ch.Group,
                })),
            })
        }
    }

    const handleAddGroupDivider = () => {
        props.onSetGroupCnt(cnt => cnt + 1)
        scrollToBottom()
    }

    const handleRemoveDivider = (group: number) => {
        const channels = props.montage.Channels.map(ch => ({
            ...ch,
            Group: ch.Group > group ? ch.Group - 1 : ch.Group,
        }))
        props.onChange?.({
            ...props.montage,
            Channels: channels,
        })
        props.onSetGroupCnt(cnt => cnt - 1)
    }

    const sortedChannels = props.montage.Channels.sort(CHANNEL_SORTER)

    // Render dividers in this component if there are no channels in the montage yet
    const dividers: number[] = []
    if (sortedChannels.length === 0) {
        for (let group = 1; group < props.groupCnt; group++) {
            dividers.push(group)
        }
    }

    return (
        <div className="montage-channel-list-view">
            <div className={`input-wrapper ${props.montage.Channels.length === 0 ? 'no-channels' : ''}`}>
                <input
                    className="channel-formula new-channel"
                    value={newChannelFormula}
                    onKeyDown={e => e.key === 'Enter' ? handleCreateChannel() : null}
                    onChange={e => setNewChannelFormula(e.target.value)}
                />
                <i
                    title="Create channel"
                    className="fas fa-plus action-icon always-show"
                    onClick={handleCreateChannel}
                />
                <i
                    title="Add new group"
                    className="fas fa-grip-horizontal new-divider"
                    onClick={handleAddGroupDivider}
                />
            </div>
            <div
                ref={listContainer}
                className="list-view"
                style={{ maxHeight: props.height - 34 }}
                onDrop={() => {
                    setDraggingDivider(0)
                    setDraggingChannel('')
                }}
            >
                {sortedChannels.length > 0 ? sortedChannels.map((channel, index) => (
                    <MontageChannelListItem
                        selected={channel.ID === (props.selectedChannel?.ID ?? 'none')}
                        channel={channel}
                        onChange={(formula: string) => handleChange(channel, formula)}
                        onDelete={() => handleDelete(channel)}
                        key={channel.ID + channel.Formula}
                        onClick={() => props.onSelectChannel?.(channel)}
                        afterDividerCnt={index === props.montage.Channels.length - 1 ? props.groupCnt - props.montage.Channels[index].Group : props.montage.Channels[index + 1].Group - props.montage.Channels[index].Group}
                        beforeDividerCnt={index === 0 ? props.montage.Channels[0].Group - 1 : 0}
                        onDragDividerStart={setDraggingDivider}
                        onDragChannelStart={setDraggingChannel}
                        onDragOverChannel={handleDragOverChannel}
                        onDragOverDivider={handleDragOverDivider}
                        onRemoveDivider={handleRemoveDivider}
                    />
                )) : dividers.map(group => (
                    <div
                        key={group}
                        draggable
                        className="group"
                        onDragOver={handleDragOverDivider}
                    >
                        <i
                            className="fas fa-grip-horizontal grab-handle"
                        />
                        <i
                            className="fa fa-trash-alt action-icon"
                            onClick={() => handleRemoveDivider(group)}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default MontageChannelListView
