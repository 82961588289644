import React from 'react'
import DevicesApi from '../../services/DevicesApi'
import DeviceForm from './DeviceForm'
import FacilitiesApi from '../../services/FacilitiesApi'
import Validation from '../../services/Validation'
import SessionManager from '../../services/SessionManager'
import NavUtils from '../../utils/NavUtils'
import { hasRequiredRole } from '../../services/Auth'
import {
    FACILITY_ADMIN_ROLE,
    PRODUCTION_ROLE,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
} from '../../constants/roles'
import ToastUtils from '../../utils/ToastUtils'
import AppContext from '../../components/AppContext'
import { Button } from '@mui/material'

const length_validation = {
    Name: {
        min: 1,
        max: 50,
    },
    Configuration: {
        min: 0,
        max: 50,
    },
    SerialNumber: {
        min: 1,
        max: 50,
    },
    PartNumber: {
        min: 0,
        max: 50,
    },
}

class Device extends React.Component {
    static contextType = AppContext

    state = {
        device: {},
        initial_device: {
            Name: '',
            PartNumber: '',
            Configuration: '',
            FacilityID: '',
            SerialNumber: '',
        },
        value_changed: {
            Name: false,
            PartNumber: false,
            Configuration: false,
            FacilityID: false,
            SerialNumber: false,
        },
        validation: {
            Name: true,
            PartNumber: true,
            Configuration: true,
            SerialNumber: true,
        },
        facilitiesList: [],
        is_saving: false,
        is_loading: true,
    }

    componentDidMount() {
        NavUtils.setPageTitle(this.getTitle())

        if (!this.props.creating) {
            DevicesApi.getDevice(this.props.match.params.id)
                .then(device => {
                    this.setDevice(device.data)
                })
                .catch(error => {
                    const isNotFound = error.status === 404
                    const isBadRequest = error.status === 400
                    if (isNotFound || isBadRequest) {
                        window.location.href = '/404'
                    }
                    ToastUtils.error({ message: error.data.Message || error.statusText })
                })
        } else {
            this.newDevice()
        }

        this.resetFacilityField()
    }

    getTitle = () => {
        if (this.props.creating) {
            return 'Create Device'
        }
        return 'Edit Device'
    }

    setDevice(device) {
        this.setState({
            device,
            initial_device: device,
            is_loading: false,
        })
    }

    handleChange = (field, value) => {
        if (typeof value === 'string') {
            value = value.replace(/^\s+/g, '')
        }
        const device = { ...this.state.device, [field]: value }
        this.setState({ device }, () => {
            this.hasDeviceChanged(field)
        })
    }

    hasDeviceChanged = field => {
        if (this.hasDeviceValueChanged(field)) {
            this.setState({
                value_changed: {
                    ...this.state.value_changed,
                    [field]: true,
                },
            })
        } else {
            this.setState({
                value_changed: {
                    ...this.state.value_changed,
                    [field]: false,
                },
            })
        }
    }

    hasDeviceValueChanged(field) {
        // if one field is null, then we can't call toString()
        if (
            Validation.isNullOrEmpty(this.state.initial_device[field]) ||
            Validation.isNullOrEmpty(this.state.device[field])
        ) {
            // if both are empty or null, then we didn't change
            if (
                Validation.isNullOrEmpty(this.state.initial_device[field]) &&
                Validation.isNullOrEmpty(this.state.device[field])
            )
                return false

            // otherwise, something changed
            return true
        }

        return (
            this.state.initial_device[field].toString().trim() !==
            this.state.device[field].toString().trim()
        )
    }

    handleSubmit = () => {
        const device = { ...this.state.device }

        if (device.Name) device.Name = device.Name.trim()

        if (device.PartNumber) device.PartNumber = device.PartNumber.trim()

        if (device.Configuration)
            device.Configuration = device.Configuration.trim()

        this.setState({
            device,
        })

        if (!this.props.creating) {
            this.updateDevice()
        } else {
            this.createDevice()
        }
    }

    newDevice = () => {
        this.setState({
            is_loading: false,
        })
    }

    isFormValid = () => {
        let valid = true
        let Name = true
        let SerialNumber = true
        let PartNumber = true
        let Configuration = true

        if (
            !this.state.device.PartNumber ||
            (this.state.device.PartNumber.length <
                length_validation.PartNumber.min ||
                this.state.device.PartNumber.length >
                    length_validation.PartNumber.max)
        ) {
            valid = false
            PartNumber = false
        }

        if (
            !this.state.device.Configuration ||
            (this.state.device.Configuration.length <
                length_validation.Configuration.min ||
                this.state.device.Configuration.length >
                    length_validation.Configuration.max)
        ) {
            valid = false
            Configuration = false
        }

        if (
            !this.state.device.Name ||
            this.state.device.Name.length < length_validation.Name.min ||
            this.state.device.Name.length > length_validation.Name.max
        ) {
            valid = false
            Name = false
        }

        if (
            !this.state.device.SerialNumber ||
            this.state.device.SerialNumber.length <
                length_validation.SerialNumber.min ||
            this.state.device.SerialNumber.length >
                length_validation.SerialNumber.max
        ) {
            valid = false
            SerialNumber = false
        }

        this.setState({
            validation: {
                ...this.state.validation,
                Name,
                Configuration,
                SerialNumber,
                PartNumber,
            },
        })

        return valid
    }

    createDevice() {
        if (this.isFormValid()) {
            this.setState({ is_saving: true })

            DevicesApi.createDevice(
                this.state.device.FacilityID,
                this.state.device,
            )
                .then(() => {
                    this.setState({ is_saving: false })
                    ToastUtils.success({ message: 'Device Created!' })
                })
                .then(() => {
                    this.resetState()
                    this.resetFacilityField()
                })
                .catch(error => {
                    this.setState({ is_saving: false })
                    ToastUtils.error({ message: error.data.Message || error.statusText })
                })
        }
    }

    updateDevice() {
        if (this.isFormValid()) {
            this.setState({ is_saving: true })

            DevicesApi.updateDevice(
                this.props.match.params.id,
                this.state.device,
            )
                .then(() => {
                    this.setState({
                        is_saving: false,
                        initial_device: this.state.device,
                        value_changed: {
                            Name: false,
                            PartNumber: false,
                            Configuration: false,
                            FacilityID: false,
                            SerialNumber: false,
                        },
                    })
                    ToastUtils.success({ message: 'Device saved!' })
                })
                .catch(error => {
                    this.setState({ is_saving: false })
                    ToastUtils.error({ message: error.data.Message || error.statusText })
                })
        }
    }

    resetFacilityField() {
        const currentFacility = SessionManager.get('mmt_facility_id')

        FacilitiesApi.getFacilities().then(response => {
            if (this.props.creating) {
                this.setState({
                    facilitiesList: response.data,
                    device: {
                        ...this.state.device,
                        FacilityID: currentFacility,
                    },
                })
            } else {
                this.setState({
                    facilitiesList: response.data,
                    device: {
                        ...this.state.device,
                        FacilityID: this.state.device.FacilityID,
                    },
                })
            }
        })
    }

    resetState = () => {
        const device = {
            Name: '',
            PartNumber: '',
            Configuration: '',
            FacilityID: '',
            SerialNumber: '',
        }

        const validation = {
            Name: true,
            PartNumber: true,
            Configuration: true,
            SerialNumber: true,
        }

        this.setState({
            ...this.state,
            device,
            validation,
            is_saving: false,
            value_changed: {
                Name: false,
                PartNumber: false,
                Configuration: false,
                FacilityID: false,
                SerialNumber: false,
            },
        })
    }

    canEdit = () =>
        hasRequiredRole(
            [
                SUPER_ADMIN_ROLE,
                SUPPORT_ROLE,
                PRODUCTION_ROLE,
                FACILITY_ADMIN_ROLE,
            ],
            this.context.Roles,
        )

    render() {
        const has_device_changed = Object.values(this.state.value_changed).some(
            element => element === true,
        )
        const can_edit = this.canEdit()

        return (
            <React.Fragment>
                {!this.state.is_loading && (
                    <div className="col-xl-5 col-lg-7 px-0">
                        <h3>
                            <i className="fa fa-laptop" /> {this.getTitle()}
                        </h3>
                        <div>
                            <DeviceForm
                                {...this.state.device}
                                validationRules={length_validation}
                                validation={this.state.validation}
                                facilitiesList={this.state.facilitiesList}
                                handleChange={this.handleChange}
                                creating={this.props.creating}
                                readOnly={!can_edit}
                            />
                            <Button
                                disabled={this.state.is_saving || !has_device_changed}
                                onClick={this.handleSubmit}
                                sx={{ float: 'right' }}
                            >
                                {this.state.is_saving ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default Device
