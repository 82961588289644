import React, { useEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import SearchPatient from '../../components/forms/SearchPatient'
import Patient from '../../types/Patient'
import NavUtils from '../../utils/NavUtils'

const ImportPatientSelect: React.FC = () => {
    useEffect(() => {
        NavUtils.setPageTitle('Import Study')
    }, [])

    return (
        <div>
            <Modal isOpen toggle={() => null} className="modal-lg change-patient-modal">
                <ModalHeader toggle={() => null}>
                    Select Patient
                </ModalHeader>
                <ModalBody>
                    <div className="container py-4">
                        <div className="align-self-center mx-2">
                            <p>Search and select a patient for the study import:</p>
                            <SearchPatient handlePatient={(patient: Patient) => {
                                window.open(`/Patient/${patient.ID}/ImportStudy`, '_blank')
                            }} />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outlined"
                        onClick={() => window.history.back()}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default ImportPatientSelect
