export function getRGBAFromHexARGB(hex: string): string {
    let r = 0
    let g = 0
    let b = 0
    let a = 1

    if (hex.charAt(0) === '#') {
        hex = hex.substr(1)
    }

    if (hex.length === 6) {
        r = parseInt(`0x${hex[0]}${hex[1]}`, 16)
        g = parseInt(`0x${hex[2]}${hex[3]}`, 16)
        b = parseInt(`0x${hex[4]}${hex[5]}`, 16)
        a = parseInt('0xFF', 16)
    } else if (hex.length === 8) {
        r = parseInt(`0x${hex[2]}${hex[3]}`, 16)
        g = parseInt(`0x${hex[4]}${hex[5]}`, 16)
        b = parseInt(`0x${hex[6]}${hex[7]}`, 16)
        a = parseInt(`0x${hex[0]}${hex[1]}`, 16)
    }

    a = +(a / 255).toFixed(3)

    return `rgba(${+r},${+g},${+b},${a})`
}
