import { Theme, CSSObject } from '@mui/material/styles'

function headerHeightMixin(theme: Theme): CSSObject {
    return {
        minHeight: '50px',
        '@media (min-width: 600px)': {
            minHeight: '50px',
        },
    }
}

export default headerHeightMixin
