import React from 'react'
import moment from 'moment-timezone'
import * as DateTimeFormats from '../../constants/datetimeformats'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'

const FacilityForm = props => {
    const LOCAL_STORAGE_OPTIONS = [
        { value: 0, name: 'Never' },
        { value: 5, name: 'After 5 days' },
        { value: 10, name: 'After 10 days' },
        { value: 15, name: 'After 15 days' },
        { value: 20, name: 'After 20 days' },
        { value: 30, name: 'After 30 days' },
        { value: 60, name: 'After 60 days' },
        { value: 90, name: 'After 90 days' },
    ]
    const facilityErrMsg = `A facility name is required that is ${props.validationRules.Name.max} characters or less`
    const domainErrMsg = `A web domain is required that is a maximum ${props.validationRules.Domain.max} characters and uses valid domain syntax rules (e.g. mysite.com)`

    if (!props.DisableAutoArchive) {
        let isValueValidOption = false
        for (let i = 0; i < LOCAL_STORAGE_OPTIONS.length; i += 1) {
            const option = LOCAL_STORAGE_OPTIONS[i]
            if (option.value === parseInt(props.LocalStorageDays, 10)) {
                isValueValidOption = true
                break
            }
        }
        if (!isValueValidOption) {
            LOCAL_STORAGE_OPTIONS.push({
                value: parseInt(props.LocalStorageDays, 10),
                name: `After ${props.LocalStorageDays} days`,
            })
            LOCAL_STORAGE_OPTIONS.sort((a, b) => {
                if (a.value < b.value) return -1
                if (a.value > b.value) return 1
                return 0
            })
        }
    }

    return (
        <>
            <TextField fullWidth
                required
                disabled={props.readOnly}
                error={!props.validation.Name}
                helperText={!props.validation.Name && facilityErrMsg }
                id="facilityForm_name"
                inputProps={{ maxLength: props.validationRules.Name.max }}
                label="Facility Name"
                name="Name"
                onChange={props.handleChange}
                placeholder="Facility Name"
                value={props.Name || ''}
            />

            <TextField
                required
                disabled={props.readOnly}
                error={!props.validation.Domain}
                fullWidth
                helperText={!props.validation.Domain && domainErrMsg }
                id="facilityForm_domain"
                inputProps={{ maxLength: 200 }}
                label="Domain"
                name="Domain"
                onChange={props.handleChange}
                placeholder="Facility web domain"
                value={props.Domain || ''}
            />

            {!props.creating && (
                <>
                    <FormControl fullWidth className="selector-parent">
                        <InputLabel id="demo-simple-select-label">Clear Local Studies</InputLabel>
                        <Select
                            disabled={props.readOnly}
                            id="facilityForm_localStorageDays"
                            label="Clear Local Studies"
                            name="LocalStorageDays"
                            onChange={props.handleChange}
                            options={LOCAL_STORAGE_OPTIONS}
                            value={props.LocalStorageDays || ''}
                        >
                            <MenuItem value={0}>Never</MenuItem>
                            <MenuItem value={5}>After 5 days</MenuItem>
                            <MenuItem value={10}>After 10 days</MenuItem>
                            <MenuItem value={15}>After 15 days</MenuItem>
                            <MenuItem value={20}>After 20 days</MenuItem>
                            <MenuItem value={30}>After 30 days</MenuItem>
                            <MenuItem value={60}>After 60 days</MenuItem>
                            <MenuItem value={90}>After 90 days</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField
                        fullWidth
                        disabled={props.readOnly}
                        id="studyNotesTemplate"
                        inputProps={{ maxLength: 2000 }}
                        label="Study Notes Template"
                        multiline
                        name="StudyNotesTemplate"
                        onChange={props.handleChange}
                        rows={5}
                        value={props.StudyNotesTemplate || ''}
                    />

                    <TextField
                        fullWidth
                        disabled
                        id="dateCreated"
                        label="Date Created"
                        name="DateCreated"
                        value={moment
                            .utc(props.DateCreated)
                            .local()
                            .format(
                                DateTimeFormats.FACILITIES_LIST_DATE_CREATED_FORMAT,
                            )}
                    />
                </>
            )}
        </>
    )
}

export default FacilityForm
