import { useState, useEffect, useCallback } from 'react'
import StudiesApi from '../../services/StudiesApi'
import * as SyncStates from '../../constants/syncstates'
import ToastUtils from '../../utils/ToastUtils'
import usePreventAutoLogout from '../../hooks/usePreventAutoLogout'
import NeutralButton from '../../components/NeutralButton'
import { LinearProgress } from '@mui/material'
import DateTimeUtils from '../../utils/DateTimeUtils'

const INTERVAL = 10000
const INTERVAL_ELAPSED_TIME = 1000

const progressPercentToColor = percent => {
    if (percent === 100) {
        return 'success'
    }
    return 'info'
}

const UsePreventAutoLogout = usePreventAutoLogout

const ImportProcessingMonitor = props => {
    const [syncState, setSyncState] = useState(SyncStates.IMPORTING)
    const [syncProgress, setSyncProgress] = useState({ data: 0, video: 0 })
    const [didPollFail, setDidPollFail] = useState(false)
    const [elapsedTime, setElapsedTime] = useState(0)

    UsePreventAutoLogout()
    const updateImportStatus = useCallback(() => {
        StudiesApi.getStudySyncInfo(props.study.ID)
            .then(res => {
                setDidPollFail(false)
                let corruptVideoCnt = 0
                switch (res.data.SyncState) {
                    case SyncStates.FAILED:
                        setSyncState(SyncStates.FAILED)
                        props.syncStatusUpdate(SyncStates.FAILED)
                        break
                    case SyncStates.SYNCED:
                        for (const studyRecordingVideos of res.data.StudyRecordingVideos) {
                            if (studyRecordingVideos.SyncState === SyncStates.CORRUPT_DATA) {
                                corruptVideoCnt += 1
                            }
                        }
                        if (corruptVideoCnt > 0) {
                            ToastUtils.warning({
                                message:
                                    `Your study is available for review, but ${corruptVideoCnt} of ${res.data.StudyRecordingVideos.length
                                    } video file${res.data.StudyRecordingVideos.length === 1
                                        ? ''
                                        : 's'
                                    } ${corruptVideoCnt === 1 ? 'was' : 'were'
                                    } found to be corrupted and will not be available`,
                            })
                        }
                        props.syncStatusUpdate(SyncStates.SYNCED)
                        break
                    case SyncStates.UNSYNCED:
                        setSyncProgress({
                            data: res.data.StudyDataSyncPercent,
                            video: res.data.VideoDataSyncPercent,
                        })
                        if (props.study.UseVideo && props.shouldDisplayDataProgress && res.data.StudyDataSyncPercent === 100) {
                            props.syncStatusUpdate(SyncStates.SYNCED)
                            break
                        }
                        break
                    default:
                        throw Error(`unrecognized sync state ${res.data.SyncState}`)
                }
            })
            .catch(err => {
                console.log(err)
                setDidPollFail(true)
            })
    }, [props])

    useEffect(() => {
        const interval = setInterval(updateImportStatus, INTERVAL)
        return () => clearInterval(interval)
    }, [updateImportStatus])
    
    const updateElapsedTime = useCallback(() => setElapsedTime((Date.now() - props.step.start_time)/1000),[props])

    useEffect(() => {
        const elapsedTime = setInterval(updateElapsedTime, INTERVAL_ELAPSED_TIME)
        return () => clearInterval(elapsedTime)
    }, [updateElapsedTime])

    if (syncState === SyncStates.FAILED) {
        return (
            <div>
                <p>Import failed.</p>
                <p>Contact support if this keeps happening.</p>
                <NeutralButton onClick={props.onBack}>
                    Back
                </NeutralButton>
            </div>
        )
    }

    return (
        <div className="progress-container">
            <br/>
            <p style={{ textAlign: 'center' }}>
                {props.shouldDisplayVideoProgress ? (
                    'The study data was successfully processed. The videos are now being processed.'
                ) : (
                    'Study was successfully uploaded. The study data is now being processed.'
                )}
            </p>
            <br/>
            {props.shouldDisplayDataProgress && (
                <>
                    <span className="import-elapsed">
                        <span className="label">Data import:</span>
                        <span className="data">
                            {syncProgress.data > 0
                                ? `${syncProgress.data}%`
                                : 'Waiting...'}
                        </span>
                        <span style={{ float: 'right' }}>
                            <span className="label">Elapsed Time:</span>
                            <span className="data">{DateTimeUtils.getFriendlyDuration(elapsedTime)}</span>
                        </span>
                    </span>
                    <LinearProgress
                        value={syncProgress.data}
                        variant={syncProgress.data > 0 ? 'determinate' : 'indeterminate'}
                        color={
                            didPollFail && syncProgress.data < 100
                                ? 'warning'
                                : progressPercentToColor(syncProgress.data)
                        }
                    />
                </>
            )}
            {props.shouldDisplayVideoProgress && (
                <>
                    <span className="import-elapsed">
                        <span className="label">Video progress:</span>
                        <span className="data">
                            {syncProgress.video > 0
                                ? `${syncProgress.video}%`
                                : 'Waiting...'}
                        </span>

                        <span style={{ float: 'right' }}>
                            <span className="label">Elapsed Time:</span>
                            <span className="data">{DateTimeUtils.getFriendlyDuration(elapsedTime)}</span>
                        </span>
                    </span>
                    <LinearProgress
                        value={syncProgress.video}
                        variant={syncProgress.data > 0 ? 'determinate' : 'indeterminate'}
                        color={
                            didPollFail && syncProgress.data >= 100
                                ? 'warning'
                                : progressPercentToColor(
                                    syncProgress.video,
                                )
                        }
                    />
                </>
            )}
        </div>
    )
}

export default ImportProcessingMonitor
