import { useState } from 'react'
import { Button } from '@mui/material'
import ShareStudyModal from '../../components/modals/ShareStudyModal'
import NeutralButton from '../../components/NeutralButton'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { TableContainer, Table, TableBody, TableCell, TableRow } from '@mui/material'

const ImportStudySummary = props => {
    const [showShareModal, setShowShareModal] = useState(false)
    const fileUploadTime = (props.steps[2].end_time - props.steps[2].start_time)/1000
    const processDataTime = (props.steps[3].end_time - props.steps[3].start_time)/1000
    const processVideoTime = !!props.steps[4].start_time ? (props.steps[4].end_time - props.steps[4].start_time)/1000 : 0
    const totalTime = fileUploadTime + processDataTime + processVideoTime

    return (
        <div>
            {showShareModal && (
                <ShareStudyModal
                    study={props.study}
                    toggle={() => setShowShareModal(!showShareModal)}
                />
            )}
            <p>Study has successfully been imported. You may close this tab.</p>
            <br/>
            <TableContainer align="center" >
                <i>How long did it take?</i>&nbsp;&nbsp;Here's a breakdown:
                <br/><br/>
                <Table sx={{ maxWidth: 300 }} size="small" className="import-elapsed">
                    <TableBody>
                        <TableRow>
                            <TableCell className="label" align="right">Upload Time:</TableCell>
                            <TableCell className="data">{DateTimeUtils.getFriendlyDuration(fileUploadTime)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" align="right">Data Process Time:</TableCell>
                            <TableCell className="data">{DateTimeUtils.getFriendlyDuration(processDataTime)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" align="right">Video Process Time:</TableCell>
                            <TableCell className="data">{DateTimeUtils.getFriendlyDuration(processVideoTime)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="label" align="right">Total:</TableCell> 
                            <TableCell className="data" sx={{ fontWeight: 800 }}>{DateTimeUtils.getFriendlyDuration(totalTime)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <br/>
            <NeutralButton
                onClick={() => window.close()}
                sx={{ mr: 2 }}
            >
                Close
            </NeutralButton>
            <Button onClick={() => setShowShareModal(true)}>
                Share
            </Button>
        </div>
    )
}

export default ImportStudySummary
