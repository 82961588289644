import { tooltipClasses, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'

function useStyles() {
    const theme = useTheme()

    return makeStyles({
        popper: {
            [`& .${tooltipClasses.tooltip}`]: {
                background: theme.palette.background.paper,
                padding: theme.spacing(1, 2),
                color: '#fff',
                maxWidth: 220,
            },
        },
        arrow: {
            color: theme.palette.background.paper,
        },
    })()
}

export default useStyles
