import React, { useState, useEffect, useRef, useContext } from 'react'
import './FacilityDropdown.scss'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from 'reactstrap'
import SessionManager from '../../../services/SessionManager'
import UsersApi from '../../../services/UsersApi'
import AppContext from '../../AppContext'
import { truncate } from '../../../utils/StringUtils'
import { Action } from '../../AppState'
import ToastUtils from '../../../utils/ToastUtils'
import { Auth } from '../../../services/Auth'

const FacilityDropdown = () => {

    const DROPDOWN_ENABLED_ROUTES = [
        '/dashboard',
        '/studies',
        '/patients',
        '/facilities',
        '/amplifiers',
        '/devices',
        '/users',
        '/help',
        '/cameras',
        '/montages',
    ]
    const FACILITY_NAME_MAX_LENGTH = 32
    const NO_SELECTED_FACILITY_PLACEHOLDER = 'Select a facility'

    const appContext = useContext(AppContext)
    const selectedFacilityElement = useRef<HTMLDivElement>(null)
    const [is_open, setIsOpen] = useState(false)
    const selectedFacility = SessionManager.get('mmt_facility_id')

    const scrollToFacility = () => {
        selectedFacilityElement?.current?.scrollIntoView({ block: 'center' })
    }

    useEffect(() => { scrollToFacility() }, [is_open])

    const getSelectedFacilityName = (id: string) => {
        for (const facility of appContext.Facilities) {
            if (facility.ID === id) return facility.Name
        }
        return ''
    }

    const selectedFacilityName = truncate(
        getSelectedFacilityName(selectedFacility),
        FACILITY_NAME_MAX_LENGTH,
    )

    const hideFacilityDropDown = !DROPDOWN_ENABLED_ROUTES.includes(window.location.pathname.toLowerCase())

    if (hideFacilityDropDown) {
        return (
            <span className="facility-dropdown d-none d-sm-block name-only">
                {selectedFacilityName || (appContext.Facilities.length > 0 && NO_SELECTED_FACILITY_PLACEHOLDER)}
            </span>
        )
    }

    const changeSelectedFacility = (id: string, reload = true) => {
        if (reload && id === selectedFacility) return
        UsersApi.setSelectedFacility(id)
            .then(res => {
                SessionManager.set(
                    'mmt_facility_id',
                    res.data.SelectedFacilityID,
                    0,
                )
                SessionManager.set(
                    'mmt_facility_name',
                    res.data.SelectedFacilityName,
                    0,
                )
                if (reload) {
                    const uri = window.location.toString()
                    if (uri.indexOf('#') > 0) {
                        const clean_uri = uri.substring(0, uri.indexOf('#'))
                        window.history.replaceState({}, document.title, clean_uri)
                    } 
                    window.location.hash = ''
                    window.location.reload()
                    
                }
            })
            .catch(err => {
                if (err && err.status && err.status >= 400 && err.status < 500) {
                    appContext.commit(Action.ResetFacilities)
                    if (id === selectedFacility && Auth.loggedIn) {
                        SessionManager.set(
                            'system_msg',
                            `You have been removed from the facility ${getSelectedFacilityName(
                                id,
                            )}.`,
                        )
                        SessionManager.remove('mmt_facility_id')
                        SessionManager.remove('mmt_facility_name')
                        window.location.href = '/Dashboard'
                    } else {
                        ToastUtils.error({
                            message: 'You have been removed from that facility',
                        })
                    }
                } else {
                    ToastUtils.error({ message: 'Unable to set selected facility' })
                }
            })
    }

    const dropDownItem = (facilityName: string, isSelected: boolean) => {
        const facName = truncate(facilityName, FACILITY_NAME_MAX_LENGTH)
        if (isSelected)
            return (<div ref={selectedFacilityElement} className="small mb-1 dropdown-item-checked">{facName}</div>)
        else
            return (<div className="small mb-1">{facName}</div>)
    }

    const showFacilityDropDown = appContext.Facilities.length > 1 ||
        (appContext.Facilities.length > 0 && !selectedFacilityName)

    return (
        <span className="facility-dropdown d-none d-sm-block">
            {showFacilityDropDown && (
                <Dropdown isOpen={is_open} toggle={() => setIsOpen(!is_open)} style={{ height: 50, display: 'inline-block' }}>
                    <DropdownToggle nav style={{ height: 50 }}>
                        <span className={`selected-facility-name ${!selectedFacilityName && 'gray'}`}>
                            {selectedFacilityName ||
                                NO_SELECTED_FACILITY_PLACEHOLDER}
                        </span>
                        <i className="fa fa-sort-down" />
                    </DropdownToggle>
                    <DropdownMenu className="scrollable-menu">
                        <DropdownItem
                            header
                            tag="div"
                            className="text-center"
                        >
                            <strong>Facilities</strong>
                        </DropdownItem>
                        {appContext.Facilities.map(facility => {
                            const isSelected = facility.ID === selectedFacility
                            return (
                                <DropdownItem
                                    key={facility.ID}
                                    onClick={() => changeSelectedFacility(facility.ID)}
                                >
                                    {dropDownItem(facility.Name, isSelected)}
                                </DropdownItem>
                            )
                        })}
                    </DropdownMenu>
                </Dropdown>
            )
            }
            {!showFacilityDropDown && (appContext.Facilities.length > 0) && <span> {selectedFacilityName} </span>}
        </span >
    )
}


export default FacilityDropdown
