import { ListItemButton, Tooltip, Typography } from '@mui/material'
import { NavLink, useHistory } from 'react-router-dom'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import useStyles from './MenuLink.styles'
import { CSSProperties } from 'react'

interface MenuLinkProps {
    path?: string
    label: string
    leftIcon?: any
    rightIcon?: any
    onClick: () => void
    isDrawerOpen: boolean
    newTab?: boolean
    labelStyle?: CSSProperties
    buttonStyle?: CSSProperties
}

function MenuLink(props: MenuLinkProps) {
    const history = useHistory()
    const classes = useStyles()

    const icon = (
        <ListItemIcon sx={{ minWidth: 42 }}>
            <span
                style={{
                    margin: 'auto',
                    marginLeft: '3px',
                    position: 'relative',
                    top: 2,
                    fontSize: '20px',
                }}
            >
                {props.leftIcon}
            </span>
        </ListItemIcon>
    )

    const handleClick = () => {
        if (props.newTab && props.path) {
            window.open(props.path)
        }
        props.onClick()
    }

    return (
        <ListItemButton
            style={props.buttonStyle}
            component={(props.path && !props.newTab) ? NavLink : 'div'}
            to={props.newTab ? undefined : props.path}
            onClick={handleClick}
            selected={history.location.pathname === props.path}
        >
            {props.leftIcon && (props.isDrawerOpen ? icon : (
                <Tooltip
                    classes={{ popper: classes.popper, arrow: classes.arrow }}
                    title={<Typography variant="body2">{props.label}</Typography>}
                    placement="right"
                    arrow
                >
                    {icon}
                </Tooltip>
            ))}
            <ListItemText
                primary={<span style={props.labelStyle}>{props.label}</span>}
            />
            {props.rightIcon}
        </ListItemButton>
    )
}

export default MenuLink
