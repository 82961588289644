// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

import FilteredDataMinute from './FilteredDataMinute'

class DataResponse {
    constructor(
        requestId,
        startMinute,
        endMinute,
        montageTemplateId,
        filterSettings,
    ) {
        this.requestId = requestId
        this.startMinute = startMinute
        this.endMinute = endMinute
        this.totalMinutes = Math.abs(endMinute - startMinute)
        this.responseComplete = false
        this.filteredDataMinutes = {}
        this.minutesReceived = {}
        this.montageTemplateId = montageTemplateId
        this.filterSettings = filterSettings
    }

    // Data is given to this response.  If the data given completes the response, then it will be marked as completed.
    // Please don't send it bogus data :-|
    // NOTE: Parts start at 1 and go to the total parts.  So 3 parts will have parts 1, 2, 3
    addData(
        minute,
        partOfMinute,
        totalMinuteParts,
        finalRecordingIndexOfMinute,
        finalRecordingPacketIndexOfMinute,
        studySamples,
    ) {
        // if this is an empty minute...
        if (studySamples === null) {
            this.minutesReceived[minute] = true

            if (
                Object.keys(this.minutesReceived).length === this.totalMinutes
            ) {
                console.log(
                    `[GDM] request #${this.requestId} [min:${
                        this.totalMinutes
                    }] complete`,
                )
                this.responseComplete = true
            }

            return
        }

        // otherwise, process it
        if (this.filteredDataMinutes[minute] === undefined) {
            this.filteredDataMinutes[minute] = new FilteredDataMinute(
                minute,
                this.montageTemplateId,
                this.filterSettings,
            )
            this.filteredDataMinutes[minute].setLastStudyPacketIndex(
                finalRecordingIndexOfMinute,
                finalRecordingPacketIndexOfMinute,
            )
        }

        this.filteredDataMinutes[minute].addStudySamples(studySamples)

        if (partOfMinute === totalMinuteParts) {
            this.minutesReceived[minute] = true
        }

        // we must have all expected minutes totally received
        if (Object.keys(this.minutesReceived).length === this.totalMinutes) {
            console.log(
                `[GDM] request #${this.requestId} [min:${
                    this.totalMinutes
                }] complete`,
            )
            this.responseComplete = true
        }

        this.startMinute = this.getStartMinute()
        this.endMinute = this.getEndMinute()
    }

    // Before a request is made, a response is created.  Whole minutes in the cache are added to the
    // response.  If the response has all of the minutes in it that were requested, then
    // responseComplete needs to be marked true externally.
    addMinute(filteredDataMinute) {
        this.filteredDataMinutes[filteredDataMinute.minute] = filteredDataMinute
        this.startMinute = this.getStartMinute()
        this.endMinute = this.getEndMinute()
    }

    getStartMinute() {
        let start = 1000000

        for (const key in this.filteredDataMinutes) {
            if (this.filteredDataMinutes[key].minute < start) {
                start = this.filteredDataMinutes[key].minute
            }
        }

        return start
    }

    getEndMinute() {
        let end = -1

        for (const key in this.filteredDataMinutes) {
            if (this.filteredDataMinutes[key].minute > end) {
                end = this.filteredDataMinutes[key].minute
            }
        }

        return end
    }
}

export default DataResponse
