import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import './ModalBool.scss'

interface Props {
    isOpen: boolean
    title: string
    message?: string
    trueButtonText: string
    falseButtonText: string
    loading?: boolean
    handleTrue: () => void
    handleFalse: () => void
    toggle: () => void
}

const ModalBool: React.FC<Props> = props => (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
            <div className="container">
                <div
                    style={{ display: 'block' }}
                    className="row form-group pl-2 pr-2"
                >
                    {props.children ? props.children : <div className="modal-bool msg">{props.message}</div>}
                </div>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button
                variant="outlined"
                onClick={props.handleFalse}
                disabled={props.loading}
                sx={{ mr: 2 }}
            >
                {props.falseButtonText}
            </Button>
            <Button
                onClick={props.handleTrue}
                disabled={props.loading}
            >
                {props.trueButtonText}
            </Button>
        </ModalFooter>
    </Modal>
)

export default ModalBool
