import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    FACILITY_ADMIN_ROLE,
    FIELD_TECH_ROLE,
    LEAD_TECH_ROLE,
    REVIEW_DOCTOR_ROLE,
    PRODUCTION_ROLE,
} from '../constants/roles'

const MONTAGE_BASE_AUTH = [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, FIELD_TECH_ROLE, LEAD_TECH_ROLE, REVIEW_DOCTOR_ROLE, PRODUCTION_ROLE]

const MontageAuth = {
    List: MONTAGE_BASE_AUTH,
    View: MONTAGE_BASE_AUTH,
    Create: MONTAGE_BASE_AUTH,
    Edit: MONTAGE_BASE_AUTH,
    Delete: MONTAGE_BASE_AUTH,
}

export default MontageAuth
