const CUSTOM_ELECTRODE_GUESSES = [
    { pattern: /eeg/i, name: 'EEG' },
    { pattern: /ekg|ecg/i, name: 'EKG' },
    { pattern: /emg|plm|leg|tib|tremor/i, name: 'EMG' },
    { pattern: /chin/i, name: 'Chin' },
    { pattern: /snore/i, name: 'Snore' },
    { pattern: /nasal/i, name: 'Nasal Pressure' },
    { pattern: /eog|eye|loc|roc|^e1-|^e2-|lue|rue|lle|rle/i, name: 'EOG' },
    { pattern: /tmp|temp|therm/i, name: 'Thermister' },
    { pattern: /rsp|resp/i, name: 'Respiratory' },
    { pattern: /photic/i, name: 'Photic' },
    { pattern: /nonin|sp02|sa02|heartrate|pulse|hr/i, name: 'Nonin' },
    { pattern: /sample|counter/i, name: 'Sample Counter' },
    { pattern: /event/i, name: 'Event' },
]

const DEFAULT_GUESS = 1

class ChannelTypeGuesser {
    constructor(supportedTypes) {
        this.supportedTypes = supportedTypes
    }

    guessID = name => {
        for (const guess of CUSTOM_ELECTRODE_GUESSES) {
            if (guess.pattern.test(name)) {
                for (const supportedType of this.supportedTypes) {
                    if (supportedType.Name === guess.name) {
                        return supportedType.ID
                    }
                }
            }
        }
        return DEFAULT_GUESS
    }
}

export default ChannelTypeGuesser
