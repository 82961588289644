import { useState, useEffect } from 'react'
import './QuickNotesDropdown.scss'
import { QUICK_NOTES } from '../../../constants/studyevents'
import QuickNote from './QuickNote'
import useKeypress from '../../../hooks/useOnKeypress'

const QuickNotesDropdown = props => {
    const countQuickNotes = QUICK_NOTES.length - 1
    let [selectedNoteIndex, setSelectedNoteIndex] = useState(0)

    const handleArrowKeyDown = () => {
        selectedNoteIndex += 1
        if (selectedNoteIndex > countQuickNotes)
            selectedNoteIndex = 0
        setSelectedNoteIndex(selectedNoteIndex)
    }

    const handleArrowKeyUp = () => {
        selectedNoteIndex -= 1
        if (selectedNoteIndex < 0)
            selectedNoteIndex = countQuickNotes
        setSelectedNoteIndex(selectedNoteIndex)
    }

    const handleEnterKey = () =>
        props.handleCreateQuickNote(QUICK_NOTES[selectedNoteIndex])

    useKeypress('ArrowDown', handleArrowKeyDown)
    useKeypress('ArrowUp', handleArrowKeyUp)
    useKeypress('Enter', handleEnterKey)

    useEffect(() => {
        function onKeypress(e) {
            const searchNote = QUICK_NOTES.find(qn =>
                e.key?.toLowerCase() === qn.charAt(0).toLowerCase() &&
                qn !== QUICK_NOTES[selectedNoteIndex]
            )
            const noteIndex = QUICK_NOTES.findIndex(n => n === searchNote)
            if (!!searchNote) {
                setSelectedNoteIndex(noteIndex)
            }
        }
        window.addEventListener('keypress', onKeypress)
        return () => window.removeEventListener('keypress', onKeypress)
    }, [selectedNoteIndex])

    return (
        props.isOpen ? (
            <div className="quick-notes-dropdown">
                {QUICK_NOTES.map((quick_note, index) => (
                    <QuickNote
                        key={quick_note}
                        quickNote={quick_note}
                        handleClick={() => props.handleCreateQuickNote(quick_note)}
                        selected={selectedNoteIndex === index}
                    />
                ))}
            </div>
        ) : null
    )
}
export default QuickNotesDropdown
