import AWS from 'aws-sdk'
import StudiesApi from '../../services/StudiesApi'
import ToastUtils from '../../utils/ToastUtils'

class Credentials extends AWS.Credentials {
    studyId = null

    async refresh(cb) {
        console.log('refreshing aws creds')
        let res
        try {
            res = await StudiesApi.awsBucketForStudy(this.studyId)
        } catch (err) {
            console.log(err)
            ToastUtils.error({ message: 'Unable to refresh upload credentials' })
            cb(err)
            return
        }
        this.sessionToken = res.data.SessionToken
        this.accessKeyId = res.data.AccessKeyId
        this.secretAccessKey = res.data.SecretAccessKey
        cb()
    }
}

export default Credentials
