import React from 'react'
import AppContext, { Ctx, defaultCtx } from './AppContext'
import FacilitiesApi from '../services/FacilitiesApi'
import { Auth } from '../services/Auth'
import SessionManager from '../services/SessionManager'
import {
    getLastActivity,
    CHECK_INTERVAL,
    MINUTES_UNTIL_AUTO_LOGOUT,
    MINUTES_UNTIL_WARNING,
} from '../services/Inactivity'
import ToastUtils from '../utils/ToastUtils'

export enum Action {
    SetAreGraphControlsMinimized,
    SetStudy,
    UpdateStudy,
    SetPatient,
    SetUserRoles,
    ResetFacilities,
    StartTimer,
    StopTimer,
    UpdateStudyEvent,
    DeleteStudyEvent,
    AddStudyEvent,
}

class AppState extends React.Component<React.PropsWithChildren<{}>, Ctx> {
    constructor(props: React.PropsWithChildren<{}>) {
        super(props)
        this.state = {
            ...defaultCtx(),
            commit: this.commit,
        }

        document.body.addEventListener('click', () => this.resetActivity())
        document.body.addEventListener('keypress', () => this.resetActivity())

        this.inactivityTimer = null
        this.inactivityToastId = null
    }

    private inactivityTimer: NodeJS.Timeout | null
    private inactivityToastId: number | null

    commit = (type: Action, payload?: any): void | Promise<void> | null => {
        switch (type) {
            case Action.SetStudy: {
                return this.setState(state => ({
                    ...state,
                    Study: payload,
                }))
            }

            case Action.SetAreGraphControlsMinimized: {
                return this.setState(state => ({
                    ...state,
                    AreGraphControlsMinimized: payload,
                }))
            }

            case Action.UpdateStudy: {
                return this.setState(state => ({
                    ...state,
                    Study: {
                        ...state.Study,
                        ...payload,
                    },
                }))
            }

            case Action.SetPatient: {
                console.log(payload)
                return this.setState(state => ({
                    ...state,
                    Study: {
                        ...state.Study,
                        FirstName: payload.FirstName,
                        MiddleName: payload.MiddleName,
                        LastName: payload.LastName,
                        Patient: payload,
                    },
                }))
            }

            case Action.UpdateStudyEvent: {
                const StudyEvents = this.state.Study.StudyEvents
                for (let i = StudyEvents.length - 1; i >= 0; i -= 1) {
                    if (StudyEvents[i].ID === payload.ID) {
                        StudyEvents[i] = payload
                        break
                    }
                }

                return new Promise(resolve => {
                    this.setState(state => ({
                        ...state,
                        Study: {
                            ...state.Study,
                            StudyEvents,
                        },
                    }), resolve)
                })
            }

            case Action.AddStudyEvent: {
                return this.setState(state => ({
                    ...state,
                    Study: {
                        ...state.Study,
                        StudyEvents: [...state.Study.StudyEvents, payload],
                    },
                }))
            }

            case Action.DeleteStudyEvent: {
                const StudyEvents = this.state.Study.StudyEvents
                for (let i = StudyEvents.length - 1; i >= 0; i -= 1) {
                    if (StudyEvents[i].ID === payload.ID) {
                        const ev = StudyEvents.pop()
                        if (ev && i < StudyEvents.length - 1) {
                            StudyEvents[i] = ev
                            break
                        }
                    }
                }

                return this.setState(state => ({
                    ...state,
                    Study: {
                        ...state.Study,
                        StudyEvents,
                    },
                }))
            }

            case Action.SetUserRoles: {
                return new Promise<void>(resolve =>
                    this.setState(
                        state => ({
                            ...state,
                            Roles: payload,
                        }),
                        resolve,
                    ),
                )
            }

            case Action.ResetFacilities: {
                return FacilitiesApi.getFacilities()
                    .then(res =>
                        this.setState(state => ({
                            ...state,
                            Facilities: res.data,
                        })),
                    )
                    .catch(err =>
                        console.log(
                            '[ERROR] FacilitiesApi.getFacilities()',
                            err,
                        ),
                    )
            }

            case Action.StartTimer: {
                this.startTimer()
                return null
            }

            case Action.StopTimer: {
                return this.stopTimer()
            }
        }
    }

    componentDidMount() {
        this.checkActivity()
        this.resetActivity()
        if (Auth.loggedIn()) this.commit(Action.ResetFacilities)
    }

    startTimer = () => {
        this.inactivityTimer = setInterval(() => {
            this.checkActivity()
        }, CHECK_INTERVAL)
    }

    stopTimer = () => {
        if (this.inactivityTimer) {
            clearInterval(this.inactivityTimer)
        }
    }

    resetActivity = () => {
        if (this.inactivityToastId !== null) {
            ToastUtils.close(this.inactivityToastId)
            ToastUtils.success({ message: 'Welcome back!' })
        }
        SessionManager.set('activity', Date.now())
        this.inactivityToastId = null
    }

    checkActivity = () => {
        if (!Auth.loggedIn())
            return

        const now = Date.now()
        if (SessionManager.get('import_in_progress')) {
            SessionManager.set('activity', now)
            return
        }
        const warning =
            parseInt(getLastActivity(), 10) + MINUTES_UNTIL_WARNING * 60 * 1000
        const logout =
            parseInt(getLastActivity(), 10) +
            MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000

        if (now > warning && now <= warning + CHECK_INTERVAL) {
            this.inactivityToastId = ToastUtils.warning({
                message: 'Inactivity warning, you will soon be logged out.',
            })
        }

        if (now > logout) {
            Auth.logout()
        }
    }

    render = () => (
        <AppContext.Provider value={this.state}>
            {this.props.children}
        </AppContext.Provider>
    )
}

export default AppState
