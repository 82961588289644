import { createTheme, darken, alpha } from '@mui/material'
import Button from './theme/Button'
import IconButton from './theme/IconButton'
import * as ThemeVars from './theme/LifeLinesVariables'

function makeTheme() {
    return createTheme({
        palette: {
            background: {
                default: darken(ThemeVars.rendrWave0, 0.4),
                paper: ThemeVars.rendrWave0,
            },
            primary: { main: ThemeVars.rendrWave2 },
            secondary: { main: ThemeVars.rendrWave1 },
            mode: 'dark',
        },
        components: {
            MuiAlert: {
                styleOverrides: {
                    action: {
                        paddingTop: 3,
                    },
                    root: {
                        marginTop: 15,
                        marginBottom: 15,
                    },
                    standardWarning: {
                        color: '#5f2120',
                        backgroundColor: '#fff4e5',
                    },
                    standardInfo: {
                        color: '#014361',
                        backgroundColor: '#e5f6fd',
                    },
                    standardError: {
                        color: '#550000',
                        backgroundColor: '#fdeded',
                    },
                    standardSuccess: {
                        color: '#1e4620',
                        backgroundColor: '#edf7ed',
                    },
                },
            },
            MuiButton: Button(),
            MuiIconButton: IconButton(),
            MuiChip: {
                styleOverrides: {
                    sizeSmall: {
                        lineHeight: 1.5,
                    },
                    colorPrimary: {
                        color: '#fff',  
                    },
                },
            },
            MuiCard : {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(35,35,35,0.1)',
                        backgroundImage: 'none',
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        color: ThemeVars.rendrWave2,
                        backgroundColor: 'black',
                        borderRadius: 0,

                        '&:hover': {
                            color: ThemeVars.rendrWave3,
                        },

                        '&.Mui-selected': {
                            backgroundColor: ThemeVars.rendrWave2,
                            
                            '&:hover': {
                                backgroundColor: ThemeVars.rendrWave3,
                                color: 'white',
                            },
                        },
                    },
                },
            },
            MuiListItemButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            color: '#fff',
                        },
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        borderRight: 'none',
                    },
                },
            },
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        borderTopLeftRadius: ThemeVars.tablesBorderRadius,
                        borderTopRightRadius: ThemeVars.tablesBorderRadius,
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            background: alpha(ThemeVars.rendrWave3, 0.3),
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: 'none',
                    },
                    head: {
                        backgroundColor: ThemeVars.tablesBackground,
                        color: '#fff',
                        padding: 12,
                    },
                    body: {
                        padding: 10,
                        borderTopWidth: 2,
                        borderTopStyle: 'solid',
                        borderTopColor: alpha(ThemeVars.rendrWave3, 0.2),
                        background: alpha(ThemeVars.rendrWave0, 0.20),
                    },
                },
            },
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        height: 15,
                        borderRadius: 3,
                    },
                    bar: {
                        borderRadius: 0,
                    },
                },
            },
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        background: ThemeVars.rendrWave0,
                        color: '#fff',
                    },
                    arrow: {
                        color: ThemeVars.rendrWave0,
                    },
                },
            },
        },
        typography: {
            fontFamily: 'proxima-nova, sans-serif',
            subtitle1: {
                color: 'lightgray',

            },
        },
    })
}

export default makeTheme
