import React from 'react'
import './LatestStudies.scss'
import { Link } from 'react-router-dom'
import StudyCard from '../../components/cards/StudyCard'
import DateTimeUtils from '../../utils/DateTimeUtils'
import StudiesApi from '../../services/StudiesApi'
import LoadingSpinner from '../../components/LoadingSpinner'
import { abbreviate, truncate } from '../../utils/StringUtils'
import FailedToLoad from '../../components/FailedToLoad'

import Grid from '@mui/material/Grid'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Card, IconButton } from '@mui/material'

class LatestStudies extends React.Component {
    state = {
        study_cards: [],
        studies: [],
        is_loading: true,
        did_get_studies_fail: false,
    }

    componentDidMount() {
        this.getStudies()
    }

    getStudies = () => {
        this.setState({
            studies: [],
            study_cards: [],
            is_loading: true,
            did_get_studies_fail: false,
        })

        StudiesApi.getStudiesForList()
            .then(res => {
                res.data.sort(
                    (a, b) => new Date(b.DateCreated) - new Date(a.DateCreated),
                )
                this.setState({
                    studies: res.data.slice(0, 5),
                    study_cards: res.data.slice(0, 3),
                    is_loading: false,
                })
            })
            .catch(() => {
                this.setState({
                    did_get_studies_fail: true,
                    is_loading: false,
                })
            })
    }

    patientRow(study) {
        const shortName = `${truncate(study.LastName, 20)}, ${truncate(
            study.FirstName,
            10,
        )} ${abbreviate(study.MiddleName)}`
        const fullName = `${study.LastName}, ${study.FirstName} ${study.MiddleName || ''}`

        return (
            <TableRow key={study.ID}>
                <TableCell title={fullName}>
                    <Link
                        title={fullName}
                        className="name-link"
                        to={`/Study/${study.ID}`}
                    >
                        {shortName}
                    </Link>
                </TableCell>
                <TableCell>{study.PatientId}</TableCell>
                <TableCell>
                    {DateTimeUtils.getMinStudyDate(study.DateCreated)}
                    {' - '}
                    {DateTimeUtils.getMinStudyTimestamp(study.DateCreated)}
                </TableCell>
                <TableCell>
                    {DateTimeUtils.getStudyDuration(
                        study.TotalStudyRecordingDuration,
                    )}
                </TableCell>
                <TableCell className="text-right">
                    <IconButton
                        title="Open"
                        component={Link}
                        to={`/Study/${study.ID}`}
                        color="primary">
                        <i className="icon fas fa-book-open" />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    }

    render() {
        if (this.state.did_get_studies_fail) {
            return (
                <FailedToLoad
                    onRetry={this.getStudies}
                    items="latest studies"
                />
            )
        }

        if (this.state.is_loading)
            return (<LoadingSpinner />)

        return (
            <span className="latest-studies">
                <h3>Latest Studies</h3>
                <Grid container direction="row" sx={{ maxWidth: '2000px' }} spacing={1} justifyContent="space-evenly" alignItems="flex-start">
                    {this.state.study_cards &&
                        this.state.study_cards.map(study => (
                            <Grid item xs key={study.ID}>
                                <StudyCard
                                    key={study.ID}
                                    study={study}
                                />
                            </Grid>
                        ))}
                    <br />
                </Grid>

                <Card raised>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Patient Name</TableCell>
                                    <TableCell>Patient ID</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Duration</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.studies &&
                                    this.state.studies.map(study =>
                                        this.patientRow(study),
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>

            </span>
        )
    }
}

export default LatestStudies
