import React from 'react'
import './ImportStudy.scss'
import AWS from 'aws-sdk'
import { LinearProgress } from '@mui/material'
import StudiesApi from '../../services/StudiesApi'
import NavUtils from '../../utils/NavUtils'
import PatientsApi from '../../services/PatientsApi'
import ChannelTypesApi from '../../services/ChannelTypesApi'
import LoadingSpinner from '../../components/LoadingSpinner'
import SessionManager from '../../services/SessionManager'
import ImportStudyFolderSelect from './ImportStudyFolderSelect'
import ImportProcessingMonitor from './ImportProcessingMonitor'
import ImportStudyUpload from './ImportStudyUpload'
import ImportStudySummary from './ImportStudySummary'
import ImportStudyVerify from './ImportStudyVerify'
import Stepper from '../../components/Stepper'
import * as StepStatus from '../../constants/importstepstates'
import {
    STEP_FOLDER_SELECT,
    STEP_VERIFY,
    STEP_UPLOAD,
    STEP_PROCESS_DATA,
    STEP_SUMMARY,
    STEP_PROCESS_VIDEO,
} from '../../constants/importsteps'
import * as SyncStates from '../../constants/syncstates'
import ToastUtils from '../../utils/ToastUtils'
import { patientName } from '../../utils/StringUtils'
import Credentials from './Credentials'
import {
    PHOTIC,
    NONIN,
    SAMPLECOUNTER,
    STUDY_TYPE_AMBULATORY,
    STUDY_TYPE_ROUTINE,
    EVENT,
} from '../studies/eegMonitor/constants'

class ImportStudy extends React.Component {
    state = {
        files: {},
        study_info: {},
        patient: undefined,
        is_cloud_sync_pkg: false,
        all_uploads: [],
        s3: null,
        study: null,
        associated_study: null,
        supported_channel_types: [],
        could_sync_api_err: false,
        steps: [
            {
                name: STEP_FOLDER_SELECT.name,
                status: StepStatus.CURRENT,
                icon: 'icon-electrotek-folder-open-solid',
                start_time: Date.now(),
                end_time: null,
            },
            {
                name: STEP_VERIFY.name,
                status: StepStatus.INCOMPLETE,
                icon: 'icon-electrotek-user-circle-o',
                start_time: null,
                end_time: null,
            },
            {
                name: STEP_UPLOAD.name,
                status: StepStatus.INCOMPLETE,
                icon: 'icon-electrotek-import',
                start_time: null,
                end_time: null,
            },
            {
                name: STEP_PROCESS_DATA.name,
                status: StepStatus.INCOMPLETE,
                icon: 'icon-electrotek-settings',
                start_time: null,
                end_time: null,
            },
            {
                name: STEP_PROCESS_VIDEO.name,
                status: StepStatus.INCOMPLETE,
                icon: 'icon-electrotek-settings',
                start_time: null,
                end_time: null,
            },
            {
                name: STEP_SUMMARY.name,
                status: StepStatus.INCOMPLETE,
                icon: 'icon-electrotek-check2',
                start_time: null,
                end_time: null,
            },
        ],
    }


    componentDidMount() {
        SessionManager.set('import_in_progress', true)
        SessionManager.set('import_steps', JSON.stringify(this.state.steps))
        window.addEventListener('beforeunload', this.removeImportInProgressFlag)
        NavUtils.setPageTitle('Import Study')
        if (this.props.match.params.patientId) {
            this.getPatientById(this.props.match.params.patientId)
        } else {
            this.setState({
                patient: null,
                is_cloud_sync_pkg: true,
            })
        }

        ChannelTypesApi.getSupportedChannelTypes()
            .then(res => {
                this.setState({
                    supported_channel_types: [
                        ...res.data,
                        { ID: PHOTIC, Name: 'Photic' },
                        { ID: NONIN, Name: 'Nonin' },
                        { ID: SAMPLECOUNTER, Name: 'Sample Counter' },
                        { ID: EVENT, Name: 'Event' },
                    ],
                })
            })
            .catch(console.log)
    }


    componentWillUnmount() {
        this.removeImportInProgressFlag()
        window.removeEventListener(
            'beforeunload',
            this.removeImportInProgressFlag,
        )
    }

    getPatientById = id => {
        return PatientsApi.getPatient(id)
            .then(res => this.setState({ patient: res.data }))
            .catch(console.log)
    }

    getStudyAndPatientByStudyId = async (id) => {
        const res = await StudiesApi.getStudy(id)
        return [res.data.Study, res.data.Patient]
    }

    removeImportInProgressFlag = () => {
        SessionManager.remove('import_in_progress')
        SessionManager.remove('import_steps')
    }

    goToStep = step => {
        const steps = [...this.state.steps]
        for (let i = 0; i < step.index; i += 1) {
            steps[i].status = StepStatus.COMPLETE
            if (steps[i].end_time===null)
                steps[i].end_time=Date.now()
        }
        steps[step.index].status = StepStatus.CURRENT
        if (steps[step.index].start_time===null)
            steps[step.index].start_time=Date.now()

        for (let i = step.index + 1; i < steps.length; i += 1) {
            steps[i].status = StepStatus.INCOMPLETE
            steps[i].start_time = null
            steps[i].end_time = null
        }

        // last step should not be 'current'
        if (steps[steps.length - 1].status === StepStatus.CURRENT) {
            steps[steps.length - 1].status = StepStatus.COMPLETE
        }
        this.setState({ steps },()=>SessionManager.set('import_steps', JSON.stringify(this.state.steps)))
    }

    failStep = step => {
        const steps = [...this.state.steps]
        for (let i = 0; i < step.index; i += 1) {
            steps[i].status = StepStatus.COMPLETE
            if (steps[i].end_time===null)
                steps[i].end_time=Date.now()
        }
        steps[step.index].status = StepStatus.ERROR
        for (let i = step.index + 1; i < steps.length; i += 1) {
            steps[i].status = StepStatus.INCOMPLETE
            steps[i].start_time = null
            steps[i].end_time = null
    
        }
        this.setState({ steps },()=>SessionManager.set('import_steps', JSON.stringify(this.state.steps)))
    }

    getCurrentStep = () => {
        for (let i = 0; i < this.state.steps.length; i += 1) {
            if (this.state.steps[i].status === StepStatus.CURRENT) {
                return i
            }
            if (this.state.steps[i].status === StepStatus.ERROR) {
                return i
            }
        }
        if (
            this.state.steps[this.state.steps.length - 1].status ===
            StepStatus.COMPLETE
        ) {
            return this.state.steps.length - 1
        }
        return -1
    }

    getCurrentStepStartTime = () => {
        const current_step = this.getCurrentStep()
        return current_step?.start_time
    }

    handleUploadSetup = () => {
        const studyImports = JSON.parse(SessionManager.get('incomplete_study_imports'))
        let studyImport = null
        if (studyImports) {
            studyImport = studyImports[this.state.patient?.ID ?? this.state.study_info.recordId]
        }
        if (
            studyImport &&
            JSON.stringify(studyImport.files) ===
            JSON.stringify(this.state.files)
        ) {
            this.resumeUpload(studyImport)
        } else {
            this.createStudyAndUpload()
        }
    }

    resumeUpload = async study => {
        let res
        try {
            res = await StudiesApi.awsBucketForStudy(study.ID)
        } catch (err) {
            console.log(err)
            ToastUtils.error({ message: 'Unable to resume upload' })
            return
        }

        let uploadedFiles = []
        try {
            uploadedFiles = await this.getAlreadyUploadedFiles(res, study.ID)
        } catch (err) {
            console.log(err)
            ToastUtils.error({ message: 'Unable to resume upload' })
            return
        }

        const all_uploads = this.getAllUploads()
        for (const uploadedFile of uploadedFiles) {
            const name = uploadedFile.Key.split('/').pop()
            const size = uploadedFile.Size
            let found = false
            for (const upload of all_uploads) {
                if (upload.size === size && upload.name === name) {
                    upload.finished = true
                    found = true
                    break
                }
            }
            if (!found) {
                throw Error(`Unexpected file '${name}' found in s3 bucket`)
            }
        }

        const s3 = this.getS3ServiceObjectFromCreds(res.data, study.ID)

        this.setState(
            {
                all_uploads,
                study,
                s3,
                upload_prefix: `${res.data.Path}/`,
            },
            () => this.goToStep(STEP_UPLOAD),
        )
    }

    getAlreadyUploadedFiles = (res, studyId) => {
        const s3 = this.getS3ServiceObjectFromCreds(res.data, studyId)
        const params = {
            Bucket: res.data.BucketName,
            Prefix: res.data.Path,
        }
        return new Promise((resolve, reject) => {
            s3.listObjects(params, (err, data) => {
                if (err) {
                    reject(err)
                    return
                }
                resolve(data.Contents)
            })
        })
    }

    getS3ServiceObjectFromCreds = (awsBucket, studyId) => {
        const credentials = new Credentials(
            awsBucket.AccessKeyId,
            awsBucket.SecretAccessKey,
            awsBucket.SessionToken,
        )

        credentials.studyId = studyId
        AWS.config.update({
            region: awsBucket.AwsRegion,
            credentials,
            correctClockSkew: true,
        })

        const params = {
            Bucket: awsBucket.BucketName,
            Prefix: awsBucket.Path,
        }

        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params,
            s3ForcePathStyle: true,
            useAccelerateEndpoint: true,
        })

        return s3
    }

    createStudyAndUpload = async () => {
        let shouldParseExamId = this.state.is_cloud_sync_pkg
        const dto = {
            PatientID: this.state.patient?.ID ?? null,
            ExamID: this.state.study_info.recordId.trim(),
            StudySyncState: SyncStates.IMPORTING,
            Scale: 0,
            UseVideo: this.state.study_info.videoCount > 0,
            SampleRate: this.state.study_info.sampleRate,
            CustomElectrodes: this.state.study_info.customElectrodes,
            HarnessID: this.state.harness_id || 9,
            IsFileSizeCorrect: this.state.study_info.isFileSizeCorrect,
            IanaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            DateCreated: new Date(),
            StudyTypeId: this.state.is_cloud_sync_pkg ? STUDY_TYPE_AMBULATORY : STUDY_TYPE_ROUTINE,
            LinkedStudyId: null,
            AmplifierId: null,
            AmpVersionJSON: this.state.study_info.ampVersionJSON && JSON.stringify(this.state.study_info.ampVersionJSON),
        }

        if (shouldParseExamId) {
            const parseValues = dto.ExamID.split(' ')
            dto.LinkedStudyId = parseValues[0]
            dto.AmplifierId = parseValues[1]
        }

        let res
        try {
            res = await StudiesApi.uploadSetup(dto)
        } catch (err) {
            console.log(err)
            ToastUtils.error({ message: 'Unable to import study' })
            return
        }

        let studyImports = JSON.parse(SessionManager.get('incomplete_study_imports'))
        if (!studyImports) {
            studyImports = {
                [this.state.patient?.ID ?? this.state.study_info.recordId]: {
                    ID: res.data.study.ID,
                    files: { ...this.state.files },
                },
            }
        } else {
            studyImports[this.state.patient?.ID ?? this.state.study_info.recordId] = {
                ID: res.data.study.ID,
                files: { ...this.state.files },
            }
        }

        SessionManager.set(
            'incomplete_study_imports',
            JSON.stringify(studyImports),
        )

        const s3 = this.getS3ServiceObjectFromCreds(
            res.data.awsBucket,
            res.data.study.ID,
        )

        this.setState(
            {
                all_uploads: this.getAllUploads(),
                study: res.data.study,
                s3,
                upload_prefix: `${res.data.awsBucket.Path}/`,
            },
            () => this.goToStep(STEP_UPLOAD),
        )
    }

    getAllUploads = () => {
        const all_uploads = []
        all_uploads.push(...this.state.files.tvsFiles)
        all_uploads.push(...this.state.files.tevFiles)
        if (this.state.files.tvxFile) {
            all_uploads.push(this.state.files.tvxFile)
        }
        all_uploads.push(...this.state.files.dataFiles)

        const ignoredChannels = [PHOTIC, EVENT, NONIN, SAMPLECOUNTER]
        const dotFinishedBody = JSON.stringify({
            ExtraChannels: this.state.study_info.customElectrodes.filter(e => !ignoredChannels.includes(e.ChannelTypeID)),
            PhoticIndex: this.state.study_info.photicIndex,
            TimeZone: 'UTC',
        })
        all_uploads.push(...this.state.files.vidFiles)
        all_uploads.push(new File([dotFinishedBody], '.FINISHED'))
        return all_uploads
    }

    handleCustomElectrodeChange = (index, id) => {
        const study_info = { ...this.state.study_info }
        study_info.custom_electrodes[index].ChannelTypeID = parseInt(id, 10)
        this.setState({ study_info })
    }

    handleVerify = async (files, study_info) => {
        this.setState({ files, study_info })
        if (this.state.is_cloud_sync_pkg) {
            try {
                const [study, patient] = await this.getStudyAndPatientByStudyId(study_info.recordId.split(' ')[0])
                await new Promise(r => this.setState({ patient, associated_study: study }, r))
            } catch (err) {
                console.log(err)
                this.setState({ could_sync_api_err: true })
            }
        }
        this.goToStep(STEP_VERIFY)
    }

    handleUpdateStudyInfo = study_info => {
        this.setState({ study_info })
    }

    handleSyncStatusUpdate = (status, stepToFail, nextStep) => {
        switch (status) {
            case SyncStates.FAILED:
                this.failStep(stepToFail)
                break
            case SyncStates.SYNCED:
                this.goToStep(nextStep)
                break
            default:
        }
    }

    setHarnessId = id => {
        this.setState({ harness_id: id })
    }

    renderCurrentStep = () => {
        const step = this.getCurrentStep()
        switch (step) {
            case STEP_FOLDER_SELECT.index:
                return (
                    <ImportStudyFolderSelect
                        onVerify={this.handleVerify}
                        supportedChannelTypes={this.state.supported_channel_types}
                        isCloudSyncPkg={this.state.is_cloud_sync_pkg}
                    />
                )
            case STEP_VERIFY.index:
                return (
                    <ImportStudyVerify
                        onContinue={this.handleUploadSetup}
                        isCloudSyncPkg={this.state.is_cloud_sync_pkg}
                        cloudSyncApiErr={this.state.could_sync_api_err}
                        associatedStudy={this.state.associated_study}
                        onUpdateStudyInfo={this.handleUpdateStudyInfo}
                        patient={this.state.patient}
                        studyInfo={this.state.study_info}
                        supportedChannelTypes={
                            this.state.supported_channel_types
                        }
                        setHarnessId={this.setHarnessId}
                        onBack={() => {
                            if (this.state.is_cloud_sync_pkg) {
                                this.setState({ patient: null })
                            }
                            this.goToStep(STEP_FOLDER_SELECT)
                        }}
                    />
                )
            case STEP_UPLOAD.index:
                if (this.state.all_uploads.length > 0) {
                    return (
                        <ImportStudyUpload
                            files={this.state.all_uploads}
                            prefix={this.state.upload_prefix}
                            patient={this.state.patient}
                            s3={this.state.s3}
                            onFinish={() => this.goToStep(STEP_PROCESS_DATA)}
                            recordId={this.state.study_info.recordId}
                        />
                    )
                } else {
                    return (
                        <React.Fragment>
                            <span>Waiting on server...</span>
                            <LinearProgress variant="indeterminate" />
                        </React.Fragment>
                    )
                }
            case STEP_PROCESS_DATA.index:
                return (
                    <ImportProcessingMonitor
                        shouldDisplayDataProgress
                        study={this.state.study}
                        syncStatusUpdate={status => this.handleSyncStatusUpdate(status, STEP_PROCESS_DATA, this.state.study.UseVideo ? STEP_PROCESS_VIDEO : STEP_SUMMARY)}
                        studyInfo={this.state.study_info}
                        onBack={() => this.goToStep(STEP_VERIFY)}
                        step={this.state.steps[step]}
                    />
                )
            case STEP_PROCESS_VIDEO.index:
                return (
                    <ImportProcessingMonitor
                        shouldDisplayVideoProgress
                        study={this.state.study}
                        syncStatusUpdate={status => this.handleSyncStatusUpdate(status, STEP_PROCESS_VIDEO, STEP_SUMMARY)}
                        studyInfo={this.state.study_info}
                        onBack={() => this.goToStep(STEP_PROCESS_DATA)}
                        step={this.state.steps[step]}
                    />
                )
            case STEP_SUMMARY.index:
                return ( 
                    <ImportStudySummary 
                        study={this.state.study} 
                        steps={this.state.steps}
                    />
                )
            default:
                throw Error(`Unrecognized step '${this.getCurrentStep()}'`)
        }
    }

    render() {
        if (
            this.state.patient === undefined ||
            this.state.supported_channel_types.length === 0
        ) {
            return (
                <div className="import-study">
                    <LoadingSpinner />
                </div>
            )
        }

        return (
            <div className="import-study">
                <div className="container">
                    {!this.state.is_cloud_sync_pkg ? (
                        <h3>Import Study for {patientName(this.state.patient)}</h3>
                    ) : (
                        <h3>
                            Import Cloud Sync Package
                            {this.state.patient && ` for ${patientName(this.state.patient)}`}
                        </h3>
                    )}
                    <Stepper steps={this.state.steps} />
                    {this.renderCurrentStep()}
                </div>
            </div>
        )
    }
}

export default ImportStudy
