import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import StudyEvent from '../../../../types/StudyEvent'
import AppContext from '../../../../components/AppContext'
import ToastUtils from '../../../../utils/ToastUtils'
import DateTimeUtils from '../../../../utils/DateTimeUtils'
import { EventMoveLocation } from './EventMarkerInfoBox'
import ClockSetting from '../../../../types/ClockSetting'
import GraphUtilities from '../../utils/GraphUtilities'

interface Props {
    event: StudyEvent
    clockSetting: ClockSetting
    timebase: number
    onExit: () => void
    onUpdateEvent: (event: StudyEvent) => Promise<void>
    onMoveGraphToStudyEvent: (event: StudyEvent, endOfEvent: EventMoveLocation) => Promise<void>
    calculateSampleIndex: (recordingIndex: number, packetIndex: number) => number
}

type Edge = 'StartPacketIndex' | 'EndPacketIndex'

const DURATION_OPTIONS = [1, 3, 5, 10, 20, 30]

const EventMarkerDurationEditing: React.FC<Props> = props => {
    const ctx = useContext(AppContext)
    const [isSaving, setIsSaving] = useState(false)
    const [duration, setDuration] = useState<number>(0)
    const [edge, setEdge] = useState<Edge>()
    const [shouldFollowChanges, setShouldFollowChanges] = useState(false)
    const [hasRecentDurationChange, setHasRecentDurationChange] = useState(false)
    const changeCnt = useRef(0)

    const startPktIndex = props.event.StartPacketIndex
    const endPktIndex = props.event.EndPacketIndex
    const recordingPacketCount = ctx.Study.StudyRecordings[props.event.RecordingIndex - 1].PacketCount - 12
    const isDurationOptionDisabled = useCallback((packets: number): boolean => {
        let newIndex = startPktIndex + packets
        let min = 1
        let max = recordingPacketCount
        if (!max) return true
        switch (edge) {
            case 'StartPacketIndex':
                max = endPktIndex
                break
            case 'EndPacketIndex':
                min = startPktIndex
                newIndex = endPktIndex + packets
                break
            default:
                return true
        }
        return newIndex > max || newIndex < min
    }, [edge, startPktIndex, endPktIndex, recordingPacketCount])

    useEffect(() => {
        if (!duration || !edge) return
        if (isDurationOptionDisabled(duration)) {
            setDuration(0)
        }
    }, [duration, edge, isDurationOptionDisabled])

    const handleApply = async () => {
        if (isSaving || !edge || !duration) return
        setIsSaving(true)
        const event: StudyEvent = {
            ...props.event,
            [edge]: props.event[edge] + duration,
        }
        const shouldMoveGraphFirst = (edge === 'StartPacketIndex' && duration > 0) || (edge === 'EndPacketIndex' && duration < 0)

        try {
            if (shouldMoveGraphFirst) {
                if (shouldFollowChanges) {
                    await props.onMoveGraphToStudyEvent(event, edge === 'EndPacketIndex' ? 'end' : 'start')
                }
                await props.onUpdateEvent(event)
            } else {
                await props.onUpdateEvent(event)
                if (shouldFollowChanges) {
                    await props.onMoveGraphToStudyEvent(event, edge === 'EndPacketIndex' ? 'end' : 'start')
                }
            }
        } catch (err) {
            ToastUtils.error({ message: 'Unable to update event duration' })
            console.log('Error updating duration:', err)
        }
        setIsSaving(false)
    }

    const durationPkts = Math.abs(props.event.EndPacketIndex - props.event.StartPacketIndex)
    const durationMs = 1000 * durationPkts / ctx.Study.StudyDataRate
    useEffect(() => {
        changeCnt.current++
        if (changeCnt.current === 1) {
            return
        }
        setHasRecentDurationChange(true)
        const timeout = setTimeout(() => setHasRecentDurationChange(false), 1000)
        return () => clearTimeout(timeout)
    }, [durationPkts])

    const formatTimestamp = (packetIndex: number) => {
        packetIndex = props.calculateSampleIndex(props.event.RecordingIndex, packetIndex)
        const mom = GraphUtilities.getMomentByPacketIndex(ctx, packetIndex)
        let display = ''
        switch (props.clockSetting) {
            case ClockSetting.Duration:
                display = DateTimeUtils.getFriendlyStudyTimestamp(1000 * packetIndex / ctx.Study.StudyDataRate)
                break
            case ClockSetting.TwelveHour:
                display = mom.format('h:mm:ss A')
                break
            case ClockSetting.TwentyFourHour:
                display = mom.format('HH:mm:ss')
                break
        }
        return display
    }

    const durations = [props.timebase / 60, ...DURATION_OPTIONS]

    return (
        <div className="duration-change">
            <p>
                <span style={{ float: 'right' }}>
                    Duration:
                    <span className="duration-wrapper">
                        <span
                            className={`duration ${hasRecentDurationChange ? 'highlight' : ''}`}
                        >
                            {durationMs < 1000 ? '< 1sec' : DateTimeUtils.getStudyDuration(durationMs)}
                        </span>
                    </span>
                </span>
                <i
                    onClick={props.onExit}
                    className="fas fa-arrow-left no-selection-toggle"
                    style={{ marginRight: 20, cursor: 'pointer' }}
                />
            </p>
            <div style={{ marginBottom: 20, textAlign: 'center' }}>
                {formatTimestamp(props.event.StartPacketIndex)}
                {' '}-{' '}
                {formatTimestamp(props.event.EndPacketIndex)}
            </div>
            <div style={{ marginBottom: 20 }}>
                <span className="select-wrapper">
                    <select
                        className="no-selection-toggle select"
                        value={edge}
                        onChange={v => setEdge(v.target.value as Edge)}
                    >
                        <option value={undefined}>
                            Select edge
                        </option>
                        <option value="StartPacketIndex">
                            Move start of event
                        </option>
                        <option value="EndPacketIndex">
                            Move end of event
                        </option>
                    </select>
                </span>
            </div>
            <div style={{ marginBottom: 20 }}>
                <span className="select-wrapper">
                    <select
                        className="no-selection-toggle select"
                        value={duration}
                        onChange={v => setDuration(parseInt(v.target.value))}
                    >
                        <option value={0}>
                            Select duration
                        </option>
                        {[...durations].reverse().map(dur => {
                            const packets = -1 * dur * 60 * ctx.Study.StudyDataRate
                            return (
                                <option
                                    key={dur}
                                    value={packets}
                                    disabled={isDurationOptionDisabled(packets)}
                                >
                                    {Math.abs(dur) < 1 ?
                                        `back ${dur * 60} second${dur * 60 === 1 ? '' : 's'}` :
                                        `back ${dur} minute${dur === 1 ? '' : 's'}`}
                                </option>
                            )
                        })}
                        {[...durations].map(dur => {
                            const packets = dur * 60 * ctx.Study.StudyDataRate
                            return (
                                <option
                                    key={dur}
                                    value={packets}
                                    disabled={isDurationOptionDisabled(packets)}
                                >
                                    {Math.abs(dur) < 1 ?
                                        `forward ${dur * 60} second${dur * 60 === 1 ? '' : 's'}` :
                                        `forward ${dur} minute${dur === 1 ? '' : 's'}`}
                                </option>
                            )
                        })}
                    </select>
                </span>
            </div>
            <div
                className="no-selection-toggle"
                style={{ marginBottom: 20, cursor: 'pointer', float: 'right' }}
                onClick={() => setShouldFollowChanges(s => !s)}
            >
                Follow {edge === 'StartPacketIndex' ? 'start of event' : edge === 'EndPacketIndex' ? 'end of event' : 'edge'}
                <input
                    type="checkbox"
                    checked={shouldFollowChanges}
                    readOnly
                    style={{ marginLeft: 10, position: 'relative', top: 2, cursor: 'pointer' }}
                />
            </div>
            <div style={{ clear: 'both' }} />
            <button
                className={`no-selection-toggle button ${(isSaving || !duration || !edge) ? 'disabled' : ''}`}
                onClick={handleApply}
                style={{ float: 'right' }}
            >
                Apply{isSaving ? 'ing...' : ''}
            </button>
        </div>
    )
}

export default EventMarkerDurationEditing
