import { useContext, useState } from 'react'
import StudiesApi from '../../../services/StudiesApi'
import {
    IN_PROGRESS,
    PENDING_REVIEW,
    REPORT_COMPLETE,
    STUDY_STATES,
} from '../../../constants/studystates'
import AppContext from '../../../components/AppContext'
import { Action } from '../../../components/AppState'
import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    REVIEW_DOCTOR_ROLE,
    FIELD_TECH_ROLE,
    LEAD_TECH_ROLE,
    FACILITY_ADMIN_ROLE,
} from '../../../constants/roles'
import ToastUtils from '../../../utils/ToastUtils'
import ModalBool from '../../../components/modals/ModalBool'
import Stepper from '../../../components/Stepper'
import * as StepStatus from '../../../constants/importstepstates'
import { Button } from '@mui/material'
import RoleBasedContent from '../../../components/RoleBasedContent'
import { ROUTINE_EEG } from '../../../constants/studytypes'

interface Props {
    isTrackitPlus: boolean
}

function StudyStateView(props: Props) {
    const [isChangingState, setIsChangingState] = useState(false)
    const [newStudyState, setNewStudyState] = useState(-1)
    const ctx = useContext(AppContext)

    const handleSetStudyState = (state: number) => {
        setIsChangingState(true)
        StudiesApi.changeStudyState(ctx.Study.ID, state)
            .then(() => {
                const StudyState = {
                    ...ctx.Study.StudyState,
                    Name: STUDY_STATES[state],
                    ID: state,
                }
                ctx.commit(Action.UpdateStudy, { StudyState })
                setIsChangingState(false)
                setNewStudyState(-1)
            })
            .catch(() => {
                ToastUtils.error({ message: 'Unable to change study state' })
                setIsChangingState(false)
            })
    }

    const getNextStudyState = (): number => {
        switch (ctx.Study.StudyState.ID) {
            case IN_PROGRESS: return PENDING_REVIEW
            case PENDING_REVIEW: return REPORT_COMPLETE
            default: return -1
        }
    }

    const getPrevStudyState = (): number => {
        switch (ctx.Study.StudyState.ID) {
            case PENDING_REVIEW: return IN_PROGRESS
            case REPORT_COMPLETE: return PENDING_REVIEW
            default: return -1
        }
    }

    const getNextStudyStateRoles = (): string[] => {
        switch (ctx.Study.StudyState.ID) {
            case PENDING_REVIEW: return [
                SUPER_ADMIN_ROLE,
                SUPPORT_ROLE,
                FACILITY_ADMIN_ROLE,
                LEAD_TECH_ROLE,
                FIELD_TECH_ROLE,
                REVIEW_DOCTOR_ROLE,
            ]
            default: return []
        }
    }

    const getPrevStudyStateRoles = (): string[] => {
        switch (ctx.Study.StudyState.ID) {
            case PENDING_REVIEW:
                if (ctx.Study.StudyType.ID !== ROUTINE_EEG || props.isTrackitPlus) {
                    return []
                }
                return [
                    SUPER_ADMIN_ROLE,
                    SUPPORT_ROLE,
                ]
            case REPORT_COMPLETE: return [
                SUPER_ADMIN_ROLE,
                SUPPORT_ROLE,
                FACILITY_ADMIN_ROLE,
            ]
            default: return []
        }
    }

    const getInProgressStepStatus = (): number => {
        switch (ctx.Study.StudyState.ID) {
            case REPORT_COMPLETE:
            case PENDING_REVIEW: return StepStatus.COMPLETE
            case IN_PROGRESS: return StepStatus.CURRENT
            default: return StepStatus.INCOMPLETE
        }
    }

    const getPendingReviewStepStatus = (): number => {
        switch (ctx.Study.StudyState.ID) {
            case REPORT_COMPLETE: return StepStatus.COMPLETE
            case PENDING_REVIEW: return StepStatus.CURRENT
            case IN_PROGRESS: return StepStatus.INCOMPLETE
            default: return StepStatus.COMPLETE
        }
    }

    const getCompleteStepStatus = (): number => {
        switch (ctx.Study.StudyState.ID) {
            case REPORT_COMPLETE: return StepStatus.COMPLETE
            default: return StepStatus.INCOMPLETE
        }
    }

    return (
        <div className="row study-state-view">
            <div className="col-12">
                <h4>
                    Study Status
                </h4>
                <div style={{ width: '90%', margin: 'auto' }}>
                    <Stepper
                        steps={[
                            {
                                name: 'In Progress',
                                status: getInProgressStepStatus(),
                                icon: 'icon-electrotek-amplifier',
                            },
                            {
                                name: 'Pending Review',
                                status: getPendingReviewStepStatus(),
                                icon: 'fas fa-user-md',
                            },
                            {
                                name: 'Complete',
                                status: getCompleteStepStatus(),
                                icon: 'icon-electrotek-check2',
                            },
                        ]}
                    />
                </div>
                <RoleBasedContent required_roles={[
                    SUPER_ADMIN_ROLE,
                    SUPPORT_ROLE,
                    FACILITY_ADMIN_ROLE,
                    LEAD_TECH_ROLE,
                    FIELD_TECH_ROLE,
                    REVIEW_DOCTOR_ROLE,
                ]}>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <RoleBasedContent required_roles={getPrevStudyStateRoles()}>
                            {getPrevStudyState() > -1 && (
                                <Button
                                    sx={{ m: 1 }}
                                    onClick={() => setNewStudyState(getPrevStudyState())}
                                >
                                    <i className="icon-electrotek-chevron-left" />
                                    {' '}
                                    {STUDY_STATES[getPrevStudyState()]}
                                </Button>
                            )}
                        </RoleBasedContent>
                        <RoleBasedContent required_roles={getNextStudyStateRoles()}>
                            {getNextStudyState() > -1 && (
                                <Button
                                    sx={{ m: 1 }}
                                    onClick={() => setNewStudyState(getNextStudyState)}
                                >
                                    {STUDY_STATES[getNextStudyState()]}
                                    {' '}
                                    <i className="icon-electrotek-chevron-right" />
                                </Button>
                            )}
                        </RoleBasedContent>
                    </div>
                </RoleBasedContent>
                <ModalBool
                    isOpen={newStudyState > -1}
                    title="Change Study State"
                    message={`Are you sure you want to make this study as ${STUDY_STATES[newStudyState]}?`}
                    trueButtonText={`Chang${isChangingState ? 'ing...' : 'e'}`}
                    loading={isChangingState}
                    falseButtonText="Cancel"
                    toggle={() => isChangingState ? null : setNewStudyState(-1)}
                    handleTrue={() => handleSetStudyState(newStudyState)}
                    handleFalse={() => setNewStudyState(-1)}
                />
            </div>
        </div>
    )
}

export default StudyStateView
