import { useEffect } from 'react'
import NavUtils from '../utils/NavUtils'

function useDocumentTitle(title: string) {
    useEffect(() => {
        NavUtils.setPageTitle(title)
    }, [title])
}

export default useDocumentTitle
