// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

class FilteredDataMinute {
    constructor(minute, montageTemplateId, filterSettings) {
        this.minute = minute
        this.studySamples = []
        this.fullMinute = false
        this.lastRecordingIndex = -1
        this.lastRecordingPacketIndex = -1
        this.montageTemplateId = montageTemplateId
        this.filterSettings = filterSettings
    }

    // Note: This method assumes that the lists will come to me in order and that all lists will make
    // it here.  This may need to change.
    addStudySamples(samples) {
        if (samples[0] === undefined) return

        // if we don't have any items in study samples, just add it
        if (this.studySamples.length === 0) {
            this.studySamples.push(samples)
            // console.log("Initial sample list added.");
        } else {
            const firstSampleRecordingIndex = samples[0].RecordingIndex
            const firstSamplePacketIndex = samples[0].Index
            let inserted = false

            for (
                let index = 0;
                index < this.studySamples.length && !inserted;
                index += 1
            ) {
                const recordingIndex = this.studySamples[index][0]
                    .RecordingIndex
                const packetIndex = this.studySamples[index][0].Index

                // insert this list if the first sample of the given list is greater than this
                if (
                    firstSampleRecordingIndex < recordingIndex ||
                    (firstSampleRecordingIndex === recordingIndex &&
                        firstSamplePacketIndex < packetIndex)
                ) {
                    this.studySamples.splice(index, 0, samples)
                    inserted = true
                }
            }

            if (!inserted) {
                this.studySamples.push(samples)
            }
        }

        // check the last sample in the list to see if it contains the last recording packet index.
        // This assumes that the last sample list will be provided here last.

        if (
            this.lastRecordingIndex !== -1 &&
            this.lastRecordingPacketIndex !== -1
        ) {
            if (
                this.studySamples[this.studySamples.length - 1]
                    .RecordingIndex === this.lastRecordingIndex &&
                this.studySamples[this.studySamples.length - 1].Index ===
                    this.lastRecordingPacketIndex
            ) {
                this.fullMinute = true
            }
        }
    }

    setLastStudyPacketIndex(recordingIndex, recordingPacketIndex) {
        this.lastRecordingIndex = recordingIndex
        this.lastRecordingPacketIndex = recordingPacketIndex
    }
}

export default FilteredDataMinute
