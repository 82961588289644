import React from 'react'
import InputGroup from '../../components/forms/InputGroup'
import SelectGroup from '../../components/forms/SelectGroup'
import RoleBasedContent from '../../components/RoleBasedContent'
import {
    PRODUCTION_ROLE,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
} from '../../constants/roles'
import './DeviceForm.scss'
import { truncate } from '../../utils/StringUtils'

// type Rule = {
//     min: number,
//     max: number,
// }

// type ValidationRules = {
//     Name: Rule,
//     PartNumber: Rule,
//     Configuration: Rule,
//     SerialNumber: Rule,
// }

// type Facility = {
//     ArchiveLocation: string,
//     DateCreated: string,
//     DisableAutoArchive: string,
//     Domain: string,
//     ID: string,
//     LocalStorageDays: string,
//     Name: string,
//     StudyNotesTemplate: string,
//     UsesCloudStorage: string,
// }

// type Props = {
//     ID: string,
//     Name: ?string,
//     PartNumber: ?string,
//     Configuration: ?string,
//     SerialNumber: string,
//     FacilityID: string,
//     facilitiesList: Array<Facility>,
//     validationRules: ValidationRules,
//     validation: {
//         Name: boolean,
//         PartNumber: boolean,
//         Configuration: boolean,
//         SerialNumber: boolean,
//     },
//     creating?: boolean,
//     readOnly?: boolean,
//     handleChange: (field: string, value: string) => ?void,
// }

// type State = {
//     is_showing_copied_message: boolean,
// }

class DeviceForm extends React.Component {
    state = {
        is_showing_copied_message: false,
    }

    facilityOptions = () =>
        this.props.facilitiesList.map(facility => ({
            value: facility.ID,
            name: truncate(facility.Name, 25),
        }))

    handleIDClick = () => {
        if (this.state.is_showing_copied_message) return
        const input = document.querySelector('.input-copy-text')
        if (input instanceof HTMLInputElement) {
            // for flow
            input.select()
        } else {
            return
        }
        document.execCommand('copy')
        window.getSelection().removeAllRanges()
        this.setState({ is_showing_copied_message: true })
        this.toggleIsShowingCopiedMessage()
        setTimeout(this.toggleIsShowingCopiedMessage, 1500)
    }

    toggleIsShowingCopiedMessage = () =>
        this.setState({
            is_showing_copied_message: !this.state.is_showing_copied_message,
        })

    render = () => (
        <form className="device-form">
            <p>* All fields are required</p>

            {!this.props.creating && (
                <RoleBasedContent
                    required_roles={[
                        SUPER_ADMIN_ROLE,
                        PRODUCTION_ROLE,
                        SUPPORT_ROLE,
                    ]}
                >
                    <InputGroup
                        onClick={this.handleIDClick}
                        class="input-copy-text form-control"
                        type="text"
                        field="ID"
                        title="Click to copy"
                        value={
                            this.state.is_showing_copied_message
                                ? 'Copied!'
                                : this.props.ID
                        }
                        label="ID"
                        readOnly
                    />
                </RoleBasedContent>
            )}

            <InputGroup
                type="text"
                field="Name"
                valid={this.props.validation.Name}
                error={`A name is required that's ${
                    this.props.validationRules.Name.max
                } characters or less`}
                value={this.props.Name || ''}
                label="Name"
                placeholder="Name"
                disabled={this.props.readOnly}
                maxLength={50}
                handleChange={this.props.handleChange}
            />

            <InputGroup
                type="text"
                field="PartNumber"
                valid={this.props.validation.PartNumber}
                error={`A part number is required that's ${
                    this.props.validationRules.PartNumber.max
                } characters or less`}
                label="Part Number"
                placeholder="Part Number"
                disabled={this.props.readOnly}
                maxLength={50}
                value={this.props.PartNumber || ''}
                handleChange={this.props.handleChange}
            />

            <InputGroup
                type="text"
                field="Configuration"
                valid={this.props.validation.Configuration}
                error={`A configuration is required that's ${
                    this.props.validationRules.Configuration.max
                } characters or less`}
                label="Configuration"
                placeholder="Configuration"
                value={this.props.Configuration || ''}
                disabled={this.props.readOnly}
                maxLength={50}
                handleChange={this.props.handleChange}
            />

            <InputGroup
                type="text"
                field="SerialNumber"
                valid={this.props.validation.SerialNumber}
                error={`A serial number is required that's ${
                    this.props.validationRules.SerialNumber.max
                } characters or less`}
                label="Serial Number"
                placeholder="Serial Number"
                disabled={this.props.readOnly}
                maxLength={50}
                value={this.props.SerialNumber || ''}
                handleChange={this.props.handleChange}
            />

            {this.props.creating && (
                <SelectGroup
                    field="FacilityID"
                    label="Facility"
                    options={this.facilityOptions()}
                    value={this.props.FacilityID}
                    disabled={this.props.readOnly}
                    handleChange={this.props.handleChange}
                />
            )}
        </form>
    )
}

export default DeviceForm
