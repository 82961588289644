import axios from 'axios'
import MMTApi from './MMTApi'

const SharedUsersApi = {
    getSharedUsers(studyId) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/Studies/GetSharedUsers/?studyId=${studyId}`,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default SharedUsersApi
