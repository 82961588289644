import Mapping from '../types/Mapping'
import NamedEnum from '../types/NamedEnum'
import MappingItem from '../types/MappingItem'
import Channel from '../types/Channel'
import Electrode from '../types/Electrode'
import axios from 'axios'
import MMTApi from './MMTApi'

const LATENCY = 1000

class MappingApi {
    static getAllMappings(facilityId: string): Promise<{ data: Mapping[] }> {
        console.log(facilityId)
        return new Promise(r => setTimeout(() => r({ data: [
            {
                ID: 'xyx-1',
                Name: 'Standard 10 / 20',
                HarnessID: 7,
            },
            {
                ID: 'xyx-2',
                Name: 'Respiratory A',
                HarnessID: 7,
            },
            {
                ID: 'xyx-4',
                Name: 'Respiratory B',
                HarnessID: 7,
            },
            {
                ID: 'xyx-5',
                Name: 'Special Mapping',
                HarnessID: 7,
            },
        ] }), LATENCY))
    }

    static getElectrodes(harnessId: number): Promise<{ data: Electrode[] }> {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Electrodes/GetElectrodes?harnessId=${harnessId}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static createMappingItem(item: MappingItem): Promise<{ data: MappingItem }> {
        return new Promise(r => setTimeout(() => r({ data: {
            ...item,
            ID: (Math.random() * 1e6).toString(),
        } }), LATENCY))
    }

    static updateMappingItem(item: MappingItem): Promise<void> {
        console.log(item)
        return new Promise(r => setTimeout(() => r(), LATENCY))
    }

    static deleteMappingItem(id: string): Promise<void> {
        console.log(id)
        return new Promise(r => setTimeout(() => r(), LATENCY))
    }

    static createMapping(mapping: { Name: string, HarnessID: number }): Promise<{ data: Mapping }> {
        return new Promise(r => setTimeout(() => r({ data: {
            ...mapping,
            ID: (Math.random() * 1e6).toString(),
        } }), LATENCY))
    }

    static updateMapping(mapping: Mapping): Promise<{ data: Mapping }> {
        return new Promise(r => setTimeout(() => r({ data: {
            ...mapping,
            ID: (Math.random() * 1e6).toString(),
        } }), LATENCY))
    }

    static deleteMapping(id: string): Promise<void> {
        console.log(id)
        return new Promise(r => setTimeout(() => r(), LATENCY))
    }

    static getMappingItems(_id: string): Promise<{ data: MappingItem[] }> {
        return new Promise(r => setTimeout(() => r({ data: [
            // {
            //     ID: 'def-1',
            //     Name: 'Cz',
            //     Electrode: {
            //         ID: 1,
            //         Input: 1,
            //         Name: 'Cz',
            //         HarnessID: 7,
            //     },
            //     InputType: { ID: 1, Name: 'Referential' },
            //     ChannelType: { ID: 1, Name: 'EEG' },
            //     MappingID: 'xyx-1',
            // },
            // {
            //     ID: 'def-2',
            //     Name: 'Fz',
            //     Electrode: {
            //         ID: 2,
            //         Input: 23,
            //         Name: 'Fz',
            //         HarnessID: 7,
            //     },
            //     InputType: { ID: 1, Name: 'Referential' },
            //     ChannelType: { ID: 1, Name: 'EEG' },
            //     MappingID: 'xyx-2',
            // },
        ] }), LATENCY))
    }

    static getMappingItemsByRecording(studyId: string, recordingIndex: number): Promise<{ data: any[] }> {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/StudyRecordings/GetMappings?id=${studyId}&recordingIndex=${recordingIndex}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getChannelSuggestions(inputTypeID: number): Promise<{ data: Channel[] }> {
        console.log(inputTypeID)
        return new Promise(r => setTimeout(() => r({ data: [
            { Name: 'Cz', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'Fz', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'Pz', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'P3', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'P4', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'A1', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'A2', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'O1', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'O2', ChannelType: { ID: 1, Name: 'EEG' } },
            { Name: 'Rsp', ChannelType: { ID: 3, Name: 'Respiratory' } },
            { Name: 'Rspl', ChannelType: { ID: 3, Name: 'Respiratory' } },
            { Name: 'Rspr', ChannelType: { ID: 3, Name: 'Respiratory' } },
            { Name: 'EKL', ChannelType: { ID: 2, Name: 'EKG' } },
            { Name: 'EKR', ChannelType: { ID: 2, Name: 'EKG' } },
            { Name: 'ECL', ChannelType: { ID: 2, Name: 'EKG' } },
            { Name: 'ECR', ChannelType: { ID: 2, Name: 'EKG' } },
        ] }), LATENCY))
    }

    static getChannelTypes(inputTypeId: number): Promise<{ data: NamedEnum[] }> {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/ChannelTypes/GetChannelTypesByInputTypeID?inputTypeId=${inputTypeId}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getAllHarnesses(): Promise<{ data: NamedEnum[] }> {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Harnesses/GetHarnesses`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getInputTypes(electrodeId: number): Promise<{ data: NamedEnum[] }> {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/InputTypes/GetInputTypes?electrodeId=${electrodeId}`,
            MMTApi.getRequestHeaders(),
        )
    }
}

export default MappingApi
