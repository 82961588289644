import React from 'react'
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
} from 'reactstrap'
import { Button } from '@mui/material'
import SessionManager from '../../services/SessionManager'
import InputGroup from '../../components/forms/InputGroup'
import SelectGroup from '../../components/forms/SelectGroup'

import Validation from '../../services/Validation'

const facilityName = () => SessionManager.get('mmt_facility_name')

const UserInvite = Props => {
    const {
        email,
        role,
        roleOptions,
        handleSendInvite,
        handleChange,
        toggle,
        is_modal_open,
        message,
    } = Props
    const isValidEmail = () => email && Validation.isValidEmail(email)
    const isValidRole = () => role && role !== ''
    const isFormValid = () => isValidEmail() && isValidRole()

    return (
        <Modal isOpen={is_modal_open} toggle={() => toggle()}>
            <ModalHeader>Send Facility Invitation</ModalHeader>
            <ModalBody>
                <div className="m-4">
                    {message ? message : <React.Fragment>
                        Enter the email address and specify the role of the
                        individual you would like to invite to your Rendr platform
                        facility.
                    </React.Fragment>}
                </div>
                <Form className="container">
                    <InputGroup
                        type="email"
                        id="Email"
                        field="InviteeEmail"
                        label="Email"
                        className="is-valid"
                        placeholder="Enter Invitee Email Address"
                        value={email}
                        valid={!email || isValidEmail(email)}
                        error="A valid email address is required"
                        handleChange={handleChange}
                    />

                    <SelectGroup
                        field="InviteeRole"
                        label="Role"
                        value={role}
                        options={roleOptions}
                        handleChange={handleChange}
                    />

                    <InputGroup
                        style={{ color: '#000' }}
                        type="text"
                        field="Facility"
                        label="Facility"
                        value={facilityName()}
                        disabled
                        readOnly
                    />                   
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    variant="outlined"
                    onClick={toggle}
                    sx={{ mr: 2 }}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!isFormValid()}
                    onClick={() => isFormValid() && handleSendInvite()}
                >
                    Send
                </Button>
            </ModalFooter>
        </Modal>
    )
}

export default UserInvite
