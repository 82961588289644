import axios, { CancelTokenSource } from 'axios'
import Montage from '../types/Montage'
import MMTApi from './MMTApi'

class MontagesApi {
    static search(searchString: string, source?: CancelTokenSource) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/MontageTemplates/SearchTemplates/?searchString=${searchString}`,
            {
                ...MMTApi.getRequestHeaders(),
                cancelToken: source?.token,
            }
        )
    }

    static getMontages(source?: CancelTokenSource) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/MontageTemplates/GetTemplates`,
            {
                ...MMTApi.getRequestHeaders(),
                cancelToken: source?.token,
            }
        )
    }

    static deleteMontage(id: string) {
        return axios.delete(
            `${
                process.env.REACT_APP_API_URL
            }/api/MontageTemplates/DeleteTemplate?id=${id}`,
            MMTApi.getRequestHeaders(),
        )
    }

    static getMontage(id: string, source?: CancelTokenSource) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/MontageTemplates/GetTemplate?id=${id}`,
            {
                ...MMTApi.getRequestHeaders(),
                cancelToken: source?.token,
            }
        )
    }

    static updateMontage(montage: Montage) {
        return axios.put(
            `${
                process.env.REACT_APP_API_URL
            }/api/MontageTemplates/UpdateTemplate`,
            { ...montage, Channels: montage.Channels.map(ch => /[0-9]+/.test(ch.ID) ? { ...ch, ID: undefined } : ch) },
            MMTApi.getRequestHeaders(),
        )
    }

    static createMontage(montage: Partial<Montage>) {
        return axios.post(
            `${
                process.env.REACT_APP_API_URL
            }/api/MontageTemplates/CreateTemplate`,
            montage,
            MMTApi.getRequestHeaders(),
        )
    }
}

export default MontagesApi