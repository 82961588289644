import React, { useEffect, useState } from 'react'
import './ImpedanceModal.scss'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import Electrode from '../../../components/head/Electrode'
import { GND_MAPPING_ITEM, REF_MAPPING_ITEM, STANDARD_MAPPING_NAMES } from './constants'
import AppContext from '../../../components/AppContext'
import MappingApi from '../../../services/MappingsApi'
import ToastUtils from '../../../utils/ToastUtils'
import StudyEvent from '../../../types/StudyEvent'
import MappingItem from '../../../types/MappingItem'
import Head from '../../../components/head/Head'

interface Props {
    event: StudyEvent
    toggle: () => void
}

const ImpedanceModal = (props: Props) => {
    const ctx = React.useContext(AppContext)

    const [isShowingLabels, setIsShowingLabels] = useState(false)
    const [mappingItems, setMappingItems] = useState<MappingItem[]>([])

    const headWidth = 500

    const recordingIndex = props.event.RecordingIndex
    const studyId = ctx.Study.ID
    useEffect(() => {
        MappingApi.getMappingItemsByRecording(studyId, recordingIndex).then(res => {
            if (res.data.length > 0 && res.data[0].Harness.ID === 1) {
                // If using rendr amp, we must add Gnd and Ref to mapping items
                res.data.push(REF_MAPPING_ITEM, GND_MAPPING_ITEM)
            }
            setMappingItems(res.data)
        }).catch(err => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to get mapping items' })
        })
    }, [recordingIndex, studyId])

    const [thresholds, measurementStr] = props.event.Value.split('|')
    const [lowerThresholdStr, upperThresholdStr] = thresholds.split(',')
    const lowerThreshold = parseInt(lowerThresholdStr, 10) / 1000
    const upperThreshold = parseInt(upperThresholdStr, 10) / 1000
    const measurements = measurementStr.split(',')

    const renderElectrode = (item: MappingItem) => {
        if (mappingItems.length === 0) {
            return null
        }

        const measurement = measurements.find(m => m.split(':')[0] === item.Electrode.Name)
        if (!measurement) throw Error(`StudyEvent.Value is missing measurement for ${item.Electrode.Name}`)

        const isCustomElectrode = !STANDARD_MAPPING_NAMES.includes(item.Name)

        return (
            <Electrode
                key={item.Electrode.ID}
                position={isCustomElectrode ? item.Electrode.Name : item.Name}
                measurement={measurement}
                mappingName={item.Name}
                showLabels={isShowingLabels}
                lowerThreshold={lowerThreshold}
                upperThreshold={upperThreshold}
                electrodeWidth={35}
                headImageWidth={headWidth}
            />
        )
    }

    const customElectrodes = mappingItems
        .filter(mappingItem => !STANDARD_MAPPING_NAMES.includes(mappingItem.Name) && mappingItem.InputTypeID === 1)
        .map(renderElectrode)

    const standardElectrodes = mappingItems
        .filter(mappingItem => STANDARD_MAPPING_NAMES.includes(mappingItem.Name))
        .map(renderElectrode)

    return (
        <Modal
            className="impedance-modal modal-fullscreen"
            isOpen
            toggle={props.toggle}
        >
            <ModalHeader toggle={props.toggle}>Impedance</ModalHeader>
            <ModalBody className="body">
                <div className="impedance-body text-center">
                    <p className="right-note">Measurements in kΩ</p>
                    <p className="right-note">Lower threshold: {lowerThreshold} kΩ</p>
                    <p className="right-note">Upper threshold: {upperThreshold} kΩ</p>
                    <p className="show-labels">
                        <input
                            type="checkbox"
                            onClick={() => setIsShowingLabels(v => !v)}
                        />
                        Show labels
                    </p>
                    <div className="electrodes-container">
                        {customElectrodes}
                        <div className="primary-electrodes-container">
                            <Head width={headWidth}>
                                {standardElectrodes}
                            </Head>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default ImpedanceModal
