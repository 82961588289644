import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import React from 'react'
import Slider from 'rc-slider'

interface Props {
    playSpeed: number
    maxPlaySpeed: number
    onChange: (speed: number) => void
}

const SpeedControl: React.FC<Props> = props => {
    return (
        <Slider
            vertical
            min={0}
            max={props.maxPlaySpeed}
            step={0.1}
            value={props.playSpeed}
            onChange={props.onChange}
        />
    )
}

export default SpeedControl
