import React, { useEffect, useRef, useState } from 'react'
import MontageChannel from '../../types/MontageChannel'
import { getChannelFormulaViolation, normalizeChannelFormulaCase } from '../../utils/StringUtils'
import ToastUtils from '../../utils/ToastUtils'

interface Props {
    selected: boolean
    channel: MontageChannel
    afterDividerCnt: number
    beforeDividerCnt: number
    onChange: (formula: string) => void
    onDelete: () => void
    onClick: () => void
    onDragDividerStart: (group: number) => void
    onDragChannelStart: (channelId: string) => void
    onDragOverChannel: (e: React.DragEvent<HTMLDivElement>) => void
    onDragOverDivider: (e: React.DragEvent<HTMLDivElement>) => void
    onRemoveDivider: (n: number) => void
}

function MontageChannelListItem(props: Props) {
    const [formula, setFormula] = useState(props.channel.Formula)
    const container = useRef<HTMLInputElement | null>(null)

    const selected = props.selected
    const sort = props.channel.Sort
    const group = props.channel.Group
    useEffect(() => {
        const el = container.current
        if (!el) return
        if (selected) {
            el.focus()
            el.scrollIntoView({ block: 'center', behavior: 'smooth' })
        } else {
            el.blur()
        }
    }, [selected, sort, group])

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            container.current?.blur()
        }
    }

    const afterDividers = []
    for (let i = 0; i < props.afterDividerCnt; i++) {
        afterDividers.push(i)
    }

    const beforeDividers = []
    for (let i = 0; i < props.beforeDividerCnt; i++) {
        beforeDividers.push(i)
    }

    const renderDivider = (group: number) => (
        <div
            key={group}
            id={`group-${group}`}
            draggable
            className="group"
            onDragStart={() => props.onDragDividerStart(group)}
            onDragOver={props.onDragOverDivider}
        >
            <i
                className="fas fa-grip-horizontal grab-handle"
            />
            <i
                className="fa fa-trash-alt action-icon"
                onClick={() => props.onRemoveDivider(group)}
            />
        </div>
    )

    const handleBlur = () => {
        const violation = getChannelFormulaViolation(formula)
        if (violation) {
            if (!ToastUtils.isOpen(new RegExp(violation))) {
                ToastUtils.error({ message: violation, duration: 2000 })
            }
            container.current?.focus()
            return
        }
        const normalizedFormula = normalizeChannelFormulaCase(formula)
        if (formula.toLowerCase() === normalizedFormula.toLowerCase()) {
            setFormula(normalizedFormula)
        }
        props.onChange(normalizedFormula)
    }

    return (
        <>
            {beforeDividers.reverse().map(i => renderDivider(props.channel.Group - i - 1))}
            <div
                draggable
                id={props.channel.ID}
                className={`list-item ${props.selected ? 'selected' : ''}`}
                onDragOver={props.onDragOverChannel}
                onDragStart={() => props.onDragChannelStart(props.channel.ID)}
            >
                <i
                    className="fas fa-grip-horizontal grab-handle"
                />
                <input
                    ref={container}
                    value={formula}
                    className="channel-formula"
                    onClick={props.onClick}
                    onChange={e => setFormula(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                />
                <i
                    className="fa fa-trash-alt action-icon"
                    onClick={props.onDelete}
                />
            </div>
            {afterDividers.map(i => renderDivider(props.channel.Group + i))}
        </>
    )
}

export default MontageChannelListItem
