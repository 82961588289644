import React, { useEffect, useState } from 'react'
import './Diagnostics.scss'
import DataSource from './DataSource/DataSource'
import DiagnosticEvent from '../../../types/DiagnosticEvent'

interface Props {
    graphHeight: number
    dataSource: DataSource
    onClose: () => void
}

interface CombinedEvents {
    [key: string]: {
        durationMs: number
        occurrences: number
    }
}

type IconColor = 'red' | 'orange'

const START_RECORDING_MESSAGE = JSON.stringify({ Message: 'StartRecordingEvents', Body: {} })
const STOP_RECORDING_MESSAGE = JSON.stringify({ Message: 'StopRecordingEvents', Body: {} })
const REQUEST_START_EVENT_NAME = 'CustomDataRequest'

const Diagnostics: React.FC<Props> = props => {
    const { dataSource, dataSource: { dataWebsocket } } = props
    const [recordingState, setRecordingState] = useState({
        isRecording: false,
        isWaitingOnData: false,
    })
    const [recordingIconColor, setRecordingIconColor] = useState<IconColor>('red')
    const [events, setEvents] = useState<DiagnosticEvent[]>([])

    useEffect(() => {
        dataSource.setEventRecordingProcessor((e: DiagnosticEvent[]) => {
            setEvents(e)
            setRecordingState({ isRecording: false, isWaitingOnData: false })
        })
        return () => dataSource.setEventRecordingProcessor(null)
    }, [dataWebsocket, dataSource])

    const { isRecording } = recordingState

    useEffect(() => {
        if (isRecording) {
            const cb = () => setRecordingIconColor(s => s === 'red' ? 'orange' : 'red')
            cb()
            const interval = setInterval(cb, 500)
            return () => clearInterval(interval)
        } else {
            setRecordingIconColor('red')
        }
    }, [isRecording])

    const handleRecordingButtonClick = () => {
        if (isRecording) {
            setRecordingState({ isRecording: false, isWaitingOnData: true })
            dataWebsocket?.send(STOP_RECORDING_MESSAGE)
        } else if (!recordingState.isWaitingOnData) {
            setEvents([])
            setRecordingState({ isRecording: true, isWaitingOnData: false })
            dataWebsocket?.send(START_RECORDING_MESSAGE)
        }
    }

    const combinedEvents: CombinedEvents = {}
    for (const event of events) {
        if (combinedEvents[event.EventName]) {
            combinedEvents[event.EventName].durationMs += event.DurationMs
            combinedEvents[event.EventName].occurrences += event.Occurrences
        } else {
            combinedEvents[event.EventName] = {
                durationMs: event.DurationMs,
                occurrences: event.Occurrences,
            }
        }
    }
    const combinedEventsSorter = (a: string, b: string): number => {
        if (combinedEvents[a].durationMs < combinedEvents[b].durationMs) return 1
        if (combinedEvents[b].durationMs < combinedEvents[a].durationMs) return -1
        return 0
    }

    const getTextWhenNoEvents = () => {
        if (isRecording) return 'Recording events...'
        if (recordingState.isWaitingOnData) return 'Waiting on diagnostic data...'
        return 'No diagnostic events'
    }

    return (
        <div className="diagnostics">
            <i
                title="Close diagnostics"
                className="close-icon fas fa-times"
                onClick={props.onClose}
            />
            <h3>
                <span className="record-icon-container">
                    <i
                        title={isRecording ? 'Stop recording' : 'Start recording'}
                        className={`
                            record-icon
                            fas
                            fa-circle
                            ${recordingIconColor}
                            ${recordingState.isWaitingOnData ? 'disabled' : ''}
                        `}
                        onClick={handleRecordingButtonClick}
                    />
                </span>
                Diagnostics
            </h3>
            <div className="events" style={{ maxHeight: props.graphHeight - 145 }}>
                {events.length === 0 ? (
                    <p>{getTextWhenNoEvents()}</p>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                <th>Process</th>
                                <th>Total</th>
                                <th>Occurrences</th>
                                <th>Average</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(combinedEvents).sort(combinedEventsSorter).map(key => {
                                if (key === REQUEST_START_EVENT_NAME) return null
                                const durationMs = combinedEvents[key].durationMs
                                const occurrences = combinedEvents[key].occurrences
                                const average = Math.round(durationMs / occurrences)

                                return (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td>{durationMs.toLocaleString()} ms</td>
                                        <td>{occurrences.toLocaleString()}</td>
                                        <td>{average.toLocaleString()} ms</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
}

export default Diagnostics