import * as EVENTS from '../constants/studyevents'
import GraphUtilities from '../pages/studies/utils/GraphUtilities'

function getStart(event) {
    return event.RecordingIndex
}

function getMontageChange(event) {
    const parts = event.Value.split(':')
    return parts[1]
}

function getPhoticStim(event) {
    return `${event.Value} Hz`
}

function getNote(event) {
    return `${event.Comment || ''}`
}

function getHighFrequencyFilterChange(event) {
    const parts = event.Value.split(':')
    return `${parts[0]}Hz`
}

function getLowFrequencyFilterChange(event) {
    const parts = event.Value.split(':')
    return `${parts[0]}sec`
}

function getNotchFilterChange(event) {
    const parts = event.Value.split(':')
    return `${parts[0]}Hz`
}

function getNotchToggle(event) {
    const parts = event.Value.split(':')
    if (parts[0] === 'True') {
        return 'On'
    }
    return 'Off'
}

function getSensitivityChange(event) {
    const parts = event.Value.split(':')
    return GraphUtilities.formatSensitivity(parts[0])
}

function getTimebaseChange(event) {
    const parts = event.Value.split(':')
    return `${parts[0]} sec`
}

function getManualPulse(event) {
    return event.Value
}

function getMeasurement(event) {
    return event.Value.split(':')
}

function getInitialFilter(event) {
    const parts = event.Value.split(':')
    return `LFF: ${parts[0]}sec HFF: ${parts[1]}Hz`
}

function getInitialNotch(event) {
    const parts = event.Value.split(':')

    let notch_value
    if (parts[1] === '1') {
        notch_value = 'On'
    } else {
        notch_value = 'Off'
    }

    return `${parts[0]}Hz ${notch_value}`
}

const StudyEventUtils = {
    getFriendlyTitle(event) {
        switch (event.EventType.ID) {
            case EVENTS.START:
                return 'Start Recording'
            case EVENTS.STOP:
                return 'Stop Recording'
            case EVENTS.HIGH_FREQUENCY_FILTER_CHANGE:
                return 'High Freq Filter Change'
            case EVENTS.LOW_FREQUENCY_FILTER_CHANGE:
                return 'Low Freq Filter Change'
            case EVENTS.NOTCH_FILTER_CHANGE:
                return 'Notch Filter Change'
            case EVENTS.TRACKIT_CONNECT:
                return 'PC connect to amp'
            case EVENTS.TRACKIT_DISCONNECT:
                return 'PC disconnect from amp'
            default:
                return EVENTS.EFN[event.EventType.ID] || 'Event'
        }
    },

    getFriendlyValue(event) {
        switch (event.EventType.ID) {
            case EVENTS.START:
                return getStart(event)
            case EVENTS.MONTAGE_CHANGE:
                return getMontageChange(event)
            case EVENTS.HIGH_FREQUENCY_FILTER_CHANGE:
                return getHighFrequencyFilterChange(event)
            case EVENTS.LOW_FREQUENCY_FILTER_CHANGE:
                return getLowFrequencyFilterChange(event)
            case EVENTS.NOTCH_FILTER_CHANGE:
                return getNotchFilterChange(event)
            case EVENTS.NOTCH_TOGGLE:
                return getNotchToggle(event)
            case EVENTS.SENSITIVITY_CHANGE:
                return getSensitivityChange(event)
            case EVENTS.TIMEBASE_CHANGE:
                return getTimebaseChange(event)
            case EVENTS.MANUAL_PULSE:
                return getManualPulse(event)
            case EVENTS.PHOTIC_STIM:
                return getPhoticStim(event)
            case EVENTS.NOTE:
                return getNote(event)
            case EVENTS.MEASUREMENT:
                return getMeasurement(event)
            case EVENTS.INITIAL_FILTER_VALUES:
                return getInitialFilter(event)
            case EVENTS.INITIAL_NOTCH:
                return getInitialNotch(event)
            case EVENTS.NORMAL_MODE:
                return 'EEG restarted'
            case EVENTS.CALIBRATE_MODE:
                return 'Calibration mode started'
            case EVENTS.IMPEDANCE_MODE:
                return 'Impedance mode started'
            case EVENTS.IMPEDANCE_MEASUREMENT:
                return 'Measurement'
            default:
                return ''
        }
    },

    getFormattedMeasurementTime(t) {
        return `${parseFloat(t).toFixed(3)} s`
    },

    getFormattedMeasurementFreq(f) {
        return `${parseFloat(f).toFixed(3)} Hz`
    },

    getFormattedMeasurementVolt(v, abs = false) {
        let volt = parseFloat(v)
        if (abs) {
            volt = Math.abs(volt)
        }
        return `${Math.round(volt * 1e6)} μV`
    },

    getStudyEventText(studyEvent) {
        if (studyEvent.Comment) {
            const trimmed = studyEvent.Comment.trim()

            if (trimmed.length > 50) {
                return `${trimmed.substring(0, 47)}...`
            }

            return trimmed
        }

        return EVENTS.EFN[studyEvent.EventType.ID]
    },
}

export default StudyEventUtils
