import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { TEN_TWENTY_ELECTRODES } from '../../constants/tentwentyelectrodes'
import FileHeaderParser from '../../services/FileHeaderParser'
import ChannelTypeGuesser from '../../services/ChannelTypeGuesser'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { NONIN, PHOTIC, SAMPLECOUNTER, EVENT } from '../studies/eegMonitor/constants'
import Violations, { ViolationMessage, ViolationType } from './Violations'
import { Link } from 'react-router-dom'
import validateTvsFile from './validateTvsFile'
import FileUtils from '../../utils/FileUtils'
import md5 from 'blueimp-md5'
import { Button } from '@mui/material'

const MAX_DB_CHANNELS_SUPPORTED = 32
const TRACKIT_PLUS_SAMPLE_CTR_CH_NAME = 'Sample-Gnd'
const TRACKIT_PLUS_EV_CH_NAME = 'Event-Gnd'
const RENDR_ACQ_SAMPLE_CTR_CH_NAME = 'SampleCounter'
const RENDR_ACQ_EV_CH_NAME = 'Event'
const SYSTEM_CH_NAMES = [TRACKIT_PLUS_EV_CH_NAME, TRACKIT_PLUS_SAMPLE_CTR_CH_NAME, RENDR_ACQ_EV_CH_NAME, RENDR_ACQ_SAMPLE_CTR_CH_NAME]

const FILE_NAME_SORTER = (a: File, b: File) => {
    const aNumber = extract83FormatNumberSuffix(a.name)
    const bNumber = extract83FormatNumberSuffix(b.name)
    if (aNumber || bNumber) {
        // file ends in _<number>.EXT
        // sort by number
        return aNumber > bNumber ? 1 : aNumber < bNumber ? -1 : 0
    }

    // sort alphabetically
    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
}

interface FileBatch {
    dataFiles: File[]
    tevFiles: File[]
    tvxFile: File | null
    vidFiles: File[]
    tvsFiles: File[]
}

const emptyFileBatch = (): FileBatch => ({
    dataFiles: [],
    tevFiles: [],
    tvxFile: null,
    vidFiles: [],
    tvsFiles: [],
})

const getMoment = (date: string, time: string) => moment(`${date} ${time}`, 'DD.MM.YY hh.mm.ss')

const doHeadersMatch = (dataFiles: File[], eventFiles: File[]) => {
    const promise = async (resolve: (value?: boolean) => void, reject: (reason?: unknown) => void) => {
        for (let i = 0; i < dataFiles.length; i += 1) {
            const dataFile = dataFiles[i]
            const eventFile = eventFiles[i]
            try {
                const dataHeaderRaw = await FileUtils.read(dataFile, 256)
                const eventsHeaderRaw = await FileUtils.read(eventFile, 256)
                if (dataHeaderRaw.slice(8, 168) !== eventsHeaderRaw.slice(8, 168)) {
                    resolve(false)
                    return
                }
                const dataHeaderStartTime = moment(dataHeaderRaw.slice(168, 184), 'DD.MM.YYHH.mm.ss')
                const eventsHeaderStartTime = moment(eventsHeaderRaw.slice(168, 184), 'DD.MM.YYHH.mm.ss')
                if (Math.abs(dataHeaderStartTime.diff(eventsHeaderStartTime, 'seconds')) > 30) {
                    resolve(false)
                    return
                }
            } catch (err) {
                reject(err)
                return
            }
        }
        resolve(true)
    }
    return new Promise(promise)
}

const getPatientIdFromDataFile = async (file: File): Promise<string> => {
    const data = await FileUtils.read(file, 1e5)
    const parser = new FileHeaderParser(data)
    return parser.getString(8, 80)
}

const getRecordIdFromDataFile = async (file: File): Promise<string> => {
    const data = await FileUtils.read(file, 1e5)
    const parser = new FileHeaderParser(data)
    return parser.getString(88, 80)
}

const getHeader = async (file: File, violations: Violations) => {
    const data = await FileUtils.read(file, 1e5)
    const parser = new FileHeaderParser(data)
    const signalCount = parser.getInteger(252, 8)
    
    parser.setCursor(256)
    const labels = parser.scanStrings(signalCount, 16)
    const channelsNotCounted = labels.filter(l => SYSTEM_CH_NAMES.includes(l)).length
    if (signalCount - channelsNotCounted > MAX_DB_CHANNELS_SUPPORTED) {
        violations.add(ViolationType.DataFiles, ViolationMessage.MaxChannelCountExceeded, signalCount, MAX_DB_CHANNELS_SUPPORTED)
    }

    const transducerTypes = parser.scanStrings(signalCount, 80)
    const physicalDimensions = parser.scanStrings(signalCount, 8)
    const physicalMinimums = parser.scanIntegers(signalCount, 8)
    const physicalMaximums = parser.scanIntegers(signalCount, 8)
    const digitalMinimums = parser.scanIntegers(signalCount, 8)
    const digitalMaximums = parser.scanIntegers(signalCount, 8)
    const prefilters = parser.scanStrings(signalCount, 80)
    const samplesPerDataRecords = parser.scanIntegers(signalCount, 8)
    for (const samplesPerDataRecord of samplesPerDataRecords) {
        if (samplesPerDataRecord !== samplesPerDataRecords[0]) {
            violations.add(ViolationType.DataFiles, ViolationMessage.MixedSampleRates, samplesPerDataRecord, samplesPerDataRecords[0])
            break
        }
    }
    const reserveds = parser.scanStrings(signalCount, 32)

    return {
        signalCount,
        transducerTypes,
        physicalDimensions,
        physicalMinimums,
        physicalMaximums,
        digitalMinimums,
        digitalMaximums,
        prefilters,
        samplesPerDataRecords,
        reserveds,
        labels,
        dataVersionFormat: parser.getString(0, 8),
        patientID: parser.getString(8, 80),
        recordID: parser.getString(88, 80),
        startDate: parser.getString(168, 8),
        startTime: parser.getString(176, 8),
        headerByteCount: parser.getInteger(184, 8),
        reserved: parser.getString(192, 44),
        dataRecordCount: parser.getInteger(236, 8),
        dataRecordDuration: parser.getFloat(244, 8),
    }
}

const findCorrespondingFile = (reference: File, files: File[]): File | undefined => {
    const match = files.find(f => {
        if (f.name.length !== reference.name.length) {
            return false
        }
        const fileName = f.name.split('.')[0].toUpperCase()
        const referenceFileName = reference.name.split('.')[0].toUpperCase()
        return fileName === referenceFileName
    })
    return match
}

const getFirmwareVersionJSON = (header: any) => {
    const startDate = header.startDate
    const reservedStr = header.reserved
    // The reserved section of the header optionally starts with '24BIT' (BDF) or 'BIOSEMI' (EDF).
    // The parser that returns the header fields skips blank spaces, so if idCode is present,
    // the version string starts at offset 8 (or offset 0 when id code is not present)
    const idCode = reservedStr.substring(0,5)
    const nSkip8 = idCode === '24BIT' || idCode === 'BIOSE' ? 8 : 0
    const versionSection = reservedStr.substring(nSkip8)
    let retJSON = null

    // Is there a version and checksum?
    // count the versions parts separated by colon (4 version parts plus checksum)
    const versionParts = versionSection.split(':')
    if (versionParts.length === 5) {

        const lastIdx = versionSection.lastIndexOf(':') + 1
        const versionStr = versionSection.substring(0, lastIdx)
        const sCompleteCRCStr = versionStr+ startDate.substring(0,2) + startDate.substring(3,5) + startDate.substring(6,8)

        const strLen = sCompleteCRCStr.length
        let sum = 0
        for (let i=0; i < strLen; i++) {
            sum += Number(sCompleteCRCStr.charCodeAt(i))
        }
        const calcCRC = (0x00FF & sum).toString(16)

        retJSON = {
            'amp_serial' : versionParts[0],
            'amp_type' : versionParts[1],
            'amp_fw' : versionParts[2],
            'trackit_sw' : versionParts[3],
            'crc' : versionParts[4],
            'crc_valid': versionParts[4].toUpperCase() === calcCRC.toUpperCase(),
        }
    }
    return retJSON
}

const getDataHeaderFieldInconsistency = (dataFiles: File[]) => {
    const promise = async (resolve: (value?: string) => void, reject: (reason?: unknown) => void) => {
        const firstHeader = await getHeader(dataFiles[0], new Violations())

        for (let i = 0; i < dataFiles.length; i += 1) {
            const dataFile = dataFiles[i]
            try {
                const header = await getHeader(dataFile, new Violations())
                if (header.signalCount !== firstHeader.signalCount) resolve('Signal Count')
                if (header.patientID !== firstHeader.patientID) resolve('Patient ID')
                if (header.recordID !== firstHeader.recordID) resolve('Record ID')
                if (header.dataVersionFormat !== firstHeader.dataVersionFormat) resolve('Data Version Format')
                if (header.dataRecordDuration !== firstHeader.dataRecordDuration) resolve('Data Record Duration')
                if (header.samplesPerDataRecords.join(',') !== firstHeader.samplesPerDataRecords.join(',')) resolve('Samples Per Data Record')
            } catch (err) {
                reject(err)
                return
            }
        }
        resolve('')
    }
    return new Promise(promise)
}

/*
 * 8.3 File name format
 *
 * The file name must include an extension that is 3 characters long.
 * The portion before the extension can not exceed 8 characters.
 *
 * See https://en.wikipedia.org/wiki/8.3_filename for more information.
 */
const transformTo83Format = (fileName: string, order: number) => {
    const extension = fileName.split('.').pop()
    if (!extension) throw Error('filename must have extension')
    fileName = fileName.replace(`.${extension}`, '')
    const suffixLength = order.toString().length + 1
    fileName = fileName.slice(0, 8 - suffixLength)
    return `${fileName}_${order}.${extension}`
}

const extract83FormatNumberSuffix = (name: string) => {
    const matches = name.match(/_([0-9]+)\.(bdf|tev|tvx)$/i)
    if (matches && matches.length > 1) {
        return parseInt(matches[1])
    }
    return 0
}

const validateCspHash = async (files: File[], violations: Violations): Promise<void> => {
    const vidFiles = files.filter(f => /.*?\.mp4/i.test(f.name))
    const dataFiles = files.filter(f => /.*?\.(tev|bdf|edf)/i.test(f.name))
    const sortedFiles = [...vidFiles, ...dataFiles]
        .sort((a, b) => a.size > b.size ? 1 : a.size < b.size ? -1 : 0)

    const portalHashInput = sortedFiles.map(f => f.size.toString()).join('-')
    const portalHash = md5(portalHashInput).toLowerCase()

    const validationFile = files.find(f => f.name === 'validation.csp')
    if (!validationFile) {
        violations.add(ViolationType.Other, ViolationMessage.InvalidCspPkg, 'Missing file \'validation.csp\'')
        return
    }
    const hashFileText = await validationFile.text()
    const validationFileObject = JSON.parse(hashFileText)
    const validationFileHash = validationFileObject.hash.toLowerCase()

    if (portalHash !== validationFileHash) {
        console.log(`[CSP] portal: ${portalHashInput} => ${portalHash}`)
        console.log(`[CSP] validation file: ${validationFileObject.files} => ${validationFileHash}`)
        const expectedFileCnt = validationFileObject.files.split('-').length
        const actualFileCnt = sortedFiles.length
        if (expectedFileCnt !== actualFileCnt) {
            const info = `Expected ${expectedFileCnt} file${expectedFileCnt === 1 ? '' : 's'}, found ${actualFileCnt}.`
            violations.add(ViolationType.Other, ViolationMessage.InvalidCspPkg, info)
        } else {
            violations.add(ViolationType.Other, ViolationMessage.InvalidCspPkg, 'Invalid hash.')
        }
    }
}

interface CustomElectrode {
    Name: string
    ChannelTypeID: number
    Index: number
}

interface Props {
    onVerify: (files: FileBatch, studyInfo: any) => void
    supportedChannelTypes: { ID: number, Name: string }[]
    isCloudSyncPkg?: boolean
}

const ImportStudyFolderSelect: React.FC<Props> = props => {
    const [violations, setViolations] = useState(new Violations())
    const [hasFolderBeenSelected, setHasFolderBeenSelected] = useState(false)
    const [fileBatch, setFileBatch] = useState(emptyFileBatch())
    const [studyInfo, setStudyInfo] = useState<any>(null)

    const { onVerify } = props
    useEffect(() => {
        if (studyInfo && hasFolderBeenSelected && violations.isEmpty()) {
            onVerify(fileBatch, studyInfo)
        }
    }, [hasFolderBeenSelected, violations, onVerify, fileBatch, studyInfo])

    const channelTypeGuesser = new ChannelTypeGuesser(
        props.supportedChannelTypes,
    )

    const getCustomElectrodesGuess = (header: any): CustomElectrode[] => {
        const customElectrodes = []
        for (let i = 0; i < header.labels.length; i += 1) {
            const Name = header.labels[i].replace(/-.*$/g, '')
            if (!TEN_TWENTY_ELECTRODES.some(x => x.toUpperCase() === Name.toUpperCase())) {
                customElectrodes.push({
                    Name,
                    ChannelTypeID: channelTypeGuesser.guessID(Name),
                    Index: i,
                })
            }
        }
        return customElectrodes
    }

    const organizeSelectedFiles = async (files: File[], violations: Violations): Promise<FileBatch> => {
        const dataFiles = []
        const tevFiles = []
        let tvxFile = null
        const vidFiles = []
        const tvsFiles = []
        let cspFileCnt = 0
        for (const file of files) {
            const fileName = file.name.split('.').pop()
            if (!fileName) continue
            const extension = fileName.toLowerCase()
            switch (extension) {
                case 'csp':
                    cspFileCnt++
                    break
                case 'bdf':
                    for (const f of dataFiles) {
                        const fName = f.name.split('.').pop()
                        if (fName && fName.toLowerCase() === 'edf') {
                            violations.add(ViolationType.DataFiles, ViolationMessage.MixedDataFileTypes)
                        }
                    }
                    dataFiles.push(file)
                    break
                case 'edf':
                    if (dataFiles.length > 0) {
                        const fName = dataFiles[0].name.split('.').pop()
                        if (fName && fName.toLowerCase() === 'edf') {
                            violations.add(ViolationType.DataFiles, ViolationMessage.MultipleEDFFiles)
                        } else {
                            violations.add(ViolationType.DataFiles, ViolationMessage.MixedDataFileTypes)
                        }
                    }
                    dataFiles.push(file)
                    break
                case 'tev':
                    tevFiles.push(file)
                    break
                case 'tvx':
                    if (tvxFile) {
                        violations.add(ViolationType.EventsFiles, ViolationMessage.TooManyTVXFiles)
                        break
                    }
                    tvxFile = file
                    break
                case 'mp4':
                case 'avi':
                    if (vidFiles.length > 0) {
                        if (vidFiles[0].name.slice(-3) !== extension) {
                            violations.add(ViolationType.VideoFiles, ViolationMessage.MixedVideoFileTypes)
                        }
                    }
                    vidFiles.push(file)
                    break
                case 'tvs':
                    tvsFiles.push(file)
                    break
                default:
            // ignore unrelated files
            }
        }

        if (cspFileCnt > 1) {
            violations.add(ViolationType.Other, ViolationMessage.InvalidCspPkg, 'Multiple files with .csp extension found. Only one is allowed.')
        }
        if (tvsFiles.length > 0 && vidFiles.length === 0) {
            violations.add(ViolationType.VideoFiles, ViolationMessage.NoVideoFile)
        }
        if (!props.isCloudSyncPkg && tvsFiles.length !== vidFiles.length) {
            violations.add(ViolationType.VideoInfoFiles, ViolationMessage.ExtraTvsFilesFound)
        }
        if (dataFiles.length === 0) {
            violations.add(ViolationType.DataFiles, ViolationMessage.NoDataFile)
        }
        if (dataFiles.length > 0) {
            const dataFilePatientId = await getPatientIdFromDataFile(dataFiles[0])
            const dataFileRecordId = await getRecordIdFromDataFile(dataFiles[0])
            for (const f of tvsFiles) {
                await validateTvsFile(violations, f, dataFilePatientId, dataFileRecordId)
            }
        }
        if (tevFiles.length === 0) {
            violations.add(ViolationType.EventsFiles, ViolationMessage.NoEventsFile)
        }
        dataFiles.sort(FILE_NAME_SORTER)
        tevFiles.sort(FILE_NAME_SORTER)
        for (const tevFile of tevFiles) {
            if ((tevFile.size - 768) % 4 !== 0) {
                const msg = 'TEV file body size is not divisible by 4'
                violations.add(ViolationType.EventsFiles, ViolationMessage.InvalidFile, tevFile.name, msg)
            }
        }
        if (dataFiles.length > 0) {
            if (dataFiles.length !== tevFiles.length) {
                violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTEVFileCount)
            } else {
                for (const dataFile of dataFiles) {
                    const correspondingTevFile = findCorrespondingFile(dataFile, tevFiles)
                    if (!correspondingTevFile) {
                        violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTEVFileName)
                        break
                    }
                }
                if (tvxFile && tvxFile.name.split('.')[0].toUpperCase() !== dataFiles[0].name.split('.')[0].toUpperCase()) {
                    violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTVXFileName)
                }
            }
        }
        if (violations.dataFiles.length === 0) {
            const inconsistency = await getDataHeaderFieldInconsistency(dataFiles)
            if (inconsistency) {
                violations.add(ViolationType.DataFiles, ViolationMessage.DataHeaderMismatch, inconsistency)
            }
        }
        if (violations.dataFiles.length === 0 && violations.eventsFiles.length === 0) {
            if (tevFiles.length > 0 && !(await doHeadersMatch(dataFiles, tevFiles))) {
                violations.add(ViolationType.EventsFiles, ViolationMessage.HeaderMismatch)
            }
            if (tvxFile && !(await doHeadersMatch([dataFiles[0]], [tvxFile]))) {
                violations.add(ViolationType.EventsFiles, ViolationMessage.HeaderMismatch)
            }
            if (tvxFile) {
                // Check if header is too short
                const tvxHeader = await FileUtils.read(tvxFile, 6912)
                const ascii = /^[ -~\t\n\r]+$/
                if (!ascii.test(tvxHeader)) {
                    violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTVXFileHeaderLength)
                }

                // Check if header is too long
                const tvxOverflowDataSample = await FileUtils.read(tvxFile, 16, 6912)
                if (ascii.test(tvxOverflowDataSample)) {
                    violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTVXFileHeaderLength)
                }

                // Validate that the value specified in the header for the size of header is 6912, the correct TVX header size
                let tvxHeaderSizeValue = await FileUtils.read(tvxFile, 8, 184)
                tvxHeaderSizeValue = tvxHeaderSizeValue.trim()
                if (tvxHeaderSizeValue !== '6912') {
                    violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTVXFileHeaderLengthValue)
                }

                // Validate file size
                if ((tvxFile.size - 6912) % 6 !== 0) {
                    violations.add(ViolationType.EventsFiles, ViolationMessage.IncorrectTVXFileBodyLength)
                }
            }
        }
        if (!props.isCloudSyncPkg) {
            for (const videoFile of vidFiles) {
                const correspondingVideoInfoFile = findCorrespondingFile(videoFile, tvsFiles)
                if (!correspondingVideoInfoFile) {
                    violations.add(ViolationType.VideoInfoFiles, ViolationMessage.IncorrectTVSFileName)
                    break
                }
            }
        }

        if (dataFiles.length > 1 && violations.dataFiles.length === 0) {
            const firstBdfFile = dataFiles.find(f => !/_[0-9]+\.bdf$/i.test(f.name) && /\.bdf$/i.test(f.name))
            if (!firstBdfFile) {
                violations.add(ViolationType.DataFiles, ViolationMessage.MissingDataFile, 'first BDF file')
            } else {
                for (let i = 1; i < dataFiles.length; i += 1) {
                    const expectedFileName = transformTo83Format(firstBdfFile.name, i)
                    if (dataFiles[i].name !== expectedFileName) {
                        violations.add(ViolationType.DataFiles, ViolationMessage.MissingDataFile, expectedFileName)
                        break
                    }
                }
            }
        }
        return { dataFiles, tevFiles, tvxFile, vidFiles, tvsFiles }
    }

    const handleFolderSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const violations = new Violations()
        setHasFolderBeenSelected(true)
        if (!e.target.files) return
        const fileArray = Array.from(e.target.files)
        if (props.isCloudSyncPkg) {
            await validateCspHash(fileArray, violations)
        }
        const files = await organizeSelectedFiles(fileArray, violations)
        setFileBatch(files)

        if (files.dataFiles.length === 0) {
            setViolations(violations)
            return
        }

        const headers = []
        for (const dataFile of files.dataFiles) {
            headers.push(await getHeader(dataFile, violations))
        }
        const header = headers[0]
        const start = getMoment(header.startDate, header.startTime)
        const customElectrodes = getCustomElectrodesGuess(header)
        let dataFileFormat = 'none'
        const dataFileExt = files.dataFiles[0].name.split('.').pop()
        if (dataFileExt) dataFileFormat = dataFileExt.toUpperCase()

        let videoFileFormat: string | null = null
        if (files.vidFiles.length > 0) {
            const videoFileExt = files.vidFiles[0].name.split('.').pop()
            if (videoFileExt) videoFileFormat = videoFileExt.toUpperCase()
        }
        let photicIndex: number | null = null
        for (let i = 0; i < customElectrodes.length; i += 1) {
            if (customElectrodes[i].ChannelTypeID === PHOTIC) {
                if (photicIndex !== null) {
                    violations.add(ViolationType.DataFiles, ViolationMessage.MultiplePhoticChannels)
                    break
                }
                photicIndex = customElectrodes[i].Index
            }
        }
        const ignoredChannels = [PHOTIC, EVENT, NONIN, SAMPLECOUNTER]
        const filteredCustomElectrodes = customElectrodes.filter(e => !ignoredChannels.includes(e.ChannelTypeID))
        const duration = headers.reduce((a, b) => {
            const d = 1000 * b.dataRecordCount * b.dataRecordDuration
            return a + d
        }, 0)
      
        const studyInfo = {
            patientId: props.isCloudSyncPkg ? null : header.patientID,
            recordId: header.recordID,
            duration: DateTimeUtils.getStudyDuration(duration),
            startDateTime: start.format('MMM D, YYYY h:mm a'),
            sampleRate: header.samplesPerDataRecords[0] / header.dataRecordDuration,
            channelCount: header.signalCount,
            videoCount: files.vidFiles.length,
            customElectrodes: filteredCustomElectrodes,
            photicIndex,
            showFileSizeWarning: false,
            ampVersionJSON: getFirmwareVersionJSON(header),
            dataFileFormat,
            videoFileFormat,
        }
        setViolations(violations)
        setStudyInfo(studyInfo)
    }

    const renderInfoIcon = (violationMsgs: ViolationMessage[]) => {
        return (
            <i className={`
            icon
            pull-right
            ${!hasFolderBeenSelected ? 'icon-electrotek-info-circle' :
                violationMsgs.length ? 'text-danger icon-electrotek-cross' :
                    'text-success icon-electrotek-check'}
        `} />
        )
    }

    return (
        <React.Fragment>
            <div className="folder-selection-feedback">
                <div className="category">
                    {renderInfoIcon(violations.dataFiles)}
                    <i className="icon icon-left icon-electrotek-file-empty" />
                    <span className="name">EEG Data {hasFolderBeenSelected ? `(${fileBatch.dataFiles.length})` : null}</span>
                    <p className={violations.dataFiles.length ? 'text-danger' : ''}>
                        {violations.dataFiles.length ? (
                            violations.dataFiles.map(v => (
                                <span key={v}>{v}<br /></span>
                            ))
                        ) : 'A single .EDF file or one or more .BDF files'}
                    </p>
                </div>
                <div className="category">
                    {renderInfoIcon(violations.eventsFiles)}
                    <i className="icon icon-left icon-electrotek-file-empty" />
                    <span className="name">Events {hasFolderBeenSelected ? `(${fileBatch.tevFiles.length})` : null}</span>
                    <p className={violations.eventsFiles.length ? 'text-danger' : ''}>
                        {violations.eventsFiles.length ? violations.eventsFiles.join(' ') : 'One .TEV / .TVX file for each EEG data file'}
                    </p>
                </div>
                <div className="category">
                    {renderInfoIcon(violations.videoFiles)}
                    <i className="icon icon-left icon-electrotek-file-empty" />
                    <span className="name">Videos {hasFolderBeenSelected ? `(${fileBatch.vidFiles.length})` : null}</span>
                    <p className={violations.videoFiles.length ? 'text-danger' : ''}>
                        {violations.videoFiles.length ? violations.videoFiles.join(' ') : 'Optional .MP4 / .AVI video files'}
                    </p>
                </div>
                {props.isCloudSyncPkg ? (
                    <div className="category">
                        {renderInfoIcon(violations.other)}
                        <i className="icon icon-left icon-electrotek-file-empty" />
                        <span className="name">Cloud Sync Package Validation</span>
                        <p className={violations.other.length ? 'text-danger' : ''}>
                            {violations.other.length ? violations.other.join(' ') : 'Check that all the right files are present'}
                        </p>
                    </div>
                ) : (
                    <div className="category">
                        {renderInfoIcon(violations.videoInfoFiles)}
                        <i className="icon icon-left icon-electrotek-file-empty" />
                        <span className="name">Video Info {hasFolderBeenSelected ? `(${fileBatch.tvsFiles.length})` : null}</span>
                        <p className={violations.videoInfoFiles.length ? 'text-danger' : ''}>
                            {violations.videoInfoFiles.length ? violations.videoInfoFiles.join(' ') : 'One .TVS file for each video file'}
                        </p>
                    </div>
                )}
            </div>
            {hasFolderBeenSelected && !violations.isEmpty() && (
                <p>If you have any questions, please <Link to="/help">contact support</Link>.</p>
            )}
            {(!hasFolderBeenSelected || !violations.isEmpty()) && (
                <Button component="label" htmlFor="input" sx={{ mb: 2 }}>
                    Select Folder
                </Button>
            )}
            <input
                ref={(e: any) => {
                    if (e) {
                        e.webkitdirectory = true
                        e.mozdirectory = true
                    }
                }}
                id="input"
                style={{ display: 'none' }}
                type="file"
                onChange={handleFolderSelect}
            />
        </React.Fragment>
    )
}

export default ImportStudyFolderSelect
