import React, { Component } from 'react'
import { zxcvbn } from '../utils/PasswordUtils'
import { Link, Redirect } from 'react-router-dom'
import InputGroup from './forms/InputGroup'
import ErrorMessage from './messaging/ErrorMessage'
import LoadingSpinner from './LoadingSpinner'
import FormFieldManager from '../utils/FormFieldManager'
import AccountApi from '../services/AccountApi'
import { Button } from '@mui/material'

class PasswordReset extends Component {
    formManager = null

    state = {
        is_saving: false,
        is_loading: true,
        error: '',
        has_error: false,
        has_success: false,
    }

    componentDidMount() {
        this.setFormManager()
    }

    setErrorMessage(message) {
        this.setState({ error: message })
    }

    setFormManager() {
        const URLparams = new URLSearchParams(this.props.location.search)

        const fieldsConfig = [
            { name: 'password', value: '' },
            { name: 'confirmPassword', value: '' },
            { name: 'code', value: URLparams.get('code') },
            { name: 'userId', value: URLparams.get('userId') },
        ]

        fieldsConfig.forEach(field => {
            field.hideValidityError = true
            switch (field.name) {
                case 'password':
                    field.isValid = () =>
                        field.value.length >= 8 &&
                        field.value.length <= 100 &&
                        zxcvbn(field.value).feedback.warning === ''
                    break
                case 'confirmPassword':
                    field.isValid = () => this.doesPasswordsMatch()
                    break
                default:
                    field.isValid = () => true
            }
        })

        this.formManager = new FormFieldManager(fieldsConfig, () =>
            this.forceUpdate(),
        )
        this.setState({ is_loading: false })
    }

    doesPasswordsMatch() {
        return (
            this.formManager.getFieldValue('password') ===
            this.formManager.getFieldValue('confirmPassword')
        )
    }

    clearLoadingState = () => {
        this.setState({ is_loading: false })
    }

    handleSubmit = () => {
        const userId = this.formManager.getFieldValue('userId')
        const password = this.formManager.getFieldValue('password')
        const confirmPassword = this.formManager.getFieldValue(
            'confirmPassword',
        )
        const code = this.formManager.getFieldValue('code')
        const data = { userId, password, confirmPassword, code }
        try {
            AccountApi.AccountRecovery(data)
        } catch (err) {
            this.setState({
                error:
                    'Unable to recover account, please try again or contact support.',
                has_error: true,
            })
        }
        this.setState({ has_success: true })
    }

    removeError = () => {
        this.setState({
            has_error: false,
            error: '',
        })
    }

    renderResetComplete = () => (
        <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 col-sm-6">
                <h2 className="mb-1 pb-3">Reset Password</h2>
                <p>
                    Your password has been successfully reset! Please{' '}
                    <Link to="/Login">login</Link>
                </p>
            </div>
        </div>
    )

    render() {
        if (this.state.is_loading) {
            return <LoadingSpinner />
        }
        if (!this.formManager.getFieldValue('code')) {
            return <Redirect to="/Login" />
        }
        if (this.state.has_success) {
            return this.renderResetComplete()
        }
        const formManager = this.formManager
        const shouldHideError = field => formManager.shouldHideError(field)

        return (
            <React.Fragment>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                        <h2 className="mb-0 pb-3">Account Recovery</h2>
                        <h5>Please enter your new password</h5>
                        <br />
                        {this.state.has_error && (
                            <ErrorMessage
                                message={this.state.error}
                                isVisible={this.state.has_error}
                                handleRemove={this.removeError}
                            />
                        )}
                        {
                            <InputGroup
                                type="password"
                                field="password"
                                label="Password"
                                placeholder="Password"
                                error={
                                    zxcvbn(
                                        formManager.getFieldValue('password'),
                                    ).feedback.warning ||
                                    'Password must be 8 to 100 characters long'
                                }
                                value={formManager.getFieldValue('password')}
                                valid={formManager.isValid('password')}
                                handleChange={(field, value) =>
                                    formManager.handleChange(field, value)
                                }
                                handleBlur={field =>
                                    formManager.handleBlur(field.name)
                                }
                                hideError={shouldHideError('password')}
                            />
                        }
                        {
                            <InputGroup
                                type="password"
                                field="confirmPassword"
                                label="Confirm Password"
                                placeholder="Confirm Password"
                                error="Does not match Password"
                                value={formManager.getFieldValue(
                                    'confirmPassword',
                                )}
                                valid={formManager.isValid('confirmPassword')}
                                handleChange={(field, value) =>
                                    formManager.handleChange(field, value)
                                }
                                handleBlur={field =>
                                    formManager.handleBlur(field.name)
                                }
                                hideError={shouldHideError('confirmPassword')}
                            />
                        }
                        <Button
                            sx={{ width: '100%' }}
                            disabled={
                                this.state.is_saving ||
                                !formManager.hasChanged() ||
                                !formManager.areFieldsValid()
                            }
                            onClick={this.handleSubmit}
                        >
                            {this.state.is_saving ? 'Resetting Password...' : 'Reset Password'}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default PasswordReset
