import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'

const focusInputField = input => {
    if (input) {
        setTimeout(() => {
            input.focus()
        }, 100)
    }
}

class NoteModal extends React.Component {
    state = {
        message: this.props.studyEvent.Comment,
    }

    getHeaderText = () => {
        if (this.props.isCreating) {
            return 'Create Note'
        }

        return 'Edit Note'
    }

    handleCancelChanges = () => {
        this.props.toggle()
        this.props.handleCancelStudyEvent()
    }

    handleNoteChange = value => {
        this.setState({
            message: value,
        })
    }

    handleSaveNote = () => {
        const study_event = {
            ...this.props.studyEvent,
            Comment: this.state.message,
        }
        this.props.handleSubmitStudyEvent(study_event)
    }

    handleKeyUp = e => {
        switch (e.keyCode)
        {
            case 35: // End
            case 36: // Home
                e.stopPropagation()
                break
            default:
                break
        }
    }

    render() {
        return (
            <Modal isOpen toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    {this.getHeaderText()}
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row">
                            <textarea
                                id="note_comment"
                                name="note_comment"
                                className="form-control"
                                maxLength={200}
                                rows="10"
                                value={this.state.message}
                                onChange={e =>
                                    this.handleNoteChange(e.target.value)
                                }
                                onKeyUp ={this.handleKeyUp}
                                ref={focusInputField}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outlined"
                        onClick={() => this.handleCancelChanges()}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ ml: 2 }}
                        onClick={() => this.handleSaveNote()}
                    >
                        Ok
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default NoteModal
