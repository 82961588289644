import React from 'react'
import './ShareStudyModal.scss'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import Select from 'react-select'
import NotificationsApi from '../../services/NotificationsApi'
import SessionManager from '../../services/SessionManager'
import { truncate, abbreviate } from '../../utils/StringUtils'
import ToastUtils from '../../utils/ToastUtils'

const USER = 'user'
const GROUP = 'group'

class ShareStudyModal extends React.Component {
    state = {
        is_saving: false,
        selected_recipients: [],
        Users: [],
        Groups: [],
    }

    componentDidMount() {
        const facility_id = SessionManager.get('mmt_facility_id')

        NotificationsApi.getShareableUsers(facility_id)
            .then(response => {
                this.setRecipients(response.data)
            })
            .catch(() => {
                ToastUtils.error({ message: 'Unable to get shareable users' })
            })
    }

    getRecipientOptions = () =>
        this.state.Users.map(user => ({
            value: user.ID,
            label: `${user.LastName}, ${user.FirstName}`,
            type: USER,
        }))

    getGroupOptions = () =>
        this.state.Groups.map(group => ({
            value: group.ID,
            label: group.Name,
            type: GROUP,
        }))

    getOptions = () => [
        ...this.getGroupOptions(),
        ...this.getRecipientOptions(),
    ]

    setRecipients = recipients => {
        const Users = recipients.Users

        Users.sort((a, b) => {
            if (a.LastName.toLocaleLowerCase() < b.LastName.toLocaleLowerCase())
                return -1
            if (a.LastName.toLocaleLowerCase() > b.LastName.toLocaleLowerCase())
                return 1
            return 0
        })

        const Groups = recipients.Groups

        this.setState({
            Users,
            Groups,
        })
    }

    handleChange = selected_recipients => {
        this.setState({ selected_recipients })
    }

    handleSend = () => {
        this.setState({ is_saving: true })
        const user_ids = this.state.selected_recipients
            .filter(user => user.type === USER)
            .map(user => user.value)

        const group_ids = this.state.selected_recipients
            .filter(group => group.type === GROUP)
            .map(group => group.value)

        NotificationsApi.shareStudy(this.props.study.ID, user_ids, group_ids)
            .then(() => {
                this.props.handleShare && this.props.handleShare(this.props.study.ID)
                this.setState({
                    is_saving: false,
                    selected_recipients: [],
                })
                this.props.toggle()
                ToastUtils.success({ message: 'Study successfully shared' })
            })
            .catch(err => {
                console.log(err)
                this.setState({
                    is_saving: false,
                    selected_recipients: [],
                })
                ToastUtils.error({ message: 'There was error sharing the study' })
            })
    }

    render() {
        const options = this.getOptions()

        return (
            <Modal isOpen toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    Share Study
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                        <div className="row form-group pl-2 pr-2">
                            <div className="modal-bool msg">
                                {this.props.study.PatientId && (
                                    <p title={this.props.study.PatientId}>
                                        Patient ID:{' '}
                                        {truncate(
                                            this.props.study.PatientId,
                                            30,
                                        )}
                                    </p>
                                )}
                                {this.props.study.LastName && (
                                    <p
                                        title={`${this.props.study.LastName}, ${this.props.study.FirstName} ${this.props.study.MiddleName}`}
                                    >
                                        Name:{' '}
                                        {truncate(
                                            this.props.study.LastName,
                                            15,
                                        )}
                                        ,{' '}
                                        {truncate(
                                            this.props.study.FirstName,
                                            10,
                                        )}{' '}
                                        {abbreviate(
                                            this.props.study.MiddleName,
                                        )}
                                    </p>
                                )}
                                <p>
                                    Please choose the users to share the study
                                    with:
                                </p>
                                <Select
                                    isMulti
                                    autoFocus
                                    className="share-study-modal"
                                    options={options}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outlined"
                        onClick={this.props.toggle}
                        sx={{ mr: 2 }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={this.handleSend}
                        disabled={
                            this.state.is_saving ||
                            !this.state.selected_recipients ||
                            this.state.selected_recipients.length < 1
                        }
                    >
                        {this.state.is_saving ? 'Sharing...' : 'Share'}
                    </Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default ShareStudyModal
