import ScreenCalibration from '../types/ScreenCalibration'
import ScreenUtils from '../utils/ScreenUtils'

interface Storage {
    getItem: (key: string) => string | null
    setItem: (key: string, value: string) => void
}

type ScreenChangeHandler = (calibration: ScreenCalibration, isValid: boolean) => void

class ScreenCalibrationService {
    constructor(storage: Storage, onScreenChange: ScreenChangeHandler) {
        this._storage = storage
        this._isScreenCalibrated = false
        this._calibration = this._findCalibration()
        this._onScreenChange = onScreenChange
        this._listen()
    }

    readonly _storageKey = 'mmt_calibrated_screens'
    private _storage: Storage
    private _calibration: ScreenCalibration
    private _isScreenCalibrated: boolean
    private _listenerInterval?: NodeJS.Timeout
    private _onScreenChange: ScreenChangeHandler

    isScreenCalibrated(): boolean {
        return this._isScreenCalibrated
    }

    update(calibration: ScreenCalibration): void {
        const calibrations = this._getStoredCalibrations()
        let found = false
        for (const c of calibrations) {
            if (c.screen === calibration.screen) {
                Object.assign(c, calibration)
                found = true
                break
            }
        }
        if (!found) {
            calibrations.push(calibration)
        }
        this._calibration = calibration
        this._isScreenCalibrated = true
        this._save(calibrations)
    }

    pixelsPerCm(): number {
        return this._calibration.ppcm
    }

    getCalibration(): ScreenCalibration {
        return this._calibration
    }

    destroy(): void {
        if (!this._listenerInterval) return
        clearInterval(this._listenerInterval)
    }

    private _listen(): void {
        this._listenerInterval = setInterval(() => {
            if (this._hasScreenChanged()) {
                this._calibration = this._findCalibration()
                this._onScreenChange(this._calibration, this._isScreenCalibrated)
            }
        }, 1000)
    }

    private _hasScreenChanged(): boolean {
        return ScreenUtils.fingerprint() !== this._calibration.screen
    }

    private _save(calibrations: ScreenCalibration[]): void {
        const value = JSON.stringify(calibrations)
        this._storage.setItem(this._storageKey, value)
    }

    private _guessCalibration(): ScreenCalibration {
        return {
            ppcm: window.devicePixelRatio * 96 / 2.54 / window.devicePixelRatio,
            screen: ScreenUtils.fingerprint(),
            userId: '',
            userName: '',
            timestamp: 0,
        }
    }

    private _getStoredCalibrations(): ScreenCalibration[] {
        const calibrationsStr = this._storage.getItem(this._storageKey)
        if (!calibrationsStr) {
            return []
        }
        return JSON.parse(calibrationsStr)
    }

    private _findCalibration(): ScreenCalibration {
        const calibrations = this._getStoredCalibrations()
        const fingerprint = ScreenUtils.fingerprint()
        for (const c of calibrations) {
            if (fingerprint === c.screen) {
                this._isScreenCalibrated = true
                return c
            }
        }
        this._isScreenCalibrated = false
        return this._guessCalibration()
    }
}

export default ScreenCalibrationService
