
/* Variables */


// Rendr colors
export const rendrWave0 = '#1c3846'
export const rendrWave1 = '#174a8c'
export const rendrWave2 = '#2ba9a0'
export const rendrWave3 = '#71c2ae'
export const rendrWave4 = '#bcddc7'

export const rendrAccent1 = '#D15D64'
export const rendrAccent2 = '#E0AE72'

export const rendrSoftWhite = 'E3EAFF'

export const colorGreen = '#55c45e'

// Blues
export const electroTekBlue = '#a2e0ee'

// Reds
export const electroTekLightRed = '#ff5154'
export const electroTekRed = '#e54749'
export const electroTekDarkRed = '#722324'

// Oranges
export const electroTekLightOrange = '#f78e61'
export const electroTekOrange = '#ff4e00'
export const electroTekDarkOrange = '#c53d03'

// Grays 
export const electroTekWhite = '#ffffff'
export const electroTekVeryLightGray = '#e9e9e9'
export const electroTekLightGray = '#b8b8b8'
export const electroTekGray = '#909090'
export const electroTekDarkGray = '#666666'
export const electroTekBlack = '#232323'

// Wire colors (used in placement and impedance area)
export const wireBlue = '#0091ff'
export const wireOrange = '#ff270f'
export const wireYellow = '#d4ff00'
export const wireGreen = '#30ff19'

// Other
export const colorGraph = '#000000'
export const colorTextBlack = '#232323'
export const colorTextGray = '#525252'
export const colorWhite = '#fff'
export const colorPanelBg = '#f0f0f0'
export const colorGrayLt = '#dfdfdf'
export const colorGrayMd = '#909090'
export const colorGrayDk = '#333333'
export const colorGrayDv = '#ccc'
export const colorOrangeLt = '#ff4e00'
// ---

// Tables
export const tablesBorderRadius = '6px'
export const tablesBackground = rendrWave2

// Modals
export const modalsBorderRadius = '6px'