import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { styled, useTheme } from '@mui/material/styles'
import Toolbar from '@mui/material/Toolbar'
import Logo from './Logo'
import drawerHeaderMixin from './drawerHeaderMixin'
import FacilityDropdown from '../theme/Header/FacilityDropdown'
import { STUDY_STATES } from '../../constants/studystates'
import AppContext from '../AppContext'
// import NetworkConnectionIndicator from '../theme/Header/NetworkConnectionIndicator'
import HeaderDropdown from '../theme/Header/HeaderDropdown'
import SessionManager from '../../services/SessionManager'
import { useState, useEffect, useContext } from 'react'

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}

const shouldForwardProp = {
    shouldForwardProp: (prop: string) => prop !== 'open',
}

const StyledAppBar = styled(MuiAppBar, shouldForwardProp)<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}))

interface Props {
    open: boolean
    toggleDrawer?: () => void
}

function AppBar(props: Props) {
    const theme = useTheme()
    const ctx = useContext(AppContext)

    const [userName, setUserName] = useState(
        SessionManager.get('mmt_user_name') || '',
    )

    useEffect(() => {
        const listener = () => {
            setUserName(SessionManager.get('mmt_user_name'))
        }
        window.addEventListener('storage', listener)
        return () => window.removeEventListener('storage', listener)
    }, [])

    return (
        <StyledAppBar
            position="fixed"
            open={props.open}
            elevation={1}
        >
            <Toolbar sx={{ ...drawerHeaderMixin(theme), flexGrow: 1 }}>
                <Logo />
                <FacilityDropdown />
                {/* <NetworkConnectionIndicator /> */}
                {ctx.Study.StudyState && (
                    <span className="d-none d-sm-block study-state">
                        {STUDY_STATES[ctx.Study.StudyState.ID]}
                    </span>
                )}
                <span
                    style={{ maxWidth: '300px' }}
                    className="d-none d-sm-block text-truncate"
                >
                    {userName}
                </span>
                <HeaderDropdown />
            </Toolbar>
        </StyledAppBar>
    )
}

export default AppBar
