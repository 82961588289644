import React from 'react'
import NotSet from '../components/NotSet'

class PatientUtils {
    static formatSex(sex: string | null | undefined): string | JSX.Element {
        if (!sex) return <NotSet />
        if (sex === 'm') return 'Male'
        return 'Female'
    }
}

export default PatientUtils