import 'moment-duration-format'
import moment, { Moment } from 'moment/moment'
import moment_timezone from 'moment-timezone'

const DateTimeUtils = {
    getMomentFromDBString(str: string): Moment {
        return moment(`${str}+00:00`)
    },

    getLocalTimezone() {
        return moment_timezone.tz.guess()
    },

    getFriendlyStudyDate(study_creation_date: string) {
        if (moment(study_creation_date).isAfter(moment(0, 'HH'))) {
            return moment_timezone(`${study_creation_date}+00:00`)
                .tz(this.getLocalTimezone())
                .fromNow()
        }
        if (
            moment(study_creation_date).isBefore(moment(0, 'HH')) &&
            moment(study_creation_date).isAfter(moment().startOf('day').subtract(1, 'day'))
        ) {
            return moment_timezone(`${study_creation_date}+00:00`)
                .tz(this.getLocalTimezone())
                .format('[Yesterday at] hh:mm a')
        }
        if (
            moment(study_creation_date).isBefore(moment(0, 'HH')) &&
            moment(study_creation_date).isAfter(moment().subtract(1, 'years'))
        ) {
            return moment_timezone(`${study_creation_date}+00:00`)
                .tz(this.getLocalTimezone())
                .format('MMMM DD [at] hh:mm a')
        }
        return moment_timezone(`${study_creation_date}+00:00`)
            .tz(this.getLocalTimezone())
            .format('MM-DD-YYYY, hh:mm:ss a')
    },

    getFullStudyDateTimestamp(study_creation_date: string) {
        return moment_timezone(`${study_creation_date}+00:00`)
            .tz(this.getLocalTimezone())
            .format('MM-DD-YYYY, hh:mm:ss a')
    },

    getFullStudyDateTimestampTimeZone(study_creation_date: string, tz: string) {
        return moment_timezone(`${study_creation_date}+00:00`)
            .tz(tz)
            .format('MM-DD-YYYY, hh:mm:ss a')
    },

    getMomentInTimeZone(stamp: string, timezone: string) {
        return moment_timezone(`${stamp}+00:00`).tz(timezone)
    },

    getMinStudyDate(study_creation_date: string) {
        return moment_timezone(`${study_creation_date}+00:00`)
            .tz(this.getLocalTimezone())
            .format('MMM D, YYYY')
    },

    getMinStudyTimestamp(study_creation_date: string) {
        return moment_timezone(`${study_creation_date}+00:00`)
            .tz(this.getLocalTimezone())
            .format('hh:mm:ss a')
    },

    getStudyDuration(studyDuration: any) {
        let mom = moment.duration(studyDuration)
        const remainderMs = mom.milliseconds() % 1000
        mom = mom.subtract(remainderMs, 'milliseconds')
        return mom.format('h[h] mm[m] s[s]')
    },

    getStudyAltDuration(seconds: number) {
        const duration = moment
            .duration(seconds, 'seconds')
            .format('hh:mm:ss')
        return this.prefixZeros(duration)
    },

    getApproxStudyDuration(study_duration: any) {
        return moment.duration(study_duration).format('h[h] mm[m]')
    },

    getFriendlyDuration(seconds: number) {
        const duration = moment
            .duration(seconds, 'seconds')
            .format('hh:mm:ss')
        return this.prefixZeros(duration)
    },

    getFriendlyStudyTimestamp(milliseconds: number) {
        return moment.utc(milliseconds).format('HH:mm:ss')
    },

    getFriendlyUnixTimestamp(milliseconds: number) {
        return moment.unix(milliseconds).format('HH:mm:ss')
    },

    getISOUnixTimestamp(milliseconds: number) {
        return moment.unix(milliseconds).format('DD MMM YYYY hh:mm a')
    },

    getStudyTimestamp(milliseconds: number) {
        return moment.utc(milliseconds).format('HH:mm:ss')
    },

    getStudyTimestampBySecond(milliseconds: number) {
        /* Check if the timestamp is under an hour first */
        if (milliseconds < 1e3 * 60 * 60) {
            return moment.utc(milliseconds).format('mm:ss.SS')
        }
        const dur = moment
            .duration(milliseconds / 1000, 'seconds')
            .format('hh:mm:ss.SS')
        return this.prefixZeros(dur)
    },

    prefixZeros(dur: string) {
        while (dur.split(':').length < 3) {
            dur = `00:${dur}`
        }
        return dur
    },

    getGraphTimestamp(index: number, offset: number, timebase: string, datarate: number) {
        const offsetSecs = offset / datarate
        if (timebase === '1') {
            return this.getStudyTimestampBySecond(
                (index / 10 + offsetSecs) * 1000,
            )
        }
        if (timebase === '30') {
            return this.getStudyAltDuration(index * 5 + offsetSecs)
        }
        return this.getStudyAltDuration(index + offsetSecs)
    },

    getFriendlyDate(date: string) {
        return moment(date).format('MM/DD/YYYY')
    },

    getFriendlyDateTime(date: string, seperator = ' | ') {
        return moment(date).format(`MM/DD/YYYY ${seperator} h:mm:ss a`)
    },

    getAbbreviated(date: string) {
        return moment(date).format('MMM. D YYYY')
    },

    getFriendlyDateOfBirth(date: string) {
        const dob = moment(date).format('MM/DD/YYYY')
        return dob === 'Invalid date' ? '*****' : dob
    },

    getAbbreviatedDateOfBirth(date: string) {
        return moment(date).format('MMM. D YYYY')
    },

    isDateAfter(dateValue: any, time: string) {
        const hourAgo = moment().subtract(1, 'hour')
        const dayAgo = moment().subtract(1, 'day')
        const weekAgo = moment().subtract(1, 'week')
        const monthAgo = moment().subtract(1, 'month')
        const yearAgo = moment().subtract(1, 'year')
        const dateToCheck = moment.utc(dateValue)

        switch (time) {
            case 'hour':
                return dateToCheck.isAfter(hourAgo)
            case 'day':
                return dateToCheck.isAfter(dayAgo)
            case 'week':
                return dateToCheck.isAfter(weekAgo)
            case 'month':
                return dateToCheck.isAfter(monthAgo)
            case 'year':
                return dateToCheck.isAfter(yearAgo)
            default:
                console.log(
                    '[ERROR] invalid time value passed for isDateOlderThan() method',
                )
                return false
        }
    },
}

export default DateTimeUtils
