import { useState } from 'react'

type Key = string | number

const useOpenableTableRow = () => {
    const [isRowOpen, setIsRowOpen] = useState<{ [key: string]: boolean }>({})

    const isOpen = (id: Key) => {
        return isRowOpen[id]
    }

    const toggle = (id: Key) => {
        setIsRowOpen(i => ({ ...i, [id]: !i[id] }))
    }

    return { isOpen, toggle }
}

export default useOpenableTableRow
