import React from 'react'
import ErrorMessage from './messaging/ErrorMessage'
import InputGroup from './forms/InputGroup'
import FormFieldManager from '../utils/FormFieldManager'
import LoadingSpinner from './LoadingSpinner'
import Validation from '../services/Validation'
import AccountApi from '../services/AccountApi'
import { Button } from '@mui/material'

class ForgotPassword extends React.Component {
    formManager = null

    state = {
        is_submitting: false,
        is_loading: true,
        has_submitted: false,
        error_message: '',
        hide_error: true,
    }

    componentDidMount() {
        this.setFieldsConfig()
    }

    setFieldsConfig() {
        const fieldsConfig = [{ name: 'email', value: '' }]

        fieldsConfig.forEach(field => {
            field.hideValidityError = true
            switch (field.name) {
                case 'email':
                    field.isValid = () =>
                        field.value && Validation.isValidEmail(field.value)
                    break
                default:
                    field.isValid = () => true
            }
        })

        this.formManager = new FormFieldManager(fieldsConfig, () =>
            this.forceUpdate(),
        )
        this.setState({ is_loading: false })
    }

    hideError = () => this.state.hide_error

    handleSubmit = () => {
        const email = this.formManager.getFieldValue('email')
        AccountApi.ForgotUserPassword(email)
        this.setState({ has_submitted: true })
    }

    hasSubmittedContent = () => (
        <div className="row justify-content-center">
            <div className="col-sm-6">
                Check your {this.formManager.getFieldValue('email')} inbox for
                instructions from us on how to reset your password. If you do
                not see an email after 10 minutes check the spam folder just in
                case.
            </div>
        </div>
    )

    render() {
        if (this.state.is_loading) {
            return <LoadingSpinner />
        }
        if (this.state.has_submitted) {
            return this.hasSubmittedContent()
        }
        const hideError = field => this.formManager.shouldHideError(field)

        return (
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-4 col-sm-6">
                    <h2 className="mb-1 pb-3">Recover Your Account</h2>
                    <p>
                        We can help you reset your password, enter the email
                        address you used when your account was created and we will email you a link to reset your password.
                    </p>
                    {this.state.subtitle && <h3>{this.state.subtitle}</h3>}
                    {this.state.error_message !== '' && (
                        <ErrorMessage message={this.state.error_message} />
                    )}

                    <form>
                        <div className="form-group">
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <InputGroup
                                type="email"
                                id="Email"
                                field="email"
                                label="Email"
                                className="is-valid"
                                placeholder="Email"
                                error="Valid email address required"
                                value={this.formManager.getFieldValue('email')}
                                valid={this.formManager.isValid('email')}
                                hideError={hideError('email')}
                                handleChange={(field, value) =>
                                    this.formManager.handleChange(field, value)
                                }
                                handleBlur={() =>
                                    this.formManager.handleBlur('email')
                                }
                            />
                        </div>
                        <br />
                        <Button
                            disabled={
                                this.state.is_submitting ||
                                !this.formManager.isValid('email')
                            }
                            onClick={this.handleSubmit}
                            sx={{ width: '100%' }}
                        >
                            {this.state.is_submitting ? 'Sending...' : 'Send link'}
                        </Button>
                    </form>
                </div>
            </div>
        )
    }
}

export default ForgotPassword
