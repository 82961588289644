import { useCallback, useEffect, useState } from 'react'
import './Montages.scss'
import axios from 'axios'
import MontageSearch from './MontageSearch'
import MontageView from './MontageView'
import MontagesApi from '../../services/MontagesApi'
import Montage from '../../types/Montage'
import FailedToLoad from '../../components/FailedToLoad'
import LoadingSpinner from '../../components/LoadingSpinner'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import SortUtils from '../../utils/SortUtils'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

function Montages() {
    useDocumentTitle('Montages')
    const [filteredMontages, setFilteredMontages] = useState<Montage[]>([])
    const [allMontages, setAllMontages] = useState<Montage[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [didMontagesFailToLoad, setDidMontagesFailToLoad] = useState(false)
    const [selectedMontage, setSelectedMontage] = useState<Montage | undefined>()
    const [searchedText, setSearchedText] = useState('')

    const selectedMontageId = selectedMontage?.ID
    useEffect(() => {
        const filtered = allMontages.filter(m => m.Name.toLowerCase().includes(searchedText.toLowerCase()))
        setFilteredMontages(filtered)
        if (!filtered.find(m => m.ID === selectedMontageId)) {
            setSelectedMontage(filtered[0])
        }
    }, [searchedText, allMontages, selectedMontageId])

    const getAllMontages = useCallback(() => {
        setIsLoading(true)
        setAllMontages([])
        setSelectedMontage(undefined)
        setDidMontagesFailToLoad(false)
        const source = axios.CancelToken.source()
        MontagesApi.getMontages(source).then(res => {
            SortUtils.string<Montage, 'Name'>(res.data, 'Name', 'asc')
            setAllMontages(res.data)
            setIsLoading(false)
            const selectedPatientId = window.location.hash.replace('#', '')
            for (const montage of res.data) {
                if (montage.ID === selectedPatientId) {
                    setSelectedMontage(montage)
                    window.location.hash = montage.ID
                    return
                }
            }
            setSelectedMontage(res.data[0])
            if (res.data[0]) {
                window.location.hash = res.data[0].ID
            } else {
                window.history.pushState('', document.title, window.location.pathname)
            }
        }).catch(err => {
            if (axios.isCancel(err)) return
            console.log(err)
            setDidMontagesFailToLoad(true)
            setIsLoading(false)
        })
        return () => source.cancel('New search performed')
    }, [])

    useEffect(getAllMontages, [getAllMontages])

    const pageHeader = () => {
        const countMessage = isLoading ? 'Loading...' :
            `${filteredMontages.length === 0 ? 'No' : filteredMontages.length} montages found`

        return (
            <div className="header-seperator">
                <h3 style={{ marginBottom: 3 }}>
                    <i className="icon-electrotek-montage" />{' '}
                    <span>Montages</span>
                </h3 >
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
            </div>
        )
    }

    return (
        <div className="animated fadeIn montages">
            {pageHeader()}
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <MontageSearch
                        isLoading={isLoading}
                        onSearch={setSearchedText}
                        onSelectMontage={m => {
                            setSelectedMontage(m)
                            window.location.hash = m.ID
                        }}
                        selectedMontage={selectedMontage}
                        montages={filteredMontages}
                    />
                </Grid>
                <Grid item xs={9}>
                    {isLoading ? (
                        <LoadingSpinner />
                    ) : selectedMontage ? (
                        <MontageView
                            montage={selectedMontage}
                            allMontages={allMontages}
                            onDeleted={getAllMontages}
                            onDuplicated={m => setAllMontages(ms => [...ms, m])}
                        />
                    ) : (
                        <h2 style={{ textAlign: 'center' }}>
                            {didMontagesFailToLoad ? (
                                <FailedToLoad items="montages" onRetry={getAllMontages} />
                            ) : 'No montages found'}
                        </h2>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default Montages