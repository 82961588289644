import React, { Component } from 'react'
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown,
} from 'reactstrap'
import { Auth } from '../../../services/Auth'
import SessionManager from '../../../services/SessionManager'
import AppContext from '../../AppContext'
import { ROLE_NAMES } from '../../../constants/roles'
import { NavLink } from 'react-router-dom'

class HeaderDropdown extends Component {
    static contextType = AppContext

    constructor(props) {
        super(props)

        this.state = {
            dropdownOpen: false,
        }
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        })
    }

    render() {
        if (Auth.loggedIn()) {
            const roles = ROLE_NAMES.filter(rn => rn.name === this.context.Roles[0])
            const roleName = roles[0]?.friendly_name || ''
            return (
                <>
                    <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle} style={{ listStyle: 'none', marginRight: 0 }}>
                        <DropdownToggle nav style={{ paddingRight: 0 }}>
                            <img
                                src="/assets/img/profile.png"
                                className="img-avatar"
                                title={SessionManager.get('mmt_auth_user')}
                                alt=""
                                style={{
                                    height: '30px',
                                    margin: 0,
                                    borderRadius: '50em',
                                    verticalAlign: 'middle',
                                }}
                            />
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem header tag="div" className="text-center">
                                Signed in as
                                <br />
                                <strong>{SessionManager.get('mmt_auth_user')}</strong>
                                <br />
                                <strong>{roleName}</strong>
                            </DropdownItem>
                            <DropdownItem>
                                <NavLink to="/Profile" style={{ color: '#000' }}>
                                    <i className="fa fa-user" />
                                    Your Profile
                                </NavLink>
                            </DropdownItem>
                            <DropdownItem toggle={false}>
                                <i className="fa fa-globe" /> English (US)
                            </DropdownItem>
                            <DropdownItem onClick={Auth.logout}>
                                <i className="fa fa-lock" />
                                Sign Out
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </>
            )
        }
        return null
    }
}

export default HeaderDropdown
