import React from 'react'
import './AmplifierInfoRow.scss'
import DateTimeUtils from '../../utils/DateTimeUtils'
import Amplifier from '../../types/Amplifier'
import { Collapse, TableRow, TableCell } from '@mui/material'

const NULL_VALUE_TEXT = 'Unknown'

interface Props {
    amplifier: Amplifier
}

const AmplifierInfoRow: React.FC<Props> = props => (
    <TableRow className="amplifier-info-row">
        <TableCell colSpan={12}>
            <Collapse in={true} timeout="auto">
                <div className="info">
                    <p>
                        <span className="float-right">
                            {props.amplifier.FirmwareVersion || NULL_VALUE_TEXT}
                        </span>
                        Primary firmware version:
                    </p>
                    <p>
                        <span className="float-right">
                            {props.amplifier.BootloaderVersion || NULL_VALUE_TEXT}
                        </span>
                        Primary bootloader version:
                    </p>
                    <p>
                        <span className="float-right">
                            {props.amplifier.BluetoothID || NULL_VALUE_TEXT}
                        </span>
                        Bluetooth ID:
                    </p>
                    <p>
                        <span className="float-right">
                            {props.amplifier.UsbID || NULL_VALUE_TEXT}
                        </span>
                        USB ID:
                    </p>
                    <p>
                        <span className="float-right">
                            {props.amplifier.Enabled ? 'Yes' : 'No'}
                        </span>
                        Enabled:
                    </p>
                    <p>
                        <span className="float-right">
                            {DateTimeUtils.getFullStudyDateTimestamp(props.amplifier.DateCreated)}
                        </span>
                        Date Created:
                    </p>
                </div>
            </Collapse>
        </TableCell>
    </TableRow>
)

export default AmplifierInfoRow
