import axios from 'axios'
import MMTApi from './MMTApi'

const DashboardApi = {
    getFacilityMetrics: guid =>
        axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/Facilities/Metrics?id=${guid}`,
            MMTApi.getRequestHeaders(),
        ),
}

export default DashboardApi
