import React from 'react'
import { Alert } from '@mui/material'
import InputGroup from '../../../components/forms/InputGroup'
import StudiesApi from '../../../services/StudiesApi'
import FunctionUtils from '../../../utils/FunctionUtils'
import LoadingSpinner from '../../../components/LoadingSpinner'
import ToastUtils from '../../../utils/ToastUtils'
import AppContext from '../../../components/AppContext'

class StudyReport extends React.Component {
    static contextType = AppContext

    constructor() {
        super()
        this.state = {
            is_loading: true,
            is_saving: false,
            is_saved_msg_visible: false,
            no_report_found: false,
            saved_msg_timeout: null,
            doctor: '',
            notes: '',
        }
        this.updateStudyReport = FunctionUtils.debounce(
            this.updateStudyReport,
            500,
        )
    }

    componentDidMount() {
        StudiesApi.getStudyReport(this.context.Study.ID)
            .then(res => {
                this.setState({
                    id: res.data.ID,
                    doctor: res.data.Doctor || '',
                    notes: res.data.Notes || '',
                    is_loading: false,
                })
            })
            .catch(err => {
                this.setState({ is_loading: false })
                if (err && err.status === 404) {
                    this.setState({ no_report_found: true })
                } else {
                    ToastUtils.error({ message: 'Unable to get study report' })
                }
            })
    }

    componentWillUnmount() {
        clearTimeout(this.state.saved_msg_timeout)
    }

    handleChange = (field, value) => {
        this.props.setCanCloseReviewOverlay(false)
        this.setState({ [field]: value })
        this.updateStudyReport()
    }

    updateStudyReport = () => {
        this.setState({ is_saving: true })
        const studyReport = {
            ID: this.state.id,
            Doctor: this.state.doctor,
            Notes: this.state.notes,
            StudyID: this.context.Study.ID,
        }
        StudiesApi.updateStudyReport(studyReport)
            .then(() => {
                this.flashSavedMessage()
                this.setState({ is_saving: false })
                this.props.setCanCloseReviewOverlay(true)
            })
            .catch(() => {
                this.setState({ is_saving: false })
                ToastUtils.error({ message: 'Unable to save study report' })
            })
    }

    flashSavedMessage = () => {
        this.setState({
            is_saved_msg_visible: true,
            saved_msg_timeout: setTimeout(
                () => this.setState({ is_saved_msg_visible: false }),
                1500,
            ),
        })
    }

    getSaveStatus = () => {
        if (this.state.is_saving === true) {
            return 'Saving...'
        }
        if (this.state.is_saved_msg_visible === true) {
            return 'Saved!'
        }
        return ''
    }

    render() {
        return (
            <div
                className={`row report ${this.state.is_saving || this.state.is_saved_msg_visible
                    ? 'save-status'
                    : ''
                }`}
            >
                <div className="col-12">
                    <h4>
                        Doctor Report
                        <span className="save-status">
                            {this.getSaveStatus()}
                        </span>
                    </h4>
                    {this.state.is_loading === true ? (
                        <LoadingSpinner />
                    ) : (
                        <div>
                            {this.state.no_report_found === true ? (
                                <Alert severity="error">
                                    No report was found for this study.
                                </Alert>
                            ) : (
                                <div>
                                    <InputGroup
                                        type="text"
                                        field="doctor"
                                        label="Doctor"
                                        maxLength={50}
                                        value={this.state.doctor}
                                        handleChange={this.handleChange}
                                    />
                                    <InputGroup
                                        type="textarea"
                                        field="notes"
                                        label="Notes"
                                        maxLength={1000}
                                        value={this.state.notes}
                                        handleChange={this.handleChange}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default StudyReport
