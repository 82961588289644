import { TEN_TWENTY_ELECTRODES } from '../constants/tentwentyelectrodes'
import Patient from '../types/Patient'

export function truncate(str: string | null | undefined, limit: number) {
    if (str === null || str === undefined) {
        return ''
    }
    if (limit === undefined) throw Error('limit must be defined')
    if (limit < 4)
        throw Error('can not truncate a string to 3 or less characters')

    if (str.length > limit) return `${str.slice(0, limit - 3)}...`
    return str
}

export function abbreviate(str: string) {
    if (typeof str !== 'string' || str.length === 0) return ''
    return `${str.slice(0, 1)}.`
}

export function patientName(patient: Patient) {
    return `${patient.LastName}, ${patient.FirstName} ${patient.MiddleName || ''}`
}

export function patientNameAbr(patient: Patient, toAbr: string) {
    const rF = /[.F.f.]/
    const rM = /[M.m]/
    const rL = /[L.l]/
    const fn = toAbr.match(rF) ? abbreviate(patient.FirstName) : patient.FirstName
    const ln = toAbr.match(rL) ? abbreviate(patient.LastName) : patient.LastName
    const mn = toAbr.match(rM) ? abbreviate(patient.MiddleName || '') : patient.MiddleName
    return `${ln}, ${fn} ${mn}`
}

export function formatStorageSize(bytes: number) {
    if (bytes < 1e3) return `${bytes} B`
    if (bytes < 1e6) return `${Math.round(bytes / 1e2) / 10} KB`
    if (bytes < 1e9) return `${Math.round(bytes / 1e5) / 10} MB`
    if (bytes < 1e12) return `${Math.round(bytes / 1e8) / 10} GB`
    if (bytes < 1e15) return `${Math.round(bytes / 1e11) / 10} TB`
    return '?'
}

const letters = 'abcdefghijklmnopqrstuvwxyz0123456789'

export function randString(length: number) {
    let str = ''

    if (length > 8) {
        str = Date.now().toString(36)
    }

    for (let i = str.length; i < length; i++) {
        const rand = Math.floor(Math.random() * letters.length)
        str += letters.slice(rand, rand + 1)
    }

    return str
}

export function isGuid(s: string): boolean {
    return /^[a-fA-F0-9-]{36}$/.test(s)
}

const HAS_DUPE_PARENS_REGEX = new RegExp(/\([0-9]+\)$/)

export function getDuplicateNumber(s: string): number {
    if (HAS_DUPE_PARENS_REGEX.test(s)) {
        const openParensIndex = s.lastIndexOf('(')
        const digit = s.slice(openParensIndex + 1, s.length - 1)
        return parseInt(digit)
    }
    return 0
}

export function getDuplicateBaseName(s: string): string {
    if (HAS_DUPE_PARENS_REGEX.test(s)) {
        const openParensIndex = s.lastIndexOf('(')
        return s.slice(0, openParensIndex - 1)
    }
    return s
}

export function duplicateParentheses(s: string): string {
    if (HAS_DUPE_PARENS_REGEX.test(s)) {
        const dupeNum = getDuplicateNumber(s)
        const baseName = getDuplicateBaseName(s)
        return `${baseName} (${dupeNum + 1})`
    }
    return `${s} (1)`
}

export function getChannelFormulaViolation(formula: string): string {
    const electrodes = formula.split('-')
    for (let i = 0; i < electrodes.length; i++) {
        if (electrodes[i] === '') {
            return 'Formula can not be empty'
        }
        const validElectrodes = [...TEN_TWENTY_ELECTRODES].map(e => e.toLowerCase())
        if (i > 0) {
            validElectrodes.push('avg', 'le')
        }
        if (!validElectrodes.includes(electrodes[i].toLowerCase())) {
            return `${electrodes[i]} is not a ten-twenty electrode`
        }
        for (let j = 0; j < i; j++) {
            if (electrodes[j].toLowerCase() === electrodes[i].toLowerCase()) {
                return `You can not use ${electrodes[i]} twice`
            }
        }
    }
    return ''
}

export function normalizeChannelFormulaCase(formula: string): string {
    return formula.split('-')
        .map(e => {
            if (e.toLowerCase() === 'le') return 'LE'
            return e.slice(0, 1).toUpperCase() + e.slice(1).toLowerCase()
        })
        .join('-')
}

// adds commas to large numbers (ex. 1123 => 1,123)
export function commatizeNumber(n: number): string {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
