import React from 'react'
import './LogEntryRow.scss'
import DateTimeUtils from '../../utils/DateTimeUtils'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'

const LogEntryRow = props => (
    <TableRow 
        className={`logentry-row ${props.selected ? 'selected' : ''}`}
        onClick={() => props.click_handler(props.logEntry.ID)}
    >
        <TableCell>
            {DateTimeUtils.getFriendlyDateTime(props.logEntry.DateCreated)}
        </TableCell>
        <TableCell>{props.logEntry.SoftwareVersion}</TableCell>
        <TableCell>{props.logEntry.UserName}</TableCell>
        <TableCell>{props.logEntry.ErrorCode}</TableCell>
    </TableRow>
)

export default LogEntryRow
