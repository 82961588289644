import axios from 'axios'
import MMTApi from './MMTApi'

const NotificationsApi = {
    getShareableUsers(facility_id) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/Notifications/GetShareableUsersAndGroups?facilityId=${facility_id}`,
            MMTApi.getRequestHeaders(),
        )
    },

    shareStudy(study_id, user_ids, group_ids) {
        let payload

        if (user_ids.length > 0 && group_ids.length < 1) {
            payload = {
                StudyId: study_id,
                UserIds: user_ids,
            }
        }

        if (user_ids.length > 0 && group_ids.length > 0) {
            payload = {
                StudyId: study_id,
                UserIds: user_ids,
                GroupIds: group_ids,
            }
        }

        if (user_ids.length < 1 && group_ids.length > 0) {
            payload = {
                StudyId: study_id,
                GroupIds: group_ids,
            }
        }

        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Notifications/ShareStudy`,
            payload,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default NotificationsApi
