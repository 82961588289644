import { useState, useEffect, useCallback } from 'react'
import './Toasts.scss'

const getIconClasses = type => {
    switch (type) {
        case 'success':
            return 'icon success fas fa-check-circle'
        case 'warning':
            return 'icon warning fas fa-exclamation-triangle'
        case 'error':
            return 'icon error fas fa-times-circle'
        default:
            return 'icon fas fa-info-circle'
    }
}

const openToastSetter = id => toasts =>
    toasts.map(toast => {
        if (toast.id === id) {
            toast.open = true
        }
        return toast
    })

const closeToastSetter = id => toasts =>
    toasts.map(toast => {
        if (toast.id === id) {
            delete toast.open
        }
        return toast
    })

const addToastSetter = newToast => oldToasts => {
    const newToasts = [...oldToasts]
    newToasts.push(newToast)
    return newToasts
}

const Toasts = () => {
    const [toasts, setToasts] = useState([])

    const handleRemove = id => setToasts(ts => ts.filter(t => t.id !== id))

    const handleClose = useCallback(id => {
        setToasts(closeToastSetter(id))
        setTimeout(() => handleRemove(id), 600)
    }, [])

    useEffect(() => {
        const listener = e => {
            setToasts(addToastSetter(e.detail))
            setTimeout(() => setToasts(openToastSetter(e.detail.id)))
            if (e.detail.duration) {
                setTimeout(() => handleClose(e.detail.id), e.detail.duration)
            }
        }
        window.addEventListener('toast', listener)
        return () => window.removeEventListener('toast', listener)
    }, [handleClose])

    useEffect(() => {
        const listener = e => handleClose(e.detail)
        window.addEventListener('closetoast', listener)
        return () => window.removeEventListener('closetoast', listener)
    }, [handleClose])

    const handleClick = (e, toast) => {
        if (e.target.closest('.close-icon')) return
        toast.onClick?.()
        handleClose(toast.id)
    }

    return (
        <div className="toasts">
            {toasts.map(toast => (
                <div
                    key={toast.id}
                    className={`message ${toast.open ? 'open' : ''} ${toast.onClick ? 'clickable' : ''}`}
                    onClick={e => handleClick(e, toast)}
                >
                    <i
                        className="close-icon icon-electrotek-cross2"
                        onClick={() => handleClose(toast.id)}
                    />
                    <i className={getIconClasses(toast.type)} />
                    <div className="toast-message body">{toast.message}</div>
                </div>
            ))}
        </div>
    )
}

export default Toasts
