import React, { CSSProperties } from 'react'
import { MouseTouchEvent } from '../../pages/montages/MontageHeadView'

import {
    NOT_MEASURED_VALUE,
    NOT_MAPPED_VALUE,
    OUT_OF_RANGE_VALUE,
    HEAD_HEIGHT_RATIO,
    ELECTRODE_POSITIONS,
} from './constants'

const getTextFromValueAndLabel = (value: number, label: string) => {
    switch (value) {
        case NOT_MEASURED_VALUE:
            return label
        case NOT_MAPPED_VALUE:
            return label
        case OUT_OF_RANGE_VALUE:
            return '>100'
        default:
            return value
    }
}

interface Props {
    style?: CSSProperties
    position: string
    measurement?: string
    mappingName?: string
    showLabels: boolean
    lowerThreshold?: number
    upperThreshold?: number
    headImageWidth: number
    electrodeWidth: number
    onDragStart?: (e: MouseTouchEvent) => void
    onDragEnd?: () => void
    draggable?: boolean
    className?: string
}

const Electrode = (props: Props) => {
    const parts = props.measurement ? props.measurement.split(':') : [props.position, '-2']
    const label = parts[0].replace(/^I/, '')
    const value = parseFloat(parts[1])

    let meterColor
    let meterValue
    if (props.upperThreshold && props.lowerThreshold) {
        if (value > props.upperThreshold) {
            meterColor = 'red'
            meterValue =
                0.67 +
                (0.33 * (Math.min(100, value) - props.upperThreshold)) /
                (100 - props.upperThreshold)
        } else if (value > props.lowerThreshold) {
            meterColor = 'yellow'
            meterValue =
                0.33 +
                (0.33 * (value - props.lowerThreshold)) /
                (props.upperThreshold - props.lowerThreshold)
        } else {
            meterColor = 'green'
            meterValue = (0.33 * value) / props.lowerThreshold
        }
    }


    const getStyle = (name: string) => {
        const makeStyle = (top: number, left: number) => ({
            top: top * props.headImageWidth * HEAD_HEIGHT_RATIO - props.electrodeWidth / 2,
            left: left * props.headImageWidth - props.electrodeWidth / 2,
            width: props.electrodeWidth,
            height: props.electrodeWidth,
        })
        const position = ELECTRODE_POSITIONS[name]
        if (position) {
            return makeStyle(position.top, position.left)
        }
        return {
            width: props.electrodeWidth,
            height: props.electrodeWidth,
        }
    }

    const labelFontSize = props.electrodeWidth / 3

    return (
        <div
            id={props.position}
            className={`
                electrode
                ${props.position}
                text-white
                p-2
                position-absolute
                ${props.draggable ? 'draggable' : ''}
                ${props.className ?? ''}
            `}
            style={{ ...getStyle(props.position), ...props.style }}
            onMouseDown={props.draggable ? props.onDragStart : undefined}
            onMouseUp={props.onDragEnd}
            onTouchStart={props.draggable ? e => props.onDragStart?.(e.touches[0]) : undefined}
            onTouchEnd={props.onDragEnd}
        >
            <div
                className="electrode-label"
                style={{ fontSize: labelFontSize }}
            >
                {props.showLabels === true && props.mappingName
                    ? props.mappingName
                    : getTextFromValueAndLabel(value, label)}
            </div>
            {props.mappingName && props.mappingName !== label && (
                <div
                    className="custom-electrode-label"
                    style={{ fontSize: labelFontSize }}
                >
                    {label}
                </div>
            )}
            {value > 0 && meterValue && (
                <svg className="meter" viewBox="0 0 200 200">
                    <path
                        className={meterColor}
                        d="M41 149.5a77 77 0 1 1 117.93 0"
                        fill="none"
                        strokeDasharray="350"
                        strokeDashoffset={350 - meterValue * 350}
                    />
                </svg>
            )}
        </div>
    )
}

export default Electrode