import React, { Fragment } from 'react'
import './ReviewOverlay.scss'
import StudyRecordingCard from '../../../components/cards/StudyRecordingCard'
import AmplifiersApi from '../../../services/AmplifiersApi'
import DevicesApi from '../../../services/DevicesApi'
import DateTimeUtils from '../../../utils/DateTimeUtils'
import FunctionUtils from '../../../utils/FunctionUtils'
import StudyNotes from './StudyNotes'
import StudyReport from './StudyReport'
import StudyStateView from './StudyStateView'
import VersionInformation from './VersionInformation'
import { AMP_FRIENDLY_NAMES } from '../../../constants/amplifiertypes'
import { STUDY_TYPE_FRIENDLY_NAMES } from '../../../constants/studytypes'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { truncate, abbreviate } from '../../../utils/StringUtils'
import AppContext from '../../../components/AppContext'
import RoleBasedContent from '../../../components/RoleBasedContent'
import {
    SUPER_ADMIN_ROLE,
    FACILITY_ADMIN_ROLE,
} from '../../../constants/roles'
import ChangePatientModal from './ChangePatientModal'
import { Button, Grid } from '@mui/material'

class ReviewOverlay extends React.Component {
    static contextType = AppContext

    constructor() {
        super()
        this.updateStudyNotes = FunctionUtils.debounce(
            this.updateStudyNotes,
            500,
        )
    }

    state = {
        is_completing: false,
        is_assign_patient_modal_open: false,
        is_confirm_complete_modal_open: false,
    }

    componentDidMount() {
        const hasValidAmp = !!this.context.Study.AmplifierID && this.context.Study.AmplifierID !== '00000000-0000-0000-0000-000000000000'
        const hasValidDevice = !!this.context.Study.DeviceID

        hasValidAmp && this.getAmplifier()
        hasValidDevice && this.getDevice()
    }

    setAmplifier(amplifier) {
        this.setState({ amplifier })
    }

    setDevice(device) {
        this.setState({ device })
    }

    getAmplifier() {
        AmplifiersApi.getAmplifier(this.context.Study.AmplifierID)
            .then(amplifier => this.setAmplifier(amplifier.data))
            .catch(err => console.log('No amplifier associated to requested study'))
    }

    getDevice() {
        DevicesApi.getDeviceForStudy(this.context.Study.ID)
            .then(device => this.setDevice(device.data))
            .catch(err => console.log('No device associated to requested study'))
    }

    toggleChangePatient = () => {
        this.props.setCanCloseReviewOverlay(this.state.is_assign_patient_modal_open)
        this.setState({
            is_assign_patient_modal_open: !this.state.is_assign_patient_modal_open,
        })
    }

    ampAndDeviceRender(deviceName, amplifierType, amplifierName) {
        return (
            <Fragment>
                <dt>Device</dt>
                <dd>
                    {deviceName}
                </dd>
                <br />
                <dd className="text-nowrap font-weight-bold">Amplifier:</dd>
                <br />
                <dt className="text-nowrap">Type</dt>
                <dd>
                    {amplifierType}
                </dd>
                <br />
                <dt className="text-nowrap">Name</dt>
                <dd>
                    {amplifierName}
                </dd>
            </Fragment>)
    }

    trackitPlusRender = () => (<dt className="text-nowrap">Trackit+ Import</dt>)

    render() {
        const fullPatientName = `${this.context.Study.LastName}, ${this.context.Study.FirstName} ${this.context.Study.MiddleName || ''}`
        const formattedPatientName = `${truncate(
            this.context.Study.LastName,
            12,
        )}, ${truncate(this.context.Study.FirstName, 10)} ${abbreviate(
            this.context.Study.MiddleName,
        )}`
        const deviceName = this.state.device?.Name || 'No associated device'
        const amplifierType = this.context.Study.AmplifierTypeID && AMP_FRIENDLY_NAMES[this.context.Study.AmplifierTypeID]
        const amplifierName = this.state.amplifier?.Name || 'No associated amplifier'
        const studyType = (this.context.Study.StudyType.ID && STUDY_TYPE_FRIENDLY_NAMES[this.context.Study.StudyType.ID])
            || this.context.Study?.StudyType?.Name
            || 'Unknown Study Type'
        const shouldDisplayTrackitPlus = !(!!this.state.device?.Name || !!this.state.amplifier?.Name)
        const versionInfoString =  this.context.Study?.StudyProps.find(e=> e.Name === 'AmpVersionJSON')
        const versionInfo = versionInfoString && versionInfoString.Value && JSON.parse(versionInfoString.Value)
        const hasVersionInformation = !!versionInfo

        return (
            <div className="review-overlay" >
                {
                    this.state.is_loading ? (
                        <LoadingSpinner />
                    ) : (
                        <>
                            <Grid container sx={{ flexWrap: 'nowrap' }} >
                                <Grid item xs={2} className="patient">
                                    <div>
                                        <i
                                            className={`icon-electrotek-person ${!this.context.Study.Patient
                                                .PatientID
                                                ? 'no-patient-id'
                                                : ''
                                            }`}
                                        />
                                        <div>
                                            <h4 title={fullPatientName}>
                                                {formattedPatientName}
                                            </h4>
                                            <p className="patient-id">
                                                {
                                                    this.context.Study.Patient
                                                        .PatientID
                                                }
                                            </p>
                                            <p className="patient-dob">
                                                {DateTimeUtils.getAbbreviatedDateOfBirth(
                                                    this.context.Study.Patient
                                                        .DateOfBirth,
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <dl>
                                        <dt>Type</dt>
                                        <dd>
                                            {studyType}
                                        </dd>
                                        <br />
                                        <dt>Created</dt>
                                        <dd>
                                            {DateTimeUtils.getFullStudyDateTimestamp(
                                                this.context.Study.DateCreated,
                                            )}
                                        </dd>
                                        <br />
                                        <dt>Duration</dt>
                                        <dd>
                                            {DateTimeUtils.getStudyDuration(
                                                this.context.Study
                                                    .TotalStudyRecordingDuration ||
                                                '',
                                            )}
                                        </dd>
                                        <br />
                                        {shouldDisplayTrackitPlus ?
                                            this.trackitPlusRender()
                                            : this.ampAndDeviceRender(deviceName, amplifierType, amplifierName)}
                                    </dl>
                                    <RoleBasedContent
                                        required_roles={[
                                            SUPER_ADMIN_ROLE,
                                            FACILITY_ADMIN_ROLE,
                                        ]}>
                                        <ChangePatientModal
                                            isOpen={this.state.is_assign_patient_modal_open}
                                            toggle={this.toggleChangePatient}
                                        />
                                        <Button
                                            sx={{ ml: 2 }}
                                            disabled={this.state.is_assign_patient_modal_open}
                                            onClick={this.toggleChangePatient}>
                                            Change Patient
                                        </Button>
                                    </RoleBasedContent>
                                </Grid>
                                { hasVersionInformation &&
                                    <VersionInformation versionInfo={versionInfo} />
                                }
                                <Grid item sx={{ overFlow: 'auto' }} className="recordings">
                                    {this.context.Study.StudyRecordings.map(
                                        recording => (
                                            <StudyRecordingCard
                                                key={recording.Index}
                                                recording={recording}
                                                setCanCloseReviewOverlay={cc => this.props.setCanCloseReviewOverlay(cc)}
                                            />
                                        ),
                                    )}
                                </Grid>
                            </Grid>
                            <StudyNotes
                                setCanCloseReviewOverlay={cc => this.props.setCanCloseReviewOverlay(cc)}
                            />
                            <StudyReport
                                setCanCloseReviewOverlay={cc => this.props.setCanCloseReviewOverlay(cc)}
                            />
                            <StudyStateView isTrackitPlus={shouldDisplayTrackitPlus} />
                        </>
                    )
                }
            </div>
        )
    }
}

export default ReviewOverlay
