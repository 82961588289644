import { Components } from '@mui/material/styles'

function Button(): Components['MuiButton'] {
    return {
        styleOverrides: {
            root: {
                borderRadius: 100,
            },
            contained: {
                color: '#fff',
                '&:hover': {
                    color: '#fff',
                },
                '&.Mui-disabled': {
                    background: '#777',
                    color: '#fff',
                    cursor: 'not-allowed',
                    pointerEvents: 'all',
                    '&:hover': {
                        color: '#fff',
                    },
                },
            },
        },
        defaultProps: {
            variant: 'contained',
            disableElevation: true,
            size: 'large',
        },
    }
}

export default Button
