import React, { useContext, useState, useEffect } from 'react'
import { TabContent } from 'reactstrap'
import { ToggleButtonGroup, ToggleButton  } from '@mui/material'
import StudyEventItem from './StudyEventItem'
import {
    ALL_EVENTS,
    SETTING_EVENTS,
    NOTE_EVENTS,
    STIMULATION_EVENTS,
    OTHER_EVENTS,
    HIDDEN_EVENTS,
    VIDEO_START,
    HIGH_CPU,
    WEAK_BLUETOOTH,
} from '../../constants/studyevents'
import StudyEvent from '../../types/StudyEvent'
import StudyEventAuth from '../../auth/StudyEventAuth'
import AppContext from '../../components/AppContext'
import ClockSetting from '../../types/ClockSetting'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { Moment } from 'moment'
import { hasRequiredRole } from '../../services/Auth'
import AsideMenu from './AsideMenu'

interface Props {
    selectedStudyEvent: string
    lastSelectedStudyEvent: string
    handleEventClick: (event: StudyEvent, moveGraph?: boolean) => void
    moveGraphToStudyEvent: (event: StudyEvent) => void
    handleEventDelete: (event: StudyEvent) => void
    handleEventEdit: (event: StudyEvent) => void
    areControlsMinimized: boolean
    forceEventListFocus: boolean
    resetForceEventListFocus: () => void
    isFocusedReview: boolean
    toggleFocusedReview: () => void
    clockSetting: ClockSetting
}

const StudyEventsList: React.FC<Props> = props => {
    const ctx = useContext(AppContext)
    const [active_events, toggleActiveEvents] = useState<string[]>(props.isFocusedReview ? ['important_events'] : [])
    /*
        'note_events',
        'stim_events',
        'setting_events',
        'other_events',
        important_events - special case
    */

    const { forceEventListFocus, resetForceEventListFocus } = props
    useEffect(() => {
        if (forceEventListFocus) {
            const eventListElement = document.getElementById('event-list')
            if (eventListElement) {
                eventListElement.focus()
            }
            resetForceEventListFocus()
        }
    }, [forceEventListFocus, resetForceEventListFocus])

    const getEvents = () => {

        let active_types: number[] = []

        if (active_events.includes('note_events')) {
            active_types = [...active_types, ...NOTE_EVENTS]
        }

        if (active_events.includes('stim_events')) {
            active_types = [...active_types, ...STIMULATION_EVENTS]
        }

        if (active_events.includes('setting_events')) {
            active_types = [...active_types, ...SETTING_EVENTS]
        }

        if (active_events.includes('other_events')) {
            active_types = [...active_types, ...OTHER_EVENTS]
        }

        // don't include important_events btn in the 'all event toggle'
        if (active_types.length === 0) {
            active_types = [...ALL_EVENTS]
        }

        // filter which events should be shown in the sidebar list
        const events: StudyEvent[] = ctx.Study.StudyEvents.filter((event: StudyEvent) => {
            if (HIDDEN_EVENTS.includes(event.EventTypeID)) return false
            if (event.EventTypeID === VIDEO_START && (!!event.Value && JSON.parse(event.Value).VideoIndex > 1)) return false
            if ([HIGH_CPU, WEAK_BLUETOOTH].includes(event.EventTypeID) && !hasRequiredRole(StudyEventAuth.ViewHighCPU, ctx.Roles)) return false
            const hasCorrectType = active_types.includes(event.EventTypeID)
            const hasRequiredImportant = props.isFocusedReview ? event.Important : true
            return hasCorrectType && hasRequiredImportant
        })

        events.sort(
            (a: StudyEvent, b: StudyEvent) => a.StartTimeRelativeToStudy - b.StartTimeRelativeToStudy !== 0 ? a.StartTimeRelativeToStudy - b.StartTimeRelativeToStudy : a.EventTypeID - b.EventTypeID,
        )

        return events
    }

    const handleDelete = (event: StudyEvent) => {
        props.handleEventDelete(event)
    }

    const handleEdit = (event: StudyEvent) => {
        props.handleEventEdit(event)
    }

    const handleClick = (event: StudyEvent) => {
        props.handleEventClick(event)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (!props.selectedStudyEvent) {
            return
        }
        const events = getEvents()
        const selected = props.selectedStudyEvent
        const curIndex = events.findIndex(event => event.ID === selected)
        let nextIndex = curIndex
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault()
                nextIndex += 1
                if (nextIndex >= events.length) {
                    return
                }
                handleClick(events[nextIndex])
                break
            case 'ArrowUp':
                e.preventDefault()
                nextIndex -= 1
                if (nextIndex < 0) {
                    return
                }
                handleClick(events[nextIndex])
                break
            default:
        }
        document
            .querySelectorAll('.event')
            .item(nextIndex)
            .scrollIntoView({ block: 'center' })
    }

    const events = getEvents()
    const selectedEvent = props.selectedStudyEvent
    const lastSelectedEvent = props.lastSelectedStudyEvent

    const renderItems = () => {
        let prevEventTime: Moment
        let prevEventDay: number

        return events.map(event => {
            let showDateSeparator = false
            let dateDisplay = ''

            let showDaySeparator = false
            let dayDisplay = ''

            const studyRecording = ctx.Study.StudyRecordings.find(r => r.Index === event.RecordingIndex)
            if (!studyRecording) {
                throw new Error(`unable to find study recording with index ${event.RecordingIndex}`)
            }
            const eventTime = DateTimeUtils.getMomentInTimeZone(studyRecording.DateStarted, studyRecording.TimeZone)
            eventTime.add(event.StartPacketIndex / ctx.Study.StudyDataRate, 'seconds')
            if (!prevEventTime || prevEventTime.date() !== eventTime.date()) {
                showDateSeparator = true
                dateDisplay = eventTime.format('MMMM D, YYYY')
            }
            prevEventTime = eventTime

            const eventDay = Math.floor(event.StartTimeRelativeToStudy / (60 * 60 * 24)) + 1
            if (prevEventDay && prevEventDay !== eventDay) {
                showDaySeparator = true
                dayDisplay = `${eventDay * 24 - 24} hours`
            }
            prevEventDay = eventDay
            const isCurrentSelected = event.ID === selectedEvent
            const isLastSelected = !isCurrentSelected && lastSelectedEvent === event.ID

            return (
                <div key={event.ID}>
                    {showDateSeparator && (
                        <div className="event-date-separator">
                            {dateDisplay}
                        </div>
                    )}
                    {showDaySeparator && (
                        <div className="event-day-separator">
                            {dayDisplay}
                        </div>
                    )}
                    <StudyEventItem
                        event={event}
                        isCurrentSelected={isCurrentSelected}
                        isLastSelected={isLastSelected}
                        isImportantFilterOn={props.isFocusedReview}
                        handleEdit={() => handleEdit(event)}
                        handleDelete={() => handleDelete(event)}
                        handleClick={() => handleClick(event)}
                        moveGraphToStudyEvent={props.moveGraphToStudyEvent}
                        clockSetting={props.clockSetting}
                    />
                </div>
            )
        })
    }

    return (
        <AsideMenu areControlsMinimized={props.areControlsMinimized}>
            <div className="aside-menu-icons">
                <ToggleButtonGroup
                    value={active_events}
                    onChange={(event: React.MouseEvent<HTMLElement>, value: string[]) => toggleActiveEvents(value)}

                    aria-label="text formatting">
                    <ToggleButton value="note_events" aria-label="note events">
                        <i
                            className="icon-electrotek-compose icon-btn"
                            title="Study Notes"
                        />
                    </ToggleButton>
                    <ToggleButton value="stim_events" aria-label="stim events">
                        <i
                            className="icon-electrotek-bolt icon-btn"
                            title="Stim Events"
                        />
                    </ToggleButton>
                    <ToggleButton value="setting_events" aria-label="setting events">
                        <i
                            className="icon-electrotek-controls icon-btn"
                            title="Settings Events"
                        />
                    </ToggleButton>
                    <ToggleButton value="other_events" aria-label="other events">
                        <i
                            className="icon-electrotek-dots-three-horizontal icon-btn"
                            title="Other Events"
                        />
                    </ToggleButton>
                    <ToggleButton value="important_events" onClick={props.toggleFocusedReview} 
                        style={{ position: 'absolute', right: 0 }} selected={props.isFocusedReview} aria-label="important events">
                        <i
                            style={{ fontSize: '20px', color: 'gold' }}
                            className="icon-electrotek-star icon-btn"
                            title="Important Events"                            
                        />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <TabContent
                onKeyDown={handleKeyDown}
                tabIndex={0}
                id="event-list"
                className="aside-content"
            >
                {renderItems()}
            </TabContent>
        </AsideMenu>
    )
}

export default StudyEventsList
