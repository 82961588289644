import React from 'react'
import './LoadingSpinner.scss'
import { CircularProgress } from '@mui/material'

const LoadingSpinner = ({ spinType = 'info', txtMsg = 'Loading ...', height = '50vh' }) => (
    <div className="loading-spinner" style={{ height }}>
        <CircularProgress color={spinType} />
        <div className="loading-spinner-txt">{txtMsg}</div>
    </div>
)

export default LoadingSpinner
