import Violations, { ViolationType, ViolationMessage } from './Violations'
import FileHeaderParser from '../../services/FileHeaderParser'
import FileUtils from '../../utils/FileUtils'
import moment from 'moment'

const validateTvsFile = async (violations: Violations, file: File, patientId: string, recordId: string) => {
    const addViolation = (msg: string) => {
        violations.add(ViolationType.VideoInfoFiles, ViolationMessage.InvalidFile, file.name, msg)
    }

    let headerAContents = ''
    try {
        headerAContents = await FileUtils.read(file, 256)
    } catch (e) {
        addViolation(`Unable to read file: ${(e as any)?.toString()}`)
        return
    }
    if (headerAContents.length !== 256) {
        addViolation(`Header A must be 256 characters (found ${headerAContents.length})`)
        return
    }

    const parser = new FileHeaderParser(headerAContents)

    const vidSyncVersion = parser.getString(0, 8).trim()
    if (!vidSyncVersion.match(/^tvs\d+$/)) {
        addViolation(`VidSyncVersion ${vidSyncVersion} is not valid`)
        return
    }

    const vidSyncPatientId = parser.getString(8, 80).trim()
    if (vidSyncPatientId.length === 0) {
        addViolation('VidSyncPatientId is required')
        return
    }

    if (patientId !== vidSyncPatientId) {
        addViolation('Patient id does not match')
        return
    }

    const vidSyncRecordId = parser.getString(88, 80).trim()
    if (vidSyncRecordId.length === 0) {
        addViolation('VidSyncRecordId is required')
        return
    }

    if (recordId !== vidSyncRecordId) {
        addViolation('Record id does not match')
        return
    }

    const vidSyncStartDateTime = moment(parser.getString(168, 16).trim(), 'DD.MM.YYHH.mm.ss')
    if (!vidSyncStartDateTime.isValid()) {
        addViolation(`VidSyncStartDate & VidSyncStartTime ${vidSyncStartDateTime} is not valid`)
        return
    }

    const vidSyncHeaderSize = parser.getString(184, 8).trim()
    if (parseInt(vidSyncHeaderSize).toString() !== vidSyncHeaderSize) {
        addViolation(`VidSyncHeaderSize must be numeric (found ${vidSyncHeaderSize})`)
        return
    }

    const vidSyncTotalEvents = parser.getString(192, 8).trim()
    if (parseInt(vidSyncTotalEvents).toString() !== vidSyncTotalEvents) {
        addViolation(`VidSyncTotalEvents must be numeric (found ${vidSyncTotalEvents})`)
        return
    }

    const vidSyncFrameRateX1000 = parser.getString(200, 8).trim()
    if (parseInt(vidSyncFrameRateX1000).toString() !== vidSyncFrameRateX1000) {
        addViolation(`VidSyncFrameRateX1000 must be numeric (found ${vidSyncFrameRateX1000})`)
        return
    }

    const vidSyncDroppedFrames = parser.getString(208, 8).trim()
    if (parseInt(vidSyncDroppedFrames).toString() !== vidSyncDroppedFrames) {
        addViolation(`VidSyncDroppedFrames must be numeric (found ${vidSyncDroppedFrames})`)
        return
    }

    const vidSyncLengthms = parser.getString(216, 10).trim()
    if (parseInt(vidSyncLengthms).toString() !== vidSyncLengthms) {
        addViolation(`VidSyncLengthms must be numeric (found ${vidSyncLengthms})`)
        return
    }

    const vidSyncLengthFrames = parser.getString(226, 8).trim()
    if (parseInt(vidSyncLengthFrames).toString() !== vidSyncLengthFrames) {
        addViolation(`VidSyncLengthFrames must be numeric (found ${vidSyncLengthFrames})`)
        return
    }

    const vidSyncManualOffsetms = parser.getString(234, 8).trim()
    if (parseInt(vidSyncManualOffsetms).toString() !== vidSyncManualOffsetms) {
        addViolation(`VidSyncManualOffsetms must be numeric (found ${vidSyncManualOffsetms})`)
        return
    }

    const vidSyncSoftwareVersion = parser.getString(242, 8).trim()
    if (!vidSyncSoftwareVersion.match(/^[0-9]+\.[0-9]+\.[0-9]+$/)) {
        addViolation(`VidSyncSoftwareVersion must be formated xx.yy.zz (found ${vidSyncSoftwareVersion})`)
        return
    }
}

export default validateTvsFile
