import { Button, ButtonProps } from '@mui/material'
import { ElementType } from 'react'
import useStyles from './NeutralButton.styles'

type Props<T extends ElementType> = ButtonProps<T, { component?: T }>

// This component can be used when the standard
// Mui Buttons do not have enough contrast with
// their background.
function NeutralButton<T extends ElementType>(props: Props<T>) {
    const classes = useStyles()

    return (
        <Button
            {...props}
            className={`${classes.root} ${props.className}`}
            variant="outlined"
        >
            {props.children}
        </Button>
    )
}

export default NeutralButton








