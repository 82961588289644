import React from 'react'

interface Props {
    needle: number
    isMinimized: boolean
}

const SpeedometerNeedle: React.FC<Props> = props => {
    const rotation = props.needle * 180 - 90
    let y = 58
    if (props.isMinimized) {
        y = 50
    }

    return (
        <svg viewBox="0 0 100 100">
            <circle cx="46" cy={y} r="4" />
            <polygon
                transform={`rotate(${rotation} 46 ${y})`}
                points={`42,${y} 50,${y} 46,${y-20}`}
            />
        </svg>
    )
}

export default SpeedometerNeedle
