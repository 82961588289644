/**
 * This enumeration defines an enumeration used by the API to define text message types that are
 * sent over the web socket.
 */

export const TextMessageType = Object.freeze({
    OriginalMontageUpload: { value: 1, name: 'OriginalMontageUpload' },
    CustomRequestedMontage: { value: 2, name: 'CustomRequestedMontage' },
    OriginalRequestedMontage: { value: 3, name: 'OriginalRequestedMontage' },
    NotchOn: { value: 4, name: 'NotchOn' },
    NotchFrequency: { value: 5, name: 'NotchFrequency' },
    FilterSettings: { value: 6, name: 'FilterSettings' },
    OriginalDataRequest: { value: 7, name: 'OriginalDataRequest' },
    CustomDataRequest: { value: 8, name: 'CustomDataRequest' },
    Error: { value: 9, name: 'Error' },
    StopRecordingEvents: { value: 10, name: 'StopRecordingEvents' },
    ClearCustomDataRequests: { value: 32, name: 'ClearCustomDataRequests' },
})

// close event codes
export const CLOSE_NORMAL = 1000
export const CLOSE_GOING_AWAY = 1001
export const CLOSE_PROTOCOL_ERROR = 1002
export const CLOSE_UNSUPPORTED_DATA = 1003
export const CLOSE_NO_STATUS = 1005
export const CLOSE_ABNORMAL = 1006
export const CLOSE_INVALID_FRAME = 1007
export const CLOSE_POLICY_VIOLATION = 1008
export const CLOSE_MESSAGE_TOO_BIG = 1009
export const CLOSE_MISSING_EXTENSION = 1010
export const CLOSE_INTERNAL_ERROR = 1011
export const CLOSE_SERVICE_RESTART = 1012
export const CLOSE_TRY_AGAIN_LATER = 1013
export const CLOSE_BAD_GATEWAY = 1014

export const OnCloseEventCodesFN = []
OnCloseEventCodesFN[CLOSE_NORMAL] = 'Normal Closure'
OnCloseEventCodesFN[CLOSE_GOING_AWAY] = 'Going Away'
OnCloseEventCodesFN[CLOSE_PROTOCOL_ERROR] = 'Protocol Error'
OnCloseEventCodesFN[CLOSE_UNSUPPORTED_DATA] = 'Unsupported Data'
OnCloseEventCodesFN[CLOSE_NO_STATUS] = 'No Status Recvd'
OnCloseEventCodesFN[CLOSE_ABNORMAL] = 'Abnormal Closure'
OnCloseEventCodesFN[CLOSE_INVALID_FRAME] = 'Invalid Frame Payload Data'
OnCloseEventCodesFN[CLOSE_POLICY_VIOLATION] = 'Policy Violation'
OnCloseEventCodesFN[CLOSE_MESSAGE_TOO_BIG] = 'Message Too Big'
OnCloseEventCodesFN[CLOSE_MISSING_EXTENSION] = 'Missing Extension'
OnCloseEventCodesFN[CLOSE_INTERNAL_ERROR] = 'Internal Error'
OnCloseEventCodesFN[CLOSE_SERVICE_RESTART] = 'Service Restart'
OnCloseEventCodesFN[CLOSE_TRY_AGAIN_LATER] = 'Try Again Later'
OnCloseEventCodesFN[CLOSE_BAD_GATEWAY] = 'Bad Gateway'

// socket states
export const CONNECTING_STATE = 0
export const OPEN_STATE = 1
export const CLOSING_STATE = 2
export const CLOSED_STATE = 3

export const readyStatesFN = []
readyStatesFN[CONNECTING_STATE] = 'Connecting'
readyStatesFN[OPEN_STATE] = 'Open'
readyStatesFN[CLOSING_STATE] = 'Closing'
readyStatesFN[CLOSED_STATE] = 'Closed'
