import React from 'react'
import { zxcvbn } from '../../utils/PasswordUtils'
import UsersApi from '../../services/UsersApi'
import UserForm from './UserForm'
import UserInvite from './UserInvite'
import FacilitiesApi from '../../services/FacilitiesApi'
import Validation from '../../services/Validation'
import NavUtils from '../../utils/NavUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import { hasRequiredRole } from '../../services/Auth'
import {
    FACILITY_ADMIN_ROLE,
    PRODUCTION_ROLE,
    ROLE_NAMES,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    REGISTERED_USER_ROLE,
} from '../../constants/roles'
import SessionManager from '../../services/SessionManager'
import ToastUtils from '../../utils/ToastUtils'
import AppContext from '../../components/AppContext'
import { Button } from '@mui/material'

class User extends React.Component {
    static contextType = AppContext

    state = {
        fieldValues: [],
        available_roles: [],
        is_saving: false,
        is_loading: true,
        is_new_user: false,
        is_invite_modal_open: false,
        invitee_email: '',
        invitee_role: '',
    }

    componentDidMount() {
        const isNewUser = this.props.creating
        NavUtils.setPageTitle(isNewUser ? 'Create User' : 'Edit User')

        isNewUser && this.setNewUser()
        !isNewUser && this.setEditUser()
    }

    handleApiError(error) {
        const isNotFound = error.status === 404
        const isBadRequest = error.status === 400
        if (isNotFound || isBadRequest) {
            window.location.href = '/404'
            return
        }

        ToastUtils.error({ message: (error.data && error.data.Message) || error.statusText })
    }

    toggleUserInvite = () => {
        this.setState({ is_invite_modal_open: !this.state.is_invite_modal_open })
    }

    setAvailableRoles(available_roles) {
        available_roles.unshift({ Id: '', Name: 'Please select a role' })
        this.setState({ available_roles })
    }

    setNewUser() {
        const isSuperAdmin = this.context.Roles.includes(SUPER_ADMIN_ROLE)
        let available_roles = ROLE_NAMES.map(r => ({ Id: r.id, Name: r.friendly_name, systemName: r.name }))
        available_roles.unshift({ Id: '', Name: 'Please select a role' })
        available_roles = available_roles.filter(r => r.systemName !== REGISTERED_USER_ROLE)
        available_roles = !isSuperAdmin ? available_roles.filter(r => r.systemName !== SUPER_ADMIN_ROLE) : available_roles

        this.setState({ is_new_user: true, options: available_roles, available_roles }, () => { this.setUser() })
    }

    setEditUser() {
        const userId = this.props.match.params.id
        UsersApi.getAvailableRolesForUser(userId)
            .then(response => {
                let user_roles = ROLE_NAMES.map(r => ({ Id: r.id, Name: r.friendly_name, systemName: r.name }))
                user_roles = user_roles.filter(role => response.data.some(respd => respd.Id === role.Id))
                this.setAvailableRoles(user_roles)

                UsersApi.getUserInfo(userId)
                    .then(user => this.setUser(user.data))
                    .catch(error => this.handleApiError(error))
            })
            .catch(error => this.handleApiError(error))
    }

    setUser(user) {
        SessionManager.get('mmt_facility_id')
        const currentFacilityId = SessionManager.get('mmt_facility_id')
        const currentFacilityUser = user && user.FacilityUsers.filter(facUser => facUser.FacilityID === currentFacilityId)[0]
        let userGlobalRoleId = (currentFacilityUser && currentFacilityUser.Roles[0]) || null

        // If only system role available, use that
        if (!userGlobalRoleId && user && user.IdentityRoles && user.IdentityRoles.length > 1) {
            const userGlobalRoles = user.IdentityRoles.filter(
                r => r !== 'RegisteredUser',
            )[0]
            userGlobalRoleId = this.state.available_roles
                .filter(r => r.systemName === userGlobalRoles)[0]
                .Id.toUpperCase()
        }

        const fieldValues = [
            { name: 'ID', value: (user && user.ID) || '' },
            { name: 'Title', value: (user && user.Title) || '' },
            { name: 'FirstName', value: (user && user.FirstName) || '' },
            { name: 'MiddleName', value: (user && user.MiddleName) || '' },
            { name: 'LastName', value: (user && user.LastName) || '' },
            { name: 'Suffix', value: (user && user.Suffix) || '' },
            { name: 'Email', value: (user && user.Email) || '' },
            { name: 'PhoneNumber', value: (user && user.PhoneNumber) || '' },
            {
                name: 'TwoFactorEnabled',
                value: (user && user.TwoFactorEnabled) || '',
            },
            { name: 'FacilityID', value: (user && user.FacilityID) || '' },
            { name: 'Password', value: (user && user.Password) || '' },
            {
                name: 'ConfirmPassword',
                value: (user && user.ConfirmPassword) || '',
            },
            {
                name: 'RoleId',
                value: (user && userGlobalRoleId) || '',
            },
            { name: 'TwoFactorEnabled', value: false },
        ]

        fieldValues.forEach((field, index) => {
            fieldValues[index].orig = field.value
        })

        this.setFieldValidations(fieldValues)
        this.setState({
            is_loading: false,
            fieldValues,
        })
    }

    setFieldValue(field, value, setOrig = false) {
        const fieldValues = this.state.fieldValues
        const index = this.getFieldIndex(field)
        fieldValues[index].value = value
        if (setOrig) {
            fieldValues[index].orig = value
        }
        this.setState({ fieldValues })
    }

    hasChanged = () => this.state.fieldValues.some(fv => fv.value !== fv.orig)

    clearLoadingState = () => {
        this.setState({ is_loading: false })
    }

    handleInviteChange = (field, value) => {
        switch (field) {
            case 'InviteeEmail':
                this.setState({ invitee_email: value })
                break
            case 'InviteeRole':
                this.setState({ invitee_role: value })
                break
            default:
        }
    }

    handleChange = (field, value) => {
        const { fieldValues } = this.state
        const index = this.getFieldIndex(field)
        if (field === 'Email') value = value.trim()
        fieldValues[index].value = value
        this.setState({ fieldValues })
    }

    handleSubmit = () => {
        if (!this.state.is_new_user) {
            this.updateUser()
        } else {
            this.createUser()
        }
    }

    setFieldValidations(userFields) {
        userFields.forEach(field => {
            field.hideValidityError = true
            switch (field.name) {
                case 'Title':
                case 'Suffix':
                    field.isValid = () =>
                        !!field.value.trim() || field.value.trim().length <= 100
                    break
                case 'FirstName':
                case 'LastName':
                    field.isValid = () =>
                        !!field.value.trim() && field.value.trim().length <= 200
                    break
                case 'MiddleName':
                    field.isValid = () =>
                        !field.value.trim() || field.value.trim().length <= 200
                    break
                case 'Email':
                    field.isValid = () =>
                        field.value && Validation.isValidEmail(field.value)
                    break
                case 'Password':
                    field.isValid = () =>
                        !this.state.is_new_user ||
                        (field.value.length >= 8 &&
                            field.value.length <= 100 &&
                            zxcvbn(field.value).feedback.warning === '')
                    break
                case 'ConfirmPassword':
                    field.isValid = () =>
                        this.getFieldValue('Password') ===
                        this.getFieldValue('ConfirmPassword')
                    break
                case 'RoleId':
                    field.isValid = () => this.getFieldValue('RoleId') !== ''
                    break
                default:
                    field.isValid = () => true
            }
        })
    }

    isFormValid = () => {
        const fieldValues = this.state.fieldValues
        let valid = true

        fieldValues.forEach(field => {
            valid = valid && field.isValid()
        })

        return valid
    }

    createUser() {
        const user = this.getUserFromFieldValues()

        if (this.isFormValid()) {
            this.setState({
                is_saving: true,
            })

            UsersApi.createUser(user)
                .then(response => {
                    this.setState(
                        {
                            is_saving: false,
                        },
                        () =>
                            UsersApi.getUserInfo(response.data.ID).then(
                                createduser => {
                                    this.setUser(createduser.data)
                                    NavUtils.setPageTitle('Edit User')
                                },
                                this.setState({ is_new_user: false }),
                                UsersApi.getAvailableRolesForUser(response.data.ID)
                                    .then(response => {
                                        let user_roles = ROLE_NAMES.map(r => ({ Id: r.id, Name: r.friendly_name, systemName: r.name }))
                                        user_roles = user_roles.filter(role => response.data.some(respd => respd.Id === role.Id))
                                        this.setAvailableRoles(user_roles)
                                    })
                                    .catch(error => this.handleApiError(error))
                            ),
                    )
                    ToastUtils.success({ message: 'User created!' })
                })
                .catch(error => {
                    if (error.status === 409) {
                        this.setState({
                            is_saving: false,
                        })
                        ToastUtils.error({ message: 'Email is already in use by a Rendr Platform user, and is already added to this facility.' })
                    } else {
                        this.setState({ is_saving: false })
                        if (error.data.Message === 'Already exists but not in facility') {
                            this.showInviteUser()
                            return
                        }
                        ToastUtils.error({
                            message:
                                (error.data && error.data.Message) ||
                                error.statusText ||
                                'Unable to create user',
                        })
                    }
                })
        }
    }

    updateUser() {
        const user = this.getUserFromFieldValues()
        if (this.isFormValid()) {
            this.setState({ is_saving: true })

            UsersApi.updateUser(
                this.props.match.params.id || this.getFieldValue('ID'),
                user,
            )
                .then(() => {
                    this.trimFieldValuesAndUpdateOrig()
                })
                .then(() => {
                    this.setState({ is_saving: false })
                    ToastUtils.success({ message: 'User saved!' })
                    if (user.ID === SessionManager.get('mmt_user_id')) {
                        SessionManager.set(
                            'mmt_user_name',
                            `${user.FirstName} ${user.LastName}`.trim(),
                        )
                        window.dispatchEvent(new Event('storage'))
                    }
                })
                .catch(error => {
                    this.setState({ is_saving: false })
                    console.log(error)
                    ToastUtils.error({
                        message:
                            (error.data && error.data.Message) ||
                            error.statusText ||
                            'Unable to save user',
                    })
                })
        }
    }

    getFieldIndex = field =>
        this.state.fieldValues.findIndex(c => c.name === field)

    getFieldValue = field => {
        const index = this.getFieldIndex(field)
        return index > -1 ? this.state.fieldValues[index].value : '_error_'
    }

    getFieldValidation = field => {
        const index = this.getFieldIndex(field)
        const isValid = this.state.fieldValues[index].isValid()
        return index > -1 ? isValid : false
    }

    getFieldValidityErrorDisplay = field => {
        const index = this.getFieldIndex(field)
        const shouldHideError = this.state.fieldValues[index].hideValidityError
        return index > -1 ? shouldHideError : false
    }

    getUserFromFieldValues() {
        const user = {}
        this.state.fieldValues.forEach(field => {
            let { value } = field
            if (typeof value === 'string') {
                value = value.trim()
            }
            Object.defineProperty(user, field.name, {
                value,
                writable: true,
                enumerable: true,
            })
            // update endpoint wants different property name than create endpoint
            if (field.name === 'RoleId') {
                Object.defineProperty(user, 'Role', {
                    value,
                    writable: true,
                    enumerable: true,
                })
            }
        })
        return user
    }

    trimFieldValuesAndUpdateOrig() {
        const fieldValues = [...this.state.fieldValues]
        for (const field of fieldValues) {
            let { value, name } = field
            if (typeof value === 'string' && name !== 'Password') {
                value = value.trim()
            }
            field.orig = value
            field.value = value
        }
        this.setState({ fieldValues })
    }

    onBlur = et => {
        const { fieldValues } = this.state
        const fieldIndex = this.getFieldIndex(et.id)
        if (!fieldIndex && fieldIndex !== 0) {
            console.log(
                '[User] Invalid field index retrieve during onBlur event',
            )
            return
        }
        fieldValues[fieldIndex].hideValidityError = false

        // hack: drop down isn't firing onblur so we 'hook' it to another field
        if (et.id === 'ConfirmPassword') {
            fieldValues[this.getFieldIndex('RoleId')].hideValidityError = false
        }

        this.setState(prevState => ({
            fieldValues: prevState.fieldValues,
        }))
    }

    canEdit = () =>
        hasRequiredRole(
            [
                SUPER_ADMIN_ROLE,
                SUPPORT_ROLE,
                PRODUCTION_ROLE,
                FACILITY_ADMIN_ROLE,
            ],
            this.context.Roles,
        )

    sendInvite = () => {
        const email = this.state.invitee_email
        const role = this.state.invitee_role

        FacilitiesApi.sendUserInvite(email, role)
            .then(response => {
                ToastUtils.success({ message: response.data || 'Invitation has been sent!' })
            })
            .catch(error => {
                ToastUtils.error({
                    message:
                        (error.data && error.data.Message) ||
                        'An error occurred sending the invitation!',
                })
            })
        this.toggleUserInvite()
    }

    showInviteUser = () => {
        const roleId = this.roleOptions().find(m => m.value === this.getFieldValue('RoleId'))?.value

        this.setState({
            invitee_email: this.getFieldValue('Email'),
            invitee_role: roleId,
        })

        this.toggleUserInvite()
    }

    roleOptions = () => {
        const options = this.state.available_roles.filter(
            r =>
                r.systemName !== SUPER_ADMIN_ROLE &&
                r.systemName !== SUPPORT_ROLE &&
                r.systemName !== PRODUCTION_ROLE,
        )
        return options.map(role => ({ name: role.Name, value: role.Id }))
    }

    render() {
        const has_changed = this.hasChanged()
        const can_edit = this.canEdit()

        return (
            <React.Fragment>
                <UserInvite
                    is_modal_open={this.state.is_invite_modal_open}
                    toggle={this.toggleUserInvite}
                    handleChange={this.handleInviteChange}
                    handleSendInvite={this.sendInvite}
                    roleOptions={this.roleOptions()}
                    email={this.state.invitee_email}
                    role={this.state.invitee_role}
                    message="This email is already in use by a Rendr Platform user, to add this user to your facility you need to send an invitation. Would you like to send an invitation now?"
                />
                {this.state.is_loading && <LoadingSpinner />}
                {!this.state.is_loading && (
                    <div className="col-xl-4 col-lg-6 px-0">
                        <h3>
                            <i className="icon-user" /> {this.state.is_new_user ? 'Create User' : 'Edit User'}
                        </h3>
                        <div>
                            <UserForm
                                {...this.state.user}
                                handleChange={(f, v) => this.handleChange(f, v)}
                                facilitiesList={this.state.facilitiesList}
                                availableRoles={this.state.available_roles}
                                getFieldValue={field =>
                                    this.getFieldValue(field)
                                }
                                getFieldValidation={field =>
                                    this.getFieldValidation(field)
                                }
                                getFieldValidityErrorDisplay={field =>
                                    this.getFieldValidityErrorDisplay(field)
                                }
                                excludeFields={
                                    !this.state.is_new_user && [
                                        'password',
                                        'confirmpassword',
                                    ]
                                }
                                readOnly={!can_edit}
                                handleBlur={this.onBlur}
                            />
                            <Button
                                disabled={this.state.is_saving || !has_changed || !this.isFormValid()}
                                onClick={this.handleSubmit}
                                sx={{ float: 'right' }}
                            >
                                {this.state.is_saving ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default User
