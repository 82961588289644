import md5 from 'blueimp-md5'

class ScreenUtils {
    static fingerprint(): string {
        const input = `${
            window.screen.width
        }${
            window.screen.height
        }${
            window.devicePixelRatio
        }${
            window.screen.pixelDepth
        }${
            window.screen.colorDepth
        }`
        return md5(input)
    }
}

export default ScreenUtils
