export const downloadData = (name: string, data: any) => {
    const link = document.createElement('a')
    link.download = name
    link.href = data
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const downloadRemoteFile = (name: string, url: string) => {
    fetch(url).then(res => res.blob()).then(blob => {
        downloadData(name, URL.createObjectURL(blob))
    })
}

export const downloadFile = (file: File) => {
    downloadData(file.name, URL.createObjectURL(file))
}