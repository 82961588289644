
import React, { useState } from 'react'
import { truncate, abbreviate } from '../../utils/StringUtils'
import StudiesListRow from './StudiesListRow'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import { Chip } from '@mui/material'

const StudiesList = props => {
    const [isExpanded, setIsExpanded] = useState(props.noExpandingList)

    const patientName = `${truncate(props.studies[0].LastName, 16)}, ${abbreviate(
        props.studies[0].FirstName,
    )} ${abbreviate(props.studies[0].MiddleName)}`

    return isExpanded ? (
        <React.Fragment>
            {!props.noExpandingList && (
                <TableRow className="studylist-header-row">
                    <TableCell colSpan={10}>
                        <IconButton onClick={() => setIsExpanded(i => !i)}>
                            <i className="icon icon-electrotek-chevron-down" />
                        </IconButton>
                        <b className="expand-column-name">
                            {props.studies[0].FirstName}&nbsp;{props.studies[0].LastName}
                        </b>
                        <Chip
                            color="primary"
                            sx={{ ml: 2 }}
                            size="small"
                            label={`${props.studies.length} studies`}
                        />
                    </TableCell>
                </TableRow>
            )}
            {
                props.studies.map(study => (
                    <StudiesListRow
                        key={study.ID}
                        onDelete={() => props.onDelete(study.ID)}
                        onRestore={() => props.onRestore(study.ID)}
                        study={study}
                        patientName={patientName}
                        toggleExpandedRow={() => { }}
                        studyCount={props.studies.length}
                        showShareModal={() => props.showShareModal(study)}
                        leftBorder={!props.noExpandingList}
                        wasRecentlyShared={props.recentlyShared.includes(study.ID)}
                    />
                ))
            }
        </React.Fragment >
    ) : (
        <StudiesListRow
            key={props.studies[0].ID}
            onDelete={() => props.onDelete(props.studies[0].ID)}
            onRestore={() => props.onRestore(props.studies[0].ID)}
            study={props.studies[0]}
            patientName={patientName}
            studyCount={props.studies.length}
            showShareModal={() => props.showShareModal(props.studies[0])}
            toggleExpandedRow={() => setIsExpanded(i => !i)}
            titleRow
            wasRecentlyShared={props.recentlyShared.includes(props.studies[0].ID)}
        />
    )
}

export default StudiesList
