import './MontageSearch.scss'
import Montage from '../../types/Montage'
import { useHistory } from 'react-router-dom'
import SortUtils, { SortDir } from '../../utils/SortUtils'
import SearchBar from '../../components/forms/SearchBar'
import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { Button } from '@mui/material'
import InputGroup from '../../components/forms/InputGroup'
import MontagesApi from '../../services/MontagesApi'
import SessionManager from '../../services/SessionManager'
import ToastUtils from '../../utils/ToastUtils'

interface Props {
    montages: Montage[]
    selectedMontage?: Montage
    isLoading: boolean
    onSearch: (searchString: string) => void
    onSelectMontage: (montage: Montage) => void
}

function MontageSearch(props: Props) {
    const { selectedMontage } = props
    const [sortOrder, setSortOrder] = useState<SortDir>('asc')
    const [isCreateMontageModalOpen, setIsCreateMontageModalOpen] = useState(false)
    const [isCreatingMontage, setIsCreatingMontage] = useState(false)
    const [newMontageName, setNewMontageName] = useState('')
    const history = useHistory()
    const montages = [...props.montages]

    useEffect(() => {
        setTimeout(() => {
            const selectedMontageDiv = document.querySelector('.selected')
            if (selectedMontageDiv) {
                selectedMontageDiv.scrollIntoView({ block: 'center', behavior: 'smooth' })
            }
        }, 10)
    }, [selectedMontage])

    SortUtils.string(montages, 'Name', sortOrder)

    useEffect(() => {
        const listener = (e: KeyboardEvent) => {
            if (e.key !== 'ArrowUp' && e.key !== 'ArrowDown') return
            if (e.repeat) return
            if (!selectedMontage) return
            let step = 0
            if (e.key === 'ArrowUp') step = -1
            if (e.key === 'ArrowDown') step = 1
            for (let i = 0; i < montages.length; i += 1) {
                if (montages[i].ID === selectedMontage.ID) {
                    if (i + step < 0 || i + step >= montages.length) return
                    props.onSelectMontage(montages[i + step])
                }
            }
        }
        window.addEventListener('keydown', listener)
        return () => window.removeEventListener('keydown', listener)
    })

    const handleCreateMontage = () => {
        setIsCreatingMontage(true)
        const newMontage = {
            Name: newMontageName.trim(),
            Channels: [],
            FacilityID: SessionManager.get('mmt_facility_id'),
        }
        MontagesApi.createMontage(newMontage).then(res => {
            setIsCreatingMontage(false)
            history.push(`/Montage/${res.data.ID}`)
        }).catch(err => {
            setIsCreatingMontage(false)
            ToastUtils.error({ message: `Unable to create montage: ${err?.data?.Message ?? 'Unknown reason'}` })
            console.log(err)
        })
    }

    return (
        <div className="montage-search">
            <div className="input">
                <i
                    title="Create montage"
                    className="create-icon icon-electrotek-add-montage"
                    onClick={() => setIsCreateMontageModalOpen(true)}
                />
                <i
                    className={`sort-icon fa fa-fw fa-sort-amount-${sortOrder === 'asc' ? 'down' : 'up'}`}
                    onClick={() => setSortOrder(o => o === 'asc' ? 'desc' : 'asc')}
                    title="Sort montages"
                />
                <SearchBar
                    onSearch={props.onSearch}
                    searching={props.isLoading}
                    maxSearchStringLength={100}
                />
            </div>
            <div className="results">
                {props.isLoading ? 'Loading...' : montages.map(montage => (
                    <div
                        key={montage.ID}
                        className={`montage ${selectedMontage && montage.ID === selectedMontage.ID ? 'selected' : ''}`}
                        onClick={() => props.onSelectMontage(montage)}
                    >
                        {montage.Name}
                    </div>
                ))}
            </div>
            <Modal isOpen={isCreateMontageModalOpen} toggle={() => setIsCreateMontageModalOpen(i => !i)}>
                <ModalHeader toggle={() => setIsCreateMontageModalOpen(i => !i)}>
                    Create New Montage
                </ModalHeader>
                <ModalBody>
                    <div style={{ padding: '20px 20px', paddingBottom: 0 }}>
                        <InputGroup
                            maxLength={50}
                            label="Name"
                            value={newMontageName}
                            handleChange={(_: string, name: string) => setNewMontageName(name)}
                            onEnter={handleCreateMontage}
                        />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={() => setIsCreateMontageModalOpen(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCreateMontage}
                        disabled={isCreatingMontage || newMontageName === ''}
                    >
                        Creat{isCreatingMontage ? 'ing...' : 'e'}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default MontageSearch
