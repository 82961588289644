import { useEffect } from 'react'
import SessionManager from '../services/SessionManager'
import setupWorker from '../workers/setup'
import intervalWorker from '../workers/interval'

const usePreventAutoLogout = () => {
    useEffect(() => {
        const worker = setupWorker(intervalWorker)
        const intervalId = 'usePreventAutoLogout'
        worker.postMessage({
            type: 'set',
            ms: 10000,
            id: intervalId,
        })
        worker.onmessage = () => {
            SessionManager.set('activity', Date.now())
        }
        return () => worker.postMessage({
            type: 'clear',
            id: intervalId,
        })
    }, [])
}

export default usePreventAutoLogout
