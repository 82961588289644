import React from 'react'
import LoadedSegment from './LoadedSegment'

interface Props {
    packetsSegmentsLoaded: LoadedSegment[]
    packetCount: number
}

const ScrubberLoadedData: React.FC<Props> = props => {
    const segments = props.packetsSegmentsLoaded.map((seg: LoadedSegment) => (
        <div
            className="section loaded"
            key={`${seg.start}${seg.end}`}
            style={{
                left: `${(100 * seg.start) / props.packetCount}%`,
                width: `${(100 * (seg.end - seg.start)) / props.packetCount}%`,
            }}
        />
    ))

    return <>{segments}</>
}

export default ScrubberLoadedData
