import {
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { useEffect, useState } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner'
import ModalBool from '../../components/modals/ModalBool'
import ShareStudyModal from '../../components/modals/ShareStudyModal'
import NotSet from '../../components/NotSet'
import PatientsApi from '../../services/PatientsApi'
import StudiesApi from '../../services/StudiesApi'
import Patient from '../../types/Patient'
import Study from '../../types/Study'
import SortUtils from '../../utils/SortUtils'
import ToastUtils from '../../utils/ToastUtils'
import PatientStudiesRow from './PatientStudiesRow'

interface Props {
    patient: Patient
}

function PatientStudies(props: Props) {
    const [studies, setStudies] = useState<Study[] | null>(null)
    const [studyToDelete, setStudyToDelete] = useState<Study | null>(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [studyToRestore, setStudyToRestore] = useState<Study | null>(null)
    const [studyToShare, setStudyToShare] = useState<Study | null>(null)
    const [isRestoring, setIsRestoring] = useState(false)

    const patientId = props.patient.ID
    useEffect(() => {
        PatientsApi.getStudies(patientId, true, true)
            .then((res: {data: Study[]}) => {
                SortUtils.date(res.data, 'DateCreated', 'desc')
                setStudies(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, [patientId])

    const handleDelete = (id: string) => {
        setIsDeleting(true)
        StudiesApi.deleteStudy(id).then(() => {
            setStudies(ss => {
                if (!ss) return ss
                return ss.map(s => {
                    if (s.ID === id) {
                        return { ...s, DateDeleted: moment().format() }
                    }
                    return s
                })
            })
        }).catch(err => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to mark study for deletion' })
        }).finally(() => {
            setIsDeleting(false)
            setStudyToDelete(null)
        })
    }

    const handleRestore = (id: string) => {
        setIsRestoring(true)
        StudiesApi.restoreStudy(id).then(() => {
            setStudies(ss => {
                if (!ss) return ss
                return ss.map(s => {
                    if (s.ID === id) {
                        return { ...s, DateDeleted: null }
                    }
                    return s
                })
            })
        }).catch(err => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to restore study' })
        }).finally(() => {
            setIsRestoring(false)
            setStudyToRestore(null)
        })
    }

    const handleNewShare = (id: string) => {
        setStudies(ss => {
            if (!ss) return ss
            return ss.map(s => {
                if (s.ID !== id) return s
                const share = {
                    StudyID: id,
                    UserID: '',
                    ID: '',
                    DateCreated: '',
                }
                if (s.Shares) {
                    return {
                        ...s,
                        Shares: [...s.Shares, share],
                    }
                }
                return {
                    ...s,
                    Shares: [share],
                }
            })
        })
    }

    if (studies === null) {
        return <LoadingSpinner />
    }

    return (
        <Box sx={{ pt:2, pb: 2 }}>
            <Typography variant="subtitle1" sx={{ mt: 0, mb: 1 }}>
                {studies.length} studies found
            </Typography>
            {studyToShare && (
                <ShareStudyModal
                    toggle={() => setStudyToShare(null)}
                    study={studyToShare}
                    handleShare={handleNewShare}
                />
            )}
            {studyToDelete && (
                <ModalBool
                    isOpen
                    loading={isDeleting}
                    toggle={() => setStudyToDelete(null)}
                    title="Mark Study for Deletion"
                    trueButtonText="Yes"
                    falseButtonText="No"
                    handleTrue={() => handleDelete(studyToDelete.ID)}
                    handleFalse={() => setStudyToDelete(null)}
                >
                    <p>Are you sure you want to mark this study for deletion?</p>
                    <p>Patient Name: {studyToDelete.LastName}, {studyToDelete.FirstName} {studyToDelete.MiddleName}</p>
                    <p>Patient ID: {studyToDelete.PatientId || <NotSet />}</p>
                    <p style={{ margin: 0 }}>Study ID: {studyToDelete.ExamId || <NotSet />}</p>
                </ModalBool>
            )}
            {studyToRestore && (
                <ModalBool
                    isOpen
                    loading={isRestoring}
                    toggle={() => setStudyToRestore(null)}
                    title="Restore Study"
                    trueButtonText="Yes"
                    falseButtonText="No"
                    handleTrue={() => handleRestore(studyToRestore.ID)}
                    handleFalse={() => setStudyToRestore(null)}
                >
                    <p>Are you sure you want to restore this study?</p>
                    <p>Patient Name: {studyToRestore.LastName}, {studyToRestore.FirstName} {studyToRestore.MiddleName}</p>
                    <p>Patient ID: {studyToRestore.PatientId || <NotSet />}</p>
                    <p style={{ margin: 0 }}>Study ID: {studyToRestore.ExamId || <NotSet />}</p>
                </ModalBool>
            )}
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell sx={{ minWidth: '150px' }}>Video</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {studies.map(study => (
                            <PatientStudiesRow
                                key={study.ID}
                                study={study}
                                onDelete={() => setStudyToDelete(study)}
                                onRestore={() => setStudyToRestore(study)}
                                onShare={() => setStudyToShare(study)}
                            />
                        ))}
                        {studies.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={6}>No studies for this patient</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

export default PatientStudies
