import { Component } from 'react'
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from 'reactstrap'
import { Button } from '@mui/material'
import { Redirect } from 'react-router-dom'
import ToastUtils from '../../utils/ToastUtils'
import { SYNCED } from '../../constants/syncstates'
import StorageManager from '../../services/StorageManager'
import StudiesApi from '../../services/StudiesApi'
import StudyEventsApi from '../../services/StudyEventsApi'
import ThemesApi from '../../services/ThemesApi'
import EEGContainer from './EEGContainer'
import NavUtils from '../../utils/NavUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import AppContext from '../../components/AppContext'
import { Action } from '../../components/AppState'

class Study extends Component {
    static contextType = AppContext

    refreshDataRateIntervalId = null
    refreshStudyIntervalId = null
    redirect_to_studies_list = false

    state = {
        study: { StudyEvents: {} },
        is_loading: true,
        userEegTheme: null,
        is_sync_warning_modal_open: false,
    }

    componentDidMount() {
        this.pollStudy()
        ThemesApi.getMyTheme()
            .then(res => {
                this.setUserEegTheme(res.data)
            })
            .catch(err => {
                console.log(`getMyTheme error: ${err.statusText}`)
            })
    }

    pollStudy = () => {
        this.getStudy()
        this.refreshStudyIntervalId = setInterval(this.getStudy, 90000)
    }

    getStudy = () => {
        StudiesApi.getStudy(this.props.match.params.id)
            .then(res => {
                const info = res.data
                const study = info.Study

                if (!study.StudyRecordings || study.StudyRecordings.length === 0) {
                    ToastUtils.error({ message: 'The study you attempted to open has no recordings.' })
                    this.redirect_to_studies_list = true
                }

                // filter out events that are beyond what has been synced so far
                const studyEvents = study.StudyEvents.filter(e => {
                    const recordingSyncIndex = study.StudyRecordings[e.RecordingIndex - 1].SyncPacketIndex
                    const eventEndIndex = e.EndPacketIndex ?? e.StartPacketIndex
                    return eventEndIndex <= recordingSyncIndex
                })

                study.StudyEvents = studyEvents
                this.setStudy(info)
            })
            .catch(err => {
                const isNotFound = err.status === 404
                if (isNotFound) {
                    window.location.href = '/404'
                }
            })
    }

    componentWillUnmount() {
        this.studyUnmounted = true
        this.context.commit(Action.SetStudy, {})
        clearInterval(this.refreshDataRateIntervalId)
        clearInterval(this.refreshStudyIntervalId)
    }

    setStudy = study => {
        if (this.studyUnmounted) return

        NavUtils.setPageTitle(
            `${study.Patient.LastName}, ${study.Patient.FirstName}`,
        )

        // currently the api returns the datarate in two areas: studyrecording & studytype
        // studytype is no longer correct with new trackit integration so as a stop gap
        // we are going to add a new property to the top level study object which will be
        // pulled from the first study recording. For instances where there is no study recordings
        // yet we will default it and set reoccuring callback to get it from the first study recording
        // when it becomes available. 3/16/19 - CK
        if (study.Study.StudyRecordings.length === 0) {
            study.Study.StudyDataRate = 1
            this.refreshDataRateIntervalId = setInterval(() => {
                this.refreshDataRate()
            }, 5000)
        } else {
            study.Study.StudyDataRate =
                study.Study.StudyRecordings[0].DataRateDecimal
        }

        this.context.commit(Action.SetStudy, {
            ...study.Study,
            Patient: study.Patient,
        })
        this.restrictHighFreqOptionsAndDefaults(study)
        this.setState({
            study,
            is_loading: false,
            is_sync_warning_modal_open: this.hasSyncError(study.Study),
        })
    }

    setUserEegTheme = userEegTheme => {
        if (this.studyUnmounted) return
        this.setState({ userEegTheme })
    }

    async refreshDataRate() {
        if (this.state.study.Study.StudyDataRate > 1) {
            clearInterval(this.refreshDataRateIntervalId)
            return
        }

        try {
            console.log('[DataRate] Attempting to retrieve study datarate')
            const result = await StudiesApi.getStudyRecordings(
                this.state.study.Study.ID,
                1,
            )
            if (result.data.StudyRecordings.length > 0) {
                clearInterval(this.refreshDataRateIntervalId)
                this.setDataRate(result.data.StudyRecordings[0].DataRateDecimal)
                console.log('[DataRate] Datarate set from study recording')
            }
            console.log('[DataRate] No study recordings available')
        } catch (err) {
            console.log('[DataRate] Unable to retrieve data rate for study')
        }
    }

    setDataRate(dataRate) {
        const study = this.state.study
        study.StudyDataRate = dataRate
        this.context.commit(Action.SetStudy, {
            ...study.Study,
            Patient: study.Patient,
        })
        this.restrictHighFreqOptionsAndDefaults(study)
        this.setState({ study })
    }

    restrictHighFreqOptionsAndDefaults = study => {
        const maxHighFreq = study.Study.StudyDataRate / 2 - 10

        // restrict the user's options
        for (let i = study.HighFilterSettings.length - 1; i > 0; i -= 1) {
            const option = study.HighFilterSettings[i]
            if (parseInt(option.Value, 10) > maxHighFreq) {
                study.HighFilterSettings.splice(i, 1)
            }
        }

        // restrict / alter the facility defaults
        const filterValues = study.FacilityDefaultSettings.DefaultFilterSettings.HighLowFrequencyFilterValues
        for (const filterValue of filterValues) {
            if (filterValue.HighFrequencyFilterValue > maxHighFreq) {
                const lastVal = study.HighFilterSettings[study.HighFilterSettings.length - 1].Value
                filterValue.HighFrequencyFilterValue = parseInt(lastVal)
            }
        }
    }

    handleNewStudyEvent = event =>
        new Promise((resolve, reject) => {
            StudyEventsApi.newStudyEvent(event)
                .then(res => {
                    this.context.commit(Action.AddStudyEvent, res.data)
                    this.setState({ last_selected_study_event_id: res.data.ID },
                        StorageManager.set(
                            `study:${this.context.Study.ID}:LastSelectedEvent`,
                            res.data.ID,
                        ))
                    resolve(res.data.ID)
                })
                .catch(reject)
        })

    handleEditStudyEvent = async event => {
        await StudyEventsApi.updateStudyEvent(event.ID, event)
        this.context.commit(Action.UpdateStudyEvent, event)
    }

    handleDeleteStudyEvent = async event => {
        await StudyEventsApi.deleteStudyEvent(event.ID)
        this.context.commit(Action.DeleteStudyEvent, event)
    }

    // A sync error is when a study recording has SyncState of SYNCED
    // but the SyncPacketIndex values are not equal to the PacketCount values.
    hasSyncError = study => {
        if (!study.StudyRecordings) return false
        const badRecording = study.StudyRecordings.find(r => r.SyncState === SYNCED && r.SyncPacketIndex !== r.PacketCount)
        return badRecording !== undefined
    }

    toggleSyncWarningModal = () => {
        this.setState({ is_sync_warning_modal_open: !this.state.is_sync_warning_modal_open })
    }

    render() {
        const userEegTheme = this.state.userEegTheme
        if (this.redirect_to_studies_list) { return <Redirect to="/Studies" /> }
        return (
            <div>
                {this.state.is_loading ? (
                    <LoadingSpinner />
                ) : (
                    <>
                        {this.state.is_sync_warning_modal_open && (
                            <Modal isOpen toggle={this.toggleSyncWarningModal}>
                                <ModalHeader>Warning</ModalHeader>
                                <ModalBody>
                                    <p style={{ padding: '20px', margin: 0 }}>
                                        This study has not been properly synchronized,{' '}
                                        you may experience difficulties viewing this study.{' '}
                                        Please contact customer service: support@Lifelinesneuro.com.
                                    </p>
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={this.toggleSyncWarningModal}>OK</Button>
                                </ModalFooter>
                            </Modal>
                        )}
                        <EEGContainer
                            {...this.state.study}
                            userEegTheme={userEegTheme}
                            handleNewStudyEvent={this.handleNewStudyEvent}
                            handleEditStudyEvent={this.handleEditStudyEvent}
                            handleDeleteStudyEvent={this.handleDeleteStudyEvent}
                        />
                    </>
                )}
            </div>
        )
    }
}

export default Study
