import React from 'react'
import './OptionsMenu.scss'
import { Link } from 'react-router-dom'
import { Grid } from '@mui/material'
import GraphSettingsDropdown from './GraphSettingsDropdown'
import {
    SETTING_NOTCH_FILTER,
    SETTING_SENSITIVITY,
    SETTING_HIGH_FILTER,
    SETTING_TIMEBASE,
    SETTING_MONTAGE,
    SETTING_LOW_FILTER,
} from '../../../constants/graphsettings'
import { NOTE } from '../../../constants/studyevents'

class OptionsMenu extends React.Component {
    montageOptions = () =>
        this.props.options.Montages.map(setting => ({
            Value: setting.Name,
            Units: '',
        }))

    lowFilterOptions = () =>
        this.props.options.LowFilterSettings.map(setting => ({
            Value: setting.Value,
            Units: setting.Units,
        }))

    highFilterOptions = () =>
        this.props.options.HighFilterSettings.map(setting => ({
            Value: setting.Value,
            Units: setting.Units,
        }))

    notchFilterOptions = () => {
        const options = this.props.options.NotchFilterSettings.map(setting => ({
            Value: setting.Value,
            Units: setting.Units,
        }))

        options.push({
            Value: 'Off',
            Units: '',
        })

        return options
    }

    sensitivityOptions = () =>
        this.props.options.SensitivitySettings.map(setting => ({
            Value: setting.Value,
            Units: setting.Units,
        }))

    timebaseOptions = () =>
        this.props.options.TimebaseSettings.map(setting => ({
            Value: setting.Value,
            Units: setting.Units,
        }))

    render() {
        return (
            <Grid container className="options-menu flex-nowrap">
                <div className="btn-graph-back-container">
                    <Link
                        className="icon-electrotek-arrow-left btn btn-graph-back"
                        to="/Studies"
                        title="Exit Graph"
                    />
                </div>
                <GraphSettingsDropdown
                    type={SETTING_MONTAGE}
                    displayName="Montage"
                    currentSetting={{ Value: this.props.settings.Montage.Name }}
                    options={this.montageOptions()}
                    handleSettingSelect={this.props.handleSettingSelect}
                    disabled={this.props.isReviewOverlayOpen}
                />

                <GraphSettingsDropdown
                    type={SETTING_LOW_FILTER}
                    displayName="Low Frequency"
                    currentSetting={this.props.settings.LowFilter}
                    options={this.lowFilterOptions()}
                    handleSettingSelect={this.props.handleSettingSelect}
                    disabled={this.props.isReviewOverlayOpen}
                />

                <GraphSettingsDropdown
                    type={SETTING_HIGH_FILTER}
                    displayName="High Frequency"
                    currentSetting={this.props.settings.HighFilter}
                    options={this.highFilterOptions()}
                    handleSettingSelect={this.props.handleSettingSelect}
                    disabled={this.props.isReviewOverlayOpen}
                />

                <GraphSettingsDropdown
                    type={SETTING_NOTCH_FILTER}
                    displayName="Notch"
                    currentSetting={this.props.settings.NotchFilter}
                    options={this.notchFilterOptions()}
                    handleSettingSelect={this.props.handleSettingSelect}
                    disabled={this.props.isReviewOverlayOpen}
                />

                <GraphSettingsDropdown
                    type={SETTING_SENSITIVITY}
                    displayName="Sensitivity"
                    currentSetting={this.props.settings.Sensitivity}
                    options={this.sensitivityOptions()}
                    handleSettingSelect={this.props.handleSettingSelect}
                    disabled={this.props.isReviewOverlayOpen}
                />

                <GraphSettingsDropdown
                    type={SETTING_TIMEBASE}
                    displayName="Time Base"
                    currentSetting={this.props.settings.Timebase}
                    options={this.timebaseOptions()}
                    handleSettingSelect={this.props.handleSettingSelect}
                    disabled={this.props.isReviewOverlayOpen}
                />
                <div className="options-subpanel">
                    <button
                        type="button"
                        title="Review Info (I)"
                        className={`btn btn-icon ${this.props.isReviewOverlayOpen ? 'active' : ''}`}
                        onClick={this.props.handleToggleReviewOverlay}
                    >
                        <i
                            id="review-button"
                            className="icon icon-electrotek-info"
                        />
                    </button>

                    <button
                        type="button"
                        title="Quick Note (N)"
                        className={`btn btn-icon ${this.props.isQuickNotesOpen ? 'active' : ''}`}
                        onClick={this.props.handleToggleQuickNotes}
                        disabled={
                            this.props.isReviewOverlayOpen ||
                            !this.props.areCurrentEpochPacketsLoaded
                        }
                    >
                        <i
                            id="quick-note-button"
                            className="icon icon-electrotek-note-quick"
                        />
                    </button>

                    <button
                        type="button"
                        title="Add Note"
                        className={`btn btn-icon new-note ${this.props.creatingEventOfType === NOTE
                            ? 'active'
                            : ''}`}
                        onClick={() =>
                            this.props.toggleCreateEventMode(NOTE)
                        }
                        disabled={
                            this.props.isReviewOverlayOpen ||
                            !this.props.areCurrentEpochPacketsLoaded
                        }
                    >
                        <i className="icon icon-electrotek-note_add" />
                    </button>
                    <button
                        type="button"
                        title="Study Events (E)"
                        className={`btn btn-icon ${this.props.isStudyEventsOpen ? 'active' : ''}`}
                        onClick={this.props.handleToggleStudyEvents}
                        disabled={this.props.isReviewOverlayOpen}
                    >
                        <i
                            className={`icon icon-electrotek-archive ${this.props.isStudyEventsOpen ? 'active' : ''}`}
                        />
                    </button>
                    <button
                        type="button"
                        title="More Options Menu"
                        className={`btn btn-icon ${this.props.isMoreOptionsMenuOpen ? 'active' : ''}`}
                        onClick={this.props.handleToggleMoreOptionsMenu}
                        disabled={this.props.isReviewOverlayOpen}
                    >
                        <i className="icon icon-electrotek-dots-three-vertical" />
                    </button>
                </div>                    
            </Grid>
        )
    }
}

export default OptionsMenu
