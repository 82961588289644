import { Alert } from '@mui/material'

interface Props {
    isVisible: boolean
    message: string
    handleRemove: () => void
}

function ErrorMessage(props: Props) {
    if (!props.isVisible) {
        return null
    }

    return (
        <Alert
            severity="success"
            onClose={props.handleRemove}
        >
            {props.message}
        </Alert>
    )
}

export default ErrorMessage