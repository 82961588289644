const valueSeparator = ']||'
const storageMethod = localStorage
const permanentKeys = ['mmt_calibrated_screens']

const SessionManager = {
    get(key) {
        const storedVal = storageMethod.getItem(key)
        if (storedVal === null) {
            return null
        }
        if (this.removeExpiredValueCheck(key)) {
            return null
        }
        const result = this.seperateExpDateAndValue(storedVal)
        return result.value
    },

    set(key, value, minutesTillExpiration = 0) {
        value = this.addExpDateToValue(value, minutesTillExpiration)
        return storageMethod.setItem(key, value)
    },

    remove(key) {
        storageMethod.removeItem(key)
    },

    clear() {
        for (const key of Object.keys(storageMethod)) {
            if (!permanentKeys.includes(key)) {
                storageMethod.removeItem(key)
            }
        }
    },

    // #region 'PRIVATE' METHODS
    // ------------------------------------------------------------------------

    // checks and removes expired key|value from session storage
    removeExpiredValueCheck(key) {
        const storedValue = storageMethod.getItem(key)
        if (storedValue === null) {
            return false
        }
        if (this.doesValueHaveExpDate(storedValue)) {
            const result = this.seperateExpDateAndValue(storedValue)
            if (Date.now() > result.expDate) {
                this.remove(key)
                return true
            }
        }
        return false
    },

    // does passed value contain an expiration date?
    doesValueHaveExpDate(value) {
        if (value === null || value === undefined || value.length === 0) {
            return false
        }
        return value[0] === '[' && value.indexOf(valueSeparator) > 0
    },

    // returns object containing expDate and value as seperate properties
    seperateExpDateAndValue(value) {
        if (this.doesValueHaveExpDate(value)) {
            const seperatorLocationStart = value.indexOf(valueSeparator)
            const seperatorLocationEnd =
                seperatorLocationStart + valueSeparator.length
            const expDate = value.substr(1, seperatorLocationStart - 1)
            return {
                expDate: new Date(expDate),
                value: value.substr(seperatorLocationEnd),
            }
        }
        return { expDate: null, value }
    },

    addExpDateToValue(value, minutesTillExpiration) {
        if (minutesTillExpiration <= 0) {
            return value
        }
        const expirationDate = new Date()
        expirationDate.setMinutes(
            expirationDate.getMinutes() + minutesTillExpiration,
        )
        const valueToStore = `[${expirationDate}${valueSeparator}${value}`
        return valueToStore
    },
    // #endregion
}

export default SessionManager
