import axios from 'axios'
import MMTApi from './MMTApi'

const baseUrl = `${process.env.REACT_APP_API_URL}/api/Logs`

const ControlSoftwareLogsApi = {
    getDeviceLogs: guid =>
        axios.get(
            `${baseUrl}/GetDeviceLogs?deviceId=${guid}&maxSearchResults=100`,
            MMTApi.getRequestHeaders(),
        ),
}

export default ControlSoftwareLogsApi
