// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

class DataRequest {
    constructor(
        startMinute,
        endMinute,
        priorityMinute,
        settingsChangeId,
        montageTemplateId,
        isOriginalData,
    ) {
        this.isOriginalData = isOriginalData || false
        this.startMinute = startMinute
        this.endMinute = endMinute
        this.priorityMinute = priorityMinute
        this.settingsChangeId = settingsChangeId
        this.montageTemplateId = montageTemplateId
    }
}

export default DataRequest
