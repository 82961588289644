import React from 'react'
import './StudyRecordingCard.scss'
import { Input } from 'reactstrap'
import { Card, CardContent, CardHeader } from '@mui/material'
import StudiesApi from '../../services/StudiesApi'
import DateTimeUtils from '../../utils/DateTimeUtils'
import FunctionUtils from '../../utils/FunctionUtils'
import AppContext from '../AppContext'
import { Action } from '../AppState'
import ToastUtils from '../../utils/ToastUtils'

class StudyRecordingCard extends React.Component {
    static contextType = AppContext

    constructor() {
        super()
        this.state = {
            notes: null,
            is_saving: false,
            is_saved_msg_visible: false,
            saved_msg_timeout: null,
        }
        this.updateNotes = FunctionUtils.debounce(this.updateNotes, 500)
    }

    componentWillUnmount() {
        clearTimeout(this.state.saved_msg_timeout)
    }

    handleNotesChange = event => {
        this.props.setCanCloseReviewOverlay(false)
        this.setState({ notes: event.target.value })
        this.updateNotes()
    }

    updateNotes() {
        this.setState({ is_saving: true })
        const studyRecordingNote = {
            StudyID: this.context.Study.ID,
            Index: this.props.recording.Index,
            Notes: this.state.notes,
        }

        StudiesApi.updateStudyRecordingNotes(studyRecordingNote)
            .then(() => {
                this.flashSavedMessage()
                const StudyRecordings = [...this.context.Study.StudyRecordings]
                StudyRecordings[this.props.recording.Index - 1] = {
                    ...StudyRecordings[this.props.recording.Index - 1],
                    Notes: this.state.notes,
                }
                this.context.commit(Action.UpdateStudy, { StudyRecordings })
                this.setState({ is_saving: false })
                this.props.setCanCloseReviewOverlay(true)
            })
            .catch(() => {
                this.setState({ is_saving: false })
                ToastUtils.error({ message: 'Unable to save study recording notes' })
            })
    }

    flashSavedMessage = () => {
        this.setState({
            is_saved_msg_visible: true,
            saved_msg_timeout: setTimeout(
                () => this.setState({ is_saved_msg_visible: false }),
                1500,
            ),
        })
    }

    getSaveStatus = () => {
        if (this.state.is_saving === true) {
            return 'Saving...'
        }
        if (this.state.is_saved_msg_visible === true) {
            return 'Saved!'
        }
        return ''
    }

    render() {
        return (
            <Card className="study-recording-card" raised>
                <CardHeader className="header" title={`Recording ${this.props.recording.Index}`} />
                <CardContent className="body">
                    <div>
                        <span className="right">
                            {DateTimeUtils.getFullStudyDateTimestampTimeZone(
                                this.props.recording.DateStarted,
                                this.props.recording.TimeZone,
                            )}
                        </span>
                        Start
                    </div>
                    <div>
                        <span className="right">
                            {this.props.recording.DateStopped ? DateTimeUtils.getFullStudyDateTimestampTimeZone(
                                this.props.recording.DateStopped,
                                this.props.recording.TimeZone,
                            ) : 'Not stopped yet'}
                        </span>
                        Stop
                    </div>
                    <div>
                        <span className="right">
                            {DateTimeUtils.getStudyDuration(
                                this.props.recording.Duration,
                            )}
                        </span>
                        Duration
                    </div>
                    <div>
                        <span className="right">
                            {this.props.recording.Events
                                ? this.props.recording.Events.length
                                : 0}
                        </span>
                        Events
                    </div>
                    Notes
                    <span className="saved-status">{this.getSaveStatus()}</span>
                    <Input
                        disabled={this.props.recording.SyncState === 0}
                        type="textarea"
                        maxLength={1000}
                        value={
                            this.state.notes === null
                                ? this.props.recording.Notes || ''
                                : this.state.notes
                        }
                        onChange={this.handleNotesChange}
                    />
                </CardContent>
            </Card>
        )
    }
}

export default StudyRecordingCard
