import React from 'react'
import { Container, Grid, Typography } from '@mui/material'

const ExpiredInvitation = () => (
    <div className="flex-row align-items-center">
        <p>
            <br />
            <br />
            <br />
            <br />
        </p>
        <Container className="Expired Invitation">
            <Grid container>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" spacing={2}>
                        <Grid item alignContent="center">
                            <Typography variant="h3" gutterBottom color="error">
                                <i className="icon icon-envelope-letter" />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" noWrap>
                                Sorry! The invitation you requested has expired.
                            </Typography>
                            <Typography variant="h6" color="textSecondary">
                                Please contact your inviter for a new invitation.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </div >
)

export default ExpiredInvitation
