import React from 'react'

const ScrubberEpoch = props => {
    const epochWidth = (100 * props.samplesPerWindow) / props.packetCount
    const epochMarkerLeft = (100 * props.sampleOffset) / props.packetCount
    const epochMarkerWidth = Math.min(epochWidth, 100 - epochMarkerLeft)

    return (
        <div
            className="section epoch"
            style={{
                width: `${epochMarkerWidth}%`,
                left: `${epochMarkerLeft}%`,
            }}
        />
    )
}

export default ScrubberEpoch
