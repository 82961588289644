import React from 'react'
import './LogEntryDetail.scss'
import {  Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'

const LogEntryDetail = props => (
    <Card className="log-entry-detail" raised>
        <CardHeader className="log-entry-header" title="Log Entry Detail" />
        <CardContent className="log-entry-body">
            <Grid container direction="row">
                <Grid xs={4} item>
                    <Typography>
                        <dt>Error Code</dt>
                        {props.logEntry.ErrorCode}
                    </Typography>
                </Grid>
                <Grid xs={4} item>
                    <Typography>
                        <dt>Software</dt>
                        {props.logEntry.SoftwareVersion}
                    </Typography>
                </Grid>
                <Grid xs={4} item>
                    <Typography>
                        <dt>User</dt>
                        {props.logEntry.UserName}
                    </Typography>
                    <br />
                </Grid>
            </Grid>
            <Grid container direction="row">
                <Grid xs={12} item>
                    <Typography>
                        <dt>Log Text</dt>
                    </Typography>
                </Grid>
                <Grid xs={12} item>
                    <Typography>{props.logEntry.LogText}</Typography>
                </Grid>
            </Grid>
            <br />
            <Grid item>
                <Typography>
                    <dt>StackTrace</dt>
                </Typography>
            </Grid>
            <Grid xs={12} item>
                <Typography className="stack-trace">
                    {props.logEntry.StackTrace || '<no trace>'}
                </Typography>
            </Grid>
        </CardContent>
    </Card>
)

export default LogEntryDetail
