export const HEAD_HEIGHT_RATIO = 1.132

export const NOT_MEASURED_VALUE = -1
export const NOT_MAPPED_VALUE = -2
export const OUT_OF_RANGE_VALUE = 101

export const ELECTRODE_POSITIONS: { [key: string]: { top: number, left: number } } = {
    Fp1: { top: 0.08, left: 0.35 },
    Fp2: { top: 0.08, left: 0.65 },
    F7: { top: 0.3, left: 0.17 },
    F8: { top: 0.3, left: 0.83 },
    F3: { top: 0.28, left: 0.35 },
    F4: { top: 0.28, left: 0.65 },
    Fz: { top: 0.29, left: 0.5 },
    A1: { top: 0.55, left: 0.05 },
    A2: { top: 0.55, left: 0.95 },
    T3: { top: 0.55, left: 0.17 },
    T4: { top: 0.55, left: 0.83 },
    C3: { top: 0.55, left: 0.3 },
    C4: { top: 0.55, left: 0.7 },
    Cz: { top: 0.55, left: 0.5 },
    T5: { top: 0.8, left: 0.18 },
    T6: { top: 0.8, left: 0.82 },
    P3: { top: 0.81, left: 0.32 },
    P4: { top: 0.81, left: 0.68 },
    Pz: { top: 0.83, left: 0.5 },
    O1: { top: 0.93, left: 0.35 },
    O2: { top: 0.93, left: 0.65 },
    Oz: { top: 0.95, left: 0.5 },

    Avg: { top: 0.05, left: 0.05 },
    LE: { top: 0.05, left: 0.95 },
}