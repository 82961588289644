interface IntervalEvent {
    data: { type: string, id: string, ms?: number }
}

function worker() {
    const store = new Map<string, NodeJS.Timer>()

    const validateIntervalEvent = (e: IntervalEvent) => {
        const { type, ms, id } = e.data
        if (!id) {
            throw  Error('id must be provided')
        }
        switch (type) {
            case 'set':
                if (store.get(id)) {
                    throw  Error('id must be unique')
                }
                if (!ms) {
                    throw  Error('ms must be provided and non-zero')
                }
                break
            case 'clear':
                if (store.get(id) === undefined) {
                    throw  Error(`interval ${id} not found`)
                }
                break
            default:
                throw  Error(`unrecognized message type ${type}`)
        }
    }

    onmessage = (e: IntervalEvent) => {
        validateIntervalEvent(e)
        const { type, ms, id } = e.data
        switch (type) {
            case 'set':
                const set = setInterval(() => postMessage(null), ms)
                const intervalId = id
                store.set(intervalId, set)
                break
            case 'clear':
                const clear = store.get(id) as NodeJS.Timer
                clearInterval(clear)
                store.delete(id)
                break
            default:
                throw Error(`unrecognized message type ${type}`)
        }
    }
}

export default worker
