import './VersionInformation.scss'
import RoleBasedContent from '../../../components/RoleBasedContent'
import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
} from '../../../constants/roles'
import { Card, Grid, Table, TableBody, TableRow, TableCell, TableHead, Typography  } from '@mui/material'

type versionInformation =  {
        amp_serial: string,
        amp_type: string,
        amp_fw: string,
        trackit_sw: string,
        crc_valid: boolean,
    }

interface Props {
    versionInfo: versionInformation
}

const VersionInformation: React.FC<Props> = props => {
    const { versionInfo } = props

    return (
        <RoleBasedContent required_roles={[SUPER_ADMIN_ROLE, SUPPORT_ROLE]}>
            <Grid item xs={2} sx={{ minWidth: 300 }}  className="version-info">
                <Card raised>
                    <Table size="small">
                        <colgroup>
                            <col width="37%" />
                            <col width="63%" />
                        </colgroup>
                        <TableHead><TableRow><TableCell colSpan={2}>
                            <Typography className="header" align="center" variant="subtitle2">Versioning Information</Typography>
                        </TableCell></TableRow></TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="right" padding="none">Amp Serial :</TableCell>
                                <TableCell> {versionInfo.amp_serial ? versionInfo.amp_serial : 'N/A' }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Amp Type :</TableCell>
                                <TableCell>{versionInfo.amp_type ? versionInfo.amp_type : 'N/A' }</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Amp FW :</TableCell>
                                <TableCell> {versionInfo.amp_fw ? versionInfo.amp_fw : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">Trackit SW :</TableCell>
                                <TableCell> {versionInfo.trackit_sw ? versionInfo.trackit_sw : 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="right">CRC Valid :</TableCell>
                                <TableCell> {versionInfo.crc_valid ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Grid>
        </RoleBasedContent>
    )

}

export default VersionInformation