import React, { useContext } from 'react'
import './GraphControl.scss'
import { Grid } from '@mui/material'
import SpeedControl from './SpeedControl'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { truncate, abbreviate } from '../../utils/StringUtils'
import AppContext from '../../components/AppContext'
import SpeedometerNeedle from './SpeedometerNeedle'
import ClockSetting from '../../types/ClockSetting'
import { Moment } from 'moment'

interface Props {
    currentSecond: number
    currentMoment: Moment
    areControlsMinimized: boolean
    playSpeed: number
    maxPlaySpeed: number
    isFocusedReview: boolean
    isPlaying: boolean
    hasVideo: boolean
    totalSeconds: number
    isPrevDisabled: boolean
    isNextDisabled: boolean
    isSpeedOpen: boolean
    areCurrentEpochPacketsLoaded: boolean
    clockSetting: ClockSetting
    timebase: string
    videoMessage: string
    handlePlaybackSpeedChange: () => void
    handleToggleSpeed: () => void
    toggleFocusedReview: () => void
    handleNextClick: () => void
    handlePlayClick: () => void
    handlePrevImportantEventClick: () => void
    handleNextImportantEventClick: () => void
    handleToggleControls: () => void
    handlePrevClick: () => void
    toggleVideo: () => void
}

const GraphControl: React.FC<Props> = props => {
    const ctx = useContext(AppContext)

    const isMinimizedClass = `${props.areControlsMinimized ? 'minimized' : ''}`
    const controlsMinimizedIcon = `icon icon-electrotek-chevron-${isMinimizedClass ? 'up' : 'down'}`
    const isSliderOpen = `${props.isSpeedOpen ? 'slider-open' : '' }`

    const hasImportantEvents = ctx.Study.StudyEvents.find(e => e.Important)

    let playSpeedText = 'Real time'
    if (props.playSpeed > 0) {
        playSpeedText = `${props.playSpeed.toFixed(1)} pages/sec`
    }

    const renderCurrentTime = () => {
        let decimalFormat = ''
        if (props.timebase === '1') {
            decimalFormat = '.S'
        }

        let display = ''
        switch (props.clockSetting) {
            case ClockSetting.TwelveHour:
                display = props.currentMoment.format(`h:mm:ss${decimalFormat} A M/DD/YYYY`)
                break
            case ClockSetting.TwentyFourHour:
                display = props.currentMoment.format(`HH:mm:ss${decimalFormat} M/DD/YYYY`)
                break
            default:
                display = DateTimeUtils.getStudyAltDuration(props.currentSecond)
        }

        return (
            <div className="playback-time">
                {display}
            </div>
        )
    }

    return (
        <div className={`graph-control ${isMinimizedClass}`}>
            <Grid container className={'row'} direction="row">
                <Grid item sm={2}>
                    <div
                        className={`controls-minimizer-container ${controlsMinimizedIcon}`}
                        onClick={props.handleToggleControls}
                        title={`${props.areControlsMinimized ? 'Maximize' : 'Minimize'} Controls`}
                    />
                    <div className="patient-container">
                        <div className="patient-icon icon icon-electrotek-person" />
                        <div className="patient-info">
                            <React.Fragment>
                                <div className="patient-name"
                                    title={`${ctx.Study.Patient.LastName}, ${ctx.Study.Patient.FirstName} ${ctx.Study.Patient.MiddleName || ''}`}
                                >
                                    {truncate(ctx.Study.Patient.LastName, 13)},{' '}
                                    {abbreviate(ctx.Study.Patient.FirstName)}{' '}
                                    {abbreviate(ctx.Study.Patient.MiddleName ?? '')}
                                </div>
                                {!props.areControlsMinimized && (
                                    <React.Fragment>
                                        <div className="patient-id"
                                            title={ctx.Study.Patient.PatientID ?? undefined}
                                        >
                                            {ctx.Study.Patient.PatientID}
                                        </div>
                                        <div className="patient-dob">
                                            {DateTimeUtils.getAbbreviatedDateOfBirth(
                                                ctx.Study.Patient.DateOfBirth,
                                            )}
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        </div>
                    </div>
                </Grid>
                <Grid item sm={2} className="d-none d-sm-block text-center">
                    <div className={`time-display ${props.isPlaying ? 'active' : ''}`}>
                        {!props.areControlsMinimized && (
                            <div className="time-title">PLAYBACK TIME ({
                                props.clockSetting === ClockSetting.Duration ? 'Elapsed'
                                    : props.clockSetting === ClockSetting.TwelveHour ? '12 hour'
                                        : '24 hour'
                            })</div>
                        )}
                        {renderCurrentTime()}
                        {!props.areControlsMinimized && (
                            <div className="total-time">
                                {DateTimeUtils.getStudyAltDuration(
                                    props.totalSeconds,
                                )} TRT
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item sm={4} xs={10}>
                    <div className="play-controls">
                        {props.isFocusedReview && (
                            <button
                                type="button"
                                className={`prev-next-important-btns ${isMinimizedClass} ${props.isPlaying ? 'playing' : 'paused'}`}
                                onClick={props.handlePrevImportantEventClick}
                                disabled={props.isPlaying || !hasImportantEvents}
                                title="Previous important event"
                            >
                                <span className={`icon ${isMinimizedClass} icon-electrotek-chevron-left`} />
                            </button>
                        )}
                        <button
                            type="button"
                            id="toPrevBtn"
                            className={`prev-next-btns ${props.isPlaying ? 'playing' : 'paused'} ${isMinimizedClass}`}
                            onClick={props.handlePrevClick}
                            disabled={props.isPrevDisabled || props.isPlaying}
                            title="Previous Page (Left Arrow Key or A) | Previous Major Tick (Shift + Left Arrow Key or Shift + A)"
                        >
                            <span className={`icon  ${isMinimizedClass} icon-electrotek-chevron-left`} />
                        </button>
                        <button
                            type="button"
                            id="PlayBtn"
                            className={`play-btn ${isMinimizedClass} ${props.isFocusedReview ? 'focused' : ''}`}
                            onClick={props.handlePlayClick}
                            disabled={(props.isFocusedReview && !hasImportantEvents) || !props.areCurrentEpochPacketsLoaded}
                            title="Toggle Graph Playback (spacebar)"
                        >
                            <span
                                className={`icon  ${!props.isPlaying ? 'icon-electrotek-control-play play' : 'icon-electrotek-control-pause pause'} ${isMinimizedClass}`}
                            />
                        </button>
                        <button
                            type="button"
                            id="toNextBtn"
                            className={`prev-next-btns ${isMinimizedClass} ${props.isPlaying ? 'playing' : 'paused'}`}
                            onClick={props.handleNextClick}
                            disabled={props.isNextDisabled || props.isPlaying}
                            title="Next page (Right Arrow Key or D) | Next Major Tick (Shift + Right Arrow Key or Shift + D)"
                        >
                            <span className={`icon  ${isMinimizedClass} icon-electrotek-chevron-right`} />
                        </button>
                        {props.isFocusedReview && (
                            <button
                                type="button"
                                className={`prev-next-important-btns ${isMinimizedClass} ${props.isPlaying ? 'playing' : 'paused'}`}
                                onClick={props.handleNextImportantEventClick}
                                disabled={props.isPlaying || !hasImportantEvents}
                                title="Next important event"
                            >
                                <span className={`icon ${isMinimizedClass} icon-electrotek-chevron-right`} />
                            </button>
                        )}
                    </div>
                </Grid>
                <Grid item xs={1} className="focused-review">
                    <button
                        title={`${props.isFocusedReview ? 'Exit' : 'Enter'} focused review (F)`}
                        type="button"
                        onClick={props.toggleFocusedReview}
                    >
                        <span
                            className={`icon icon-electrotek-star${props.isFocusedReview ? ' gold' : '-o'}`}
                        />
                    </button>
                </Grid>
                <Grid item xs={1} id="speedControl">

                    <div className={`speed-slider-container ${isMinimizedClass} ${isSliderOpen}`}>
                        <div className="slider-control-elements">
                            <div className={`speed-slider-value-container ${isMinimizedClass}`} >
                                <div className="speed-slider-value">
                                    {playSpeedText}
                                </div>
                            </div>
                            <div className="speed-slider">
                                <SpeedControl
                                    playSpeed={props.playSpeed}
                                    maxPlaySpeed={props.maxPlaySpeed}
                                    onChange={props.handlePlaybackSpeedChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={`speed-icon-text ${isMinimizedClass}`}>{playSpeedText}</div>

                    <button
                        type="button"
                        title="Graph Playback Speed (0-9) or ( / )"
                        onClick={props.handleToggleSpeed}
                        className={`${isMinimizedClass}`}
                    >
                        <span className={`icon icon-electrotek-speedometer2 speed-icon ${isMinimizedClass}`} >                      
                            <SpeedometerNeedle
                                needle={props.playSpeed / props.maxPlaySpeed}
                                isMinimized={props.areControlsMinimized}
                            />
                        </span>
                    </button>
                </Grid>
                <Grid item xs={1} className={`video-control ${isMinimizedClass}`}>
                    {props.hasVideo && (
                        <>
                            <button
                                title="Toggle video display"
                                className="video-btn"
                                disabled={props.playSpeed > 0}
                                type="button"
                                onClick={props.toggleVideo}
                            >
                                <span style={{ display: 'block' }}
                                    className={`icon ${isMinimizedClass} icon-electrotek-videocam`}
                                    onClick={props.toggleVideo}
                                />
                            </button>
                            <span className="video-message">{props.videoMessage}</span>
                        </>
                    )}
                </Grid>
            </Grid>
        </div >
    )
}

export default GraphControl
