import React, { useEffect, useState } from 'react'
import './SearchPatient.scss'
import Patient from '../../types/Patient'
import PatientsApi from '../../services/PatientsApi'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { InputGroup, InputGroupAddon } from 'reactstrap'

interface Props {
    isPatientSelected?: boolean
    handlePatient: (patient: Patient) => void
    maxHeight?: string
}

const SearchPatient: React.FC<Props> = props => {
    const [search, setSearch] = useState<string>('')
    const [results, setResults] = useState<Patient[] | null>(null)

    const searchPatients = (searchQuery: string) => {
        PatientsApi.search(searchQuery)
            .then(response => {
                setResults(response.data)
            })
    }

    useEffect(() => {
        if (search === '') {
            setResults(null)
            return
        }
        const timeout = setTimeout(() => searchPatients(search), 500)
        return () => clearTimeout(timeout)
    }, [search])

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            searchPatients(search)
        }
    }

    const handleClearSearch = () => {
        setResults(null)
        setSearch('')
    }

    return (
        <div className="search-patient">
            <div className="form-group">
                <InputGroup>
                    <input
                        name="search-patient"
                        value={search}
                        id="search-input"
                        onChange={e => setSearch(e.target.value.trim())}
                        type="search"
                        className="form-control rounded"
                        placeholder="Search patient..."
                        onKeyPress={handleKeyPress}
                        autoFocus
                    />
                    <InputGroupAddon
                        addonType="append"
                        onClick={handleClearSearch}
                        hidden={!search}
                    >
                        <span className="input-group-text">
                            <i className="icon-electrotek-cross"
                                style={{ fontSize: '14px' }}
                            />
                        </span>
                    </InputGroupAddon>
                </InputGroup>
            </div>

            {results !== null && results.length > 0 && (
                <div className="col-md-12"
                    style={{
                        overflowY: 'scroll',
                        maxHeight: props.maxHeight ?? '25rem',
                    }}
                >
                    <table className="table-hover col-md-12" id="search-patient-results">
                        <thead>
                            <tr className="border-bottom">
                                <th>ID</th>
                                <th>Name</th>
                                <th>DoB</th>
                                <th>SSN</th>
                                <th>Sex</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.map(patient => (
                                <tr key={patient.ID}
                                    className="table-striped"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => props.handlePatient(patient)}
                                >
                                    <td className="limit-width" title={patient.PatientID ?? undefined}>{patient.PatientID}</td>
                                    <td className="limit-width" title={patient.FullName}>{patient.FullName}</td>
                                    <td>{DateTimeUtils.getFriendlyDateOfBirth(patient.DateOfBirth)}</td>
                                    <td>{patient.SSN}</td>
                                    <td>{patient.Sex}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
            {results !== null && results.length <= 0 && (
                <div className="col-md-12">
                    <span><i>No patients</i></span>
                </div>
            )}
        </div>
    )
}

export default SearchPatient

