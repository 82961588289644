import AuditLogEntry from '../types/AuditLogEntry'
import Document from '../types/Document'

class AuditLogUtils {
    static formatUserName(entry: AuditLogEntry): string {
        if (entry.UserName.length > 0) {
            return entry.UserName
        }
        let name = ''
        if (entry.UserTitle) {
            name = `${entry.UserTitle} ${entry.UserFirstName}`
        } else {
            name = `${entry.UserFirstName}`
        }
        if (entry.UserMiddleName) {
            name += ` ${entry.UserMiddleName}`
        }
        name += ` ${entry.UserLastName}`
        if (entry.UserSuffix) {
            name += ` ${entry.UserSuffix}`
        }
        return name
    }

    static getPatientDocumentName(entry: AuditLogEntry<Document | null>): string {
        if (entry.ItemAfter) {
            return entry.ItemAfter.FileName
        }
        if (entry.ItemBefore) {
            return entry.ItemBefore.FileName
        }
        return entry.ItemId.split('/').pop() ?? entry.ItemId
    }
}

export default AuditLogUtils
