import React from 'react'
import './Cameras.scss'
import FacilitiesApi from '../../services/FacilitiesApi'
import RoleBasedContent from '../../components/RoleBasedContent'
import NavUtils from '../../utils/NavUtils'
import SortUtils from '../../utils/SortUtils'
import SortHeader from '../../components/SortHeader'
import LoadingSpinner from '../../components/LoadingSpinner'
import FailedToLoad from '../../components/FailedToLoad'
import ToastUtils from '../../utils/ToastUtils'
import AppContext from '../../components/AppContext'
import ModalBool from '../../components/modals/ModalBool'
import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    PRODUCTION_ROLE,
} from '../../constants/roles'
import AddFacilityCameraModal from './AddCameraModal'
import NeutralButton from '../../components/NeutralButton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const NAME_FIELD = 'Name'
const DEVICE_FIELD = 'DeviceName'
const VIO_CAMERA_NAME = 'VIO Stream'
const IPELLA_CAMERA_NAME = 'Sony IPELA SNC‑EP550'
const FACILITY_ONLY_CAMERAS = [VIO_CAMERA_NAME]

class Cameras extends React.Component {
    static contextType = AppContext

    state = {
        is_loading: true,
        cameras: [],
        sorted_by: NAME_FIELD,
        sorted_dir: 'asc',
        scroll_to_top: false,
        is_add_camera_modal_open: false,
        is_confirm_modal_open: false,
        excluded_cameras: [IPELLA_CAMERA_NAME],
    }

    componentDidMount() {
        NavUtils.setPageTitle('Cameras')
        this.getCameras()
    }

    getCameras = () => {
        this.setState({ is_loading: true, get_data_did_fail: false })

        FacilitiesApi.getFacilityNetworkCameras()
            .then(data => this.setCameras(data.data))
            .catch(() =>
                this.setState({
                    is_loading: false,
                    get_data_did_fail: true,
                }),
            )
            .catch(() =>
                this.setState({ is_loading: false, get_data_did_fail: true }),
            )
    }

    componentDidUpdate() {
        if (this.state.scroll_to_top) {
            window.scrollTo(0, 0)
            this.setScrollToTop(false)
        }
    }

    setScrollToTop(setting) {
        this.setState({ scroll_to_top: setting })
    }

    setCameras(cameras) {
        SortUtils.string(cameras, this.state.sorted_by, this.state.sorted_dir)
        this.setState({ cameras, is_loading: false })
        this.checkFacilityCameras(cameras)
    }

    refreshCameras = () => {
        FacilitiesApi.getFacilityNetworkCameras().then(data => {
            this.setCameras(data.data)
        })
    }

    checkFacilityCameras = (cameraList) => {
        // Only allow one VIO per facility 
        const cameras = cameraList.map(c => c.Name)
        const hasVIO = cameras.includes(VIO_CAMERA_NAME)
        let excluded_cameras = this.state.excluded_cameras
        if (hasVIO) {
            excluded_cameras.push(VIO_CAMERA_NAME)
        } else {
            excluded_cameras = excluded_cameras.filter(c => c !== VIO_CAMERA_NAME)
        }
        this.setState({ excluded_cameras })
    }

    toggleConfirmDeleteModal = () => {
        this.setState({
            is_confirm_modal_open: !this.state.is_confirm_modal_open,
        })
    }

    toggleAddCameraModal = () => {
        this.setState({
            is_add_camera_modal_open: !this.state.is_add_camera_modal_open,
        })
    }

    initiateDeleteCamera = (id, name, deviceName) => {
        this.setState({ camera_to_delete: { id, name, deviceName } })
        this.toggleConfirmDeleteModal()
    }

    handleConfirmDeleteYes = () => {
        this.toggleConfirmDeleteModal()
        const camera_to_delete = this.state.cameras.find(u => u.ID === this.state.camera_to_delete.id)

        FacilitiesApi.removeFacilityNetworkCamera(camera_to_delete.ID)
            .then(result => {
                if (result.status === 200) {
                    ToastUtils.success({ message: 'Camera has been deleted' })
                    this.refreshCameras()
                } else {
                    ToastUtils.error({ message: `Camera delete failed: ${result.statusText}` })
                }
            })
            .then(this.setScrollToTop(true))
            .catch(error => {
                ToastUtils.error({ message: error.data?.Message || error.message || error.statusText })
            })
    }

    handleConfirmDeleteNo = () => {
        this.setState({ is_confirm_modal_open: false })
        this.setState({ camera_to_delete: {} })
    }

    handleAddSuccess = () => {
        ToastUtils.success({ message: 'New Camera has been added' })
        this.toggleAddCameraModal()
        this.refreshCameras()
    }

    handleSort = sorted_by => {
        const cameras = [...this.state.cameras]
        let sorted_dir = 'desc'
        if (
            this.state.sorted_by !== sorted_by ||
            this.state.sorted_dir === 'desc'
        ) {
            sorted_dir = 'asc'
        }
        switch (sorted_by) {
            case NAME_FIELD:
                SortUtils.string(cameras, sorted_by, sorted_dir)
                break
            case DEVICE_FIELD:
                SortUtils.string(cameras, sorted_by, sorted_dir)
                break
            default:
                throw Error(`you can not sort by ${sorted_by}`)
        }
        this.setState({ sorted_by, sorted_dir, cameras })
    }

    addCameraModal = () => (
        <RoleBasedContent
            required_roles={[
                SUPER_ADMIN_ROLE,
                SUPPORT_ROLE,
                PRODUCTION_ROLE,
            ]}
        >
            <AddFacilityCameraModal
                isOpen={this.state.is_add_camera_modal_open}
                title="Add Facility Camera"
                toggle={this.toggleAddCameraModal}
                handleSuccess={() => this.handleAddSuccess()}
                excluded_cameras={this.state.excluded_cameras}
                facility_only_cameras={FACILITY_ONLY_CAMERAS}
            />
        </RoleBasedContent>
    )

    deleteConfirmModal = () => (
        <ModalBool
            isOpen={this.state.is_confirm_modal_open}
            title="Confirm Camera Deletion"
            message={`Are you sure you want to delete the camera '${this.state.camera_to_delete.name}
                ${` on ${this.state.camera_to_delete.deviceName || 'no associated device'}`}'?`}
            trueButtonText="Delete"
            falseButtonText="Cancel"
            toggle={this.toggleConfirmDeleteModal}
            handleTrue={this.handleConfirmDeleteYes}
            handleFalse={this.handleConfirmDeleteNo}
        />
    )

    pageHeader = () => {
        const countMessage = this.state.is_loading ? 'Loading...' :
            `${this.state.cameras?.length === 0 ? 'No' : this.state.cameras?.length} cameras found`

        return (
            <div style={{ marginBottom: 10 }}>
                <h3 style={{ marginBottom: 3 }}>
                    <i className="icon-electrotek-videocam" />{' '}
                    Cameras

                    <RoleBasedContent
                        required_roles={[
                            SUPER_ADMIN_ROLE,
                            SUPPORT_ROLE,
                            PRODUCTION_ROLE,
                        ]}
                    >
                        <NeutralButton
                            sx={{ marginTop: 2 }}
                            className="btn btn-secondary pull-right"
                            onClick={() => this.toggleAddCameraModal()}
                        >
                            Create
                        </NeutralButton>
                    </RoleBasedContent>
                </h3>
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
                {this.state.is_loading && (<LoadingSpinner />)}
                {this.state.is_add_camera_modal_open && this.addCameraModal()}
            </div >
        )
    }

    tableHeader = () => (
        <TableHead>
            <TableRow>
                <SortHeader
                    text="Name"
                    field={NAME_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Associated Device"
                    field={DEVICE_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <TableCell>Url</TableCell>
                <TableCell>Port</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    )

    render() {
        const cameras = this.state.cameras
        const noCameras = !this.state.is_loading && !this.state.get_data_did_fail && cameras.length === 0
        const isLoading = this.state.is_loading
        const isConfirmModalOpen = this.state.is_confirm_modal_open
        const isAddCameraModalOpen = this.state.is_add_camera_modal_open
        const didGetDataFail = this.state.get_data_did_fail
        const pageHeader = this.pageHeader()
        const tableHeader = this.tableHeader()

        if (isLoading || noCameras)
            return (pageHeader)

        return (
            <div className="animated fadeIn">
                {pageHeader}
                <div>
                    {isConfirmModalOpen && this.deleteConfirmModal()}
                    {isAddCameraModalOpen && this.addCameraModal()}
                    <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                        <Table stickyHeader>
                            {tableHeader}
                            <TableBody>
                                {this.state.cameras.map(camera => (
                                    <TableRow
                                        key={camera.ID}
                                        className={`${camera.isDeleted ? 'deleted' : ''}`}
                                    >
                                        <TableCell title={`${camera.Name}`}>
                                            {camera.Name}
                                        </TableCell>
                                        <TableCell title={`${camera.DeviceName}`}>
                                            {camera.DeviceName || 'None'}
                                        </TableCell>
                                        <TableCell title={`${camera.Url}`}>
                                            {camera.Url}
                                        </TableCell>
                                        <TableCell title={`${camera.StreamPort}`}>
                                            {camera.StreamPort?.replace(':', '')}
                                        </TableCell>
                                        <TableCell>
                                            <div className="row-action-icons">
                                                <RoleBasedContent
                                                    required_roles={[
                                                        SUPER_ADMIN_ROLE,
                                                        SUPPORT_ROLE,
                                                        PRODUCTION_ROLE,
                                                    ]}
                                                >
                                                    <IconButton
                                                        title="Delete"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() =>
                                                            this.initiateDeleteCamera(
                                                                camera.ID,
                                                                camera.Name,
                                                                camera.DeviceName,
                                                            )
                                                        }
                                                    >
                                                        <i className="fa fa-trash-alt" />
                                                    </IconButton>
                                                </RoleBasedContent>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    {!cameras.length && didGetDataFail && (
                        <FailedToLoad
                            items="cameras"
                            onRetry={
                                this.getCameras
                            }
                        />
                    )}
                </div>
            </div>
        )
    }
}

export default Cameras
