import React, { useCallback, useEffect, useRef, useState } from 'react'
import './MontageHeadView.scss'
import { TEN_TWENTY_ELECTRODES } from '../../constants/tentwentyelectrodes'
import Montage from '../../types/Montage'
import Electrode from '../../components/head/Electrode'
import Head from '../../components/head/Head'
import { HEAD_HEIGHT_RATIO } from '../../components/head/constants'
import ChannelArrow from './ChannelArrow'
import MontageChannel from '../../types/MontageChannel'
import { EEG } from '../studies/eegMonitor/constants'

interface Props {
    montage: Montage
    width: number
    onChange?: React.Dispatch<React.SetStateAction<Montage | null>>
    selectedChannel?: MontageChannel | null
    onSelectChannel?: (channel: MontageChannel) => void
    groupCnt?: number
}

export type MouseTouchEvent = { clientX: number, clientY: number, pageX: number, pageY: number }

const ELECTRODES = [...TEN_TWENTY_ELECTRODES, 'Avg', 'LE']

function MontageHeadView(props: Props) {
    const [dragging, setDragging] = useState('')
    const [draggedOver, setDraggedOver] = useState('')
    const [mousePosition, setMousePosition] = useState([0, 0])
    const container = useRef<HTMLDivElement | null>(null)
    const electrodeWidth = Math.min(props.width / 10, 35)

    const simpleChannels = props.montage.Channels.filter(ch => {
        const parts = ch.Formula.split('-')
        if (parts.length !== 2) return false
        return ELECTRODES.includes(parts[0]) && ELECTRODES.includes(parts[1])
    })

    const setMousePositionByEvent = useCallback((e: MouseTouchEvent) => {
        if (!container.current) return
        const rect = container.current.getBoundingClientRect()
        const x = Math.max(e.clientX - rect.left, 0)
        const y = Math.max(e.clientY - rect.top, 0)
        setMousePosition([x, y])
    }, [setMousePosition])

    const mousePositionX = mousePosition[0]
    const mousePositionY = mousePosition[1]
    useEffect(() => {
        if (!container.current) return
        const rect = container.current.getBoundingClientRect()
        const el = document.elementFromPoint(mousePositionX + rect.left, mousePositionY + rect.top)
        if (el && el.classList.contains('electrode')) {
            setDraggedOver(el.id)
        } else {
            setDraggedOver('')
        }
    }, [mousePositionX, mousePositionY])

    const onMontageChange = props.onChange
    const groupCnt = props.groupCnt
    useEffect(() => {
        if (dragging) {
            const onMove = (e: MouseTouchEvent) => setMousePositionByEvent(e)
            const onUp = (e: MouseTouchEvent) => {
                setDragging('')
                const el = document.elementFromPoint(e.clientX, e.clientY)
                if (el && el.classList.contains('electrode')) {
                    setDraggedOver('')
                    setMousePosition([0, 0])
                    if (el.id === dragging || !onMontageChange) return
                    onMontageChange(m => {
                        if (!m) return m
                        const lastCh = [...m.Channels].pop()
                        const channel = {
                            ID: Math.floor(Math.random() * 1e9).toString(),
                            Formula: `${dragging}-${el.id}`,
                            Sort: lastCh ? lastCh.Sort + 1 : 0,
                            Group: groupCnt,
                            ChannelTypeID: EEG,
                            MontageTemplateID: m.ID,
                        } as MontageChannel
                        return {
                            ...m,
                            Channels: [...m.Channels, channel],
                        }
                    })
                }
            }
            const onTouchMove = (e: TouchEvent) => onMove(e.touches[0])
            const onTouchUp = (e: TouchEvent) => onUp(e.changedTouches[0])
            document.body.addEventListener('mousemove', onMove)
            document.body.addEventListener('touchmove', onTouchMove)
            document.body.addEventListener('mouseup', onUp)
            document.body.addEventListener('touchend', onTouchUp)
            return () => {
                document.body.removeEventListener('mousemove', onMove)
                document.body.removeEventListener('mouseup', onUp)
                document.body.removeEventListener('touchmove', onTouchMove)
                document.body.removeEventListener('touchend', onTouchUp)
            }
        }
    }, [dragging, groupCnt, onMontageChange, setMousePosition, setMousePositionByEvent])

    return (
        <div className="montage-head-view" ref={container}>
            <Head width={props.width}>
                <svg
                    style={{
                        position: 'absolute',
                        left: 0,
                        width: props.width,
                        height: props.width * HEAD_HEIGHT_RATIO,
                    }}
                    viewBox={`0 0 ${props.width} ${props.width * HEAD_HEIGHT_RATIO}`}
                >
                    {simpleChannels.map(ch => (
                        <ChannelArrow
                            id={ch.ID}
                            key={ch.ID + ch.Formula}
                            selected={ch.ID === props.selectedChannel?.ID}
                            editable={props.onSelectChannel !== undefined}
                            onClick={() => props.onSelectChannel?.(ch)}
                            className={props.onSelectChannel ? undefined : 'no-hover'}
                            formula={ch.Formula}
                            headWidth={props.width}
                            electrodeWidth={electrodeWidth}
                        />
                    ))}
                    {dragging && mousePosition[0] > 0 && mousePosition[1] > 0 && (
                        <ChannelArrow
                            id={dragging}
                            className="no-hover"
                            selected={false}
                            editable={false}
                            onClick={() => null}
                            formula={`${dragging}-${mousePosition.join(',')}`}
                            headWidth={props.width}
                            electrodeWidth={electrodeWidth}
                        />
                    )}
                </svg>
                {[...TEN_TWENTY_ELECTRODES, 'LE', 'Avg'].map(name => (
                    <Electrode
                        key={name}
                        position={name}
                        showLabels
                        className={(draggedOver === name && dragging !== name) ? 'dragged-over' : ''}
                        style={['LE', 'Avg'].includes(name) ? { background: 'rgb(40, 49, 85)', borderRadius: 0 } : undefined}
                        electrodeWidth={electrodeWidth}
                        headImageWidth={props.width}
                        draggable={TEN_TWENTY_ELECTRODES.includes(name) && (dragging === '' || dragging === name) && props.onChange !== undefined}
                        onDragStart={e => {
                            setMousePositionByEvent(e)
                            setDragging(name)
                        }}
                        onDragEnd={() => {
                            setDragging('')
                            setMousePosition([0, 0])
                        }}
                    />
                ))}
            </Head>
        </div>
    )
}

export default MontageHeadView
