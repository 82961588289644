class FileHeaderParser {
    constructor(header: string) {
        this.header = header
        this.cursor = 0
    }

    private header: string
    private cursor: number

    setCursor(cursor: number) {
        this.cursor = cursor
    }

    getString(startPosition: number, size: number) {
        return this.header.slice(startPosition, startPosition + size).trim()
    }

    scanStrings(chukCount: number, chunkSize: number) {
        const items = []
        for (let i = 0; i < chukCount; i += 1) {
            items.push(
                this.header
                    .slice(
                        this.cursor + chunkSize * i,
                        this.cursor + chunkSize * (i + 1),
                    )
                    .trim(),
            )
        }
        this.cursor += chukCount * chunkSize
        return items
    }

    getInteger(startPosition: number, size: number) {
        return parseInt(
            this.header.slice(startPosition, startPosition + size),
            10,
        )
    }

    getFloat(startPosition: number, size: number) {
        return parseFloat(this.header.slice(startPosition, startPosition + size))
    }

    scanIntegers(chukCount: number, chunkSize: number) {
        const items = []
        for (let i = 0; i < chukCount; i += 1) {
            items.push(
                parseInt(
                    this.header
                        .slice(
                            this.cursor + chunkSize * i,
                            this.cursor + chunkSize * (i + 1),
                        )
                        .trim(),
                    10,
                ),
            )
        }
        this.cursor += chukCount * chunkSize
        return items
    }
}

export default FileHeaderParser
