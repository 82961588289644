import React from 'react'
import NavUtils from '../../utils/NavUtils'
import ToastUtils from '../../utils/ToastUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import SharedUsersApi from '../../services/SharedUsersApi'
import DateTimeUtils from '../../utils/DateTimeUtils'
import SortHeader from '../../components/SortHeader'
import SortUtils from '../../utils/SortUtils'
import RoleBasedContent from '../../components/RoleBasedContent'
import StudiesApi from '../../services/StudiesApi'
import ModalBool from '../../components/modals/ModalBool'
import StudyAuth from '../../auth/StudyAuth'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const PAGE_TITLE = 'Shared Users'
const NAME_FIELD = 'Name'
const DATE_FIELD = 'DateShared'

class SharedUsers extends React.Component {
    state = {
        is_loading: true,
        is_confirm_open: false,
        removing_share: false,
        error_message: '',
        sorted_by: NAME_FIELD,
        sorted_dir: 'asc',
        shared_users: [],
    }

    componentDidMount() {
        NavUtils.setPageTitle(PAGE_TITLE)
        this.loadSharedUsers()
    }

    showConfirm(shareToRemove) {
        this.setState({ is_confirm_open: true, share_to_remove: shareToRemove })
    }

    hideConfirm() {
        this.setState({ is_confirm_open: false, share_to_remove: '' })
    }

    loadSharedUsers() {
        SharedUsersApi
            .getSharedUsers(this.props.match.params.id)
            .then(res =>
                this.setState({
                    is_loading: false,
                    shared_users: res.data,
                }),
            )
            .catch(err =>
                this.setState({
                    is_loading: false,
                    error_message: `Failed to get shared users${err || ''}`,
                    shared_users: [],
                }),
            )
    }

    handleSort = sorted_by => {
        const shared_users = [...this.state.shared_users]
        let sorted_dir = 'desc'
        if (
            this.state.sorted_by !== sorted_by ||
            this.state.sorted_dir === 'desc'
        ) {
            sorted_dir = 'asc'
        }
        switch (sorted_by) {
            case NAME_FIELD:
                SortUtils.string(shared_users, sorted_by, sorted_dir)
                break
            case DATE_FIELD:
                SortUtils.date(shared_users, sorted_by, sorted_dir)
                break
            default:
                throw Error(`you can not sort by ${sorted_by}`)
        }
        this.setState({ sorted_by, sorted_dir, shared_users })
    }

    removeStudyShare() {
        const isRemoving = this.state.removing_share
        const shareID = this.state.share_to_remove
        this.hideConfirm()
        if (isRemoving) { return }
        this.setState({ removing_share: true })
        StudiesApi.removeStudyShare(shareID)
            .then(() => {
                this.loadSharedUsers()
                ToastUtils.success({ message: 'Study share has been removed' })
            })
            .catch(err =>
                this.setState({
                    is_loading: false,
                    error_message: 'Failed to remove study share, please refresh page',
                })
            )
            .finally(
                () => this.setState({ removing_share: false })
            )
    }

    pageHeader = () => {
        const recCount = this.state.shared_users.length
        const countMessage = this.state.is_loading ? 'Loading...' :
            `${recCount === 0 ? 'No' : recCount} share${recCount > 1 ? 's' : ''} found`

        return (
            <React.Fragment>
                <h3 style={{ marginBottom: 7 }}>
                    <i className="fas fa-handshake" />{' '}
                    {PAGE_TITLE}
                    <Typography noWrap variant="subtitle1">
                        {countMessage}
                    </Typography>
                </h3>
                {this.state.is_loading && (<LoadingSpinner />)}
            </React.Fragment>
        )
    }

    render() {
        const isLoading = this.state.is_loading
        const noShares = !this.state.is_loading && !this.state.get_data_did_fail && this.state.shared_users.length === 0
        const showConfirmDialog = this.state.is_confirm_open

        if (isLoading || noShares)
            return (this.pageHeader())

        return (
            <div style={{ marginBottom: 10 }}>
                {this.pageHeader()}
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <SortHeader
                                    class="name-column"
                                    text="Name"
                                    field={NAME_FIELD}
                                    onSort={this.handleSort}
                                    by={this.state.sorted_by}
                                    dir={this.state.sorted_dir}
                                />
                                <SortHeader
                                    class="date-shared-column"
                                    text="Date Shared"
                                    field={DATE_FIELD}
                                    onSort={this.handleSort}
                                    by={this.state.sorted_by}
                                    dir={this.state.sorted_dir}
                                />
                                <TableCell>&nbsp;</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.shared_users.map(shared_user => (
                                <TableRow key={shared_user.ID}>
                                    <TableCell>{shared_user.Name}</TableCell>
                                    <TableCell>
                                        {DateTimeUtils.getFriendlyDate(
                                            shared_user.DateShared,
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <RoleBasedContent required_roles={StudyAuth.RevokeShare}>
                                            <div className="row-action-icons">
                                                <IconButton
                                                    title="Delete"
                                                    color="primary"
                                                    onClick={() => this.showConfirm(shared_user.ShareID)}>
                                                    <i className=" icon fa fa-fw fa-trash-alt" />
                                                </IconButton>
                                            </div>
                                        </RoleBasedContent>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {this.state.shared_users.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={2} className="no-shared-users-cell">
                                        {
                                            this.state.error_message ||
                                            'This study has no shares'
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    showConfirmDialog &&
                    (
                        <ModalBool
                            isOpen={showConfirmDialog}
                            title="Confirm Share Deletion"
                            message="Are you sure you want to delete the study share?"
                            trueButtonText="Delete"
                            falseButtonText="Cancel"
                            handleTrue={() => this.removeStudyShare()}
                            handleFalse={() => this.hideConfirm()}
                        />
                    )
                }
            </div>
        )
    }
}

export default SharedUsers
