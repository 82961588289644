import Shell from './components/shell/Shell'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import { useMemo } from 'react'
import { Redirect, Route, Switch, BrowserRouter } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import LogRocket from 'logrocket'

import {
    SUPER_ADMIN_ROLE,
    FACILITY_ADMIN_ROLE,
    SUPPORT_ROLE,
    LEAD_TECH_ROLE,
    REVIEW_DOCTOR_ROLE,
    OFFICE_PERSONNEL_ROLE,
    FIELD_TECH_ROLE,
    PRODUCTION_ROLE,
    REGISTERED_USER_ROLE,
} from './constants/roles'
import Content from './components/shell/Content'
import 'simple-line-icons/css/simple-line-icons.css'

// Components
// import { isFeatureEnabled } from './FeatureFlag' TODO: Fix
import { Action } from './components/AppState'
import AppContext from './components/AppContext'
import AmplifierView from './pages/amplifiers/AmplifierView'
import Amplifiers from './pages/amplifiers/Amplifiers'
import { Auth } from './services/Auth'
import AmplifierAuth from './auth/AmplifierAuth'
import Cameras from './pages/cameras/Cameras'
import CPTUserMonitoring from './pages/cpt/CPTUserMonitoring'
import CPTStudyMonitoring from './pages/cpt/CPTStudyMonitoring'
import Dashboard from './pages/dashboard/Dashboard'
import Device from './pages/devices/Device'
import DeviceLogs from './pages/logs/DeviceLogs'
import Devices from './pages/devices/Devices'
import ExpiredInvitation from './components/ExpiredInvitation'
import Facilities from './pages/facilities/Facilities'
import Facility from './pages/facilities/Facility'
import ForgotPassword from './components/ForgotPassword'
import Help from './pages/Help'
import ImportPatientSelect from './pages/import/ImportPatientSelect'
import ImportStudy from './pages/import/ImportStudy'
import Invitation from './pages/Invitation'
import LoadingSpinner from './components/LoadingSpinner'
import Login from './pages/Login'
import Montages from './pages/montages/Montages'
import MontageAuth from './auth/MontageAuth'
import MontageEditor from './pages/montages/MontageEditor'
import PatientAuth from './auth/PatientAuth'
import PrivateRoute from './components/PrivateRoute'
import Page404 from './components/Page404'
import Patient from './pages/patients/Patient'
import PatientDocuments from './pages/patients/PatientDocuments'
import PatientAuditLogs from './pages/patients/PatientAuditLogs'
import Patients from './pages/patients/Patients'
import Profile from './pages/profile/Profile'
import PasswordReset from './components/PasswordReset'
import SharedUsers from './pages/studies/SharedUsers'
import StudyAuth from './auth/StudyAuth'
import Studies from './pages/studies/Studies'
import Study from './pages/studies/Study'
import SharedStudy from './pages/studies/SharedStudy'
import Toasts from './components/Toasts'
import User from './pages/users/User'
import UserAuth from './auth/UserAuth'
import Users from './pages/users/Users'
import UsersApi from './services/UsersApi'
import makeTheme from './makeTheme'
import FacilityAuth from './auth/FacilityAuth'
import DeviceAuth from './auth/DeviceAuth'
import CameraAuth from './auth/CameraAuth'
import SessionManager from './services/SessionManager'
import setupWorker from './workers/setup'
import intervalWorker from './workers/interval'

function App() {
    const theme = useMemo(() => makeTheme(), [])
    const ctx = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(true)

    const { commit } = ctx
    useEffect(() => {
        commit(Action.StartTimer)
        if (!Auth.loggedIn()) {
            setIsLoading(false)
            return
        }

        UsersApi.getMyRoles()
            .then(async result => {
                const roles = result.data.filter((r: string) => r !== REGISTERED_USER_ROLE)
                await commit(Action.SetUserRoles, roles)
                setIsLoading(false)
            })
            .catch(error => {
                Auth.logout()
                console.log(`Error attempting to get roles, logging user out of app. Error: ${error.result}`)
                setIsLoading(false)
            })
    }, [commit])

    useEffect(() => {
        if (!Auth.loggedIn()) return
        const worker = setupWorker(intervalWorker)
        const intervalId = 'App-auto-logout'
        worker.postMessage({
            type: 'set',
            ms: 2000,
            id: intervalId,
        })
        worker.onmessage = () => {
            if (!SessionManager.get('mmt_auth_token')) {
                Auth.logout()
            }
        }
        if (process.env.REACT_APP_LOGROCKET_ENABLE) {
            LogRocket.init('jkmu5j/phoenix-test')
        }

        return () => worker.postMessage({
            type: 'clear',
            id: intervalId,
        })
    }, [])

    if (isLoading) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <Content>
                            <LoadingSpinner />
                        </Content>
                    </BrowserRouter>
                </ThemeProvider>
            </StyledEngineProvider>
        )
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Toasts />
                    <Shell>
                        <Switch>
                            <PrivateRoute
                                exact
                                path="/Cameras"
                                authorizedRoles={CameraAuth.List}
                                component={Cameras}
                            />

                            <Route
                                exact
                                path="/"
                                render={() => <Redirect to="/Dashboard" />}
                            />
                            <Route
                                exact
                                path="/Login"
                                component={Login}
                            />
                            <Route
                                exact
                                path="/invitation/:inviteId"
                                component={Invitation}
                            />
                            <Route
                                exact
                                path="/expiredinvitation"
                                component={ExpiredInvitation}
                            />
                            <PrivateRoute
                                exact
                                path="/Help"
                                component={Help}
                            />
                            <PrivateRoute
                                exact
                                path="/Dashboard"
                                component={Dashboard}
                            />

                            <PrivateRoute
                                exact
                                path="/Facilities"
                                authorizedRoles={FacilityAuth.List}
                                component={Facilities}
                            />

                            <PrivateRoute
                                exact
                                path="/Facility/Create"
                                authorizedRoles={[SUPER_ADMIN_ROLE]}
                                render={props => (
                                    <Facility {...props} creating />
                                )}
                            />

                            <PrivateRoute
                                exact
                                path="/Facility/:id"
                                authorizedRoles={FacilityAuth.View}
                                component={Facility}
                            />

                            <PrivateRoute
                                exact
                                path="/Studies"
                                authorizedRoles={StudyAuth.List}
                                component={Studies}
                            />

                            <PrivateRoute
                                exact
                                path="/Study/:id"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    FACILITY_ADMIN_ROLE,
                                    LEAD_TECH_ROLE,
                                    FIELD_TECH_ROLE,
                                    REVIEW_DOCTOR_ROLE,
                                ]}
                                component={Study}
                            />

                            <PrivateRoute
                                exact
                                path="/shared-study/:facilityId/:id"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    FACILITY_ADMIN_ROLE,
                                    LEAD_TECH_ROLE,
                                    FIELD_TECH_ROLE,
                                    REVIEW_DOCTOR_ROLE,
                                ]}
                                component={SharedStudy}
                            />

                            <PrivateRoute
                                exact
                                path="/Study/:id/AuditLogs"
                                authorizedRoles={StudyAuth.AuditLogsPage}
                                component={CPTStudyMonitoring}
                            />

                            <PrivateRoute
                                exact
                                path="/SharedUsers/:id"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    FACILITY_ADMIN_ROLE,
                                    LEAD_TECH_ROLE,
                                    FIELD_TECH_ROLE,
                                    REVIEW_DOCTOR_ROLE,
                                ]}
                                component={SharedUsers}
                            />

                            <PrivateRoute
                                exact
                                path="/Amplifiers"
                                authorizedRoles={AmplifierAuth.List}
                                component={Amplifiers}
                            />

                            <PrivateRoute
                                exact
                                path="/Montages"
                                authorizedRoles={MontageAuth.List}
                                render={props => (<Montages {...props} />)}
                            />

                            <PrivateRoute
                                exact
                                path="/Montage/:montageId"
                                authorizedRoles={MontageAuth.Edit}
                                render={props => (<MontageEditor {...props} />)}
                            />

                            <PrivateRoute
                                exact
                                path="/Amplifier/Create"
                                authorizedRoles={AmplifierAuth.Create}
                                render={props => (
                                    <AmplifierView
                                        {...props}
                                        creating
                                    />
                                )}
                            />

                            <PrivateRoute
                                exact
                                path="/Amplifier/:id"
                                authorizedRoles={AmplifierAuth.EditName}
                                component={AmplifierView}
                            />

                            <PrivateRoute
                                exact
                                path="/Devices"
                                authorizedRoles={DeviceAuth.List}
                                component={Devices}
                            />

                            <PrivateRoute
                                exact
                                path="/Device/Create"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    PRODUCTION_ROLE,
                                ]}
                                render={props => (
                                    <Device {...props} creating />
                                )}
                            />

                            <PrivateRoute
                                exact
                                path="/Device/:id"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    PRODUCTION_ROLE,
                                ]}
                                component={Device}
                            />

                            <PrivateRoute
                                exact
                                path="/Logs/DeviceLogs/:id"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    PRODUCTION_ROLE,
                                ]}
                                component={DeviceLogs}
                            />

                            <PrivateRoute
                                exact
                                path="/Patients"
                                authorizedRoles={PatientAuth.List}
                                component={Patients}
                            />

                            <PrivateRoute
                                exact
                                path="/Patient/Create"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    FACILITY_ADMIN_ROLE,
                                    LEAD_TECH_ROLE,
                                    FIELD_TECH_ROLE,
                                    OFFICE_PERSONNEL_ROLE,
                                ]}
                                render={props => (
                                    <Patient {...props} creating />
                                )}
                            />

                            <PrivateRoute
                                exact
                                path="/Patient/:id"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    FACILITY_ADMIN_ROLE,
                                    LEAD_TECH_ROLE,
                                    FIELD_TECH_ROLE,
                                    OFFICE_PERSONNEL_ROLE,
                                ]}
                                component={Patient}
                            />

                            <PrivateRoute
                                exact
                                path="/Patient/:patientId/ImportStudy"
                                authorizedRoles={PatientAuth.Import}
                                component={ImportStudy}
                            />

                            <PrivateRoute
                                exact
                                path="/ImportStudy/CloudSyncPackage"
                                authorizedRoles={PatientAuth.Import}
                                component={ImportStudy}
                            />

                            <PrivateRoute
                                exact
                                path="/ImportStudy/Trackit"
                                authorizedRoles={PatientAuth.Import}
                                component={ImportPatientSelect}
                            />

                            <PrivateRoute
                                exact
                                path="/ImportStudy/CloudSyncPackage"
                                authorizedRoles={PatientAuth.Import}
                                component={ImportStudy}
                            />

                            <PrivateRoute
                                exact
                                path="/Patient/:id/Documents"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                    FACILITY_ADMIN_ROLE,
                                    LEAD_TECH_ROLE,
                                    FIELD_TECH_ROLE,
                                ]}
                                component={PatientDocuments}
                            />

                            <PrivateRoute
                                exact
                                path="/Patient/:id/AuditLogs"
                                authorizedRoles={[
                                    SUPER_ADMIN_ROLE,
                                    SUPPORT_ROLE,
                                ]}
                                component={PatientAuditLogs}
                            />
                            <PrivateRoute
                                exact
                                path="/Users"
                                authorizedRoles={UserAuth.List}
                                component={Users}
                            />
                            <PrivateRoute
                                exact
                                path="/User/:id/AuditLogs"
                                authorizedRoles={UserAuth.AuditLogsPage}
                                component={CPTUserMonitoring}
                            />
                            <PrivateRoute
                                exact
                                path="/User/Create"
                                authorizedRoles={UserAuth.Create}
                                render={props => (
                                    <User {...props} creating />
                                )}
                            />

                            <PrivateRoute
                                exact
                                path="/User/:id"
                                authorizedRoles={UserAuth.Edit}
                                component={User}
                            />

                            <PrivateRoute
                                exact
                                path="/Profile"
                                component={Profile}
                            />

                            <Route
                                exact
                                path="/ForgotPassword"
                                component={
                                    Auth.loggedIn()
                                        ? Page404
                                        : ForgotPassword
                                }
                            />
                            <Route
                                exact
                                path="/PasswordReset"
                                component={
                                    Auth.loggedIn()
                                        ? Page404
                                        : PasswordReset
                                }
                            />

                            <Route component={Page404} />
                        </Switch>
                    </Shell>
                </BrowserRouter>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App
