import React from 'react'
import './MetricBlock.scss'
import { Card } from '@mui/material'

const MetricBlock = ({ name, units, value, link, colorClassName }) => (
    <Card className="metric-block" raised>
        <a href={link}>
            <div className={`metric-box  ${colorClassName}`}>
                <div className="metric card-block">
                    <div title={name} className="text-nowrap">
                        {name}
                    </div>
                    <span className="metric-value">{value}</span>
                    {units && units.length ? (
                        <span className="metric-unit">{units}</span>
                    ) : null}
                </div>
            </div>
        </a>
    </Card>
)

export default MetricBlock
