import React, { useState, useEffect } from 'react'
import MMTApi from '../../../services/MMTApi'
import packageJson from '../../../../package.json'
import { Auth } from '../../../services/Auth'
import { useTheme } from '@mui/material'

const Footer = () => {
    const [apiVersion, setApiVersion] = useState('')
    const theme = useTheme()

    useEffect(() => {
        if (Auth.loggedIn()) {
            MMTApi.getAPIVersion().then(response => {
                setApiVersion(response.data)
            })
        }
    }, [])

    return (
        <footer style={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: theme.zIndex.drawer+1,
            padding: theme.spacing(0.5, 2),
            background: theme.palette.background.default,
        }}>
            <span>
                <a
                    href="http://lifelinesneuro.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    rendr PLATFORM
                </a>{' '}
                &copy; {new Date().getFullYear()} Lifelines Neuro
            </span>
            {apiVersion && (
                <small className="text-muted" style={{ marginLeft: 20 }}>
                    &emsp;build - {apiVersion} | {packageJson.version}
                </small>
            )}
        </footer>
    )
}

export default Footer
