type DivRef = React.MutableRefObject<HTMLDivElement | null>

export const handleZoomIn = (limit: number, msPerPx: number, wrapper: DivRef, setMsPerPx: (msPerPx: number) => void) => {
    let factor = 0.7
    const newMsPerPx = Math.max(limit, msPerPx * factor)
    factor = newMsPerPx / msPerPx
    if (wrapper.current && newMsPerPx !== msPerPx) {
        const scrollTop = wrapper.current.scrollTop
        const scrollMiddle = wrapper.current.clientHeight / 2 + scrollTop
        const newScrollTop = scrollMiddle / factor - wrapper.current.clientHeight / 2
        wrapper.current.scrollTo({ top: newScrollTop })
        setMsPerPx(newMsPerPx)
    }
}

export const handleZoomOut = (limit: number, msPerPx: number, wrapper: DivRef, setMsPerPx: (msPerPx: number) => void) => {
    let factor = 1.3
    const newMsPerPx = Math.min(limit, msPerPx * factor)
    factor = newMsPerPx / msPerPx
    if (wrapper.current && newMsPerPx !== msPerPx) {
        const scrollTop = wrapper.current.scrollTop
        const scrollMiddle = wrapper.current.clientHeight / 2 + scrollTop
        const newScrollTop = scrollMiddle / factor - wrapper.current.clientHeight / 2
        wrapper.current.scrollTo({ top: newScrollTop })
        setMsPerPx(newMsPerPx)
    }
}
