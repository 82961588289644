import { useEffect, useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Box, Button, Typography } from '@mui/material'
import InputGroup from '../../components/forms/InputGroup'
import ScreenCalibration from '../../types/ScreenCalibration'
import ScreenUtils from '../../utils/ScreenUtils'
import ScreenCalibrationMeasurementArrow from './ScreenCalibrationMeasurementArrow'
import SessionManager from '../../services/SessionManager'
import moment from 'moment'

interface Props {
    onSaved: (calibration: ScreenCalibration) => void
    onClose: () => void
    calibration: ScreenCalibration
}

const measuringBoxWidthPx = 800

function getInitialInputValue(calibration: ScreenCalibration): string {
    if (!calibration.timestamp) return ''
    let cm = measuringBoxWidthPx / calibration.ppcm
    if (Math.abs(cm - Math.round(cm)) < 0.000001) {
        cm = Math.round(cm)
    }
    return cm.toString()
}

function ScreenCalibrationModal(props: Props) {
    const [calibration, setCalibration] = useState(props.calibration)
    const [input, setInput] = useState(getInitialInputValue(props.calibration))

    const validationError = ((): string => {
        if (input === '') return ''
        if (!/^-?[0-9.]+$/.test(input) || input.split('.').length > 2) {
            return 'Must be a number'
        }
        const floatVal = parseFloat(input)
        if (floatVal <= 0) {
            return 'Must be a number greater than 0'
        }
        if (floatVal > 100) {
            return 'Must be a number less than or equal to 100'
        }
        return ''
    })()

    useEffect(() => {
        if (validationError !== '' || input === '') return
        setCalibration({
            userId: SessionManager.get('mmt_user_id'),
            userName: SessionManager.get('mmt_user_name'),
            timestamp: Date.now(),
            ppcm: measuringBoxWidthPx / parseFloat(input),
            screen: ScreenUtils.fingerprint(),
        })
    }, [input, validationError])

    useEffect(() => {
        if (input.startsWith('.')) {
            setInput(i => `0${i}`)
        }
        if (input.length > 10) {
            setInput(i => i.slice(0, 10))
        }
    }, [input])

    return (
        <Modal className="modal-fullscreen" isOpen toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>
                Screen Calibration
            </ModalHeader>
            <ModalBody>
                <Box sx={{ p: 2, textAlign: 'center' }}>
                    Measure the length of this rectangle:
                    <Box sx={{
                        position: 'relative',
                        bgcolor: 'background.paper',
                        width: `${measuringBoxWidthPx}px`,
                        color: '#fff',
                        margin: 'auto',
                        mt: 2,
                        mb: 2,
                        height: '50px',
                    }}>
                        <ScreenCalibrationMeasurementArrow />
                    </Box>
                    <Box sx={{ maxWidth: 400, margin: 'auto' }}>
                        <InputGroup
                            value={input}
                            label="cm"
                            error={validationError}
                            valid={validationError === ''}
                            handleChange={(_: any, val: string) => setInput(val)}
                        />
                    </Box>
                    {props.calibration.timestamp > 0 && (
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: 'center',
                                color: '#999',
                                fontSize: 14,
                                mb: 2,
                            }}
                        >
                            Calibrated on {moment(props.calibration.timestamp).format('MM/DD/YYYY')} by {props.calibration.userName}
                        </Typography>
                    )}
                    <Button variant="outlined" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => props.onSaved(calibration)}
                        sx={{ ml: 2 }}
                        disabled={validationError !== '' || input === ''}
                    >
                        Save
                    </Button>
                </Box>
            </ModalBody>
        </Modal>
    )
}

export default ScreenCalibrationModal
