import { useEffect, useState } from 'react'

const useDeviceWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const listener = () => {
            setWidth(window.innerWidth)
        }
        window.addEventListener('resize', listener)
        return () => window.removeEventListener('resize', listener)
    }, [])

    return width
}

export default useDeviceWidth
