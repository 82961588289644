import React from 'react'
import './EEGContainer.scss'
import OptionsMenu from './OptionsMenu/OptionsMenu'
import ElectroGraph from './eegMonitor/ElectroGraph'
import GraphControl from './GraphControl'
import FilterUtilities from './utils/FilterUtilities'
import FullScreenUtilities from './utils/FullScreenUtilities'
import StudyEventsList from './StudyEventsList'
import ThemeSelectPanel from './ThemeSelectPanel'
import SessionManager from '../../services/SessionManager'
import ThemesApi from '../../services/ThemesApi'
import ScreenCalibrationService from '../../services/ScreenCalibrationService'
import NoteModal from './NoteModal'
import PrintModal from './PrintModal'
import ReviewOverlay from './review/ReviewOverlay'
import VideoPlayer from './VideoPlayer'
import { EEG } from './eegMonitor/constants'
import {
    SETTING_MONTAGE,
    SETTING_HIGH_FILTER,
    SETTING_LOW_FILTER,
    SETTING_NOTCH_FILTER,
    SETTING_SENSITIVITY,
    SETTING_TIMEBASE,
} from '../../constants/graphsettings'
import ModalBool from '../../components/modals/ModalBool'
import { NOTE, VIDEO_START } from '../../constants/studyevents'
import QuickNotesDropdown from './OptionsMenu/QuickNotesDropdown'
import StorageManager from '../../services/StorageManager'
import AppContext from '../../components/AppContext'
import ClockSetting from '../../types/ClockSetting'
import moment from 'moment'
import { Action } from '../../components/AppState'
import ScreenCalibrationModal from './ScreenCalibrationModal'
import ToastUtils from '../../utils/ToastUtils'

class EEGContainer extends React.Component {
    static contextType = AppContext
    state = {
        are_current_epoch_packets_loaded: false,
        is_note_modal_open: false,
        creating_event_of_type: null,
        creating_event_on_channel: null,
        is_review_overlay_open: false,
        can_close_review_overlay: true,
        is_quick_notes_open: false,
        is_creating_note: false,
        is_confirmation_modal_open: false,
        is_study_events_open: false,
        is_theme_panel_open: false,
        is_print_modal_open: false,
        is_more_options_menu_open: false,
        is_speed_control_open: false,
        is_calibration_modal_open: false,
        calibration: {
            ppcm: 0,
            screen: '',
        },
        is_fullscreen: false,
        is_fullscreen_available: FullScreenUtilities.available(),
        current_second_in_study: 0,
        current_moment_in_study: moment(),
        total_seconds_in_study: 0,
        is_next_disabled: false,
        is_prev_disabled: false,
        selected_study_event: {
            Comment: '',
            StartPacketIndex: null,
            EndPacketIndex: null,
            ChannelTypeID: 0,
            Value: '',
            EventTypeID: NOTE,
            StudyID: 0,
            RecordingIndex: 0,
        },
        selected_study_event_id: null,
        last_selected_study_event_id: null,
        settings: {
            Montage: {},
            LowFilter: {},
            HighFilter: {},
            NotchFilter: {},
            Sensitivity: {},
            Timebase: {},
        },
        is_playing: false,
        is_video_player_open: false,
        play_cursor_index_at_last_video_critical_event: 0,
        force_event_list_focus: false,
        is_focused_review: false,
        play_speed: 0,
        clock_setting: this.getStoredClockSetting(),
        video_message: '',
    }

    constructor(props) {
        super(props)
        const settings = this.getSettings()
        this.toggleScreenCalibrator()
        this.state = {
            ...this.state,
            last_play_speed: 0,
            settings,
            calibration: this.screenCalibSvc.getCalibration(),
        }
    }

    // #region Lifecycle

    componentDidMount() {
        const theme = this.props.userEegTheme
            ? this.props.userEegTheme
            : this.props.Themes[0]
        this.setUserEegTheme(theme)
        this.setLastSelectedEvent()
        FullScreenUtilities.registerChangeListener(
            this.fullscreenChangeListener,
        )
        if (this.hasVideo()) this.setState({ is_video_player_open: true })
        this.addEventListeners()
    }

    componentDidUpdate(_prevProps, prevState) {
        if (prevState.play_speed !== this.state.play_speed) {
            this.electroGraph.updateDataRequestLength()
            if (this.state.play_speed > 0) {
                // Request more data
                this.electroGraph.graphDataManager.epochChanged()
            }
        }
        if (prevState.is_focused_review !== this.state.is_focused_review) {
            if (this.state.is_focused_review) {
                this.electroGraph.graphDataManager.epochChanged()
            }
        }
    }

    componentWillUnmount() {
        if (this.state.is_fullscreen === true) {
            FullScreenUtilities.exit()
        }
        FullScreenUtilities.unregisterChangeListener(
            this.fullscreenChangeListener,
        )
        this.removeEventListener()
        this.screenCalibSvc.destroy()
        this.context.commit(Action.SetAreGraphControlsMinimized, false)
    }

    addEventListeners() {
        window.addEventListener('keyup', this.handleKeyUp)
    }

    removeEventListener() {
        window.removeEventListener('keyup', this.handleKeyUp)
    }

    handleKeyUp = e => {
        // #region variable setup
        const arePacketsLoaded = this.state.are_current_epoch_packets_loaded
        const filterOptions = this.getFilterOptions()
        const isRealTime = this.state.play_speed === 0

        const areSettingDropdownsClosed = document.activeElement.closest('.graph-settings-dropdown') === null
        const activeElement = document.activeElement.localName
        const documentBodyHasFocus = activeElement === 'body'

        const areModalsClosed =
            !this.state.is_confirmation_modal_open &&
            !this.state.is_note_modal_open &&
            !this.state.is_creating_note &&
            !this.state.is_print_modal_open &&
            !this.state.is_review_overlay_open &&
            !this.state.is_calibration_modal_open

        const notCreatingEvent =
            !this.state.is_quick_notes_open &&
            this.state.creating_event_of_type === null

        const areSidePanelsClosed =
            !this.state.is_theme_panel_open &&
            !this.state.is_study_events_open

        const areMenusClosed =
            !this.state.is_quick_notes_open &&
            !this.state.is_more_options_menu_open

        const okToUseUpDnArrowKeys =
            notCreatingEvent &&
            areModalsClosed &&
            areMenusClosed &&
            (areSidePanelsClosed || documentBodyHasFocus) &&
            areSettingDropdownsClosed

        const okToUseLeftRightArrowKeys =
            notCreatingEvent &&
            areModalsClosed &&
            areMenusClosed &&
            areSettingDropdownsClosed

        const okToPlay =
            arePacketsLoaded &&
            areModalsClosed &&
            notCreatingEvent

        const includesCtrlAltShift = e.altKey || e.ctrlKey || e.shiftKey
        const numberKeyCodes = Array.from({ length: 10 }, (k, i) => i + 48)
        const didPressNumberKey = numberKeyCodes.includes(e.keyCode) && !includesCtrlAltShift

        // #endregion

        // 0-9 : Playback speed shortcuts
        if (didPressNumberKey && notCreatingEvent && areModalsClosed) {
            let keyValue = parseInt(e.key)
            if (keyValue === 0) keyValue = 10
            this.setPlaybackSpeedFromKeyboard(keyValue)
            return
        }

        const noCtrlAltPressed = !e.ctrlKey && !e.altKey
        const isArrowKey = e.keyCode === 40 || e.keyCode === 38

        switch (e.keyCode) {
            case 40: // ↓ - sensitivity increment
            case 38: // ↑ - sensitivity decrement
            case 87: // w - WASD - up arrow - Sensitivty increment
            case 83: // s - WASD - dn arrow - Sensitivity decrement
                if (!okToUseUpDnArrowKeys || !noCtrlAltPressed) break
                if (isArrowKey && !documentBodyHasFocus) break
                const shouldIncrement = e.keyCode === 40 || e.keyCode === 83
                const shouldDecrement = e.keyCode === 38 || e.keyCode === 87

                shouldIncrement &&
                    this.incrementSetting('Sensitivity', filterOptions.SensitivitySettings, this.state.settings.Sensitivity)
                shouldDecrement &&
                    this.decrementSetting('Sensitivity', filterOptions.SensitivitySettings, this.state.settings.Sensitivity)
                break
            case 37: // ← - previous epoc
            case 39: // → - next epoc
            case 65: // a - WASD - prev epoc / tick (shift a)
            case 68: // d - WASD - next epoc / tic (shift d)
                if (!okToUseLeftRightArrowKeys || !noCtrlAltPressed) break
                const moveForward = e.keyCode === 39 || e.keyCode === 68
                const moveBackward = e.keyCode === 37 || e.keyCode === 65

                moveForward && !e.shiftKey && this.handleNextClick(e)
                moveForward && e.shiftKey && this.electroGraph.toNextMajorTick(e)
                moveBackward && !e.shiftKey && this.handlePrevClick(e)
                moveBackward && e.shiftKey && this.electroGraph.toPrevMajorTick(e)
                break
            case 78: // n - create event
                if (e.altKey) {
                    this.toggleCreateEventMode(NOTE)
                } else {
                    this.toggleQuickNotes()
                }
                break
            case 69: // e - toggle events panel
                okToPlay && this.toggleStudyEvents()
                break
            case 73: // i - toggle review overlay
                this.state.creating_event_of_type === null &&
                    this.state.can_close_review_overlay &&
                    !this.state.is_note_modal_open &&
                    this.toggleReviewOverlay()
                break
            case 70: // f - toggle focused review mode
                areModalsClosed && notCreatingEvent && this.toggleFocusedReview()
                break
            case 191: // / - toggles fast play & real time
                notCreatingEvent && !isRealTime && this.resetPlaybackSpeed()
                notCreatingEvent && isRealTime && this.resetToPreviousPlaybackSpeed()
                break
            case 188: // < - play speed decrement
                notCreatingEvent && this.decrementPlaybackSpeed()
                break
            case 190: // > - playback speed increment
                notCreatingEvent && this.incrementPlaybackSpeed()
                break
            case 32: // spacebar - start / stop playback
                e.preventDefault()
                if (okToPlay) {
                    this.handlePlayClick()
                }
                break
            case 27: // ESC
                if (this.state.is_quick_notes_open) {
                    this.toggleQuickNotes()
                }
                if (this.state.creating_event_of_type) {
                    this.toggleCreateEventMode(NOTE)
                }
                if (this.state.is_study_events_open) {
                    this.toggleStudyEvents()
                }
                if (this.state.is_note_modal_open) {
                    this.toggleModal()
                }
                break
            default:
                break
        }
    }

    getFilterOptions = () => ({
        Montages: this.props.Montages,
        LowFilterSettings: this.props.LowFilterSettings,
        HighFilterSettings: this.props.HighFilterSettings,
        NotchFilterSettings: this.props.NotchFilterSettings,
        SensitivitySettings: this.props.SensitivitySettings,
        TimebaseSettings: this.props.TimebaseSettings,
    })

    getSettings = () => {
        const Montage =
            this.getStoredSetting(SETTING_MONTAGE) ||
            FilterUtilities.getMontageTemplate(this.props)
        const LowFilter =
            this.getStoredSetting(SETTING_LOW_FILTER) ||
            FilterUtilities.getLowFilter(this.props)
        const HighFilter =
            this.getStoredSetting(SETTING_HIGH_FILTER) ||
            FilterUtilities.getHighFilter(this.props)
        const NotchFilter =
            this.getStoredSetting(SETTING_NOTCH_FILTER) ||
            FilterUtilities.getNotchFilter(this.props)
        const Sensitivity =
            this.getStoredSetting(SETTING_SENSITIVITY) ||
            FilterUtilities.getSensitivity(this.props)
        const Timebase =
            this.getStoredSetting(SETTING_TIMEBASE) ||
            FilterUtilities.getTimebase(this.props)

        const settings = {
            ...this.state.settings,
            Montage,
            LowFilter,
            HighFilter,
            NotchFilter,
            Sensitivity,
            Timebase,
        }

        return settings
    }

    setUserEegTheme(theme) {
        if (theme) {
            this.setState({ userEegTheme: theme })
            this.electroGraph.setTheme(theme)
        }
    }

    setLastSelectedEvent() {
        const lastSelectedEvent = this.getStoredlastSelectedEvent()
        this.setState({ last_selected_study_event_id: lastSelectedEvent })
    }

    // #endregion

    // #region UI Handlers

    handleSelectEvent = async (event, moveGraph = true) => {
        if (this.state.is_playing) {
            this.handlePause()
        }
        if (this.state.selected_study_event_id === event.ID) {
            this.setState({ selected_study_event_id: null })
        } else {
            if (moveGraph) {
                await this.moveGraphToStudyEvent(event)
            }
            this.handleVideoResync()
            this.setState({ selected_study_event_id: event.ID })
        }

        this.setState({ last_selected_study_event_id: event.ID },
            this.storeLastSelectedEvent(event.ID))
    }

    getSelectedImportantEvent = () => this.context.Study.StudyEvents.find(e => e.Important && e.ID === this.state.selected_study_event_id)

    getChronologicallySortedImportantEvents = () => {
        const importantEvents = this.context.Study.StudyEvents.filter(e => e.Important)
        const sortedEvents = importantEvents.sort((a, b) => {
            if (a.RecordingIndex !== b.RecordingIndex)
                return a.RecordingIndex - b.RecordingIndex
            return a.StartPacketIndex - b.StartPacketIndex
        })
        return sortedEvents
    }

    getNextImportantEvent = () => {
        const events = this.getChronologicallySortedImportantEvents()
        const selectedEvent = this.getSelectedImportantEvent()
        if (selectedEvent) {
            const ev = events.find(e => {
                if (e.RecordingIndex > selectedEvent.RecordingIndex) return true
                if (e.RecordingIndex < selectedEvent.RecordingIndex) return false
                return e.StartPacketIndex > selectedEvent.StartPacketIndex
            })
            if (ev) return ev
        } else {
            const ev = this.getNextImportantEventFromPlayCursor()
            if (ev) return ev
        }
        return null
    }

    getNextImportantEventFromPlayCursor = () => {
        const events = this.getChronologicallySortedImportantEvents()
        return events.find(e => {
            const eventIndex = this.electroGraph.calculateSampleIndex(e.RecordingIndex, e.StartPacketIndex)
            return eventIndex >= this.electroGraph.playCursor.index
        })
    }

    getNextImportantEpochOffset = () => {
        const events = this.getChronologicallySortedImportantEvents()
        const samplesPerEpoch = this.electroGraph.state.samples_per_window
        const epochStartIndex = this.electroGraph.state.sample_offset
        const epochEndIndex = epochStartIndex + samplesPerEpoch - 1

        for (const event of events) {
            const eventStartIndex = this.electroGraph.calculateSampleIndex(event.RecordingIndex, event.StartPacketIndex)
            const eventEndIndex = event.EndPacketIndex ? eventStartIndex + (event.EndPacketIndex - event.StartPacketIndex) : eventStartIndex
            if (eventEndIndex <= epochStartIndex) continue
            if (eventStartIndex <= epochEndIndex && eventEndIndex > epochEndIndex) {
                return epochEndIndex + 1
            }
            if (eventStartIndex > epochEndIndex) {
                return Math.floor(eventStartIndex / samplesPerEpoch) * samplesPerEpoch
            }
        }
        return this.electroGraph.state.sample_offset
    }

    getPrevImportantEventFromPlayCursor = () => {
        const events = this.getChronologicallySortedImportantEvents().reverse()
        return events.find(e => {
            const eventIndex = this.electroGraph.calculateSampleIndex(e.RecordingIndex, e.StartPacketIndex)
            return eventIndex >= this.electroGraph.playCursor.index
        })
    }

    getPrevImportantEvent = () => {
        const events = this.getChronologicallySortedImportantEvents().reverse()
        const selectedEvent = this.getSelectedImportantEvent()
        if (selectedEvent) {
            const ev = events.find(e => {
                if (e.RecordingIndex < selectedEvent.RecordingIndex) return true
                if (e.RecordingIndex > selectedEvent.RecordingIndex) return false
                return e.StartPacketIndex < selectedEvent.StartPacketIndex
            })
            if (ev) return ev
        } else {
            const ev = this.getPrevImportantEventFromPlayCursor()
            if (ev) return ev
        }
        return null
    }

    handleSelectNextImportantEvent = () => {
        const nextImportantEvent = this.getNextImportantEvent()
        if (nextImportantEvent) {
            this.handleSelectEvent(nextImportantEvent, true)
        } else {
            const events = this.getChronologicallySortedImportantEvents()
            const firstImportantEvent = events[0]
            if (firstImportantEvent) {
                this.handleSelectEvent(firstImportantEvent)
            }
        }
    }

    handleSelectPrevImportantEvent = () => {
        const prevImportantEvent = this.getPrevImportantEvent()
        if (prevImportantEvent) {
            this.handleSelectEvent(prevImportantEvent, true)
        } else {
            const events = this.getChronologicallySortedImportantEvents().reverse()
            const lastImportantEvent = events[0]
            if (lastImportantEvent) {
                this.handleSelectEvent(lastImportantEvent)
            }
        }
    }

    moveGraphToStudyEvent = async event => {
        if (this.electroGraph) {
            await this.electroGraph.moveGraphToStudyEvent(event)
        }
    }

    handleSettingSelect = (name, value) => {
        let settingValue
        switch (name) {
            case SETTING_MONTAGE: {
                settingValue = this.props.Montages.find(s => s.Name === value)
                this.setState({
                    settings: {
                        ...this.state.settings,
                        Montage: settingValue,
                    },
                    are_current_epoch_packets_loaded: false,
                })
                this.electroGraph.setMontageTemplate(settingValue)
                break
            }
            case SETTING_HIGH_FILTER: {
                settingValue = this.props.HighFilterSettings.find(
                    s => s.Value === value,
                )
                this.setState({
                    settings: {
                        ...this.state.settings,
                        HighFilter: settingValue,
                    },
                    are_current_epoch_packets_loaded: false,
                })
                this.electroGraph.setHighFilter(EEG, value)
                break
            }
            case SETTING_LOW_FILTER: {
                settingValue = this.props.LowFilterSettings.find(
                    s => s.Value === value,
                )
                this.setState({
                    settings: {
                        ...this.state.settings,
                        LowFilter: settingValue,
                    },
                    are_current_epoch_packets_loaded: false,
                })
                this.electroGraph.setLowFilter(EEG, value)
                break
            }
            case SETTING_NOTCH_FILTER: {
                if (value === 'Off') {
                    settingValue = { Value: 'Off', Units: '' }
                    this.setState({
                        settings: {
                            ...this.state.settings,
                            NotchFilter: settingValue,
                        },
                        are_current_epoch_packets_loaded: false,
                    })
                    this.electroGraph.setNotchFilter(
                        this.state.settings.NotchFilter.Value,
                        false,
                    )
                    break
                }
                settingValue = this.props.NotchFilterSettings.find(
                    s => s.Value === value,
                )
                this.setState({
                    settings: {
                        ...this.state.settings,
                        NotchFilter: settingValue,
                    },
                    are_current_epoch_packets_loaded: false,
                })
                this.electroGraph.setNotchFilter(value)
                break
            }
            case SETTING_SENSITIVITY: {
                settingValue = this.props.SensitivitySettings.find(
                    s => s.Value === value,
                )
                this.setState({
                    settings: {
                        ...this.state.settings,
                        Sensitivity: settingValue,
                    },
                })
                break
            }
            case SETTING_TIMEBASE: {
                settingValue = this.props.TimebaseSettings.find(
                    s => s.Value === value,
                )
                const maxPlaySpeed = this.getMaxPlaybackSpeed(settingValue.Value)
                if (maxPlaySpeed < this.state.play_speed) {
                    this.setState({ play_speed: maxPlaySpeed })
                }
                this.setState({
                    settings: {
                        ...this.state.settings,
                        Timebase: settingValue,
                    },
                })
                break
            }
            default:
                break
        }
        this.electroGraph.isResizeNeeded = true
        this.storeSetting(name, settingValue)
    }

    incrementSetting(settingName, options, currentValue) {
        const index = options.findIndex(o => o.Value === currentValue.Value)
        const next = index + 1 === options.length ? options[index] : options[index + 1]
        this.setState({ settings: { ...this.state.settings, [settingName]: next } })
    }

    decrementSetting(settingName, options, currentValue) {
        const index = options.findIndex(o => o.Value === currentValue.Value)
        const next = index - 1 < 0 ? options[0] : options[index - 1]
        this.setState({ settings: { ...this.state.settings, [settingName]: next } })
    }

    storeSetting(name, value) {
        StorageManager.set(`study:${this.props.Study.ID}:${name}`, value)
    }

    getStoredSetting(name) {
        return StorageManager.get(`study:${this.props.Study.ID}:${name}`)
    }

    storeClockSetting(setting) {
        StorageManager.set('ClockSetting', setting)
    }

    getStoredClockSetting() {
        let setting = StorageManager.get('ClockSetting')
        if (setting === null) {
            setting = ClockSetting.TwentyFourHour
        }
        return setting
    }

    storeLastSelectedEvent(eventID) {
        StorageManager.set(
            `study:${this.context.Study.ID}:LastSelectedEvent`,
            eventID,
        )
    }

    getStoredlastSelectedEvent() {
        return StorageManager.get(
            `study:${this.context.Study.ID}:LastSelectedEvent`,
        )
    }

    // #endregion

    // #region UI Toggles

    togglePrintModal = () => {
        this.electroGraph.props.onPause()
        this.setState({
            creating_event_of_type: null,
            is_print_modal_open: !this.state.is_print_modal_open,
            is_theme_panel_open: false,
            is_study_events_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
        })
    }

    toggleQuickNotes = () => {
        const okToOpen = !this.state.is_note_modal_open &&
            !this.state.is_review_overlay_open &&
            !this.state.is_print_modal_open &&
            this.state.creating_event_of_type === null &&
            !(this.state.is_playing && this.state.play_speed > 0) &&
            this.state.are_current_epoch_packets_loaded

        if (!okToOpen)
            return

        this.setState({
            creating_event_of_type: null,
            is_theme_panel_open: false,
            is_study_events_open: false,
            is_more_options_menu_open: false,
            is_quick_notes_open: !this.state.is_quick_notes_open,
        })
    }

    toggleReviewOverlay = () => {
        this.setState({
            creating_event_of_type: null,
            is_review_overlay_open: !this.state.is_review_overlay_open,
            is_study_events_open: false,
            is_theme_panel_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
            is_playing: false,
        })
    }

    toggleThemeSelectPanel = () => {
        this.setState({
            creating_event_of_type: null,
            is_study_events_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
            is_theme_panel_open: !this.state.is_theme_panel_open,
        })
    }

    toggleClockSetting = () => {
        switch (this.state.clock_setting) {
            case ClockSetting.TwelveHour:
                this.setState({
                    clock_setting: ClockSetting.Duration,
                })
                this.storeClockSetting(ClockSetting.Duration)
                break

            case ClockSetting.TwentyFourHour:
                this.setState({
                    clock_setting: ClockSetting.TwelveHour,
                })
                this.storeClockSetting(ClockSetting.TwelveHour)
                break

            default:
                this.setState({
                    clock_setting: ClockSetting.TwentyFourHour,
                })
                this.storeClockSetting(ClockSetting.TwentyFourHour)
        }
    }

    openCalibrationModal = () => {
        this.setState({
            is_calibration_modal_open: true,
            creating_event_of_type: null,
            is_theme_panel_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
            is_study_events_open: false,
        })
    }

    toggleStudyEvents = () => {
        this.setState({
            creating_event_of_type: null,
            is_theme_panel_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
            is_study_events_open: !this.state.is_study_events_open,
        }, () => {
            const selectedEvent = document.getElementById('highlighted-event')
            !!selectedEvent && selectedEvent.scrollIntoView({ block: 'center' })
            !!selectedEvent && selectedEvent.focus()
        })
        window.document.body.focus()
    }

    toggleVideo = () => {
        this.setState({ is_video_player_open: !this.state.is_video_player_open })
        this.handleVideoResync()
    }

    toggleMoreOptionsMenu = () => {
        this.setState({
            creating_event_of_type: null,
            is_more_options_menu_open: !this.state.is_more_options_menu_open,
            is_quick_notes_open: false,
        })
    }

    toggleControls = () => {
        this.context.commit(Action.SetAreGraphControlsMinimized, !this.context.AreGraphControlsMinimized)

        if (this.state.is_fullscreen) {
            this.setState({ is_fullscreen: false })
            FullScreenUtilities.exit()
        } else if (!this.context.AreGraphControlsMinimized) {
            this.setState({ is_fullscreen: true })
            FullScreenUtilities.enter()
        }
    }

    toggleScreenCalibrator() {
        const warn = () => ToastUtils.warning({
            message: 'Please calibrate your screen\n Click here to to begin!',
            onClick: () => this.setState({ is_calibration_modal_open: true }),
        })
        let openToastId = 0
        const onNewScreen = (calibration, isValid) => {
            this.setState({ calibration })
            if (!isValid) {
                openToastId = warn()
            } else {
                if (ToastUtils.isOpen(/Please calibrate your screen\n Click here to begin!/)) {
                    ToastUtils.close(openToastId)
                }
            }
        }
        this.screenCalibSvc = new ScreenCalibrationService(localStorage, onNewScreen)
        if (!this.screenCalibSvc.isScreenCalibrated()) {
            openToastId = warn()
        }
    }

    // #endregion

    // #region Graph Handlers

    handlePrevClick = (e) => {
        if (this.state.is_playing) return
        this.electroGraph.shouldAutoPlay = false
        e.shiftKey ? this.electroGraph.toPrevMajorTick() : this.electroGraph.toPrev()
    }

    handleNextClick = (e) => {
        if (this.state.is_playing) return
        this.electroGraph.shouldAutoPlay = false
        e.shiftKey ? this.electroGraph.toNextMajorTick() : this.electroGraph.toNext()
    }

    handlePlayClick = () => {
        const shouldCloseEventPanel = !this.state.is_playing && this.state.is_study_events_open

        this.setState({ is_playing: !this.state.is_playing })
        shouldCloseEventPanel && this.setState({ is_study_events_open: false, reopen_event_panel_on_play_stop: true })
        this.handleVideoResync()
    }

    handlePlayEvent = () => {
        this.setState({ is_playing: true })
        this.handleVideoResync()
    }

    handlePause = reset => {
        if (!this.state.is_playing) 
            return

        console.log('[GRAPH] playback paused')
        const play_speed = reset ? 0 : this.state.play_speed
        this.setState({ play_speed, is_playing: false })
        this.handleVideoResync()
    }

    resetPlaybackSpeed = () => {
        if (this.state.play_speed !== 0) {
            this.setState({ last_play_speed: this.state.play_speed, play_speed: 0 })
        }
    }

    resetToPreviousPlaybackSpeed = () => {
        const lastPlaySpeed = this.state.last_play_speed
        !!lastPlaySpeed && this.handlePlaybackSpeedChange(lastPlaySpeed)
    }

    setPlaybackSpeedFromKeyboard(newSpeed) {
        const currentSpeed = this.state.play_speed
        const maxPlaybackSpeed = this.getMaxPlaybackSpeed()

        if (newSpeed > maxPlaybackSpeed) return
        const newSpeedSetting = newSpeed === currentSpeed ? 0 : newSpeed
        this.handlePlaybackSpeedChange(newSpeedSetting)
    }

    incrementPlaybackSpeed() {
        const maxSpeed = this.getMaxPlaybackSpeed()
        const moreThanMax = this.state.play_speed + 1 > maxSpeed
        const newSpeed = moreThanMax ? maxSpeed : this.state.play_speed + 1
        this.handlePlaybackSpeedChange(newSpeed)
    }

    decrementPlaybackSpeed() {
        const lessThanZero = this.state.play_speed - 1 < 0
        const newSpeed = lessThanZero ? 0 : this.state.play_speed - 1
        this.handlePlaybackSpeedChange(newSpeed)
    }

    // #endregion

    // #region Video

    hasVideo = () =>
        this.context.Study.StudyEvents.filter(
            e => e.EventTypeID === VIDEO_START,
        ).length > 0

    handleVideoResync = index => {
        if (!this.hasVideo()) return 
        index = index === undefined ? this.electroGraph.playCursor.index : index
        if (this.electroGraph) 
            this.electroGraph.setMinPacketIndexForVideoStart(index)
        this.setState({ play_cursor_index_at_last_video_critical_event: index })
    }

    // #endregion

    // #region Misc

    setCanCloseReviewOverlay = val => this.setState({ can_close_review_overlay: val })

    handlePlaybackSpeedChange = value => {
        this.setState({ play_speed: value })
    }

    handleSetAreCurrentEpochPacketsLoaded = are_current_epoch_packets_loaded => {
        if (
            this.state.are_current_epoch_packets_loaded !==
            are_current_epoch_packets_loaded
        ) {
            this.setState({ are_current_epoch_packets_loaded })
        }
    }

    changeUserEegTheme = themeId => {
        ThemesApi.setMyTheme(themeId)
            .then(res => {
                this.setUserEegTheme(res.data)
            })
            .catch(err => {
                console.log(`[ERROR] changeUserEegTheme() : ${err.statusText}`)
            })
    }

    handleUpdateGraphControl = (
        is_prev_disabled,
        is_next_disabled,
        current_second_in_study,
        current_moment_in_study,
    ) => {
        let momentMsDiffThreshold = 500
        if (this.state.settings.Timebase.Value === '1') {
            momentMsDiffThreshold = 100
        }

        const momentMsDiff = Math.abs(current_moment_in_study.diff(this.state.current_moment_in_study))

        return new Promise(resolve => {
            if (
                is_prev_disabled !== this.state.is_prev_disabled ||
                is_next_disabled !== this.state.is_next_disabled ||
                (this.state.clock_setting === ClockSetting.Duration && current_second_in_study !== this.state.current_second_in_study) ||
                (this.state.clock_setting !== ClockSetting.Duration && momentMsDiff >= momentMsDiffThreshold)
            ) {
                this.setState({
                    is_prev_disabled,
                    is_next_disabled,
                    current_second_in_study,
                    current_moment_in_study,
                }, resolve())
            } else {
                resolve()
            }
        })
    }

    handleUpdateGraphControlTotalSeconds = total_seconds_in_study => {
        if (total_seconds_in_study !== this.state.total_seconds_in_study) {
            this.setState({ total_seconds_in_study })
        }
    }

    handleCreateQuickNote = quick_note_type => {
        const StartPacketIndex = this.electroGraph.packets[
            this.electroGraph.playCursor.index
        ].Index
        const event = {
            StartPacketIndex,
            EndPacketIndex: null,
            StudyID: this.props.Study.ID,
            RecordingIndex: this.electroGraph.packets[
                this.electroGraph.playCursor.index
            ].RecordingIndex,
            EventTypeID: NOTE,
            Value: '',
            Comment: quick_note_type,
        }

        this.setState({
            is_quick_notes_open: false,
        })

        this.newStudyEvent(event)
            .then(selected_study_event_id => {
                this.setState({
                    selected_study_event_id,
                    last_selected_study_event_id: selected_study_event_id,
                })
            })
    }

    handleNewStudyEvent = selected_study_event => {
        this.setState({
            is_note_modal_open: true,
            is_theme_panel_open: false,
            is_study_events_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
            is_creating_note: true,
            selected_study_event,
        })
    }

    toggleCreateEventMode = (studyEventTypeId, channel) => {
        this.handlePause()
        const creating_event_of_type =
            this.state.creating_event_of_type === studyEventTypeId
                ? null
                : studyEventTypeId

        this.setState({
            creating_event_of_type: creating_event_of_type || null,
            creating_event_on_channel: channel || null,
            is_theme_panel_open: false,
            is_study_events_open: false,
            is_quick_notes_open: false,
            is_more_options_menu_open: false,
        })
    }

    handleDeleteStudyEvent = selected_study_event => {
        this.setState({
            selected_study_event,
            last_selected_study_event_id: undefined,
            is_confirmation_modal_open: true,
        }, this.storeLastSelectedEvent(null))
    }

    handleEditStudyEvent = selected_study_event => {
        this.setState({
            selected_study_event,
            is_note_modal_open: true,
        })
    }

    newStudyEvent = event =>
        this.props.handleNewStudyEvent(event)

    editStudyEvent = event => {
        this.props.handleEditStudyEvent(event)
    }

    deleteStudyEvent = event => {
        this.setState(
            {
                is_confirmation_modal_open: false,
            },
            () => {
                this.electroGraph.isEpochDrawNeeded = true
                this.props.handleDeleteStudyEvent(event)
            },
        )
    }

    handleSubmitStudyEvent = study_event => {
        this.setState(
            {
                is_note_modal_open: false,
            },
            () => {
                if (this.state.is_creating_note) {
                    this.newStudyEvent(study_event).then(
                        selected_study_event_id => {
                            this.setState({
                                selected_study_event_id,
                                last_selected_study_event_id: selected_study_event_id,
                            })
                        },
                    )
                } else {
                    this.editStudyEvent(study_event)
                }

                this.setState({
                    is_creating_note: false,
                    creating_event_of_type: null,
                    force_event_list_focus: true,
                })
            },
        )
    }

    handleCancelStudyEvent = () => {
        this.setState({
            is_note_modal_open: false,
            is_creating_note: false,
            creating_event_of_type: null,
        })
    }

    toggleConfirmationModal = () => {
        this.setState({
            is_confirmation_modal_open: !this.state.is_confirmation_modal_open,
            force_event_list_focus: true,
        })
    }

    toggleFocusedReview = () => {
        this.handlePause()
        this.setState({ is_focused_review: !this.state.is_focused_review })
    }

    fullscreenChangeListener = () => {
        if (this.state.is_fullscreen !== FullScreenUtilities.enabled()) {
            this.setState({
                is_fullscreen: !this.state.is_fullscreen,
            })
        }
    }

    resetForceEventListFocus = () => {
        this.setState({ force_event_list_focus: false })
    }

    getMaxPlaybackSpeed = (timebaseValue = this.state.settings.Timebase.Value) => {
        switch (timebaseValue) {
            case '1':
                return 30
            case '5':
                return 20
            case '10':
                return 10
            case '15':
                return 6
            case '20':
                return 5
            default:
                return 3
        }
    }

    // #endregion

    // #region Modals

    removeEventModal = () => (
        <ModalBool
            isOpen={this.state.is_confirmation_modal_open}
            title="Remove Event"
            message="Are you sure you want to remove the selected study event?"
            trueButtonText="Remove"
            falseButtonText="Cancel"
            toggle={this.toggleConfirmationModal}
            handleTrue={() =>
                this.deleteStudyEvent(
                    this.state.selected_study_event,
                )
            }
            handleFalse={this.toggleConfirmationModal}
        />
    )

    createNoteModal = () => (
        <NoteModal
            isCreating={this.state.is_creating_note}
            studyEvent={this.state.selected_study_event}
            handleSubmitStudyEvent={
                this.handleSubmitStudyEvent
            }
            handleCancelStudyEvent={
                this.handleCancelStudyEvent
            }
            toggle={this.handleCancelStudyEvent}
        />
    )

    printModal = () => (
        <PrintModal
            study={this.props.Study}
            settings={this.state.settings}
            patient={this.props.Patient}
            toggle={this.togglePrintModal}
            electroGraph={this.electroGraph}
        />
    )

    screenCalibrationModal = () => (
        <ScreenCalibrationModal
            onClose={() => this.setState({ is_calibration_modal_open: false })}
            onSaved={calibration => {
                this.setState({ is_calibration_modal_open: false, calibration })
                this.screenCalibSvc.update(calibration)
            }}
            calibration={this.state.calibration}
        />
    )

    // #endregion

    render() {
        // #region variables
        const options = this.getFilterOptions()
        const showVideoWindow = this.hasVideo()
        const isVideoWindowVisible =  this.state.is_video_player_open && !this.state.is_review_overlay_open && !this.state.creating_event_of_type
        const facility_themes = this.props.Themes
        const userEegTheme = this.state.userEegTheme || facility_themes[0]
        const events = this.context.Study.StudyEvents
        const isMontageSet = !!this.state.settings.Montage
        const socket_path = `${process.env.REACT_APP_WS_URL}/api/sync/subscribe/${this.props.Study.ID}`
        const areControlsMinimized = this.context.AreGraphControlsMinimized
        const minimizedControlsClass = areControlsMinimized ? 'controls-minimized' : ''
        const showPrintModal = this.state.is_print_modal_open
        const showCreateNoteModal = this.state.is_note_modal_open
        const showRemoveEventModal = this.state.is_confirmation_modal_open
        const showScreenCalibrationModal = this.state.is_calibration_modal_open
        const showReviewOverlay = this.state.is_review_overlay_open
        const showQuickNotes = this.state.is_quick_notes_open
        const showEventsPanel = this.state.is_study_events_open
        const showThemePanel = this.state.is_theme_panel_open
        const showMoreOptions = this.state.is_more_options_menu_open
        // #endregion

        return (
            <div className={`eeg-container fillspace ${minimizedControlsClass} aside-menu-fixed`}  >
                <React.Fragment>
                    {showPrintModal && this.printModal()}
                    {showCreateNoteModal && this.createNoteModal()}
                    {showRemoveEventModal && this.removeEventModal()}
                    {showScreenCalibrationModal && this.screenCalibrationModal()}

                    <div className="page active">

                        {// Options Menu
                            isMontageSet && (
                                <React.Fragment>
                                    <OptionsMenu
                                        settings={this.state.settings}
                                        options={options}
                                        areCurrentEpochPacketsLoaded={this.state.are_current_epoch_packets_loaded}
                                        creatingEventOfType={this.state.creating_event_of_type}
                                        isStudyEventsOpen={this.state.is_study_events_open}
                                        isThemePanelOpen={this.state.is_theme_panel_open}
                                        isReviewOverlayOpen={this.state.is_review_overlay_open}
                                        isQuickNotesOpen={this.state.is_quick_notes_open}
                                        isPrintModalOpen={this.state.is_print_modal_open}
                                        isMoreOptionsMenuOpen={this.state.is_more_options_menu_open}
                                        handleSettingSelect={this.handleSettingSelect}
                                        toggleCreateEventMode={this.toggleCreateEventMode}
                                        handleToggleReviewOverlay={this.toggleReviewOverlay}
                                        handleToggleThemePanel={this.toggleThemeSelectPanel}
                                        handleToggleStudyEvents={this.toggleStudyEvents}
                                        handleToggleQuickNotes={this.toggleQuickNotes}
                                        handleTogglePrintModal={this.togglePrintModal}
                                        handleToggleMoreOptionsMenu={this.toggleMoreOptionsMenu}
                                    />
                                    {/* More options  | verticle dots button */}
                                    <ul className={`more-options ${showMoreOptions ? 'show' : ''}`} >
                                        <li>
                                            <button
                                                type="button"
                                                title="Print"
                                                className="btn btn-icon"
                                                onClick={this.state.are_current_epoch_packets_loaded ? this.togglePrintModal : undefined}
                                                disabled={!this.state.are_current_epoch_packets_loaded}
                                            >
                                                <i id="print-button" className="icon icon-electrotek-print" />
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                title="Select Theme"
                                                className="btn btn-icon"
                                                onClick={this.toggleThemeSelectPanel}
                                            >
                                                <i className="icon icon-electrotek-color-lens" />
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                title="Toggle time setting"
                                                className="btn btn-icon"
                                                onClick={this.toggleClockSetting}
                                            >
                                                <i className="fa fa-2x fa-fw fa-clock" />
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                type="button"
                                                title="Calibrate screen"
                                                className="btn btn-icon"
                                                onClick={this.openCalibrationModal}
                                            >
                                                <i
                                                    style={{ fontSize: 24, position: 'relative', top: '2px' }}
                                                    className="icon-electrotek-calibrate-mode"
                                                />
                                            </button>
                                        </li>
                                    </ul>
                                </React.Fragment>
                            )
                        }

                        {// Review Overlay
                            showReviewOverlay && (
                                <ReviewOverlay setCanCloseReviewOverlay={cc => this.setCanCloseReviewOverlay(cc)} />
                            )
                        }

                        {// Quick Notes Dropdown
                            showQuickNotes && (
                                <QuickNotesDropdown
                                    isOpen={showQuickNotes}
                                    handleCreateQuickNote={
                                        this.handleCreateQuickNote
                                    }
                                />)
                        }

                        {// Electrograph
                            isMontageSet && (
                                <ElectroGraph
                                    ref={electroGraph => (this.electroGraph = electroGraph)}
                                    options={options}
                                    study={this.props.Study}
                                    socketPath={socket_path}
                                    selectedTheme={userEegTheme}
                                    settings={this.state.settings}
                                    initialSettings={this.props.FacilityDefaultSettings}
                                    dataRate={this.props.Study.StudyDataRate}
                                    events={this.props.Study.StudyEvents}
                                    setAreCurrentEpochPacketsLoaded={this.handleSetAreCurrentEpochPacketsLoaded}
                                    areCurrentEpochPacketsLoaded={this.state.are_current_epoch_packets_loaded}
                                    playSpeed={this.state.play_speed}
                                    handlePlayClick={this.handlePlayClick}
                                    resetPlaybackSpeed={this.resetPlaybackSpeed}
                                    totalSeconds={this.state.total_seconds_in_study}
                                    onRightArrowKey={this.handleNextClick}
                                    onLeftArrowKey={this.handlePrevClick}
                                    onVideoResync={this.handleVideoResync}
                                    onPlay={this.handlePlayEvent}
                                    onPause={this.handlePause}
                                    onUpdateGraphControl={this.handleUpdateGraphControl}
                                    onUpdateGraphControlTotalSeconds={this.handleUpdateGraphControlTotalSeconds}
                                    onNote={() => this.toggleModal('note')}
                                    onUpdateEvent={this.props.handleEditStudyEvent}
                                    onSelectEvent={this.handleSelectEvent}
                                    onEditStudyEvent={this.handleEditStudyEvent}
                                    onDeleteStudyEvent={this.handleDeleteStudyEvent}
                                    onCreateEvent={this.handleNewStudyEvent}
                                    creatingEventOfType={this.state.creating_event_of_type}
                                    creatingEventOnChannel={this.state.creating_event_on_channel}
                                    selectedStudyEvent={this.state.selected_study_event_id}
                                    toggleCreateEventMode={this.toggleCreateEventMode}
                                    isPlaying={this.state.is_playing}
                                    isHidden={this.state.is_review_overlay_open}
                                    isCustomMontageAndFiltersMode={this.props.CustomMontageAndFiltersMode}
                                    isFocusedReview={this.state.is_focused_review}
                                    getNextImportantEpochOffset={this.getNextImportantEpochOffset}
                                    clockSetting={this.state.clock_setting}
                                    pixelsPerCm={this.screenCalibSvc.pixelsPerCm()}
                                />
                            )
                        }

                        {// Study Events
                            showEventsPanel && (
                                <React.Fragment>
                                    <div
                                        onClick={this.toggleStudyEvents}
                                        className={`aside-menu-closer ${minimizedControlsClass}`}
                                        title="Close Study Events"
                                    >
                                        <i className="icon-electrotek-chevron-right" />
                                    </div>
                                    <StudyEventsList
                                        selectedStudyEvent={this.state.selected_study_event_id}
                                        lastSelectedStudyEvent={this.state.last_selected_study_event_id}
                                        handleEventClick={this.handleSelectEvent}
                                        moveGraphToStudyEvent={this.moveGraphToStudyEvent}
                                        handleEventDelete={this.handleDeleteStudyEvent}
                                        handleEventEdit={this.handleEditStudyEvent}
                                        areControlsMinimized={this.context.AreGraphControlsMinimized}
                                        forceEventListFocus={this.state.force_event_list_focus}
                                        resetForceEventListFocus={this.resetForceEventListFocus}
                                        isFocusedReview={this.state.is_focused_review}
                                        toggleFocusedReview={this.toggleFocusedReview}
                                        clockSetting={this.state.clock_setting}
                                    />
                                </React.Fragment>
                            )
                        }

                        {// Theme Selection Panel
                            showThemePanel && (
                                <React.Fragment>
                                    <div
                                        onClick={this.toggleThemeSelectPanel}
                                        className={`aside-menu-closer ${minimizedControlsClass}`}
                                    >
                                        <i className="icon-electrotek-chevron-right" />
                                    </div>
                                    <ThemeSelectPanel
                                        facilityThemes={facility_themes}
                                        selected={SessionManager.userGraphThemeSelection}
                                        handleThemeSelectClick={this.changeUserEegTheme}
                                        areControlsMinimized={this.context.AreGraphControlsMinimized}
                                        selectedTheme={userEegTheme}
                                    />
                                </React.Fragment>
                            )
                        }

                        {// EEG Graph Controls
                            !showReviewOverlay && isMontageSet && (
                                <GraphControl
                                    clockSetting={this.state.clock_setting}
                                    isFocusedReview={this.state.is_focused_review}
                                    toggleFocusedReview={this.toggleFocusedReview}
                                    hasVideo={this.hasVideo()}
                                    toggleVideo={this.toggleVideo}
                                    isPlaying={this.state.is_playing}
                                    currentSecond={this.state.current_second_in_study}
                                    currentMoment={this.state.current_moment_in_study}
                                    totalSeconds={this.state.total_seconds_in_study}
                                    isPrevDisabled={this.state.is_prev_disabled}
                                    isNextDisabled={this.state.is_next_disabled}
                                    isFullscreen={this.state.is_fullscreen}
                                    isFullscreenAvailable={this.state.is_fullscreen_available}
                                    areCurrentEpochPacketsLoaded={this.state.are_current_epoch_packets_loaded}
                                    handlePrevClick={e => this.handlePrevClick(e)}
                                    handleNextClick={e => this.handleNextClick(e)}
                                    handleNextImportantEventClick={this.handleSelectNextImportantEvent}
                                    handlePrevImportantEventClick={this.handleSelectPrevImportantEvent}
                                    handlePlayClick={this.handlePlayClick}
                                    handleToggleFullscreen={this.toggleFullscreen}
                                    handleToggleControls={this.toggleControls}
                                    handleToggleSpeed={() => this.setState({ is_speed_control_open: !this.state.is_speed_control_open })}
                                    handlePlaybackSpeedChange={this.handlePlaybackSpeedChange}
                                    isSpeedOpen={this.state.is_speed_control_open}
                                    playSpeed={this.state.play_speed}
                                    maxPlaySpeed={this.getMaxPlaybackSpeed()}
                                    areControlsMinimized={this.context.AreGraphControlsMinimized}
                                    timebase={this.state.settings.Timebase.Value}
                                    videoMessage={this.state.video_message}
                                />
                            )
                        }

                        {// Video Window
                            showVideoWindow && (
                                <VideoPlayer
                                    isFocusedReview={this.state.is_focused_review}
                                    visible={this.state.play_speed === 0}
                                    calculateSampleIndex={
                                        this.electroGraph
                                            ? this.electroGraph.calculateSampleIndex
                                            : () => 0
                                    }
                                    sampleRate={this.context.Study.StudyDataRate}
                                    studyId={this.context.Study.ID}
                                    events={events}
                                    playCursorIndex={this.state.play_cursor_index_at_last_video_critical_event}
                                    isVisible={isVideoWindowVisible}
                                    isPlaying={this.state.is_playing}
                                    playSpeed={this.state.play_speed}
                                    onVideoResync={this.handleVideoResync}
                                    areControlsMinimized={this.context.AreGraphControlsMinimized}
                                    clockSetting={this.state.clock_setting}
                                    onPlay={this.handlePlayEvent}
                                    onPause={this.handlePause}
                                    videoMessage={this.state.video_message}
                                    onSetVideoMessage={msg => this.setState({ video_message: msg })}
                                />
                            )
                        }
                    </div>
                </React.Fragment>
            </div>
        )
    }
}

export default EEGContainer
