import React, { useEffect, useState, useCallback } from 'react'
import './PatientAuditLogs.scss'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import AuditLogsApi from '../../services/AuditLogsApi'
import Patient from '../../types/Patient'
import Document from '../../types/Document'
import AuditLogItem from '../../types/AuditLogItem'
import LoadingSpinner from '../../components/LoadingSpinner'
import ToastUtils from '../../utils/ToastUtils'
import SortHeader from '../../components/SortHeader'
import SortUtils, { KeysOfTypedValue, SortDir } from '../../utils/SortUtils'
import moment from 'moment'
import AuditLogEntry, { ItemType } from '../../types/AuditLogEntry'
import AuditLogUtils from '../../utils/AuditLogUtils'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
interface Props {
    patient: Patient
}

const PatientAuditLogs: React.FC<Props> = props => {
    const [patientDocumentLogs, setPatientDocumentLogs] = useState<AuditLogItem[] | null>(null)
    const [patientRecordLogs, setPatientRecordLogs] = useState<AuditLogItem[] | null>(null)
    const [sortedBy, setSortedBy] = useState<KeysOfTypedValue<AuditLogItem, string>>('LogDate')
    const [sortedDir, setSortedDir] = useState<SortDir>('desc')
    const patientId = props.patient.ID

    const getDocumentAction = (logItem: any) => {
        switch (logItem.ActionTypeId) {
            case 1:
                return 'Uploaded'
            case 2:
                return 'Downloaded'
            case 3:
                return logItem.ItemAfter.LockerName === null ? 'Unlocked' : 'Locked'
            case 4:
                return 'Deleted'
        }

        return 'Unknown Action'
    }

    const mapPatientDocumentItems = useCallback((logItems: AuditLogEntry<Document | null>[]) => {
        const mappedItems: AuditLogItem[] = []
        for (const item of logItems) {
            const newDocItem: AuditLogItem = {
                ID: item.ID,
                LogDate: item.DateCreated,
                User: AuditLogUtils.formatUserName(item),
                Action: getDocumentAction(item),
                Details: AuditLogUtils.getPatientDocumentName(item),
            }
            mappedItems.push(newDocItem)
        }
        
        SortUtils.date(mappedItems, 'LogDate', 'desc')
        return mappedItems
    }, [])

    useEffect(() => {
        const patientDocumentFilters = { ItemType: ItemType.PatientDocument }

        AuditLogsApi.getAuditLogsForPatient(patientId, patientDocumentFilters).then((res: any) => {
            const mappedLogs: AuditLogItem[] = mapPatientDocumentItems(res.data)
            setPatientDocumentLogs(mappedLogs)
        }).catch((err: any) => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to load patient document audit logs' })
        })

        setPatientRecordLogs([])
    }, [patientId, mapPatientDocumentItems])

    if (!patientDocumentLogs || !patientRecordLogs) {
        return (
            <LoadingSpinner />
        )
    }

    const handleSort = (newSortedBy: KeysOfTypedValue<AuditLogItem, string>) => {
        const patientDocLogs = [...patientDocumentLogs]
        let newSortedDir: SortDir = 'asc'

        if (sortedBy === newSortedBy) {
            newSortedDir = sortedDir === 'desc' ? 'asc' : 'desc'
        }

        switch (newSortedBy) {
            case 'LogDate':
                SortUtils.date(patientDocLogs, newSortedBy, newSortedDir)
                break
            case 'User':
            case 'Action':
            case 'Details':
                SortUtils.string(patientDocLogs, newSortedBy, newSortedDir)
                break
            default:
                throw Error('Unrecognized sort field')
        }

        setSortedBy(newSortedBy)
        setSortedDir(newSortedDir)
        setPatientDocumentLogs(patientDocLogs)
    }

    return (
        <div className="row patient-audit-logs">
            <section className="col-lg-12">
                <Accordion
                    allowMultipleExpanded={true}
                    allowZeroExpanded={true}
                >
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Patient Documents
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <SortHeader
                                                text="Date"
                                                field={'LogDate'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                            <SortHeader
                                                text="User"
                                                field={'User'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                            <SortHeader
                                                text="Action"
                                                field={'Action'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                            <SortHeader
                                                text="Document Name"
                                                field={'Details'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patientDocumentLogs.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5} align="center">
                                                    No document audit logs are available for this patient
                                                </TableCell>
                                            </TableRow>
                                        ) : patientDocumentLogs.map(documentLog => (
                                            <TableRow key={documentLog.ID}>
                                                <TableCell>
                                                    {moment(documentLog.LogDate).format('MMMM D, YYYY h:mm A')}
                                                </TableCell>
                                                <TableCell>
                                                    {documentLog.User}
                                                </TableCell>
                                                <TableCell>
                                                    {documentLog.Action}
                                                </TableCell>
                                                <TableCell>
                                                    {documentLog.Details}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                Patient Record
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <SortHeader
                                                text="Date"
                                                field={'LogDate'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                            <SortHeader
                                                text="User"
                                                field={'User'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                            <SortHeader
                                                text="Action"
                                                field={'Action'}
                                                onSort={handleSort}
                                                by={sortedBy}
                                                dir={sortedDir}
                                            />
                                            <TableCell>Fields Changed</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patientRecordLogs.length === 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={5} align="center">
                                                    Patient record audit logging is not currently implemented
                                                </TableCell>
                                            </TableRow>
                                        ) : (patientRecordLogs.map(documentLog => (
                                            <TableRow key={documentLog.ID}>
                                                <TableCell>
                                                    {moment(documentLog.LogDate).format('MMMM D, YYYY h:mm A')}
                                                </TableCell>
                                                <TableCell>
                                                    {documentLog.User}
                                                </TableCell>
                                                <TableCell>
                                                    {documentLog.Action}
                                                </TableCell>
                                                <TableCell>
                                                    {documentLog.Details}
                                                </TableCell>
                                            </TableRow>
                                        )))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </section>
        </div >
    )
}

export default PatientAuditLogs
