import React, { useState, useEffect } from 'react'
import { Input } from 'reactstrap'

interface Props {
    onSearch: (text: string) => void // Must be memoized
    maxSearchStringLength: number
    searching: boolean
    delay?: number
}

const SearchBar: React.FC<Props> = props => {
    const { onSearch } = props
    const [value, setValue] = useState<string | null>(null)

    const { delay } = props
    useEffect(() => {
        if (value === null) return undefined
        if (value === '') {
            return onSearch(value)
        }
        const timeout = setTimeout(() => onSearch(value), delay ?? 500)
        return () => clearTimeout(timeout)
    }, [value, onSearch, delay])

    return (
        <div className="form-group search-bar-group">
            {props.searching ? (
                <i className="search-icon fas fa-fw fa-spinner fa-spin" />
            ) : (
                <>
                    {value ? (
                        <i
                            title="Clear search"
                            className="search-icon fas fa-fw fa-times"
                            onClick={() => setValue('')}
                        />
                    ) : (
                        <i className="search-icon fas fa-fw fa-search" />
                    )}
                </>
            )}
            <Input
                value={value || ''}
                maxLength={props.maxSearchStringLength}
                onChange={e => setValue(e.target.value)}
                type="search"
                placeholder="Search..."
            />
        </div>
    )
}

export default SearchBar
