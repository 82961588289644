import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import { Drawer } from '@mui/material'
import AppBar from './AppBar'
import MenuLink from './MenuLink'
import drawerHeaderMixin from './drawerHeaderMixin'
import DrawerHeader from './DrawerHeader'
import Content from './Content'
import useDeviceWidth from '../../hooks/useDeviceWidth'
import { Auth } from '../../services/Auth'
import useStyles from './Shell.styles'
import Footer from '../theme/Footer/Footer'
import { Switch, Route } from 'react-router-dom'
import ExpandableMenu from './ExpandableMenu'
import {
    Add as AddIcon,
    Cloud as CloudIcon,
    Devices as DevicesIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'
import { SxProps } from '@mui/system'
import AppContext from '../AppContext'
import RoleBasedContent from '../RoleBasedContent'
import StudyAuth from '../../auth/StudyAuth'
import PatientAuth from '../../auth/PatientAuth'
import FacilityAuth from '../../auth/FacilityAuth'
import UserAuth from '../../auth/UserAuth'
import CameraAuth from '../../auth/CameraAuth'
import AmplifierAuth from '../../auth/AmplifierAuth'
import DeviceAuth from '../../auth/DeviceAuth'
import MontageAuth from '../../auth/MontageAuth'

const DRAWER_DEVICE_WIDTH_CUTOFF = 1000

const materialIconSx: SxProps = {
    fontSize: 20,
    position: 'relative',
    top: -3,
}

function Shell(props: React.PropsWithChildren<{}>) {
    const ctx = useContext(AppContext)
    const theme = useTheme()
    const deviceWidth = useDeviceWidth()
    const [isDrawerOpen, setIsDrawerOpen] = useState(deviceWidth > DRAWER_DEVICE_WIDTH_CUTOFF)
    const classes = useStyles(isDrawerOpen)

    useEffect(() => {
        setIsDrawerOpen(deviceWidth >= DRAWER_DEVICE_WIDTH_CUTOFF)
    }, [deviceWidth])

    const handleNavigate = () => {
        if (!isDrawerOpen) return
        const isLargeDevice = deviceWidth >= DRAWER_DEVICE_WIDTH_CUTOFF
        setIsDrawerOpen(isLargeDevice)
    }

    return (
        <Box sx={{ display: 'flex' }} className="app" // app class needed for fullscreenutilities
        >
            <CssBaseline />
            <Switch>
                <Route exact path="/Study/:id" render={() => !ctx.AreGraphControlsMinimized && <AppBar open={false} />} />
                <Route exact path="/Patient/:id/ImportStudy" render={() => null} />
                <Route exact path="/ImportStudy/CloudSyncPackage" render={() => null} />
                <Route render={() => Auth.loggedIn() && (
                    <AppBar
                        open={isDrawerOpen && Auth.loggedIn()}
                        toggleDrawer={() => setIsDrawerOpen(i => !i)}
                    />
                )} />
            </Switch>
            <Switch>
                <Route exact path="/Study/:id" render={() => null} />
                <Route exact path="/Patient/:id/ImportStudy" render={() => null} />
                <Route exact path="/ImportStudy/CloudSyncPackage" render={() => null} />
                <Route render={() => Auth.loggedIn() && (
                    <Drawer variant="permanent" open={isDrawerOpen} className={classes.drawer}>
                        <DrawerHeader sx={{ ...drawerHeaderMixin(theme) }} />
                        <List disablePadding>
                            <MenuLink
                                isDrawerOpen={isDrawerOpen}
                                onClick={() => setIsDrawerOpen(i => !i)}
                                label={isDrawerOpen ? 'Menu' : ''}
                                rightIcon={<ChevronRightIcon style={{
                                    color: 'rgba(255, 255, 255, 0.4)',
                                    transform: isDrawerOpen ? 'rotate(180deg)' : undefined,
                                    transition: theme.transitions.create('transform'),
                                }} />}
                                buttonStyle={{ background: 'rgba(0, 0, 0, 0.15)', height: '48px' }}
                                labelStyle={{ textTransform: 'uppercase', fontSize: '11px' }}
                            />
                            <MenuLink
                                isDrawerOpen={isDrawerOpen}
                                onClick={handleNavigate}
                                label="Dashboard"
                                path="/Dashboard"
                                leftIcon={<i className="icon-speedometer" />}
                            />
                            <RoleBasedContent required_roles={StudyAuth.List}>
                                <MenuLink
                                    isDrawerOpen={isDrawerOpen}
                                    onClick={handleNavigate}
                                    label="Studies"
                                    path="/Studies"
                                    leftIcon={<i className="icon-notebook" />}
                                />
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={PatientAuth.List}>
                                <MenuLink
                                    isDrawerOpen={isDrawerOpen}
                                    onClick={handleNavigate}
                                    label="Patients"
                                    path="/Patients"
                                    leftIcon={<i className="icon-electrotek-person" />}
                                />
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={PatientAuth.Import}>
                                <ExpandableMenu
                                    label="Import"
                                    icon={<i className="icon-electrotek-import" />}
                                    isDrawerOpen={isDrawerOpen}
                                >
                                    <MenuLink
                                        isDrawerOpen={isDrawerOpen}
                                        onClick={handleNavigate}
                                        label="Trackit+ Study"
                                        path="/ImportStudy/Trackit"
                                        leftIcon={<AddIcon sx={materialIconSx} />}
                                    />
                                    <MenuLink
                                        isDrawerOpen={isDrawerOpen}
                                        onClick={handleNavigate}
                                        label="Cloud Sync Pkg"
                                        path="/ImportStudy/CloudSyncPackage"
                                        leftIcon={<CloudIcon sx={materialIconSx} />}
                                        newTab
                                    />
                                </ExpandableMenu>
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={FacilityAuth.List}>
                                <MenuLink
                                    isDrawerOpen={isDrawerOpen}
                                    onClick={handleNavigate}
                                    label="Facilities"
                                    path="/Facilities"
                                    leftIcon={<i className="icon-grid" />}
                                />
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={UserAuth.List}>
                                <MenuLink
                                    isDrawerOpen={isDrawerOpen}
                                    onClick={handleNavigate}
                                    label="Users"
                                    path="/Users"
                                    leftIcon={<i className="icon-user" />}
                                />
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={[...CameraAuth.List, ...DeviceAuth.List, ...AmplifierAuth.List]}>
                                <ExpandableMenu
                                    label="Hardware"
                                    icon={<i className="icon-electrotek-hardware" />}
                                    isDrawerOpen={isDrawerOpen}
                                >
                                    <RoleBasedContent required_roles={AmplifierAuth.List}>
                                        <MenuLink
                                            isDrawerOpen={isDrawerOpen}
                                            onClick={handleNavigate}
                                            label="Amplifiers"
                                            path="/Amplifiers"
                                            leftIcon={<i className="icon-electrotek-amplifier" />}
                                        />
                                    </RoleBasedContent>
                                    <RoleBasedContent required_roles={CameraAuth.List}>
                                        <MenuLink
                                            isDrawerOpen={isDrawerOpen}
                                            onClick={handleNavigate}
                                            label="Cameras"
                                            path="/Cameras"
                                            leftIcon={<i className="icon-electrotek-videocam" />}
                                        />
                                    </RoleBasedContent>
                                    <RoleBasedContent required_roles={DeviceAuth.List}>
                                        <MenuLink
                                            isDrawerOpen={isDrawerOpen}
                                            onClick={handleNavigate}
                                            label="Devices"
                                            path="/Devices"
                                            leftIcon={<DevicesIcon sx={materialIconSx} />}
                                        />
                                    </RoleBasedContent>
                                </ExpandableMenu>
                            </RoleBasedContent>
                            <RoleBasedContent required_roles={MontageAuth.List}>
                                <MenuLink
                                    isDrawerOpen={isDrawerOpen}
                                    onClick={handleNavigate}
                                    label="Montages"
                                    path="/Montages"
                                    leftIcon={<i className="icon-electrotek-montage" />}
                                />
                            </RoleBasedContent>
                            <MenuLink
                                isDrawerOpen={isDrawerOpen}
                                onClick={handleNavigate}
                                label="Help"
                                path="/Help"
                                leftIcon={<i className="icon-question" />}
                            />
                        </List>
                    </Drawer>
                )} />
            </Switch>
            <Content>
                {props.children}
            </Content>
            <Switch>
                <Route exact path="/Study/:id" render={() => null} />
                <Route render={() => <Footer />} />
            </Switch>
        </Box>
    )
}

export default Shell
