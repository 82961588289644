import React, { CSSProperties } from 'react'
import { KeysOfTypedValue, SortDir } from '../utils/SortUtils'
import TableCell from '@mui/material/TableCell'

interface Props<T, V> {
    class?: string
    style?: CSSProperties
    field: KeysOfTypedValue<T, V>
    onSort: (field: KeysOfTypedValue<T, V>) => void
    by: keyof T
    text: string
    dir: SortDir
}
const SortHeader = <T, V>(props: Props<T, V>) => (
    <TableCell
        className={`${props.class ?? ''}`}
        style={{ cursor: 'pointer', userSelect: 'none', ...props.style }}
        onClick={() => props.onSort(props.field)}
    >
        {props.text}
        {props.by === props.field && (
            <i
                className={
                    props.dir === 'asc'
                        ? 'fa fa-sort-up'
                        : 'fa fa-sort-down'
                }
            />
        )}
    </TableCell>
)

export default SortHeader
