import React, { useState } from 'react'
import './Scrubber.scss'
import AppContext from '../../../../components/AppContext'
import ScrubberEpoch from './ScrubberEpoch'
import ScrubberRequestedData from './ScrubberRequestedData'
import ScrubberLoadedData from './ScrubberLoadedData'
import ScrubberEvents from './ScrubberEvents'
import LoadedSegment from './LoadedSegment'
import ClockSetting from '../../../../types/ClockSetting'
import GraphUtilities from '../../utils/GraphUtilities'
import DateTimeUtils from '../../../../utils/DateTimeUtils'

const FOLLOW_BTN_WIDTH = 35

interface Props {
    totalPacketCount: number
    samplesPerWindow: number
    sampleOffset: number
    scrubberHeight: number
    minutesRequestedIndices: number[]
    packetsSegmentsLoaded: LoadedSegment[]
    attachedToHead: boolean
    clockSetting: ClockSetting
    totalSeconds: number
    calculateSampleIndex: (
        recordingIndex: number,
        packetIndex: number,
    ) => number
    onAttachToHead: () => void
    onPause: () => void
    onDetachFromHead: () => void
    setSampleOffset: (epochOffset: number) => Promise<void>
    setPlayCursor: (index: number) => void
    drawPlayCursor: () => void
    onVideoResync: (epochOffset: number) => void
}

const Scrubber: React.FC<Props> = props => {
    const ctx = React.useContext(AppContext)
    const [isTimeVisible, setIsTimeVisible] = useState(false)
    const [touchX, setTouchX] = useState(0)

    const [position, setPosition] = useState({
        time: GraphUtilities.getMomentByPacketIndex(ctx, 1),
        index: 1,
    })

    const scrubberWidth = window.innerWidth - FOLLOW_BTN_WIDTH

    const apparentPacketCount = Math.max(
        props.totalPacketCount,
        120 * ctx.Study.StudyDataRate,
    )

    const handleClick = async (x: number) => {
        const clickIndex = Math.floor(((apparentPacketCount * x) / scrubberWidth) / ctx.Study.StudyDataRate) * ctx.Study.StudyDataRate
        if (clickIndex > props.totalPacketCount) {
            return
        }
        const epochOffset =
            Math.floor(clickIndex / props.samplesPerWindow) *
            props.samplesPerWindow
        props.onPause()
        props.onDetachFromHead()
        await props.setSampleOffset(epochOffset)
        props.setPlayCursor(clickIndex)
        props.drawPlayCursor()
        props.onVideoResync(clickIndex)
        setIsTimeVisible(false)
    }

    const setTimeDisplay = (x: number) => {
        const positionPercent = x / scrubberWidth
        const index = positionPercent * apparentPacketCount
        if (index > props.totalPacketCount) {
            setIsTimeVisible(false)
            return
        }
        const time = GraphUtilities.getMomentByPacketIndex(ctx, Math.round(index))
        setPosition({ time, index })
        setIsTimeVisible(true)
        setTouchX(x)
    }

    let timeDisplayWidth = 220
    let timeDisplay = ''
    switch (props.clockSetting) {
        case ClockSetting.TwelveHour:
            timeDisplay = position.time.format('h:mm:ss A | MMMM D, YYYY')
            break
        case ClockSetting.TwentyFourHour:
            timeDisplay = position.time.format('HH:mm:ss | MMMM D, YYYY')
            break
        default:
            timeDisplay = DateTimeUtils.getStudyAltDuration(props.totalSeconds * position.index / props.totalPacketCount)
            timeDisplayWidth = 100
    }

    const isTimeDisplayOnRight = position.index / apparentPacketCount < 0.5
    let timeDisplayPositionLeft = (position.index / apparentPacketCount) * scrubberWidth
    if (!isTimeDisplayOnRight) {
        timeDisplayPositionLeft -= timeDisplayWidth
    }

    return (
        <div
            className="scrubber-wrapper"
            style={{ height: `${props.scrubberHeight}px` }}
        >
            <div
                className="scrubber"
                style={{ right: FOLLOW_BTN_WIDTH }}
                onClick={e => handleClick(e.clientX)}
                onMouseLeave={() => setIsTimeVisible(false)}
                onMouseMove={e => setTimeDisplay(e.clientX)}
                onTouchMove={e => setTimeDisplay(e.touches[0].clientX)}
                onTouchEnd={() => {
                    setIsTimeVisible(false)
                    handleClick(touchX)
                }}
                onTouchStart={e => setTimeDisplay(e.touches[0].clientX)}
            >
                <ScrubberRequestedData
                    minutesRequestedIndices={props.minutesRequestedIndices}
                    packetCount={apparentPacketCount}
                />
                <ScrubberLoadedData
                    packetsSegmentsLoaded={props.packetsSegmentsLoaded}
                    packetCount={apparentPacketCount}
                />
                <ScrubberEpoch
                    sampleOffset={props.sampleOffset}
                    samplesPerWindow={props.samplesPerWindow}
                    packetCount={apparentPacketCount}
                />
                <ScrubberEvents
                    calculateSampleIndex={props.calculateSampleIndex}
                    packetCount={apparentPacketCount}
                />
                <div
                    className={`
                        section
                        time-display
                        ${isTimeDisplayOnRight ? 'right' : 'left'}
                        ${isTimeVisible ? '' : 'hidden'}
                    `}
                    style={{
                        left: `${timeDisplayPositionLeft}px`,
                        width: `${timeDisplayWidth}px`,
                    }}
                >
                    <span className="time">{timeDisplay}</span>
                </div>
            </div>
            <div
                title="Follow live data"
                className={`icon icon-electrotek-control-forward follow-live-data ${
                    props.attachedToHead ? 'disabled' : ''
                }`}
                style={{ width: `${FOLLOW_BTN_WIDTH}px` }}
                onClick={() => {
                    if (!props.attachedToHead) {
                        props.onAttachToHead()
                    }
                }}
            />
        </div>
    )
}

export default Scrubber
