import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
} from '../constants/roles'

const StudyEventAuth = {
    ViewHighCPU: [SUPER_ADMIN_ROLE, SUPPORT_ROLE],
}

export default StudyEventAuth
