import axios from 'axios'
import MMTApi from './MMTApi'

const PatientsApi = {
    search(searchString, source) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/Patients/Search/?searchString=${searchString}`,
            {
                ...MMTApi.getRequestHeaders(),
                cancelToken: source?.token,
            }
        )
    },

    getPatients(studyIds) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Patients/GetPatientByStudyIds`,
            studyIds,
            MMTApi.getRequestHeaders(),
        )
    },

    getStudies(patientId, includeDeleted, includeZeroDuration) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Studies/GetStudiesForPatient/?patientId=${patientId}&includeDeleted=${includeDeleted}&includeZeroDuration=${includeZeroDuration}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getPatient(guid) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Patients/${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    doesPatientWithPatientIdExist(patientId) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Patients/DoesPatientWithPatientIdExist/?patientId=${patientId}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getPatientForEditing(guid) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/Patients/GetPatientForEditing/?id=${guid}`,
            MMTApi.getRequestHeaders(),
        )
    },

    updatePatient(guid, data) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/Patients/UpdatePatient/?id=${guid}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    createPatient(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Patients/CreatePatient`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    getDocuments(patientId, source) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Patients/GetDocuments/?patientId=${patientId}`,
            {
                ...MMTApi.getRequestHeaders(),
                cancelToken: source?.token,
            }
        )
    },

    deleteDocument(patientId, name) {
        return axios.delete(
            `${process.env.REACT_APP_API_URL}/api/Patients/DeleteDocument/?patientId=${patientId}&documentReference=${name}`,
            MMTApi.getRequestHeaders(),
        )
    },

    setDocumentLock(patientId, name, isLocked) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/Patients/SetDocumentLock/?patientId=${patientId}&documentReference=${name}&isLocked=${isLocked}`,
            {},
            MMTApi.getRequestHeaders(),
        )
    },

    getDocumentUploadCreds(patientId) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Patients/GetDocumentUploadCreds/?patientId=${patientId}`,
            MMTApi.getRequestHeaders(),
        )
    },

    getDocumentDownloadUrl(patientId, name) {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Patients/GetDocumentDownloadURL/?patientId=${patientId}&documentReference=${name}`,
            MMTApi.getRequestHeaders(),
        )
    },

    logDocumentCreated(patientId, documentReference, fileName) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Patients/LogDocumentCreation/?patientId=${patientId}&documentReference=${documentReference}&fileName=${fileName}`,
            {},
            MMTApi.getRequestHeaders(),
        )
    },
}

export default PatientsApi
