import React from 'react'
import MetricBlock from '../../components/charts-graphs/MetricBlock'
import DashboardApi from '../../services/DashboardApi'
import SessionManager from '../../services/SessionManager'
import FailedToLoad from '../../components/FailedToLoad'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Grid } from '@mui/material'

const COLOR_CLASS_NAMES = ['blue', 'green', 'orange', 'darkblue']

class MetricBlockSignalR extends React.Component {
    state = {
        metrics: [],
        did_get_metrics_fail: false,
        is_loading: true,
        current_facility: SessionManager.get('mmt_facility_id'),
    }

    componentDidMount() {
        this.getMetrics()
    }

    getMetrics = () => {
        this.setState({
            did_get_metrics_fail: false,
            is_loading: true,
        })

        DashboardApi.getFacilityMetrics(this.state.current_facility)
            .then(response => {
                this.setMetrics(response.data)
            })
            .catch(() => {
                this.setState({
                    did_get_metrics_fail: true,
                    is_loading: false,
                })
            })
    }

    setMetrics(metrics) {
        const list = [metrics[1], metrics[0], metrics[2]]

        metrics = this.setMetricsValuesToIntegers(list)

        this.setState({
            metrics,
            is_loading: false,
        })
    }

    setMetricsValuesToIntegers(metrics) {
        metrics.forEach(metric => {
            metric.value = parseInt(metric.value, 10)
        })
        return metrics
    }

    render() {
        if (this.state.is_loading) {
            return <LoadingSpinner />
        }

        if (this.state.did_get_metrics_fail) {
            return <FailedToLoad onRetry={this.getMetrics} items="metrics" />
        }

        const { metrics } = this.state
        return (
            <Grid
                container
                spacing={2}
                className="animated fadeIn"
                sx={{ mb: 2 }}
            >
                {metrics.map((metric, index) => (
                    <Grid key={index} item xs xl={3} sx={{ minWidth: 230, maxWidth: 300 }}>
                        <MetricBlock
                            colorClassName={COLOR_CLASS_NAMES[index]}
                            {...metric}
                        />
                    </Grid>
                ))}
            </Grid>
        )
    }
}

export default MetricBlockSignalR
