import React from 'react'
import './ElectroGraph.scss'
import LoadingSpinner from '../../../components/LoadingSpinner'
import GraphDataManager from './GraphDataManager'
import { getRGBAFromHexARGB } from '../utils/ColorUtilities'
import { getChannelColor } from '../utils/ChannelUtilities'
import GraphUtilities from '../utils/GraphUtilities'
import * as ED from '../../../constants/epochdirection'
import GraphChannelMenu from './GraphChannelMenu'
import {
    CHANNEL_TYPE,
    EEG,
    EM,
    NON_EEG_CHANNEL_TYPES,
} from './constants'
import StorageManager from '../../../services/StorageManager'
import EventMarkers from './Events/EventMarkers'
import ChannelLabels from './Graph/ChannelLabels'
import Scrubber from './Scrubber'
import NonEEGOverlay from './Graph/NonEEGOverlay/NonEEGOverlay'
import GridLines from './Graph/GridLines'
import AppContext from '../../../components/AppContext'
import {
    SETTING_HIGH_FILTER,
    SETTING_LOW_FILTER,
    SETTING_SENSITIVITY,
    CHANNEL_SETTINGS,
} from '../../../constants/graphsettings'
import { VIDEO_START, MEASUREMENT } from '../../../constants/studyevents'
import Legend from './Graph/Legend'
import Diagnostics from './Diagnostics'
import RoleBasedContent from '../../../components/RoleBasedContent'
import StudyAuth from '../../../auth/StudyAuth'

class ElectroGraph extends React.Component {
    // #region Class setup

    static contextType = AppContext

    constructor(props) {
        super(props)

        this.state = {
            samples_per_window: Math.max(props.study.StudyDataRate, 1) * props.settings.Timebase.Value,
            is_channel_menu_showing: false,
            is_non_eeg_overlay_showing: false,
            selected_channel: {
                id: 0,
                top: 0,
                label: '',
                mid: 0,
                type: 0,
            },
            sample_offset: 0,
            channel_type_settings: [],
            midpoints: [],
            channel_count: 0,
            attached_to_head: false,
            are_diagnostics_on: false,
        }

        this.devicePixelRatio = (window.devicePixelRatio || 1)

        this.canvasGraph = null
        this.canvasMouse = null

        this.ctx = null
        this.ctxMouse = null

        this.packets = []
        this.isComponentMounted = true
        this.packetsSegmentsLoaded = []
        this.minutesRequestedIndices = []
        this.packetBufferSize = 500
        this.packetsToDrawRemainder = 0.0
        this.resizeMinInterval = 200 // ms
        this.lastResizeTime = 0
        this.headIndex = 0 // Index of this.packets for drawing
        this.playCursor = { index: 0, indexFloat: 0, x: 0, width: 1 }
        this.previousEpochSampleOffset = 0
        this.currentEpochMovementDirection = ED.EPOCH_DIRECTION_NONE
        this.lastDrawTime = null
        this.isResized = false
        this.isBuffered = false
        this.customMontage = null
        this.gutter = {
            top: 0,
            bottom: 20,
            left: 80,
            right: 40,
        }
        this.scrubberHeight = 30
        this.minPacketIndexForVideoStart = 0

        this.isResizeNeeded = false
        this.isEpochDrawNeeded = false
        this.shouldAutoPlay = false // If paused for lack of data, this should be true

        this.customMontageAndFiltersMode = this.props.customMontageAndFiltersMode

        // TODO: Change this when both the original and custom montages are passed in.
        this.setCustomMontage(this.props.settings.Montage)

        this.width = null
        this.height = null
        this.graphWidth = null // This doesn't include the gutters on either side.
        this.graphHeight = null

        this.channels = []

        this.customMontageAndFiltersMode = true

        this.setTheme(this.props.selectedTheme)
    }

    // #endregion

    // #region Life Cycle Methods

    UNSAFE_componentWillMount() {
        this.createStudyRecordingPacketCounts(this.context.Study)
    }

    componentDidMount() {
        // setup
        this.setCanvasElements()
        this.setResizeSensor()

        this.setDefaultChannelSettings()

        // Start the render loop.
        this.rendrLoop()
        this.pagingLoop()

        // add canvas input event listeners
        this.addEventListeners()

        const initialPlayCursorMs = this.getInitialPlayCursorMilliseconds()
        window.history.replaceState(
            {},
            null,
            window.location.toString().split('?')[0],
        )
        if (initialPlayCursorMs === null) {
            if (this.getStoredIsAttachedToHead()) {
                this.toEnd()
                this.attachToHead()
            } else {
                // navigate to the end of the study if the state is 'InProgress'
                // TODO: Need a better indicator, this doesn't cover data only live streaming
                const savedSampleOffset = this.getStoredSampleOffset()
                if (savedSampleOffset !== null) {
                    this.setSampleOffset(savedSampleOffset)
                    this.props.onVideoResync(savedSampleOffset)
                    this.detachFromHead()
                } else {
                    this.setSampleOffset(0)
                    this.detachFromHead()
                }
            }
        } else {
            const initialIndex =
                (initialPlayCursorMs * this.props.study.StudyDataRate) / 1000
            const sampleOffset = Math.min(
                this.getEpochSampleOffset(initialIndex),
                this.getSampleOffsetForEndEpoch(),
            )
            this.setSampleOffset(sampleOffset)
            this.props.onVideoResync(sampleOffset)
            this.detachFromHead()
        }

        this.graphDataManager = new GraphDataManager(
            this.context.Study,
            this.props.socketPath,
            this,
            this.props.settings.Montage,
            this.getInitialSettings(),
        )

        // Rerender the scrubber if the requests are cleared
        this.graphDataManager.setNotifyClearRequestQueue(() => {
            this.minutesRequestedIndices.length = 0
            this.graphDataManager.cacheManager.clean()
            this.forceUpdate()
        })
    }

    componentDidUpdate() {
        if (this.props.playSpeed > 0) {
            this.ctxMouse.clearRect(0, 0, this.graphWidth * this.devicePixelRatio, this.graphHeight * this.devicePixelRatio)
        }
        const prevTotalPktCnt = this.studyRecordingPacketCounts.reduce((a, b) => a + b, 0)
        this.createStudyRecordingPacketCounts(this.context.Study)
        const postTotalPktCnt = this.studyRecordingPacketCounts.reduce((a, b) => a + b, 0)
        if (postTotalPktCnt > prevTotalPktCnt) {
            if (this.state.attached_to_head) {
                this.toEnd()
            }
            // if more data is available for the graph,
            // retrieve that data if near play cursor
            this.graphDataManager.epochChanged()
        }
    }

    /**
     * This will apply the user's filter settings (stored in window.sessionStorage)
     * to the default settings from the API. This should only be called in componentDidMount().
     */
    getInitialSettings = () => {
        // "clone" the initial settings so we don't mutate props
        const initialSettings = JSON.parse(
            JSON.stringify(this.props.initialSettings.DefaultFilterSettings),
        )
        initialSettings.NotchFilterFrequency =
            parseFloat(this.props.settings.NotchFilter.Value) || 0
        initialSettings.NotchOn =
            this.props.settings.NotchFilter.Value !== 'Off'

        for (const setting of initialSettings.HighLowFrequencyFilterValues) {
            if (setting.ChannelTypeId === EEG) {
                setting.HighFrequencyFilterValue = parseFloat(
                    this.props.settings.HighFilter.Value,
                )
                setting.LowFrequencyFilterTimeConstant = parseFloat(
                    this.props.settings.LowFilter.Value,
                )
            }
        }

        return initialSettings
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.playSpeed !== nextProps.playSpeed ||
            this.props.clockSetting !== nextProps.clockSetting ||
            this.state.sample_offset !== nextState.sample_offset ||
            this.props.isHidden !== nextProps.isHidden ||
            this.props.selectedTheme.ID !== nextProps.selectedTheme.ID ||
            this.state.samples_per_window !== nextState.samples_per_window ||
            this.props.events !== nextProps.events ||
            this.props.creatingEventOfType !== nextProps.creatingEventOfType ||
            this.props.selectedStudyEvent !== nextProps.selectedStudyEvent ||
            this.props.areCurrentEpochPacketsLoaded !== nextProps.areCurrentEpochPacketsLoaded ||
            this.state.attached_to_head !== nextState.attached_to_head ||
            this.state.are_diagnostics_on !== nextState.are_diagnostics_on
        )
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            this.props.settings.Timebase.Value !==
            nextProps.settings.Timebase.Value ||
            this.props.montage !== nextProps.montage ||
            this.props.events !== nextProps.events
        ) {
            this.isResizeNeeded = true
            this.isEpochDrawNeeded = true
        }

        if (
            this.props.settings.Sensitivity.Value !==
            nextProps.settings.Sensitivity.Value
        ) {
            this.handleSettingChange(
                EEG,
                SETTING_SENSITIVITY,
                nextProps.settings.Sensitivity.Value,
            )
        }
        if (this.props.settings.Montage !== nextProps.settings.Montage) {
            delete this.customMontage
        }
    }

    componentWillUnmount() {
        this.isComponentMounted = false
        this.resizeSensor.detach()
        cancelAnimationFrame(this.animationLoopID)
        window.removeEventListener('keyup', this.handleKeyUp)
        window.removeEventListener(
            'visibilitychange',
            this.visibilityChangeHandler,
        )
        if (this.graphDataManager) this.graphDataManager.close()
    }

    // #endregion

    // #region Initialization

    setCanvasElements() {
        this.ctx = this.canvasGraph.getContext('2d')
        this.ctxMouse = this.canvasMouse.getContext('2d')
    }

    setTheme(theme) {
        for (const key of Object.keys(theme)) {
            if (theme[key] === null) theme[key] = '#AFFF33'
        }
        this.theme = theme
        this.isResizeNeeded = true // trigger graph redraw
    }

    setResizeSensor() {
        const ResizeSensor = require('css-element-queries/src/ResizeSensor')
        this.resizeSensor = new ResizeSensor(this.elem, () => {
            this.isResizeNeeded = true
        })
    }

    addEventListeners() {
        window.addEventListener('keyup', this.handleKeyUp)
        this.canvasMouse.addEventListener('wheel', this.handleMouseWheel)
        window.addEventListener(
            'visibilitychange',
            this.visibilityChangeHandler,
        )
    }

    // #endregion

    // #region Event Listeners

    visibilityChangeHandler = () => {
        if (this.props.isPlaying && document.hidden) {
            this.props.onPause()
            this.videoPausedFlag = true
        } else if (!this.props.isPlaying && this.videoPausedFlag) {
            this.props.onPlay()
            this.videoPausedFlag = false
        }
    }

    setDefaultChannelSettings = () => {
        const storedSettings = this.getStoredSetting(CHANNEL_SETTINGS)

        if (!!storedSettings) {
            this.setState(() => ({ channel_type_settings: storedSettings }))
            return
        }

        const channel_type_settings = []

        NON_EEG_CHANNEL_TYPES.forEach(type => {
            const default_sensitivity = this.props.initialSettings.DefaultSensitivities.Sensitivities.find(
                sensitivity => sensitivity.ChannelTypeID === type,
            )
            const channel_filter = this.props.initialSettings.DefaultFilterSettings.HighLowFrequencyFilterValues.find(
                channel => channel.ChannelTypeId === type,
            )


            channel_type_settings.push({
                channel_type_id: type,
                Sensitivity: default_sensitivity.SenstivityValue,
                HighFilter: channel_filter.HighFrequencyFilterValue,
                LowFilter: channel_filter.LowFrequencyFilterTimeConstant,
            })

        })

        channel_type_settings.push({
            channel_type_id: EEG,
            Sensitivity: this.props.settings.Sensitivity.Value,
            HighFilter: this.props.settings.HighFilter.Value,
            LowFilter: this.props.settings.LowFilter.Value,
        })

        this.setState(() => ({
            channel_type_settings,
        }))
    }

    // #endregion

    // #region UI Handlers - Buttons

    toStart() {
        const newSampleOffset = 0
        if (this.state.sample_offset === newSampleOffset) {
            return
        }

        this.setSampleOffset(newSampleOffset)
        this.detachFromHead()
        this.isEpochDrawNeeded = true
    }

    toEnd = () => {
        this.headIndex = Math.max(0, this.getTotalPacketCountForStudy())
        const newSampleOffset = this.getSampleOffsetForEndEpoch()
        if (this.state.sample_offset !== newSampleOffset) {
            this.props.isPlaying && this.props.onPause()
            this.setSampleOffset(newSampleOffset)
            this.props.onVideoResync(newSampleOffset)
        }

        this.attachToHead()
        this.isEpochDrawNeeded = true
        this.updateGraphControl(newSampleOffset)
    }

    toPrev() {
        if (this.playCursor.index > this.state.sample_offset + 1) {
            this.setPlayCursorByIndex(this.state.sample_offset + 1)
            this.props.onVideoResync(this.state.sample_offset + 1)
            this.drawPlayCursor()
        } else {
            const newSampleOffset = this.getEpochSampleOffset(
                this.state.sample_offset - 1,
            )
            if (this.props.isPlaying) {
                this.props.onVideoResync()
            }
            this.setSampleOffset(newSampleOffset)
            if (!this.props.isPlaying) {
                this.props.onVideoResync(newSampleOffset)
            }
            this.detachFromHead()
            this.isEpochDrawNeeded = true
            this.updateGraphControl(newSampleOffset)
        }
    }

    toPrevMajorTick() {
        const intervalSeconds = GraphUtilities.getMajorTickSecondsInterval(
            this.props.settings.Timebase.Value,
        )
        const newSampleOffset =
            this.state.sample_offset -
            intervalSeconds * this.props.study.StudyDataRate
        if (newSampleOffset < 0) return
        this.setSampleOffset(newSampleOffset)
        this.props.onVideoResync(newSampleOffset)
        this.detachFromHead()
        this.isEpochDrawNeeded = true
    }

    // returns Promise<boolean>: true if the epoch changed, false otherwise
    toNext = async (isPaging = false) => {
        const maxOffset = this.getSampleOffsetForEndEpoch()
        const nextOffset =
            this.state.samples_per_window *
            (1 +
                Math.floor(
                    this.state.sample_offset /
                    this.state.samples_per_window,
                ))
        const newSampleOffset = Math.min(maxOffset, nextOffset)
        if (this.state.sample_offset === newSampleOffset) {
            const totalPacketCount = this.getTotalPacketCountForStudy()
            this.setPlayCursorByIndex(totalPacketCount)
            if (!isPaging) {
                this.props.onVideoResync(totalPacketCount)
                this.drawPlayCursor()
            }
            return false
        } else {
            this.setPlayCursorByIndex(nextOffset + 1)
        }
        await this.setSampleOffset(newSampleOffset)
        await this.updateGraphControl(newSampleOffset)
        if (!isPaging) {
            this.props.onVideoResync(newSampleOffset)
            this.isEpochDrawNeeded = true
        }
        return true
    }

    toNextMajorTick() {
        const intervalSeconds = GraphUtilities.getMajorTickSecondsInterval(
            this.props.settings.Timebase.Value,
        )
        const newSampleOffset =
            this.state.sample_offset +
            intervalSeconds * this.props.study.StudyDataRate
        if (newSampleOffset > this.getSampleOffsetForEndEpoch()) return
        this.setSampleOffset(newSampleOffset)
        this.props.onVideoResync(newSampleOffset)
        this.isEpochDrawNeeded = true
    }

    setMontageTemplate(selection) {
        this.customMontageAndFiltersMode = true

        this.props.isPlaying && this.props.onPause()

        this.graphDataManager.setSelectedMontageTemplateId(selection.ID)
    }

    setHighFilter(channel_type = EEG, selection) {
        this.customMontageAndFiltersMode = true
        this.graphDataManager.setHighFilter(channel_type, selection)
    }

    setLowFilter(channel_type = EEG, selection) {
        this.customMontageAndFiltersMode = true
        this.graphDataManager.setLowFilter(channel_type, selection)
    }

    setNotchFilter(selection, is_notch_on = true) {
        this.customMontageAndFiltersMode = true
        this.graphDataManager.setNotchFilter(selection, is_notch_on)
    }

    updateChannelsForCurrentMontage() {
        const selectedMontage = this.getCurrentMontage()
        const thigh_gap = 15

        let groupCount = 0
        // Calculate the number of groups to put gaps in between
        for (let i = 1; i < selectedMontage.Channels.length; i += 1) {
            if (
                selectedMontage.Channels[i].Group !==
                selectedMontage.Channels[i - 1].Group
            ) {
                // Factor in the number of group markers between two groups
                const grpDelta = selectedMontage.Channels[i].Group -  selectedMontage.Channels[i - 1].Group
                groupCount +=  grpDelta > 0 ? grpDelta : 1
            }
        }

        // Add a gap between the EM and the other channels
        groupCount += 1

        const channel_count = ((selectedMontage && selectedMontage.Channels.length) || 0) + 1 // the extra channel is the event marker
        const channel_height = (this.graphHeight - groupCount * thigh_gap) / (channel_count+1)
        this.channels.length = channel_count

        let thighGaps = 0

        const midpoints = []

        for (
            let channel_index = 0;
            channel_index < channel_count;
            channel_index += 1
        ) {
            if (!this.channels[channel_index]) {
                this.channels[channel_index] = { mid: 0, enabled: true }
            }

            if (channel_index > 0) {
                if (
                    selectedMontage.Channels[channel_index] &&
                    selectedMontage.Channels[channel_index].Group !==
                    selectedMontage.Channels[channel_index - 1].Group
                ) {
                    const grpDelta = selectedMontage.Channels[channel_index].Group -  selectedMontage.Channels[channel_index - 1].Group
                    const gapx = grpDelta > 0 ?  thigh_gap * grpDelta  : thigh_gap
                    thighGaps += gapx
                }
            }

            // Calculate the origin (midpoint) for each channel
            const midpoint = parseInt(channel_index * channel_height + channel_height * 1.5 + thighGaps)

            this.channels[channel_index].mid = midpoint

            midpoints.push({
                channel_index,
                midpoint,
            })
        }

        this.setState(
            () => ({
                midpoints,
                channel_count,
            }),
            () => {
                this.forceUpdate()
                this.isEpochDrawNeeded = true
            },
        )
    }

    handleMouseClick = e => {
        this.state.is_channel_menu_showing && this.hideChannelMenu()

        const packet = this.getPacketFromX(e.clientX - this.gutter.left)
        if (packet) {
            const studyPacketIndex = this.calculateSampleIndex(
                packet.RecordingIndex,
                packet.Index,
            )
            this.setPlayCursorByIndex(studyPacketIndex)
            this.drawPlayCursor()
            this.props.onVideoResync(studyPacketIndex)
        }
    }

    handleMouseWheel = e => {
        this.props.isPlaying && this.props.onPause()
        if (e.shiftKey) {
            if (e.deltaY > 0) {
                this.toNextMajorTick()
            } else {
                this.toPrevMajorTick()
            }
            return
        }
        if (e.deltaY > 0) {
            this.toNext()
        } else {
            this.toPrev()
        }
    }

    handleToggleHideChannel = channel => {
        this.setState(
            {
                is_channel_menu_showing: false,
            },
            () => {
                this.channels[channel.id].enabled = !this.channels[channel.id]
                    .enabled
                this.forceUpdate()
                this.isEpochDrawNeeded = true
            },
        )
    }

    handleToggleCreateEventMode = (id, channel) => {
        this.setState({
            is_channel_menu_showing: false,
        })
        this.isEpochDrawNeeded = true
        this.props.toggleCreateEventMode(id, channel)
    }

    handleShowNonEEGOverlay = () => {
        this.setState(
            {
                is_channel_menu_showing: false,
                is_non_eeg_overlay_showing: true,
            },
            () => {
                this.isEpochDrawNeeded = true
                this.forceUpdate()
            },
        )
    }

    toggleNonEEGOverlay = () => {
        this.setState(
            {
                is_non_eeg_overlay_showing: false,
            },
            () => {
                this.forceUpdate()
            },
        )
    }

    handleSettingChange = (channel_type, setting_type, value) => {
        if (setting_type === SETTING_HIGH_FILTER) {
            this.props.setAreCurrentEpochPacketsLoaded(false)
            this.graphDataManager.setHighFilter(channel_type, value)
        }

        if (setting_type === SETTING_LOW_FILTER) {
            this.props.setAreCurrentEpochPacketsLoaded(false)
            this.graphDataManager.setLowFilter(channel_type, value)
        }

        const channel_type_settings = this.state.channel_type_settings
        const setting_index = channel_type_settings.findIndex(
            x => x.channel_type_id === channel_type,
        )

        channel_type_settings[setting_index] = {
            ...channel_type_settings[setting_index],
            [setting_type]: value,
        }

        this.setState(
            {
                channel_type_settings,
            },
            () => {
                this.storeSetting(CHANNEL_SETTINGS, channel_type_settings)
                this.forceUpdate()
                this.isResizeNeeded = true
                this.isEpochDrawNeeded = true
            },
        )
    }

    handleChannelClick = channel_index => {
        const channel_type = this.getChannelType(channel_index)

        if (channel_type === EEG) {
            this.setState(
                {
                    is_non_eeg_overlay_showing: false,
                },
                () => {
                    this.showChannelMenu(channel_index)
                },
            )
        } else {
            this.showChannelMenu(channel_index)
        }
    }

    showChannelMenu(channel_index) {
        // If already creating measurement, cancel
        if (this.props.creatingEventOfType === MEASUREMENT) {
            this.props.toggleCreateEventMode(MEASUREMENT)
        }

        // If the menu is already visible for this channel, hide it
        if (
            this.state.is_channel_menu_showing &&
            this.state.selected_channel.id === channel_index
        ) {
            this.hideChannelMenu()
            this.isEpochDrawNeeded = true
            return
        }

        const top = parseInt(this.channels[channel_index].mid - 25, 10)
        const label = this.getChannelLabel(channel_index)
        const type = this.getChannelType(channel_index)

        const selected_channel = {
            id: channel_index,
            ...this.channels[channel_index],
            top,
            label,
            type,
        }

        this.setState(
            {
                selected_channel,
                is_channel_menu_showing: true,
            },
            this.forceUpdate,
        )
        this.isEpochDrawNeeded = true
    }

    hideChannelMenu() {
        this.setState(
            {
                is_channel_menu_showing: false,
            },
            this.forceUpdate,
        )
        this.isEpochDrawNeeded = true
    }

    // #endregion

    // #region UI Handlers - Keyboard
    handleKeyUp = e => {
        switch (e.keyCode) {
            case 36: // Home
                this.toStart()
                break
            case 35: // End
                this.toEnd()
                break
            case 192: // ~ 
                if (e.altKey) {
                    this.setState({ are_diagnostics_on: !this.state.are_diagnostics_on })
                }
                break
            default:
                break
        }
    }

    // #endregion

    // #region Data Buffer Management

    updateBuffer() {
        if (this.isBuffered) return

        if (this.packets.length >= this.packetBufferSize) {
            this.isBuffered = true
        }
    }

    updateRecordingPacketCounts(packet) {
        if (
            this.studyRecordingPacketCounts[packet.RecordingIndex] === undefined
        ) {
            this.studyRecordingPacketCounts[packet.RecordingIndex] =
                packet.Index
            return
        }

        if (
            this.studyRecordingPacketCounts[packet.RecordingIndex] <
            packet.Index
        ) {
            this.studyRecordingPacketCounts[packet.RecordingIndex] =
                packet.Index
        }
    }

    createStudyRecordingPacketCounts(study) {
        this.studyRecordingPacketCounts = []

        this.studyRecordingPacketCounts[0] = 0

        // all except for the last recording are used.  The last recording may or may not still be
        // going when this is called.

        // I subtract 12 from each one to get the correct packet count.  The last recording might still
        // be running so if I remove 12 from the packet count it won't accurately represent the last
        // packet that made it to the screen.  However, if the recording is still going, the first
        // packet that's received will update this count and make it accurate.  If no more packets come
        // in, then the count is still accurate.  There's a slight chance that the count will be
        // inaccurate if a user tunes in at the end of a recording only a microsecond before it's
        // stopped.  In that rare case, this might be incorrect. -MRB
        for (let index = 1; index <= study.StudyRecordings.length; index += 1) {
            const packetCount = study.StudyRecordings[index - 1].SyncPacketIndex - 12
            this.studyRecordingPacketCounts[index] = Math.max(packetCount, 0)
        }
    }

    calculateSampleIndexForPacket(packet) {
        // Make sure that the packet counts are up to date.  (Maybe we should move this somewhere else
        // so it's not called as often.) -MRB

        let packetIndex = 0

        for (
            let recordingIndex = 1;
            recordingIndex < packet.RecordingIndex;
            recordingIndex += 1
        ) {
            packetIndex += this.studyRecordingPacketCounts[recordingIndex]
        }

        packetIndex += packet.Index

        return packetIndex
    }

    calculateSampleIndex = (
        sampleRecordingIndex,
        sampleRecordingPacketIndex,
    ) => {
        let packetIndex = 0

        for (
            let recordingIndex = 1;
            recordingIndex < sampleRecordingIndex;
            recordingIndex += 1
        ) {
            packetIndex += this.studyRecordingPacketCounts[recordingIndex]
        }

        packetIndex += sampleRecordingPacketIndex

        return packetIndex
    }

    setSampleOffset = async offset => {
        const newOffset = Math.min(offset, this.getSampleOffsetForEndEpoch())

        return new Promise(resolve => {
            this.setState({ sample_offset: newOffset }, () => {
                if (!this.props.isPlaying) {
                    this.setPlayCursorByIndex(newOffset + 1)
                    this.drawPlayCursor()
                }
                if (this.state.attached_to_head) {
                    this.storeSampleOffset(null)
                } else {
                    this.isEpochDrawNeeded = true
                    this.storeSampleOffset(newOffset)
                }
                this.updateAreCurrentEpochPacketsLoaded()
                resolve()
            })
        })
    }

    updateGraphControl = (sampleOffset) => {
        let current_second
        if (sampleOffset === undefined) {
            current_second = Math.floor(
                this.playCursor.index / this.props.study.StudyDataRate,
            )
        } else {
            current_second = Math.floor(
                sampleOffset / this.props.study.StudyDataRate,
            )
        }
        const is_prev_disabled = this.playCursor.index === 1
        const is_next_disabled =
            this.playCursor.index === this.getTotalPacketCountForStudy()

        const current_moment = GraphUtilities.getMomentByPacketIndex(this.context, this.playCursor.index)

        return this.props.onUpdateGraphControl(
            is_prev_disabled,
            is_next_disabled,
            current_second,
            current_moment,
        )
    }

    updateGraphControlTotalSeconds = () => {
        let total_seconds
        if (this.state.attached_to_head) {
            total_seconds = Math.floor(
                this.headIndex / this.props.study.StudyDataRate,
            )
        } else {
            total_seconds = Math.floor(
                this.getTotalPacketCountForStudy() /
                this.props.study.StudyDataRate,
            )
        }
        this.props.onUpdateGraphControlTotalSeconds(total_seconds)
    }

    setPlayCursor = (x, indexFloat, updateGraphControl = true) => {
        this.playCursor = { x, index: Math.round(indexFloat), indexFloat }
        updateGraphControl && this.updateGraphControl()
    }

    setPlayCursorByIndex = index => this.setPlayCursor(this.getXFromIndex(index), index)

    setPlayCursorByRefreshTimespan = async refreshTimespan => {
        if (!this.props.isPlaying) return
        let x = 0
        let newCursorIndex = 0
        if (this.props.playSpeed === 0) {
            const packetBatchCount = (refreshTimespan * this.props.study.StudyDataRate) / 1000
            newCursorIndex = this.playCursor.indexFloat + packetBatchCount
            x = this.getXFromIndex(newCursorIndex)
        }

        const totalPacketCount = this.getTotalPacketCountForStudy()
        const maxX = this.getXFromIndex(totalPacketCount)
        if (x <= maxX) {
            if (newCursorIndex < 0) {
                this.props.onPause(true)
                this.setPlayCursor(0, 1)
                return
            }
            if (x > this.graphWidth) {
                if (this.props.isFocusedReview) {
                    const nextImportantEpochOffset = this.props.getNextImportantEpochOffset()
                    if (nextImportantEpochOffset !== this.state.sample_offset) {
                        await this.setSampleOffset(nextImportantEpochOffset)
                        this.setPlayCursorByIndex(nextImportantEpochOffset + 1)
                        this.props.onPlay()
                    } else {
                        this.props.onPause()
                    }
                } else {
                    this.toNext()
                }
                return
            }
            if (x < 0) {
                this.toPrev()
                return
            }
            this.setPlayCursor(x, newCursorIndex, this.props.playSpeed === 0)
            return
        }
        this.props.onPause()
        this.setPlayCursor(maxX, totalPacketCount)

        // this will need to be reviewed if we ever go back to real time review vs delayed sync review
        if (!this.isAttachedToHead && this.shouldAutoAttachToHead()) {
            this.toEnd()
            this.attachToHead()
        }
    }

    getTotalPacketCountForStudy() {
        const add = (a, b) => a + b
        return this.studyRecordingPacketCounts.reduce(add, 0)
    }

    getSampleOffsetForEndEpoch() {
        const totalGraphPackets = this.getTotalPacketCountForStudy()
        const offset = this.getEpochSampleOffset(totalGraphPackets)
        return Math.max(offset, 0)
    }

    getEpochSampleOffset = i => {
        const offset =
            Math.floor(i / this.state.samples_per_window) *
            this.state.samples_per_window
        return Math.max(0, offset)
    }

    getInitialPlayCursorMilliseconds() {
        const initialTime = new URLSearchParams(window.location.search).get('t')
        if (!initialTime || !/^[0-9.]+$/.test(initialTime)) return null
        return Math.max(0, parseFloat(initialTime))
    }

    getLastMinuteOfData() {
        const totalGraphPackets = this.getTotalPacketCountForStudy()

        return Math.floor(
            totalGraphPackets / (this.props.study.StudyDataRate * 60),
        ) + 1
    }

    addToPacketsSegmentsLoaded = (start, end) => {
        this.updateAreCurrentEpochPacketsLoaded()
        this.removeFromMinutesRequestedIndices(start)

        for (let i = 0; i < this.packetsSegmentsLoaded.length; i += 1) {
            const seg = this.packetsSegmentsLoaded[i]
            // seg already includes start and end
            if (seg.start <= start && seg.end >= end) return

            // seg needs to be expanded to left
            if (seg.start > start && seg.start <= end + 1) {
                seg.start = start
                // update scrubber if adding many packets
                // or update scrubber every 500 packets if receiving live data
                // (again 2x below)
                if (end - start > 1 || start % 500 === 0) this.forceUpdate()
                return
            }

            // seg needs to be expanded to right
            if (seg.end >= start - 1 && seg.end < end) {
                seg.end = end
                if (end - start > 1 || start % 500 === 0) this.forceUpdate()
                return
            }
        }

        // new segment needed
        this.packetsSegmentsLoaded.push({ start, end })
        if (end - start > 1 || start % 500 === 0) this.forceUpdate()
    }

    addToMinutesRequestedIndices = dr => {
        for (let minute = dr.startMinute; minute < dr.endMinute; minute += 1) {
            const startIndex = minute * 60 * this.context.Study.StudyDataRate
            for (const index of this.minutesRequestedIndices) {
                if (index === startIndex) return
            }
            this.minutesRequestedIndices.push(startIndex)
        }
        this.forceUpdate()
    }

    removeFromMinutesRequestedIndices = startIndex => {
        for (let i = this.minutesRequestedIndices.length - 1; i >= 0; i -= 1) {
            const index = this.minutesRequestedIndices[i]
            const isFirstMinute = index === 0 && startIndex === 1
            if (index === startIndex || isFirstMinute) {
                this.minutesRequestedIndices.splice(i, 1)
            }
        }
    }

    areCurrentEpochPacketsLoaded = () => {
        for (let i = 1; i < this.state.samples_per_window; i += 1) {
            if (this.packets[this.state.sample_offset + i] !== undefined) {
                return true
            }
        }
        return false
    }

    updateAreCurrentEpochPacketsLoaded = () => {
        let arePacketsLoaded = this.areCurrentEpochPacketsLoaded()
        this.props.setAreCurrentEpochPacketsLoaded(arePacketsLoaded)
    }

    addPackets(packets) {
        if (!packets || packets.length === 0) {
            return
        }

        const firstSampleIndex = this.calculateSampleIndexForPacket(packets[0])

        const lastSampleIndex = this.calculateSampleIndexForPacket(
            packets[packets.length - 1],
        )

        for (const i in packets) {
            this.updateRecordingPacketCounts(packets[i])
            const sampleIndex = this.calculateSampleIndexForPacket(packets[i])
            this.packets[sampleIndex] = packets[i]
        }

        this.addToPacketsSegmentsLoaded(firstSampleIndex, lastSampleIndex)

        this.updateBuffer()
    }

    processOriginalDataResponseMinute(minute) {
        // if there are no existing packets yet, just add these
        for (const samples of minute.studySamples) {
            const firstSampleIndex = this.calculateSampleIndexForPacket(
                samples[0],
            )

            const lastSampleIndex = this.calculateSampleIndexForPacket(
                samples[samples.length - 1],
            )

            // move the head index
            if (samples.length > 0 && lastSampleIndex > this.headIndex) {
                this.headIndex = lastSampleIndex
            }

            // add the samples to the packets array
            for (const i in samples) {
                const sampleIndex = this.calculateSampleIndexForPacket(
                    samples[i],
                )
                this.packets[sampleIndex] = samples[i]
            }

            this.addToPacketsSegmentsLoaded(firstSampleIndex, lastSampleIndex)
        }

        this.isEpochDrawNeeded = true
    }

    processCustomDataResponseMinute(samples, samplesMontage) {
        this.setCustomMontage(samplesMontage)
        this.updateChannelsForCurrentMontage()

        const firstSampleIndex = this.calculateSampleIndexForPacket(samples[0])

        const lastSampleIndex = this.calculateSampleIndexForPacket(
            samples[samples.length - 1],
        )

        // move the head index
        if (samples.length > 0 && lastSampleIndex > this.headIndex) {
            this.headIndex = lastSampleIndex
        }

        // add the samples to the packets array
        for (const i in samples) {
            const sampleIndex = this.calculateSampleIndexForPacket(samples[i])
            this.packets[sampleIndex] = samples[i]
        }

        this.addToPacketsSegmentsLoaded(firstSampleIndex, lastSampleIndex)

        this.isEpochDrawNeeded = true
    }

    detachFromHead = () => {
        if (this.state.attached_to_head) {
            this.setState({ attached_to_head: false })
            this.storeIsAttachedToHead(false)
            console.log('[HEADINDEX] Detached from Head')
        }
    }

    attachToHead() {
        if (!this.state.attached_to_head) {
            this.setState({ attached_to_head: true })
            this.storeIsAttachedToHead(true)
            console.log('[HEADINDEX] Attached to Head')
        }
    }

    // #endregion

    // #region Montage Management

    /**
     * Sets the customMontage to the given one.  It then sets the customMontageChanged flag to true.
     * This triggers certain elements to be re-rendered.
     *
     * @param {any} montage
     * @memberof ElectroGraph
     */
    setCustomMontage(montage) {
        if (
            this.channels &&
            (!this.customMontage || montage.id !== this.customMontage.id)
        ) {
            for (const channel of this.channels) {
                channel.enabled = true
            }
        }
        this.customMontage = montage
    }

    /**
     * Returns the current montage.  If a custom montage is set, then it returns it.  Otherwise it
     * returns the selected montage template.
     *
     * @returns
     * @memberof ElectroGraph
     */
    getCurrentMontage() {
        let selectedMontage = null

        if (
            this.customMontage === undefined ||
            this.customMontage.Channels === undefined
        ) {
            selectedMontage = this.props.settings.Montage
        } else {
            selectedMontage = this.customMontage
        }

        return selectedMontage
    }

    // #endregion

    // #region Graph Rendering - High Level

    rendrLoop = currentTime => {
        if (this.props.playSpeed === 0 || !this.props.isPlaying) {
            const renderGraph = this.canRenderGraph(currentTime)
            renderGraph && this.renderElectroGraph(currentTime)
            this.updateGraphControlTotalSeconds()
        }
        this.animationLoopID = requestAnimationFrame(this.rendrLoop)
    }

    pagingLoop = async () => {
        if (!this.isComponentMounted) return
        const start = Date.now()
        if (this.devicePixelRatio !== window.devicePixelRatio || 1) {
            this.devicePixelRatio = window.devicePixelRatio || 1
            this.isResizeNeeded = true
            this.resizeGraph()
        }
        let timeout = 1000 / (this.props.playSpeed || 1)
        if (this.props.isPlaying && this.props.playSpeed > 0) {
            this.isEpochDrawNeeded = true
            let shouldDrawEpoch = true
            if (this.areCurrentEpochPacketsLoaded()) {
                if (this.props.isFocusedReview) {
                    const nextImportantEpochOffset = this.props.getNextImportantEpochOffset()
                    if (nextImportantEpochOffset !== this.state.sample_offset) {
                        await this.setSampleOffset(nextImportantEpochOffset)
                        this.setPlayCursorByIndex(nextImportantEpochOffset + 1)
                        this.props.onPlay()
                    } else {
                        shouldDrawEpoch = false
                    }
                } else {
                    shouldDrawEpoch = await this.toNext()
                }
            } else {
                shouldDrawEpoch = false
            }
            if (shouldDrawEpoch) {
                this.state.samples_per_window = Math.max(this.props.study.StudyDataRate, 1) * this.props.settings.Timebase.Value
                this.drawEpoch()
            } else if (this.props.isPlaying) {
                this.props.onPause()
            }
            setTimeout(this.pagingLoop, Math.max(0, timeout - (Date.now() - start)))
        } else {
            setTimeout(this.pagingLoop, timeout)
        }
    }

    renderElectroGraph = currentTime => {
        // Calculate the ms since last update
        // Draw 17ms (1 frame at 60hz) worth if it's the first draw
        const refreshTimespan =
            currentTime - (this.lastDrawTime || currentTime - 17)

        // render graph
        this.resizeGraph()

        this.drawEpoch()

        // This is commented out since data is not live streamed currently.
        // this.drawGraph(refreshTimespan)

        if (this.props.isPlaying) {
            this.setPlayCursorByRefreshTimespan(refreshTimespan)
            this.drawPlayCursor()
            this.props.study.UseVideo && this.videoResyncCheck()
        }

        // post render updates
        this.lastDrawTime = currentTime
        this.lastResizeTime = currentTime
        this.isResized = false
    }

    videoResyncCheck() {
        const videoStartEvents = this.props.study.StudyEvents.filter(e => e.EventTypeID === VIDEO_START)
        if (videoStartEvents.length === 0 || !this.props.study.UseVideo) {
            return
        }

        const videoStartEventPacketIndexes = videoStartEvents.map(e =>
            this.calculateSampleIndex(e.RecordingIndex, e.StartPacketIndex),
        )

        const shouldCheckResync = videoStartEventPacketIndexes.some(
            index =>
                index >= this.minPacketIndexForVideoStart &&
                index <= this.playCursor.index,
        )

        if (shouldCheckResync) {
            this.setMinPacketIndexForVideoStart(this.playCursor.index + 1)
            this.props.onVideoResync(this.playCursor.index)
        }
    }

    setMinPacketIndexForVideoStart(index) {
        this.minPacketIndexForVideoStart = index
    }

    drawGraph = refreshTimespan => {
        const packetBatchCount = this.calculatePacketsToRender(refreshTimespan)

        if (!this.canDrawGraph(packetBatchCount)) return

        if (this.state.attached_to_head) {
            this.drawSweepLine(packetBatchCount)
        }

        this.drawGraphRange(this.headIndex - 1, packetBatchCount + 1)

        this.headIndex += packetBatchCount
    }

    drawEpoch = () => {
        if (!this.isEpochDrawNeeded || this.packets.length === 0) {
            return
        }

        this.isEpochDrawNeeded = false
        const hasEpochChanged =
            this.state.sample_offset !== this.previousEpochSampleOffset


        // Clear the graph canvas so we don't draw new data over old data
        this.ctx.clearRect(0, 0, this.graphWidth * this.devicePixelRatio, this.graphHeight * this.devicePixelRatio)
        this.drawGraphRange(
            this.state.sample_offset,
            Math.min(
                this.state.samples_per_window,
                this.headIndex - this.state.sample_offset,
            ),
        )

        // notify listeners of epoch change
        hasEpochChanged && this.updateEpochMovementDirection()
        hasEpochChanged &&
            this.graphDataManager &&
            this.graphDataManager.epochChanged()

        // update epoch sample offset tracking
        this.previousEpochSampleOffset = this.state.sample_offset
    }

    drawGraphRange = (startIndex, packetBatchCount) => {
        if (packetBatchCount === 0) {
            return
        }
        // draw a batch for each channel
        this.ctx.lineWidth = '1'

        const montage = this.getCurrentMontage()

        for (let ch = 0; ch < this.state.channel_count - 1; ch += 1) {
            const color = getChannelColor(ch, montage, this.theme)
            this.drawChannel(ch, startIndex, packetBatchCount, color)
        }

        this.drawPeripherals(startIndex, packetBatchCount)
    }

    resizeGraph() {
        if (!this.isResizeNeeded) {
            return
        }

        this.isResizeNeeded = false
        this.isResized = true
        this.isEpochDrawNeeded = true

        if (!this.props.isHidden) {
            this.width = this.elem.clientWidth
            this.height = this.elem.clientHeight
        }

        this.graphWidth = this.width - this.gutter.left - this.gutter.right
        this.graphHeight =
            this.height -
            this.gutter.top -
            this.gutter.bottom -
            this.scrubberHeight

        this.setCanvasSize(
            this.canvasGraph,
            this.graphWidth,
            this.graphHeight,
        )
        this.setCanvasSize(
            this.canvasMouse,
            this.graphWidth,
            this.graphHeight,
        )
        this.ctxMouse.strokeStyle = '#68e32a'
        this.ctxMouse.lineWidth = this.playCursor.width
        this.drawPlayCursor()

        this.setState({
            samples_per_window: Math.round(
                this.props.settings.Timebase.Value *
                this.props.study.StudyDataRate,
            ),
        })

        // update the montage.
        this.updateChannelsForCurrentMontage()
    }

    canRenderGraph(currentTime) {
        // If the window is being actively resized, wait a little between resizes to keep from redrawing the epoch a million times a second
        return (
            !this.isResizeNeeded ||
            currentTime - this.lastResizeTime > this.resizeMinInterval
        )
    }

    canDrawGraph = packetBatchCount =>
        packetBatchCount > 0 && this.packets.length - this.headIndex > 0

    moveGraphToStudyEvent = async (studyEvent, position = 'start') => {
        this.props.isPlaying && this.props.onPause()
        let packetIndex = 0
        switch (position) {
            case 'end':
                packetIndex = studyEvent.EndPacketIndex
                break
            // case 'center': todo
            // case 'start': default behavior
            default:
                packetIndex = studyEvent.StartPacketIndex
        }
        const initialIndex = this.calculateSampleIndex(
            studyEvent.RecordingIndex,
            packetIndex,
        )
        const newSampleOffset =
            Math.floor(initialIndex / this.state.samples_per_window) *
            this.state.samples_per_window
        const stayInCurrentEpoch =
            this.state.sample_offset === newSampleOffset

        if (!stayInCurrentEpoch) {
            await this.setSampleOffset(newSampleOffset)
            this.detachFromHead()
            this.isEpochDrawNeeded = true
            this.setPlayCursorByIndex(newSampleOffset)
        }
        if (this.props.playSpeed === 0) this.drawPlayCursor()
    }

    updateEpochMovementDirection() {
        const previousEpochSampleOffset = this.previousEpochSampleOffset
        const currentEpochSampleOffset = this.state.sample_offset

        let epochDirection =
            this.currentEpochMovementDirection || ED.EPOCH_DIRECTION_NONE

        if (previousEpochSampleOffset === currentEpochSampleOffset) {
            this.currentEpochMovementDirection = ED.EPOCH_DIRECTION_NONE
        }

        if (currentEpochSampleOffset < previousEpochSampleOffset) {
            epochDirection = ED.EPOCH_DIRECTION_BACKWARD
        }

        if (currentEpochSampleOffset > previousEpochSampleOffset) {
            epochDirection = ED.EPOCH_DIRECTION_FORWARD
        }

        if (
            this.props.playSpeed !== 0 &&
            epochDirection === ED.EPOCH_DIRECTION_FORWARD
        ) {
            epochDirection = ED.EPOCH_DIRECTION_FORWARD_FAST
        }

        this.currentEpochMovementDirection = epochDirection

        this.updateDataRequestLength()
    }

    // #endregion

    // #region Graph Rendering - Data Rendering

    getChannelScale = channel_index => {
        const channel_type = this.getChannelType(channel_index)

        const channel_type_setting = this.state.channel_type_settings.find(
            channel => channel.channel_type_id === channel_type,
        )

        return this.props.pixelsPerCm / channel_type_setting.Sensitivity / 10
    }

    drawChannel(channel_index, startIndex, packetBatchCount, color) {
        if (!this.channels[channel_index].enabled) return

        let firstBatch = true
        let sampleOffset = this.state.sample_offset
        let sample0
        let sample1
        let x
        let y0
        let y1
        let index
        let prevX
        let prevY

        const scale = this.getChannelScale(channel_index)

        // Create path
        this.ctx.strokeStyle = color

        const isTraceLineThick =
            this.state.selected_channel.id === channel_index &&
            this.state.is_channel_menu_showing
        if (isTraceLineThick) {
            this.ctx.lineWidth = 3
        }

        for (let b = 0; b <= packetBatchCount; b += 1) {
            index = startIndex + b
            sample0 = this.packets[index - 1]
            sample1 = this.packets[index]

            if (sample0 === undefined) {
                // Skip through until we find the first packet
                continue
            }

            if (!sample1) {
                // Error: We shouldn't be trying to plot more points than we have.
                break
            }

            x = Math.round(this.getXFromIndex(index, sampleOffset))

            if (firstBatch) {
                y0 = Math.round(this.channels[channel_index].mid - sample0.Inputs[channel_index] * scale)
                this.ctx.moveTo(x * this.devicePixelRatio, y0 * this.devicePixelRatio)
                this.ctx.beginPath()
                firstBatch = false
            }

            y1 = Math.round(this.channels[channel_index].mid - sample1.Inputs[channel_index] * scale)

            if (x !== prevX || y1 !== prevY) {
                this.ctx.lineTo(x * this.devicePixelRatio, y1 * this.devicePixelRatio)
                prevX = x
                prevY = y1
            }

            if (this.state.attached_to_head && this.getXFromIndex(index + 1, sampleOffset) > this.graphWidth) {
                // Packet index of the overlap
                sampleOffset = this.getEpochSampleOffset(this.headIndex)

                if (this.state.sample_offset !== sampleOffset) {
                    this.setSampleOffset(sampleOffset)
                }

                firstBatch = true
            }
        }

        this.ctx.stroke()
        this.ctx.closePath()
        if (isTraceLineThick) {
            this.ctx.lineWidth = 1
        }
    }

    drawPeripherals = (startIndex, packetBatchCount) => {
        this.drawEMChannel(startIndex, packetBatchCount)
        this.drawPhoticStims(startIndex, packetBatchCount)
    }

    drawEMChannel(startIndex, packetBatchCount) {
        if (!this.channels[this.channels.length - 1].enabled) return

        let firstBatch = true

        const sampleOffset = this.state.sample_offset

        let sample0
        let sample1
        let x
        let y0
        let y1
        let index

        const isTraceLineThick =
            this.state.selected_channel.id === this.channels.length - 1 &&
            this.state.is_channel_menu_showing
        if (isTraceLineThick) {
            this.ctx.lineWidth = 3
        }
        this.ctx.strokeStyle = getRGBAFromHexARGB(
            this.theme.ManualPulseColor,
        )

        for (let b = 0; b <= packetBatchCount; b += 1) {
            index = startIndex + b
            sample0 = this.packets[index - 1]
            sample1 = this.packets[index]

            // Skip through until we find the first packet
            if (sample0 === undefined) {
                continue
            }

            if (!sample1) {
                // We shouldnt be trying to plot more EM points than we have.
                break
            }

            x = this.getXFromIndex(index, sampleOffset)

            if (firstBatch) {
                y0 =
                    this.channels[this.state.channel_count - 1].mid -
                    (sample0.Periphs.isEventMarker ? 1 : 0) * 20
                this.ctx.moveTo(x * this.devicePixelRatio, y0 * this.devicePixelRatio)
                this.ctx.beginPath()
                firstBatch = false
            }

            y1 =
                this.channels[this.state.channel_count - 1].mid -
                (sample0.Periphs.isEventMarker ? 1 : 0) * 20
            this.ctx.lineTo(x * this.devicePixelRatio, y1 * this.devicePixelRatio)

            if (this.getXFromIndex(index + 1, sampleOffset) > this.graphWidth) {
                if (!this.detachedFromHead) {
                    firstBatch = true
                }
            }
        }
        this.ctx.strokeStyle = getRGBAFromHexARGB(
            this.theme.ManualPulseColor,
        )
        this.ctx.stroke()
        this.ctx.closePath()
        if (isTraceLineThick) {
            this.ctx.lineWidth = 1
        }
    }

    drawPhoticStims = (startIndex, packetBatchCount) => {
        this.ctx.fillStyle = getRGBAFromHexARGB(this.theme.BioMidlineColor)
        for (let b = 0; b < packetBatchCount; b += 1) {
            const index = startIndex + b
            const sample = this.packets[index]
            if (sample && sample.Periphs.isPhoticLightOn) {
                const x = this.getXFromIndex(
                    index,
                    this.state.sample_offset,
                )
                const width = Math.ceil(
                    this.graphWidth / this.state.samples_per_window,
                )
                this.ctx.fillRect(x * this.devicePixelRatio, (this.graphHeight - 10) * this.devicePixelRatio, Math.max(1, width) * this.devicePixelRatio, 10 * this.devicePixelRatio)
            }
        }
    }

    getChannelType = channel_index => {
        const montage = this.getCurrentMontage()

        if (channel_index === montage.Channels.length) {
            return CHANNEL_TYPE[EM]
        }

        if (channel_index > montage.Channels.length) {
            return null
        }

        return montage.Channels[channel_index].ChannelTypeID
    }

    getChannelLabel(channel_index) {
        const currentMontage = this.getCurrentMontage()

        if (channel_index === currentMontage.Channels.length) {
            return 'EM'
        }

        return currentMontage.Channels[channel_index]
            ? currentMontage.Channels[channel_index].Name
            : ''
    }

    // #endregion

    // #region Graph Rendering - Graph Framework

    clear() {
        this.stop()
        this.ctx.clearRect(0, 0, this.graphWidth * this.devicePixelRatio, this.graphHeight * this.devicePixelRatio)
    }

    setCanvasSize(canvas, width, height) {
        canvas.style.width = `${width}px`
        canvas.style.height = `${height}px`
        canvas.width = width * this.devicePixelRatio
        canvas.height = height * this.devicePixelRatio
    }

    drawPlayCursor = () => {
        this.ctxMouse.clearRect(0, 0, this.graphWidth * this.devicePixelRatio, this.graphHeight * this.devicePixelRatio)
        this.ctxMouse.beginPath()
        this.ctxMouse.moveTo(this.playCursor.x * this.devicePixelRatio, 0)
        this.ctxMouse.lineTo(this.playCursor.x * this.devicePixelRatio, this.graphHeight * this.devicePixelRatio)
        this.ctxMouse.stroke()
    }

    drawSweepLine = packetBatchCount => {
        // Draws the sweep line that clears data right in front of where the new data will be added.
        const x1 = this.getXFromIndex(this.headIndex)
        const sweepWidth = Math.max(
            20,
            this.getPixelWidthFromSeconds(
                packetBatchCount / this.context.Study.StudyDataRate,
            ),
        )
        const x2 = x1 + sweepWidth

        // if the sweep line goes beyond the graph, then we need to sweep at the beginning of the graph
        if (x2 > this.graphWidth) {
            // Sweep what's left of the right side
            this.ctx.clearRect(x1, 0, this.graphWidth - x1, this.graphHeight)
            // Wrap around and sweep what's left of the sweep width at the beginning
            this.ctx.clearRect(
                0,
                0,
                sweepWidth - (this.graphWidth - x1),
                this.graphHeight,
            )
        } else {
            this.ctx.clearRect(x1, 0, sweepWidth, this.graphHeight)
        }
    }

    getChannelLabels = () => {
        const labels = []
        for (
            let channel_index = 0;
            channel_index < this.state.channel_count;
            channel_index += 1
        ) {
            const label = this.getChannelLabel(channel_index)
            const type = this.getChannelType(channel_index)

            if (type === null) break

            labels.push({
                channel_index,
                type,
                label,
            })
        }

        return labels
    }

    // #endregion

    // #region Graph Rendering - Low Level & Utility

    /**
     * adjusts amount of data that will be requested based on the speed the person is
     * moving through the graph
     * TODO: take speed of movement via arrow keys into account
     */
    updateDataRequestLength() {
        if (this.props.playSpeed > 0) {
            this.graphDataManager.dataRequestSettings.minutesToRequest = 40
            this.graphDataManager.dataRequestSettings.minutesToCheck = 40
        } else {
            this.graphDataManager.dataRequestSettings.minutesToRequest = this.graphDataManager.dataRequestDefaults.minimumMinutesToRequest
            this.graphDataManager.dataRequestSettings.minutesToCheck = this.graphDataManager.dataRequestDefaults.minimumMinutesToCheck
        }
    }

    calculatePacketsToRender(refreshTimespan) {
        // TODO: Make sure the - 1 is correct
        const samplesLeft = this.packets.length - this.headIndex - 1
        if (samplesLeft < 1) {
            return 0
        }

        // NOTE: If we try to print more px than the sweep, it doesn't clear everything ahead.
        let packetsToDraw =
            (refreshTimespan * this.props.study.StudyDataRate) / 1000

        this.packetsToDrawRemainder += packetsToDraw % 1

        packetsToDraw = Math.floor(packetsToDraw)

        if (this.packetsToDrawRemainder >= 1) {
            packetsToDraw += Math.floor(this.packetsToDrawRemainder)
            this.packetsToDrawRemainder -= Math.floor(
                this.packetsToDrawRemainder,
            )
        }

        if (samplesLeft < packetsToDraw) {
            // TODO: Check if we're attached to head and livestreaming
            // If we don't have enough samples, add the balance to the remainder for later
            // this.packetsToDrawRemainder += (packetsToDraw - samplesLeft)
            packetsToDraw = samplesLeft
        }

        return packetsToDraw
    }

    getMsFromX(x) {
        const oMs =
            (this.state.sample_offset / this.props.study.StudyDataRate) *
            1000
        const mMs =
            (x / this.graphWidth) * this.props.settings.Timebase.Value * 1000
        return Math.round(oMs + mMs)
    }

    getPacketFromX(x) {
        const s = this.getMsFromX(x) / 1000.0
        const packetIndex = Math.floor(s * this.props.study.StudyDataRate)

        if (packetIndex >= this.packets.length) {
            return this.packets[this.packets.length - 1]
        }

        return this.packets[packetIndex]
    }

    getPackets = (startIndex, endIndex) =>
        this.packets.slice(startIndex, endIndex)

    getPixelWidthFromSeconds = seconds =>
        (seconds * this.graphWidth) / this.props.settings.Timebase.Value

    /**
     * Get canvas x coordinate value corresponding to specified index
     * NOTE: This value does not include the left gutter
     * @param {number} index index for which to find corresponding x coordinate
     * @param {number} sampleOffset index offset used in place of this.state.sample_offset
     * @return {number} x coordinate corresponding to index
     */
    getXFromIndex(index, sampleOffset) {
        // pixel width from very beginning of graph to index
        const nPixels = this.getPixelWidthFromSeconds(
            index / this.props.study.StudyDataRate,
        )
        // pixel width from very beginning of graph to this.state.sample_offset
        const oPixels = this.getPixelWidthFromSeconds(
            (typeof sampleOffset === 'number'
                ? sampleOffset
                : this.state.sample_offset) /
            this.props.study.StudyDataRate,
        )

        return nPixels - oPixels
    }

    storeSampleOffset(offset) {
        StorageManager.set(
            `study:${this.context.Study.ID}:SampleOffset`,
            offset,
        )
    }

    getStoredSampleOffset() {
        return StorageManager.get(`study:${this.context.Study.ID}:SampleOffset`)
    }

    storeIsAttachedToHead(isAttachedToHead) {
        StorageManager.set(
            `study:${this.context.Study.ID}:IsAttachedToHead`,
            isAttachedToHead,
        )
    }

    getStoredIsAttachedToHead() {
        return StorageManager.get(
            `study:${this.context.Study.ID}:IsAttachedToHead`,
        )
    }

    shouldAutoAttachToHead() {
        const headIndexTolerance = 5
        const havePackets = this.packets.length > 0
        const distanceToIndexEnd = this.packets.length - (this.headIndex + headIndexTolerance)
        return havePackets && (distanceToIndexEnd <= 0)
    }

    // #endregion

    // #region Session Storage

    storeSetting(name, value) {
        StorageManager.set(`study:${this.props.study.ID}:${name}`, value)
    }

    getStoredSetting(name) {
        return StorageManager.get(`study:${this.props.study.ID}:${name}`)
    }

    // #endregion

    // #region Render Method

    render() {
        const channels = this.getChannelLabels()
        const graphAndMouseStyle = {
            position: 'absolute',
            left: this.gutter.left,
            top: this.gutter.top,
        }
        const channel_type_setting = this.state.channel_type_settings.find(
            channel =>
                channel.channel_type_id === this.state.selected_channel.type,
        )

        return (
            <div
                id="GraphPanel"
                ref={elem => (this.elem = elem)}
                className={`${this.props.isHidden ? 'hidden' : ''}
                ${this.props.creatingEventOfType !== null ? 'creating' : ''}`}
                style={{
                    background: getRGBAFromHexARGB(
                        this.theme.BackgroundColor,
                    ),
                }}
            >
                <ChannelLabels
                    channels={channels}
                    midpoints={this.state.midpoints}
                    width={this.gutter.left}
                    height={this.graphHeight}
                    theme={this.theme}
                    handleChannelClick={this.handleChannelClick}
                />

                <EventMarkers
                    samplesPerWindow={this.state.samples_per_window}
                    timebase={parseInt(this.props.settings.Timebase.Value)}
                    sampleOffset={this.state.sample_offset}
                    recordingPacketCounts={this.studyRecordingPacketCounts}
                    calculateSampleIndex={this.calculateSampleIndex}
                    gutter={this.gutter}
                    scrubberHeight={this.scrubberHeight}
                    onMoveGraphToStudyEvent={this.moveGraphToStudyEvent}
                    graphWidth={this.graphWidth}
                    montage={this.getCurrentMontage()}
                    onUpdateEvent={this.props.onUpdateEvent}
                    onEditStudyEvent={this.props.onEditStudyEvent}
                    onDeleteStudyEvent={this.props.onDeleteStudyEvent}
                    onSelectEvent={this.props.onSelectEvent}
                    selectedStudyEvent={this.props.selectedStudyEvent}
                    onCreateEvent={this.props.onCreateEvent}
                    getPackets={this.getPackets}
                    creatingEventOfType={this.props.creatingEventOfType}
                    creatingEventOnChannel={
                        this.props.creatingEventOnChannel
                    }
                    onExitCreatingEvent={this.props.toggleCreateEventMode}
                    getChannelScale={this.getChannelScale}
                    channels={this.channels}
                    clockSetting={this.props.clockSetting}
                />

                <GridLines
                    width={this.graphWidth}
                    height={this.graphHeight}
                    gutter={this.gutter}
                    sampleOffset={this.state.sample_offset}
                    timebase={this.props.settings.Timebase.Value}
                    channelCount={this.state.channel_count}
                    midpoints={this.state.midpoints}
                    theme={this.theme}
                    clockSetting={this.props.clockSetting}
                />

                {!this.props.areCurrentEpochPacketsLoaded && (
                    <div className="loading-packets-container">
                        {this.studyRecordingPacketCounts.length > 1 ? (
                            <LoadingSpinner height="0" txtMsg="Loading graph data..." />
                        ) : (
                            <p>No recordings for this study</p>
                        )}
                    </div>
                )}

                <canvas // Graph
                    id="myEEG"
                    className="graph-canvas"
                    style={graphAndMouseStyle}
                    ref={canvas => (this.canvasGraph = canvas)}
                />

                <canvas // Mouse
                    className="mouse-canvas"
                    style={graphAndMouseStyle}
                    ref={canvas => (this.canvasMouse = canvas)}
                    onClick={this.handleMouseClick}
                />

                <RoleBasedContent required_roles={StudyAuth.ViewDiagnostics}>
                    {this.state.are_diagnostics_on && this.graphDataManager.dataSource && (
                        <Diagnostics
                            graphHeight={this.graphHeight}
                            dataSource={this.graphDataManager.dataSource}
                            onClose={() => this.setState({ are_diagnostics_on: false })}
                        />
                    )}
                </RoleBasedContent>

                <NonEEGOverlay
                    isOpen={this.state.is_non_eeg_overlay_showing}
                    channel={this.state.selected_channel}
                    options={this.props.options}
                    settings={channel_type_setting}
                    handleToggle={this.toggleNonEEGOverlay}
                    handleSettingChange={this.handleSettingChange}
                />

                <GraphChannelMenu
                    channel={this.state.selected_channel}
                    isOpen={this.state.is_channel_menu_showing}
                    handleShowNonEEGOverlay={this.handleShowNonEEGOverlay}
                    handleToggleHideChannel={this.handleToggleHideChannel}
                    toggleCreateEventMode={this.handleToggleCreateEventMode}
                />

                <Scrubber
                    scrubberHeight={this.scrubberHeight}
                    totalPacketCount={
                        this.state.attached_to_head
                            ? this.headIndex
                            : this.getTotalPacketCountForStudy()
                    }
                    clockSetting={this.props.clockSetting}
                    sampleOffset={this.state.sample_offset}
                    calculateSampleIndex={this.calculateSampleIndex}
                    samplesPerWindow={this.state.samples_per_window}
                    setSampleOffset={this.setSampleOffset}
                    setPlayCursor={this.setPlayCursorByIndex}
                    drawPlayCursor={this.drawPlayCursor}
                    packetsSegmentsLoaded={this.packetsSegmentsLoaded}
                    minutesRequestedIndices={this.minutesRequestedIndices}
                    attachedToHead={this.state.attached_to_head}
                    onAttachToHead={this.toEnd}
                    onDetachFromHead={this.detachFromHead}
                    onPause={() => {
                        this.props.onPause()
                        this.shouldAutoPlay = false
                    }}
                    onVideoResync={this.props.onVideoResync}
                    totalSeconds={this.props.totalSeconds}
                />

                <Legend
                    sensitivity={parseFloat(
                        this.props.settings.Sensitivity.Value,
                        10,
                    )}
                    timebase={parseInt(this.props.settings.Timebase.Value, 10)}
                    gutter={this.gutter}
                    scrubberHeight={this.scrubberHeight}
                    height={this.props.pixelsPerCm}
                    graphHeight={this.graphHeight}
                    graphWidth={this.graphWidth}
                    color={getRGBAFromHexARGB(this.theme.LegendColor)}
                />
            </div>
        )
    }
}

// #endregion

export default ElectroGraph
