import Patient from '../../types/Patient'
import React from 'react'
import DateTimeUtils from '../../utils/DateTimeUtils'

interface Props {
    patient: Patient
    canRemove?: boolean,
    isOld?: boolean,
    handleRemove?: () => void,
}

const PatientCard: React.FC<Props> = props => (
    <div className={`card rounded ${(props.isOld ? 'text-muted shadow-sm mt-1': 'mt-1')}`}>
        <div className="card-body">
            <div className="row">
                <div className="col-md-3">
                    <span>
                        <i className="icon-electrotek-user-circle-o"
                            style={{
                                fontSize: '2.5em',
                            }}
                        />
                    </span>
                </div>

                <div className="col-md-7">
                    <h5>{props.patient.FirstName} {props.patient.LastName}</h5>
                    <small>ID: {props.patient.PatientID}</small>
                    <hr/>
                    <small>
                        DOB: {DateTimeUtils.getAbbreviatedDateOfBirth(props.patient.DateOfBirth)}
                    </small>
                </div>
                {
                    props.canRemove &&
                    <button type="button"
                        className="close"
                        style={{
                            padding: '0.5rem',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                        }}
                        aria-label="Remove"
                        onClick={props.handleRemove}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                }
            </div>
        </div>
    </div>
)

export default PatientCard
