export enum ViolationType {
    DataFiles,
    EventsFiles,
    VideoFiles,
    VideoInfoFiles,
    Other,
}

export enum ViolationMessage {
    MixedDataFileTypes = 'Can not have both .EDF and .BDF files.',
    MixedVideoFileTypes = 'Only one type of video file is allowed.',
    MultipleEDFFiles = 'Can not have more than one .EDF file.',
    MultiplePhoticChannels = 'Multiple photic channels detected in data file.',
    NoDataFile = 'No data file found.',
    NoEventsFile = 'No .TEV / .TVX file found.',
    NoVideoFile = 'No video file, but a .TVS file was found.',
    IncorrectTEVFileCount = 'There must be one and only one .TEV file per data file.',
    IncorrectTEVFileName = 'Each .EDF / .BDF file must have a corresponding .TEV file with the same name.',
    IncorrectTVXFileName = 'The .TVX file must have the same name as the first .EDF / .BDF file',
    IncorrectTVXFileHeaderLength = 'The .TVX file header is the wrong size.',
    IncorrectTVXFileBodyLength = 'The .TVX file body size is not divisible by 6.',
    IncorrectTVXFileHeaderLengthValue = 'The header size value as specified in the header of .TVX file is incorrect.',
    IncorrectTVSFileName = 'Each video file must have a corresponding TVS file with the same name.',
    ExtraTvsFilesFound = 'There must be one and only one .TVS file per video file.',
    MissingDataFile = 'Unable to find %s.',
    HeaderMismatch = 'Headers of data files and events files do not match.',
    DataHeaderMismatch = 'The %s must be the same in all data files.',
    MixedSampleRates = 'Each channel must use the same sample rate. Found %s and %s.',
    MaxChannelCountExceeded = 'The header file contains more data channels (%s) than the maximum supported by database (%s).',
    TooManyTVXFiles = 'Only one .TVX file is allowed.',
    InvalidFile = '%s is not valid: %s.',
    InvalidCspPkg = 'Unable to validate the Cloud Sync Package. Please verify that all Cloud Sync Package files created are present. %s',
}

class Violations {
    constructor() {
        this.dataFiles = []
        this.eventsFiles = []
        this.videoFiles = []
        this.videoInfoFiles = []
        this.other = []
    }

    dataFiles: ViolationMessage[]
    eventsFiles: ViolationMessage[]
    videoFiles: ViolationMessage[]
    videoInfoFiles: ViolationMessage[]
    other: ViolationMessage[]

    add(type: ViolationType, msg: ViolationMessage, ...replacements: any[]) {
        for (const replacement of replacements) {
            msg = msg.replace('%s', replacement) as ViolationMessage
        }
        switch (type) {
            case ViolationType.DataFiles:
                if (!this.dataFiles.includes(msg)) {
                    this.dataFiles.push(msg)
                }
                break
            case ViolationType.EventsFiles:
                if (!this.eventsFiles.includes(msg)) {
                    this.eventsFiles.push(msg)
                }
                break
            case ViolationType.VideoFiles:
                if (!this.videoFiles.includes(msg)) {
                    this.videoFiles.push(msg)
                }
                break
            case ViolationType.VideoInfoFiles:
                if (!this.videoInfoFiles.includes(msg)) {
                    this.videoInfoFiles.push(msg)
                }
                break
            case ViolationType.Other:
                if (!this.other.includes(msg)) {
                    this.other.push(msg)
                }
                break
        }
    }

    isEmpty() {
        if (this.dataFiles.length > 0) return false
        if (this.eventsFiles.length > 0) return false
        if (this.videoInfoFiles.length > 0) return false
        if (this.videoFiles.length > 0) return false
        if (this.other.length > 0) return false
        return true
    }
}

export default Violations
