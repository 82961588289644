import React from 'react'
import AuditLogEntry, { formatActionType } from '../../types/AuditLogEntry'
import moment from 'moment'
import { StudyPatient } from '../../types/cptMonitoringTypes'
import { Link } from 'react-router-dom'

interface Props {
    logEntries: AuditLogEntry[]
    studyPatients: StudyPatient[]
}

const CPTMonitoringListView: React.FC<Props> = props => {
    return (
        <table className="table sortable-table table-hover">
            <thead>
                <tr>
                    <th>Action</th>
                    <th>Date</th>
                    <th>Study</th>
                </tr>
            </thead>
            <tbody>
                {props.logEntries.map(entry => {
                    const studyPatient = props.studyPatients.find(p => p.StudyID === entry.ItemId)
                    const patientName = studyPatient?.PatientInfo.FullName
                    const mom = moment(entry.DateCreated)
                    const formatString = 'MMM D, YYYY h:mm a'

                    return (
                        <tr key={entry.ID}>
                            <td>{formatActionType(entry.ActionTypeId)}</td>
                            <td>{mom.format(formatString)}</td>
                            <td>
                                <Link to={`/Study/${entry.ItemId}`}>
                                    {patientName}
                                </Link>
                            </td>
                        </tr>
                    )
                })}
                {props.logEntries.length === 0 && (
                    <tr>
                        <td>No logs for this month</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}

export default CPTMonitoringListView
