import { PropsWithChildren } from 'react'
import './AsideMenu.scss'

interface Props {
    areControlsMinimized: boolean
}

function AsideMenu(props: PropsWithChildren<Props>) {
    return (
        <div className={`aside-menu ${props.areControlsMinimized ? 'controls-minimized' : ''}`}>
            {props.children}
        </div>
    )
}

export default AsideMenu
