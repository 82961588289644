export const SUPER_ADMIN_ROLE = 'SuperAdmin'
export const SUPPORT_ROLE = 'Support'
export const PRODUCTION_ROLE = 'Production'
export const FACILITY_ADMIN_ROLE = 'FacilityAdmin'
export const REVIEW_DOCTOR_ROLE = 'ReviewDoctor'
export const LEAD_TECH_ROLE = 'LeadTech'
export const FIELD_TECH_ROLE = 'FieldTech'
export const OFFICE_PERSONNEL_ROLE = 'OfficePersonnel'
export const REGISTERED_USER_ROLE = 'RegisteredUser'

export const ROLES = [
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    PRODUCTION_ROLE,
    FACILITY_ADMIN_ROLE,
    REVIEW_DOCTOR_ROLE,
    LEAD_TECH_ROLE,
    FIELD_TECH_ROLE,
    OFFICE_PERSONNEL_ROLE,
    REGISTERED_USER_ROLE,
]

export const SYSTEM_ROLES = [SUPER_ADMIN_ROLE, SUPPORT_ROLE, PRODUCTION_ROLE]
export const FACILITY_ROLES = [FACILITY_ADMIN_ROLE, REVIEW_DOCTOR_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE]

export const ROLE_NAMES = [
    {
        id: '907198ED-EFBD-4B48-ABE9-1C62C129D947',
        name: SUPER_ADMIN_ROLE,
        friendly_name: 'Super Admin',
    },
    {
        id: '1139367A-07B8-46D3-A949-20DFE2B7E9AC',
        name: SUPPORT_ROLE,
        friendly_name: 'Support',
    },
    {
        id: 'A247B200-ED53-4F9A-8449-4E56043A00C1',
        name: PRODUCTION_ROLE,
        friendly_name: 'Production',
    },
    {
        id: 'C94FD9B6-0C93-4901-91F7-764FEEE1FA21',
        name: FACILITY_ADMIN_ROLE,
        friendly_name: 'Facility Admin',
    },
    {
        id: '289C85AC-F6CB-4DA5-9BA1-06A8E467821E',
        name: REVIEW_DOCTOR_ROLE,
        friendly_name: 'Review Doctor',
    },
    {
        id: '17950AA1-181D-4BA2-8FA5-CB8A91283B4B',
        name: LEAD_TECH_ROLE,
        friendly_name: 'Lead Tech',
    },
    {
        id: 'F6A4A81B-4BB0-452D-908C-5563D4A77CCE',
        name: FIELD_TECH_ROLE,
        friendly_name: 'Field Tech',
    },
    {
        id: 'EECD0CA1-5EAF-46CC-A3F1-7B90248F6CEF',
        name: OFFICE_PERSONNEL_ROLE,
        friendly_name: 'Office Personnel',
    },
    {
        id: '8232EE0C-D443-4000-90D8-A7E71FA1D59D',
        name: REGISTERED_USER_ROLE,
        friendly_name: 'Registered User',
    },
]
