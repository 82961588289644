import React, { useState, useEffect, useMemo, useRef } from 'react'
import './CPTUserMonitoring.scss'
import moment, { Moment } from 'moment'
import DateTime from 'react-datetime'
import CPTMonitoringBlockView from './CPTMonitoringBlockView'
import CPTMonitoringListView from './CPTMonitoringListView'
import AuditLogsApi from '../../services/AuditLogsApi'
import CPTMonitoringService from '../../services/CPTMonitoringService'
import ToastUtils from '../../utils/ToastUtils'
import UsersApi from '../../services/UsersApi'
import User from '../../types/User'
import LoadingSpinner from '../../components/LoadingSpinner'
import AuditLogEntry, { ChangeActionType } from '../../types/AuditLogEntry'
import PatientsApi from '../../services/PatientsApi'
import { StudyPatient, Session } from '../../types/cptMonitoringTypes'
import NavUtils from '../../utils/NavUtils'
import MonthPicker from '../../components/forms/MonthPicker'
import Patient from '../../types/Patient'
import { handleZoomIn, handleZoomOut } from './utils'
import { downloadFile } from '../../utils/DownloadUtils'
import { truncate, abbreviate } from '../../utils/StringUtils'
import { MAX_ZOOM_SCALE, MIN_ZOOM_SCALE } from './constants'
import { Scope } from './types'

interface Props {
    match: any
}

enum View {
    Block,
    List,
}

const CPTUserMonitoring: React.FC<Props> = props => {
    const wrapper = useRef<HTMLDivElement | null>(null)
    const mountedAt = useMemo(moment, [])
    const [msPerPx, setMsPerPx] = useState(100000)
    const [scope, setScope] = useState<Scope>({
        start: mountedAt.clone().startOf('day'),
        end: mountedAt.clone().startOf('day').add(24, 'hours'),
    })
    const [logEntries, setLogEntries] = useState<AuditLogEntry[] | undefined>()
    const [studyPatients, setStudyPatients] = useState<StudyPatient[] | undefined>()
    const [user, setUser] = useState<User | undefined>()
    const [view, setView] = useState(View.Block)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)

    useEffect(() => {
        NavUtils.setPageTitle('User Monitoring History')
    }, [])

    const userId = props.match.params.id
    useEffect(() => {
        setUser(undefined)
        UsersApi.getUserForCPTMonitoring(userId)
            .then(res => {
                setUser(res.data)
            })
            .catch(err => {
                console.log(err)
                ToastUtils.error({ message: 'Unable to get user' })
            })
    }, [userId])

    useEffect(() => {
        setLogEntries(undefined)
        const start = scope.start.clone()
        const end = scope.end.clone()
        if (view === View.Block) {
            start.subtract(96, 'hours')
            end.add(96, 'hours')
        }
        AuditLogsApi.getAuditLogsForUser(userId, start, end).then(res => {
            const actionTypes = [ChangeActionType.StartMonitoring, ChangeActionType.StopMonitoring]
            const logEntries = res.data.filter(e => actionTypes.includes(e.ActionTypeId))
            setLogEntries(logEntries)
        }).catch(err => {
            console.log(err)
            ToastUtils.error({ message: 'Unable to get monitoring logs' })
        })
    }, [userId, scope, view])

    useEffect(() => {
        setStudyPatients(undefined)
        if (logEntries) {
            const studyIds: string[] = []
            for (const entry of logEntries) {
                if (!studyIds.includes(entry.ItemId)) {
                    studyIds.push(entry.ItemId)
                }
            }
            if (studyIds.length > 0) {
                PatientsApi.getPatients(studyIds).then(res => {
                    setStudyPatients(res.data)
                }).catch(err => {
                    console.log(err)
                    ToastUtils.error({ message: 'Unable to get study patients' })
                })
            } else {
                setStudyPatients([])
            }
        }
    }, [logEntries])

    const handleUseListView = () => {
        setView(View.List)
        const startOfMonth = mountedAt.clone().startOf('month')
        setScope({
            start: startOfMonth,
            end: startOfMonth.clone().endOf('month'),
        })
    }

    const handleUseVisualView = () => {
        setView(View.Block)
        const start = mountedAt.clone().startOf('day')
        setScope({
            start,
            end: start.clone().add(24, 'hours'),
        })
    }

    const renderSessionInfo = (session: Session<Patient>) => {
        const getPatientName = () => {
            const patient = session.Info
            if (!patient) return ''
            let name = `${patient.LastName}, ${patient.FirstName}`
            if (patient.MiddleName) {
                name += ` ${patient.MiddleName}`
            }
            return name
        }

        const getSessionTimeRange = () => {
            const timeFormatString = 'h:mm a'
            const dateFormatString = 'MMM D'
            let started = ''
            if (session.Started.isSameOrAfter(scope.start)) {
                started = session.Started.format(timeFormatString)
            } else {
                started = session.Started.format(dateFormatString)
            }

            let stopped = 'now'
            if (session.Stopped) {
                if (session.Stopped.isAfter(scope.end)) {
                    stopped = session.Stopped.format(dateFormatString)
                } else {
                    stopped = session.Stopped.format(timeFormatString)
                }
            }

            return `${started} - ${stopped}`
        }

        const patientName = getPatientName()
        const dob = moment(session.Info?.DateOfBirth).format('MMM D, YYYY')
        const timeRange = getSessionTimeRange()

        return session.Info ? (
            <div className="info">
                <p title={patientName}>{patientName}</p>
                <p title={dob}>DOB: {dob}</p>
                <p title={session.Info.PatientID || ''}>
                    ID: {session.Info.PatientID}
                </p>
                <p title={timeRange}>{timeRange}</p>
            </div>
        ) : <div />
    }

    const handleDownload = () => {
        if (!logEntries || !studyPatients || !user) return
        const sessions = CPTMonitoringService.getPatientSessions(logEntries, studyPatients, scope.start, scope.end)
        const f = CPTMonitoringService.getUserCSVReport(user, studyPatients, sessions)
        downloadFile(f)
    }

    return (
        <div className="animated fadeIn cpt-user-monitoring">
            <div className="row">
                <section className="col-lg-12">
                    <div className="action-icons">
                        {view === View.List ? (
                            <>
                                <i
                                    title="Block view"
                                    className="icon icon-electrotek-th-list mr-3"
                                    onClick={handleUseVisualView}
                                />
                                <MonthPicker
                                    inputProps={{
                                        readOnly: true,
                                        onClick: () => setIsCalendarOpen(i => !i),
                                    }}
                                    date={scope.start.clone().startOf('month')}
                                    onSelect={m => {
                                        setScope({
                                            start: m,
                                            end: m.clone().endOf('month'),
                                        })
                                        setIsCalendarOpen(false)
                                    }}
                                    open={isCalendarOpen}
                                />
                                <i
                                    title="Select date"
                                    className="calendar-icon icon-electrotek-calendar"
                                    onClick={() => setIsCalendarOpen(i => !i)}
                                />
                            </>
                        ) : (
                            <>
                                <i
                                    title="List view"
                                    className="icon icon-electrotek-th-list mr-3"
                                    onClick={handleUseListView}
                                />
                                <i
                                    title="Zoom in"
                                    className={`icon-electrotek-zoom-in ${msPerPx <= MIN_ZOOM_SCALE ? 'disabled' : ''}`}
                                    onClick={() => handleZoomIn(MIN_ZOOM_SCALE, msPerPx, wrapper, setMsPerPx)}
                                />
                                <i
                                    title="Zoom out"
                                    className={`icon-electrotek-zoom-out ${msPerPx >= MAX_ZOOM_SCALE ? 'disabled' : ''}`}
                                    onClick={() => handleZoomOut(MAX_ZOOM_SCALE, msPerPx, wrapper, setMsPerPx)}
                                />
                                <DateTime
                                    inputProps={{
                                        readOnly: true,
                                        onClick: () => setIsCalendarOpen(i => !i),
                                    }}
                                    value={scope.start}
                                    timeFormat={false}
                                    dateFormat="MMMM D, YYYY"
                                    onChange={m => {
                                        if (typeof m === 'string') {
                                            m = moment(m)
                                        }
                                        setScope({
                                            start: m,
                                            end: m.clone().add(24, 'hours'),
                                        })
                                        setIsCalendarOpen(false)
                                    }}
                                    open={isCalendarOpen}
                                    isValidDate={(m: Moment) => moment().isAfter(m)}
                                />
                                <i
                                    title="Select date"
                                    className="calendar-icon icon-electrotek-calendar"
                                    onClick={() => setIsCalendarOpen(i => !i)}
                                />
                            </>
                        )}
                        {logEntries && studyPatients && user && (
                            <i
                                title="Download report"
                                className="download-icon fas fa-cloud-download-alt"
                                onClick={handleDownload}
                            />
                        )}
                    </div>
                    <h3 title={user ? `${user.LastName}, ${user.FirstName}` : ''}>
                        <i
                            className="icon-electrotek-monitoring mr-3"
                            style={{ position: 'relative', top: '3px' }}
                        />
                        Monitoring History{user ? ` - ${truncate(user.LastName, 12)}, ${abbreviate(user.FirstName)}` : ''}
                        <hr />
                    </h3>
                    {(!logEntries || !studyPatients) ? <LoadingSpinner /> : (
                        <>
                            {view === View.Block && (
                                <CPTMonitoringBlockView
                                    wrapper={wrapper}
                                    scope={scope}
                                    msPerPx={msPerPx}
                                    sessions={CPTMonitoringService.getPatientSessions(logEntries, studyPatients, scope.start, scope.end)}
                                    user={user}
                                    renderSessionInfo={renderSessionInfo}
                                />
                            )}
                            {view === View.List && (
                                <CPTMonitoringListView
                                    logEntries={logEntries}
                                    studyPatients={studyPatients}
                                />
                            )}
                        </>
                    )}
                </section>
            </div>
        </div>
    )
}

export default CPTUserMonitoring
