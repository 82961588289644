// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

class CustomSettingsChange {
    constructor(changeId, filterSettings, montageTemplateId) {
        this.changeId = changeId
        this.filterSettings = filterSettings
        this.montageTemplateId = montageTemplateId
    }
}

export default CustomSettingsChange
