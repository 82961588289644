import React from 'react'
import AppContext from '../../../../components/AppContext'

interface Props {
    packetCount: number
    minutesRequestedIndices: number[]
}

const ScrubberRequestedData: React.FC<Props> = props => {
    const context = React.useContext(AppContext)

    const packetsPerMinute = context.Study.StudyDataRate * 60
    const minuteWidth = packetsPerMinute / props.packetCount
    const minuteWidthPercent = Math.ceil(10000 * minuteWidth) / 100

    return (
        <>
            {props.minutesRequestedIndices.map(index => (
                <div
                    className="section requested"
                    key={index}
                    style={{
                        left: `${(100 * index) / props.packetCount}%`,
                        width: `${minuteWidthPercent}%`,
                    }}
                />
            ))}
        </>
    )
}

export default ScrubberRequestedData
