import './ChannelArrow.scss'
import { ELECTRODE_POSITIONS, HEAD_HEIGHT_RATIO } from '../../components/head/constants'

interface Props {
    id: string
    formula: string // `Cz-Pz` or `Cz-134,229`
    headWidth: number
    electrodeWidth: number
    editable: boolean
    selected: boolean
    onClick: () => void
    className?: string
}

function ChannelArrow(props: Props) {
    const [start, end] = props.formula.split('-')

    const headHeight = props.headWidth * HEAD_HEIGHT_RATIO
    const arrowheadSize = props.headWidth / 100

    let x1 = ELECTRODE_POSITIONS[start].left * props.headWidth
    let y1 = ELECTRODE_POSITIONS[start].top * headHeight
    let x2 = 0
    let y2 = 0
    const isTipElectrode = ELECTRODE_POSITIONS[end] !== undefined
    if (isTipElectrode) {
        x2 = ELECTRODE_POSITIONS[end].left * props.headWidth
        y2 = ELECTRODE_POSITIONS[end].top * headHeight
    } else {
        const [x, y] = end.split(',')
        x2 = parseFloat(x)
        y2 = parseFloat(y)
    }
    const xMid = (x1 + x2) / 2
    const yMid = (y1 + y2) / 2

    // Shorten arrows so that they don't go inside electrode
    if (isTipElectrode) {
        const deltaX = x2 - x1
        const deltaY = y2 - y1
        const ang = Math.atan2(deltaY, deltaX)
        const yOffset = Math.sin(ang) * props.electrodeWidth / 2
        const xOffset = Math.cos(ang) * props.electrodeWidth / 2
        x1 += xOffset
        y1 += yOffset
        x2 -= xOffset
        y2 -= yOffset
    }

    const shouldShowArrowHead = (): boolean => {
        if (isTipElectrode) return true
        const arrowLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
        return arrowLength > arrowheadSize * 5
    }

    return (
        <g
            onClick={props.onClick}
            style={{ cursor: props.editable ? 'pointer' : undefined }}
            className={`channel-arrow ${props.selected ? 'selected' : ''} ${props.className ?? ''}`}
        >
            <defs>
                <marker
                    id={`arrowhead-${props.id}`}
                    markerWidth={arrowheadSize}
                    markerHeight={arrowheadSize}
                    refX={arrowheadSize / 2}
                    refY={arrowheadSize / 2}
                    orient="auto"
                >
                    <polygon
                        className="marker"
                        points={`0 0, ${arrowheadSize} ${arrowheadSize / 2}, 0 ${arrowheadSize}`}
                    />
                </marker>
            </defs>
            <line
                className="colored"
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                strokeWidth={3}
            />
            <line /* this thicker line is just for hovering and selecting */
                x1={x1}
                y1={y1}
                x2={x2}
                y2={y2}
                stroke="transparent"
                strokeWidth={16}
            />
            <line
                className="colored"
                x1={x1}
                y1={y1}
                x2={xMid}
                y2={yMid}
                strokeWidth={3}
                markerEnd={shouldShowArrowHead() ? `url(#arrowhead-${props.id})` : undefined}
            />
        </g>
    )
}

export default ChannelArrow
