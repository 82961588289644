import React from 'react'
import './VideoPlayer.scss'
import VideoApi from '../../services/VideoApi'
import DateTimeUtils from '../../utils/DateTimeUtils'
import SortUtils from '../../utils/SortUtils'
import { VIDEO_START, VIDEO_SYNC } from '../../constants/studyevents'
import ClockSetting from '../../types/ClockSetting'
import AppContext from '../../components/AppContext'
import GraphUtilities from './utils/GraphUtilities'

// const REVERSE_PLAYBACK_FRAME_RATE = 10

class VideoPlayer extends React.PureComponent {
    static contextType = AppContext

    constructor(props) {
        super(props)
        this.playVideoTimeout = null
        this.currentVideoPlayer = null
        this.upnextVideoPlayer = null
        this.currentVideoEvent = null
        this.upnextVideoEvent = null
        this.isPlaySpeedRealTime = this.props.playSpeed===0

        // flags
        this.keepPIPOpen = false
        this.keepGraphPlaying = false

        this.state = {
            size: 'normal',
            position: 'bottom-right',
            events: [],
            has_error: false,
            has_video_error_p1: false,
            has_video_error_p2: false,
            first_video_start_event: null,
            videoP1_style: '',
            videoP2_style: 'hidden',
            video_ended: false,
            is_pip: false,
        }
    }

    async componentDidMount() {
        await this.initialize()
        this.checkVideoToPlayCursorSync()
    }

    async initialize() {
        const events = this.props.events.filter(e => [VIDEO_START,VIDEO_SYNC].includes(e.EventTypeID))

        if (events.length > 1) {
            SortUtils.digits(events, 'StartTimeRelativeToStudy', 'asc')
        }

        this.currentVideoPlayer = this.videoP1
        this.upnextVideoPlayer = this.videoP2

        this.setState({
            events,
            first_video_start_event: events[0],
        })
    }

    checkVideoSources(packetIndex) {
        // #region Variable Declarations
        const isGraphPlaying = this.props.isPlaying && this.isPlaySpeedRealTime

        let newEvents = this.getNearestStartVideoEvents(packetIndex)

        if (newEvents.current === null && newEvents.next !== null) {
            newEvents = {
                current: newEvents.next,
                next: newEvents.nextnext || null,
            }
        }

        const hasNewCurrentEvent = !!newEvents.current
        const hasExistingCurrentEvent = !!this.currentVideoEvent
        const hasNewNextEvent = !!newEvents.next
        const hasExistingNextPlayerEvent = !!this.upnextVideoEvent

        const isNewCurrentEventDifferent =
            !hasExistingCurrentEvent ||
            (hasNewCurrentEvent &&
                hasExistingCurrentEvent &&
                newEvents.current.ID !== this.currentVideoEvent.ID)
        const isNewUpnextEventDifferent =
            !hasExistingNextPlayerEvent ||
            (newEvents.next && newEvents.next.ID !== this.upnextVideoEvent.ID)
        const isNewCurrentEventSameAsExistingNextEvent =
            newEvents.current &&
            this.upnextVideoEvent &&
            newEvents.current.ID === this.upnextVideoEvent.ID

        const isPlayerSwitchNeeded =
            hasNewCurrentEvent &&
            hasExistingNextPlayerEvent &&
            isNewCurrentEventSameAsExistingNextEvent

        const shouldUpdateCurrent =
            !isPlayerSwitchNeeded &&
            hasNewCurrentEvent &&
            isNewCurrentEventDifferent

        const shouldUpdateNext =
            !isPlayerSwitchNeeded &&
            hasNewNextEvent &&
            isNewUpnextEventDifferent

        // #endregion

        if (isPlayerSwitchNeeded) {
            this.setState({ has_error: false }, () => {
                this.swapVideoPlayers()
                const videoTime = Math.max(0,this.getVideoTimeBasedOnPlayCursor())
                this.currentVideoPlayer.currentTime = videoTime
                if (isGraphPlaying) {
                    this.playCurrentVideo()
                }
                newEvents.nextnext &&
                    this.setVideoSrc(
                        this.upnextVideoPlayer,
                        newEvents.next,
                        'next',
                    )
            })
        } else if (shouldUpdateCurrent) {
            this.setVideoSrc(
                this.currentVideoPlayer,
                newEvents.current,
                'current',
            )
        }

        shouldUpdateNext &&
            this.setVideoSrc(this.upnextVideoPlayer, newEvents.next, 'next')
    }

    requestPIP = async () => {
        this.setState({ is_pip: true })
        if (this.currentVideoPlayer.readyState < 1) {
            await new Promise(r => {
                this.currentVideoPlayer.onloadedmetadata = () => r()
            })
        }
        await this.currentVideoPlayer.requestPictureInPicture()
        this.currentVideoPlayer.addEventListener('leavepictureinpicture', () => {
            this.setState({ is_pip: this.keepPIPOpen })
        })
        this.keepPIPOpen = false
    }

    setVideoSrc(videoPlayer, startVideoEvent, eventIsCurrentOrNext) {
        const isInitialized = !!startVideoEvent && !!videoPlayer
        if (!isInitialized) {
            console.log('[VIDEO] setVideoSrc() called before initialization complete')
            return
        }
 
        const isCurrent = videoPlayer.id === this.currentVideoPlayer.id ? ' (Current)' : ''
        const isGraphPlaying = this.props.isPlaying && this.isPlaySpeedRealTime

        eventIsCurrentOrNext === 'current' && this.setState({ has_error: false })

        videoPlayer.src = VideoApi.getStudyVideoSrc(
            startVideoEvent.StudyID,
            startVideoEvent.RecordingIndex,
            this.getCameraIndexFromEvent(startVideoEvent),
            this.getVideoIndexFromEvent(startVideoEvent),
        )
        console.log(`[VIDEO] video source set for ${videoPlayer.id} ${isCurrent}`, videoPlayer.src)

        switch (eventIsCurrentOrNext) {
            case 'current':
                this.currentVideoEvent = startVideoEvent
                isGraphPlaying && this.playCurrentVideo()
                break
            case 'next':
                this.upnextVideoEvent = startVideoEvent
                break
            default:
                console.log('[VIDEO][ERROR] Invalid parameter sent to setVideoSrc()')
        }
        console.log(`[VIDEO] ${eventIsCurrentOrNext} video event updated`, startVideoEvent)
    }

    // swap voodoo see :
    // https://stackoverflow.com/questions/16151682/swap-two-objects-in-javascript
    swapVoodoo = x => x

    swapVideoPlayers = async () => {
        this.upnextVideoPlayer = this.swapVoodoo(
            this.currentVideoPlayer,
            (this.currentVideoPlayer = this.upnextVideoPlayer),
        )
        const doesPlayerHaveError =
            (this.currentVideoPlayer.id === 'P1' && this.state.has_video_error_p1) ||
            (this.currentVideoPlayer.id === 'P2' && this.state.has_video_error_p2)
        if (doesPlayerHaveError && document.pictureInPictureElement) {
            await document.exitPictureInPicture()
            this.setState({ is_pip: false })
        }
        if (this.state.is_pip) {
            this.keepPIPOpen = true
            this.requestPIP()
        }

        this.upnextVideoEvent = this.swapVoodoo(
            this.currentVideoEvent,
            (this.currentVideoEvent = this.upnextVideoEvent),
        )

        this.upnextVideoPlayer.pause()
        this.upnextVideoEvent = null

        this.setState({
            videoP1_style: this.state.videoP2_style,
            videoP2_style: this.state.videoP1_style,
            has_error: doesPlayerHaveError,
        })
        console.log(`[VIDEO] swapping video player to ${this.currentVideoPlayer.id}`)
        this.clearVideoEnded()
    }

    // This can return 0, 1, or 2 StudyEvents
    getVideoStartEventsForEvent(importantEvent) { // => StudyEvent[]
        const importantVideoEvents = []

        const reverseOrderEvents = [...this.state.events].reverse()
        const precedingVideoStartEvent = reverseOrderEvents.find(e => {
            if (e.EventTypeID !== VIDEO_START) {
                return false
            }
            return e.StartPacketIndex <= importantEvent.StartPacketIndex
        })
        if (precedingVideoStartEvent) {
            importantVideoEvents.push(precedingVideoStartEvent)
        }

        if (importantEvent.EndPacketIndex) {
            const nextVideoStartEvent = this.state.events.find(e => {
                if (e.EventTypeID !== VIDEO_START) {
                    return false
                }
                if (e.StartPacketIndex > importantEvent.StartPacketIndex) {
                    return false
                }
                return e.StartPacketIndex <= importantEvent.EndPacketIndex
            })
            if (nextVideoStartEvent) {
                importantVideoEvents.push(nextVideoStartEvent)
            }
        }

        return importantVideoEvents
    }

    getImportantEvents() {
        return this.props.events.filter(e => e.Important)
    }

    getImportantVideoStartEvents() {
        const importantEvents = this.getImportantEvents()
        const importantVideoEvents = []
        for (const importantEvent of importantEvents) {
            const importantVideoStartEvents = this.getVideoStartEventsForEvent(importantEvent)
            for (const importantVideoStartEvent of importantVideoStartEvents) {
                if (!importantVideoEvents.find(e => e.ID === importantVideoStartEvent.ID)) {
                    importantVideoEvents.push(importantVideoStartEvent)
                }
            }
        }
        return importantVideoEvents
    }

    getNearestStartVideoEvents(packetIndex) {
        const firstVideoStartEvent = this.state.first_video_start_event
        let currentPlayingVideoEvent = null
        let upNextVideoEvent = null
        let upNextNextVideoEvent = null

        if (!this.state.events || this.state.events.length === 0) {
            return null
        }

        let startEvents = this.state.events.filter(e => e.EventTypeID === VIDEO_START)
        if (this.props.isFocusedReview) {
            startEvents = this.getImportantVideoStartEvents()
        }

        SortUtils.number(startEvents, 'StartTimeRelativeToStudy', 'asc')

        const hasOnlyOneVideo = startEvents.length === 1
        const timeBasedOnIndex = this.getTimeBasedOnIndex(packetIndex)

        const isIndexBeforeAllVideos =
            firstVideoStartEvent.StartTimeRelativeToStudy > timeBasedOnIndex

        if (isIndexBeforeAllVideos) {
            upNextVideoEvent = startEvents[0]
            return { current: currentPlayingVideoEvent, next: upNextVideoEvent }
        }

        if (hasOnlyOneVideo) {
            currentPlayingVideoEvent = startEvents[0]
            return { current: currentPlayingVideoEvent, next: upNextVideoEvent }
        }

        const indexTime = this.props.playCursorIndex / this.props.sampleRate

        // requires events are already sorted by packet index
        startEvents.forEach((videoEvent, index) => {
            if (indexTime >= videoEvent.StartTimeRelativeToStudy) {
                currentPlayingVideoEvent = videoEvent
                const hasNextVideo = startEvents.length > index + 1
                if (hasNextVideo) {
                    upNextVideoEvent = startEvents[index + 1]
                    const hasNextNextVideo = startEvents.length > index + 2
                    if (hasNextNextVideo) {
                        upNextNextVideoEvent = startEvents[index + 2]
                    } else {
                        upNextNextVideoEvent = null
                    }
                } else {
                    upNextVideoEvent = null
                    upNextNextVideoEvent = null
                }
            }
        })

        return {
            current: currentPlayingVideoEvent,
            next: upNextVideoEvent,
            nextnext: upNextNextVideoEvent,
        }
    }

    getCameraIndexFromEvent(event) {
        const defaultValue = 1
        const videoInfo = this.getJsonObjFromEventValue(event)
        return videoInfo ? videoInfo.CameraIndex : defaultValue
    }

    getVideoIndexFromEvent(event) {
        const defaultValue = 1
        const videoInfo = this.getJsonObjFromEventValue(event)
        return videoInfo ? videoInfo.VideoIndex : defaultValue
    }

    getJsonObjFromEventValue(event) {
        let obj = {}
        if (event.Value === '') {
            return null
        }

        try {
            obj = JSON.parse(event.Value)
        } catch (err) {
            console.log(
                `[VIDEO] Invalid json in event.value : ${err.message}`,
                event,
            )
            return null
        }

        return obj
    }

    async componentWillUnmount() {
        clearTimeout(this.playVideoTimeout)
        if (document.pictureInPictureElement) {
            await document.exitPictureInPicture()
        }
    }

    async componentDidUpdate(prevProps) {

        const isNewCursorIndex = prevProps.playCursorIndex !== this.props.playCursorIndex
        const isNewPlayingProp = prevProps.isPlaying !== this.props.isPlaying
        const isNewplaySpeed = prevProps.playSpeed !== this.props.playSpeed

        const isVideoWindowHidden = !this.props.isVisible
        const isNewWindowHiddenState = prevProps.isVisible !== this.props.isVisible
        const isNewFocusState = prevProps.isFocusedReview !== this.props.isFocusedReview
        const didPlayspeedLeaveRealtime = this.props.playSpeed > 0 && prevProps.playSpeed === 0
        const isPIPActive = !!document.pictureInPictureElement
        const shouldExitPIP = didPlayspeedLeaveRealtime && isPIPActive

        this.updateVideoMessage()

        shouldExitPIP &&  (await document.exitPictureInPicture())
            
        if (isVideoWindowHidden) {
            if (this.currentVideoPlayer) {
                this.keepGraphPlaying = true
                this.currentVideoPlayer.pause()
            }
            
            if (isPIPActive) {
                await document.exitPictureInPicture()
                if (this.props.isPlaying) {
                    this.props.onPlay()
                }
            }
            return
        }

        isNewFocusState &&  this.checkVideoSources(this.props.playCursorIndex)
        isNewCursorIndex && this.checkVideoToPlayCursorSync()
        isNewWindowHiddenState && this.checkVideoToPlayCursorSync()
        isNewPlayingProp && this.handleNewPlayingProp()
        isNewplaySpeed && this.handleNewplaySpeed() 
    }

    getFirstVideoEvent = () => (this.props.events ? this.props.events[0] : null)

    getVideoTimeBasedOnPlayCursor = () => {
        if (this.state.events.length > 0) {
            let nearestVidSyncPoint = this.state.events[0]
            for (const ev of this.state.events) {
                if (
                    ev.EventTypeID === VIDEO_START &&
                    ev.StartPacketIndex > this.props.playCursorIndex
                )
                    continue
                const distanceToEv = Math.abs(
                    ev.StartPacketIndex - this.props.playCursorIndex,
                )
                const distanceToNearestEv = Math.abs(
                    nearestVidSyncPoint.StartPacketIndex -
                    this.props.playCursorIndex,
                )
                if (
                    distanceToEv < distanceToNearestEv &&
                    ev.RecordingIndex === this.currentVideoEvent.RecordingIndex
                ) {
                    nearestVidSyncPoint = ev
                }
            }
            if (nearestVidSyncPoint.EventTypeID === VIDEO_SYNC) {
                const vidTimeAtSyncEvent =
                    JSON.parse(nearestVidSyncPoint.Value).VideoMs / 1000
                const syncEventTimeFromNow =
                    (this.props.playCursorIndex -
                        this.props.calculateSampleIndex(
                            nearestVidSyncPoint.RecordingIndex,
                            nearestVidSyncPoint.StartPacketIndex,
                        )) /
                    this.props.sampleRate
                return vidTimeAtSyncEvent + syncEventTimeFromNow
            }
        }
        return this.getVideoTimeBasedOnPacketIndex(
            this.props.playCursorIndex,
            this.currentVideoEvent.RecordingIndex,
            this.currentVideoEvent.StartPacketIndex,
        )
    }

    updateVideoMessage() {
        let msg = ''
        if (this.state.is_pip) {
            if (this.shouldShowCoverStartsAt()) {
                msg = `Starts ${this.videoCoverStartsAtTimestamp()}`
            } else if (this.state.video_ended) {
                msg = 'Ended'
            } else if (this.state.has_error) {
                msg = 'Segment Unavailable'
            }
        }
        if (this.props.videoMessage !== msg) {
            this.props.onSetVideoMessage(msg)
        }
    }

    getVideoTimeBasedOnPacketIndex(
        index,
        recordingIndex,
        recordingStartPacketIndex,
    ) {
        const videoOffset = this.props.calculateSampleIndex(
            recordingIndex,
            recordingStartPacketIndex,
        )
        const videoTimeIndex = index - videoOffset
        const time = videoTimeIndex / this.props.sampleRate
        return Math.max(0, time)
    }

    getTimeBasedOnIndex(index) {
        return index / this.props.sampleRate
    }

    videoPlayCheck(graphIndex) {
        // if the graph isn't playing then we don't need to check
        // to ensure video is playing
        if (!this.props.isPlaying) {
            return
        }
        const isVideoPaused = this.currentVideoPlayer.paused
        const isVideoEnded = this.currentVideoPlayer.ended || this.state.video_ended

        const timeAtIndex = this.getTimeBasedOnIndex(graphIndex)
        const firstStartVideoEventTime = this.state.first_video_start_event
            .StartTimeRelativeToStudy
        const isPastFirstStartEvent = timeAtIndex > firstStartVideoEventTime

        const shouldPlayVideo =
            isPastFirstStartEvent && isVideoPaused && !isVideoEnded

        shouldPlayVideo && this.playCurrentVideo()
    }

    checkVideoToPlayCursorSync() {
        console.log('[VIDEO] checking video to play cursor synchronization')
        
        this.checkVideoSources(this.props.playCursorIndex) // must be done first
        this.mediaLoadingCursorIndex=0

        const cursorVideoTime = Math.max(0, this.getVideoTimeBasedOnPlayCursor())
        const isVideoDurationValid = !Number.isNaN(this.currentVideoPlayer.duration)
        const hasCursorPassedVideoLength = cursorVideoTime > this.currentVideoPlayer.duration
        const isGraphPaused = !this.props.isPlaying
        const isCursorPastStart = this.props.playCursorIndex>0
        const showingVideoEnded = this.state.video_ended
        const videoDurationFormatted = isVideoDurationValid ? this.formattedTime(this.currentVideoPlayer.duration) : '??:??'
        const cursorVideoTimeFormatted = cursorVideoTime>0 ? this.formattedTime(cursorVideoTime) : 'beginning'
        const currentVideoEventValue = this.currentVideoEvent ? this.getJsonObjFromEventValue(this.currentVideoEvent) : '?'

        if (!isVideoDurationValid && isCursorPastStart && isGraphPaused) {
            console.log(`[VIDEO] Request meta loaded callback for ${this.currentVideoPlayer.id}`)
            this.mediaLoadingCursorIndex = this.props.playCursorIndex
            return
        }

        if (!showingVideoEnded && !hasCursorPassedVideoLength){
            this.currentVideoPlayer.currentTime = cursorVideoTime
            console.log(`[VIDEO] video ${currentVideoEventValue?.VideoIndex} set to ${cursorVideoTimeFormatted} of ${videoDurationFormatted} (min:sec)`)
        } 

        this.isPlaySpeedRealTime && this.videoPlayCheck(this.props.playCursorIndex)

        if (isVideoDurationValid) {
            if (hasCursorPassedVideoLength) {
                !showingVideoEnded && this.setVideoEnded()
            } else {
                showingVideoEnded && this.clearVideoEnded()
            }
        }


    }

    handlePause = playerId => {
        console.log(`[VIDEO] playback for player ${playerId} paused`)
        if (this.currentVideoPlayer.id === playerId && document.pictureInPictureElement) {
            if (this.currentVideoPlayer.duration > this.currentVideoPlayer.currentTime) {
                if (!this.keepGraphPlaying) {
                    this.props.onPause()
                }
                this.keepGraphPlaying = false
            }
            return
        }
        if (this.props.isPlaying && this.props.isVisible && this.isPlaySpeedRealTime && !this.currentVideoPlayer.ended) {
            this.playCurrentVideo()
        }
    }

    handlePlay = (e) => { 
        console.log(`[VIDEO] ${e} video element play event fired`)
        if (this.props.isVisible && !this.state.video_ended) {
            this.props.onPlay()
        }
    }

    handleLoadedMetaData = (playerId) => {
        if (this.mediaLoadingCursorIndex>0 && this.mediaLoadingCursorIndex === this.props.playCursorIndex && this.currentVideoPlayer.id === `P${playerId}`) {
            console.log(`[VIDEO] P${playerId} meta resync executed`)
            this.checkVideoToPlayCursorSync()
            this.mediaLoadingCursorIndex=0
        }
    }

    handleNewPlayingProp() {
        switch (this.props.isPlaying) {
            case true:
                this.isPlaySpeedRealTime &&
                    this.videoPlayCheck(this.props.playCursorIndex)
                break

            case false:
                this.currentVideoPlayer.pause()
                break

            default:
                console.log('[VIDEO] Invalid isPlaying prop value encountered')
                break
        }
    }

    handleNewplaySpeed() {
        console.log('[VIDEO] handling new play speed')
        this.isPlaySpeedRealTime = this.props.playSpeed===0

        !this.isPlaySpeedRealTime &&
            !this.currentVideoPlayer.paused &&
            this.currentVideoPlayer.pause()

        this.isPlaySpeedRealTime &&
            this.props.isPlaying &&
            this.playCurrentVideo()
    }

    pauseVideoCheck() {
        if (!this.props.isVisible) {
            this.currentVideoPlayer.pause()
            return
        }

        if (this.isPlaySpeedRealTime) {
            return
        }

        this.currentVideoPlayer.pause()
        const currentVideoTime = this.getVideoTimeBasedOnPlayCursor()
        this.currentVideoPlayer.currentTime = Math.max(0, currentVideoTime)
    }

    playCurrentVideo() {
        console.log('[VIDEO] play() called on current video element')
        this.currentVideoPlayer.play().catch(() => { })
    }

    handleVideoError = async err => {
        console.log(`[VIDEO] Error on ${err.target.id}:`, err.target.error)
        switch (err.target.id) {
            case 'P1':
                this.setState({ has_video_error_p1: true })
                break

            case 'P2':
                this.setState({ has_video_error_p2: true })
                break

            default:
                break
        }
        const shouldShowNotAvailable = err.target.id === this.currentVideoPlayer.id
        if (shouldShowNotAvailable) {
            this.setState({ has_error: true })
            if (document.pictureInPictureElement) {
                await document.exitPictureInPicture()
                this.setState({ is_pip: false })
            }
        }
    }

    handleSetPosition = position => this.setState({ position })

    toggleSize = () => {
        if (this.state.size === 'normal') {
            this.setState({ size: 'large' })
        } else {
            this.setState({ size: 'normal' })
        }
    }

    getRelocateIcon = position =>
        `arrow-${position.replace('top', 'up').replace('bottom', 'down')}`

    getResizeIcon = () =>
        this.state.size === 'normal' ? 'plus-circle' : 'minus-circle'

    getButtonTitle = position => {
        if (this.state.position === position) {
            if (this.state.size === 'normal') {
                return 'Enlarge video'
            }
            return 'Reduce video size'
        }
        return `Move video to ${position.replace('-', ' ')}`
    }

    setVideoEnded(player) {
        const playerEventText = !!player ? `(source : player ${player} event)` : '(source : programatic)'
        if (!this.state.video_ended) {
            this.setState({ video_ended: true }, () => console.log(`[VIDEO] video ended ${playerEventText}`))
            this.currentVideoPlayer.pause()
        }
    }

    clearVideoEnded = () =>
        this.state.video_ended && this.setState({ video_ended: false })

    videoCoverStartsAtTimestamp = () => {
        const mom = GraphUtilities.getMomentByTimeRelativeToStudy(this.context.Study, this.state.first_video_start_event.StartTimeRelativeToStudy)
        let timeDisplay = ''
        switch (this.props.clockSetting) {
            case ClockSetting.Duration:
                timeDisplay = DateTimeUtils.getStudyAltDuration(this.state.first_video_start_event.StartTimeRelativeToStudy)
                break
            case ClockSetting.TwelveHour:
                timeDisplay = mom.format('h:mm:ss A')
                break
            default:
                timeDisplay = mom.format('HH:mm:ss')
        }
        return timeDisplay
    }

    videoCoverStartsAt = () => {
        return (
            <div className="video-cover">
                <span className="message">
                    Video starts at {this.videoCoverStartsAtTimestamp()}
                </span>
            </div>
        )
    }

    videoCoverEnded = () => (
        <div className="video-cover-ended">
            <span className="message">video ended</span>
        </div>
    )

    videoCoverError = () => (
        <div className="video-cover error">
            <span className="message">Video segment unavailable</span>
        </div>
    )

    shouldShowCoverStartsAt = () => (
        !this.state.has_error &&
        this.state.first_video_start_event &&
        this.props.playCursorIndex <
        this.props.calculateSampleIndex(this.state.first_video_start_event.RecordingIndex, this.state.first_video_start_event.StartPacketIndex)
    )

    formattedTime(seconds) {
        return `${Math.trunc(seconds/60)}:${Math.trunc(seconds%60)}`
    }

    render() {
        const videoP1Style = this.state.videoP1_style
        const videoP2Style = this.state.videoP2_style
        const shouldShowCoverStartsAt = this.shouldShowCoverStartsAt()
        const shouldShowCoverEnded = !shouldShowCoverStartsAt && this.state.video_ended
        const shouldShowCoverError = this.state.has_error

        return (
            <div className="d-none d-sm-block video-player">
                <div
                    style={{ display: this.props.visible ? '' : 'none' }}
                    className={`
                        video-container
                        video-container-${this.state.position}
                        video-container-${this.state.size}
                        ${this.props.isVisible ? '' : 'hidden'}
                        ${this.props.areControlsMinimized ? 'minimized' : ''}
                    `}
                >
                    <video
                        id="P1"
                        className="video"
                        style={{ visibility: this.state.is_pip ? 'hidden' : videoP1Style }}
                        ref={v => (this.videoP1 = v)}
                        onError={e => {
                            this.handleVideoError(e)
                        }}
                        onCanPlayThrough={() => {
                            if (this.currentVideoPlayer.id === 'P1') {
                                this.setState({ has_error: false, has_video_error_p1: false })
                            }
                        }}
                        onPlay={() => this.handlePlay('P1')}
                        onPause={() => this.handlePause('P1')}
                        onEnded={() => this.setVideoEnded(1)}
                        onLoadedMetadata={() => this.handleLoadedMetaData(1)}
                        autoPlay={false} 
                        loop={false}
                    />
                    <video
                        onPlay={()=> this.handlePlay('P2')}
                        id="P2"
                        className="video"
                        style={{ visibility: this.state.is_pip ? 'hidden' : videoP2Style }}
                        ref={v => (this.videoP2 = v)}
                        onError={e => {
                            this.handleVideoError(e)
                        }}
                        onCanPlayThrough={() => {
                            if (this.currentVideoPlayer.id === 'P2') {
                                this.setState({ has_error: false, has_video_error_p2: false })
                            }
                        }}
                        onPause={() => this.handlePause('P2')}
                        onEnded={() => this.setVideoEnded(2)}
                        onLoadedMetadata={() => this.handleLoadedMetaData(2)}
                        loop={false}
                    />
                    {shouldShowCoverStartsAt && !this.state.is_pip && this.videoCoverStartsAt()}
                    {shouldShowCoverEnded && !this.state.is_pip && this.videoCoverEnded()}
                    {shouldShowCoverError && !this.state.is_pip && this.videoCoverError()}
                    {!this.state.is_pip && !this.state.has_error && (
                        <div className="button-container">
                            {this.currentVideoPlayer && this.currentVideoPlayer.requestPictureInPicture && (
                                <button
                                    title="Picture in picture"
                                    type="button"
                                    className={`btn btn-icon ${this.state.position}`}
                                    onClick={this.requestPIP}
                                >
                                    <span
                                        className={`
                                            pip-icon
                                            ${this.state.position.includes('left') ? 'left' : ''}
                                            fas fa-external-link-alt
                                            icon
                                        `}
                                    />
                                </button>
                            )}
                            {[
                                'top-left',
                                'top-right',
                                'bottom-left',
                                'bottom-right',
                            ].map(position => (
                                <button
                                    key={position}
                                    title={this.getButtonTitle(position)}
                                    type="button"
                                    className={`btn btn-icon ${position}`}
                                    onClick={
                                        this.state.position === position
                                            ? this.toggleSize
                                            : () => this.handleSetPosition(position)
                                    }
                                >
                                    <span className={`
                                        icon
                                        icon-electrotek-${this.state.position === position ? this.getResizeIcon() : this.getRelocateIcon(position)}
                                    `} />
                                </button>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default VideoPlayer
