import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    FACILITY_ADMIN_ROLE,
    LEAD_TECH_ROLE,
} from '../constants/roles'

const UserAuth = {
    List: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    Create: [SUPER_ADMIN_ROLE, SUPPORT_ROLE],
    Edit: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE],
    AuditLogsPage: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    AuditLogsCPT: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    AuditLogList: [SUPER_ADMIN_ROLE, SUPPORT_ROLE],
}

export default UserAuth
