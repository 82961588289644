import { useState, useEffect, useMemo, useRef } from 'react'

const PART_SIZE = 5 * 1024 * 1024 // 5 MB
const NETWORKING_ERROR = 'NetworkingError'
const REQUEST_ABORTED_ERROR = 'RequestAbortedError'
const NO_SUCH_UPLOAD = 'NoSuchUpload'
const EXPIRED_TOKEN = 'ExpiredToken'

function transformRecords(records) {
    if (records.length < 2) return []
    return records.slice(1).map((r, i) => {
        const uploadedBytes = r.uploadedBytes - records[i].uploadedBytes
        const ms = r.timestamp - records[i].timestamp
        return {
            timestamp: r.timestamp,
            bytesPerMs: Math.max(0, uploadedBytes / ms),
        }
    })
}

const useS3Uploader = ({ s3, prefix, files, done }) => {
    const [previouslyUploadedBytes, totalUploadBytes] = useMemo(() => {
        let totalBytes = 0
        let alreadyUploaded = 0
        for (let i = 0; i < files.length; i += 1) {
            if (files[i].finished) {
                alreadyUploaded += files[i].size
            }
            totalBytes += files[i].size
        }
        return [alreadyUploaded, totalBytes]
    }, [files])

    const uploadStartedAt = useMemo(() => Date.now(), [])
    const [uploadedBytes, setUploadedBytes] = useState(previouslyUploadedBytes)
    const [records, setRecords] = useState([{ timestamp: Date.now(), uploadedBytes }])
    const transformedRecords = useMemo(() => transformRecords(records), [records])

    const [currentUpload, setCurrentUpload] = useState({
        progress: 0,
        label: '',
        size: 0,
        index: 0,
    })
    const [error, setError] = useState(null)

    useEffect(() => {
        const maxDataPoints = 120
        const hasMinutePassed = Date.now() - records[records.length - 1].timestamp > 60000

        if (hasMinutePassed) {
            setRecords(rs => {
                rs.length > maxDataPoints && rs.shift()
                return ([...rs, { timestamp: Date.now(), uploadedBytes }])
            })
        }
    }, [uploadedBytes, records])

    const calculateMsRemaining = () => {
        const bytesRemaining = Math.max(0, totalUploadBytes - uploadedBytes)
        for (let i = records.length - 1; i >= 0; i--) {
            if (Date.now() - records[i].timestamp >= 5 * 60 * 1000) {
                // Calculation will be based on last 5 minutes noly
                const elapsed = records[records.length - 1].timestamp - records[i].timestamp
                const uploaded = records[records.length - 1].uploadedBytes - records[i].uploadedBytes
                const bytesPerMs = uploaded / elapsed
                return bytesRemaining / bytesPerMs
            }
        }
        // 5 minutes not yet on record, calculation based on entire upload
        const uploadedBytesSinceStart = uploadedBytes - previouslyUploadedBytes
        if (uploadedBytesSinceStart < 1e4) return 0
        const elapsed = Date.now() - uploadStartedAt
        const bytesPerMs = uploadedBytesSinceStart / elapsed
        return bytesRemaining / bytesPerMs
    }

    const totalUploadProgress = Math.min(
        99.9,
        Math.floor((100 * uploadedBytes) / totalUploadBytes),
    )

    const retry = useRef(() => null)

    useEffect(() => {
        const [facilityId, studyId] = prefix.split('/')
        const options = {
            tags: [
                { Key: 'FacilityID', Value: facilityId },
                { Key: 'StudyID', Value: studyId },
            ],
            partSize: PART_SIZE,
            queueSize: 1,
            leavePartsOnError: true,
        }
        const uploadFiles = async () => {
            for (let i = 0; i < files.length; i += 1) {
                if (files[i].finished) continue
                await new Promise(resolve => {
                    const params = {
                        Body: files[i],
                        Key: prefix + files[i].name,
                    }
                    const attemptManagedUpload = () => {
                        const managedUpload = s3.upload(params, options)
                        let previousBytesLoaded = 0
                        managedUpload.on('httpUploadProgress', e => {
                            setCurrentUpload({
                                progress: Math.min(
                                    100,
                                    (100 * e.loaded) / e.total,
                                ),
                                label: files[i].name,
                                size: e.total,
                                index: i,
                            })
                            setUploadedBytes(
                                ub => ub + e.loaded - previousBytesLoaded,
                            )
                            previousBytesLoaded = e.loaded
                        })
                        const attemptSend = () => {
                            managedUpload.send(err => {
                                if (err) {
                                    if (managedUpload.totalBytes > PART_SIZE) {
                                        switch (err.code) {
                                            case NETWORKING_ERROR:
                                                retry.current = () => {
                                                    if (!managedUpload.failed)
                                                        return
                                                    setError(null)

                                                    // attemptSend() will stall without error if the
                                                    // last part of the upload is in progress. So,
                                                    // restart the file upload from the beginning.
                                                    if (
                                                        managedUpload.doneParts ===
                                                        Math.ceil(
                                                            managedUpload.totalBytes /
                                                            PART_SIZE,
                                                        )
                                                    ) {
                                                        attemptManagedUpload()
                                                    } else {
                                                        attemptSend()
                                                    }
                                                }
                                                break
                                            case REQUEST_ABORTED_ERROR:
                                                retry.current = () => {
                                                    if (!managedUpload.failed)
                                                        return
                                                    setError(null)
                                                    attemptManagedUpload()
                                                }
                                                break
                                            case NO_SUCH_UPLOAD:
                                                retry.current = () => {
                                                    if (!managedUpload.failed)
                                                        return
                                                    setError(null)
                                                    attemptManagedUpload()
                                                }
                                                break
                                            case EXPIRED_TOKEN:
                                                retry.current = () => {
                                                    if (!managedUpload.failed)
                                                        return
                                                    setError(null)
                                                    attemptManagedUpload()
                                                }
                                                break
                                            default:
                                                console.log(
                                                    'Error code:',
                                                    err.code,
                                                )
                                                retry.current = () => {
                                                    if (!managedUpload.failed)
                                                        return
                                                    setError(null)
                                                    attemptManagedUpload()
                                                }
                                        }
                                    } else {
                                        setUploadedBytes(() => {
                                            let uploaded = 0
                                            for (let j = 0; j < i; j += 1) {
                                                uploaded += files[j].size
                                            }
                                            return uploaded
                                        })
                                        setCurrentUpload({
                                            progress: 0,
                                            label: files[i].name,
                                            index: i,
                                        })
                                        retry.current = () => {
                                            setError(null)
                                            attemptManagedUpload()
                                        }
                                    }
                                    setError(err)
                                } else {
                                    resolve()
                                }
                            })
                        }
                        attemptSend()
                    }
                    attemptManagedUpload()
                })
            }
            done()
        }
        uploadFiles()
    }, [files, s3, done, prefix])

    return {
        current: currentUpload,
        totalProgress: totalUploadProgress,
        remainingMs: calculateMsRemaining(),
        error,
        retry: retry.current,
        records: transformedRecords,
    }
}

export default useS3Uploader
