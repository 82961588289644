import axios from 'axios'
import MMTApi from './MMTApi'

const StudyEventsApi = {
    getStudyEvents(study_id) {
        return axios.get(
            `${
                process.env.REACT_APP_API_URL
            }/api/StudyEvents/GetStudyEventsByStudyID?id=${study_id}`,
            MMTApi.getRequestHeaders(),
        )
    },

    newStudyEvent(data) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/StudyEvents/AddStudyEvent`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    deleteStudyEvent(id) {
        return axios.delete(
            `${process.env.REACT_APP_API_URL}/api/StudyEvents/${id}`,
            MMTApi.getRequestHeaders(),
        )
    },

    updateStudyEvent(id, data) {
        return axios.put(
            `${process.env.REACT_APP_API_URL}/api/StudyEvents/${id}`,
            data,
            MMTApi.getRequestHeaders(),
        )
    },

    setImportant(id, isImportant) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/StudyEvents/SetImportant?id=${id}&isImportant=${isImportant}`,
            null,
            MMTApi.getRequestHeaders(),
        )
    },
}

export default StudyEventsApi
