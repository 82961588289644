import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    FACILITY_ADMIN_ROLE,
    OFFICE_PERSONNEL_ROLE,
    FIELD_TECH_ROLE,
    LEAD_TECH_ROLE,
    REVIEW_DOCTOR_ROLE,
} from '../constants/roles'

const StudyAuth = {
    List: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, REVIEW_DOCTOR_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE, OFFICE_PERSONNEL_ROLE],
    AuditLogsPage: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    AuditLogsList: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    AuditLogsCPT: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    DeleteRestore: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE],
    RevokeShare: [SUPER_ADMIN_ROLE, FACILITY_ADMIN_ROLE],
    View: [SUPER_ADMIN_ROLE, SUPPORT_ROLE, FACILITY_ADMIN_ROLE, LEAD_TECH_ROLE, FIELD_TECH_ROLE, REVIEW_DOCTOR_ROLE],
    ViewDiagnostics: [SUPER_ADMIN_ROLE],
}

export default StudyAuth
