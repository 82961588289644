import SessionManager from './SessionManager'

export const Auth = {
    loggedIn() {
        return SessionManager.get('mmt_auth_token') !== null
    },

    getUserId() {
        return this.loggedIn()
            ? SessionManager.get('mmt_user_id').toLowerCase()
            : null
    },

    logout() {
        SessionManager.clear()
        window.sessionStorage.clear()
        window.location.href = '/login'
    },
}

export const hasRequiredRole = (authorizedRoles, roles) => {
    for (const authorizedRole of authorizedRoles) {
        for (const role of roles) {
            if (role === authorizedRole) return true
        }
    }
    return false
}
