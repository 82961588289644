import React from 'react'
import './FacilitiesUserCardDeck.scss'
import * as Constants from '../../constants/roles'
import { truncate } from '../../utils/StringUtils'
import { Card, CardContent, CardHeader, Chip } from '@mui/material'

class FacilitiesUserCardDeck extends React.PureComponent {
    state = {}

    render() {
        const facilities = this.props.facilities || []
        const isMultipleFacilities = facilities.length > 1

        const facilityCards = facilities.map(facility => {
            const isSelected = this.props.selected === facility.FacilityID
            const headerContent = <>
                <div>
                    <i
                        style={{
                            fontSize: '18px',
                            marginRight: '8px',
                            position: 'relative',
                            top: '2px',
                        }}
                        className="fas fa-building"
                    />
                    {truncate(facility.FacilityName, 30)}
                </div>
                {isSelected && isMultipleFacilities && (
                    <div className="chipBox">
                        <Chip
                            color="primary"
                            label="SELECTED"
                            sx={{ mt: 1 }}
                        />
                    </div>
                )}
            </>
            return (
                <Card className="facility-user-cards" key={facility.ID} raised>
                    <CardHeader className="facility-user-card-header" title={headerContent} />

                    <CardContent sx={{ color: '#000' }}>
                        <div style={{ fontWeight: 'bolder' }}>
                            Facility Roles:
                        </div>
                        <ul className="text-align-left">
                            {facility.Roles.map(facRole => {
                                const name = []
                                const role = Constants.ROLE_NAMES.filter(
                                    r => r.id === facRole,
                                )
                                if (role[0]) {
                                    name.push(
                                        <li key={role[0].id}>
                                            {(role[0] &&
                                                role[0].friendly_name) ||
                                                facRole}
                                        </li>,
                                    )
                                    return name
                                }
                                return undefined
                            })}
                        </ul>
                    </CardContent>
                </Card>
            )
        })

        return (<div className="facility-card-deck">{facilityCards}</div>)
    }
}

export default FacilitiesUserCardDeck
