import React, { Component } from 'react'
import { zxcvbn } from '../utils/PasswordUtils'
import { Redirect } from 'react-router-dom'
import { Button } from '@mui/material'
import UserForm from './users/UserForm'
import SuccessMessage from '../components/messaging/SuccessMessage'
import ErrorMessage from '../components/messaging/ErrorMessage'
import FacilitiesApi from '../services/FacilitiesApi'
import { Auth } from '../services/Auth'
import SessionManager from '../services/SessionManager'
import LoadingSpinner from '../components/LoadingSpinner'
import NeutralButton from '../components/NeutralButton'

class Invitation extends Component {
    state = {
        fieldValues: [],
        is_accepting: false,
        is_loading: true,
        error: '',
        success: '',
        has_error: false,
        has_success: false,
        is_new_user: false,
        invite_accepted: false,
        invite: null,
        should_redirect: false,
        redirect_to: null,
    }

    // grab invitation id from url
    inviteId = this.props.match.params.inviteId

    componentDidMount() {
        this.redirectIfInvalidId(this.inviteId)
        const userIsLoggedIn = Auth.loggedIn()

        userIsLoggedIn && this.getFullInvitation()
        !userIsLoggedIn && this.getPartialInvitation()
    }

    getPartialInvitation() {
        FacilitiesApi.isInviteExpired(this.inviteId)
            .then(isExpired => {
                if (isExpired.data) {
                    this.setRedirect(true, '/ExpiredInvitation')
                }
            })
            .then(() => {
                FacilitiesApi.doesInvitedUserExist(this.inviteId).then(
                    result => {
                        this.setUserExists(result.data)
                    },
                )
            })
            .catch(() => {
                this.setRedirect(true, '/Page404')
            })
    }

    getFullInvitation() {
        FacilitiesApi.getInvitation(this.inviteId)
            .then(result => {
                if (result.data.IsExpired || result.data.Accepted) {
                    this.setRedirect(true, '/ExpiredInvitation')
                    return
                }
                this.setInvitation(result.data)
            })
            .catch(() => {
                this.setRedirect(true, '/Page404')
            })
    }

    setRedirect(should_redirect, redirect_to) {
        this.setState({ should_redirect, redirect_to })
    }

    redirectIfInvalidId(id) {
        if (!id || id.length < 36 || id.length > 40) {
            this.setState({ should_redirect: true, redirect_to: '/Page404' })
            console.log('attempted to load invalid invitation id')
        }
    }

    setUserExists(doesUserExist) {
        if (doesUserExist && !Auth.loggedIn()) {
            this.setRedirect(true, `/Login?l=${window.location.pathname}`)
            return
        }
        this.setNewUser()
        this.setState({ is_new_user: true, is_loading: false })
    }

    setInvitation(invite) {
        const isNewUser = !invite.Invited && !(invite.InvitedEmail === SessionManager.get('mmt_auth_user'))
        this.setState({
            invite,
            is_new_user: isNewUser,
            is_loading: false,
        })
    }

    setErrorMessage(message) {
        this.setState({ error: message, has_error: message !== '' })
    }

    setNewUser() {
        const fieldValues = [
            { name: 'FirstName', value: '' },
            { name: 'LastName', value: '' },
            { name: 'Password', value: '' },
            { name: 'ConfirmPassword', value: '' },
        ]

        fieldValues.forEach((field, index) => {
            fieldValues[index].orig = field.value
        })

        this.setFieldValidations(fieldValues)

        this.setState({
            fieldValues,
            is_new_user: true,
            invite_accepted: false,
        })
    }

    hasChanged = () => this.state.fieldValues.some(fv => fv.value !== fv.orig)

    handleChange = (field, value) => {
        const { fieldValues } = this.state
        const index = this.getFieldIndex(field)
        if (field === 'Email') value = value.trim()
        fieldValues[index].value = value
        this.setState({ fieldValues })
    }

    setFieldValidations(userFields) {
        userFields.forEach(field => {
            field.hideValidityError = true
            switch (field.name) {
                case 'FirstName':
                case 'LastName':
                    field.isValid = () =>
                        !!field.value && field.value.length <= 200
                    break
                case 'Password':
                    field.isValid = () =>
                        (field.isValid = () =>
                            field.value.length >= 8 &&
                            field.value.length <= 100 &&
                            zxcvbn(field.value).feedback.warning === '')
                    break
                case 'ConfirmPassword':
                    field.isValid = () =>
                        field.value === this.getFieldValue('Password')
                    break
                default:
                    field.isValid = () => true
            }
        })
    }

    isFormValid = () => {
        const fieldValues = this.state.fieldValues
        let valid = true

        fieldValues.forEach(field => {
            valid = valid && field.isValid()
        })

        return valid
    }

    handleAccept = () => {
        const isNewUser = this.state.is_new_user

        this.setState({ is_accepting: true }, () => {
            isNewUser && this.acceptNewUserInvite()
            !isNewUser && this.acceptExistingUserInvite()
        })
    }

    onBlur = et => {
        const { fieldValues } = this.state
        const fieldIndex = this.getFieldIndex(et.id)
        if (!fieldIndex && fieldIndex !== 0) {
            console.log(
                '[Invitation] Invalid field index retrieve during onBlur event',
            )
            return
        }
        fieldValues[fieldIndex].hideValidityError = false

        this.setState({ fieldValues: this.state.fieldValues })
    }

    acceptExistingUserInvite() {
        FacilitiesApi.acceptInvitation(this.inviteId, '{}')
            .then(() => {
                this.setState({
                    has_success: true,
                    success: 'Invitation Accepted',
                    error: '',
                    is_accepting: false,
                    invite_accepted: true,
                })
            })
            .catch(error => {
                this.setState({
                    has_error: true,
                    error: error.data.Message || error.statusText,
                    is_accepting: false,
                })
            })
    }

    acceptNewUserInvite() {
        const isFormValid = this.isFormValid()
        if (!isFormValid) {
            return
        }

        const user = this.getUserFromFieldValues()
        user.RoleId = '0' // required by api dto but not used

        FacilitiesApi.acceptInvitation(this.inviteId, user)
            .then(() => {
                this.setState({
                    has_success: true,
                    success: 'Invitation Accepted',
                    error: '',
                    is_accepting: false,
                    invite_accepted: true,
                })
            })
            .catch(error => {
                this.setState({
                    has_error: true,
                    error:
                        (error.data && error.data.Message) || error.statusText,
                    is_accepting: false,
                })
            })
    }

    removeError = () => {
        this.setState({
            has_error: false,
            error: '',
        })
    }

    removeSuccess = () => {
        this.setState({
            has_success: false,
            success: '',
        })
    }

    getFieldIndex = field =>
        this.state.fieldValues.findIndex(c => c.name === field)

    getFieldValue = field => {
        const index = this.getFieldIndex(field)
        return index > -1 ? this.state.fieldValues[index].value : '_error_'
    }

    getFieldValidation = field => {
        const index = this.getFieldIndex(field)
        return index > -1 ? this.state.fieldValues[index].isValid() : false
    }

    getFieldValidityErrorDisplay = field => {
        const index = this.getFieldIndex(field)
        const shouldHideError = this.state.fieldValues[index].hideValidityError
        return index > -1 ? shouldHideError : false
    }

    getUserFromFieldValues() {
        const data = {}
        this.state.fieldValues.forEach(field => {
            Object.defineProperty(data, field.name, {
                value: field.value,
                writable: true,
                enumerable: true,
            })
        })
        return data
    }

    acceptButtons() {
        if (this.state.invite_accepted) {
            return ''
        }
        const isFormValid = this.isFormValid()
        const isLoggedIn = Auth.loggedIn()
        const isAccepting = this.state.is_accepting

        return (
            <React.Fragment>
                <Button
                    disabled={!isLoggedIn && !isFormValid}
                    onClick={() => this.handleAccept()}
                    sx={{ ml: 2, float: 'right' }}
                >
                    {isAccepting ? 'Accepting...' : 'Accept Invitation'}
                </Button>
                <NeutralButton
                    disabled={isAccepting}
                    onClick={() =>
                        (window.location.href = isLoggedIn ? '/' : '/Login')
                    }
                    sx={{ float: 'right' }}
                >
                    Decline
                </NeutralButton>
            </React.Fragment>
        )
    }

    render() {
        const isLoggedIn = Auth.loggedIn()
        if (this.state.should_redirect) return <Redirect to={this.state.redirect_to} />
        if (this.state.is_loading) return <LoadingSpinner />

        const isInvitor =
            isLoggedIn &&
            this.state.invite &&
            this.state.invite.InvitorID === Auth.getUserId()
        if (isInvitor) { return <div>You sent this invitation.</div> }

        if (isLoggedIn) {
            const loggedInUserEmail = SessionManager.get('mmt_auth_user')
            const invitedEmail = this.state.invite.InvitedEmail
            const doesEmailMatch = loggedInUserEmail.toLowerCase() === invitedEmail.toLowerCase()
            if (!doesEmailMatch) {
                console.log('[Invitation] Email Mismatch : Invite opened by neither invitor nor invitee')
                return <Redirect to="/Page404" />
            }
        }

        const is_new_user = this.state.is_new_user || false
        const invite_accepted = this.state.invite_accepted
        const excludeFields = ['title', 'suffix', 'email', 'middlename', 'role']
        const displayClass = 'container justify-content-center col-sm-6'
        const facilityName = this.state.invite && `${this.state.invite.FacilityInvitedToName}`
        const acceptButtons = this.acceptButtons()

        return (
            <React.Fragment>
                {// Existing User
                    !is_new_user && !invite_accepted && (
                        <div className={displayClass}>
                            <h3>
                                <i className="icon-envelope-letter" />
                                &nbsp;You&#39;ve been Invited!
                            </h3>
                            <p>
                                Congratulations! You have been invited to join the
                                Rendr Platform for&nbsp;
                                <span style={{ fontWeight: 'bolder' }}>
                                    {facilityName}
                                </span>
                                ! Would you like accept this invitation?
                            </p>
                            {acceptButtons}
                        </div>
                    )}

                {// New User
                    is_new_user && !invite_accepted && (
                        <div className={displayClass}>
                            <h3>
                                <i className="icon-envelope-letter" />
                                &nbsp;You&#39;ve been Invited!
                            </h3>
                            <p>
                                Congratulations! You have been invited to join the
                                Rendr Platform! Please fill out the following
                                information to create your account.
                            </p>
                            <div>
                                {this.state.has_error && (
                                    <ErrorMessage
                                        message={this.state.error}
                                        isVisible={this.state.has_error}
                                        handleRemove={this.removeError}
                                    />
                                )}
                                {this.state.has_success && (
                                    <SuccessMessage
                                        message={this.state.success}
                                        isVisible={this.state.has_success}
                                        handleRemove={this.removeSuccess}
                                    />
                                )}
                                {this.state.is_new_user && (
                                    <UserForm
                                        excludeFields={excludeFields}
                                        fieldValues={this.state.fieldValues}
                                        handleChange={(f, v) =>
                                            this.handleChange(f, v)
                                        }
                                        getFieldValue={field =>
                                            this.getFieldValue(field)
                                        }
                                        getFieldValidation={field =>
                                            this.getFieldValidation(field)
                                        }
                                        getFieldValidityErrorDisplay={field =>
                                            this.getFieldValidityErrorDisplay(field)
                                        }
                                        handleBlur={this.onBlur}
                                        hideAsterisks
                                    />
                                )}
                                {acceptButtons}
                            </div>
                        </div>
                    )}

                {// Accepted Message
                    this.state.invite_accepted && (
                        <div className={displayClass}>
                            <p>
                                <h3>
                                    <i className="icon-thumbs-up" />
                                </h3>
                                You have accepted the invitation and have been newly
                                added to a Rendr Platform facility!
                            </p>
                            {Auth.loggedIn() && (
                                <Button
                                    className="float-right"
                                    onClick={() => (window.location.href = '/')}
                                >
                                    Finished
                                </Button>
                            )}
                            {!Auth.loggedIn() && (
                                <Button
                                    className="pl-3 pr-3 float-right"
                                    onClick={() =>
                                        (window.location.href = '/Login')
                                    }
                                >
                                    Let's Login
                                </Button>
                            )}
                        </div>
                    )}
            </React.Fragment>
        )
    }
}

export default Invitation
