import React from 'react'
import { Button } from '@mui/material'
import { NavLink } from 'react-router-dom'
import NeutralButton from './NeutralButton'

interface Props {
    items: string
    onRetry: () => void
}

const FailedToLoad: React.FC<Props> = props => (
    <div style={{ padding: '20px' }}>
        <h4 style={{ marginBottom: '20px' }}>
            An error occurred getting {props.items}.
        </h4>
        {!navigator.onLine && (
            <h5 style={{ marginBottom: '20px' }}>
                Check your internet connection.
            </h5>
        )}
        <NeutralButton
            component={NavLink}
            to="/Help"
        >
            Help
        </NeutralButton>
        <Button sx={{ ml: 2 }} onClick={props.onRetry}>
            Retry
        </Button>
    </div>
)

export default FailedToLoad
