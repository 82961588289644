import { Link, NavLink } from 'react-router-dom'
import React from 'react'
import DevicesApi from '../../services/DevicesApi'
import RoleBasedContent from '../../components/RoleBasedContent'
import NavUtils from '../../utils/NavUtils'
import {
    SUPPORT_ROLE,
    SUPER_ADMIN_ROLE,
    PRODUCTION_ROLE,
} from '../../constants/roles'
import SortUtils from '../../utils/SortUtils'
import SortHeader from '../../components/SortHeader'
import FailedToLoad from '../../components/FailedToLoad'
import LoadingSpinner from '../../components/LoadingSpinner'
import NeutralButton from '../../components/NeutralButton'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const NAME_FIELD = 'Name'
const SERIAL_FIELD = 'SerialNumber'
const VERSION_FIELD = 'SoftwareVersionString'
const DATE_FIELD = 'DateCreated'

class Devices extends React.Component {
    state = {
        is_loading: true,
        get_devices_did_fail: false,
        devices: [],
        sorted_by: NAME_FIELD,
        sorted_dir: 'asc',
    }

    componentDidMount() {
        NavUtils.setPageTitle('Devices')
        this.getDevices()
    }

    getDevices = () => {
        this.setState({
            is_loading: true,
            get_devices_did_fail: false,
            devices: [],
        })

        DevicesApi.getDevices()
            .then(res => {
                const devicesPlus = res.data.map((obj) => (
                    { ...obj, SoftwareVersionString: obj.SoftwareVersion ? obj.SoftwareVersion : 'Unavailable' }
                ))                
                this.setDevices(devicesPlus)
            })
            .catch(() => {
                this.setState({
                    is_loading: false,
                    get_devices_did_fail: true,
                })
            })
    }

    setDevices(devices) {
        SortUtils.string(devices, this.state.sorted_by, this.state.sorted_dir)
        this.setState({ devices, is_loading: false })
    }

    handleSort = sorted_by => {
        const devices = [...this.state.devices]
        let sorted_dir = 'desc'
        if (
            this.state.sorted_by !== sorted_by ||
            this.state.sorted_dir === 'desc'
        ) {
            sorted_dir = 'asc'
        }
        switch (sorted_by) {
            case SERIAL_FIELD:
                SortUtils.digits(devices, sorted_by, sorted_dir)
                break
            case NAME_FIELD:
                SortUtils.string(devices, sorted_by, sorted_dir)
                break
            case VERSION_FIELD:
                SortUtils.string(devices, sorted_by, sorted_dir)
                break
            case DATE_FIELD:
                SortUtils.string(devices, sorted_by, sorted_dir)
                break
            default:
                throw Error(`you can not sort by ${sorted_by}`)
        }
        this.setState({ sorted_by, sorted_dir, devices })
    }

    pageHeader = () => {
        const countMessage = this.state.is_loading ? 'Loading...' :
            `${this.state.devices?.length === 0 ? 'No' : this.state.devices?.length} devices found`

        return (
            <div style={{ marginBottom: 10 }}>
                <h3 style={{ marginBottom: 3 }}>
                    <i className="fa fa-laptop" />{' '}
                    Devices
                    <RoleBasedContent
                        required_roles={[
                            SUPER_ADMIN_ROLE,
                            PRODUCTION_ROLE,
                        ]}
                    >
                        <NeutralButton
                            sx={{ marginTop: 2 }}
                            component={NavLink}
                            className="btn btn-secondary pull-right"
                            to={'/Device/Create'}
                        >
                            Create
                        </NeutralButton>
                    </RoleBasedContent>
                </h3>
                <Typography noWrap variant="subtitle1">
                    {countMessage}
                </Typography>
                {this.state.is_loading && (<LoadingSpinner />)}
            </div>
        )
    }

    tableHeader = () => (
        <TableHead>
            <TableRow>
                <SortHeader
                    text="Serial"
                    field={SERIAL_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Name"
                    field={NAME_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <TableCell>Part</TableCell>
                <TableCell>Configuration</TableCell>
                <SortHeader
                    text="Version"
                    field={VERSION_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <SortHeader
                    text="Created"
                    field={DATE_FIELD}
                    onSort={this.handleSort}
                    by={this.state.sorted_by}
                    dir={this.state.sorted_dir}
                />
                <TableCell>
                    <RoleBasedContent
                        required_roles={[
                            SUPER_ADMIN_ROLE,
                            SUPPORT_ROLE,
                            PRODUCTION_ROLE,
                        ]}
                    >
                    </RoleBasedContent>
                </TableCell>
            </TableRow>
        </TableHead>
    )

    formatDate = longFormat => {
        const justDate = longFormat ? longFormat.slice(0, longFormat.indexOf('T')) : ''
        return justDate
    }

    render() {
        const isLoading = this.state.is_loading
        const didGetDevicesFail = this.state.get_devices_did_fail
        const devices = this.state.devices
        const noDevices = !isLoading && !didGetDevicesFail && devices?.length === 0
        const pageHeader = this.pageHeader()
        const tableHeader = this.tableHeader()

        if (isLoading || noDevices)
            return (pageHeader)

        return (
            <div className="animated fadeIn">
                {pageHeader}
                <TableContainer sx={{ maxHeight: 'calc(100vh - 200px)' }}>
                    <Table stickyHeader>
                        {tableHeader}
                        <TableBody>
                            {this.state.devices.map(device => {
                                let versionProps = {}
                                if (!device.SoftwareVersion) {
                                    versionProps = { color: 'rgba(255, 255, 255, 0.50)', fontStyle: 'italic' }
                                }

                                const logIconProps = device.NumberOfLogEntries > 0 ? {
                                    title: `LogEntries - ${device.NumberOfLogEntries.toLocaleString()}`,
                                    component: Link,
                                    to: `/Logs/DeviceLogs/${device.ID}`,
                                    color: 'primary',
                                } : {
                                    title: 'No Logs Available',
                                    color: 'primary',
                                    sx: { visibility: 'hidden' },
                                }
                         
                                return (
                                    <React.Fragment key={device.ID}>
                                        <TableRow>
                                            <TableCell>{device.SerialNumber}</TableCell>
                                            <TableCell>{device.Name}</TableCell>
                                            <TableCell>{device.PartNumber}</TableCell>
                                            <TableCell>{device.Configuration}</TableCell>
                                            <TableCell sx={{ ...versionProps }}>{device.SoftwareVersionString}</TableCell>
                                            <TableCell>{this.formatDate(device.DateCreated)}</TableCell>                                            
                                            <TableCell>
                                                <div className="row-action-icons">
                                                    <RoleBasedContent
                                                        required_roles={[
                                                            SUPER_ADMIN_ROLE,
                                                            PRODUCTION_ROLE,
                                                        ]}
                                                    >
                                                        <IconButton
                                                            title="Edit"
                                                            color="primary"
                                                            component={Link}
                                                            to={`/Device/${device.ID}`}>
                                                            <i className="fa fa-pencil-alt" />
                                                        </IconButton>
                                                    </RoleBasedContent>
                                                    <RoleBasedContent
                                                        required_roles={[
                                                            SUPER_ADMIN_ROLE,
                                                            SUPPORT_ROLE,
                                                            PRODUCTION_ROLE,
                                                        ]}
                                                    >
                                                        <IconButton {...logIconProps}>
                                                            <i className="fas fa-file-contract" />
                                                        </IconButton>
                                                    </RoleBasedContent>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                )
                            })}
                            {!this.state.devices.length && (
                                <TableRow>
                                    <TableCell
                                        colSpan="5"
                                        style={{ textAlign: 'center' }}
                                    >
                                        {this.state.get_devices_did_fail && (
                                            <FailedToLoad
                                                items="devices"
                                                onRetry={this.getDevices}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br />
            </div>
        )
    }
}

export default Devices
