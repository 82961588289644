// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

import ChannelTypeFilterSettings from './ChannelTypeFilterSettings'

class FilterSettings {
    constructor(filterSettings) {
        this.NotchFilterFrequency = filterSettings.NotchFilterFrequency
        this.NotchOn = filterSettings.NotchOn
        this.HighLowFrequencyFilterValues = []

        for (
            let index = 0,
                len = filterSettings.HighLowFrequencyFilterValues.length;
            index < len;
            index += 1
        ) {
            const channelTypeFilterSettings = new ChannelTypeFilterSettings(
                filterSettings.HighLowFrequencyFilterValues[index],
            )
            this.HighLowFrequencyFilterValues.push(channelTypeFilterSettings)
        }
    }

    equalTo(otherFilterSettings) {
        let equal = false

        if (
            this.NotchFilterFrequency ===
                otherFilterSettings.NotchFilterFrequency &&
            this.NotchOn === otherFilterSettings.NotchOn
        ) {
            equal = true
            for (
                let index = 0,
                    len =
                        otherFilterSettings.HighLowFrequencyFilterValues.length;
                index < len;
                index += 1
            ) {
                if (
                    this.HighLowFrequencyFilterValues[index]
                        .HighFrequencyFilterValue !==
                        otherFilterSettings.HighLowFrequencyFilterValues[index]
                            .HighFrequencyFilterValue ||
                    this.HighLowFrequencyFilterValues[index]
                        .HighFrequencyFilterValue !==
                        otherFilterSettings.HighLowFrequencyFilterValues[index]
                            .HighFrequencyFilterValue
                ) {
                    equal = false
                    break
                }
            }
        }

        return equal
    }
}

export default FilterSettings
