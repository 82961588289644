import React from 'react'

interface Props {
    name: string
    id: string
    handleThemeSelectClick: (id: string) => void
    isSelected: boolean
}

const ThemeItem: React.FC<Props> = ({ name, id, handleThemeSelectClick, isSelected }) => (
    <div
        className={`event ${isSelected ? 'active-event' : ''}`}
        onClick={() => handleThemeSelectClick(id)}
    >
        <span
            className="p-2 text-black event-name"
        >
            {name}
        </span>
    </div>
)

export default ThemeItem
