import axios from 'axios'
import MMTApi from './MMTApi'

const ThemesApi = {
    getMyTheme() {
        return axios.get(
            `${process.env.REACT_APP_API_URL}/api/Users/GetMyTheme/`,
            MMTApi.getRequestHeaders(),
        )
    },

    setMyTheme(id) {
        return axios.post(
            `${process.env.REACT_APP_API_URL}/api/Users/SetMyTheme?id=${id}`,
            '',
            MMTApi.getRequestHeaders(),
        )
    },
}

export default ThemesApi
