// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

import DataSource from './DataSource/DataSource'
import FilterSettings from './DataSource/FilterSettings'
import DataRequest from './DataSource/DataRequest'
import CacheManager from './DataSource/CacheManager'

// This class is responsible for monitoring the ElectroGraph's data.  It then adds/removes data to
// the graph based on the state and based on the data returned from the datasource.
class GraphDataManager {
    constructor(
        study,
        socketPath,
        electroGraph,
        montage,
        defaultFilterSettings,
    ) {
        this.study = study
        this.socketPath = socketPath
        this.montage = montage
        this.montageTemplateId = montage.ID
        this.dataRate = study.StudyDataRate
        this.dataPeriod = 1 / study.StudyDataRate
        this.electroGraph = electroGraph
        this.cacheManager = new CacheManager(electroGraph)
        this.customFilterSettings = new FilterSettings(defaultFilterSettings)
        this.dataSource = new DataSource(this.socketPath, this, this.montage)
        this.callbackOnRequestClear = () => { }

        this.dataRequestDefaults = {
            minimumMinutesToCheck: 4,
            minimumMinutesToRequest: 6,
            fastSpeedMinutesToRequestMultiplier: 2,
        }

        this.dataRequestSettings = {
            minutesToCheck: this.dataRequestDefaults.minimumMinutesToCheck,
            minutesToRequest: this.dataRequestDefaults.minimumMinutesToRequest,
        }
    }

    // #region Requests

    /**
     * Tells the API to clear any pending requests from its queue for this websocket connection.
     */
    clearCustomDataRequests() {
        this.dataSource.clearCustomDataRequests()
    }

    /**
     * This method requests custom data.  This method is similar to the initial request in that it
     * doesn't check the graph to see if data is already there.  It assumes that the data there
     * isn't the correct data according to the current state of the graph and requests all minutes.
     * It requests custom data based on the currently selected montage template and filter values.
     *
     * @param {any} settingsChanged A boolean to let the data source know that something in the
     * filter settings and montage template changed.
     * @memberof GraphDataManager
     */
    requestCustomDataAroundCurrentMinute() {
        const tr = this.getTimeRangeForDataRequest()
        const dr = this.createNewDataRequest(
            tr.startMinute,
            tr.endMinute,
            tr.priorityMinute,
        )
        this.dataSource.requestCustomData(dr)
    }

    /**
     * request initial data during graph's first set of data
     *
     * @memberof GraphDataManager
     */
    requestInitialData() {
        // set initial settings on datasource
        this.dataSource.handleSettingsChange(
            this.customFilterSettings,
            this.montageTemplateId,
        )

        // first request for graph data
        const tr = this.getTimeRangeForDataRequest()
        const dr = this.createNewDataRequest(
            tr.startMinute,
            tr.endMinute,
            tr.priorityMinute,
        )
        this.dataSource.requestCustomData(dr)
    }

    /**
     * returns a datarequest and handles custom vs original automatically
     *
     * @param {int} firstMinute
     * @param {int} lastMinute
     * @param {int} priorityMinute
     */
    createNewDataRequest(firstMinute, lastMinute, priorityMinute) {
        const dr = new DataRequest(firstMinute, lastMinute, priorityMinute)
        dr.isOriginalData = true
        if (this.electroGraph.customMontageAndFiltersMode) {
            dr.isOriginalData = false
            dr.customFilterSettings = this.customFilterSettings
            dr.montageTemplateId = this.montageTemplateId
        }

        return dr
    }

    getTimeRangeForDataCheck() {
        const minuteRange = this.dataRequestSettings.minutesToCheck
        return this.getTimeRange(minuteRange)
    }

    getTimeRangeForDataRequest() {
        const minuteRange = this.dataRequestSettings.minutesToRequest
        const timeRange = this.getTimeRange(minuteRange)
        this.optimizeTimeRangeForRequest(timeRange)
        return timeRange
    }

    /**
     * returns typical time values requested around a given minute, adjust for epoch
     * movement direction and uses graphs current minute if a minute is not passed.
     * Returns appropriate minutes when checking for data vs requesting data (see dataRequestSettings )
     *
     * @param {int}  minuteRange | number of minutes to request
     * @param {int} minuteToDefineRangeBy | minute to center request around (priority minute)
     */
    getTimeRange(numberOfMinutesToRequest, minuteToDefineRangeBy = null) {
        const currentMinute = this.getGraphCurrentMinute()
        const priorityMinute = minuteToDefineRangeBy || currentMinute
        const lastMinuteOfData = this.electroGraph.getLastMinuteOfData()

        const startMinute = Math.max(currentMinute - 2, 0)
        const endMinute = Math.min(
            startMinute + numberOfMinutesToRequest,
            lastMinuteOfData,
        )
        return {
            startMinute,
            endMinute,
            priorityMinute,
        }
    }

    /**
     * increases a time range's start minute to the first minute not found in packet array,
     * then increases endMinute to ensure full minutesToRequest is meet
     * @param {object} timeRange
     */
    optimizeTimeRangeForRequest(timeRange) {
        // adjust start minute
        for (
            let minute = timeRange.startMinute;
            minute < timeRange.endMinute;
            minute += 1
        ) {
            if (this.doesMinuteLackPackets(minute)) {
                timeRange.startMinute = minute
                break
            }
        }
        if (timeRange.endMinute === timeRange.startMinute) return

        // adjust end minute
        for (
            let minute = timeRange.endMinute;
            minute >= timeRange.startMinute;
            minute -= 1
        ) {
            if (this.doesMinuteLackPackets(minute - 1)) {
                timeRange.endMinute = minute
                break
            }
        }
        if (timeRange.endMinute === timeRange.startMinute) return

        // don't be greater than the last minute of study or less then 1 (for sub-minute recording)
        timeRange.endMinute = Math.max(
            Math.min(
                timeRange.endMinute,
                this.electroGraph.getLastMinuteOfData(),
            ),
            1,
        )

        // adjust priority minute
        if (timeRange.priorityMinute < timeRange.startMinute) {
            timeRange.priorityMinute = timeRange.startMinute
        }
        if (timeRange.priorityMinute >= timeRange.endMinute) {
            timeRange.priorityMinute = timeRange.endMinute - 1
        }
    }

    doesMinuteLackPackets(minute) {
        const startPacketIndex = this.minuteToPacketIndex(minute)
        const endPacketIndex = this.minuteToPacketIndex(minute + 1) - 1
        for (
            let index = startPacketIndex;
            index <= endPacketIndex;
            index += 1
        ) {
            if (this.electroGraph.packets[index] === undefined) return true
        }
        return false
    }

    // #endregion

    // #region Responses

    /**
     * Processes original recorded data that was requested and returned.  Data is returned in
     * minutes and each minute is passed through here and sent to the graph.
     *
     * @param {any} minute
     * @memberof GraphDataManager
     */
    processOriginalDataResponseMinute(minute) {
        // if the graph is now in custom filters and montage mode, then don't send the data to the
        // graph.
        if (this.electroGraph.customMontageAndFiltersMode) {
            console.log(
                '[GDM] requested original data cached, but not drawn since not in correct mode.',
            )
        } else {
            this.electroGraph.processOriginalDataResponseMinute(minute)
        }
    }

    /**
     * Processes custom recorded data that was requested and returned.
     *
     * @param {any} minute
     * @memberof GraphDataManager
     */
    processCustomDataResponseMinute(samples, samplesMontage) {
        if (!this.electroGraph.customMontageAndFiltersMode) {
            console.warn('[GDM] processCustomDataResponseMinute() called while graph not in customMontageAndFiltersMode')
            return
        }

        this.electroGraph.processCustomDataResponseMinute(
            samples,
            samplesMontage,
        )
    }

    // #endregion

    // #region Event Handlers

    /**
     * Called when the web-socket has opened and can take requests for data
     *
     * @param {any} value
     * @memberof GraphDataManager
     */
    dataSourceAvailableChanged(isAvailable) {
        if (
            isAvailable &&
            this.electroGraph.packetsSegmentsLoaded.length === 0 &&
            this.electroGraph.studyRecordingPacketCounts.length > 1
        ) {
            this.requestInitialData()
        }
    }

    requestForNormalReview() {
        // check to see if all data is already in packet array
        const trCheck = this.getTimeRangeForDataCheck()
        if (this.areAllTimeRangeMinutesInPacketArray(trCheck)) {
            return
        }

        const trRequest = this.getTimeRangeForDataRequest()
        if (trRequest.endMinute === trRequest.startMinute) return

        // Clear pending request queue if the new minutes being requested are 
        const isHighSpeedPlayback = this.electroGraph.props.playSpeed > 0
        const isRequestOutsideOfRange = this.dataSource.minutesInRequestQueue.every(min => {
            const startMinDistance = Math.abs(min.minute - trRequest.startMinute)
            const endMinDistance = Math.abs(min.minute - trRequest.endMinute)
            const largestDistance = Math.max(startMinDistance, endMinDistance)
            return largestDistance > this.dataRequestSettings.minutesToCheck
        })

        if (isHighSpeedPlayback && isRequestOutsideOfRange) {
            console.log('[GDM] Clearing pending API requests')
            this.dataSource.clearCustomDataRequests()
            this.notifyClearRequestQueue()
        }

        const dr = this.createNewDataRequest(
            trRequest.startMinute,
            trRequest.endMinute,
            trRequest.priorityMinute,
        )


        this.dataSource.requestCustomData(dr)
    }

    requestForFocusedReview(nearbyImportantMinutes) {
        for (const minute of nearbyImportantMinutes) {
            const tr = {
                startMinute: minute - 1,
                endMinute: minute,
                priorityMinute: minute - 1,
            }
            if (this.areAllTimeRangeMinutesInPacketArray(tr)) {
                continue
            }
            const dr = this.createNewDataRequest(tr.startMinute, tr.endMinute, tr.priorityMinute)
            this.dataSource.requestCustomData(dr)
        }
    }

    /**
     * Called when the epoch changes. This method will look at the current epoch and request any
     * future data needed for graph.
     */
    epochChanged() {
        const isFocusedReview = this.electroGraph.props.isFocusedReview

        let importantMinutes = []

        if (isFocusedReview) {
            importantMinutes = this.getNearbyImportantMinutes()
            this.requestForFocusedReview(importantMinutes)
        } else {
            this.requestForNormalReview()
        }

        if (this.cacheManager.clean(importantMinutes)) this.electroGraph.forceUpdate()
    }

    getNearbyImportantMinutes() {
        const importantEvents = this.electroGraph.context.Study.StudyEvents.filter(e => e.Important)
        const precedingEvents = [...importantEvents.filter(e => this.electroGraph.playCursor.index > this.electroGraph.calculateSampleIndex(e.RecordingIndex, e.StartPacketIndex))]
        precedingEvents.sort((a, b) => {
            const aIndex = this.electroGraph.calculateSampleIndex(a.RecordingIndex, a.StartPacketIndex)
            const bIndex = this.electroGraph.calculateSampleIndex(b.RecordingIndex, b.StartPacketIndex)

            const aDistance = Math.abs(this.electroGraph.playCursor.index - aIndex)
            const bDistance = Math.abs(this.electroGraph.playCursor.index - bIndex)

            return aDistance - bDistance
        })

        const followingEvents = [...importantEvents.filter(e => this.electroGraph.playCursor.index < this.electroGraph.calculateSampleIndex(e.RecordingIndex, e.StartPacketIndex))]
        followingEvents.sort((a, b) => {
            const aIndex = this.electroGraph.calculateSampleIndex(a.RecordingIndex, a.StartPacketIndex)
            const bIndex = this.electroGraph.calculateSampleIndex(b.RecordingIndex, b.StartPacketIndex)

            const aDistance = Math.abs(this.electroGraph.playCursor.index - aIndex)
            const bDistance = Math.abs(this.electroGraph.playCursor.index - bIndex)

            return aDistance - bDistance
        })

        const samplesPerMinute = this.electroGraph.props.study.StudyDataRate * 60
        const currentMinute = Math.floor(this.electroGraph.playCursor.index / samplesPerMinute) + 1
        const preceedingImportantMinutes = []
        for (let i = 0; preceedingImportantMinutes.length < this.cacheManager.importantMinuteRetention; i += 1) {
            if (!precedingEvents[i]) break
            const startMinute = Math.floor(precedingEvents[i].StartTimeRelativeToStudy / 60) + 1
            const endMinute = Math.floor(precedingEvents[i].EndTimeRelativeToStudy / 60) + 1
            for (let minute = endMinute; minute >= startMinute; minute -= 1) {
                if (preceedingImportantMinutes.length < this.cacheManager.importantMinuteRetention) {
                    if (!preceedingImportantMinutes.includes(minute) && minute !== currentMinute) {
                        preceedingImportantMinutes.push(minute)
                    }
                }
            }
        }

        const followingImportantMinutes = []
        for (let i = 0; followingImportantMinutes.length < this.cacheManager.importantMinuteRetention; i += 1) {
            if (!followingEvents[i]) break
            const startMinute = Math.floor(followingEvents[i].StartTimeRelativeToStudy / 60) + 1
            const endMinute = Math.floor(followingEvents[i].EndTimeRelativeToStudy / 60) + 1
            for (let minute = startMinute; minute <= endMinute; minute += 1) {
                if (followingImportantMinutes.length < this.cacheManager.importantMinuteRetention) {
                    if (!followingImportantMinutes.includes(minute) && minute !== currentMinute) {
                        followingImportantMinutes.push(minute)
                    }
                }
            }
        }
        const importantMinutes = [...preceedingImportantMinutes, currentMinute, ...followingImportantMinutes]

        return importantMinutes
    }

    /**
     * This is called when the selected montage changes.  It saves the selected montage template ID
     * and makes a request for custom data based on that and the current filter values.
     *
     * @param {any} montageTemplateId
     * @memberof GraphDataManager
     */
    setSelectedMontageTemplateId(montageTemplateId) {
        this.montageTemplateId = montageTemplateId
        this.dataSource.handleSettingsChange(
            this.customFilterSettings,
            this.montageTemplateId,
        )
        this.clearBuffers()
        this.clearCustomDataRequests()
        this.requestCustomDataAroundCurrentMinute()
    }

    /**
     * This is called when the selected high filter value changes.  It saves the filter value and
     * makes a request for the custom data based on the current custom montage and filter values.
     *
     * @param channel_type
     * @param {any} value
     * @memberof GraphDataManager
     */
    setHighFilter(channel_type, value) {
        const type_index = this.customFilterSettings.HighLowFrequencyFilterValues.findIndex(
            x => x.ChannelTypeId === channel_type,
        )

        this.customFilterSettings.HighLowFrequencyFilterValues[
            type_index
        ].HighFrequencyFilterValue = parseFloat(value)
        this.dataSource.handleSettingsChange(
            this.customFilterSettings,
            this.montageTemplateId,
        )
        this.clearBuffers()
        this.clearCustomDataRequests()
        this.requestCustomDataAroundCurrentMinute()
    }

    /**
     * This is called when the selected low filter value changes.  It saves the filter value and
     * makes a request for the custom data based on the current custom montage and filter values.
     *
     * @param channel_type
     * @param {any} value
     * @memberof GraphDataManager
     */
    setLowFilter(channel_type, value) {
        const type_index = this.customFilterSettings.HighLowFrequencyFilterValues.findIndex(
            x => x.ChannelTypeId === channel_type,
        )

        this.customFilterSettings.HighLowFrequencyFilterValues[
            type_index
        ].LowFrequencyFilterTimeConstant = parseFloat(value)
        this.dataSource.handleSettingsChange(
            this.customFilterSettings,
            this.montageTemplateId,
        )
        this.clearBuffers()
        this.clearCustomDataRequests()
        this.requestCustomDataAroundCurrentMinute()
    }

    /**
     * This is called when the selected notch filter value changes.  It saves the filter value and
     * makes a request for the custom data based on the current custom montage and filter values.
     *
     * @param {any} value
     * @param is_notch_on
     * @memberof GraphDataManager
     */
    setNotchFilter(value, is_notch_on = true) {
        this.customFilterSettings.NotchFilterFrequency = parseFloat(value)
        this.customFilterSettings.NotchOn = is_notch_on
        this.dataSource.handleSettingsChange(
            this.customFilterSettings,
            this.montageTemplateId,
        )
        this.clearBuffers()
        this.clearCustomDataRequests()
        this.requestCustomDataAroundCurrentMinute()
    }

    // #endregion

    // #region Datasource

    setIsLive(value) {
        this.electroGraph.isLive = value
    }

    addPackets(packets) {
        this.electroGraph.addPackets(packets)
    }

    close() {
        this.dataSource.closeWebSocket()
    }

    // #endregion

    // #region Utility

    areAllTimeRangeMinutesInPacketArray(timeRange) {
        let areAllPacketsPresent = true
        for (
            let minute = timeRange.startMinute;
            minute < timeRange.endMinute;
            minute += 1
        ) {
            const packetIndex = this.minuteToPacketIndex(minute)
            if (this.electroGraph.packets[packetIndex] === undefined) {
                areAllPacketsPresent = false
                break
            }
        }
        return areAllPacketsPresent
    }

    /**
     * Clears the data buffers for the graph and triggers a refresh
     *
     * @memberof GraphDataManager
     */
    clearBuffers() {
        this.electroGraph.packetsSegmentsLoaded.length = 0
        this.electroGraph.packets.length = 0
        this.electroGraph.isEpochDrawNeeded = true
        console.log('[GDM] data/request/response buffers purged')
    }

    getGraphCurrentMinute() {
        return Math.floor(
            this.electroGraph.state.sample_offset / (this.dataRate * 60),
        )
    }

    /**
     * For a given minute this will return the packet index that the minute would start at
     * @param {integer} minute
     */
    minuteToPacketIndex(minute) {
        return this.dataRate * Math.max(minute * 60, this.dataPeriod)
    }

    /**
    * Set method to call when request queue is cleared
    *
    */
    setNotifyClearRequestQueue(callback) {
        console.log('[GDM] Setting clear request queue callback')
        this.callbackOnRequestClear = callback
    }

    notifyClearRequestQueue() {
        console.log('[GDM] Clearing local request queue')
        this.callbackOnRequestClear()
    }

    // #endregion
}

export default GraphDataManager
