// MOBILEMEDTEK - RENDRLABS CONFIDENTIAL
// This file is subject to the terms and conditions defined in
// file 'LICENSE.txt', which is part of this source code package.

class OriginalDataCache {
    constructor() {
        this.cache = {} // a dictionary of the filtered data minutes.
        this.cacheKeys = [] // a list of keys in order of last use.  This is used to determine how old or unused samples are in the dictionary.
    }

    // This method adds lists of samples to the data cache.
    addMinute(filteredDataMinute) {
        this.cache[filteredDataMinute.minute] = filteredDataMinute
        this.addKey(filteredDataMinute.minute)
    }

    // keys are added to the end of the list and removed from the beginning of the list.
    // TODO: Check this logic later to make sure this is doing what it's supposed to.
    // I think I wrote it to remove items from the end, but I can't check now since I don't have the
    // time. -MRB
    addKey(key) {
        // if the key is already in the array, remove it.
        const index = this.cacheKeys.indexOf(key)

        if (index > -1) {
            this.cacheKeys.splice(index, 1)
        }

        // add the key to the beginning of the list
        this.cacheKeys.push(key)

        // remove old keys from the end of the list
        // TODO: determine if this really works with MAX_CACHE_SIZE
        while (this.cacheKeys.length >= this.MAX_CACHE_SIZE) {
            const minute = this.cacheKeys[this.cacheKeys.length - 1]

            // remove the old data as well as the key.
            if (this.cache[minute] !== undefined) {
                this.cache[minute].splice(minute, 1)
            }

            this.cacheKeys.splice(this.cacheKeys.length - 1, 1)
        }
    }

    // This gets the minute of data requested.  If the minute isn't in the cache, then null is
    // returned.
    getMinute(minute) {
        if (this.cache[minute] === undefined) return null

        // if the value is in the cache, move it up in the cache since it was recently used.
        const index = this.cacheKeys.indexOf(minute)
        this.cache.move(index, 0)

        return this.cache[minute]
    }
}

export default OriginalDataCache
