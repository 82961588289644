interface ToastMessage {
    id: number
    message: string,
    type: 'notice' | 'warning' | 'success' | 'error',
    duration?: number
    onClick?: () => void
}

type Message = Omit<ToastMessage, 'id' | 'type'>

const DEFAULT_DURATION = 3000

class ToastUtils {
    static notice(msg: Message): number {
        return this.dispatch({ ...msg, type: 'notice' })
    }

    static success(msg: Message) {
        return this.dispatch({ ...msg, type: 'success' })
    }

    static warning(msg: Message) {
        return this.dispatch({ ...msg, type: 'warning' })
    }

    static error(msg: Message) {
        return this.dispatch({ ...msg, type: 'error' })
    }

    static close(id: number) {
        const event = new CustomEvent('closetoast', { detail: id })
        window.dispatchEvent(event)
    }

    static dispatch(toast: Omit<ToastMessage, 'id'>): number {
        const detail: ToastMessage = { ...toast, id: Math.round(Math.random() * 1e12) }
        if (detail.type === 'success' && !detail.duration) {
            detail.duration = DEFAULT_DURATION
        }
        const event = new CustomEvent('toast', { detail })
        window.dispatchEvent(event)
        return detail.id
    }

    static isOpen(messageRegExp: RegExp): boolean {
        const toasts = document.querySelectorAll('.toast-message.body')
        for (const toast of toasts) {
            if (messageRegExp.test(toast.innerHTML)) {
                return true
            }
        }
        return false
    }
}

export default ToastUtils
