interface Amplifier {
    ID: string
    FacilityID: string
    SerialNumber: string
    UsbID: string | null
    BluetoothID: string | null
    BootloaderVersion: string | null
    Name: string
    AmplifierTypeID: number
    FriendlyName: string
    FirmwareVersion: string | null
    FirmwareVersionString: string
    Enabled: boolean
    DateCreated: string
}

export const emptyAmplifer = (): Amplifier => ({
    ID: '',
    FacilityID: '',
    SerialNumber: '',
    FirmwareVersion: null,
    FirmwareVersionString: '',
    BootloaderVersion: null,
    UsbID: null,
    BluetoothID: null,
    Name: '',
    DateCreated: '',
    AmplifierTypeID: 0,
    FriendlyName: '',
    Enabled: false,
})

export type AmplifierField = 'ID' | 'Name' | 'FacilityID' | 'AmplifierTypeID' | 'SerialNumber' | 'UsbID' | 'BluetoothID' | 'Enabled'

export default Amplifier
