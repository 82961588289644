import React from 'react'

const BALL_DIAMETER = 10

const getBallTop = (props, index) => {
    const channelId = props.getChannelId()
    const scale = props.getChannelScale(channelId)
    const packet = props.getPackets(index, index + 1)[0]
    if (!packet) return -100
    return (
        props.channels[channelId].mid -
        scale * packet.Inputs[channelId] -
        BALL_DIAMETER / 2
    )
}

const getLeftBallTop = props => {
    const startIndex = props.calculateSampleIndex(
        props.event.RecordingIndex,
        props.event.StartPacketIndex,
    )
    return getBallTop(props, startIndex)
}

const getRightBallTop = props => {
    const endIndex = props.calculateSampleIndex(
        props.event.RecordingIndex,
        props.event.EndPacketIndex,
    )
    return getBallTop(props, endIndex)
}

const EventBallMarkers = props => (
    <div className="ball-container">
        <div
            className={`ball ${props.isChannelEnabled() ? '' : 'disabled'}`}
            style={{
                top: `${
                    props.isEventBackwards
                        ? getRightBallTop(props)
                        : getLeftBallTop(props)
                }px`,
                width: `${BALL_DIAMETER}px`,
                height: `${BALL_DIAMETER}px`,
                left: `${BALL_DIAMETER / -2}px`,
            }}
        />
        <div
            className={`ball ${props.isChannelEnabled() ? '' : 'disabled'}`}
            style={{
                top: `${
                    props.isEventBackwards
                        ? getLeftBallTop(props)
                        : getRightBallTop(props)
                }px`,
                width: `${BALL_DIAMETER}px`,
                height: `${BALL_DIAMETER}px`,
                right: `${BALL_DIAMETER / -2}px`,
            }}
        />
    </div>
)

export default EventBallMarkers
