import React, { useContext, useState } from 'react'
import DateTimeUtils from '../../utils/DateTimeUtils'
import {
    EFN,
    NOTE_EVENTS,
    EVENT_ICON,
    IMPEDANCE_MEASUREMENT,
    DELETABLE_EVENTS,
    CAN_MARK_IMPORTANT_EVENTS,
} from '../../constants/studyevents'
import StudyEventUtils from '../../utils/StudyEventUtils'
import ImpedanceModal from './Impedance/ImpedanceModal'
import StudyEvent from '../../types/StudyEvent'
import StudyEventsApi from '../../services/StudyEventsApi'
import ToastUtils from '../../utils/ToastUtils'
import AppContext from '../../components/AppContext'
import { Action } from '../../components/AppState'
import ClockSetting from '../../types/ClockSetting'

interface Props {
    event: StudyEvent
    isCurrentSelected: boolean
    isLastSelected: boolean
    isImportantFilterOn: boolean
    clockSetting: ClockSetting
    handleClick: () => void
    handleEdit: () => void
    handleDelete: () => void
    moveGraphToStudyEvent: (event: StudyEvent) => void
}

const StudyEventItem: React.FC<Props> = props => {
    const ctx = useContext(AppContext)
    const [isImpedanceModalOpen, setIsImpedanceModalOpen] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const toggleImportant = () => {
        if (isSaving) return
        setIsSaving(true)
        const important = !props.event.Important
        StudyEventsApi.setImportant(props.event.ID, important).then(() => {
            setIsSaving(false)
            ctx.commit(Action.UpdateStudyEvent, {
                ...props.event,
                Important: !props.event.Important,
            })
        }).catch(err => {
            ToastUtils.error({ message: `Unable to mark event as ${important ? '' : 'un'}important` })
            console.log('Error updating important:', err)
        })
    }


    const studyRecording = ctx.Study.StudyRecordings.find(r => r.Index === props.event.RecordingIndex)
    if (!studyRecording) {
        throw new Error(`unable to find study recording with index ${props.event.RecordingIndex}`)
    }

    const is_valid_impedance = (value: string) => value.split('|').length === 2

    const eventTime = DateTimeUtils.getMomentInTimeZone(studyRecording.DateStarted, studyRecording.TimeZone)
    eventTime.add(props.event.StartPacketIndex / ctx.Study.StudyDataRate, 'seconds')
    let timestamp = ''
    switch (props.clockSetting) {
        case ClockSetting.TwelveHour:
            timestamp = eventTime.format('h:mm:ss A')
            break
        case ClockSetting.TwentyFourHour:
            timestamp = eventTime.format('HH:mm:ss')
            break
        default:
            const seconds = Math.floor(props.event.StartTimeRelativeToStudy)
            timestamp = DateTimeUtils.getStudyAltDuration(seconds)
    }
    const isHighlightedEvent = props.isCurrentSelected || props.isLastSelected
    const canMarkImportant = CAN_MARK_IMPORTANT_EVENTS.includes(props.event.EventTypeID)

    return (
        <div
            id={isHighlightedEvent ? 'highlighted-event' : ''}
            className={`event${props.isCurrentSelected ? ' active-event' : ''} ${props.isLastSelected ? ' last-active-event' : ''}`}
            onClick={
                props.isCurrentSelected
                    ? () =>
                        props.moveGraphToStudyEvent(
                            props.event,
                        )
                    : props.handleClick
            }
        >
            <div className="row">
                <div className="col-md-2">
                    <i
                        style={{
                            color: props.event.EventType.Color,
                        }}
                        className={`icon ${EVENT_ICON[props.event.EventType.ID]}`}
                    />
                </div>
                <div className="col-md-10">
                    <span className="event-name">
                        {StudyEventUtils.getStudyEventText(props.event)}
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-2" />
                <div className="col-md-6">
                    {NOTE_EVENTS.includes(
                        props.event.EventType.ID,
                    ) &&
                        (
                            <div className="event-value">
                                {EFN[props.event.EventType.ID]}
                            </div>
                        )}
                    {!NOTE_EVENTS.includes(
                        props.event.EventType.ID,
                    ) &&
                        (
                            <div className="event-value">
                                {StudyEventUtils.getFriendlyValue(
                                    props.event,
                                )}
                            </div>
                        )}
                </div>
                <div className="col-md-4 text-right">
                    <span className="event-value">
                        {timestamp}
                    </span>
                </div>
            </div>
            {props.isCurrentSelected && (
                <div className="col text-right">
                    <button
                        type="button"
                        title="Edit"
                        className="btn btn-icon p-1"
                        onClick={props.handleEdit}
                    >
                        <i className="icon-smaller icon-electrotek-pencil" />
                    </button>
                    {props.event.EventType.ID ===
                        IMPEDANCE_MEASUREMENT &&
                        is_valid_impedance(props.event.Value) &&
                        (
                            <button
                                type="button"
                                title="Impedance"
                                className="btn btn-icon p-1"
                                onClick={() => setIsImpedanceModalOpen(true)}
                            >
                                <i className="icon-smaller icon-electrotek-omega" />
                            </button>
                        )}
                    {DELETABLE_EVENTS.includes(
                        props.event.EventType.ID,
                    ) &&
                        (
                            <button
                                type="button"
                                title="Delete"
                                className="btn btn-icon p-1"
                                onClick={props.handleDelete}
                            >
                                <i className="icon-smaller fa fa-trash-alt" />
                            </button>
                        )
                    }
                    {canMarkImportant && 
                        <button
                            type="button"
                            title="Important"
                            className="btn btn-icon p-1"
                            onClick={toggleImportant}
                            disabled={isSaving}
                        >
                            <i
                                className={`
                                    icon-smaller
                                    icon-electrotek-star${props.event.Important ? '' : '-o'}
                                    ${isSaving ? 'text-secondary' : props.event.Important ? 'text-warning' : ''}
                                `}
                                style={{ cursor: isSaving ? 'progress' : 'pointer' }}
                            />
                        </button>
                    }
                </div>
            )}
            {isImpedanceModalOpen && (
                <ImpedanceModal
                    event={props.event}
                    toggle={() => setIsImpedanceModalOpen(false)}
                />
            )}
        </div>
    )
}

export default StudyEventItem
