import React from 'react'
import {
    MOVABLE_EVENTS,
    MEASUREMENT,
    NOTE,
} from '../../../../constants/studyevents'
import AppContext from '../../../../components/AppContext'
import EventBallMarkers from './EventBallMarkers'
import EventMarkerInfoBox from './EventMarkerInfoBox'
import EventDragHandles from './EventDragHandles'
import StudyEventUtils from '../../../../utils/StudyEventUtils'
import { IMPORTANT_COLOR } from '../constants'

const INFO_BOX_WIDTH = 270 // from EventMarkers.scss

class EventMarker extends React.Component {
    static contextType = AppContext

    constructor(props) {
        super(props)
        this.state = {
            is_saving: false,
            mouse_down_at: null,
            event: props.event,
            is_spanning:
                props.event.EndPacketIndex !== null ||
                props.event.StartPacketIndex === null,
        }
        this.container = React.createRef()
        this.setPreviousEventValues()
    }

    componentWillUnmount() {
        window.removeEventListener('mousedown', this.handleDrag)
        window.removeEventListener('touchstart', this.handleDrag)
        window.removeEventListener('mousedown', this.handleBeginCreation)
        window.removeEventListener('touchstart', this.handleBeginCreation)
        window.removeEventListener('mousedown', this.handleDragLeftSideStart)
        window.removeEventListener('touchstart', this.handleDragLeftSideStart)
        window.removeEventListener('mousedown', this.handleDragRightSide)
        window.removeEventListener('touchstart', this.handleDragRightSide)
    }

    componentDidUpdate() {
        if (this.prevSampleOffset !== this.props.sampleOffset) {
            this.setState({ event: { ...this.props.event } })
        }
        this.setPreviousEventValues()
    }

    setPreviousEventValues() {
        this.prevSampleOffset = this.props.sampleOffset
    }

    getDragEventType = e => (e.clientX ? 'mousemove' : 'touchmove')

    getDragEndEventType = e => (e.clientX ? 'mouseup' : 'touchend')

    getXOfEvent = e => {
        if (e.nativeEvent) {
            return e.nativeEvent.clientX || e.nativeEvent.touches[0].clientX
        }
        return e.clientX || e.touches[0].clientX
    }

    handleSelectEvent = clickEvent => {
        if (
            this.state.event.StartPacketIndex === this.props.event.StartPacketIndex &&
            this.state.event.EndPacketIndex === this.props.event.EndPacketIndex &&
            !clickEvent.target.closest('.no-selection-toggle')
        ) {
            this.props.onSelectEvent(this.props.event, false)
        }
    }

    handleDragStart = event => {
        window.addEventListener(this.getDragEventType(event), this.handleDrag)
        window.addEventListener(
            this.getDragEndEventType(event),
            this.handleDragEnd,
        )
        this.setState({ mouse_down_at: this.getXOfEvent(event) })
    }

    handleDragEnd = event => {
        this.updateEventIfChanged()
        window.removeEventListener(
            this.getDragEventType(event),
            this.handleDrag,
        )
        window.removeEventListener(
            this.getDragEndEventType(event),
            this.handleDragEnd,
        )
        this.setState({ mouse_down_at: null })
    }

    handleDrag = event => {
        if ((!event.clientX && !event.touches) || !this.container.current)
            return
        const currentPixelX = this.getXOfEvent(event)
        const pixelChange = currentPixelX - this.state.mouse_down_at
        const percentChange = pixelChange / this.container.current.offsetWidth
        const indexChange = Math.round(
            this.props.samplesPerWindow * percentChange,
        )
        let width =
            this.props.event.EndPacketIndex - this.props.event.StartPacketIndex
        if (!this.props.event.EndPacketIndex) {
            width = 0
        }
        const StartPacketIndex = Math.min(
            Math.max(1, this.props.event.StartPacketIndex + indexChange),
            this.props.event.recordingMaxIndex -
            this.props.calculateSampleIndex(
                this.props.event.RecordingIndex,
                0,
            ) -
            width,
        )
        const adjustedStartIndex = Math.min(
            Math.max(
                this.props.event.recordingMinIndex -
                this.props.sampleOffset,
                this.props.event.adjustedStartIndex + indexChange,
            ),
            this.props.event.recordingMaxIndex -
            this.props.sampleOffset -
            width,
        )

        if (this.state.is_spanning) {
            const adjustedEndIndex = adjustedStartIndex + width
            const EndPacketIndex = StartPacketIndex + width

            this.setState({
                event: {
                    ...this.state.event,
                    adjustedStartIndex,
                    adjustedEndIndex,
                    StartPacketIndex,
                    EndPacketIndex,
                },
            })
        } else {
            this.setState({
                event: {
                    ...this.state.event,
                    adjustedStartIndex,
                    StartPacketIndex,
                },
            })
        }
    }

    handleDragLeftSideStart = event => {
        window.addEventListener(
            this.getDragEventType(event),
            this.handleDragLeftSide,
        )
        window.addEventListener(
            this.getDragEndEventType(event),
            this.handleDragLeftSideEnd,
        )
        this.setState({ mouse_down_at: this.getXOfEvent(event) })
    }

    handleDragLeftSideEnd = event => {
        this.updateEventIfChanged()
        window.removeEventListener(
            this.getDragEventType(event),
            this.handleDragLeftSide,
        )
        window.removeEventListener(
            this.getDragEndEventType(event),
            this.handleDragLeftSideEnd,
        )
        this.setState({ mouse_down_at: null })
    }

    handleDragLeftSide = event => {
        if ((!event.clientX && !event.touches) || !this.container.current)
            return
        const currentPixelX = this.getXOfEvent(event)
        const pixelChange = currentPixelX - this.state.mouse_down_at
        const percentChange = pixelChange / this.container.current.offsetWidth
        const indexChange = Math.round(
            this.props.samplesPerWindow * percentChange,
        )
        const adjustedStartIndex = Math.max(
            Math.min(
                this.props.event.adjustedStartIndex + indexChange,
                this.props.event.recordingMaxIndex -
                this.props.sampleOffset,
            ),
            this.props.event.recordingMinIndex - this.props.sampleOffset,
        )
        const StartPacketIndex = Math.max(
            Math.min(
                this.props.event.StartPacketIndex + indexChange,
                this.props.event.recordingMaxIndex,
            ),
            1,
        )

        this.setState({
            event: {
                ...this.state.event,
                adjustedStartIndex,
                StartPacketIndex,
            },
        })
    }

    handleDragRightSideStart = event => {
        window.addEventListener(
            this.getDragEventType(event),
            this.handleDragRightSide,
        )
        window.addEventListener(
            this.getDragEndEventType(event),
            this.handleDragRightSideEnd,
        )
        this.setState({ mouse_down_at: this.getXOfEvent(event) })
    }

    handleDragRightSideEnd = event => {
        this.updateEventIfChanged()
        window.removeEventListener(
            this.getDragEventType(event),
            this.handleDragRightSide,
        )
        window.removeEventListener(
            this.getDragEndEventType(event),
            this.handleDragRightSideEnd,
        )
        this.setState({ mouse_down_at: null })
    }

    handleDragRightSide = event => {
        if ((!event.clientX && !event.touches) || !this.container.current)
            return
        const currentPixelX = event.clientX || event.touches[0].clientX
        const pixelChange = currentPixelX - this.state.mouse_down_at
        const percentChange = pixelChange / this.container.current.offsetWidth
        const indexChange = Math.round(
            this.props.samplesPerWindow * percentChange,
        )
        const EndPacketIndex = Math.min(
            Math.max(this.props.event.EndPacketIndex + indexChange, 1),
            this.props.event.recordingMaxIndex -
            this.props.calculateSampleIndex(
                this.state.event.RecordingIndex,
                0,
            ),
        )
        const adjustedEndIndex =
            this.props.event.adjustedEndIndex +
            EndPacketIndex -
            this.props.event.EndPacketIndex

        this.setState({
            event: {
                ...this.state.event,
                adjustedEndIndex,
                EndPacketIndex,
            },
        })
    }

    updateEventIfChanged() {
        if (
            this.state.event.StartPacketIndex !==
            this.props.event.StartPacketIndex ||
            this.state.event.EndPacketIndex !==
            this.props.event.EndPacketIndex ||
            this.state.event.ChannelName !== this.props.event.ChannelName
        ) {
            this.setState({ is_saving: true })
            const event = this.getCorrectedBackwardsEvent()
            const StartTimeRelativeToStudy = this.props.calculateSampleIndex(event.RecordingIndex, event.StartPacketIndex) / this.context.Study.StudyDataRate
            let EndTimeRelativeToStudy = StartTimeRelativeToStudy
            if (event.EndPacketIndex) {
                EndTimeRelativeToStudy = this.props.calculateSampleIndex(event.RecordingIndex, event.EndPacketIndex) / this.context.Study.StudyDataRate
            }
            this.props.onUpdateEvent({
                ...event,
                StartTimeRelativeToStudy,
                EndTimeRelativeToStudy,
            }).then(() => {
                this.setState({ is_saving: false })
            })
        }
    }

    getCorrectedBackwardsEvent = () => {
        const event = { ...this.state.event }
        if (
            event.EndPacketIndex &&
            event.StartPacketIndex > event.EndPacketIndex
        ) {
            const si = event.StartPacketIndex
            event.StartPacketIndex = event.EndPacketIndex
            event.EndPacketIndex = si

            const asi = event.adjustedStartIndex
            event.adjustedStartIndex = event.adjustedEndIndex
            event.adjustedEndIndex = asi
        }
        return event
    }

    handleBeginCreation = event => {
        if (this.props.creatingEventOfType === null) return
        if (event.target.classList.contains('icon-close')) {
            return
        }
        const mouse_down_at = this.getXOfEvent(event) - this.props.gutter.left
        const windowPercent = mouse_down_at / this.container.current.offsetWidth
        const windowIndex = Math.round(
            this.props.samplesPerWindow * windowPercent,
        )
        let packetIndex = this.props.sampleOffset + windowIndex
        const packet = this.props.getPackets(
            packetIndex,
            packetIndex + 1,
        )[0]
        if (!packet) return
        const RecordingIndex = packet.RecordingIndex
        const recordingStartIndex = this.props.calculateSampleIndex(
            RecordingIndex,
            0,
        )
        let recordingMaxIndex = 0
        for (let i = 1; i <= RecordingIndex; i += 1) {
            recordingMaxIndex += this.props.recordingPacketCounts[i]
        }
        packetIndex -= recordingStartIndex
        this.setState({
            event: {
                ...this.state.event,
                adjustedStartIndex: windowIndex,
                adjustedEndIndex: windowIndex,
                recordingMaxIndex,
                ChannelName: (
                    this.props.creatingEventOnChannel || { label: null }
                ).label,
                StartPacketIndex: packetIndex,
                EndPacketIndex: packetIndex,
                RecordingIndex,
            },
            mouse_down_at,
        })
        window.addEventListener(
            this.getDragEventType(event),
            this.handleDragNoteCreate,
        )
    }

    handleEndCreation = windowEvent => {
        if (this.props.creatingEventOfType === null || this.state.mouse_down_at === null) return
        if (windowEvent.target.classList.contains('icon-close')) {
            return
        }
        const event = this.getCorrectedBackwardsEvent()
        this.props.onCreateEvent({
            ...event,
            StudyID: this.context.Study.ID,
        })
        window.removeEventListener(
            this.getDragEventType(windowEvent),
            this.handleDragNoteCreate,
        )
        this.setState({ mouse_down_at: null, event })
    }

    handleDragNoteCreate = event => {
        if ((!event.clientX && !event.touches) || !this.container.current)
            return
        const currentPixelX = this.getXOfEvent(event) - this.props.gutter.left
        const pixelChange = currentPixelX - this.state.mouse_down_at
        const percentChange = pixelChange / this.container.current.offsetWidth
        const indexChange = Math.round(
            this.props.samplesPerWindow * percentChange,
        )

        const EndPacketIndex = Math.max(
            Math.min(
                this.state.event.StartPacketIndex + indexChange,
                this.state.event.recordingMaxIndex -
                this.props.calculateSampleIndex(
                    this.state.event.RecordingIndex,
                    0,
                ),
            ),
            1,
        )

        const adjustedEndIndex =
            this.state.event.adjustedEndIndex +
            EndPacketIndex -
            this.state.event.EndPacketIndex

        this.setState({
            event: {
                ...this.state.event,
                adjustedEndIndex,
                EndPacketIndex,
            },
        })
    }

    getEventValues = () => {
        if (this.props.event.EventTypeID !== MEASUREMENT) return ''
        let startIndex = this.props.calculateSampleIndex(
            this.state.event.RecordingIndex,
            this.state.event.StartPacketIndex,
        )
        let endIndex = this.props.calculateSampleIndex(
            this.state.event.RecordingIndex,
            this.state.event.EndPacketIndex,
        )
        if (startIndex > endIndex) {
            const si = endIndex
            endIndex = startIndex
            startIndex = si
        }
        if (this.props.event.EventTypeID !== MEASUREMENT) return ''
        const packets = this.props.getPackets(startIndex, endIndex + 1)
        const startPacket = packets[0]
        const endPacket = packets[packets.length - 1]
        if (!startPacket || !endPacket) return ''
        const channelId = this.getChannelId()
        if (channelId === null) return ''

        const deltaT =
            (endIndex - startIndex) / this.context.Study.StudyDataRate
        const hz = 1 / deltaT
        const deltaV = Math.abs(
            endPacket.Inputs[channelId] - startPacket.Inputs[channelId],
        )
        let minV = 1e6
        let maxV = -1e6
        for (const pkt of packets) {
            const v = pkt.Inputs[channelId]
            if (v < minV) {
                minV = v
            }
            if (v > maxV) {
                maxV = v
            }
        }

        return [deltaT, hz, deltaV, minV, maxV]
    }

    getEventTypeName = () => {
        switch (this.props.creatingEventOfType) {
            case NOTE:
                return 'note'
            case MEASUREMENT:
                return 'measurement'
            default:
                return 'event'
        }
    }

    isChannelEnabled = () => {
        const channelId = this.getChannelId()
        return this.props.channels[channelId].enabled
    }

    getChannelTypeId = () => {
        if (this.props.creatingEventOfType === null) {
            for (let i = 0; i < this.props.montage.Channels.length; i += 1) {
                if (
                    this.props.montage.Channels[i].Name ===
                    this.state.event.ChannelName
                ) {
                    return this.props.montage.Channels[i].ChannelTypeID
                }
            }
            console.error('Could not find channel type id')
            return 0
        }
        return this.props.creatingEventOnChannel.type
    }

    getChannelId = () => {
        if (this.props.creatingEventOfType === null) {
            for (let i = 0; i < this.props.montage.Channels.length; i += 1) {
                if (
                    this.props.montage.Channels[i].Name ===
                    this.state.event.ChannelName
                ) {
                    return i
                }
            }
            return null
        }
        return this.props.creatingEventOnChannel.id
    }

    handleChangeChannel = event => {
        this.setState(
            {
                event: {
                    ...this.state.event,
                    ChannelName: event.target.value,
                    ChannelTypeId: this.getChannelTypeId(),
                },
            },
            this.updateEventIfChanged,
        )
    }

    render() {
        let eventLeft =
            (100 * this.state.event.adjustedStartIndex) /
            this.props.samplesPerWindow
        let eventWidth =
            (100 *
                (this.state.event.adjustedEndIndex -
                    this.state.event.adjustedStartIndex)) /
            this.props.samplesPerWindow
        if (!this.state.is_spanning) {
            eventWidth = 0
        }

        let border = `1px solid ${this.state.event.EventType.Color}99`
        let background = `${this.state.event.EventType.Color}22`
        if (this.state.event.Important) {
            border = `1px solid ${IMPORTANT_COLOR}99`
            background = `${IMPORTANT_COLOR}22`
        }
        let borderLeft = border
        let borderRight = borderLeft

        if (eventLeft < 0) {
            eventWidth += eventLeft
            eventLeft = 0
            borderLeft = 'none'
        }

        if (eventLeft + eventWidth > 100) {
            eventWidth = 100 - eventLeft
            borderRight = 'none'
        }

        let isEventBackwards = false
        if (eventWidth < 0) {
            eventLeft += eventWidth
            eventWidth = 0 - eventWidth
            isEventBackwards = true
        }
        if (!this.state.is_spanning) {
            borderLeft = border.replace('1px', '1.5px')
            borderRight = borderLeft
        }

        let infoBoxPositionKey = 'left'
        let infoBoxPositionValue = eventLeft + eventWidth / 2

        let infoBoxPosition = 'inside'
        if ((eventWidth / 100) * this.props.graphWidth < INFO_BOX_WIDTH + 20) {
            infoBoxPositionValue = eventLeft + eventWidth
            infoBoxPosition = 'outside right'
            const availablePixelsAfterEvent =
                this.props.graphWidth -
                ((eventLeft + eventWidth) / 100) * this.props.graphWidth
            if (availablePixelsAfterEvent < INFO_BOX_WIDTH + 20) {
                infoBoxPositionKey = 'right'
                infoBoxPositionValue = 100 - eventLeft
                infoBoxPosition = 'outside left'
            }
        }

        const values = this.getEventValues()

        let titleText =
            StudyEventUtils.getStudyEventText(this.state.event)
        if (!this.props.isSelected && titleText.length > 50) {
            titleText = `${titleText.slice(0, 47)}...`
        }

        const canDragEvent =
            this.props.creatingEventOfType === null &&
            this.props.isSelected &&
            MOVABLE_EVENTS.includes(this.state.event.EventType.ID) &&
            !this.state.is_saving

        const showEventBallMarkers =
            this.state.event.EventType.ID === MEASUREMENT &&
            this.props.isSelected &&
            values.length === 5

        const showInfoBox = [null, MEASUREMENT].includes(
            this.props.creatingEventOfType,
        )

        let containterClasses = 'event-marker-container'
        if (this.props.isSelected) containterClasses += ' selected'
        if (this.props.creatingEventOfType !== null)
            containterClasses += ' creating'
        if (this.state.is_saving) containterClasses += ' saving'

        return (
            <div
                className={containterClasses}
                ref={this.container}
                onMouseDown={this.handleBeginCreation}
                onMouseUp={this.handleEndCreation}
                onTouchStart={this.handleBeginCreation}
                onTouchEnd={this.handleEndCreation}
            >
                <div className="create-event-instructions">
                    <i
                        className="icon-close float-right"
                        onClick={() => this.props.onExitCreatingEvent()}
                    />
                    Click or tap and drag anywhere on the graph to add a{' '}
                    {this.getEventTypeName()}.
                </div>
                {this.state.event.StartPacketIndex !== null && (
                    <React.Fragment>
                        <div
                            className="event-marker"
                            style={{
                                left: `${eventLeft}%`,
                                width: `${eventWidth}%`,
                                background,
                                borderLeft,
                                borderRight,
                            }}
                            onClick={
                                this.state.is_saving
                                    ? null
                                    : this.handleSelectEvent
                            }
                        >
                            {showEventBallMarkers && (
                                <EventBallMarkers
                                    isChannelEnabled={this.isChannelEnabled}
                                    calculateSampleIndex={
                                        this.props.calculateSampleIndex
                                    }
                                    getChannelId={this.getChannelId}
                                    getChannelScale={this.props.getChannelScale}
                                    getPackets={this.props.getPackets}
                                    isEventBackwards={isEventBackwards}
                                    event={this.state.event}
                                    channels={this.props.channels}
                                />
                            )}
                            {canDragEvent && (
                                <EventDragHandles
                                    isSpanning={this.state.is_spanning}
                                    isEventBackwards={isEventBackwards}
                                    handleDragStart={this.handleDragStart}
                                    handleDragLeftSideStart={
                                        this.handleDragLeftSideStart
                                    }
                                    handleDragRightSideStart={
                                        this.handleDragRightSideStart
                                    }
                                />
                            )}
                        </div>
                        {showInfoBox && (
                            <EventMarkerInfoBox
                                event={this.state.event}
                                values={values}
                                timebase={this.props.timebase}
                                infoBoxPosition={infoBoxPosition}
                                infoBoxPositionKey={infoBoxPositionKey}
                                infoBoxPositionValue={infoBoxPositionValue}
                                handleChangeChannel={this.handleChangeChannel}
                                handleEditStudyEvent={this.props.onEditStudyEvent}
                                calculateSampleIndex={this.props.calculateSampleIndex}
                                onUpdateEvent={async event => {
                                    await this.props.onUpdateEvent(event)
                                    this.setState({ event: {
                                        ...this.props.event,
                                    } })
                                }}
                                onMoveGraphToStudyEvent={this.props.onMoveGraphToStudyEvent}
                                handleDeleteStudyEvent={
                                    this.props.onDeleteStudyEvent
                                }
                                clockSetting={this.props.clockSetting}
                                isSaving={this.state.is_saving}
                                titleText={titleText}
                                isSelected={this.props.isSelected}
                                montage={this.props.montage}
                                border={border}
                                onClick={this.handleSelectEvent}
                            />
                        )}
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default EventMarker
