import React, { Component } from 'react'
import './Login.scss'
import { Link } from 'react-router-dom'
import axios from 'axios'
import MMTApi from '../services/MMTApi'
import SessionManager from '../services/SessionManager'
import { Auth } from '../services/Auth'
import Validation from '../services/Validation'
import NavUtils from '../utils/NavUtils'
import PasswordInput from '../components/forms/PasswordInput'
import { Action } from '../components/AppState'
import AppContext from '../components/AppContext'
import ToastUtils from '../utils/ToastUtils'
import NeutralButton from '../components/NeutralButton'
import LogRocket from 'logrocket'
class Login extends Component {
    static contextType = AppContext

    state = {
        email: '',
        password: '',
        is_submitting: false,
        validation: {
            email: true,
            password: true,
        },
        error: {
            email: 'Valid Email is required',
            password: 'Password that is 8 characters is required',
        },
        url_destination: '',
        subtitle: null,
    }

    constructor(props) {
        super(props)
        this.passwordInput = React.createRef()
    }

    componentDidMount() {
        NavUtils.setPageTitleFromPath(this.props.match.path)

        this.context.commit(Action.StopTimer)

        if (Auth.loggedIn()) {
            window.location.href = '/dashboard'
            return
        }
        if (this.props.location && this.props.location.search) {
            const url = this.props.location.search.startsWith('?l=')
                ? this.props.location.search.slice(3)
                : this.props.location.search
            this.setUrlDestination(url)
            const msg =
                url.toLowerCase().indexOf('invitation') > 0
                    ? 'Please login before accepting the facility invitation'
                    : null
            this.setSubTitle(msg)
        }
    }

    setUrlDestination(url) {
        this.setState({ url_destination: url })
    }

    setSubTitle(subtitle) {
        this.setState({ subtitle })
    }

    isformValid = () => {
        let valid = true
        let email = true
        let password = true

        if (
            this.state.email.length <= 0 ||
            !Validation.isValidEmail(this.state.email)
        ) {
            email = false
            valid = false
        }

        if (this.state.password.length < 6) {
            password = false
            valid = false
        }

        this.setState({
            validation: {
                ...this.state.validation,
                email,
                password,
            },
        })

        return valid
    }

    handleSubmit = () => {
        if (this.isformValid()) {
            this.setState({
                is_submitting: true,
            })

            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/Account/Login`,
                    `username=${encodeURIComponent(this.state.email)}&password=${encodeURIComponent(this.state.password)}&grant_type=password`,
                    {
                        headers: MMTApi.getHeaders(),
                    },
                )
                .then(response => {
                    SessionManager.set(
                        'mmt_auth_token',
                        response.data.access_token,
                        0,
                    )
                    SessionManager.set(
                        'mmt_auth_user',
                        response.data.userName,
                        0,
                    )
                })
                .then(() => {
                    axios
                        .get(
                            `${process.env.REACT_APP_API_URL
                            }/api/Account/UserInfo`,
                            MMTApi.getRequestHeaders(),
                        )
                        .then(
                            res => {
                                SessionManager.set('mmt_user_id', res.data.ID, 0)
                                SessionManager.set('mmt_user_name',`${res.data.FirstName} ${res.data.LastName}`.trim())
                                if (res.data.FacilityID) {
                                    SessionManager.set('mmt_facility_id', res.data.FacilityID, 0)
                                    SessionManager.set('mmt_facility_name', res.data.FacilityName, 0)
                                }
                                if (this.state.password && this.state.password.length < 8) {
                                    SessionManager.set(
                                        'system_msg',
                                        'Your password no longer meets minimum length requirements. Please go to the profile page and update it. Passwords of this length will be prevented from logging in on the next release.'
                                    )
                                }
                                const roleArray = res.data.Roles.filter(r=>  r !== 'RegisteredUser')
                                const role = roleArray.length>0 ? roleArray[0] : 'Registered User Only'
                                if (process.env.REACT_APP_LOGROCKET_ENABLE) {
                                    LogRocket.identify(res.data.ID, {
                                        name: `${res.data.FirstName} ${res.data.LastName}`.trim(),
                                        email: res.data.Username,

                                        // Add your own custom user variables here, ie:
                                        selectedFacilityID: res.data.FacilityID || 'No Selected Facility',
                                        role: role,
                                    })
                                }
                                if (this.state.url_destination) {
                                    window.location.href = this.state.url_destination
                                } else {
                                    window.location.href = '/dashboard'
                                }
                            },
                            () => { this.setState({ is_submitting: false }) },
                        )
                })
                .catch(error => {
                    let errMsg = 'Connection failure during login attempt'
                    if (error && error.data) {
                        errMsg =
                            error.data.error_description || error.statusText
                    }
                    ToastUtils.error({ message: errMsg })
                    this.setState({ is_submitting: false })
                    this.passwordInput.current.focus()
                })
        }
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    }

    render() {
        return (
            <div className="row justify-content-center login">
                <div className="form-container">
                    <h2 className="mb-1 pb-3">Login</h2>
                    {this.state.subtitle && <h3>{this.state.subtitle}</h3>}
                    <form>
                        <div className="form-group">
                            <label htmlFor="email" className="sr-only">
                                Email
                            </label>
                            <input
                                value={this.state.email}
                                onChange={this.handleChange}
                                type="email"
                                name="email"
                                id="email"
                                className="form-control"
                                placeholder="Email"
                                onKeyDown={e => e.key === 'Enter' ? this.handleSubmit() : null}
                            />
                            {!this.state.validation.email && (
                                <div className="input-error-msg">
                                    {this.state.error.email || ''}
                                </div>
                            )}
                        </div>

                        <div className="form-group">
                            <div className="input-group">
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <PasswordInput
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    fieldRef={this.passwordInput}
                                    onEnter={this.handleSubmit}
                                />
                                {!this.state.validation.password && (
                                    <div className="input-error-msg">
                                        {this.state.error.password || ''}
                                    </div>
                                )}
                            </div>
                        </div>

                        <NeutralButton
                            disabled={this.state.is_submitting}
                            onClick={this.handleSubmit}
                            sx={{ width: '100%' }}
                        >
                            {this.state.is_submitting ? 'Logging in...' : 'Login'}
                        </NeutralButton>
                    </form>

                    <div className="row text-center">
                        <div className="col">
                            <br />
                            <Link className="mt-6" to="/ForgotPassword">
                                Forgot Password?
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Login
