import React from 'react'
import './UserInfoRow.scss'
import UsersApi from '../../services/UsersApi'
import {
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
    SYSTEM_ROLES,
    ROLE_NAMES,
    REGISTERED_USER_ROLE,
} from '../../constants/roles'
import FacilitiesUserCardDeck from '../../components/cards/FacilitiesUserCardDeck'
import AppContext from '../../components/AppContext'
import LoadingSpinner from '../../components/LoadingSpinner'
import { Collapse, TableRow, TableCell } from '@mui/material'

class UserInfoRow extends React.Component {
    static contextType = AppContext

    state = {
        is_loading: true,
        user_info: null,
    }

    componentDidMount() {
        UsersApi.getUserInfo(this.props.user.ID)
            .then(res => {
                this.setState({
                    user_info: res.data,
                    is_loading: false,
                })
            })
            .catch(err => {
                console.log('[ERROR] unable to retrieve user info', err)
                this.setState({ is_loading: false })
            })
    }

    isShowingFacility = () => {
        for (const role of this.context.Roles) {
            if (role === SUPER_ADMIN_ROLE || role === SUPPORT_ROLE) return true
        }
        return false
    }

    getRoles = roleType => {
        const isSystem = roleType === 'system'
        const userInfo = this.state.user_info
        const allRoles = (userInfo && this.state.user_info.IdentityRoles) || []
        const hasRoles = allRoles.length > 1 // ignores RegisteredUser

        if (!hasRoles) {
            return 'None'
        }

        let roles = []
        switch (isSystem) {
            case true:
                roles = allRoles.filter(r => SYSTEM_ROLES.some(sr => r === sr))
                break

            case false:
                roles = allRoles.filter(r => SYSTEM_ROLES.some(sr => r !== sr))
                break

            default:
        }

        if (roles.length === 0) {
            return 'None'
        }
        const filteredRows = roles.filter(r => r !== REGISTERED_USER_ROLE)
        return ROLE_NAMES.filter(rn => rn.name === filteredRows[0])[0]
            .friendly_name
    }

    getFacilites() {
        return this.state.user_info ? this.state.user_info.FacilityUsers : []
    }

    render = () => (
        <TableRow className="user-info-row">
            <TableCell colSpan={12}>
                <Collapse in={true} timeout="auto">
                    {this.state.is_loading ? (
                        <LoadingSpinner height="360px" txtMsg="Retrieving User Info ..." />
                    ) : (
                        <div className="info">
                            {this.isShowingFacility() && (
                                <p>
                                    Selected Facility :
                                    <span className="float-right text-truncate">
                                        {this.props.user.SelectedFacilityName ||
                                            ''}
                                    </span>
                                </p>
                            )}
                            <p>
                                Selected Theme :
                                <span className="float-right">
                                    {(this.state.user_info &&
                                        this.state.user_info
                                            .SelectedThemeName) ||
                                        'default'}
                                </span>
                            </p>
                            <p>
                                System Roles:
                                <span className="float-right">{this.getRoles('system')}</span>
                            </p>
                            <FacilitiesUserCardDeck
                                facilities={this.getFacilites()}
                                selected={
                                    this.props.user.SelectedFacilityID
                                }
                            />
                        </div>
                    )}
                </Collapse>
            </TableCell>
        </TableRow>
    )
}

export default UserInfoRow
