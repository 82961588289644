import React from 'react'
import './Stepper.scss'
import { Progress } from 'reactstrap'
import * as StepStatus from '../constants/importstepstates'
import { STEP_PROCESS_DATA, STEP_PROCESS_VIDEO } from '../constants/importsteps'

const ICON_SIZE = 60

export interface Step {
    name: string
    status: number
    icon: string
}

const getStepClassList = (step: Step) => {
    switch (step.status) {
        case StepStatus.COMPLETE:
            return 'bg-success'
        case StepStatus.CURRENT:
            if (step.name === STEP_PROCESS_DATA.name || step.name === STEP_PROCESS_VIDEO.name) {
                return 'bg-info fa-spin'
            }
            return 'bg-info'
        case StepStatus.ERROR:
            return 'bg-red'
        default:
            return 'bg-gray'
    }
}

interface Props {
    steps: Step[]
}

const Stepper: React.FC<Props> = props => {
    const completedSteps = props.steps.filter(
        s => s.status === StepStatus.COMPLETE,
    )
    const progressPct = (completedSteps.length / (props.steps.length - 1)) * 100

    return (
        <div
            className="stepper"
            style={{
                height: ICON_SIZE,
                marginLeft: `${ICON_SIZE / 2}px`,
                marginRight: `${ICON_SIZE / 2}px`,
                width: `calc(100% - ${ICON_SIZE}px)`,
            }}
        >
            <Progress value={progressPct} color="success" />
            {props.steps.map((step, i) => (
                <div key={step.name}>
                    <div
                        className={`step-icon ${getStepClassList(step)}`}
                        style={{
                            left: `calc(${(100 * i) /
                                (props.steps.length - 1)}% - ${ICON_SIZE /
                                2}px)`,
                            width: ICON_SIZE,
                            height: ICON_SIZE,
                        }}
                    >
                        <i className={step.icon} />
                    </div>
                    <p
                        className="step-name"
                        style={{
                            left: `${(100 * i) / (props.steps.length - 1)}%`,
                        }}
                    >
                        {step.name}
                    </p>
                </div>
            ))}
        </div>
    )
}

export default Stepper
