import React from 'react'
import './Dashboard.scss'
import MetricBlockSignalR from './MetricBlockSignalR'
import NavUtils from '../../utils/NavUtils'
import {
    FACILITY_ADMIN_ROLE,
    FIELD_TECH_ROLE,
    LEAD_TECH_ROLE,
    OFFICE_PERSONNEL_ROLE,
    REVIEW_DOCTOR_ROLE,
    SUPER_ADMIN_ROLE,
    SUPPORT_ROLE,
} from '../../constants/roles'
import RoleBasedContent from '../../components/RoleBasedContent'
import LatestStudies from './LatestStudies'
import AppContext from '../../components/AppContext'
import SessionManager from '../../services/SessionManager'
import ToastUtils from '../../utils/ToastUtils'

class Dashboard extends React.Component {
    static contextType = AppContext

    state = {
        system_msg: null,
    }

    componentDidMount() {
        NavUtils.setPageTitleFromPath(this.props.match.path)
        const system_msg = SessionManager.get('system_msg')
        if (system_msg) {
            this.displaySystemMsg(system_msg)
        }
    }

    displaySystemMsg(system_msg) {
        SessionManager.remove('system_msg')
        setTimeout(() => ToastUtils.error({ message: system_msg }))
    }

    render() {
        return (
            <div className="animated fadeIn dashboard">
                {SessionManager.get('mmt_facility_id') ? (
                    <React.Fragment>
                        <RoleBasedContent
                            required_roles={[
                                SUPER_ADMIN_ROLE,
                                SUPPORT_ROLE,
                                FACILITY_ADMIN_ROLE,
                                REVIEW_DOCTOR_ROLE,
                                LEAD_TECH_ROLE,
                                FIELD_TECH_ROLE,
                                OFFICE_PERSONNEL_ROLE,
                            ]}
                        >
                            <MetricBlockSignalR />
                        </RoleBasedContent>

                        <RoleBasedContent
                            required_roles={[
                                SUPER_ADMIN_ROLE,
                                SUPPORT_ROLE,
                                FACILITY_ADMIN_ROLE,
                                REVIEW_DOCTOR_ROLE,
                                LEAD_TECH_ROLE,
                                FIELD_TECH_ROLE,
                            ]}
                        >
                            <LatestStudies />
                        </RoleBasedContent>
                    </React.Fragment>
                ) : (
                    <div style={{ textAlign: 'center', paddingTop: '5%' }}>
                        <h3>{this.state.system_msg}</h3>
                        <h3>
                            {this.context.Facilities.length === 0
                                ? 'You are not associated with any facilities.'
                                : 'Select a facility above to get started.'}
                        </h3>
                    </div>
                )}
            </div>
        )
    }
}

export default Dashboard
