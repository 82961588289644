import { useEffect } from 'react'
import { QUICK_NOTE_ICON } from '../../../constants/studyevents'

const QuickNote = (props) => {
    let option = null
    useEffect(() => {
        props.selected && option.focus()
    })

    return (
        <button
            ref={el => option = el}
            className={`quick-note ${props.selected ? 'selected' : ''}`}
            onClick={props.handleClick}
        >
            <i className={`icon ${QUICK_NOTE_ICON[props.quickNote]}`} />
            <span className="quick-note-value">{props.quickNote}</span>
        </button>
    )
}

export default QuickNote
