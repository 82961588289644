class FormFieldManager {
    constructor(fieldsToManage, changeNotifyCallback) {
        this.fields = fieldsToManage
        this.sendChangeNotification = changeNotifyCallback

        this.fields.forEach((field, index) => {
            this.fields[index].orig = field.value
        })
    }

    getFields = () => this.fields

    setFieldValue = (field, value, setOrig = false) => {
        const index = this.getFieldIndex(field)
        this.fields[index].value = value
        if (setOrig) {
            this.fields[index].orig = value
        }
    }

    resetFieldsWithCurrentValues = () => {
        this.fields.forEach((field, index) => {
            this.fields[index].orig = field.value
        })
        this.sendChangeNotification()
    }

    getFieldIndex = field => this.fields.findIndex(c => c.name === field)

    getFieldValue = field => {
        const index = this.getFieldIndex(field)
        return index > -1 ? this.fields[index].value : '_error_'
    }

    areFieldsValid = () => {
        let valid = true
        this.fields.forEach(field => {
            valid = valid && field.isValid()
        })
        return valid
    }

    isValid = field => {
        const index = this.getFieldIndex(field)
        const isValid = this.fields[index].isValid()
        return index > -1 ? isValid : false
    }

    shouldHideError = field => this.shouldFieldValidityErrorDisplay(field)

    shouldFieldValidityErrorDisplay = field => {
        const index = this.getFieldIndex(field)
        const shouldHideError = this.fields[index].hideValidityError
        return index > -1 ? shouldHideError : false
    }

    handleChange = (field, value) => {
        const index = this.getFieldIndex(field)
        this.fields[index].value = value
        this.sendChangeNotification()
    }

    hasChanged = () => this.fields.some(fv => fv.value !== fv.orig)

    handleBlur = et => {
        const fieldIndex = this.getFieldIndex(et.id || et)
        if (!fieldIndex && fieldIndex !== 0) {
            console.log(
                '[FormManager] Invalid field index retrieve during onBlur event',
            )
            return
        }
        this.fields[fieldIndex].hideValidityError = false
        this.sendChangeNotification()
    }
}

export default FormFieldManager
