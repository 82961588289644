import AuditLogEntry, { ChangeActionType } from '../types/AuditLogEntry'
import AuditLogUtils from '../utils/AuditLogUtils'
import { Session, Lane, StudyPatient } from '../types/cptMonitoringTypes'
import moment, { Moment } from 'moment'
import Patient from '../types/Patient'
import User from '../types/User'
import Study from '../types/Study'

class CPTMonitoringService {
    static getPatientSessions(entries: AuditLogEntry[], studyPatients: StudyPatient[], scopeStart: Moment, scopeEnd: Moment): Session<Patient>[] {
        entries.sort((a: AuditLogEntry, b: AuditLogEntry) => {
            const aMoment = moment(a.DateCreated)
            const bMoment = moment(b.DateCreated)
            if (aMoment.isBefore(bMoment)) return -1
            if (aMoment.isAfter(bMoment)) return 1
            return 0
        })

        const sessions: Session<Patient>[] = []

        for (const entry of entries) {
            const entryMoment = moment(entry.DateCreated)
            if (entry.ActionTypeId === ChangeActionType.StartMonitoring) {
                sessions.push({
                    ID: entry.ID,
                    UserID: entry.UserId,
                    UserName: AuditLogUtils.formatUserName(entry),
                    UserRole: entry.UserRole,
                    StudyID: entry.ItemId,
                    Started: entryMoment,
                    Stopped: null,
                    Info: studyPatients.find(sp => sp.StudyID === entry.ItemId)?.PatientInfo,
                })
            } else {
                const startedSession = sessions.find(s => s.StudyID === entry.ItemId && !s.Stopped)
                if (startedSession) {
                    startedSession.Stopped = entryMoment
                }
            }
        }

        return sessions.filter(s => {
            if (s.Stopped?.isBefore(scopeStart)) {
                return false
            }
            if (s.Started.isAfter(scopeEnd)) {
                return false
            }
            if (s.Stopped && !s.Started) {
                return false
            }
            return true
        })
    }

    static getUserSessions(entries: AuditLogEntry[], scopeStart: Moment, scopeEnd: Moment, studyTimezone: string): Session<void>[] {
        entries.sort((a: AuditLogEntry, b: AuditLogEntry) => {
            const aMoment = moment(a.DateCreated)
            const bMoment = moment(b.DateCreated)
            if (aMoment.isBefore(bMoment)) return -1
            if (aMoment.isAfter(bMoment)) return 1
            return 0
        })

        const sessions: Session<void>[] = []

        for (const entry of entries) {
            const entryMoment = moment(entry.DateCreated).tz(studyTimezone)
            if (entry.ActionTypeId === ChangeActionType.StartMonitoring) {
                sessions.push({
                    ID: entry.ID,
                    UserID: entry.UserId,
                    UserName: AuditLogUtils.formatUserName(entry),
                    UserRole: entry.UserRole,
                    StudyID: entry.ItemId,
                    Started: entryMoment,
                    Stopped: null,
                })
            } else {
                const startedSession = sessions.find(s => s.StudyID === entry.ItemId && !s.Stopped)
                if (startedSession) {
                    startedSession.Stopped = entryMoment
                }
            }
        }

        return sessions.filter(s => {
            if (s.Stopped?.isBefore(scopeStart)) {
                return false
            }
            if (s.Started.isAfter(scopeEnd)) {
                return false
            }
            if (s.Stopped && !s.Started) {
                return false
            }
            return true
        })
    }

    static getLanes<Info>(sessions?: Session<Info>[]): Lane<Info>[] {
        const lanes: Lane<Info>[] = []
        if (sessions) {
            SESSION_LOOP:
            for (let i = 0; i < sessions.length; i += 1) {
                const session = sessions[i]
                if (i === 0) {
                    lanes.push({ sessions: [session] })
                    continue
                }
                for (let j = 0; j < lanes.length; j += 1) {
                    const lane = lanes[j]
                    const lastSessionInLane = lane.sessions[lane.sessions.length - 1]
                    if (lastSessionInLane.Stopped?.isBefore(session.Started)) {
                        lane.sessions.push(session)
                        continue SESSION_LOOP
                    }
                }
                lanes.push({ sessions: [session] })
            }
        }
        return lanes
    }

    static getStudyCSVReport(sessions: Session[], study: Study, patient: Patient): File {
        let fileName = study.ExamId ?? 'NoStudyId'
        if (fileName.trim() === '') fileName = '_'
        const fullFileName = `${fileName}.csv`

        const dob = moment(patient.DateOfBirth).format('MMM D, YYYY')
        let contents = `Patient Name,"${patient.FullName}",,User,Start Monitoring,Stop Monitoring\n`

        for (let i = 0; i < Math.max(sessions.length, 3); i++) {
            const session = sessions[i]
            let prefix = ''
            switch (i) {
                case 0:
                    prefix = `Patient ID,${patient.PatientID ? `"${patient.PatientID}"` : ''},,`
                    break
                case 1:
                    prefix = `Patient DOB,"${dob}",,`
                    break
                case 2:
                    prefix = `Study ID,${study.ExamId ? `"${study.ExamId}"` : ''},,`
                    break
                default:
                    prefix = ',,,'
            }
            if (session) {
                const started = moment(session.Started).format('hh:mm:ss A MMM D, YYYY')
                let stopped = 'now'
                if (session.Stopped) {
                    stopped = moment(session.Stopped).format('hh:mm:ss A MMM D, YYYY')
                }
                contents += `${prefix}"${session.UserName}","${started}","${stopped}"`
            } else {
                contents += prefix
            }
            contents += '\n'
        }
        return new File([contents], fullFileName)
    }


    static getUserCSVReport(user: User, studyPatients: StudyPatient[], sessions: Session<Patient>[]): File {
        const fileName = `${user.LastName}_${user.FirstName}.csv`
        let contents = 'Study ID,Patient,Start Monitoring,Stop Monitoring\n'
        for (let i = 0; i < sessions.length; i++) {
            const session = sessions[i]
            const started = moment(session.Started).format('hh:mm:ss A MMM D, YYYY')
            let stopped = 'now'
            if (session.Stopped) {
                stopped = moment(session.Stopped).format('hh:mm:ss A MMM D, YYYY')
            }
            const examId = studyPatients.find(s => s.StudyID === session.StudyID)?.ExamID ?? '<not set>'
            contents += `${examId},"${session.Info?.LastName ?? ''}, ${session.Info?.FirstName ?? ''}","${started}","${stopped}"\n`
        }
        return new File([contents], fileName)
    }
}

export default CPTMonitoringService
