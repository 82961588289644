export const UNKNOWN = 1
export const IN_PROGRESS = 2
export const PENDING_REVIEW = 3
export const IN_REVIEW = 4
export const REPORT_COMPLETE = 5
export const DISCARDED = 6
export const ARCHIVED = 7
export const LOCALLY_ARCHIVED = 8

export const STUDY_STATES: { [key: number]: string } = {}
STUDY_STATES[UNKNOWN] = 'Unknown'
STUDY_STATES[IN_PROGRESS] = 'In Progress'
STUDY_STATES[PENDING_REVIEW] = 'Pending Review'
STUDY_STATES[IN_REVIEW] = 'In Review'
STUDY_STATES[REPORT_COMPLETE] = 'Complete'
STUDY_STATES[DISCARDED] = 'Discarded'
STUDY_STATES[ARCHIVED] = 'Archived'
STUDY_STATES[LOCALLY_ARCHIVED] = 'Locally Archived'
