import React from 'react'
import { Progress } from 'reactstrap'
import { FAILED } from '../../constants/syncstates'

const StudySyncStatusBar = props => {
    const SyncInfo = props.study.SyncInfo
    const isInFailedSyncState = SyncInfo.SyncState === FAILED

    const DataSyncPercentText = SyncInfo.DataChecksumError
        ? 'Incomplete'
        : `${SyncInfo.StudyDataSyncPercent}%`

    const VideoSyncPercentText = SyncInfo.VideoChecksumError
        ? 'Incomplete'
        : `${SyncInfo.VideoDataSyncPercent}%`

    return (
        <React.Fragment>
            <div className="info-item">
                Data:
                <Progress
                    className={
                        isInFailedSyncState || SyncInfo.DataChecksumError
                            ? 'danger'
                            : 'success'
                    }
                    value={SyncInfo.StudyDataSyncPercent}
                >
                    {DataSyncPercentText}
                </Progress>
            </div>
            {props.study.UsesVideo && (
                <div className="info-item">
                    Video:
                    <Progress
                        className={
                            isInFailedSyncState || SyncInfo.VideoChecksumError
                                ? 'danger'
                                : 'success'
                        }
                        value={SyncInfo.VideoDataSyncPercent}
                    >
                        {VideoSyncPercentText}
                    </Progress>
                </div>
            )}
        </React.Fragment>
    )
}

export default StudySyncStatusBar
