// * MOBILEMEDTEK CONFIDENTIAL                                                 |
// * -------------------------                                                 |
// * Copyright 2017 MobileMedTek. All rights reserved.                         |
// *                                                                           |
// * NOTICE:  All information contained herein is, and remains                 |
// * the property of MobileMedTek. The intellectual and                        |
// * technical concepts contained herein are proprietary to MobileMedTek       |
// * and its suppliers and may be covered by U.S. and Foreign Patents,         |
// * patents in process, and are protected by trade secret or copyright law.   |
// * Dissemination of this information or reproduction of this material        |
// * is strictly forbidden unless prior written permission is obtained         |
// * from MobileMedTek.                                                        |

class ChannelTypeFilterSettings {
    constructor(channelTypeFilterSettings) {
        this.ChannelTypeId = channelTypeFilterSettings.ChannelTypeId
        this.HighFrequencyFilterValue =
            channelTypeFilterSettings.HighFrequencyFilterValue
        this.LowFrequencyFilterTimeConstant =
            channelTypeFilterSettings.LowFrequencyFilterTimeConstant
    }
}

export default ChannelTypeFilterSettings
