import React from 'react'
import { zxcvbn } from '../../utils/PasswordUtils'
import { Form } from 'reactstrap'
import InputGroup from '../../components/forms/InputGroup'
import SelectGroup from '../../components/forms/SelectGroup'

const UserForm = props => {
    const roleOptions = availableRoles =>
        availableRoles.map(role => ({
            value: role.Id,
            name: role.Name,
        }))

    const isExcluded = fieldName =>
        props.excludeFields &&
        props.excludeFields.some(i => i === fieldName.toLowerCase())
    const displayAsterisk = props.hideAsterisks ? '' : '*'
    const isValid = field => props.getFieldValidation(field)
    const hideError = field => props.getFieldValidityErrorDisplay(field)

    return (
        <Form>
            {/* Firefox will autofill email and password if this isn't here */}
            <input type="text" style={{ display: 'none' }} />
            <input type="password" style={{ display: 'none' }} />

            {!isExcluded('title') && (
                <InputGroup
                    type="text"
                    field="Title"
                    label="Title"
                    placeholder="Title"
                    error="If a title is provided, it must be 100 characters or less"
                    maxLength={100}
                    value={props.getFieldValue('Title')}
                    valid={isValid('Title')}
                    hideError={hideError('Title')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('firstname') && (
                <InputGroup
                    type="text"
                    field="FirstName"
                    label={`First${displayAsterisk}`}
                    placeholder="First Name"
                    error="200 characters or less required"
                    maxLength={200}
                    value={props.getFieldValue('FirstName')}
                    valid={isValid('FirstName')}
                    hideError={hideError('FirstName')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('middlename') && (
                <InputGroup
                    type="text"
                    field="MiddleName"
                    label="Middle"
                    placeholder="Middle Name"
                    error="200 characters or less required"
                    maxLength={200}
                    value={props.getFieldValue('MiddleName')}
                    valid={isValid('MiddleName')}
                    hideError={hideError('MiddleName')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('lastname') && (
                <InputGroup
                    type="text"
                    field="LastName"
                    label={`Last${displayAsterisk}`}
                    placeholder="Last Name"
                    error="200 characters or less required"
                    maxLength={200}
                    value={props.getFieldValue('LastName')}
                    valid={isValid('LastName')}
                    hideError={hideError('LastName')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('suffix') && (
                <InputGroup
                    type="text"
                    field="Suffix"
                    label="Suffix"
                    placeholder="Suffix"
                    error="100 characters or less required"
                    maxLength={100}
                    value={props.getFieldValue('Suffix')}
                    valid={isValid('Suffix')}
                    hideError={hideError('Suffix')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('email') && (
                <InputGroup
                    id="Email"
                    field="Email"
                    label={`Email${displayAsterisk}`}
                    className="is-valid"
                    maxLength={256}
                    placeholder="Email"
                    error="Valid email address required"
                    value={props.getFieldValue('Email')}
                    valid={isValid('Email')}
                    hideError={hideError('Email')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('password') && (
                <InputGroup
                    type="password"
                    field="Password"
                    label={`Password${displayAsterisk}`}
                    placeholder="Password"
                    error={
                        zxcvbn(props.getFieldValue('Password')).feedback
                            .warning || 'Must be 8 to 100 characters long'
                    }
                    value={props.getFieldValue('Password')}
                    valid={isValid('Password')}
                    hideError={hideError('Password')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                    autoComplete="new-password"
                />
            )}
            {!isExcluded('confirmpassword') && (
                <InputGroup
                    type="password"
                    field="ConfirmPassword"
                    label={`Confirm Password${displayAsterisk}`}
                    placeholder="Confirm Password"
                    error="Does not match Password"
                    value={props.getFieldValue('ConfirmPassword')}
                    valid={isValid('ConfirmPassword')}
                    hideError={hideError('ConfirmPassword')}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                    handleBlur={props.handleBlur}
                />
            )}
            {!isExcluded('roleid') && props.availableRoles && (
                <SelectGroup
                    field="RoleId"
                    label={`Role${displayAsterisk}`}
                    error="Please select a valid role"
                    options={roleOptions(props.availableRoles)}
                    value={props.getFieldValue('RoleId')}
                    valid={isValid('RoleId')}
                    hideError={hideError('RoleId')}
                    handleBlur={props.handleBlur}
                    disabled={props.readOnly}
                    handleChange={props.handleChange}
                />
            )}
        </Form>
    )
}

export default UserForm
