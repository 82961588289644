import './GraphChannelMenu.scss'
import { CHANNEL_TYPE, EEG, EM } from './constants'
import { MEASUREMENT } from '../../../constants/studyevents'

function GraphChannelMenu(props) {
    return (
        <ul
            id="GraphChannelMenu"
            style={{ top: `${props.channel.top}px` }}
            className={`${props.isOpen ? 'show' : ''}`}
        >
            {props.channel.type !== CHANNEL_TYPE[EEG] && props.channel.type !== CHANNEL_TYPE[EM] && (
                <li>
                    <button
                        type="button"
                        title="Change Non-EEG Settings"
                        className="icon icon-electrotek-controls"
                        onClick={() =>
                            props.handleShowNonEEGOverlay(props.channel)
                        }
                    />
                </li>
            )}
            <li>
                <button
                    type="button"
                    title={`${props.channel.enabled ? 'Hide' : 'Show'} Channel`}
                    className={`icon ${props.channel.enabled
                        ? 'icon-electrotek-minus-circle-fill'
                        : 'icon-electrotek-plus-circle-fill'
                    }`}
                    onClick={() => props.handleToggleHideChannel(props.channel)}
                />
            </li>
            {props.channel.type !== CHANNEL_TYPE[EM] && (
                <li>
                    <button
                        type="button"
                        title="Measurement"
                        className="icon icon-electrotek-ruler"
                        onClick={() =>
                            props.toggleCreateEventMode(MEASUREMENT, props.channel)
                        }
                    />
                </li>
            )}
            <li>
                <button
                    type="button"
                    className="disabled badge"
                    title={`${props.channel.label} Channel`}
                    disabled
                >
                    {props.channel.label}
                </button>
            </li>
        </ul>
    )
}

export default GraphChannelMenu
