import React from 'react'

const EventDragHandles = props => (
    <React.Fragment>
        <div
            className="move-handle"
            onMouseDown={props.handleDragStart}
            onTouchStart={props.handleDragStart}
        />
        {props.isSpanning && (
            <React.Fragment>
                <div
                    className={`drag-${
                        props.isEventBackwards ? 'right' : 'left'
                    }-handle`}
                    onMouseDown={props.handleDragLeftSideStart}
                    onTouchStart={props.handleDragLeftSideStart}
                />
                <div
                    className={`drag-${
                        props.isEventBackwards ? 'left' : 'right'
                    }-handle`}
                    onMouseDown={props.handleDragRightSideStart}
                    onTouchStart={props.handleDragRightSideStart}
                />
            </React.Fragment>
        )}
    </React.Fragment>
)

export default EventDragHandles
