import { useEffect, useState } from 'react'

const useDeviceHeight = () => {
    const [height, setHeight] = useState(window.innerHeight)

    useEffect(() => {
        const listener = () => {
            setHeight(window.innerHeight)
        }
        window.addEventListener('resize', listener)
        return () => window.removeEventListener('resize', listener)
    }, [])

    return height
}

export default useDeviceHeight