import React, { useEffect, useState } from 'react'
import './MontageView.scss'
import Montage from '../../types/Montage'
import MontagesApi from '../../services/MontagesApi'
import MontageHeadView from './MontageHeadView'
import ToastUtils from '../../utils/ToastUtils'
import LoadingSpinner from '../../components/LoadingSpinner'
import axios from 'axios'
import DateTimeUtils from '../../utils/DateTimeUtils'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { CircularProgress } from '@mui/material'
import { Button } from '@mui/material'
import { duplicateParentheses, getDuplicateBaseName, getDuplicateNumber } from '../../utils/StringUtils'
import RoleBasedContent from '../../components/RoleBasedContent'
import MontageAuth from '../../auth/MontageAuth'

interface Props {
    allMontages: Montage[]
    montage: Montage
    onDeleted: () => void
    onDuplicated: (montage: Montage) => void
}

const MontageView: React.FC<Props> = props => {
    const [isLoading, setIsLoading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isDeleteModalShowing, setIsDeleteModalShowing] = useState(false)
    const [isDuplicating, setIsDuplicating] = useState(false)
    const [montage, setMontage] = useState<Montage | undefined>()
    const history = useHistory()

    const montageId = props.montage.ID
    useEffect(() => {
        setIsLoading(true)
        setMontage(undefined)
        const source = axios.CancelToken.source()
        MontagesApi.getMontage(montageId, source).then(res => {
            setMontage(res.data)
            setIsLoading(false)
        }).catch(err => {
            if (axios.isCancel(err)) return
            ToastUtils.error({ message: 'Unable to get patient' })
            console.log(err)
            setIsLoading(false)
        })
        return () => source.cancel('New search performed')
    }, [montageId])


    const handleDelete = () => {
        if (!montage) return
        setIsDeleting(true)
        MontagesApi.deleteMontage(montage.ID).then(() => {
            props.onDeleted()
        }).catch(err => {
            ToastUtils.error({ message: 'Unable to delete montage' })
            console.log(err)
            setIsDeleting(false)
        })
    }

    const handleDuplicate = () => {
        if (!montage) return
        setIsDuplicating(true)
        const lastMontage = [...props.allMontages]
            .filter(m => getDuplicateBaseName(m.Name) === getDuplicateBaseName(montage.Name))
            .sort((a, b) => getDuplicateNumber(a.Name) - getDuplicateNumber(b.Name))
            .pop()
        const newMontage = { ...montage, Name: duplicateParentheses((lastMontage ?? montage).Name) }
        MontagesApi.createMontage(newMontage).then(res => {
            props.onDuplicated(res.data)
            setIsDuplicating(false)
        }).catch(err => {
            ToastUtils.error({ message: 'Unable to duplicate montage' })
            console.log(err)
            setIsDuplicating(false)
        })
    }

    const isEditable = montage && !['BioCalibration', 'Average'].includes(montage.Name)

    return (
        <div className="montage-view">
            {montage ? (
                <>
                    <MontageHeadView montage={montage} width={360} />
                    <div className="info">
                        <h4 className="montage-name">{montage.Name}</h4>
                        <p>Created: {DateTimeUtils.getFriendlyDateTime(montage.DateCreated)}</p>
                        <div className="actions">
                            {isEditable && (
                                <>
                                    <RoleBasedContent required_roles={MontageAuth.Edit}>
                                        <i
                                            className="icon-electrotek-pencil"
                                            onClick={() => history.push(`/Montage/${montage.ID}`)}
                                            title={`Edit ${montage.Name}`}
                                        />
                                    </RoleBasedContent>
                                    <RoleBasedContent required_roles={MontageAuth.Delete}>
                                        {isDeleting ? (
                                            <div className="spinnerContainer"><CircularProgress /></div>
                                        ) : (
                                            <i
                                                className="icon-electrotek-trashcan"
                                                onClick={() => setIsDeleteModalShowing(true)}
                                                title={`Delete ${montage.Name}`}
                                            />
                                        )}
                                    </RoleBasedContent>
                                </>
                            )}
                            <RoleBasedContent required_roles={MontageAuth.Create}>
                                {isDuplicating ? (
                                    <div className="spinnerContainer"><CircularProgress /></div>
                                ) : (
                                    <i
                                        className="icon-electrotek-file-empty"
                                        onClick={handleDuplicate}
                                        title={`Copy ${montage.Name}`}
                                    />
                                )}
                            </RoleBasedContent>
                        </div>
                    </div>
                </>
            ) : isLoading && <LoadingSpinner />}
            <Modal isOpen={isDeleteModalShowing} toggle={() => setIsDeleteModalShowing(i => !i)}>
                <ModalHeader toggle={() => setIsDeleteModalShowing(i => !i)}>
                    Delete Montage
                </ModalHeader>
                <ModalBody>
                    <div className="container">
                    Are you sure you want to delete this montage?
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        variant="outlined"
                        onClick={() => setIsDeleteModalShowing(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleDelete}
                        disabled={isDeleting}
                        sx={{ ml: 2 }}
                    >
                        {isDeleting ? 'Deleting...' : 'Delete'}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default MontageView
