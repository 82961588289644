const NavUtils = {
    setPageTitle(titleText) {
        document.title = `Rendr Review - ${titleText}`
    },

    setPageTitleFromPath(path) {
        if (!path) {
            return
        }
        const pathParts = path.split('/')
        const needsCreate = pathParts.includes('Create', 'create')
        document.title = `Rendr Review - ${needsCreate ? 'Create ' : ''}${
            pathParts[pathParts.length - 1]
        }`
    },
}

export default NavUtils
