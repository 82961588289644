import { useContext } from 'react'
import AppContext from '../components/AppContext'

function useHasRole(roles: string[]): boolean {
    const ctx = useContext(AppContext)

    for (const role of roles) {
        if (ctx.Roles.includes(role)) {
            return true
        }
    }

    return false
}

export default useHasRole
