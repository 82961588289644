import { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import { Grid, Button, Alert, AlertTitle } from '@mui/material'
import SessionManager from '../../services/SessionManager'
import { patientName } from '../../utils/StringUtils'
import { PHOTIC, NONIN, SAMPLECOUNTER, EVENT } from '../studies/eegMonitor/constants'
import NotSet from '../../components/NotSet'
import { EXAM_ID_MAX_LENGTH } from '../../constants/studies'
import PatientUtils from '../../utils/PatientUtils'
import AppContext from '../../components/AppContext'
import StudiesApi from '../../services/StudiesApi'
import ToastUtils from '../../utils/ToastUtils'
import NeutralButton from '../../components/NeutralButton'
import { CircularProgress } from '@mui/material'

const getDoesPatientSeemCorrect = props => {
    if (props.isCloudSyncPkg) return true
    const isFirstNameInDataFilePatientID = props.studyInfo.patientId
        .toLowerCase()
        .includes(props.patient.FirstName.toLowerCase())
    const isLastNameInDataFilePatientID = props.studyInfo.patientId
        .toLowerCase()
        .includes(props.patient.LastName.toLowerCase())
    return isFirstNameInDataFilePatientID && isLastNameInDataFilePatientID
}

const checkLinkedStudies = associatedStudyId => {
    return StudiesApi.getLinkedStudies(associatedStudyId)
        .then(res => {
            if (res.data?.AmplifierStudyIds?.length > 0) {
                ToastUtils.notice({ message: 'Note: This cloud sync package has already been imported.' })
            }
            return res.data.PCStudyId
        })
}

const ImportStudyVerify = props => {
    const ctx = useContext(AppContext)
    const handleCustomElectrodeChange = (index, id) => {
        const studyInfo = { ...props.studyInfo }
        studyInfo.customElectrodes[index].ChannelTypeID = parseInt(id, 10)
        props.onUpdateStudyInfo(studyInfo)
    }

    const associatedStudyId = props.associatedStudy?.ID
    const setHarnessId = props.setHarnessId
    useEffect(() => {
        if (associatedStudyId) {
            checkLinkedStudies(associatedStudyId).then(pcStudyId => {
                StudiesApi.getStudy(pcStudyId).then(res => {
                    setHarnessId(res.data.Harness.ID)
                    setIsVerified(true)                    
                })
            })
        }
    }, [associatedStudyId, setHarnessId])

    const doesPatientSeemCorrect = getDoesPatientSeemCorrect(props)
    const willExamIdBeTruncated = () => {
        if (props.isCloudSyncPkg) return false
        return props.studyInfo.recordId.length > EXAM_ID_MAX_LENGTH
    }

    const [isContinuing, setIsContinuing] = useState(false)
    // if cloudsync, set isVerified to false
    const [isVerified, setIsVerified] = useState(!props.isCloudSyncPkg)

    return (
        <div className="study-patient-verify">
            {props.cloudSyncApiErr ? (
                <Alert color="error">
                    <AlertTitle>Error!</AlertTitle> Unable to find associated study.
                    {ctx.Facilities.length > 1 && ' Are you sure you are in the correct facility?'}
                </Alert>
            ) : (
                <Grid container>
                    <Grid item xs={6}>
                        <h4>Selected Patient</h4>
                        <p>
                            <b>Patient Name:</b>{' '}
                            <span
                                className={!doesPatientSeemCorrect ? 'danger' : ''}
                            >
                                {patientName(props.patient)}
                            </span>
                        </p>
                        <p>
                            <b>Patient DOB:</b>{' '}
                            {moment(props.patient.DateOfBirth).format(
                                'MMM D, YYYY',
                            )}
                        </p>
                        <p>
                            <b>Patient ID:</b>{' '}
                            {props.patient.PatientID || <NotSet />}
                        </p>
                        <p>
                            <b>Patient Sex:</b> {PatientUtils.formatSex(props.patient.Sex)}
                        </p>
                        <p>
                            <b>Patient SSN:</b> {props.patient.SSN || <NotSet />}
                        </p>
                        <p>
                            <b>Facility Name:</b>{' '}
                            {SessionManager.get('mmt_facility_name')}
                        </p>
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Selected Study</h4>
                        {!props.isCloudSyncPkg && (
                            <p>
                                <b>Patient:</b>{' '}
                                <span
                                    className={!doesPatientSeemCorrect ? 'danger' : ''}
                                >
                                    {props.studyInfo.patientId}
                                </span>
                            </p>
                        )}
                        <p>
                            <b>Duration:</b> {props.studyInfo.duration}
                        </p>
                        <p>
                            <b>Study Started:</b> {props.studyInfo.startDateTime}
                        </p>
                        <p>
                            <b>Channel Count:</b> {props.studyInfo.channelCount}
                        </p>
                        <p>
                            <b>Sample Rate:</b> {props.studyInfo.sampleRate}
                        </p>
                        <p>
                            <b>Video Count:</b> {props.studyInfo.videoCount}
                        </p>
                        <p>
                            <b>Study ID:</b> {props.isCloudSyncPkg ? props.associatedStudy.ExamId ?? <NotSet /> : props.studyInfo.recordId}
                        </p>
                        {props.studyInfo.photicIndex && (
                            <p>
                                <b>Has Photic Channel</b>
                            </p>
                        )}
                        {!props.isCloudSyncPkg && props.studyInfo.customElectrodes.map(
                            (electrode, index) => (
                                <div key={`${electrode.Name}${electrode.Index}`}>
                                    {electrode.Name}:
                                    <select
                                        className="form-control"
                                        value={electrode.ChannelTypeID}
                                        onChange={e =>
                                            handleCustomElectrodeChange(
                                                index,
                                                e.target.value,
                                            )
                                        }
                                    >
                                        {props.supportedChannelTypes.filter(t => t.ID !== PHOTIC && t.ID !== NONIN && t.ID !== SAMPLECOUNTER && t.ID !== EVENT).map(
                                            channelType => (
                                                <option
                                                    value={channelType.ID}
                                                    key={channelType.ID}
                                                >
                                                    {channelType.Name}
                                                </option>
                                            ),
                                        )}
                                    </select>
                                </div>
                            ),
                        )}
                    </Grid>
                </Grid>
            )}
            {!doesPatientSeemCorrect && (
                <Alert color="warning">
                    <AlertTitle>Warning!</AlertTitle> Patient name does not match{' '}
                    {props.studyInfo.dataFileFormat} header.
                </Alert>
            )}
            {willExamIdBeTruncated() && (
                <Alert color="warning">
                    <AlertTitle>Warning!</AlertTitle> Study ID {`'${props.studyInfo.recordId}'`}{' '}
                    will be truncated to{' '}
                    {`'${props.studyInfo.recordId.slice(
                        0,
                        EXAM_ID_MAX_LENGTH,
                    )}'`}
                    .
                </Alert>
            )}
            {props.studyInfo.showFileSizeWarning && (
                <Alert color="warning">
                    <AlertTitle>Warning!</AlertTitle> {props.studyInfo.dataFileFormat} file is not
                    valid!
                </Alert>
            )}
            {!isVerified && <div className="loading-spinner" style={{ position: 'absolute', top: '450px' }}>
                <CircularProgress color="info" />
                <p>Verifying...</p>
            </div>}
            <Button
                onClick={() => {
                    setIsContinuing(true)
                    props.onContinue()
                }}
                className="pull-right"
                disabled={!isVerified || isContinuing || props.cloudSyncApiErr}
            >
                {isContinuing ? 'Continuing...' : 'Continue'}
            </Button>
            <NeutralButton onClick={props.onBack}>
                Back
            </NeutralButton>
        </div>
    )
}

export default ImportStudyVerify
