import { Grid } from '@mui/material'

// type select_option = { value: string } | { name: string }

// type Props = {
//     field: string,
//     value: string,
//     label: string,
//     handleChange: (field: string, value: string) => ?void,
//     options: Array<select_option>,
//     valid?: boolean,
//     error?: string,
//     hideError?: boolean,
// }

const optionList = options =>
    options.map((option, i) => {
        const key = i
        return (
            <option value={option.value} key={key}>
                {option.name}
            </option>
        )
    })

function SelectGroup(props) {
    return (
        <Grid container style={{ margin: '0 0 16px 0' }}>
            <Grid item xs={12} sm={2} md={3} className="form-group" style={{ position: 'relative' }}>
                <label
                    htmlFor={props.field}
                    style={{ margin: 0, paddingRight: 12, top: '100%' }}>
                    {props.label}
                </label>
            </Grid>

            <Grid item xs={12} sm={10} md={9}> 
                <select
                    className="form-control select"
                    id={props.field}
                    value={props.value}
                    disabled={props.disabled}
                    onChange={event =>
                        props.handleChange(props.field, event.target.value)
                    }
                >
                    {optionList(props.options)}
                </select>
            </Grid>
            {!props.valid && props.error && !props.hideError && (
                <>
                    <Grid item xs={12} sm={2} md={3} className="form-group empty-column-offset" style={{ position: 'relative', paddingRight: 12 }} />
                    <Grid item xs={12} sm={10} md={9}>                        
                        <span className="input-error-msg">
                            {props.error || ''}
                        </span>
                    </Grid>
                </>                 
            )}
        </Grid>
    )
}

export default SelectGroup
